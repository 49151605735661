/**
 * 座標
 */
class Position {
	static keys = {
		X: 'X',
		Y: 'Y',
		ellipsoidalHeight: 'ellipsoidal_height',
		orthometricHeight: 'orthometric_height',
		depth: 'depth',
	}
	/**
	 * @param {float} X TWD97_X
	 * @param {float} Y TWD97_Y
	 * @param {float} ellipsoidalHeight 橢球高
	 * @param {float} orthometricHeight 正高
	 * @param {float} depth 埋深
	 */
	constructor(X, Y, ellipsoidal_height, orthometric_height, depth){
		this.X = X
		this.Y = Y
		this.ellipsoidalHeight = ellipsoidal_height
		this.orthometricHeight = orthometric_height
		this.depth = depth
	}

	set(data={}) {
		Object.keys(Position.keys).filter(key => data[Position.keys[key]] !== undefined).forEach(key => {
			this[key] = data[Position.keys[key]]
		})
		return this
	}

	/**
	 * 管頂高程
	 * @param {int} fixed 
	 * @returns {Number}
	 */
	getFacilityHeight(fixed=6) {
		return Number((this.orthometricHeight - this.depth).toFixed(fixed))
	}

	get() {
		return {
			X: this.X,
			Y: this.Y,
			ellipsoidalHeight: this.ellipsoidalHeight,
			orthometricHeight: this.orthometricHeight,
			depth: this.depth,
			facilityHeight: this.getFacilityHeight(),
		}
	}

	/**
	 * 依key輸出
	 * @param {String[]} keys 
	 * @param {String} delim 
	 */
	getPositionByKey(keys=[], delim=" ") {
		let data = this.get()
		return keys.map(key => data[key]).map(v => v.toFixed(3)).join(delim)
	}

	/**
	 * 計算兩點間距離
	 * @param {Position} pos1 
	 * @param {Position} pos2 
	 */
	static getDistance(pos1, pos2) {
		return Math.sqrt(Math.pow(pos1.X - pos2.X, 2) + Math.pow(pos1.Y - pos2.Y, 2) + Math.pow(pos1.getFacilityHeight() - pos2.getFacilityHeight(), 2));
	}
	/**
	 * 計算兩點間距離
	 * @param {Position} pos1 
	 * @param {Position} pos2 
	 */
	static getSurfaceDistance(pos1, pos2) {
		return Math.sqrt(Math.pow(pos1.X - pos2.X, 2) + Math.pow(pos1.Y - pos2.Y, 2));
	}
	/**
	 * 計算多點間距離總和
	 * @param {Position[]} posList 
	 */
	static getPointsDistance(posList) {
		let distance = 0
		if(!posList.length)
			return distance
		let pos = posList.shift()
		while(posList.length) {
			distance += Position.getDistance(pos, posList[0])
			pos = posList.shift()
		}
		return distance
	}
	/**
	 * 計算多點間距離總和
	 * @param {Position[]} posList 
	 */
	static getPointsSurfaceDistance(posList) {
		let distance = 0
		if(!posList.length)
			return distance
		let pos = posList.shift()
		while(posList.length) {
			distance += Position.getSurfaceDistance(pos, posList[0])
			pos = posList.shift()
		}
		return distance
	}
	/**
	 * 計算兩點方向
	 * @param {Position} pos1 
	 * @param {Position} pos2 
	 */
	static getDirection(pos1, pos2) {
		return {
			X: pos2.X - pos1.X,
			Y: pos2.Y - pos1.Y,
		}
	}
}


export default Position
