<template>
	<div class="px-3">
		<h5>表單管理</h5>
		<b-row>
			<div class="col-md-8 col-sm-12 member-list">
				<dynamic-form-list v-if="!reset"></dynamic-form-list>
			</div>
			<div class="col-md-4 col-sm-12">
				<b-card no-body class="border-0 control-board">
					<b-card-body>
						<b-tabs class="tabs-wrapper" fill active-nav-item-class="tab-title font-weight-bold" active-tab-class="tab-title" fluid>
							<b-tab class="tab-body" title="新增表單" active>
								<dynamic-form-creator @createDynamicForm="reset=true"></dynamic-form-creator>
							</b-tab>
						</b-tabs>
					</b-card-body>
				</b-card>
			</div>
		</b-row>
	</div>
</template>

<script>
import DynamicFormCreator from '@/components/DynamicForm/DynamicFormCreator'
import DynamicFormList from '@/components/DynamicForm/DynamicFormList'
import { deepCopy } from '@/utils/assist';
export default {
	name: 'DynamicFormManager',
	components:{
		DynamicFormCreator,
		DynamicFormList,
	},
	data() {
		return {
			reset: false
		}
	},
	watch: {
		reset(value) {
			if(value) {
				this.$nextTick(function () {
					this.reset = false;
				});
			}
		}
	},
	created() {
	},
	mounted() {
	},
	computed: {
	},
	methods:{
	}
}
</script>

<style scoped>
.card-wrapper {
	padding: .5rem;
}
.card {
	height: 100%;
}
.tab-body {
	padding: .5rem 1rem;
	border: 1px solid #dee2e6;
	border-top: 0;
	height: 100%;
}
.tabs-wrapper {
	height: 100%;
}
.tabs-wrapper > .tab-content {
	height: 100%;
}
.member-list {
	padding: 0 1rem;
}
.control-board {
	padding: 0 1rem;
}
.action-btn {
	padding: .25rem .75rem;
	border-radius: .5rem;
	background: #b2c8d8aa;
	color: #050b10cc;
	border-color: #b2c8d8;
}
.action-btn:hover {
	text-decoration: none;
	background: #8ea9c2cc;
	color: #050b10;
}
.action-btn:focus {
	box-shadow: none;
}
</style>