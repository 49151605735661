<template>
	<loading v-if="loading"/>
	<div v-else>
		<h5>{{unit.name}}</h5>
		<b-card no-body class="border-0 control-board px-md-3 px-1 p-2">
			<b-card-body>
				<b-tabs class="tabs-wrapper" fill active-nav-item-class="tab-title font-weight-bold" active-tab-class="tab-title" fluid>
					<!-- <b-tab class="tab-body px-md-3 px-1 p-2" title="單位電子章" active>
						<signature-manager type="unit" :id="unit.id" :signatures="unit.signatures" @reload="init"></signature-manager>
					</b-tab> -->
					<b-tab class="tab-body px-md-3 px-1 p-2" title="單位區處">
						<unit-branch-manager :unit="unit" :unit-branches="unit.branches" @reload="init"></unit-branch-manager>
					</b-tab>
					<b-tab class="tab-body px-md-3 px-1 p-2" title="單位人員">
						<unit-member-manager :unit="unit" :unit-members="unit.members" :unit-branches="unit.branches" @reload="init"></unit-member-manager>
					</b-tab>
				</b-tabs>
			</b-card-body>
		</b-card>
	</div>
</template>

<script>
import SignatureManager from '@/components/Signature/SignatureManager';
import UnitBranchManager from '@/components/Unit/Branch/UnitBranchManager';
import UnitMemberManager from '@/components/Unit/Member/UnitMemberManager';
import Loading from '@/components/Loading/Loading.vue';
import { deepCopy, checkAllTrue } from '@/utils/assist';

export default {
	name: 'UnitInfo',
	components:{
		SignatureManager,
		UnitBranchManager,
		UnitMemberManager,
		Loading
	},
	props: {
		unitID: {
			required: true,
			default: 0
		},
	},
	data() {
		return {
			call: {getUnitInfo: false},
			unit: {},
			loading: true,
			unitBranches: [],
			unitMembers: [],
			signatures: [],
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	created() {
		this.init()
	},
	mounted() {
	},
	computed: {
	},
	methods:{
		init() {
			this.getUnitInfo()
		},
		onLoadEnd() {
		},
		getUnitInfo() {
			this.call.getUnitInfo = false
			this.$axios.getUnitInfo(this.unitID, (response) => {
				this.unit = response.data
				this.call.getUnitInfo = true
			}, (error) => {
				this.call.getUnitInfo = true
			})
		},
	}
}
</script>

<style scoped>
.tab-body {
	border: 1px solid #dee2e6;
	border-top: 0;
	height: max-content;
}
.tabs-wrapper {
	height: 100%;
}
.tabs-wrapper > .tab-content {
	height: 100%;
}
</style>