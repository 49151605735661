<template>
	<span>
		<template v-if="getValue(visible, true)">
			<button :class="buttonClass" :style="buttonStyle" :title="configs.title" v-if="type==='function'" @click="action(action)">
				<button-text :configs="configs"></button-text>
			</button>
			<a :class="linkClass" :style="linkStyle" :title="configs.title" v-else-if="type==='link'" :href="getValue(action)" :target="actionConfigs.target">
				<button-text :configs="configs"></button-text>
			</a>
			<router-link :class="linkClass" :style="linkStyle" :title="configs.title" v-else-if="type==='route'" :to="getValue(action)" :target="actionConfigs.target">
				<button-text :configs="configs"></button-text>
			</router-link>
			<b-dropdown :title="configs.title" :toggle-class="buttonClass" v-else-if="type==='dropdown'">
				<template #button-content>
					<button-text :configs="configs"></button-text>
				</template>
				<b-dropdown-item v-for="(dropdown, key) in actionConfigs.dropdown" :key="key">
					<button-item :visible="dropdown.visible" :configs="dropdown.button" :type="dropdown.action.type" :action="dropdown.action[dropdown.action.type]" :action-configs="dropdown.action" :dropdown="true"></button-item>
				</b-dropdown-item>
			</b-dropdown>
		</template>
	</span>
</template>

<script>
import { oneOf } from "@/utils/assist"
import ButtonText from '@/components/Button/ButtonText'

export default {
	name: 'ButtonItem',
	components: {
		ButtonText,
	},
	props: {
		visible: {
			type: [Boolean, Function],
			default: true
		},
		configs: {
			type: Object,
			default: () => { return {} }
		},
		type: {
			type: String,
			validator (value) {
				return oneOf(value, ['function', 'link', 'route', 'dropdown']);
			},
		},
		action: {
			type: [String, Function, Array, Object],
		},
		actionConfigs: {
			type: Object,
			default: () => { return {} }
		},
		dropdown: {
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			defaultClass: 'd-inline-flex align-items-center p-1',
			defaultButtonClass: 'form-btn submit-btn',
			defaultLinkClass: 'link pointer',
			defaultStyle: '',
			defaultButtonStyle: 'vertical-align: middle;',
			defaultLinkStyle: '',
			dropdownClass: 'bg-transparent border-0',
		}
	},
	watch: {},
	created() {},
	mounted() {},
	computed: {
		buttonClass() {
			return `${this.defaultClass} ${this.defaultButtonClass} ${this.dropdown ? this.dropdownClass : ''} ${this.configs.class ?? ''}`
		},
		buttonStyle() {
			return `${this.dropdown ? '' : this.defaultStyle} ${this.defaultButtonStyle} ${this.configs.style ?? ''}`
		},
		linkClass() {
			return `${this.defaultClass} ${this.defaultLinkClass} ${this.configs.class ?? ''}`
		},
		linkStyle() {
			return `${this.defaultStyle} ${this.defaultLinkStyle} ${this.configs.style ?? ''}`
		},
	},
	methods: {
		getValue(item, defaultValue, ...args) {
			item = item ?? defaultValue
			return typeof item ==='function' ? item(...args) : item
		},
	},
}
</script>

<style scoped>

</style>