"use strict";
import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_API_URL

// 請伺服器回傳json
axios.defaults.headers.common["Accept"] = "application/json";
// 允許跨域請求時帶入cookie訊息
axios.defaults.withCredentials = true;

const _axios = axios.create();
_axios.interceptors.request.use(
	function(config) {
		return config;
	},
	function(error) {
		return Promise.reject(error);
	});
// Add a response interceptor
_axios.interceptors.response.use(
	function(response) {
		return response;
	},
	function(error) {
		return Promise.reject(error);
	});

export default {
	Get(url, config = {}) {
		return _axios.get(url, config)
			.then(res => res)
			.catch(error => {
				throw error
			})
	},
	Post(url, params = {}, headers = {}) {
		return _axios.post(url, params, headers)
			.then(res => res)
			.catch(error => {
				throw error
			})
	},
	Delete(url, params = {}, headers = {}) {
		return _axios.delete(url, params, headers)
			.then(res => res)
			.catch(error => {
				throw error
			})
	},
	http : axios
}