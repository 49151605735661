<template>
	<b-row class="w-100 outside-wrapper">
		<label class="input-label col-md-2 col-12" v-show="label" :class="labelClass">{{label}}</label>
		<b-form-input class="input-range" type="range" :min="config.min" :max="config.max" :step="config.step" :size="size === 'xs' ? 'xs' : ''" v-model="model" @change="change" @input="input"></b-form-input>
		<b-row class="w-100">
			<div class="col-md-2 col-12" :class="labelClass"></div>
			<div class="flex-fill text-hint font-size-sm text-left" :class="hintClass" v-if="hint">* {{hint}}</div>
		</b-row>
	</b-row>
</template>

<script>
export default {
	name: 'InputRange',
	components: {
	},
	props: {
		value: {
			type: Number,
		},
		size: {
			type: String,
			default: ""
		},
		label: {
			type: String,
		},
		labelClass: {
			type: String,
			default: ""
		},
		min: {
			type: Number,
		},
		max: {
			type: Number,
		},
		step: {
			type: Number,
			validator: v => v > 0
		},
		logarithmic: {
			type: Boolean,
			default: false
		},
		level: {
			type: Number,
			validator: v => Number.isInteger(v) && v > 0
		},
		hint: {
			type: String,
			default: ""
		},
		hintClass: {
			type: String,
			default: ""
		},
	},
	data() {
		return {
			model: this.logarithmic ? Math.log10(this.value) : this.value,
		}
	},
	watch: {
		value: {
			handler(value) {
				this.model = this.logarithmic ? Math.log10(this.value) : this.value
			}
		}
	},
	created() {
		// Math.log10
		console.log(this.config, Math.log10(this.max) - Math.log10(this.min), (this.max - this.min) / this.step)
	},
	computed: {
		config() {
			let min = this.logarithmic ? Math.log10(this.min) : this.min;
			let max = this.logarithmic ? Math.log10(this.max) : this.max;
			let step = typeof this.level !== 'undefined' ? (max - min) / this.level : this.step
			return {
				min: min,
				max: max,
				step: step,
				logarithmic: this.logarithmic,
			}
		}
	},
	methods: {
		input(e) {
			this.$emit('input', this.logarithmic ? Math.pow(10, e) : e)
		},
		change(e) {
			this.$emit('change', this.logarithmic ? Math.pow(10, e) : e)
		},
	}
}
</script>

<style scoped>
.outside-wrapper {
	align-items: center;
}
.input-label {
	padding-right: .5rem;
}
.input-range {
	/* overflow: hidden; */
}
.input-range:focus {
	outline: none;
	border: none;
	box-shadow: none;
}
.input-range:focus::-webkit-slider-thumb {
	box-shadow: 0 0 1px 1px #1f3b5a91;
}
.input-range::-webkit-slider-thumb {
	width: 5px;
	height: 10px;
	border-radius: 50%;
	background: #ccdceb;
	box-shadow: 0 0 1px 1px #1f3b5a91;
}
.input-range::-moz-range-thumb {
	width: 5px;
	height: 10px;
	border-radius: 50%;
	background:#eee;
}
.input-range::-ms-thumb {
	width: 5px;
	height: 10px;
	border-radius: 50%;
	background:#eee;
}
.input-range::-webkit-slider-runnable-track {
	height: 1px;
	border-radius: 50%;
	background: #203c5c;
	box-shadow: 0 1px 1px 1px #1f3b5a91;
}
</style>
