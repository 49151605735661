import Constants from "./Constants"

/**
 * 點位/設施種類
 */
class Type {
	/**
	 * @param {String} formType 
	 * @param {String} formFeatureType 
	 * @param {String} formFeatureCategory 
	 */
	constructor(formType, formFeatureType, formFeatureCategory) {
		this.formType = formType
		this.formFeatureType = formFeatureType
		this.formFeatureCategory = formFeatureCategory
	}

	static newByObject(types) {
		let [formType, formFeatureType, formFeatureCategory] = [ types.formType, types.formFeatureType, types.formFeatureCategory ]
		return new Type(formType, formFeatureType, formFeatureCategory)
	}

	/**
	 * 是否為設施物的種類（非管線）
	 * @returns {Boolean}
	 */
	isFacilityType() {
		return Type.isFacilityFormTypeText(this.formType)
	}
	/**
	 * $static 是否為設施物的種類text（非管線）
	 * @returns {Boolean}
	 */
	static isFacilityTypeText(typeText) {
		let formType = Type.getFormTypeByText(typeText)
		return Type.isFacilityUtilityCategoryKey(formType.utilityCategory)
	}

	static getFormTypeByText = (typeText) => {
		return Object.values(Constants.TABLE.TYPE).find(type => type.typeText === typeText)
	}

	/**
	 * $static 是否為表單中設施物的種類text（非管線）
	 * @returns {Boolean}
	 */
	static isFacilityFormTypeText(typeText) {
		let formType = Type.getFormTypeByFormText(typeText)
		return Type.isFacilityUtilityCategoryKey(formType.utilityCategory)
	}

	static getFormTypeByFormText = (typeText) => {
		return Object.values(Constants.TABLE.TYPE).find(type => type.text === typeText)
	}

	static isFacilityUtilityCategoryKey(typeKey) {
		return typeKey !== 'PIPELINE'
	}

	/**
	 * APP設施物key
	 */
	getFormTypeKey() {
		let formType = Type.getFormTypeByFormText(this.formType)
		return formType ? formType.key : 'OTHER_FACILITY'
	}
	/**
	 * #private APP設施物型態key
	 */
	#getFormFeatureTypeKey = () => {
		let typeKey = this.getTypeKey()
		return Constants.TABLE.FEATURE_TYPE[typeKey] ? Object.keys(Constants.TABLE.FEATURE_TYPE[typeKey]).find(key => Constants.TABLE.FEATURE_TYPE[typeKey][key].text === this.formFeatureType) : undefined
	}
	/**
	 * #private APP設施物種類key
	 */
	#getFormFeatureCategoryKey = () => {
		let typeKey = this.getTypeKey()
		return Constants.TABLE.FEATURE_CATEGORY[typeKey] ? Object.keys(Constants.TABLE.FEATURE_CATEGORY[typeKey]).find(key => Constants.TABLE.FEATURE_CATEGORY[typeKey][key].text === this.formFeatureCategory) : undefined
	}
	/**
	 * Utility種類
	 * @param string key
	 */
	getType(key=undefined) {
		let formType = Type.getFormTypeByFormText(this.formType)
		return formType && key ? formType[key] : formType
	}
	/**
	 * #private Utility設施型態
	 * @param string key
	 */
	#getFeatureType = (key=undefined) => {
		let typeKey = this.getTypeKey()
		let formFeatureTypeKey = this.#getFormFeatureTypeKey()
		let formFeatureType = formFeatureTypeKey ? Constants.TABLE.FEATURE_TYPE[typeKey][formFeatureTypeKey].utilityFeatureType : undefined
		return formFeatureType && key ? formFeatureType[key] : formFeatureType
	}
	/**
	 * #private Utility設施種類
	 * @param string key
	 */
	#getFeatureCategory = (key=undefined) => {
		let typeKey = this.getTypeKey()
		let formFeatureCategoryKey = this.#getFormFeatureCategoryKey()
		let formFeatureCategory = formFeatureCategoryKey ? Constants.TABLE.FEATURE_CATEGORY[typeKey][formFeatureCategoryKey].utilityFeatureType : undefined
		return formFeatureCategory && key ? formFeatureCategory[key] : formFeatureCategory
	}
	/**
	 * Utility種類key
	 */
	getTypeKey() {
		return this.getType('utilityCategory')
	}

	/**
	 * APP設施物key(formTypeKey)、APP設施物型態key(formFeatureTypeKey)、APP設施物種類key(formFeatureCategoryKey)、
	 * Utility種類key(typeKey)、Utility設施型態key(featureTypeKey)、Utility設施種類key(featureCategoryKey)
	 */
	getKeys() {
		return {
			formTypeKey: this.getFormTypeKey(),
			formFeatureTypeKey: this.#getFormFeatureTypeKey(),
			formFeatureCategoryKey: this.#getFormFeatureCategoryKey(),
			typeKey: this.getTypeKey(),
			// Utility設施型態key
			featureTypeKey: this.#getFeatureType('key'),
			// Utility設施種類key
			featureCategoryKey: this.#getFeatureCategory('key'),
		}
	}

	/**
	 * Utility種類(type)、Utility設施型態(featureType)、Utility設施種類(featureCategory)、Utility種類名稱(category)
	 */
	get() {
		let featureCategory = this.#getFeatureCategory()
		return {
			// Utility種類
			type: this.getFormTypeKey() === 'OTHER_FACILITY' ? this.formType : this.getType('typeText'),
			// Utility種類名稱
			category: this.getType('categoryText'),
			// Utility設施型態(GML值)
			featureType: this.#getFeatureType('value'),
			// Utility設施種類
			featureCategory: featureCategory ? featureCategory.value : (this.getFormTypeKey() === 'OTHER_FACILITY' ? this.formFeatureCategory : (this.getTypeKey() === 'OTHER_FACILITY' ? this.formType : this.formFeatureCategory)),
		}
	}

	/**
	 * Utility種類(type)、Utility設施型態(featureType)、Utility設施種類(featureCategory)
	 * APP設施物(formType)、APP設施物型態(formFeatureType)、APP設施物種類(formFeatureCategory)
	 */
	getTypes() {
		return {
			...this.get(),
			formType: this.formType,
			formFeatureType: this.formFeatureType,
			formFeatureCategory: this.formFeatureCategory
		}
	}

	/**
	 * @param {Type} type1
	 * @param {Type} type2
	 */
	static equals(type1, type2){
		let typeList = [type1, type2]
		return [
			[...new Set(typeList.map(type => type.getTypeKey()))],
			[...new Set(typeList.map(type => type.#getFormFeatureTypeKey()).filter(t => t !== undefined))],
			[...new Set(typeList.map(type => type.#getFormFeatureCategoryKey()).filter(t => t !== undefined))],
		].every(o => o.length <= 1);
	}

	/**
	 * @param {Type[]|Type{}} typeList 
	 */
	static combineTypes(typeList) {
		if(Array.isArray(typeList)) {
			typeList = typeList.reduce((obj, type, index) => {
				obj[index] = type
				return obj
			}, {})
		}
		let types = Object.values(typeList).sortBy(type => type.getType('priority'), 'desc')
		let diff = Object.keys(typeList).filter(key => !Type.equals(types.first, typeList[key]))
		if(diff.length) {
			console.log(Object.keys(typeList))
			throw new Error(`無法合併種類：${Object.keys(typeList).map(key => Number.isNaN(Number(key)) ? `${key}(${typeList[key].toString()})` : typeList[key].toString()).join('、')}`)
		}
		let featureType = types.find(type => type.#getFormFeatureTypeKey() !== undefined)
		let featureCategory = types.find(type => type.#getFormFeatureTypeKey() !== undefined)
		return new Type(types.first.formType, featureType ? featureType.formFeatureType : types.first.formFeatureType, featureCategory ? featureCategory.formFeatureCategory : types.first.formFeatureCategory)
	}

	toString() {
		return [ this.formType, this.formFeatureType, this.formFeatureCategory ].filter(t => t !== undefined).join('-')
	}

	copy() {
		return new Type(this.formType, this.formFeatureType, this.formFeatureCategory)
	}
}

export default Type