<template>
	<component :is="formComponent" @submit="submit" ref="form">
		<slot name="before-inputs"></slot>
		<div class="form-wrapper" :class="wrapperClass">
			<template v-for="(input, key) in inputs">
				<slot :name="`before-input-${input.key}`" :input="input"></slot>
				<template v-if="!checkHide(input.hide, input.key)">
					<input-select :class="input.class" :label="input.label" :label-class="input.labelClass" :size="input.size ? input.size : size" :options="input.options" :keys="input.keys" :placeholder="input.placeholder" :disabled="!!input.disabled" :required="input.required" :state="input.state" :multiple="input.multiple" :emptyOption="input.emptyOption" :hint="input.hint" :hintClass="input.hintClass" v-model="result[`${input.key}`.normalizePath()]" :key="key" v-if="input.type===INPUT_TYPE.SELECT" @change="onEvent('change', $event, input.key)" @input="onEvent('input', $event, input.key)"></input-select>
					<input-checkbox :class="input.class" :label="input.label" :label-class="input.labelClass" :size="input.size ? input.size : size" :options="input.options" :keys="input.keys" :direction="input.direction" :boolean="input.boolean" :disabled="!!input.disabled" :mode="input.mode ? input.mode : 'checkbox'" :hint="input.hint" :hintClass="input.hintClass" v-model="result[`${input.key}`.normalizePath()]" :key="key" v-else-if="input.type===INPUT_TYPE.CHECKBOX" @change="onEvent('change', $event, input.key)" @input="onEvent('input', $event, input.key)"></input-checkbox>
					<input-radio :class="input.class" :label="input.label" :label-class="input.labelClass" :size="input.size ? input.size : size" :options="input.options" :keys="input.keys" :has-other="input.hasOther" :required="input.required" :hint="input.hint" :hintClass="input.hintClass" v-model="result[`${input.key}`.normalizePath()]" :key="key" v-else-if="input.type===INPUT_TYPE.RADIO" @change="onEvent('change', $event, input.key)" @input="onEvent('input', $event, input.key)"></input-radio>
					<input-tags :class="input.class" :label="input.label" :label-class="input.labelClass" :size="input.size ? input.size : size" :options="input.options" :keys="input.keys" :placeholder="input.placeholder" :disabled="!!input.disabled" :required="input.required" :state="input.state" :hint="input.hint" :hintClass="input.hintClass" v-model="result[`${input.key}`.normalizePath()]" :key="key" v-else-if="input.type===INPUT_TYPE.TAGS" @change="onEvent('change', $event, input.key)" @input="onEvent('input', $event, input.key)"></input-tags>
					<input-range :class="input.class" :label="input.label" :label-class="input.labelClass" :size="input.size ? input.size : size" :min="input.min" :max="input.max" :step="input.step" :level="input.level" :logarithmic="input.logarithmic" :hint="input.hint" :hintClass="input.hintClass" v-model="result[`${input.key}`.normalizePath()]" :key="key" v-else-if="input.type===INPUT_TYPE.RANGE" @change="onEvent('change', $event, input.key)" @input="onEvent('input', $event, input.key)"></input-range>
					<input-file :class="input.class" :label="input.label" :label-class="input.labelClass" :size="input.size ? input.size : size" :button="input.button" :placeholder="input.placeholder" :drop-placeholder="input.dropPlaceholder" :accept="input.accept" :disabled="!!input.disabled" :required="input.required" :multiple="input.multiple" :preview="input.preview" :preview-photo-class="input.previewPhotoClass" :hint="input.hint" :hintClass="input.hintClass" v-model="result[`${input.key}`.normalizePath()]" :key="key" v-else-if="input.type===INPUT_TYPE.FILE" @change="onEvent('change', $event, input.key)" @input="onEvent('input', $event, input.key)" @load="onEvent('load', $event, input.key)" @reload="onEvent('reload', $event, input.key)"></input-file>
					<input-text :class="input.class" :label="input.label" :label-class="input.labelClass" :size="input.size ? input.size : size" :type="input.type" :button="input.button" :placeholder="input.placeholder" :iconlabel="input.iconlabel" :pattern="input.pattern" :disabled="!!input.disabled" :required="input.required" :state="input.state" :multiline="input.multiline" :invalid-message="input.invalidMessage" :hint="input.hint" :hintClass="input.hintClass" v-model="result[`${input.key}`.normalizePath()]" :key="key" v-else @change="onEvent('change', $event, input.key)" @input="onEvent('input', $event, input.key)"></input-text>
				</template>
				<slot :name="`after-input-${input.key}`" :input="input"></slot>
			</template>
			<slot name="inputs"></slot>
		</div>
		<div class="px-2">
			<slot name="after-inputs"></slot>
		</div>
		<b-row class="mt-3 justify-content-center">
			<b-button class="form-btn submit-btn" :class="{'size-xs': size==='xs'}" type="submit" variant="primary" v-if="buttons.submit">{{submitText}}</b-button>
			<b-button class="form-btn cancel-btn" :class="{'size-xs': size==='xs'}" type="button" variant="primary" v-if="buttons.cancel" @click="cancel">{{cancelText}}</b-button>
			<b-button class="form-btn clear-btn" :class="{'size-xs': size==='xs'}" type="button" variant="primary" v-if="buttons.clear" @click="clear">{{clearText}}</b-button>
			<slot name="button"></slot>
		</b-row>
	</component>
</template>

<script>
import InputText from '@/components/Input/InputText'
import InputFile from '@/components/Input/InputFile'
import InputSelect from '@/components/Input/InputSelect'
import InputRadio from '@/components/Input/InputRadio'
import InputCheckbox from '@/components/Input/InputCheckbox'
import InputRange from '@/components/Input/InputRange'
import InputTags from '@/components/Input/InputTags'
import { deepCopy } from "@/utils/assist"
export default {
	name: 'InputForm',
	components: {
		InputText,
		InputFile,
		InputSelect,
		InputRadio,
		InputCheckbox,
		InputRange,
		InputTags,
	},
	props: {
		options: {
			type: Array,
			default: () => []
		},
		value: {
			type: Object,
			default: () => { return {} }
		},
		actions: {
			type: Object,
			default: () => { return {} }
		},
		wrapperClass: {
			type: [Object, String],
			default: ''
		},
		useForm: {
			type: Boolean,
			default: true
		},
		size: {
			type: String,
			default: ""
		},
		submitText: {
			type: String,
			default: '確認',
		},
		cancelText: {
			type: String,
			default: '取消',
		},
		clearText: {
			type: String,
			default: '清除',
		},
		deep: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			INPUT_TYPE: {
				TEXT: 'text',
				PASSWORD: 'password',
				EMAIL: 'email',
				NUMBER: 'number',
				URL: 'url',
				TEL: 'tel',
				SEARCH: 'search',
				DATE: 'date',
				DATETIME: 'datetime',
				DATETIME_LOCAL: 'datetime-local',
				MONTH: 'month',
				WEEK: 'week',
				TIME: 'time',
				COLOR: 'color',
				FILE: 'file',
				SELECT: 'select',
				RADIO: 'radio',
				CHECKBOX: 'checkbox',
				RANGE: 'range',
				TAGS: 'tags',
			},
			result: {},
			buttons: {
				submit: true,
				cancel: true,
				clear: false,
			},
			inputs: [],
		}
	},
	watch: {
		value: {
			deep: true,
			handler(value) {
				this.setValue()
			}
		},
		actions: {
			deep: true,
			handler(value) {
				this.setActions()
			}
		},
		options: {
			deep: true,
			handler(value) {
				this.setInputs()
			}
		},
	},
	created() {
		this.setValue();
		this.setActions();
		this.setInputs();
	},
	computed: {
		formComponent() {
			return this.useForm ? 'b-form' : 'div';
		},
		hasActions() {
			return Object.values(this.buttons).some(action => action)
		},
		deserializeResult() {
			return this.deep ? Object.deserialize(this.result) : this.result
		},
	},
	methods: {
		setValue() {
			this.result = {
				...this.deep ? Object.serialize(this.result) : this.result,
				...this.deep ? Object.serialize(this.value) : this.value,
			}
		},
		setActions() {
			this.buttons = {
				...this.buttons,
				...this.actions,
			}
		},
		setInputs() {
			this.inputs = this.options.map(opt => {
				return Object.keys(opt).reduce((obj, key) => {
					obj[key] = typeof opt[key] === 'function' && key !== 'hide' ? opt[key]() : opt[key];
					return obj;
				}, {})
			});
		},
		submit(e) {
			this.$emit('submit', e)
		},
		cancel() {
			this.$emit('cancel', this.deserializeResult)
		},
		clear() {
			this.$emit('clear', this.deserializeResult)
		},
		onEvent(event, e, key) {
			let eventTitle = event.toTitleCase()
			this.$emit(`option${eventTitle}`, key, e)
			this.$emit(event, this.deserializeResult)
			let input = this.inputs.find(input => input.key === key)
			let handler = input && input[`on${eventTitle}`] ? input[`on${eventTitle}`] : (() => {})
			handler(e)
		},
		checkHide(hide, key) {
			if(!hide) return false;
			if(typeof hide === 'function') return hide(this.result[`${key}`.normalizePath()], key, this.result);
			return hide
		},
		reportValidity() {
			if(this.$refs.form.tagName === 'FORM')
				return this.$refs.form.reportValidity()
		}
	}
}
</script>

<style scoped>
.form-wrapper {
	padding: 0 1rem 0 1rem;
}
.form-wrapper > * {
	padding: .25rem 0 .25rem 0;
}
</style>
