import Line from "./Line";
import Point from "./Point";

/**
 * 設施物集合
 * @prototype Array
 */
class FeatureCollection{
	/**
	 * @var {Feature[]} 
	 */
	constructor(generator) {
		/**
		 * @var GmlGenerator
		 */
		this.generator = generator
	}
	/**
	 * 此集合內所有管線
	 * @returns {Feature[]} 
	 */
	getLines() {
		return this.filter(feature => feature instanceof Line)
	}
	/**
	 * 此集合內所有點
	 * @returns {Feature[]} 
	 */
	getPoints() {
		return this.filter(feature => feature instanceof Point)
	}

	/**
	 * 此集合所有設施物轉換為Utility
	 * @returns {Utility[]}
	 */
	getUtilities(meta, rules=[]) {
		return this.map(feature => feature.toUtilities(meta, rules)).flat()
	}

	/**
	 * @param {int} featureIndex 
	 */
	getByIndex(featureIndex) {
		return this.find(feature => feature.index === featureIndex)
	}
	/**
	 * 移除
	 * @param {int} featureIndex 
	 */
	removeByIndex(featureIndex) {
		let index = this.findIndex(feature => feature.index === featureIndex)
		this.splice(index, 1)
	}

	/**
	 * 整理/合併管線
	 */
	rearrangeLines() {
		let lines = this.getLines();
		let connectedLines = {}
		let connections = lines.map(line => {
			return lines.filter(l => l.index !== line.index && line.checkConnectionSide(l)).map(l => {
				return {
					lineIndex: line.index > l.index ? l.index : line.index,
					connectedLineIndex: line.index > l.index ? line.index : l.index,
					priority: l.getConnectionPriority(line),
				}
			})
		}).flat().sortBy('priority', 'desc')
		if(connections.length) {
			let connection = connections.first
			let line = this.getByIndex(connection.lineIndex)
			let connectedLine = this.getByIndex(connection.connectedLineIndex)
			if(line && connectedLine && line.checkConnectionSide(connectedLine)) {
				line.connectLine(connectedLine)
				connectedLines[connectedLine.index] = line.index
				this.removeByIndex(connectedLine.index)
			}
			this.rearrangeLines()
		}
	}
}
FeatureCollection.prototype.__proto__ = Array.prototype;
Object.defineProperties(FeatureCollection.prototype, {
	generator: {
		writable: true,
		enumerable: false,
		configurable: false,
	},
});

export default FeatureCollection
