<template>
	<loading class="info-wrapper" v-if="loading"/>
	<div v-else>
		<b-card no-body class="border-0 control-board">
			<b-card-body class="p-0">
				<b-tabs class="tabs-wrapper" fill active-nav-item-class="tab-title font-weight-bold" active-tab-class="tab-title" fluid>
					<b-tab class="tab-body" title="新增案件" active>
						<project-creator title="新增案件" :group="group" :projectDataTypes="projectDataTypes"></project-creator>
					</b-tab>
					<!-- <b-tab class="tab-body" title="由施工系統匯入" v-if="$store.getters.isDeveloper">
						<cms-project-creator :group="group" :groupID="groupID" :projectDataTypes="projectDataTypes"></cms-project-creator>
					</b-tab> -->
				</b-tabs>
			</b-card-body>
		</b-card>
	</div>
</template>


<script>
import ProjectCreator from '@/components/Project/Creator/ProjectCreator.vue'
import CmsProjectCreator from '@/components/Project/Creator/CmsProjectCreator.vue'
import Loading from '@/components/Loading/Loading.vue';
import { checkAllTrue } from '@/utils/assist';

export default {
	components: {
		ProjectCreator,
		CmsProjectCreator,
		Loading,
	},
	props: {
		groupID: {
			required: true,
			default: 0
		},
		group: {
			type: Object
		}
	},
	data() {
		return {
			group_id: Number.isInteger(parseInt(this.groupID)) ? parseInt(this.groupID) : 0,
			call: { getProjectDataType: false },
			loading: true,
			projectDataTypes: [],
		}
	},
	created() {
		if(typeof this.group === 'undefined') {
			this.reload()
		}
		else if(!this.group_id) {
			// TODO
			this.$router.replace({name: '專案', params: {groupID: this.group_id, from: {name: '專案列表'}}});
		}
		else {
			this.init()
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
	},
	methods: {
		reload() {
			this.$router.replace({name: '專案', params: {groupID: this.group_id, from: {name: '新增專案案件'}}});
		},
		init() {
			this.getProjectDataType()
		},
		getProjectDataType() {
			this.call.getProjectDataType = false
			this.$axios.getProjectDataType((response) => {
				this.projectDataTypes = response.data
				this.call.getProjectDataType = true
			},(error) => {
				this.call.getProjectDataType = true
			})
		},
	}
}
</script>

<style scoped>
.card {
	height: 100%;
}
.tab-body {
	padding: .5rem 1rem;
	border: 1px solid #dee2e6;
	border-top: 0;
	height: 100%;
}
.tabs-wrapper {
	height: 100%;
}
.tabs-wrapper > .tab-content {
	height: 100%;
}
</style>
