<template>
	<div>
		<div class="loading" v-if="loading" :style="`background-image:url(${loading_img_url})`"></div>
		<div class="gmap-wrapper" v-show="!loading">
			<div class="gmap-control-wrapper address-box-wrapper" v-if="configs.searchingAddress && map.geocoder">
				<b-row class="col-12 align-items-center" style="height:100%; padding:.5rem">
					<b-form-input class="address-box" v-model="map.address" @change="geocodeAddress"></b-form-input>
					<b-button class="address-box-btn" @click="geocodeAddress(map.address)"><i class="fa fa-search"></i></b-button>
				</b-row>
			</div>
			<div ref="map" class="gmap"></div>
		</div>
	</div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'
import { oneOf, deepSet } from "@/utils/assist"
import loading_img from '@/assets/images/loading.gif';

export default {
	name: 'GoogleMap',
	components:{
	},
	props: {
		center: {
			type: Object,
			validator (value) {
				return value.lat && value.lng;
			},
			default() {
				return {
					lat: 25.0325917,
					lng: 121.5624999,
				}
			}
		},
		zoom: {
			type: Number,
			default: 15
		},
		options: {
			type: Object,
			default: () => { return {} }
		},
		markers: {
			type: Array,
			default() {
				return []
			}
		},
		configs: {
			type: Object,
			validator(value) {
				let res = true;
				if(value.drawing) {
					res &= oneOf(value.drawingMode, ['marker', 'polygon', 'polyline', 'rectangle', 'circle'])
				}
				return res
			},
		},
		overlays: {
			type: Array,
			default() { return [] }
		},
		address: {
			type: String,
		},
		modes: {
			type: Object,
			default() { return {} }
		}
	},
	data() {
		return {
			// 地圖是否載入完成
			complete: false,
			loading: true,
			loading_img_url: loading_img,
			map: {
				mapObject: null,
				markers: [],
				overlays: [],
				center: { lat: 25.0325917, lng: 121.5624999 },
				zoom: 15,
				options: {
					zoomControl: true,
					mapTypeControl: true,
					scaleControl: true,
					streetViewControl: true,
					rotateControl: true,
					fullscreenControl: true,
					disableDefaultUi: false,
					styles: undefined
				},
				drawing: false,
				drawingManager: null,
				drawingOptions: {},
				address: '',
			},
			infoWindow: {
				pos: { lat: 25.0325917, lng: 121.5624999 },
				open: false,
				options: {
					pixelOffset: { width: 0, height: -40 }
				},
				content: ""
			},
			overlayConfigs: {
				options: {},
				editable: false,
				draggable: false,
				click: () => {},
				dblclick: () => {},
				drag: () => {},
				dragend: () => {},
				dragstart: () => {},
				mousedown: () => {},
				mousemove: () => {},
				mouseout: () => {},
				mouseover: () => {},
				mouseup: () => {},
				rightclick: () => {},
			},
			controls: {},
			OPTIONS: {
				STYLES: {
					night: [
						{ elementType: 'geometry', stylers: [{color: '#242f3e'}] },
						{ elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}] },
						{ elementType: 'labels.text.fill', stylers: [{color: '#746855'}] },
						{
							featureType: 'administrative.locality',
							elementType: 'labels.text.fill',
							stylers: [{color: '#d59563'}]
						},
						{
							featureType: 'poi',
							elementType: 'labels.text.fill',
							stylers: [{color: '#d59563'}]
						},
						{
							featureType: 'poi.park',
							elementType: 'geometry',
							stylers: [{color: '#263c3f'}]
						},
						{
							featureType: 'poi.park',
							elementType: 'labels.text.fill',
							stylers: [{color: '#6b9a76'}]
						},
						{
							featureType: 'road',
							elementType: 'geometry',
							stylers: [{color: '#38414e'}]
						},
						{
							featureType: 'road',
							elementType: 'geometry.stroke',
							stylers: [{color: '#212a37'}]
						},
						{
							featureType: 'road',
							elementType: 'labels.text.fill',
							stylers: [{color: '#9ca5b3'}]
						},
						{
							featureType: 'road.highway',
							elementType: 'geometry',
							stylers: [{color: '#746855'}]
						},
						{
							featureType: 'road.highway',
							elementType: 'geometry.stroke',
							stylers: [{color: '#1f2835'}]
						},
						{
							featureType: 'road.highway',
							elementType: 'labels.text.fill',
							stylers: [{color: '#f3d19c'}]
						},
						{
							featureType: 'transit',
							elementType: 'geometry',
							stylers: [{color: '#2f3948'}]
						},
						{
							featureType: 'transit.station',
							elementType: 'labels.text.fill',
							stylers: [{color: '#d59563'}]
						},
						{
							featureType: 'water',
							elementType: 'geometry',
							stylers: [{color: '#17263c'}]
						},
						{
							featureType: 'water',
							elementType: 'labels.text.fill',
							stylers: [{color: '#515c6d'}]
						},
						{
							featureType: 'water',
							elementType: 'labels.text.stroke',
							stylers: [{color: '#17263c'}]
						}
					],
					hideBussiness: [
						{ featureType: 'poi.business', stylers: [{visibility: 'off'}] },
						{ featureType: 'poi.medical', stylers: [{visibility: 'off'}] },
						{ featureType: 'poi.place_of_worship', stylers: [{visibility: 'off'}] },
						{ featureType: 'poi.sports_complex', stylers: [{visibility: 'off'}] },
					],
					hideMarkers: [
						{ featureType: 'poi.attraction', stylers: [{visibility: 'off'}] },
						{ featureType: 'poi.business', stylers: [{visibility: 'off'}] },
						{ featureType: 'poi.government', stylers: [{visibility: 'off'}] },
						{ featureType: 'poi.medical', stylers: [{visibility: 'off'}] },
						{ featureType: 'poi.park', stylers: [{visibility: 'off'}] },
						{ featureType: 'poi.place_of_worship', stylers: [{visibility: 'off'}] },
						{ featureType: 'poi.school', stylers: [{visibility: 'off'}] },
						{ featureType: 'poi.sports_complex', stylers: [{visibility: 'off'}] },
					],
					hideTransitions: [
						{ featureType: 'transit.line', stylers: [{visibility: 'off'}] },
						{ featureType: 'transit.station', stylers: [{visibility: 'off'}] },
						{ featureType: 'transit.station.airport', stylers: [{visibility: 'off'}] },
						{ featureType: 'transit.station.bus', stylers: [{visibility: 'off'}] },
						{ featureType: 'transit.station.rail', stylers: [{visibility: 'off'}] },
					]
				},
				OPTION_KEYS: [ "backgroundColor", "center", "clickableIcons", "controlSize", "disableDefaultUI", "disableDoubleClickZoom", "draggable", "draggableCursor", "draggingCursor", "fullscreenControl", "fullscreenControlOptions", "gestureHandling", "heading", "keyboardShortcuts", "mapTypeControl", "mapTypeControlOptions", "mapTypeId", "maxZoom", "minZoom", "noClear", "panControl", "panControlOptions", "restriction", "rotateControl", "rotateControlOptions", "scaleControl", "scaleControlOptions", "scrollwheel", "streetView", "streetViewControl", "streetViewControlOptions", "styles", "tilt", "zoom", "zoomControl", "zoomControlOptions" ],

			}
		}
	},
	watch: {
		// 初始化，確認google api已載入
		google: {
			handler(value) {
				if(value && !this.complete) {
					this.initMap();
				}
			}
		},
		modes: {
			deep: true,
			handler(modes) {
				this.setMapStyles()
			}
		},
		center: {
			deep: true,
			handler(value) {
				this.setMapCenter();
			}
		},
		zoom: {
			deep: true,
			handler(value) {
				this.setMapZoom();
			}
		},
		options: {
			deep: true,
			handler(value) {
				this.setMapOptions();
			}
		},
		markers: {
			deep: true,
			handler(value) {
				this.initMarkers();
			}
		},
		'map.center': {
			deep: true,
			handler(value) {
				if(!this.complete) return;
				this.map.mapObject.setCenter(value); 
			}
		},
		'map.zoom': {
			deep: true,
			handler(value) {
				if(!this.complete) return;
				this.map.mapObject.setZoom(value); 
			}
		},
		'map.options': {
			deep: true,
			handler(value) {
				if(!this.complete) return;
				this.map.mapObject.setOptions(value); 
			}
		},
	},
	computed: {
		google: gmapApi,
		mapStyles() {
			let style = []
			Object.keys(this.modes).forEach(key => {
				if(this.modes[key] && typeof this.OPTIONS.STYLES[key] !== "undefined") {
					style = [...style, ...this.OPTIONS.STYLES[key]]
				}
			})
			return style
		},
	},
	created() {
		this.setMap();
	},
	mounted() {
		if(this.google) {
			this.initMap();
		}
	},
	methods:{
		setMap() {
			this.setMapCenter();
			this.setMapZoom();
			this.setMapOptions();
			this.setMapStyles()
		},
		setMapCenter(center=this.center) {
			this.$set(this.map, "center", center);
		},
		setMapZoom(zoom=this.zoom) {
			this.$set(this.map, "zoom", zoom);
		},
		setMapOptions(options=this.options) {
			this.$set(this.map, "options", {
				...this.map.options,
				...Object.keys(options).filter(key => this.OPTIONS.OPTION_KEYS.includes(key)).reduce((obj, key) => {
					obj[key] = options[key];
					return obj;
				}, {})
			});
		},
		setMapStyles() {
			this.$set(this.map.options, "styles", this.mapStyles)
			if(!this.complete) return
			this.map.mapObject.mapTypes.set('styled_map', new this.google.maps.StyledMapType(this.mapStyles));
		},
		initMap() {
			this.map.mapObject = new this.google.maps.Map(this.$refs.map, {
				center: this.map.center,
				zoom: this.map.zoom,
				...this.map.options,
				styles: this.mapStyles,
			});
			let vueComponent = this;
			this.google.maps.event.addListenerOnce(this.map.mapObject, 'idle', function() {
				vueComponent.complete = true;
				vueComponent.initMarkers();
				vueComponent.loading = false;
			});
		},
		initMarkers() {
			if(!this.complete) return
			this.map.markers = this.markers.map(marker => {
				let marker_object =  new this.google.maps.Marker({
					position: marker.position,
					icon: marker.icon,
					draggable: marker.draggable,
					map: this.map.mapObject
				});
				// marker_object
				return marker_object;
			});
			this.viewAllMarkers()
		},
		viewAllMarkers() {
			if(!this.complete || !this.markers.length) return
			var group = new this.google.maps.LatLngBounds();
			if(this.markers.length <= 1){
				// this.map.mapObject.setZoom(16);
				this.map.mapObject.setCenter(this.markers[0].position);
				return;
			}
			this.markers.forEach((marker)=>{
				group.extend(marker.position);
			});
			this.map.mapObject.fitBounds(group);
		},
	}
}
</script>

<style scoped>
.gmap-wrapper, .gmap {
	width: 100%;
	height: 100%;
}
li {
	list-style: none;
}
.gmap-control-wrapper {
	position: absolute;
	z-index: 3;
	margin: 10px;
	border-radius: 2px;
	background-color: white;
	box-shadow: 0px 1px 1.5px 1px #ccc5, 0px .5px 1px 1px #eee9;
	height: 40px;
	font-size: 1rem;
}
.address-box-wrapper {
	right: 50px;
	min-width: 200px;
	width: calc(100% - 175px - 85px);
	max-width: 250px;
}
.address-box {
	width: 100%;
	height: 100%;
	border: none;
	flex: 1 1;
	padding: 0 .25rem;
}
.address-box:focus {
	box-shadow: none;
}
.address-box-btn {
	border: none;
	background: transparent;
	flex: 0 0 1rem;
	color: black;
}
.address-box-btn:focus {
	box-shadow: none;
}

.loading {
	width: 100%;
	height: 100%;
	/* background-color: transparent; */
	background-repeat: no-repeat;
	background-size: 50px;
	background-position: center;
	min-height: 200px;
}
</style>