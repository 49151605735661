<template>
	<div>
		<div class="pointer d-flex justify-content-between align-items-center collapsed-icon" :class="{collapsed: show, 'not-collapsed': !show}" @click="show = !show">
			<b class="mx-1">{{title}}篩選</b>
		</div>
		<b-collapse v-model="show">
			<b-form-checkbox-group class="checkbox-group-wrapper stacked" v-model="model" switches stacked @change="change" @input="input">
				<b-form-checkbox class="d-flex align-items-center py-1" v-for="(option, key) in options" :key="key" :value="option.value">
					<img class="checkbox-icon" :src="option.icon" v-if="option.icon"> {{ option.text }}
					<b-badge :variant="model.includes(option.value) ? 'primary' : 'secondary'" pill>{{option.count}}</b-badge>
				</b-form-checkbox>
			</b-form-checkbox-group>
		</b-collapse>
	</div>
</template>

<script>
import InputCheckbox from '@/components/Input/InputCheckbox'

export default {
	name: 'GmapFilter',
	components:{
		InputCheckbox,
	},
	props: {
		title: {
			type: String,
			default: ''
		},
		options: {
			type: Array,
			default: () => [],
		},
		value: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			model: this.value,
			show: true,
		}
	},
	watch: {
		value: {
			deep: true,
			handler(value) {
				this.setValue(value)
			}
		},
		show() {
		}
	},
	created() {
	},
	mounted() {
	},
	computed: {
	},
	methods: {
		setValue(value) {
			this.$set(this, 'model', value)
		},
		input(e) {
			this.$emit('input', e)
		},
		change(e) {
			this.$emit('change', e)
		},
	},
}
</script>

<style scoped>
img.checkbox-icon {
	max-width: 100%;
	max-height: 1.2rem;
}
</style>
