<template>
	<loading v-if="loading"/>
	<input-form class="p-0 col-12" :options="formOptions" :actions="{submit:false,cancel:false}" v-model="data" :deep="true" v-else></input-form>
</template>

<script>
import InputText from '@/components/Input/InputText';
import InputForm from '@/components/Input/InputForm';
import Loading from '@/components/Loading/Loading.vue'
import { deepCopy, isEmpty, sortArray, checkAllTrue } from '@/utils/assist';

export default {
	name: 'ProjectFilter',
	components:{
		InputText,
		InputForm,
		Loading
	},
	props: {
		value: {
			type: Object,
			default: () => { return {}; }
		},
		dataKey: {
			type: String,
			default: 'created_at'
		},
		label: {
			type: String,
			default: '派工日期'
		},
	},
	data() {
		return {
			data: {},
			call: {},
			loading: true,
		}
	},
	created() {
		this.data = Object.merge(this.defaultData, this.value)
	},
	destroyed() {
	},
	mounted() {
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		value: {
			deep: true,
			handler(value) {
				this.data = value
			}
		},
		data: {
			deep: true,
			handler(value) {
				this.$emit('change', value)
				this.$emit('input', value)
			}
		},
	},
	computed: {
		formOptions() {
			return [
				{ key: `${this.dataKey}[0]`, type: "date", label: this.label, required: true, size: 'xs' },
				{ key: `${this.dataKey}[1]`, type: "date", label: "-", required: true, size: 'xs' },
			]
		},
		defaultData() {
			let data = {}
			data[this.dataKey] = [
				new Date().format('yyyy-MM-dd'),
				new Date().format('yyyy-MM-dd'),
			]
			return data
		},
	},
	methods:{
	}
}
</script>

<style scoped>
.input {
	padding: 0 .5rem .5rem 1rem;
}
</style>