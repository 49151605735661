<template>
	<frame-page class="full-iframe" :url="url" allow="geolocation"></frame-page>
</template>

<script>
import FramePage from '@/components/Frame/FramePage.vue'
export default {
	name: 'ProjectMapEditor',
	components:{
		FramePage,
	},
	props: {
	},
	data() {
		return {
			siteID: Number.isInteger(parseInt(this.$route.params.siteID)) ? parseInt(this.$route.params.siteID) : -1,
			editorBaseUrl: process.env.VUE_APP_MAP_TOOL_URL,
		}
	},
	watch: {
	},
	created() {
	},
	computed: {
		url() {
			return `${this.editorBaseUrl}?creator_id=${this.$store.getters.currentUser.id}&construction_site_id=${this.siteID}`
		}
	},
	methods:{
	}
}
</script>

<style scoped>
.full-iframe {
	height: 100vh !important;
}
</style>