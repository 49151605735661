<template>
    <div>
		<loading mode="fixed" image="1" size="lg" v-if="loading"/>
        <input-form class="p-0 col-12" :options="formOptions" :actions="{cancel:false}" v-model="result" @submit="onSubmit"></input-form>
    </div>
</template>

<script>
import InputForm from '@/components/Input/InputForm';
import Loading from '@/components/Loading/Loading.vue';
import { checkAllTrue } from '@/utils/assist';

export default {
	name: 'AppPackageUploader',
	components:{
		InputForm,
		Loading
	},
	props: {
	},
	data() {
		return {
			call: {},
			loading: true,
			result: {},
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		result: {
			deep: true,
			handler(value) {
			}
		},
	},
	created() {
	},
	mounted() {
	},
	computed: {
		formOptions() {
            let system = this.$store.getters.enum('app.system.android')
            let extension = system.data.extension
			return [
				{ key: "content", type: "file", label: "檔案", accept: `.${extension}`, required: true, size: 'xs' },
				{ key: "version", type: "text", label: "版本號", pattern: "^[\\d\\.]+$", invalidMessage: '請輸入數字及小數點', required: true, size: 'xs' },
				{ key: "note", type: "text", multiline: true, label: "版本內容", required: true, size: 'xs' },
			]
		}
	},
	methods:{
		onSubmit(evt) {
			evt.preventDefault();
			this.$set(this.call, 'uploadAppPackage', false)
			this.$axios.uploadAppPackage(this.result, (response) => {
				this.$emit('submit')
				alert('更新成功')
				this.call.uploadAppPackage = true;
			}, (error) => {
				this.call.uploadAppPackage = true;
			})
		},
	}
}
</script>
