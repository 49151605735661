const configs = {
	list_csv: {
		name: '案件列表csv',
	},
	list_excel: {
		name: 'excel清冊',
	},
	detail_list_excel: {
		name: '案件清冊',
	},
	tpw_daily_received_excel: {
		name: '每日收件檢核表',
	},
	tpw_project_implementation_excel: {
		name: '專案執行狀況',
	},
	tpw_report_submitted_excel: {
		name: '報告繳交紀錄',
	},
	tpw_gml2_uncommitted_excel: {
		name: '尚未收到GML2清冊',
	},
	tpw_paved_information_excel: {
		name: '銑鋪資料清冊',
	},
}

const commonExports = {
	list: [
		'list_csv',
		'list_excel',
		'detail_list_excel',
	],
}

const defaultExports = []

const exports = {
	// 北水
	taipei_water: {
		list: [
			'tpw_daily_received_excel',
			'tpw_project_implementation_excel',
			'tpw_report_submitted_excel',
			'tpw_gml2_uncommitted_excel',
			'tpw_paved_information_excel',
		]
	},
}

const ExportConfigs = {
	configs: configs,
	exports: {
		common: commonExports,
		default: defaultExports,
		projects: exports,
	},
}

export default ExportConfigs;

