<template>
	<div class="item-wrapper" v-if="!link.hide">
		<li class="nav-item disable-select" :class="{icononly: icononly}">
			<div class="nav-group" v-if="link.type === 'group'">
				<hr class="nav-divider">
				<div v-b-toggle="link.name" class="nav-group-title-wrapper w-100">
					<div class="w-100">
						<i class="nav-group-title-icon fa-fw" :class="link.icon"></i>
						<span class="nav-group-title">{{ link.name }}</span>
					</div>
				</div>
				<b-collapse class="nav-item nav-child-item" :class="{icononly: icononly}" :id="link.name">
					<side-bar-item :link="item" :icononly="icononly" v-for="(item, key) in link.children" :key="key"/>
				</b-collapse>
			</div>
			<div class="nav-link-wrapper hasdrop" v-else-if="link.children">
				<div class="nav-link" @click="openChildList">
					<i class="item-icon fa-fw" :class="link.icon"></i>
					<span class="nav-link-text">{{ link.name }}</span>
				</div>
				<div class="nav-item nav-child-item" :class="{icononly: icononly}" v-show="open && !icononly">
					<side-bar-item :link="item" :icononly="icononly" v-for="(item, key) in link.children" :key="key"/>
				</div>
			</div>
			<router-link :to="link.path" @click.native="linkClick" class="nav-link" :target="link.target" v-else>
				<i class="item-icon fa-fw" :class="link.icon"></i>
				<font class="nav-link-text">{{ link.name }}</font>
			</router-link>
		</li>
	</div>
</template>
<script>
	export default {
		name: 'SideBarItem',
		props: {
			link: {
				type: Object,
				default: () => {
					return {
						name: '',
						path: '',
						children: []
					};
				}
			},
			icononly: {
				type: Boolean,
				default: false
			},
		},
		inject: {
			autoClose: {
				default: true
			}
		},
		data() {
			return {
				collapsed: true,
				open: false
			};
		},
		methods: {
			linkClick() {
				if (
					this.autoClose &&
					this.$sidebar &&
					this.$sidebar.showSidebar === true
				) {
					this.$sidebar.displaySidebar(false);
				}
			},
			openChildList() {
				this.open = !this.open
			}
		}
	};
</script>


<style scoped>
.item-wrapper {
	text-align: left;
}
.nav-item {
	list-style: none;
	line-height: 3rem;
}
.nav-item.icononly > .nav-link-wrapper.hasdrop:after {
	content: unset;
}
.nav-link-wrapper.hasdrop:after {
	/*content: "\f35a";
	position: absolute;
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 900;
	right: 1rem;*/
}
.nav-child-item.icononly {
	margin-left: 0;
}
.nav-child-item {
	margin-left: 1rem;
	font-size: .95em;
}
.nav-group {
	color: inherit;
	padding: 0 1rem;
}
.nav-group-title-wrapper {
	display: flex;
	align-items: center;
}
.nav-group-title-wrapper > .nav-group-title-icon, .nav-group > .nav-group-title-wrapper {
	/* font-size: .9rem;
	opacity: .9; */
}
.nav-divider {
	border-color: inherit;
	margin-bottom: 0;
	opacity: .3;
}
.nav-link {
	padding: 0.25rem 1rem;
	color: inherit;
}
.nav-link:hover {
	background-color: rgba(255, 255, 255, 0.3);
	cursor: pointer;
}
.nav-group-title-icon, .item-icon {
	margin-right: 5px;
}
.icononly .nav-divider {
	margin-right: .5rem;
	margin-left: .5rem;
}
.icononly .nav-child-item {
	display: none;
}
.icononly .nav-group {
	padding-left: 0;
	padding-right: 0;
}
.icononly {
	text-align: center;
}
.icononly .nav-link-text, .icononly .nav-group-title {
	display: none;
}
.icononly .item-icon, .icononly .nav-group-title-icon {
	font-size: 1.5rem;
}
</style>