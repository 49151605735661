<template>
	<div>
		<alert :errors="errorPoints" :warnings="warningPoints"></alert>
		<bv-table :columns="table.columns" :detail-columns="table.detailColumns" :data="site.four_pitch_data" :configs="table.configs"></bv-table>
	</div>
</template>

<script>
import PointList from '@/components/Project/Result/Point/PointList.vue'
import Loading from '@/components/Loading/Loading.vue';
import BvTable from '@/components/Table/BvTable.vue'
import Alert from '@/components/Alert/Alert.vue';
import { checkAllTrue } from '@/utils/assist';

export default {
	name: 'ResultFourPitchPointList',
	components: {
		PointList,
		BvTable,
		Alert,
		Loading
	},
	props: {
		site: {
			type: Object,
			default: () => { return {} }
		},
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			call: {},
			loading: false,
			pointValidator: {
				errors: [
				],
				warnings: [
					{
						key: 'fourPitchPointsStatus',
						validator: pt => !pt.done,
						title: "四支距點位尚未測量完成，請至北水巡查系統查詢",
						messager: point => `${point.point_name}（${point.type_text}）`
					},
				]
			},
			table: {
				detailColumns: [
					{ key: 'id', label: 'ID', hide: !this.$store.getters.isDeveloper},
					{ key: 'point_name', label: '名稱'},
					{ key: 'type_text', label: '種類'},
					{ key: 'point_status', label: '狀態'},
					{ key: 'point_1_distance', label: '參考點1距離'},
					{ key: 'point_2_distance', label: '參考點2距離'},
					{ key: 'point_3_x_distance', label: '參考點3X距離'},
					{ key: 'point_3_y_distance', label: '參考點3Y距離'},
				],
				columns: [
					{ key: 'id', label: 'ID', hide: !this.$store.getters.isDeveloper},
					{ key: 'point_name', label: '名稱'},
					{ key: 'type_text', label: '種類'},
					{ key: 'point_status', label: '狀態'},
					{ key: 'point_1_distance', label: '參考點1距離'},
					{ key: 'point_2_distance', label: '參考點2距離'},
					{ key: 'point_3_x_distance', label: '參考點3X距離'},
					{ key: 'point_3_y_distance', label: '參考點3Y距離'},
				],
				configs: {
					rowDetail: true,
					perPage: 10
				}
			},
		}
	},
	created() {
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	mounted() {
	},
	computed: {
		errorPoints() {
			return this.pointValidator.errors.map(error => {
				let points = this.site.four_pitch_data.filter(error.validator)
				return points.length ? {
					...error,
					messages: error.messager ? points.map(pt => {
						return typeof error.messager === 'function' ? error.messager(pt) : error.messager
					}) : ''
				} : undefined
			}).filter(e => e)
		},
		warningPoints() {
			return this.pointValidator.warnings.map(error => {
				let points = this.site.four_pitch_data.filter(error.validator)
				return points.length ? {
					...error,
					messages: error.messager ? points.map(pt => {
						return typeof error.messager === 'function' ? error.messager(pt) : error.messager
					}) : ''
				} : undefined
			}).filter(e => e)
		},
	},
	methods:{
		reload() { this.$emit('reload') },
		load(loading) {
			this.loading = loading
		},
	}
}
</script>

<style scoped>
</style>
