<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>

export default {
	name: 'app',
	components: {
	},
	data() {
		return {
		}
	},
	watch: {
	},
	created() {
	},
	mounted () {
	},
	computed: {
	},
	methods: {
	}
}
</script>

<style>
	@import './app.css';
</style>
<style>
	@import './assets/css/bv-style.css';
</style>
<style>
	@import './assets/css/gmap-style.css';
</style>
<style>
.main-wrapper {
	height: 100%;
	display: flex;
	flex-direction: row;
}
.main-wrapper * {
}
#Header {
	width: 100%;
}
.main-content {
	/* padding: 1em; */
}
.animate-left {
	position: relative;
	animation: animateleft 0.4s;
}
@keyframes animateleft {
	from {
		left: -100%;
		opacity: 0;
	}
	to {
		left: 0;
		opacity: 1;
	}
}

@media (min-width:992px) {
	.sidebar-wrapper {
		flex: 0 0 4em;
	}
	.content-wrapper {
		width: calc(100% - 4em);
		width: -webkit-calc(100% - 4em);
		width: -moz-calc(100% - 4em);
		width: -o-calc(100% - 4em);
	}
}
.content-wrapper {
	flex: 1 1;
	padding: 0;
}
.nav-breadcrumb {
	background-color: transparent;
	padding: 1rem 5%;
}

</style>
