<template>
	<div>
		<form ref="form">
			<b-card class="my-1" no-body v-for="(reports, key) in Object.values(unitReports.groupBy('unit_type_key'))" :key="key">
				<b-card-body class="p-lg-2 p-1">
					<b>{{reports.first.unit_type}}</b>
					<div class="px-lg-3 px-1" v-for="(report, index) in reports.sortBy('project_data_type.id')" :key="report.id">
						<hr v-if="index"/>
						<b-row class="justify-content-between">
							[ {{report.project_data_type.name}} ]
							<button class="btn" type="button" v-if="report.rejections.length" @click="showModal(report)"><i class="fa-fw far fa-comment" aria-hidden="true"></i> 歷史退件紀錄</button>
						</b-row>
						<input-form class="px-lg-3 px-1" :options="getReportFormOptions(report)" v-model="formData" :actions="{submit: false, cancel:false}" :use-form="false" deep></input-form>
					</div>
				</b-card-body>
			</b-card>
		</form>
		<modal v-model="modal.show" centered :title="`${modal.data.unit_type}報告歷史退件紀錄`" hideFooter v-if="modal.show">
			<h6>{{modal.data.project_data_type.name}}</h6>
			<b-list-group>
				<b-list-group-item class="d-flex" v-for="(rejection, key) in modal.data.rejections" :key="key">
					<b class="col-1 p-0 pr-1">#{{modal.data.rejections.length - key}}</b>
					<div>
						<b>{{rejection.created_at}}</b>
						<div>備註：{{rejection.note}}</div>
					</div>
				</b-list-group-item>
			</b-list-group>
		</modal>
	</div>
</template>

<script>
import InputCheckbox from '@/components/Input/InputCheckbox'
import InputForm from '@/components/Input/InputForm'
import Modal from '@/components/Modal/Modal'
export default {
	name: 'ProjectUnitReportEditor',
	components:{
		InputCheckbox,
		InputForm,
		Modal,
	},
	props: {
		group: {
			type: Object,
		},
		permission: {
			type: Object,
		},
		project: {
			type: Object,
		},
		unitReports: {
			type: Array,
		},
		value: {
			type: Object,
			default: () => { return {} }
		},
	},
	data() {
		return {
			unitReportStatuses: this.$store.getters.enum('project_unit_report.status').keyBy('key'),
			formData: {},
			modal: {
				show: false,
				data: {},
			},
		}
	},
	created() {
		this.setFromData()
	},
	destroyed() {
	},
	mounted() {
	},
	watch: {
		formData: {
			deep: true,
			handler() {
				this.$emit('input', this.submittedFormData)
			},
		},
	},
	computed: {
		submittedFormData() {
			let getAction = (data) => {
				if((data.is_submitted && !data.status.data.is_submitted) || (data.is_rejected && data.resubmit)) return this.unitReportStatuses.submitted
				if(data.is_checked && !data.status.data.is_checked) return this.unitReportStatuses[data.check_type]
				return undefined
			}
			const actions = {
				submitted: [ 'action', 'last_submitted_at' ],
				pass: [ 'action', 'pass_at' ],
				rejected: [ 'action', 'note' ],
			}
			let changedFormData = Object.filter(Object.map(this.formData, data => {
				return { ...data, action: getAction(data) }
			}), 'action')
			return Object.map(changedFormData, data => Object.map(actions[data.action.key].keyBy(), key => data[key]))
		},
	},
	methods:{
		setFromData() {
			this.formData = Object.map(this.unitReports.keyBy('id'), (report) => {
				let status = this.unitReportStatuses[report.status_key]
				return {
					status: status,
					is_submitted: status.data.is_submitted,
					is_checked: status.data.is_checked,
					is_pass: status.index === this.unitReportStatuses.pass.index,
					is_rejected: status.index === this.unitReportStatuses.rejected.index,
				}
			})
		},
		getReportFormOptions(report) {
			const checkOption = { type: 'checkbox', mode: 'switch', boolean: true, labelClass: 'col-lg-2 col-3' }
			let isSubmitted = this.unitReportStatuses[report.status_key].data.is_submitted
			let isChecked = this.unitReportStatuses[report.status_key].data.is_checked
			let isRejected = this.unitReportStatuses[report.status_key].index === this.unitReportStatuses.rejected.index
			const suboptionStyle = { class: 'pl-5', labelClass: 'col-lg-2 col-3', size: 'xs' }
			let statuses = Object.values(this.unitReportStatuses)
			let checkStatuses = statuses.filter(s => s.data.is_checked)
			let checking = !isChecked && this.formData[report.id].is_checked;
			return [
				{ key: `${report.id}.is_submitted`, label: '繳交狀態', options: isSubmitted ? `提交時間: ${report.last_submitted_at}` : '', disabled: isSubmitted, ...checkOption },
				{ key: `${report.id}.last_submitted_at`, type: 'datetime', label: '繳交日期', hide: isSubmitted || !this.formData[report.id].is_submitted, disabled: false, ...suboptionStyle },
				{ key: `${report.id}.is_checked`, label: '審核狀態', options: isChecked ? `${report.status}時間: ${report.status_key === 'pass' ? report.pass_at : `${report.rejections.first.created_at} (備註：${report.rejections.first.note})`}` : '', disabled: isChecked, hide: !isSubmitted, ...checkOption },
				{ key: `${report.id}.resubmit`, label: '重新繳交', options: '', hide: !isRejected, disabled: !isRejected, ...checkOption, ...suboptionStyle },
				{ key: `${report.id}.last_submitted_at`, type: 'datetime', label: '繳交日期', hide: !isRejected || !this.formData[report.id].resubmit, disabled: false, ...suboptionStyle },
				{ key: `${report.id}.check_type`, label: ' ', required: true, options: checkStatuses, keys: {value: 'key', text: 'text'}, disabled: isChecked, hide: !checking, ...checkOption, type: 'radio' },
				...checkStatuses.map(status => {
					return { key: `${report.id}.${status.key}_at`, type: 'date', label: `${status.text}日期`, hide: !checking || this.formData[report.id].check_type !== status.key, disabled: true, ...suboptionStyle }
				}),
				{ key: `${report.id}.note`, type: 'text', label: `備註`, required: true, hide: !checking || this.formData[report.id].check_type !== 'rejected', multiline: true, ...suboptionStyle },
			];
		},
		reportFormValidity() {
			return this.$refs.form.reportValidity()
		},
		showModal(report) {
			this.modal.data = report
			this.modal.show = true
		}
	}
}
</script>

<style scoped>
</style>
