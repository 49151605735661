import Constants from './Constants'
import Position from './Position'
import Type from './Type'
import Info from './Info'
import AdditionalInfo from './AdditionalInfo'
import { resetObject } from '@/utils/assist'

/** 案件資料key對應表
 */
export function GetGmlProjectKeys() {
	return Constants.DATA.PROJECT_KEYS_TABLE
}
/** 點位資料key對應表
 */
export function GetGmlPointKeys() {
	return Constants.DATA.POINT_KEYS_TABLE
}
/** 支距點位資料key對應表
 */
export function GetPositioningPointKeys() {
	return Constants.DATA.POSITIONING_POINT_KEYS_TABLE
}
/** GML版本
 */
export function GetGmlVersions() {
	return Constants.GML.VERSIONS
}

/** 是否是設施物
 */
export function IsFacilityType(type) {
	return Type.isFacilityTypeText(type)
}

/** 是否是設施物（表單）
 */
export function IsFacilityFormType(type) {
	return Type.isFacilityFormTypeText(type)
}

/** 計算兩點間距離
 */
export function GetPointDistance(pt1, pt2) {
	let pos1 = new Position(0,0,0,0,0).set(pt1);
	let pos2 = new Position(0,0,0,0,0).set(pt2);
	return Position.getDistance(pos1, pos2);
}

/** 取得對應後點位表單資料
 */
export function TransformPointData(point) {
	const KEYS = GetGmlPointKeys()
	let pt = Object.map(resetObject(point, KEYS), (value) => {
		return typeof value === "object" && value !== null ? (value.type === "number" ? Number(value.value) : value.value) : (Number.isNaN(Number(value)) ? value : Number(value))
	})
	let pos = new Position(0,0,0,0,0).set(pt);
	pt.facility_height = pos.getFacilityHeight()
	return pt
}

export function TransformPositioningPointData(point) {
	const KEYS = GetPositioningPointKeys()
	return Object.map(resetObject(point, KEYS), (value) => {
		return typeof value === "object" && value !== null ? (value.type === "number" ? Number(value.value) : value.value) : (Number.isNaN(Number(value)) ? value : Number(value))
	})
}

/** 點位表單資料
 */
export function GetPointInfos(pt) {
	let infos = [];
	for(let i = 1; i <= Constants.FORM.MAX_INFO_AMOUNT; ++i) {
		if(pt[`material${i}`] !== undefined && pt[`pipeline_width${i}`] !== undefined) {
			infos.push({
				material: pt[`material${i}`],
				pipelineType: pt[`pipeline_type${i}`],
				pipelineWidth: pt[`pipeline_width${i}`],
				pipelineHeight: pt[`pipeline_height${i}`] ?? 0,
				pipelineColumnAmount: pt[`pipeline_column_amount${i}`] ?? 1,
				pipelineRowAmount: pt[`pipeline_row_amount${i}`] ?? 1,
			})
		}
	}
	return infos;
}

/** 取得點位連接點
 */
export function GetPointTargets(pt) {
	let targets = {};
	for(let i = 1; i <= Constants.FORM.MAX_TARGET_AMOUNT; ++i) {
		if(pt[`target${i}`] && pt[`target${i}_info`]){
			targets[pt[`target${i}`]] = pt[`target${i}_info`]
		}
	}
	return targets;
}
/** 取得點位連接點名稱
 */
export function GetPointTargetNames(pt) {
	return Object.keys(GetPointTargets(pt))
}

export function GetPointTargetMaxAmount() {
	return Constants.FORM.MAX_TARGET_AMOUNT
}

export function CheckPointTargetInfos(pt, infoFormat, throwError=false) {
	let infos = GetPointInfos(pt)
	let targetInfos = GetPointTargets(pt)
	let result = Object.every(targetInfos, targetInfo => infos.find(info => Info.equals(info, Info.newByString(targetInfo, Info.format[infoFormat] ?? Info.format.ROUND_PIPELINE))))
	if(throwError && !result) {
		throw new Error(`連接點資訊與點位不符: ${pt.name}`);
	}
	return result
}

/** 取得點位Types
 */
export function GetPointType(pt) {
	try {
		let type = new Type(pt.type, pt.feature_type, pt.feature_category ? pt.feature_category : pt.other_facility_type ? pt.other_facility_type : undefined)
		return type.getTypes()
	} catch (error) {
		console.error(error)
		alert(error.message)
	}
}

/** 取得點位Type Keys
 */
export function GetPointTypeKey(pt) {
	try {
		let type = new Type(pt.type, pt.feature_type, pt.feature_category ? pt.feature_category : pt.other_facility_type ? pt.other_facility_type : undefined)
		return type.getKeys()
	} catch (error) {
		console.error(error)
		alert(error.message)
	}
}

export function IsPointOnPrivateLand(pt) {
	return pt.on_private_land === "是";
}
export function isPointExtantFacility(pt) {
	return (new AdditionalInfo(pt)).isExtantFacility;
	// return pt.extant_facility === "是";
}

export function GetPointUtilityCategoriesCount(types) {
	return {
		...types.map(type => Type.newByObject(type).getTypeKey().toLowerCase() ? Type.newByObject(type).getTypeKey().toLowerCase() : undefined).filter(o => o).countBy(type => type),
		point: types.length,
	}
}

export function GetAllUtilityCategories() {
	return Object.map(Object.mapKeys(Object.filter(Constants.TABLE.TYPE, 'utilityCategory'), 'utilityCategory'), 'categoryText')
}

export function GetAllUtilityCategoryKeys() {
	return Object.keys(GetAllUtilityCategories())
}

export function GetFacilityUtilityCategories() {
	return Object.filter(GetAllUtilityCategories(), (type, key) => Type.isFacilityUtilityCategoryKey(key))
}
