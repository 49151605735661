<template>
	<loading v-if="loading"/>
	<input-form ref="siteFilterForm" class="p-0 col-12" :options="formOptions" :actions="{submit: false, cancel:false}" v-model="formData" v-else>
		<template v-slot:button>
			<b-button class="form-btn submit-btn" type="button" @click="filterPoints">篩選</b-button>
		</template>
	</input-form>
</template>

<script>
import InputForm from '@/components/Input/InputForm';
import { deepCopy, isEmpty, oneOf, checkAllTrue } from '@/utils/assist';
import Loading from '@/components/Loading/Loading.vue';

export default {
	name: 'ConstructionSiteFilter',
	components: {
		InputForm,
		Loading
	},
	props: {},
	data() {
		return {
			result: {},
			formData: {},
			options: {},
			call: { getGroupList: false, getUnitList: false, getUnitBranchList: false, },
			loading: true,
			units: [],
			groups: [],
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
		unitBranchList: {
			deep: true,
			handler(list) {
				// TODO
				this.formData.party_a_branch_id = this.formData.party_a_branch_id ? this.formData.party_a_branch_id.filter(b => list.find(o => o.id === b)) : []
			}
		},
	},
	created() {
		this.getGroupList()
		this.getUnitList()
	},
	mounted() {
	},
	computed: {
		formOptions() {
			return [
				{ key: "pipeline_centers_id", type: "select", label: "道管", required: true, multiple: true, options: this.getUnitListByType('pipeline_center'), keys: {value: 'id', text: 'name'} },
				{ key: "project_groups_id", type: "select", label: "專案", required: false, multiple: true, options: this.groups, keys: {value: 'id', text: 'name'} },
				// { key: "paved_at", type: "date", label: "最晚銑舖日期", required: false },
				{ key: "status", type: "select", label: "狀態", required: false, multiple: true, options: this.$store.getters.enum('construction_site.status'), keys: {value: 'index', text: 'text'} },
			]
		},
		unitList() {
			return this.units;
		},
		unitBranchList() {
			let units = this.formData.party_a_id ? this.formData.party_a_id : []
			return this.unitList.filter(u => units.includes(u.id)).map(u => u.branches).flat()
		}
	},
	methods:{
		onLoadEnd() {
			this.$emit("load")
		},
		getGroupList() {
			this.call.getGroupList = false
			this.$axios.getGroupList({}, (response) => {
				this.groups = response.data
				this.call.getGroupList = true
			}, (error) => {
				this.call.getGroupList = true
			})
		},
		getUnitList() {
			this.call.getUnitList = false
			this.$axios.getUnitList({}, (response) => {
				this.units = response.data;
				this.getUnitBranchList();
				this.call.getUnitList = true
			}, (error) => {
				this.call.getUnitList = true
			})
		},
		getUnitBranchList() {
			this.call.getUnitBranchList = false
			this.$axios.getUnitBranchList({}, (response) => {
				this.units = this.units.map(unit => {
					return {
						...unit,
						branches: response.data.filter(branch => unit.id === branch.unit_id)
					}
				})
				this.call.getUnitBranchList = true
			}, (error) => {
				this.call.getUnitBranchList = true
			})
		},
		getUnitListByType(type) {
			return this.unitList.filter(unit => unit.type_key === type)
		},
		filterPoints(evt) {
			evt.preventDefault()
			if(isEmpty(this.formData) || Object.values(this.formData).every(o=>isEmpty(o))) {
				alert("請選擇工地篩選參數")
			}
			else {
				let data = this.formData
				if(Object.isEmpty(data.project_groups_id)) {
					// data.project_groups_id = this.groups.mapValues('id')
				}
				this.$emit('filter', data);
			}
		},
	}
}
</script>

<style scoped>
.card-wrapper {
	padding: .5rem;
}
.card {
	height: 100%;
}
.google-map {
	min-height: 400px;
	height: 100%;
}
.tab-body {
	padding: .5rem 1rem;
	border: 1px solid #dee2e6;
	border-top: 0;
	height: 100%;
}
.tabs-wrapper {
	height: 100%;
}
.tabs-wrapper > .tab-content {
	height: 100%;
}
.input {
	margin: .5rem .25rem
}
.table-responsive {
	margin: 0;
}
.action-btn {
	padding: .25rem .75rem;
	border-radius: .5rem;
	background: #b2c8d8aa;
	color: #050b10cc;
	border-color: #b2c8d8;
}
.action-btn:hover {
	text-decoration: none;
	background: #8ea9c2cc;
	color: #050b10;
}
.action-btn:focus {
	box-shadow: none;
}
</style>