<template>
	<div>
		<nav id="Sidebar" class="desktop sidebar" @mouseenter="desktopEnterSidebar" @mouseleave="desktopLeaveSidebar">
			<button class="close-btn mobile" @click="mobileCloseSidebar"><i class="fa-fw fas fa-times"></i></button>
			<side-bar-item :link="{ name: '首頁', icon: 'fas fa-home', path: '/' }" :icononly="icononly" v-show="icononly"/>
			<div class="title-wrapper" v-show="!icononly">
				<router-link to="/" class="title">{{ title }}</router-link>
			</div>
			<div class="subtitle-wrapper" v-show="!icononly">
				<font class="subtitle">{{ subtitle }}</font>
			</div>
			<side-bar-item :link="item" v-for="item in menu" :key="item.name" :icononly="icononly"/>
		</nav>
		<div @click="mobileCloseSidebar" id="Overlay"></div>
	</div>
</template>


<script>
import SideBarItem from "./SideBarItem"
import { deepCopy } from '@/utils/assist';
	export default {
		name: "SideBar",
		components: {
			SideBarItem
		},
		props: {
			title: {
				type: String,
				default: "",
			},
			subtitle: {
				type: String,
				default: "",
			},
			menu: {
				type: [Array, Object],
				default: () => []
			}
		},
		data() {
			return {
				icononly: false,
				mouseOverSidebar: false,
			}
		},
		watch:{
			$route (to, from){
				if(window.innerWidth >= 992) {
					document.getElementById("Sidebar").style.width = "4em";
					if(this.mouseOverSidebar)
						this.desktopEnterSidebar()
					else
						this.desktopLeaveSidebar()
				}
				else {
					this.mobileCloseSidebar();
				}
			}
		},
		created() {
			window.addEventListener('resize', this.handleResize);
		},
		destroyed() {
			window.removeEventListener('resize', this.handleResize)
		},
		mounted() {
			this.handleResize();
		},
		computed: {
		},
		methods: {
			handleResize() {
				if(window.innerWidth >= 992) {
					document.getElementById("Sidebar").style.width = "4em";
					this.icononly = true
				}
				else {
					document.getElementById("Sidebar").style.width = "";
					this.icononly = false
				}
			},
			desktopEnterSidebar() {
				if(window.innerWidth >= 992) {
					document.getElementById("Sidebar").style.width = "250px";
					document.getElementById("Sidebar").classList.add("open")
					this.icononly = false;
					this.mouseOverSidebar = true;
					this.$forceUpdate();
				}
			},
			desktopLeaveSidebar() {
				if(window.innerWidth >= 992) {
					document.getElementById("Sidebar").style.width = "4em";
					document.getElementById("Sidebar").classList.remove("open")
					this.icononly = true
					this.mouseOverSidebar = false;
					this.$forceUpdate();
				}
			},
			mobileCloseSidebar() {
				this.$emit("mobileCloseSidebar");
			}
		}
	}
</script>

<style scoped>
.sidebar-wrapper {
	height: 100%;
}
.mobile-sidebar-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	height: 100%;
}
.sidebar {
	position: fixed;
	height: 100%;
	z-index: 999;
	background-color: #284a70;
	color: #f5f7f7;
	padding: 1rem 0;
	text-align: center;
	overflow-x: hidden;
	box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
}
.sidebar.open {
	width: 250px;
	max-width: 90%;
}
.sidebar .close-btn {
	right: 0;
	position: absolute;
	margin-right: .5rem;
	font-size: 1.25rem;
	color: #fffa;
	background-color: transparent;
	border: none;
}
.sidebar .title-wrapper {
	padding: .5rem 0;
}
.sidebar .title {
	font-size: 1.5rem;
	color: inherit;
	line-height: 3rem;
}
.sidebar .title.hide-title, .sidebar .subtitle.title.hide-title {
	color: transparent;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}
.sidebar .title:hover, .sidebar .subtitle:hover {
	text-decoration: none;
}
.sidebar .subtitle-wrapper {
	padding: .25rem 0;
}
.sidebar .subtitle {
	font-size: 1.2rem;
	color: inherit;
	line-height: 1.5rem;
}
#Overlay {
	width: 100%;
	height: 100%;
	z-index: 99;
}
#Overlay.mobile {
	width: 100%;
	height: 100%;
	z-index: 100;
	position: fixed;
	background-color: #284a7050;
}


</style>