<template>
	<div>
		<h5>施工資料</h5>
		<b-row class="justify-content-between" v-if="!editing">
			<div class="mb-2 col-12" style="flex: 1 1;">
				<template v-for="(datum, key) in constructionData">
					<b-list-group-item class="d-flex border-0 py-0 px-md-3 px-1" :key="key" style="word-wrap: break-word; white-space: normal;" >
						<b style="flex: 0 1 7rem;">{{getProjectDataType(datum.project_data_type_id).name}}：</b>
						{{datum.status}}
					</b-list-group-item>
				</template>
				<div v-if="!constructionData.length" class="w-100 pl-md-4 pl-1 text-muted">
					未設定案件施工資料
				</div>
			</div>
			<div v-if="editable">
				<b-button class="form-btn clear-btn" @click="editing=true">編輯狀態</b-button>
			</div>
			<div class="col-12 mx-md-2 mx-1 px-md-1 py-1" style="border-top: 1px solid #ddd">
				<project-data-viewer :projectData="constructionData" :projectDataType="constructionDataType" :editable="editable" @submit="reload" @loading="load"></project-data-viewer>
			</div>
		</b-row>
		<construction-data-editor :constructionData="constructionData" :constructionDataType="constructionDataType" @cancel="editing=false" @submit="reload" @loading="load" v-else></construction-data-editor>
		<b-row class="justify-content-end" v-if="!uploading">
			<b-button class="form-btn submit-btn" @click="uploading=true">上傳檔案</b-button>
		</b-row>
		<div class="mx-md-2 mx-1 px-md-1 py-1" style="border-top: 1px solid #ddd" v-else>
			<b>上傳檔案</b>
			<construction-data-uploader :constructionData="constructionData" :constructionDataType="constructionDataType" @cancel="uploading=false" @submit="reload" @loading="load"></construction-data-uploader>
		</div>
	</div>
</template>


<script>
import ConstructionDataEditor from '@/components/Project/Info/ConstructionDataEditor'
import ConstructionDataUploader from '@/components/Project/Info/ConstructionDataUploader'
import ProjectDataViewer from '@/components/Project/Info/ProjectDataViewer.vue';
import { isEmpty, checkAllTrue } from '@/utils/assist';

export default {
	name: 'ConstructionDataViewer',
	components: {
		ConstructionDataEditor,
		ConstructionDataUploader,
		ProjectDataViewer,
	},
	props: {
		projectDataType: {
			type: Array,
			default: () => []
		},
		permission: {
			type: Object,
			default: () => { return {} }
		},
		constructionData: {
			type: Array,
			default: () => []
		},
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			call: {},
			loading: true,
			editing: false,
			uploading: false,
		}
	},
	created() {
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
		loading() {
			this.$emit('loading', this.loading)
		}
	},
	mounted() {
	},
	computed: {
		editable() {
			return this.checkPermission('edit') ? true : false
		},
		constructionDataType() {
			return this.projectDataType.filter(type => type.data_type_index === this.$store.getters.enum('project_data_type.data_type.construction_datum').index)
		}
	},
	methods:{
		reload() {
			this.$emit("reload")
		},
		load(loading) {
			this.loading = loading
		},
		checkPermission(action_key) {
			if(!this.groupID) return this.$store.getters.isDeveloper;
			if(!this.permission) return false;
			return this.permission.actions.find(action => action.key === action_key)
		},
		getProjectDataType(id) {
			return this.projectDataType.find(type => type.id === id)
		}
	}
}
</script>

<style scoped>
ul > li {
	list-style: none;
	display: flex;
}
ul > li > *:first-child {
	flex: 0 1 30%;
	align-self: flex-start;
	justify-content: flex-end;
	margin-left: 1rem;
}
.info-outter-wrapper {
	width: 100%;
}
.info-wrapper > div {
	padding: .5rem;
}
.basic-wrapper {
	padding: .5rem;
	margin-bottom: 1rem;
}
.basic-wrapper:last-child {
	margin-bottom: 0;
}
.tabs-wrapper {
	height: 100%;
}
.tabs-wrapper > .tab-content {
	height: 100%;
}
.tab-body {
	padding: .5rem 1rem;
	border: 1px solid #dee2e6;
	border-top: 0;
	height: 100%;
}
.timeline-outside-wrapper {
	height: 4.5rem;
}
.timeline-wrapper {
	position: fixed;
	left: 4rem;
	bottom: 0;
	width: calc(100% - 4rem);
	width: -webkit-calc(100% - 4rem);
	width: -moz-calc(100% - 4rem);
	width: -o-calc(100% - 4rem);
	padding: .5rem;
}
@media (max-width:991px) {
	.timeline-wrapper {
		left: 0;
		width: 100%;
		display: none;
	}
}
.timeline {
	min-height: 4rem;
}
.project-data-wrapper {
	border-bottom: 1px solid #dee2e6;
	min-height: 2rem;
}
.project-data-wrapper:last-child {
	border: none;
}


.right-top {
	position: absolute;
	top: 0;
	right: 0;
}


.google-map {
	min-height: 300px;
	height: 400px;
}


.progress-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0005;
	z-index: 10;
}
.progress-bar-wrapper {
	width: 50%;
	margin: auto;
	top: 50%;
	background: #e1e6e690;
	box-shadow: 0 0 5px .5px #284a7090;
}
.progress-bar {
	background: #017ca7;
}
.progress-bar-striped {
	background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
	background-size: 1rem 1rem;
}
.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite;
}
</style>
