import Vue from 'vue';
import axios from '../plugins/axios/api.js'
import { deepCopy, isEmpty } from "../utils/assist.js";
import { getLocalUser } from "../helpers/auth.js";

const user = getLocalUser();

function findEnums(enums, keys) {
	let key = keys.shift()
	if(keys.length <= 0) {
		// return enums[key]
		return enums[key] ? (Array.isArray(enums[key].enum) ? enums[key].enum : enums[key]) : Array.isArray(enums.enum) ? enums.enum.find(e => e.key === key) : undefined
	}
	return findEnums(enums[key], keys)
}
function checkKey(obj, key) {
	for(let k in key) {
		if(key[k] !== obj[k])
			return false
	}
	return true
}

export default {
	state: {
		//使用者相關
		currentUser: user,
		//登入
		isLoggedIn: !!user,
		loading: false,
		auth_error: null,
		data: {
			table: {},
		},
		enums: {}
	},
	getters: {
		isLoading(state) {
			return state.loading;
		},
		isLoggedIn(state) {
			return state.isLoggedIn;
		},
		currentUser(state) {
			return state.currentUser;
		},
		authError(state) {
			return state.auth_error;
		},
		isDeveloper(state, getters){
			return state.currentUser ? getters.enum(state.currentUser.role_key).key === 'developer' : false;
		},
		isInsider(state, getters){
			return state.currentUser ? getters.enum(state.currentUser.role_key).key === 'insider' : false;
		},
		isOutsider(state, getters){
			return state.currentUser ? getters.enum(state.currentUser.role_key).key === 'outsider' : false;
		},
		isSupervisor(state, getters){
			return state.currentUser ? getters.enum(state.currentUser.role_key).key === 'supervisor' : false;
		},
		initialized(state) {
			return state.currentUser && state.enums ? true : false;
		},
		enum(state, keys, key) {
			return function(keys, key) {
				if(!keys)
					return state.enums
				let enums = findEnums(state.enums, keys.split('.'))
				return !enums ? undefined : key ? enums.find(val => checkKey(val, key)) : deepCopy(enums)
			}
		},
		enums(state) {
			return deepCopy(state.enums)
		},
		hasPermissions(state, getters, controlKey, actionKey) {
			return function(controlKey, actionKey) {
				let control = getters.enum(`permission.system_control.${controlKey}`)
				let action = getters.enum(`permission.action_type.${actionKey}`)
				let permissions = state.currentUser.permissions
				return !!permissions.find(permission => {
					let permission_type = getters.enum(permission.permission_type_enum)
					return permission.type_index === control.index && permission_type.data.actions.includes(action.index);
				})
			}
		},

		// enum list, enum

	},
	mutations: {
		START_LOADING(state) {
			state.loading = true;
		},
		END_LOADING(state) {
			state.loading = false;
		},
		LOGIN(state) {
			state.loading = true;
			state.auth_error = null;
		},
		LOGIN_SUCCESS(state, payload) {
			state.auth_error = null;
			state.isLoggedIn = true;
			state.loading = false;
			// state.currentUser = Object.assign({}, payload.user, {token: payload.access_token});
			state.currentUser = payload;
			localStorage.setItem("user", JSON.stringify(state.currentUser));
		},
		LOGIN_FAIL(state, payload) {
			state.loading = false;
			state.auth_error = payload.error;
		},
		LOGOUT(state) {
			localStorage.removeItem("user");
			localStorage.removeItem('token');
			state.isLoggedIn = false;
			state.currentUser = null;
		},
		SET_PERMISSIONS(state, payload) {
			state.currentUser.permissions = payload;
			localStorage.setItem("user", JSON.stringify(state.currentUser));
		},
		INIT_TABLE(state, payload) {
			state.data.table = deepCopy(payload)
			state.enums = deepCopy(payload)
		},
	},
	actions: {
		login({commit}, formData) {
			return new Promise( (resolve, reject) => {
				axios.login(formData, (response) => {
					commit("LOGIN_SUCCESS", {
						...response.data,
						...response.data.user
					});
					resolve(response);
				}, (error) => {
					reject(error)
				});
			});
		},
		setLogout({commit}){
			commit('LOGOUT');
		},
		logout({commit}){
			return new Promise( (resolve)=>{
				axios.logout((response) => {
					commit('LOGOUT');
					// delete axios.defaults.headers.common['Authorization']
					resolve();
				}, (error) => {
					commit('LOGOUT');
					// delete axios.defaults.headers.common['Authorization']
					resolve();
				});
			});
		},
		init({commit}) {
			commit("START_LOADING")
			this.dispatch('initTable');
		},
		initTable({commit}) {
			return new Promise( (resolve, reject) => {
				axios.getTable(response => {
					commit("INIT_TABLE", response);
					resolve(response)
					commit("END_LOADING")
				}, error => {
					reject(error)
					commit("END_LOADING")
				});
			});
		},
		setUserPermissions({commit}, permissions) {
			commit("SET_PERMISSIONS", permissions);
		}
	},
};
