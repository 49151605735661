<template>
	<div>
		<b-alert class="my-2 py-2 px-md-3 px-1 alert-list" :class="{'alert-text text-danger':textOnly}" show variant="danger" v-if="!Object.isEmpty(errors)">
			<div v-for="(error, key) in errors" :key="key">
				<template v-if="typeof error === 'object'">
					<b-row class="align-items-center font-weight-bold">
						<div class="alert-title">
							<i class="fa-fw fas fa-exclamation-triangle pr-1"></i><span v-html="error.title"></span>
						</div>
					</b-row>
					<template v-if="Array.isArray(error.messages)">
						<li class="ml-3 pl-3 alert-messages" v-for="(message, key) in error.messages.filter(o=>o)" :key="key" v-html="message"></li>
					</template>
					<li class="ml-3 pl-3 alert-messages" :key="key" v-html="error.messages" v-else-if="error.messages"></li>
				</template>
				<template v-else>
					<b-row class="align-items-center font-weight-bold">
						<div class="alert-title">
							<i class="fa-fw fas fa-exclamation-triangle pr-1"></i>
							<span v-html="error"></span>
						</div>
					</b-row>
				</template>
			</div>
		</b-alert>
		<b-alert class="my-2 py-2 px-md-3 px-1 alert-list" :class="{'alert-text text-warning':textOnly}" show variant="warning" v-if="!Object.isEmpty(warnings)">
			<div v-for="(error, key) in warnings" :key="key">
				<template v-if="typeof error === 'object'">
					<b-row class="align-items-center font-weight-bold">
						<div class="alert-title">
							<i class="fa-fw fas fa-exclamation-triangle pr-1"></i><span v-html="error.title"></span>
						</div>
					</b-row>
					<template v-if="Array.isArray(error.messages)">
						<li class="ml-3 pl-3 alert-messages" v-for="(message, key) in error.messages.filter(o=>o)" :key="key" v-html="message"></li>
					</template>
					<li class="ml-3 pl-3 alert-messages" :key="key" v-html="error.messages" v-else-if="error.messages"></li>
				</template>
				<template v-else>
					<b-row class="align-items-center font-weight-bold">
						<div class="alert-title">
							<i class="fa-fw fas fa-exclamation-triangle pr-1"></i>
							<span v-html="error"></span>
						</div>
					</b-row>
				</template>
			</div>
		</b-alert>
	</div>
</template>

<script>
export default {
	name: 'Alert',
	components: {
	},
	props: {
		errors: {
			type: [Array, Object],
			default: () => []
		},
		warnings: {
			type: [Array, Object],
			default: () => []
		},
		textOnly: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
		}
	},
	created() {
	},
	watch: {
	},
	computed: {
	},
	methods: {
	}
}
</script>

<style>
.alert-text {
	background-color: transparent !important;
	border: none !important;
	/* color: #dc3545 !important; */
}
.alert-title, .alert-messages {
	white-space: normal;
	text-indent: -1.2rem;
	margin-left: 1.2rem !important;
}
.alert-title *, .alert-messages * {
	white-space: normal;
}
</style>
