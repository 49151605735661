<template>
	<span class="filter-item-wrapper">
		<b-badge class="tag-badge" :disabled="disabled" :state="state" @click="onClick">
			{{ text }}
		</b-badge>
	</span>
</template>

<script>

export default {
	name: 'BvFilterItem',
	components: {
	},
	props: {
		text: {
			type: String,
		},
		value: {
			type: [String, Number]
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		state: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {

		}
	},
	watch: {
	},
	created() {
	},
	mounted () {
	},
	computed: {
	},
	methods: {
		onClick() {
			this.$emit('click', this.value)
		}
	}
}
</script>

<style scoped>
.filter-item-wrapper {
	display: inline-block;
	padding: .25rem;
}
.tag-badge {
	height: 100%;
	display: inline-flex;
	align-items: center;
	border-radius: 1rem;
	margin-left: .25rem;
	background-color: #b2c8d820;
	color: #284a70;
	border: 1px solid #6b8ca930;
	padding: .25rem .5rem;
	box-shadow: 0 0 2px 1px #6b8ca930, 0 0 2px 1px inset #6b8ca930;
	z-index: 4;
}
.tag-badge.disabled {
	background-color: #aaa9a720;
	color: #8b8b88;
	border: 1px solid #8a898630;
	box-shadow: 0 0 2px 1px #8a898630, 0 0 2px 1px inset #8a898630;
}
.tag-badge[state] {
	background-color: #1a4868e7;
	color: #e1ecf8;
	border: 1px solid #204b74ab;
	box-shadow: 0 0 2px 1px #204b74ab, 0 0 2px 1px inset #204b74ab;
}
.tag-badge:hover {
	cursor: pointer;
}
.tag-badge.disabled {
	pointer-events: none;
}
</style>