<template>
	<loading v-if="loading"/>
	<input-form class="p-0 col-12" :options="formOptions" v-model="result" @submit="onSubmit" @cancel="onCancel" v-else></input-form>
</template>

<script>
import InputForm from '@/components/Input/InputForm';
import Loading from '@/components/Loading/Loading.vue';
import { deepCopy, checkAllTrue } from '@/utils/assist';

export default {
	name: 'UnitEditor',
	components:{
		InputForm,
		Loading
	},
	props: {
		unit: {
			type: Object,
		},
	},
	data() {
		return {
			result: {},
			call: { getDynamicFormList: false },
			loading: true,
			dynamicForms: [],
			dynamicFormTypes: this.$store.getters.enum('dynamic_form.type').filter(type => type.data.has_unit),
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		unit: {
			deep: true,
			handler(value) {
				this.initResult()
			}
		},
		loading() {
			this.$emit('loading', this.loading)
		}
	},
	created() {
		this.getDynamicFormList()
		this.initResult()
	},
	mounted() {
	},
	computed: {
		unitType() {
			return this.$store.getters.enum(this.unit.type_enum)
		},
		formOptions() {
			return [
				{ key: "type", type: "select", label: "種類", required: true, options: this.$store.getters.enum('unit.type'), keys: {value: 'index', text: 'text'}, size: 'xs', disabled: true },
				{ key: "name", type: "text", label: "名稱", required: true, size: 'xs' },
				{ key: "full_name", type: "text", label: "全名", size: 'xs' },
				{ key: "phone", type: "text", label: "電話", size: 'xs' },
				{ key: "address", type: "text", label: "地址", size: 'xs' },
				...this.dynamicFormTypes.map(type => {
					return { key: `${type.key}_dynamic_form_id`, type: "select", label: `${type.text}表單`, required: true, options: this.getDynamicFormListByType(type.key), keys: {value: 'id', text: 'name'}, size: 'xs', hide: !Object.keys(this.unitType.data.relations).includes('dynamicForms') }
				}),
			]
		},
		updatedResult() {
			let result = Object.keys(this.result).reduce((obj, key) => {
				if(this.result[key] !== this.unit[key])
					obj[key] = this.result[key];
				return obj;
			}, this.dynamicFormTypes.reduce((obj, type) => {
				obj[`${type.key}_dynamic_form_id`] = this.result[`${type.key}_dynamic_form_id`]
				return obj
			}, {}))
			return result
		}
	},
	methods:{
		initResult() {
			this.result = this.unit ? {
				type: this.unit.type_index,
				name: this.unit.name,
				full_name: this.unit.full_name,
				phone: this.unit.phone,
				address: this.unit.address,
				...this.dynamicFormTypes.reduce((obj, type) => {
					obj[`${type.key}_dynamic_form_id`] = this.unit[`${type.key}_dynamic_form_id`]
					return obj
				}, {})
			} : this.unit;
		},
		getDynamicFormList() {
			this.call.getDynamicFormList = false
			this.$axios.getDynamicFormList({}, (response) => {
				this.dynamicForms = response.data
				this.call.getDynamicFormList = true
			}, (error) => {
				this.call.getDynamicFormList = true
			});
		},
		getDynamicFormListByType(typeKey) {
			return this.dynamicForms.filter(form => form.type_index === this.$store.getters.enum(`dynamic_form.type.${typeKey}`).index)
		},
		onSubmit(evt) {
			evt.preventDefault();
			let result = this.updatedResult;
			if(!result) {
				alert('修改成功')
				this.$emit('submit')
			}
			else {
				this.$set(this.call, 'updateUnit', false)
				this.$axios.updateUnit(this.unit.id, result, (response) => {
					this.$emit('submit')
					this.call.updateUnit = true;
				}, (error) => {
					this.call.updateUnit = true;
				})
			}
		},
		onCancel() {
			this.$emit('cancel')
		}
	}
}
</script>

<style scoped>
.input {
	margin: .5rem .25rem
}
</style>