<template>
	<input-form class="p-0 col-12" :options="formOptions" :actions="{cancel:false}" v-model="result" @submit="onSubmit"></input-form>
</template>

<script>
import InputText from '@/components/Input/InputText';
import InputRadio from '@/components/Input/InputRadio';
import InputSelect from '@/components/Input/InputSelect';
import InputForm from '@/components/Input/InputForm';
import Loading from '@/components/Loading/Loading.vue';
import { isEmpty, checkAllTrue } from '@/utils/assist';

export default {
	name: 'UnitBranchCreator',
	components:{
		InputText,
		InputRadio,
		InputSelect,
		InputForm,
		Loading
	},
	props: {
		unit: {
			type: Object,
		},
	},
	data() {
		return {
			result: {},
			call: {},
			loading: true,
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		loading() {
			this.$emit('loading', this.loading)
		}
	},
	created() {
	},
	mounted() {
	},
	computed: {
		formOptions() {
			return [
				{ key: "name", type: "text", label: "名稱", required: true, size: 'xs' },
				{ key: "full_name", type: "text", label: "全名", size: 'xs' },
			]
		}
	},
	methods:{
		onSubmit(evt) {
			evt.preventDefault();
			this.$set(this.call, 'createUnitBranch', false)
			this.$axios.createUnitBranch({
				...this.result,
				unit_id: this.unit.id
			}, (response) => {
				this.$emit('submit')
				alert('新增成功')
				this.call.createUnitBranch = true;
			}, (error) => {
				this.call.createUnitBranch = true;
			})
		},
	}
}
</script>

<style scoped>
.input {
	margin: .5rem .25rem
}
</style>