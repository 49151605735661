<template>
	<div>
		<div v-for="(item, key) in list" :key="key">
			<template v-if="!item.hide">
				<hr v-if="key"/>
				<h6 class="px-md-4" v-if="item.children"><i class="fa-fw" :class="item.icon"></i> {{item.name}}</h6>
				<b-row class="p-2">
					<div class="col-md-6 col-12 p-1">
						<b-card class="shadow">
							<menu-item :item="item"></menu-item>
						</b-card>
					</div>
				</b-row>
			</template>
		</div>
	</div>
</template>

<script>
import MenuItem from '@/components/Menu/MenuItem.vue'
export default {
	name: 'MenuGroup',
	components:{
		MenuItem
	},
	props: {
		menu: {
			type: [Array, Object],
			default: () => []
		}
	},
	data() {
		return {
		}
	},
	created() {
	},
	destroyed() {
	},
	mounted() {
	},
	computed: {
		list() {
			return Array.isArray(this.menu) ? this.menu : Object.values(this.menu)
		}
	},
	methods:{
	}
}
</script>

<style scoped>
a.router-link {
	color: #4c6b8a;
}
a.router-link:hover {
	text-decoration: none;
	color: #284a70;
	filter: drop-shadow(0 0 1px #0005);
}
</style>