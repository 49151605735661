<template>
	<div class="m-0 p-0 h-100">
		<iframe class="card h-100 w-100 m-0 p-0" :src="url" frameborder="0" :allow="allow"></iframe>
	</div>
</template>

<script>
export default {
	name: 'FramePage',
	components:{
	},
	props: {
		url: {
			type: String,
		},
		query: {
			type: Object,
			default() { return {}; },
		},
		allow: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
		}
	},
	watch: {
	},
	created() {
	},
	computed: {
	},
	methods:{
	}
}
</script>

<style scoped>
.iframe-page {
	height: calc(100vh - 9rem);
}
</style>