<template>
	<div class="px-5">
		<h5>個人資料</h5>
		<b-card no-body class="border-0 control-board">
			<b-card-body>
				<b-list-group>
					<template v-for="(datum, key) in basicData">
						<b-list-group-item v-if="!datum.hide" :key="key">
							{{datum.label}}：{{$store.getters.currentUser[datum.key]}}
						</b-list-group-item>
					</template>
				</b-list-group>
			</b-card-body>
		</b-card>
	</div>
</template>

<script>
export default {
	name: 'Profile',
	components:{
	},
	data() {
		return {
			basicData: [
				{ key: 'id', label: 'ID', hide: !this.$store.getters.isDeveloper },
				{ key: 'name', label: '名稱' },
				{ key: 'account', label: '帳號' },
				{ key: 'email', label: '信箱' },
				{ key: 'unit_name', label: '單位名稱' },
			],
		}
	},
	created() {
	},
	destroyed() {
	},
	mounted() {
	},
	computed: {
	},
	methods:{
	}
}
</script>

<style scoped>
</style>