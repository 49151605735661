<template>
	<div>
		<loading mode="fixed" image="1" size="lg" v-if="loading"/>
		<form @submit="submit" ref="form">
			<input-date class="mt-1" v-model="month" label="估驗月份" format="yyyy-MM" required></input-date>
			<input-file class="mt-1" v-model="file" label="估驗清冊" button="fas fa-file-excel" accept=".xlsx" required></input-file>
			<b-row class="my-3 justify-content-center">
				<b-button class="form-btn submit-btn" type="submit" variant="primary">確認匯出</b-button>
			</b-row>
		</form>
	</div>
</template>

<script>
import InputDate from '@/components/Input/InputDate.vue'
import InputFile from '@/components/Input/InputFile'
import fileManager from '@/utils/file';
import Loading from '@/components/Loading/Loading.vue';
import { checkAllTrue } from '@/utils/assist';
export default {
	name: 'AcceptanceForm',
	components: {
		InputDate,
		InputFile,
		Loading,
	},
	props: {
	},
	data() {
		return {
			loading: false,
			call: {},
			month: (new Date()).addMonths(-1),
			file: undefined,
		}
	},
	created() {
	},
	mounted() {
	},
	destroyed() {
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	computed: {
		model() {
			return {
				month: this.month,
				file: this.file,
			}
		},
		formData() {
			return {
				month: Date.isDate(this.month) ? this.month.format('yyyy-MM') : this.month,
				file: this.file,
			}
		},
	},
	methods: {
		submit(e) {
			e.preventDefault();
			if(!this.$refs.form.reportValidity())
				return;
			this.exportTpwAcceptanceProjectsList()
		},
		exportTpwAcceptanceProjectsList() {
			this.$set(this.call, 'exportTpwAcceptanceProjectsList', false)
			this.$axios.exportTpwAcceptanceProjectsList(this.formData, (response) => {
				let filename = `${this.formData.month.replace('-', '')}估驗案件清冊${(new Date()).format('yyyyMMdd_hhmmss')}`
				fileManager.saveFile(filename, 'xlsx', response)
				this.call.exportTpwAcceptanceProjectsList = true
			}, (error) => {
				this.call.exportTpwAcceptanceProjectsList = true
			})
		},
	}
}
</script>

<style scoped>
</style>
