<template>
	<loading v-if="loading"/>
	<input-form :options="formOptions" v-model="formData" @submit="onSubmit" @cancel="onCancel" v-else></input-form>
</template>

<script>
import InputForm from '@/components/Input/InputForm';
import Loading from '@/components/Loading/Loading.vue';
import { isEmpty, checkAllTrue } from '@/utils/assist';

export default {
	name: 'UserEditor',
	components:{
		InputForm,
		Loading
	},
	props: {
		user: {
			type: Object,
		},
		roleList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			form: [ 'account', 'name', 'password', 'email', 'role' ],
			formData: {},
			call: { getUnitList: false },
			loading: true,
			units: [],
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		user: {
			deep: true,
			handler() {
				this.initFormData()
			}
		},
	},
	created() {
		this.getUnitList()
	},
	mounted() {
	},
	computed: {
		formOptions() {
			return [
				{ key: "account", type: "text", label: "帳號", required: true, disabled: true, size: 'xs', pattern: "[a-zA-Z0-9]{2,}", invalidMessage: '請輸入2位以上之英數字' },
				{ key: "name", type: "text", label: "名稱", required: true, disabled: true, size: 'xs', pattern: "^[a-zA-Z0-9\u4E00-\u9FFF\uff08-\uff09_]{2,}$", invalidMessage: '請輸入2個字元以上非特殊符號' },
				// { key: "password", type: "password", label: "密碼", required: true, size: 'xs', pattern: "[a-zA-Z0-9]{3,}", invalidMessage: '請輸入3位以上之英數字' },
				{ key: "email", type: "email", label: "信箱", required: true, disabled: true, size: 'xs' },
				{ key: "unit_type", type: "select", label: "單位種類", required: true, options: this.$store.getters.enum('unit.type'), keys: {value: 'index', text: 'text'}, size: 'xs' },
				{ key: "unit_id", type: "select", label: "單位", required: true, options: this.unitList, keys: {value: 'id', text: 'name'}, size: 'xs' },
				{ key: "role", type: "select", label: "身份", required: true, options: this.userRoleList, keys: {value: 'index', text: 'text'}, size: 'xs' },
			]
		},
		unitList() {
			return this.units.filter(unit => unit.type_index === this.formData.unit_type)
		},
		userRoleList() {
			return this.$store.getters.enum('user.role').filter(role => this.roleList.includes(role.key))
		},
	},
	methods:{
		initFormData() {
			this.formData = Object.assign(this.formData, {
				id: this.user.id,
				account: this.user.account,
				name: this.user.name,
				email: this.user.email,
				unit_type: this.units.find(unit => unit.id === this.user.unit_id).type_index,
				unit_id: this.user.unit_id,
				role: this.user.role_index,
			})
			this.$forceUpdate()
		},
		getUnitList() {
			this.call.getUnitList = false
			this.$axios.getUnitList({}, (response) => {
				this.units = response.data
				this.call.getUnitList = true
				this.initFormData()
			}, (error) => {
				this.call.getUnitList = true
			})
		},
		onSubmit(evt) {
			evt.preventDefault();
			this.$axios.updateUser(this.formData.id, this.formData, (response) => {
				this.$emit('updateUser')
			})
			this.$set(this, 'formData', {})
		},
		onCancel() {
			this.$emit('cancel')
		},
	}
}
</script>

<style scoped>
.input {
	margin: .5rem .25rem
}
</style>