<template>
	<div>
		<div class="loading" v-if="loading" :style="`background-image:url(${loading_img_url})`"></div>
		<div class="gmap-wrapper" v-show="!loading">
			<gmap-map ref="map" class="gmap" :center="map.center" :zoom="map.zoom" :options="map.options" map-type-id="roadmap">
				<gmap-info-window :options="map.infoWindow.options" :position="map.infoWindow.position" :opened="map.infoWindow.open" @closeclick="map.infoWindow.open=false">
					<template v-if="Array.isArray(map.infoWindow.content)">
						<li class="no-list-style" v-for="(item, key) in map.infoWindow.content" :key="key">{{item}}</li>
					</template>
					<template v-else-if="typeof map.infoWindow.content === 'object'">
						<li class="no-list-style" v-for="(item, key) in map.infoWindow.content" :key="key">
							<template v-if="key && key !== 'html'"><b>{{key}}</b>：{{item}}</template>
							<div v-else v-html="item"></div>
						</li>
					</template>
					<div v-else v-html="map.infoWindow.content"></div>
					<div class="d-flex justify-content-end align-content-center">
						<template v-for="(button, key) in map.infoWindow.buttons">
							<button :key="key" :class="button.button.class" :style="button.button.style" @click="clickInfoWindowButton(button.action.function, button.action.params)" v-if="button.action.type==='function'">
								<i class="fa-fw" :class="button.button.icon" v-if="button.button.icon"></i>
								<template v-if="button.button.label">{{button.button.label}}</template>
							</button>
							<a :key="key" :class="button.button.class" :style="button.button.style" :href="typeof button.action.link ==='function' ? clickInfoWindowButton(button.action.link, button.action.params) : button.action.link" :target="button.action.target" v-else-if="button.action.type==='link'">
								<i class="fa-fw" :class="button.button.icon" v-if="button.button.icon"></i>
								<template v-if="button.button.label">{{button.button.label}}</template>
							</a>
							<router-link :key="key" :class="button.button.class" :style="button.button.style" :to="typeof button.action.route ==='function' ? clickInfoWindowButton(button.action.route, data.index, button.action.params) : button.action.route" :target="button.action.target" v-else-if="button.action.type==='route'">
								<i class="fa-fw" :class="button.button.icon" v-if="button.button.icon"></i>
								<template v-if="button.button.label">{{button.button.label}}</template>
							</router-link>
						</template>
					</div>
				</gmap-info-window>
			</gmap-map>
			<gmap-filter class="gmap-control-wrapper gmap-filter p-2" v-model="map.layerFilter" :options="layerFilterOptions" v-if="Object.keys(map.layers).length"></gmap-filter>
		</div>
	</div>
</template>

<script>
import GmapFilter from './GmapFilter'
import { gmapApi } from 'vue2-google-maps'
import { oneOf, deepSet, isEqual } from "@/utils/assist"
import loading_img from '@/assets/images/loading.gif';
import mapIcons from '@/plugins/google-maps-icons'

export default {
	name: 'VueGoogleMapWithLayer',
	components:{
		GmapFilter,
	},
	props: {
		center: {
			type: Object,
			validator (value) {
				return value.lat && value.lng;
			},
			default() {
				return {
					lat: 25.0325917,
					lng: 121.5624999,
				}
			}
		},
		zoom: {
			type: Number,
			default: 15
		},
		options: {
			type: Object,
			default: () => { return {} }
		},
		layers: {
			type: Array,
			default: () => [],
			validator(layers) {
				return layers.every(layer => {
					return layer.name !== undefined &&
						oneOf(layer.type, ['points']) &&
						Array.isArray(layer.features ?? []) &&
						(layer.features ?? []).every(feature => oneOf(feature.type, ['Point', 'LineString', 'Polygon', 'MultiPoint', 'MultiLineString', 'MultiPolygon']))
				})
			}
		},
		markers: {
			type: Array,
			default: () => [],
		},
		configs: {
			type: Object,
			validator(value) {
				let res = true;
				if(value.drawing) {
					res &= oneOf(value.drawingMode, ['marker', 'polygon', 'polyline', 'rectangle', 'circle'])
				}
				return res
			},
		},
		address: {
			type: String,
		},
		modes: {
			type: Object,
			default() { return {} }
		},
	},
	data() {
		return {
			loading: true,
			loading_img_url: loading_img,
			map: {
				mapObject: null,
				center: { lat: 25.0325917, lng: 121.5624999 },
				zoom: 15,
				// key: {
				// 	dataObject, key, type, features, options, style, selectedDataObject, selectedStyle
				// }
				layers: {},
				layerFilter: [],
				markers: [],
				overlays: [],
				infoWindow: {
					position: { lat: 25.0325917, lng: 121.5624999 },
					open: false,
					options: {
						pixelOffset: { width: 0, height: -40 }
					},
					content: "",
					buttons: [],
				},
				options: {
					zoomControl: true,
					mapTypeControl: true,
					scaleControl: true,
					streetViewControl: true,
					rotateControl: true,
					fullscreenControl: true,
					disableDefaultUi: false,
					styles: undefined
				},
				geocode: {
					geocoder: null,
					address: '',
				},
				drawing: {
					drawing: false,
					drawingManager: null,
					drawingOptions: {
						drawingControl: false
					},
					drawingOverlays: [],
				},
				overlayConfigs: {
					options: {},
					editable: false,
					draggable: false,
					click: () => {},
					dblclick: () => {},
					drag: () => {},
					dragend: () => {},
					dragstart: () => {},
					mousedown: () => {},
					mousemove: () => {},
					mouseout: () => {},
					mouseover: () => {},
					mouseup: () => {},
					rightclick: () => {},
				},
			},
			styles: {
				night: [
					{ elementType: 'geometry', stylers: [{color: '#242f3e'}] },
					{ elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}] },
					{ elementType: 'labels.text.fill', stylers: [{color: '#746855'}] },
					{
						featureType: 'administrative.locality',
						elementType: 'labels.text.fill',
						stylers: [{color: '#d59563'}]
					},
					{
						featureType: 'poi',
						elementType: 'labels.text.fill',
						stylers: [{color: '#d59563'}]
					},
					{
						featureType: 'poi.park',
						elementType: 'geometry',
						stylers: [{color: '#263c3f'}]
					},
					{
						featureType: 'poi.park',
						elementType: 'labels.text.fill',
						stylers: [{color: '#6b9a76'}]
					},
					{
						featureType: 'road',
						elementType: 'geometry',
						stylers: [{color: '#38414e'}]
					},
					{
						featureType: 'road',
						elementType: 'geometry.stroke',
						stylers: [{color: '#212a37'}]
					},
					{
						featureType: 'road',
						elementType: 'labels.text.fill',
						stylers: [{color: '#9ca5b3'}]
					},
					{
						featureType: 'road.highway',
						elementType: 'geometry',
						stylers: [{color: '#746855'}]
					},
					{
						featureType: 'road.highway',
						elementType: 'geometry.stroke',
						stylers: [{color: '#1f2835'}]
					},
					{
						featureType: 'road.highway',
						elementType: 'labels.text.fill',
						stylers: [{color: '#f3d19c'}]
					},
					{
						featureType: 'transit',
						elementType: 'geometry',
						stylers: [{color: '#2f3948'}]
					},
					{
						featureType: 'transit.station',
						elementType: 'labels.text.fill',
						stylers: [{color: '#d59563'}]
					},
					{
						featureType: 'water',
						elementType: 'geometry',
						stylers: [{color: '#17263c'}]
					},
					{
						featureType: 'water',
						elementType: 'labels.text.fill',
						stylers: [{color: '#515c6d'}]
					},
					{
						featureType: 'water',
						elementType: 'labels.text.stroke',
						stylers: [{color: '#17263c'}]
					}
				],
				hideBussiness: [
					{ featureType: 'poi.business', stylers: [{visibility: 'off'}] },
					{ featureType: 'poi.medical', stylers: [{visibility: 'off'}] },
					{ featureType: 'poi.place_of_worship', stylers: [{visibility: 'off'}] },
					{ featureType: 'poi.sports_complex', stylers: [{visibility: 'off'}] },
				],
				hideMarkers: [
					{ featureType: 'poi.attraction', stylers: [{visibility: 'off'}] },
					{ featureType: 'poi.business', stylers: [{visibility: 'off'}] },
					{ featureType: 'poi.government', stylers: [{visibility: 'off'}] },
					{ featureType: 'poi.medical', stylers: [{visibility: 'off'}] },
					{ featureType: 'poi.park', stylers: [{visibility: 'off'}] },
					{ featureType: 'poi.place_of_worship', stylers: [{visibility: 'off'}] },
					{ featureType: 'poi.school', stylers: [{visibility: 'off'}] },
					{ featureType: 'poi.sports_complex', stylers: [{visibility: 'off'}] },
				],
				hideTransitions: [
					{ featureType: 'transit.line', stylers: [{visibility: 'off'}] },
					{ featureType: 'transit.station', stylers: [{visibility: 'off'}] },
					{ featureType: 'transit.station.airport', stylers: [{visibility: 'off'}] },
					{ featureType: 'transit.station.bus', stylers: [{visibility: 'off'}] },
					{ featureType: 'transit.station.rail', stylers: [{visibility: 'off'}] },
				]
			},
			icons: {
				marker: mapIcons.marker,
				largeMarker: mapIcons.large_marker,
			},
			layerTypes: {
				normal: '',
				selected: 'selected',
			},
		}
	},
	watch: {
		'configs.drawing': {
			handler(value) {
				if(value)
					this.initDrawingManager();
				else if(!value && this.map.drawing.drawingManager) {
					this.map.drawing.drawingManager.setMap(null);
				}
			}
		},
		'configs.drawingMode': {
			handler(value) {
				this.map.drawing.drawingOptions.drawingMode = value
				if(this.map.drawing.drawingManager)
					this.map.drawing.drawingManager.setOptions({
						drawingMode: value
					});
				else
					this.initDrawingManager();
			}
		},
		'configs.drawingOptions': {
			deep: true,
			handler(value) {
				this.setDrawingOptions()
				if(this.map.drawing.drawingManager)
					this.map.drawing.drawingManager.setOptions(this.map.drawing.drawingOptions);
			}
		},
		center: {
			deep: true,
			handler(value) {
				this.setMapCenter(value)
			}
		},
		zoom: {
			handler(value) {
				this.setMapZoom(value)
			}
		},
		modes: {
			deep: true,
			handler(modes) {
				this.setMapStyles()
			}
		},
		layers: {
			deep: true,
			handler() {
				this.updateLayers()
				this.viewAllLayers()
			}
		},
		'map.layerFilter': {
			deep: true,
			handler(filter, oldFilter) {
				if(!isEqual(filter, oldFilter)) {
					Object.keys(this.map.layers).forEach(key => {
						Object.keys(this.layerTypes).forEach(type => {
							let objectKey = `${this.layerTypes[type]}DataObject`.toCamelCase()
							this.map.layers[key][objectKey].setMap(filter.includes(key) ? this.map.mapObject : null)
						})
					})
					this.$emit('filterLayers', filter)
				}
			}
		}
	},
	created() {
		this.initMap()
	},
	mounted() {
		this.$refs.map.$mapPromise.then((mapObject) => {
			this.map.mapObject = this.$refs.map.$mapObject
			this.loading = false
			this.initIcons()
			this.onLoadMap()
			this.google.maps.event.addListener(this.map.mapObject, 'bounds_changed', () => {
				this.filterLayers()
				this.filterOverlays()
				this.$forceUpdate()
			})
		})
	},
	computed: {
		google: gmapApi,
		mapStyles() {
			let style = []
			Object.keys(this.modes).forEach(key => {
				if(this.modes[key] && typeof this.styles[key] !== "undefined") {
					style = [...style, ...this.styles[key]]
				}
			})
			return style
		},
		mapMarkerSize() { return 28 },
		nextMarkerIcon() {
			let usedIcons = Object.keys(this.map.layers).map(key => this.getLayerStyle(key).icon)
			return Object.values(this.icons.marker).find(icon => !usedIcons.includes(icon))
		},
		nextMarkerIconColor() {
			let usedIconColors = Object.keys(this.map.layers).map(key => this.getLayerStyle(key).iconColor)
			return Object.keys(this.icons.marker).find(icon => !usedIconColors.includes(icon))
		},
		layerFilterOptions() {
			return Object.keys(this.map.layers).map(key => {
				let layer = this.map.layers[key]
				return {
					text: key,
					value: key,
					icon: this.getLayerStyle(key).icon.url,
					count: layer.features.length,
				}
			})
		},
	},
	methods:{
		initMap() {
			this.setMapCenter(this.center);
			this.setMapZoom(this.zoom);
			this.setMapOptions(this.options);
			this.setMapStyles()
		},
		onLoadMap() {
			if(this.layers.length) {
				this.initLayers()
			}
			if(this.configs.drawing)
				this.initDrawingManager()
			if(this.configs.geocoding)
				this.initGeocoder()
		},
		setMapCenter(center) { this.$set(this.map, "center", center) },
		setMapZoom(zoom) { this.$set(this.map, "zoom", zoom) },
		setMapOptions(options) {
			for(let opt in options) {
				if(opt in this.map.options){
					this.map.options[opt] = options[opt]
				}
			}
		},
		setMapStyles() {
			this.$set(this.map.options, "styles", this.mapStyles)
		},
		initIcons() {
			let createIcon = (url) => {
				let size = this.mapMarkerSize
				return {
					url: url,
					size: new this.google.maps.Size(size, size),		// 顯示圖片大小
					// origin: new this.google.maps.Point(0, 0),		// 起始位置，通常為0
					// anchor: new this.google.maps.Point(19, 40),		// 移動marker 數字越大往右和往上移動
					scaledSize: new this.google.maps.Size(size, size),	// 圖片實際大小，通常與size一樣大小
					labelOrigin: new this.google.maps.Point(this.mapMarkerSize / 4 * 3, 0),
				}
			}
			let markerIcons = {
				marker: mapIcons.marker,
				largeMarker: mapIcons.large_marker,
			}
			Object.keys(markerIcons).forEach((markerType) => {
				let icons = markerIcons[markerType]
				this.icons[markerType] = Object.keys(icons).reduce((obj, key) => {
					obj[key] = createIcon(icons[key])
					return obj;
				}, {})
			})
		},
		initLayers() {
			this.layers.forEach((layerOptions, index) => {
				this.addLayer(layerOptions)
			})
		},
		formatFeatureGeoJson(feature) {
			let getFeatureCoordinates = (type, position) => {
				switch(type) {
					case 'Point':
						return [position.lng, position.lat];
					case 'LineString':
						return position.map(pos => getFeatureCoordinates('Point', pos));
					case 'Polygon':
						return position.map(pos => getFeatureCoordinates('LineString', pos));
					case 'MultiPoint':
						return position.map(pos => getFeatureCoordinates('Point', pos));
					case 'MultiLineString':
						return position.map(pos => getFeatureCoordinates('LineString', pos));
					case 'MultiPolygon':
						return position.map(pos => getFeatureCoordinates('Polygon', pos));
				}
			}
			// ['Point', 'LineString', 'Polygon', 'MultiPoint', 'MultiLineString', 'MultiPolygon']
			return {
				id: feature.id ?? undefined,
				type: 'Feature',
				geometry: {
					type: feature.type,
					coordinates: getFeatureCoordinates(feature.type, feature.position),
				},
				properties: {
					selected: feature.selected ?? false,
					...feature.properties ?? {}
				},
			}
		},
		addLayer(layerOptions) {
			let defaultIconColor = this.nextMarkerIconColor ?? 'red'
			let iconColor = layerOptions.style && layerOptions.style.iconColor ? layerOptions.style.iconColor : defaultIconColor
			let icon = layerOptions.style && layerOptions.style.icon ? layerOptions.style.icon : this.icons.marker[iconColor]
			let borderedIcon = layerOptions.style && layerOptions.style.icon ? layerOptions.style.icon : this.icons.largeMarker[iconColor]
			let layer = {
				name: layerOptions.name,
				dataObject: null,
				options: layerOptions.options ?? {},
				style: {
					...layerOptions.style,
					iconColor: icon === this.icons.marker[iconColor] ? iconColor : undefined,
					icon: icon,
				},
				type: layerOptions.type,
				features: [],
				selectedDataObject: null,
				selectedStyle: {
					...layerOptions.style,
					iconColor: icon === this.icons.marker[iconColor] ? iconColor : undefined,
					icon: borderedIcon ?? icon,
				}
			}
			// 建立圖層
			Object.keys(this.layerTypes).forEach(type => {
				let objectKey = `${this.layerTypes[type]}DataObject`.toCamelCase()
				layer[objectKey] = new this.google.maps.Data({
					map: this.map.mapObject,
					style: layer[`${this.layerTypes[type]}Style`.toCamelCase()],
					...layer.options,
				});
			})
			if(layer.type === 'points') {
				let clickListener = (e) => {
					let feature = e.feature
					let info = feature.getProperty('info')
					let actions = feature.getProperty('actions')
					let isMarker = feature.getGeometry().getType() === 'Point';
					let offset = isMarker ? this.getLayerStyle(layer.name).icon.size.height : 1
					this.openInfoWindow(e.latLng, info, actions, {
						pixelOffset: new this.google.maps.Size(0, -offset)
					})
				}
				let addListener = (e) => {
					let feature = e.feature
					let label = feature.getProperty('label')
					if(label) {
						Object.keys(this.layerTypes).forEach(type => {
							let objectKey = `${this.layerTypes[type]}DataObject`.toCamelCase()
							layer[objectKey].overrideStyle(feature, {
								label: {
									color: "white",
									// fontFamily: "Courier",
									fontSize: "12px",
									// fontWeight: "bold",
									text: label,
									className: 'bg-danger rounded-circle badge',
								},
							});
						})
					}
				}
				Object.keys(this.layerTypes).forEach(type => {
					let objectKey = `${this.layerTypes[type]}DataObject`.toCamelCase()
					layer[objectKey].addListener('click', clickListener);
					layer[objectKey].addListener('addfeature', addListener);
				})
			}
			// 新增圖層
			this.$set(this.map.layers, layer.name, layer)
			// this.map.layers[layer.name] = layer
			this.map.layerFilter.push(layer.name)
			// 更新圖層features
			this.updateLayerFeatures(layer.name, layerOptions.features ?? [])
			return layer
		},
		clearLayers() {
			Object.keys(this.map.layers).forEach(key => {
				this.clearLayer(key)
			})
			// this.map.layers = {}
		},
		clearLayer(key) {
			if(this.isLayerExists(key)) {
				let layer = this.map.layers[key]
				Object.keys(this.layerTypes).forEach(type => {
					let objectKey = `${this.layerTypes[type]}DataObject`.toCamelCase()
					layer[objectKey].forEach(feature => layer[objectKey].remove(feature));
				})
				layer.features.splice(0, layer.features.length)
			}
		},
		deleteLayer(key) {
			if(this.isLayerExists(key)) {
				let layer = this.map.layers[key]
				this.clearLayer(key)
				Object.keys(this.layerTypes).forEach(type => {
					let objectKey = `${this.layerTypes[type]}DataObject`.toCamelCase()
					layer[objectKey].setMap(null);
				})
				delete this.map.layers[key];
			}
		},
		getLayerStyle(key) {
			if(this.isLayerExists(key)) {
				let layer = this.map.layers[key]
				return typeof layer.dataObject.getStyle() === 'function' ? layer.dataObject.getStyle()() : layer.dataObject.getStyle()
			}
			return undefined
		},
		isLayerExists(key) {
			let layer = this.map.layers[key]
			return !!(layer && layer.dataObject)
		},
		updateLayers() {
			Object.keys(this.map.layers).forEach(key => {
				let layer = this.layers.find(l => l.name === key)
				if(!layer) this.deleteLayer(key)
			})
			this.layers.forEach((layerOptions, index) => {
				if(!this.isLayerExists(layerOptions.name)) {
					this.addLayer(layerOptions)
				}
				else {
					this.updateLayerFeatures(layerOptions.name, layerOptions.features)
				}
			})
			this.map.layerFilter = [...new Set([...this.map.layerFilter])]
			// this.viewAllLayers()
		},
		updateLayerFeatures(key, features) {
			if(this.isLayerExists(key)) {
				let layer = this.map.layers[key]
				features = features.map(feature => this.formatFeatureGeoJson(feature))
				if(!isEqual(layer.features, features)) {
					// this.clearLayer(key)
					let oldFeatures = layer.features.reduce((obj, feature) => {
						obj[feature.properties.id] = feature
						return obj
					}, {})
					let newFeatures = features.filter(feature => !isEqual(oldFeatures[feature.properties.id], feature))
					layer.features = [
						...features.filter(feature => isEqual(oldFeatures[feature.properties.id], feature)),
						...newFeatures,
					]
					this.addLayerGeoJson(key, newFeatures)
				}
			}
		},
		viewAllLayers() {
			// TODO
			let group = new this.google.maps.LatLngBounds();
			Object.values(this.map.layers).forEach(layer => {
				Object.keys(this.layerTypes).forEach(type => {
					let objectKey = `${this.layerTypes[type]}DataObject`.toCamelCase()
					layer[objectKey].forEach((feature) => {
						feature.getGeometry().forEachLatLng((LatLng) => {
							group.extend(LatLng);
						});
					});
				})
			});
			this.map.mapObject.fitBounds(group);
		},
		filterLayers() {
			Object.keys(this.map.layers).forEach(key => {
				let layer = this.map.layers[key]
				Object.keys(this.layerTypes).forEach(type => {
					let objectKey = `${this.layerTypes[type]}DataObject`.toCamelCase()
					layer[objectKey].forEach((feature) => {
						let contains = true;
						feature.getGeometry().forEachLatLng((position) => {
							contains &= this.insideMapBounds(position)
						});
						if(!contains) layer[objectKey].remove(feature)
					});
				})
				this.filterLayerFeatures(key)
			});
		},
		filterLayerFeatures(key) {
			if(!this.isLayerExists(key)) return [];
			let layer = this.map.layers[key]
			let showFeatures = []
			Object.keys(this.layerTypes).forEach(type => {
				let objectKey = `${this.layerTypes[type]}DataObject`.toCamelCase()
				layer[objectKey].forEach(feature => showFeatures.push(feature.getId()))
			})
			let addFeatures = layer.features.filter(feature => !showFeatures.includes(feature.id) && feature.geometry.coordinates.flat(5).chunk(2).map(pos => {
				return { lat: pos[1], lng: pos[0] };
			}).every(pos => this.insideMapBounds(pos)))
			this.addLayerGeoJson(key, addFeatures)
		},
		addLayerGeoJson(key, features) {
			if(features.length && this.isLayerExists(key)) {
				let layer = this.map.layers[key]
				let featureIds = features.map(feature => feature.properties.id)
				Object.keys(this.layerTypes).forEach(type => {
					let objectKey = `${this.layerTypes[type]}DataObject`.toCamelCase()
					layer[objectKey].forEach(feature => {
						let id = feature.getId()
						if(featureIds.includes(id)) {
							layer[objectKey].remove(feature)
						}
					})
					layer[objectKey].addGeoJson({
						type: 'FeatureCollection',
						name: key,
						features: features.filter(feature => (!!feature.properties.selected) === (type === 'selected')),
					});
				})
			}
		},
		openInfoWindow(position, content, buttons, options) {
			this.map.infoWindow.position = position
			this.map.infoWindow.content = content
			this.map.infoWindow.buttons = buttons
			this.map.infoWindow.options = {
				...this.map.infoWindow.options,
				...options,
			}
			this.map.infoWindow.open = true;
		},
		closeInfoWindow() {
			this.map.infoWindow.open = false;
		},
		clickInfoWindowButton(onclick, params) {
			return onclick(params)
		},
		initDrawingManager() {
			deepSet(this.map.drawing.drawingOptions, this.configs.drawingOptions)
			this.map.drawing.drawingManager = new this.google.maps.drawing.DrawingManager(this.map.drawing.drawingOptions)
			this.map.drawing.drawingManager.setMap(this.map.mapObject);
			this.google.maps.event.addListener(this.map.drawing.drawingManager, 'overlaycomplete', this.initOverlay);
		},
		initOverlay(evt) {
			let overlay = evt.overlay
			let vueComponent = this
			let onChange = function(evt) {
				vueComponent.changeOverlay(evt, overlay)
			}
			switch(evt.type) {
				case 'circle':
					this.google.maps.event.addListener(overlay, 'radius_changed', onChange);
					this.google.maps.event.addListener(overlay, 'center_changed', onChange);
					// calSelectedPointsInCircle(overlay.getCenter(), overlay.getRadius());
					break;
				case 'polygon':
					this.google.maps.event.addListener(overlay.getPath(), 'set_at', onChange);
					this.google.maps.event.addListener(overlay.getPath(), 'insert_at', onChange);
					this.google.maps.event.addListener(overlay.getPath(), 'remove_at', onChange);
					// calSelectedPointsInPolygon(overlay.getPath());
					break;
				case 'rectangle':
					this.google.maps.event.addListener(overlay, 'bounds_changed', onChange);
					break;
				case 'marker':
					this.google.maps.event.addListener(overlay, 'dragend', onChange);
					break;
				default: 
					break;
			}
			this.map.drawing.drawingOverlays.push(evt)
			this.$emit('addOverlay', evt)
		},
		changeOverlay(evt, overlay) {
			this.$emit('changeOverlay', evt, overlay)
		},
		clearDrawingOverlays() {
			this.map.drawing.drawingOverlays.forEach(o => o.overlay.setMap(null))
			this.map.drawing.drawingOverlays.clear()
		},
		getFeaturesInsideDrawingOverlays() {
			let insideCircle = (position, center, radius) => {
				let distance = function(p1, p2) {
					let rad = function(x) { return x * Math.PI / 180; };
					let R = 6378137; // Earth’s mean radius in meter
					let dLat = rad(p2.lat - p1.lat);
					let dLong = rad(p2.lng - p1.lng);
					let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
					let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
					return R * c; // returns the distance in meter
				};
				return distance(position, {lat: center.lat(), lng: center.lng()}) < radius;
			}
			let insidePolygon = (position, path) => {
				let inside = function(point, vs) {
					let x = point.lat, y = point.lng;
					let inside = false;
					for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
						let xi = vs.getAt(i).lat(), yi = vs.getAt(i).lng();
						let xj = vs.getAt(j).lat(), yj = vs.getAt(j).lng();
						if (((yi > y) != (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi))
							inside = !inside;
					}
					return inside;
				};
				return inside(position, path)
			}
			let insideRectangle = (position, bounds) => {
				let inside = function(pos, bounds) {
					let ne = bounds.getNorthEast();
					let sw = bounds.getSouthWest();
					return pos.lng<ne.lng() && pos.lng>sw.lng() && pos.lat<ne.lat() && pos.lat>sw.lat();
				};
				return inside(position, bounds)
			}
			let insideOverlay = (position, geo) => {
				let overlay = geo.overlay
				switch(geo.type) {
					case 'circle':
						return insideCircle(position, overlay.getCenter(), overlay.getRadius())
					case 'polygon':
						return insidePolygon(position, overlay.getPath())
					case 'rectangle':
						return insideRectangle(position, overlay.getBounds())
					case 'marker':
					default: 
						return false;
				}
			}
			let features = []
			this.map.drawing.drawingOverlays.forEach(overlay => {
				Object.keys(this.map.layers).filter(key => this.map.layerFilter.includes(key)).forEach(key => {
					let layer = this.map.layers[key]
					features.push(layer.features.filter(feature => 
						feature.geometry.coordinates.flat(5).chunk(2).map(pos => {
							return { lat: pos[1], lng: pos[0] };
						}).every(pos => insideOverlay(pos, overlay))
					))
				});
			})
			return features.flat().uniqBy('id')
		},
		filterOverlays() {
			if(!this.complete) return
			this.map.overlays = this.overlays.filter(overlay => {
				switch(overlay.type) {
					case 'polygon':
						return (overlay.path && overlay.path.some(pos => this.insideMapBounds(pos))) || (overlay.paths && overlay.path.some(path => path.some(pos => this.insideMapBounds(pos))))
					case 'polyline':
						return overlay.path.some(pos => this.insideMapBounds(pos))
					case 'rectangle':
						return this.insideMapBounds(overlay.bounds.sw) || this.insideMapBounds(overlay.bounds.ne) || this.insideMapBounds({lat: overlay.bounds.sw.lat, lng: overlay.bounds.ne.lng}) || this.insideMapBounds({lat: overlay.bounds.ne.lat, lng: overlay.bounds.sw.lng})
					case 'circle':
						return this.insideMapBounds(overlay.center, overlay.radius, 0) || this.insideMapBounds(overlay.center, overlay.radius, 90) || this.insideMapBounds(overlay.center, overlay.radius, 180) || this.insideMapBounds(overlay.center, overlay.radius, 360)
				}
				return false
			}).map(overlay => {
				return {
					...this.map.overlayConfigs,
					...overlay
				}
			})
		},
		initGeocoder() {
			this.map.geocode.geocoder = new this.google.maps.Geocoder();
			if(this.address) {
				this.map.geocode.address = this.address
				this.geocodeAddress(this.map.geocode.address)
			}
		},
		geocodeAddress(address) {
			if(this.map.geocode.geocoder) {
				this.map.geocode.geocoder.geocode({ 'address': address}, (results, status) => {
					this.$emit("geocoderAddress", results, status)
				})
			}
		},
		insideMapBounds(position) {
			let bounds = this.map.mapObject.getBounds()
			return bounds.contains(new this.google.maps.LatLng(position))
		},
		focusToLatLng(position) {
			this.setMapCenter(position);
			this.setMapZoom(16);
		},
		refresh() {
			let mapCenter = this.map.mapObject.getCenter();
			let center = { lat: mapCenter.lat(), lng: mapCenter.lng() }
			this.setMapCenter({lat: center.lat - 0.01, lng: center.lng - 0.01})
			this.$nextTick(() => {
				this.setMapCenter(center)
			})
		},
	}
}
</script>

<style scoped>
.gmap-wrapper, .gmap {
	width: 100%;
	height: 100%;
}
.loading {
	width: 100%;
	height: 100%;
	/* background-color: transparent; */
	background-repeat: no-repeat;
	background-size: 50px;
	background-position: center;
	min-height: 200px;
}
.gmap-control-wrapper {
	position: absolute;
	z-index: 3;
	margin: 10px;
	border-radius: 2px;
	background-color: white;
	box-shadow: 0px 1px 1.5px 1px #ccc5, 0px .5px 1px 1px #eee9;
	/* height: 40px; */
	font-size: 1rem;
	padding: 0 .25rem;
}
.gmap-filter {
	bottom: 0;
}
</style>
