import _ from "lodash";
import stringSimilarity from 'string-similarity/src/index.js';

(function (window, undefined) {
	String.prototype.toTitleCase = function () {
		return _.capitalize(this.toString())
	}
	String.prototype.toCamelCase = function () {
		return _.camelCase(this.toString())
	}
	String.prototype.toSnakeCase = function () {
		return _.snakeCase(this.toString())
	}
	String.prototype.toStudlyCase = function () {
		return _.upperFirst(_.camelCase(this.toString()))
	}
	String.prototype.parseUrl = function () {
		let regex = "^(?:(?<scheme>\\w+)://)?";
		regex += "(?:(?<login>\\w+):(?<pass>\\w+)@)?";
		regex += "(?<host>(?:(?<subdomain>[\\w\\.]+)\\.)?" + "(?<domain>\\w+\\.(?<extension>\\w+)))";
		regex += "(?::(?<port>\\d+))?";
		regex += "(?<path>[\\w/]*/(?<file>\\w+(?:\\.\\w+)?)?)?";
		regex += "(?:\\?(?<arg>[\\w=&]+))?";
		regex += "(?:#(?<anchor>\\w+))?";
		regex = `/${regex}/`;
		let string = this.toString()
		let match = string.match(regex)
		return match ? match.groups : null
	}
	String.prototype.parseFilePath = function (key) {
		let regex = `^((?<dirname>.*)\\/)?(?<filename>[^\\/]+)\\.(?<extension>[^\\.]+)$`;
		regex = new RegExp(regex);
		let string = this.toString()
		let match = string.match(regex)
		if(!match) return null;
		let groups = match ? {
			...match.groups,
			basename: `${match.groups.filename}.${match.groups.extension}`
		} : null
		return key ? groups[key] : groups
	}
	String.prototype.normalizePath = function(splitByDot=false) {
		let string = this.toString()
		return string.parsePathToArray().map((k, i) => i ? (splitByDot ? `.${k}` : `[${k}]`) : k).join('')
	}
	String.prototype.parsePathToArray = function() {
		let string = this.toString()
		return _.toPath(string);
	}
	String.prototype.trim = function(chars) {
		return _.trim(this.toString(), chars);
	}
	String.prototype.pad = function(length=0, char=' ') {
		return _.pad(this.toString(), length, char)
	}
	String.prototype.padStart = function(length=0, char=' ') {
		return _.padStart(this.toString(), length, char)
	}
	String.prototype.padEnd = function(length=0, char=' ') {
		return _.padEnd(this.toString(), length, char)
	}
	String.prototype.similarity = function(string) {
		return stringSimilarity.compareTwoStrings(this.toString(), string)
	}
	String.prototype.similarString = function(strList) {
		return stringSimilarity.findBestMatch(this.toString(), strList)
	}
	String.prototype.similarStringByKey = function(list, key) {
		if(!list.length) { return undefined; }
		let match = stringSimilarity.findBestMatch(this.toString(), list.mapValues(key))
		let index = match.bestMatchIndex
		return {
			...list[index],
			match: match,
		}
	}
}(window));

