<template>
	<loading v-if="loading"/>
	<div class="w-100" v-else>
		<h5>案件資料種類管理</h5>
		<b-row>
			<div class="col-md-8 col-12 py-3 px-md-3 px-1">
				<bv-table :columns="table.columns" :data="projectDataTypeList" :configs="table.configs" :actions="table.actions"></bv-table>
			</div>
			<div class="col-md-4 col-12">
				<b-card no-body class="border-0 control-board">
					<b-card-body>
						<b-tabs class="tabs-wrapper" fill active-nav-item-class="tab-title font-weight-bold" active-tab-class="tab-title" fluid>
							<b-tab class="tab-body" title="新增資料種類" active>
								<project-data-type-creator @create="reload" />
							</b-tab>
							<b-tab class="tab-body" title="編輯資料種類" :disabled="!editable" :active="editable">
								<project-data-type-editor :project-data-type="editingProjectDataType" @update="reload" @cancel="cancelEditing"/>
							</b-tab>
						</b-tabs>
					</b-card-body>
				</b-card>
			</div>
		</b-row>
	</div>
</template>

<script>
import BvTable from '@/components/Table/BvTable.vue'
import ProjectDataTypeCreator from '@/components/Project/DataType/ProjectDataTypeCreator.vue'
import ProjectDataTypeEditor from '@/components/Project/DataType/ProjectDataTypeEditor.vue'
import { deepCopy, checkAllTrue } from '@/utils/assist';
import Loading from '@/components/Loading/Loading.vue';

export default {
	name: 'ProjectDataTypeManager',
	components:{
		BvTable,
		ProjectDataTypeCreator,
		ProjectDataTypeEditor,
		Loading
	},
	props: {
		bidID: {
			default: 0
		},
		from: {
			default: null
		}
	},
	data() {
		return {
			call: { getProjectDataType: false },
			loading: true,
			projectDataTypeList: [],
			table: {
				columns: [
					{ key: 'id', label: 'ID', sortable: true, searchable: true, hide: !this.$store.getters.isDeveloper },
					{ key: 'name', label: '名稱', sortable: true, searchable: true },
					{ key: 'data_type', label: '資料種類', sortable: true, searchable: true },
				],
				actions: [
					{
						//icon, label
						button: { icon: "fas fa-edit", label: "編輯" },
						//function, link, route
						action: { type: 'function', function: this.editProjectDataType },
						hide: (index) => this.editing === index
					},
					{
						//icon, label
						button: { icon: "far fa-edit", label: "取消編輯" },
						//function, link, route
						action: { type: 'function', function: this.cancelEditing },
						hide: (index) => this.editing !== index
					}
				],
				configs: {
					busy: true,
					actionsColumn: true,
					perPage: 10
				}
			},
			editing: -1,
			updating: false,
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	created() {
		this.getProjectDataType()
	},
	mounted() {
	},
	computed: {
		editable() {
			return this.editing >= 0
		},
		editingProjectDataType() {
			return this.projectDataTypeList[this.editing]
		}
	},
	methods:{
		onLoadEnd() {
		},
		getProjectDataType() {
			this.call.getProjectDataType = false
			this.$axios.getProjectDataType((response) => {
				this.projectDataTypeList = response.data
				this.table.configs.busy = false;
				this.call.getProjectDataType = true
			},(error) => {
				this.call.getProjectDataType = true
			})
		},
		editProjectDataType(index) {
			this.editing = index;
		},
		cancelEditing(index) {
			this.editing = -1;
		},
		reload() {
			location.reload();
		}
	}
}
</script>

<style scoped>
.card-wrapper {
	padding: .5rem;
}
.card {
	height: 100%;
}
.tab-body {
	padding: .5rem 1rem;
	border: 1px solid #dee2e6;
	border-top: 0;
	height: 100%;
}
.tabs-wrapper {
	height: 100%;
}
.tabs-wrapper > .tab-content {
	height: 100%;
}
</style>