<template>
	<div>
		<loading class="info-wrapper" v-if="loading"/>
		<template v-else>
			<div v-for="(item, key) in menu" :key="key">
				<hr v-if="key > 0"/>
				<h6 class="px-md-4" v-if="item.children"><i class="fa-fw" :class="item.icon"></i> {{item.name}}</h6>
				<b-row class="p-2">
					<template v-if="item.children">
						<div class="col-md-4 col-12 p-1" v-for="(item, key) in item.children" :key="key">
							<b-card class="shadow">
								<template v-if="item.children">
									<b class="p-1 mb-1"><i class="fa-fw" :class="item.icon"></i> {{item.name}}</b>
									<div class="p-1">
										<b-list-group-item class="border-0 py-1" v-for="(item, key) in item.children" :key="key">
											<router-link class="router-link" :to="item.path"><i class="fa-fw" :class="item.icon"></i> {{item.name}}</router-link>
										</b-list-group-item>
									</div>
								</template>
								<router-link class="router-link" :to="item.path" v-else><i class="fa-fw" :class="item.icon"></i> {{item.name}}</router-link>
							</b-card>
						</div>
					</template>
					<div class="col-md-4 col-12 p-1" v-else>
						<b-card class="shadow">
							<router-link class="router-link" :to="item.path"><i class="fa-fw" :class="item.icon"></i> {{item.name}}</router-link>
						</b-card>
					</div>
				</b-row>
			</div>
		</template>
	</div>
</template>

<script>
import Loading from '@/components/Loading/Loading.vue'
import { checkAllTrue } from '@/utils/assist';
export default {
	components: {
		Loading,
	},
	data() {
		return {
			call: { getUnitList: false },
			loading: true,
			project_category_keys: ["party_a"],
			unit_list: [],
		}
	},
	created() {
		this.getUnitList()
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	computed: {
		menu() {
			return [
				{ name: '案件管理', icon: '', children: [
					{ name: '新增案件', icon: 'far fa-plus-square', path: { name: '新增案件' }, /*hide: !this.$store.getters.isDeveloper*/ },
				]},
				{ name: '案件列表', icon: '', children: this.getProjectMenu() }
			]
		}
	},
	methods: {
		onLoadEnd() {
		},
		getUnitList() {
			this.call.getUnitList = false
			let types_index = this.$store.getters.enum('unit.type').filter(type => this.project_category_keys.includes(type.key)).map(type => type.index)
			this.$axios.getUnitList({types_index: types_index}, (response) => {
				this.unit_list = response.data;
				this.call.getUnitList = true
			}, (error) => {
				this.call.getUnitList = true
			})
		},
		getProjectMenu() {
			return this.unit_list.map(unit => {
				return {
					name: unit.name, icon: this.getUnitIcon(unit.name),
					children: [
						{ name: '案件列表', icon: 'fas fa-list', path: { name: '案件列表', query: { party_a_id: unit.id } } },
						{ name: '工地列表', icon: 'far fa-list-alt', path: { name: '工地列表', query: { "party_a_id[]": unit.id } } },
					]
				}
			})
		},
		getUnitIcon(unit_name) {
			if(unit_name.includes('自來水')) return 'fas fa-faucet';
			if(unit_name.includes('電信')) return 'fas fa-phone-alt';
			return 'fas fa-credit-card';
		}
	}
}
</script>

<style scoped>
a.router-link {
	color: #4c6b8a;
}
a.router-link:hover {
	text-decoration: none;
	color: #284a70;
	filter: drop-shadow(0 0 1px #0005);
}
</style>