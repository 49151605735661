<template>
	<div>
		<loading mode="fixed" image="1" size="lg" v-if="apiLoading"/>
		<b-card class="shadow" id="Card">
			<b-card-title class="d-flex m-0 p-0 justify-content-between">
				<div class="flex-fill">{{title}}</div>
				<b-row class="m-0 p-0 text-secondary align-items-center" v-if="!loading">
					<div class="font-size-sm" style="align-self: flex-end;">
						道管資訊更新時間: <br>{{Date(crawler.time).format('yyyy/MM/dd hh:mm')}}
					</div>
					<b-button class="border-0 bg-transparent d-flex px-1" @click="crawlerLogModal.show=true">
						<i class="font-size-md fa-fw fas" :class="`${crawler.success ? 'fa-info-circle' : 'fa-exclamation-triangle'} ${getTextClass(crawler.success)}`"></i>
					</b-button>
				</b-row>
			</b-card-title>
			<loading v-if="loading"/>
			<vue-table :columns="table.columns" :data="projects" :paging="table.paging" @fetch="fetchData" v-show="!loading"></vue-table>
		</b-card>
		<modal v-for="(modal, key) in modals" :key="key" v-model="modal.show" centered :size="modal.size" :title="modal.title" @ok="modal.submit">
			<input-form :options="modal.form.options" :actions="modal.form.actions" v-model="modal.form.data" v-if="modal.type === 'form'" />
			<input-form-group :groups="modal.form.groups" :actions="modal.form.actions" v-model="modal.form.data" v-if="modal.type === 'formGroup'" />
			<component :ref="key" :is="modal.component.name" v-bind="modal.component.params" v-model="modal.component.data" v-else-if="modal.type === 'component'"></component>
			<template v-else-if="modal.type === 'text'"> {{modal.text}} </template>
		</modal>
		<modal v-model="crawlerLogModal.show" centered size="lg" title="道管資訊更新紀錄" v-if="!loading" hide-footer>
			更新時間：{{Date(crawler.time).format('yyyy/MM/dd hh:mm')}}
			<hr>
			<b-list-group-item v-for="(log, key) in crawler.logs" :key="key">
				<div class="d-flex justify-content-between align-items-center collapsed-icon" v-b-toggle="`${key}`">
					<div class="d-flex align-items-center justify-content-between flex-fill">
						<b-row class="m-0 p-0">
							<div v-html="getIconHtml(log.success)" class="d-flex align-items-center px-1"></div> {{key}}
						</b-row>
						<div class="font-size-sm d-flex align-items-end px-1 px-md-3 text-hint">{{Date(log.time).format('yyyy/MM/dd hh:mm')}}</div>
					</div>
				</div>
				<b-collapse :id="key" class="px-md-2 p-1" accordion="crawlerLogs" v-if="log.download">
					<b-list-group-item class="py-1 py-md-2 d-flex border-0" v-for="(user, name) in log.download.users" :key="name">
						<div v-html="getIconHtml(user.success)" class="d-flex align-items-center px-1"></div>
						{{name}}
						<div class="font-size-sm d-flex align-items-end px-1" :class="user.success ? 'text-hint' : 'text-danger'">{{user.message}}</div>
					</b-list-group-item>
					<hr>
					<b-row class="m-0 p-0 px-1 px-md-2">
						<div v-html="getIconHtml(log.api && log.api.success)" class="d-flex align-items-center px-1"></div>
						同步
						<div class="font-size-sm d-flex align-items-end px-1" :class="log.api && log.api.success ? 'text-hint' : 'text-danger'">{{log.api ? log.api.message : '失敗'}}</div>
					</b-row>
				</b-collapse>
				<b-row class="m-0 p-1 px-md-2 justify-content-end">
					<b-button class="form-btn submit-btn" @click="exportPipelineCenterCrawlerProjectsList(key, log.time)">下載清冊</b-button>
				</b-row>
			</b-list-group-item>
		</modal>
	</div>
</template>

<script>
import VueTable from '@/components/Table/VueTable.vue'
import InputForm from '@/components/Input/InputForm';
import InputFormGroup from '@/components/Input/InputFormGroup';
import ProjectUnitReportEditor from '@/components/Project/Form/ProjectUnitReportEditor';
import Modal from '@/components/Modal/Modal'
import Loading from '@/components/Loading/Loading.vue'
import { checkAllTrue } from '@/utils/assist';
import fileManager from '@/utils/file';

export default {
	name: 'ProjectFormList',
	components:{
		VueTable,
		InputForm,
		InputFormGroup,
		ProjectUnitReportEditor,
		Modal,
		Loading,
	},
	props: {
		group: {
			type: Object,
		},
		permission: {
			type: Object,
		},
		title: {
			type: String,
			default: ""
		},
		filter: {
			type: Object,
			default: () => { return {}; }
		}
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			loading: true,
			call: { getProjectList: false, getPipelineCenterCrawlerLogs: false },
			apiLoading: false,
			apiCall: {},
			table: {
				columns: [
					{ title: 'ID', name: 'key', group: '#', sortField: 'id', searchField: 'id', sticky: 'left',
						// formatter: (value) => `<a class="table-btn" href="">${value}</a>`,
						actions: [{
							// icon, label
							button: { type: 'icon', icon: "fas fa-external-link-alt fa-sm", label: '', title: '', class: '' },
							// function, link, route
							action: { type: 'route', route: this.routeToProjectInfo, target: '_blank' },
						}],
					},
					{ title: '路證編號', name: 'excavation_permit', group: '案件屬性', sortField: 'excavation_permit', searchField: 'excavation_permit', sticky: 'left' },
					{ title: '首次收件日', name: 'first_received_at', group: '案件屬性', sortField: 'first_received_at', searchField: 'first_received_at' },
					{ title: '收件日', name: 'received_at', group: '案件屬性', sortField: 'received_at', searchField: 'received_at' },
					{ title: '完整交付日', name: 'completely_assigned_at', group: '案件屬性', sortField: 'completely_assigned_at', searchField: 'completely_assigned_at' },
					{ title: '派工日', name: 'assigned_at', group: '案件屬性', sortField: 'assigned_at', searchField: 'assigned_at' },
					{ title: '應測日', name: 'measured_at', group: '案件屬性', sortField: 'measured_at', searchField: 'measured_at' },
					{ title: 'GML1期限', name: 'file_made_at', group: '案件屬性', sortField: 'file_made_at', searchField: 'file_made_at' },
					{ title: '道管', name: 'pipeline_center.name', group: '案件屬性', sortField: 'pipeline_center_name', searchField: 'pipeline_center_name' },
					{ title: '區處', name: 'party_a_branch.name', group: '案件屬性', sortField: 'party_a_branch_name', searchField: 'party_a_branch_name' },
					{ title: '工程', name: 'construction', group: '案件屬性', sortField: 'construction_name', searchField: 'construction_name',
						formatter: (construction) => construction.abbreviation ?? construction.name
					},
					{ title: '監工', name: 'supervisor.name', group: '案件屬性', sortField: 'supervisor_name', searchField: 'supervisor_name' },
					{ title: '地址', name: 'address', group: '案件屬性', sortField: 'address', searchField: 'address' },
					{ title: '管線長度', name: 'pipeline_length', group: '案件屬性',
						formatter: v => (v !== null) && v >= 0 ? `${Number(v.toFixed(3))}M` : '-'
					},
					{ title: '系統狀態', name: 'status', group: '案件狀態', sortField: 'status', searchField: 'status',
						actions: [{
							// icon, label
							button: { type: 'icon', icon: "fas fa-redo-alt fa-sm", label: '更新', title: '更新案件狀態', class: '' },
							// function, link, route
							action: { type: 'function', function: (data, index) => this.showMessageModal(data, index, this.checkProjectStatus, '確認檢查案件狀態') },
						}],
					},
					{ title: '施工資料', name: 'construction_data', group: '案件狀態', 
						formatter: (value) => this.getIconHtml(value.every(v => v.is_pass)),
						actions: [{
							// icon, label
							button: { type: 'icon', icon: "fas fa-pen fa-sm", label: '編輯', title: '編輯案件施工資料', class: '' },
							// function, link, route
							action: { type: 'function', function: this.showConstructionDataEditor },
						}],
					},
					{ title: '測量狀態', name: '', group: '案件狀態',
						formatter: (project) => `<font class="${this.getTextClass(project.is_measured)}">${this.getIconHtml(project.is_measured)} ${project.actually_measured_at ?? ''}</font>`,
						actions: [{
							// icon, label
							button: { type: 'icon', icon: "fas fa-paperclip fa-sm", label: '成果', title: '檢視案件成果', class: '' },
							// function, link, route
							action: { type: 'route', route: this.routeToProjectResult, target: '_blank' },
							visible: (project) => project.is_measured,
						}],
					},
					{ title: 'GML狀態', name: '', group: '案件狀態',
						formatter: (project) => {
							if(!project.gml_status_key) return ''
							let statusEnumKey = `project.${project.project_type_data.name}_status.file_made`
							if(project.gml_status_key === this.$store.getters.enum(statusEnumKey).key) return '未上傳'
							return `${project.gml_status}<br/>上傳時間：`
						},
						actions: [{
							// icon, label
							button: { type: 'icon', icon: "fas fa-pen fa-sm", label: '編輯', title: '編輯案件施工資料', class: '' },
							// function, link, route
							action: { type: 'function', function: this.showUnitReportsEditor },
							visible: (project) => project.gml_status ?? false,
						}],
					},
					{ title: '四支距狀態', name: '', group: '案件狀態',
						formatter: (project) => {
							if(!project.is_measured) return '';
							if(project.positioning_points.length > project.points_needing_positioning_count) return `多餘(${project.positioning_points.length}/${project.points_needing_positioning_count})`
							if(project.positioning_points.length < project.points_needing_positioning_count) return `未上傳(${project.positioning_points.length}/${project.points_needing_positioning_count})`
							if(project.points_needing_positioning_count <= 0) return '-';
							let unfinished = project.positioning_points.filter(point => point.status_index !== this.$store.getters.enum('point.positioning_point_status.finished').index).length
							if(unfinished === 0) return `已完成(${project.points_needing_positioning_count})`
							return `未完成(${project.points_needing_positioning_count - unfinished}/${project.points_needing_positioning_count})`
						}
					},
					{ title: '道管狀態', name: 'dig_project.status', group: '道管資訊',
						formatter: (status) => `${this.getIconHtml(status)} ${status ?? ''}`
					},
					{ title: 'GML狀態', name: 'dig_project', group: '道管資訊',
						formatter: (project) => {
							let status = project ? project.gml_status : null
							return project ? `${this.getIconHtml(status)} ${status ?? ''}` : ''
						}
					},
					// 道管資訊: GML2日期	案件狀態	更新日期	描述	備註
					// 其他資訊: 工程簡稱	監工	預計銑鋪
					// 測量日誌	道管狀態	最終案件狀態
				],
				paging: {},
			},
			projects: [],
			modals: {
				messager: {
					show: false,
					text: '',
					type: 'text',
					data: {},
					action: undefined,
					submit: this.onModalSubmit,
				},
				constructionDataEditor: {
					show: false,
					size: 'lg',
					title: '更新案件施工資料',
					type: 'form',
					form: {
						options: [],
						actions: {submit: false, cancel:false},
						data: {},
					},
					data: {},
					submit: this.updateConstructionData,
				},
				unitReportsEditor: {
					show: false,
					size: 'lg',
					title: '更新案件報告',
					type: 'component',
					component: {
						name: 'ProjectUnitReportEditor',
						params: {},
						data: {},
					},
					data: {},
					submit: this.updateUnitReports,
				},
			},
			query: {},
			crawler: undefined,
			crawlerLogModal: {
				show: false,
				showLogDetail: {},
			},
		}
	},
	created() {
		this.getProjectList()
		this.getPipelineCenterCrawlerLogs()
	},
	destroyed() {
	},
	mounted() {
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		apiCall: {
			deep: true,
			immediate: true,
			handler(value) {
				this.apiLoading = checkAllTrue(value)
			}
		},
	},
	computed: {
		apiQuery() {
			return {
				page: 1,
				per_page: 10,
				...this.filter,
				...this.query ?? {},
				orderBy: {
					id: 'desc',
					...this.filter.orderBy ?? {},
					...(this.query ?? {}).orderBy ?? {},
				},
			}
		},
		constructionDataEditorOptions() {
			return this.modals.constructionDataEditor.data.construction_data.sortBy('project_data_type.id').map((datum) => {
				return {
					key: datum.id,
					type: "radio",
					label: datum.project_data_type.name,
					options: this.$store.getters.enum('project_construction_datum.status'),
					keys: {value: 'index', text: 'text'},
					required: true,
					labelClass: 'col-2 p-0'
				}
			})
		},
	},
	methods:{
		fetchData(query) {
			this.query = query
			this.getProjectList()
		},
		getProjectList() {
			this.call.getProjectList = false;
			this.$axios.getProjectList({
				...this.apiQuery,
				less: 0,
				detail: 1,
			}, (response) => {
				this.projects = response.data.map(project => {
					return {
						key: `${project.project_type_index}-${project.id}`,
						...project,
						tags: project.unit_tags.map(tag => tag.name).join(','),
					}
				})
				this.table.paging = response.paging
				this.call.getProjectList = true
			}, (error) => {
				this.call.getProjectList = true
			})
		},
		getPipelineCenterCrawlerLogs() {
			this.call.getPipelineCenterCrawlerLogs = false;
			this.$axios.getPipelineCenterCrawlerLogs({
				latest: '1',
			}, (response) => {
				this.crawler = response
				this.call.getPipelineCenterCrawlerLogs = true
			}, (error) => {
				this.call.getPipelineCenterCrawlerLogs = true
			})
		},
		routeToProjectResult(project) {
			return {name: '測量成果', params: {projectType: project.project_type_index, projectID: project.id}}
		},
		routeToProjectInfo(project) {
			return {name: '案件資訊', params: {projectType: project.project_type_index, projectID: project.id}}
		},
		showMessageModal(project, index, func, msg) {
			this.modals.messager.data = {
				id: project.id,
				type: project.project_type_index,
			}
			this.modals.messager.action = func
			this.modals.messager.text = msg
			this.modals.messager.show = true
		},
		onModalSubmit() {
			this.modals.messager.action(this.modals.messager.data.type, this.modals.messager.data.id)
		},
		showConstructionDataEditor(project) {
			this.modals.constructionDataEditor.data = {
				id: project.id,
				type: project.project_type_index,
				construction_data: project.construction_data,
			}
			this.modals.constructionDataEditor.form.data = Object.map(project.construction_data.keyBy('id'), 'status_index')
			this.modals.constructionDataEditor.form.options = this.constructionDataEditorOptions
			this.modals.constructionDataEditor.show = true
		},
		updateConstructionData() {
			this.$set(this.apiCall, 'updateMultiProjectConstructionData', false)
			let data = {
				project_construction_data: Object.map(this.modals.constructionDataEditor.form.data, (status, id) => {
					return {
						id: id,
						status: status,
					}
				})
			}
			this.$axios.updateMultiProjectConstructionData(data, (response) => {
				this.getProjectList()
				this.apiCall.updateMultiProjectConstructionData = true;
			}, (error) => {
				this.apiCall.updateMultiProjectConstructionData = true;
			})
		},
		checkProjectStatus(projectType, projectId) {
			this.$set(this.apiCall, 'checkProjectStatus', false)
			this.$axios.checkProjectStatus({
				project_type: projectType,
				project_id: projectId,
				user_id: this.$store.getters.currentUser.id,
			}, (response) => {
				this.getProjectList()
				this.apiCall.checkProjectStatus = true;
			}, (error) => {
				this.apiCall.checkProjectStatus = true;
			})
		},
		showUnitReportsEditor(project) {
			this.modals.unitReportsEditor.data = {
				id: project.id,
				type: project.project_type_index,
				unit_reports: project.unit_reports,
			}
			this.modals.unitReportsEditor.component.params = {
				project: project,
				unitReports: project.unit_reports,
			}
			this.modals.unitReportsEditor.show = true
		},
		updateUnitReports(e) {
			e.preventDefault();
			if(!this.$refs.unitReportsEditor.first.reportFormValidity()) return;
			let data = this.modals.unitReportsEditor.component.data
			this.modals.unitReportsEditor.show = false
			let unitReportStatuses = this.$store.getters.enum('project_unit_report.status').keyBy('key')
			this.updateMultiProjectUnitReports({
				...Object.filter(data, ['action.index', unitReportStatuses.submitted.index]),
				...Object.filter(data, ['action.index', unitReportStatuses.pass.index]),
			})
			this.createMultuUnitReportRejections(Object.filter(data, ['action.index', unitReportStatuses.rejected.index]))
		},
		updateMultiProjectUnitReports(data) {
			if(!Object.size(data)) return;
			data = Object.values(Object.map(this.modals.unitReportsEditor.component.data, (data, id) => {
				return { id: id, ...data, action: undefined }
			}))
			this.$set(this.apiCall, 'updateMultiProjectUnitReports', false)
			this.$axios.updateMultiProjectUnitReports({
				project_unit_reports: data
			}, (response) => {
				this.getProjectList()
				this.apiCall.updateMultiProjectUnitReports = true;
			}, (error) => {
				this.apiCall.updateMultiProjectUnitReports = true;
			})
		},
		createMultuUnitReportRejections(data) {
			if(!Object.size(data)) return;
			data = Object.values(Object.map(this.modals.unitReportsEditor.component.data, (data, id) => {
				return { project_unit_report_id: id, ...data, action: undefined }
			}))
			this.$set(this.apiCall, 'createMultuUnitReportRejections', false)
			this.$axios.createMultuUnitReportRejections({
				project_unit_report_rejections: data
			}, (response) => {
				this.apiCall.createMultuUnitReportRejections = true;
			}, (error) => {
				this.apiCall.createMultuUnitReportRejections = true;
			})
		},
		exportPipelineCenterCrawlerProjectsList(name, time) {
			this.$set(this.apiCall, 'exportPipelineCenterCrawlerProjectsList', false)
			let date = (new Date(time)).toDateString()
			this.$axios.exportPipelineCenterCrawlerProjectsList({
				pipeline_center_name: name,
			}, (response) => {
				fileManager.saveFile(`${name}道管爬蟲案件清冊_${date}`, 'csv', response, {bom: true})
				this.apiCall.exportPipelineCenterCrawlerProjectsList = true
			}, (error) => {
				this.apiCall.exportPipelineCenterCrawlerProjectsList = true
			})
		},
		getIconHtml(success, solid=false) {
			return `<i class="${this.getTextClass(success)} fa${solid ? 's' : 'r'} fa-${success ? 'check' : 'times'}-circle"></i>`
		},
		getTextClass(success) {
			return `text-${success ? 'success' : 'danger'}`
		},
		getTextHtml(text, success) {
			return `${this.getIconHtml(success)} <font class="${this.getTextClass(success)}">${text ?? ''}</font>`
		}
	}
}
</script>

<style scoped>
</style>