<template>
<div class="">
	<div class="login-page">
		<b-card class="login-card col-md-9 col-11" title="已登出">
			<form class="col-12 login-form">
				<b-row>
					<b-card-text>
						<router-link to="/login" class="link">以其他帳號登入</router-link>
					</b-card-text>
				</b-row>
				<b-row>
					<b-card-text>
						<router-link to="/register" class="link">註冊帳號</router-link>
					</b-card-text>
				</b-row>
			</form>
		</b-card>
	</div>
</div>
</template>

<script>
export default {
	name: 'Logout',
	data(){
		return {
		};
	},
	methods:{
	},
	created(){
		this.$store.dispatch('logout');
	}
}
</script>

<style scoped>
	.login-page {
		min-height: 100vh;
		width: 100%;	
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		background-color: #f8f9ff;
	}
	.login-page * {
		margin: auto auto;
	}
	.login-card {
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 60px 10px;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(81, 87, 99, 0.12);
	}
	.login-form > * {
		margin: 1em 0;
	}
	.card-title {
		text-align: center;
	}
	.card-text {
		color: #90979b;
	}
	.link {
		color: #3d4450;
	}
	.link:hover {
		cursor: pointer;
		text-decoration: none;
		color: #015d87;
	}
</style>