<template>
	<div class="progress-wrapper" v-if="show">
		<b-progress class="progress-bar-wrapper" :max="max">
			<b-progress-bar :value="value" :label="label + (showPercentage ? `${((value / max) * 100).toFixed(1)}%` : '')" :striped="striped" :animated="animated" :show-progress="showProgress"></b-progress-bar>
		</b-progress>
	</div>
</template>


<script>
import loading_img from '@/assets/images/loading.gif';
import loading_img2 from '@/assets/images/loading2.gif';
export default {
	name: "ProgressBar",
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		value: {
			type: Number,
			default: 1,
		},
		max: {
			type: Number,
			default: 1
		},
		striped: {
			type: Boolean,
			default: true,
		},
		animated: {
			type: Boolean,
			default: true,
		},
		showProgress: {
			type: Boolean,
			default: true,
		},
		showPercentage: {
			type: Boolean,
			default: true,
		},
		label: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
		}
	},
	computed: {
	},
	methods: {
	}
}
</script>

<style scoped>
.progress-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0005;
	z-index: 9999;
}
.progress-bar-wrapper {
	width: 50%;
	margin: auto;
	top: 50%;
	background: #e1e6e690;
	box-shadow: 0 0 5px .5px #284a7090;
}
.progress-bar {
	background: #017ca7;
}
.progress-bar-striped {
	background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
	background-size: 1rem 1rem;
}
.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite;
}
</style>