<template>
	<b-card class="shadow" id="Card" :title="title">
		<loading mode="fixed" image="1" size="lg" v-if="downloading"/>
		<loading class="info-wrapper" v-if="loading"/>
		<paging-table ref="table" v-else :columns="table.columns" :data="sites" :configs="table.configs" :detail-columns="table.detailColumns" :paging="table.paging" :actions="table.actions" :options="table.options" :sortOptions="sorting" :searchOptions="siteFilter" @changePage="changePage" @sort="getData" @search="getData"></paging-table>
		<b-row class="col-12 justify-content-start align-items-center">
			<b-button class="ml-2 form-btn submit-btn" @click="downloadConstructionSitesCsv">下載工地列表csv</b-button>
		</b-row>
	</b-card>
</template>

<script>
import { deepCopy, isEmpty, sortArray, checkAllTrue } from '@/utils/assist';
import PagingTable from '@/components/Table/PagingTable.vue'
import Loading from '@/components/Loading/Loading.vue'
import fileManager from '@/utils/file';

export default {
	name: 'ConstructionSiteList',
	components:{
		PagingTable,
		Loading
	},
	props: {
		title: {
			type: String,
			default: ""
		},
		filter: {
			type: Object,
			default: () => { return {}; }
		}
	},
	data() {
		return {
			basicData: {},
			sites: [],
			table: {
				detailColumns: [
					{ key:'excavation_permit', label: '路證編號' },
					{ key:'address', label: '案件地址' },
					{ key:'name', label: '工地名稱' },
					{ key:'latlng', label: '座標' },
					{ key:'status', label: '工地狀態' },
					{ key:'assigned_at', label: '派工日期' },
					{ key:'four_positioning_points_count', label: '應有四支距' },
					{ key:'tp_system_four_positioning_data.points_count', label: '已有四支距', deep: true, default: 0 },
				],
				columns: [
					// { key: 'project_type', label: '#', isRowHeader: true, stickyColumn: true, sortable: false, searchable: true, hide: !this.$store.getters.isDeveloper },
					// { key: 'project_id', label: '#', isRowHeader: true, stickyColumn: true, sortable: false, searchable: true, hide: !this.$store.getters.isDeveloper },
					{ key: 'project_key', label: '案件ID', isRowHeader: true, stickyColumn: true, sortable: false, searchable: true, hide: this.$store.getters.isSupervisor },
					{ key: 'id', label: 'ID', isRowHeader: true, stickyColumn: true, sortable: false, searchable: true, hide: this.$store.getters.isSupervisor },
					{ key: 'excavation_permit', label: '路證編號', isRowHeader: true, sortable: false, searchable: true },
					{ key: 'name', label: '工地名稱', sortable: false, searchable: true },
					{ key: 'assigned_at', label: '派工日期', sortable: false, searchable: true },
					{ key: 'status', label: '工地狀態', sortable: false, searchable: true },
					{ key: 'address', label: '地址', sortable: false, searchable: true },
					{ key: 'four_positioning_points_count', label: '應有四支距', sortable: false, searchable: false },
					{ key: 'tp_system_four_positioning_data.points_count', label: '已有四支距', sortable: false, searchable: false, deep: true, default: 0 },
				],
				actions: [
					{
						//icon, label
						button: { type: 'icon', icon: "fas fa-file", label: "資料" },
						//function, link, route
						action: { type: 'link', link: this.getProjectSceneUrl, target: "_blank" },
						hide: (index) => this.sites[index].scene_pictures_url
					},
					{
						//icon, label
						button: { icon: "fas fa-cog", label: "管理" },
						//function, link, route
						action: { type: 'route', route: this.routeToProjectSiteManager }
					},
					{
						//icon, label
						button: { icon: "fas fa-map-marked-alt", label: "成果" },
						//function, link, route
						action: { type: 'route', route: this.routeToProjectResult },
						hide: (index) => this.sites[index].status_index < this.$store.getters.enum('construction_site.status.measured').index
					}
				],
				configs: {
					// actionsColumn: true,
					rowDetail: true,
					busy: true,
					showStatistic: true
				},
				paging: {
					perPage: 10,
					pageLimit: 9,
				},
				options: {}
			},
			paging: {
				page: 1,
				per_page: 10,
			},
			call: { getConstructionSiteList: false },
			loading: true,
			downloading: false,
			sorting: {},
			siteFilter: deepCopy(this.filter)
		}
	},
	created() {
		this.basicData = {
			project_id: "案件ID",
			excavation_permit: "路證編號",
			address: "案件地址",
			name: "工地名稱",
			latlng: "座標",
			status: "工地狀態",
			assigned_at: "派工日期",
			four_positioning_points_count: "應有四支距",
			"tp_system_four_positioning_data.points_count": "已有四支距",
		}
		this.getConstructionSiteList()
	},
	destroyed() {
	},
	mounted() {
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		apiQuery: {
			deep: true,
			handler(value) {
				this.getConstructionSiteList();
			}
		},
		paging: {
			deep: true,
			handler(value) {
				this.getConstructionSiteList();
			}
		},
	},
	computed: {
		apiQuery() {
			const projectKey = {
			}
			return {
				...(typeof this.siteFilter.key === 'undefined') ? this.siteFilter : {
					...this.siteFilter,
					project_id: this.siteFilter.project_key
				},
				...this.paging,
				orderBy: {
					created_at: 'desc',
					// ...this.sorting,
					// TODO
					...Object.keys(this.sorting).reduce((obj, key) => {
						if(projectKey[key])
							obj[projectKey[key]] = this.sorting[key]
						else
							obj[key] = this.sorting[key]
						return obj;
					}, {})
				},
			}
		},
	},
	methods:{
		getConstructionSiteList() {
			this.call.getConstructionSiteList = false
			this.$axios.getConstructionSiteList(this.apiQuery, (response) => {
				this.sites = response.data.map(site => {
					return {
						project_key: `${site.project_type_index}-${site.project_id}`,
						...site,
						latlng: `${site.lng}˚E, ${site.lat}˚N`,
					}
				});
				this.table.configs.busy = false;
				this.table.paging = {
					...this.table.paging,
					count: response.paging.count,
					currentPage: response.paging.current_page,
					from: response.paging.from,
					lastPage: response.paging.last_page,
					perPage: response.paging.per_page,
					to: response.paging.to,
					total: response.paging.total,
				};
				this.call.getConstructionSiteList = true;
			}, (error) => {
				this.table.configs.busy = false;
				this.call.getConstructionSiteList = true;
			})
		},
		getProjectSceneUrl(index) {
			return this.sites[index].scene_pictures_url;
		},
		routeToProjectSiteManager(index) {
			return {name: '工地管理', params: {projectType: this.sites[index].project_type_index, projectID: this.sites[index].project_id}}
		},
		routeToProjectResult(index) {
			return {name: '測量成果', params: {projectType: this.sites[index].project_type_index, projectID: this.sites[index].project_id}}
		},
		changePage(page) {
			this.paging.page = page
		},
		getData(options) {
			// TODO: 搜尋、排序
			this.siteFilter = {
				...this.filter,
				...options.search
			}
			this.sorting = options.sort
			this.paging.page = 1
		},
		downloadConstructionSitesCsv() {
			let sites = []
			this.downloading = true;
			this.$axios.getConstructionSiteList({ ...this.apiQuery, paging: 0 }, (response) => {
				sites = response.data.map(site => {
					return {
						...site,
						latlng: `${site.lng}˚E, ${site.lat}˚N`,
					}
				});
				let content = sites.map(site => {
					return [
						site.id,
						site.excavation_permit,
						site.address,
					].join(",")
				})
				fileManager.saveFile(`${this.title}(${Object.values(this.siteFilter).join(',')})`, "csv", content.join("\r\n"), {bom: true})
				this.downloading = false;
			}, (error) => {
				this.downloading = false;
			})
		},
	}
}
</script>

<style scoped>
.table-paging {
	margin: 1rem;
}
.search-bar {
	margin: 0 1rem;
}
.form-input-wrapper {
	padding: 0 1rem;
	border-radius: 1.5rem;
	height: 2.5rem;
	display: flex;
	align-items: center;
}
.form-input-wrapper.size-xs {
	border-radius: 0.5rem;
	height: 1.8rem;
}
.form-input-wrapper.size-xs .input-label {
	display: flex;
	align-items: center;
}
.form-input-wrapper.size-xs .input-label [data-icon="search"] {
	font-size: 0.8rem
}
/* 清除自動填字背景色 */
.input-text:-webkit-autofill,
.input-text:-webkit-autofill:hover,
.input-text:-webkit-autofill:focus,
.input-text:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
	-webkit-transition: background-color 5000s ease-in-out 0s;
	-moz-transition: background-color 5000s ease-in-out 0s;
	-o-transition: background-color 5000s ease-in-out 0s;
	box-shadow: 0 0 0 30px white inset !important;
	-webkit-box-shadow: 0 0 0 30px white inset !important;
	-moz-box-shadow: 0 0 0 30px white inset !important;
	-o-box-shadow: 0 0 0 30px white inset !important;
}
.form-input-wrapper.size-xs .input-text {
	height: 1rem;
	font-size: 0.9rem
}
.input-text {
	border: none;
	z-index: 3;
}
.input-text:focus {
	border: none;
	box-shadow: none;
}
.input-text:focus + .input-label + .input-background {
	border: 1px solid #9bc8de;
}
.input-text.is-invalid + .input-label + .input-background{
	border: 1px solid red;
}
.link:hover {
	cursor: pointer;
	text-decoration: none;
}
.input-background {
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 1.5rem;
	border: 1px solid #e0e8e7;
	width: 100%;
	height: 100%;
}
.input-label {
	background-color: transparent;
	border: none;
	z-index: 3;
}
.table-bar {
	display: flex;
}
@media (min-width:576px) {
	.table-bar {
		flex-direction: row;
		justify-content: space-between;
	}
}

@media (max-width:575px) {
	.table-bar {
		flex-direction: column;
	}
}
.table-btn {
	color: #4c6b8a;
	margin: 0 .5rem;
	background-color: transparent;
	border: none;
}
.table-btn:hover {
	color: #6b8ca9;
	cursor: pointer;
}
.table-btn:hover > label {
	cursor: pointer;
}
.table-btn:focus {
	box-shadow: none !important;
}
.action-btn {
	padding: .25rem .75rem;
	border-radius: .5rem;
	background: #b2c8d8aa;
	color: #050b10cc;
	border-color: #b2c8d8;
}
.action-btn:hover {
	text-decoration: none;
	background: #8ea9c2cc;
	color: #050b10;
}
.action-btn:focus {
	box-shadow: none;
}
</style>