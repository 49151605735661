import Utility from "./Utility";

/**
 * 設施物
 */
class Feature {
	/**
	 * @var {int}
	 */
	static count = 0
	/**
	 * @param {Type} type 
	 * @param {Info} info 
	 */
	constructor(collection, type, info) {
		/**
		 * @var FeatureCollection
		 */
		this.collection = collection
		/**
		 * @var {int}
		 */
		this.index = Feature.count;
		Feature.count++
		/**
		 * 種類
		 * @var {Type}
		 */
		this.type = type
		/**
		 * 點位/管線資訊
		 * @var {Info}
		 */
		this.info = info
	}

	/**
	 * 重新計數
	 */
	static resetCounter() {
		Feature.count = 0;
	}

	/**
	 * 取得實體的class名稱（繼承後）
	 * @returns {String}
	 */
	getInstanceName() {
		return this.constructor.name
	}

	/**
	 * 取得data
	 */
	getData() {
		return {
			...this.type.get(),
			...this.info.get(),
		}
	}

	/**
	 * 取得Feature長度
	 * 預設為0，Line另外實作
	 * @returns {Number}
	 */
	getLength() {
		return 0
	}

	getName() {
		return ''
	}

	getNumber() {
		return undefined
	}

	/**
	 * 轉換為Utility
	 * @param {Object} meta 
	 * @param {String[]} rules 
	 * @returns {Utility[]}
	 */
	toUtilities(meta, rules) {
		return [new Utility(this, meta, rules)];
	}

	get() {
		return {
			name: this.getName(),
			number: this.getNumber(),
			type: this.type.getTypes()
		}
	}
}

export default Feature
