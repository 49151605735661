<template>
	<div class="header-wrapper">
		<b-navbar toggleable="md" type="light" variant="light" class="header">
			<b-nav-item-dropdown right no-caret>
				<template v-slot:button-content>
					<span class="header-item">
						<i class="fa-fw far fa-user-circle header-icon"></i>
						<span class="xs-mobile-except">{{$store.getters.currentUser.name}}</span>
					</span>
				</template>
				<b-dropdown-item><router-link :to="{name: '個人資料'}">個人資料</router-link></b-dropdown-item>
				<b-dropdown-divider />
				<b-dropdown-item><router-link :to="{name: '登出'}">登出</router-link></b-dropdown-item>
			</b-nav-item-dropdown>
			<b-nav-item class="mobile" @click="mobileOpenSidebar" right v-if="!hideSideBar">
				<i class="fa-fw fa fa-bars header-icon"></i>
			</b-nav-item>
		</b-navbar>
	</div>
</template>

<script>
export default {
	name: 'app',
	components: {
	},
	props: {
		hideSideBar: {
			type: Boolean,
			default: false
		}
	},
	created() {
	},
	methods: {
		mobileOpenSidebar() {
			this.$emit("mobileOpenSidebar");
		}
	}
}
</script>



<style scoped>
	.header-wrapper {
		width: 100%;
		z-index: 3;
		height: 60px;
	}
	.header {
		justify-content: flex-end;
	}
	.bar-item {
		background-color: transparent;
		border: none;
		vertical-align: middle;
	}
	.nav-item {
		list-style: none;
	}
	.nav-link {
		padding: 0.5em 0;
	}
	.header-item {
		color: #050b10;
		vertical-align: middle;
		background-color: transparent;
		border: none;
		font-weight: 600;
	}
	.header-icon {
		font-size: 24px;
		margin: 0 0.25em;
		color: #050b10;
		vertical-align: middle;
		background-color: transparent;
		border: none;
		font-weight: 600;
	}
	.navbar-toggler * {
		color: #f5f7f7;
	}
</style>
