<template>
	<b-form @submit="onSubmit">
		<loading v-if="loading"/>
		<b-row v-else>
			<input-text class="input" label="帳號" size="xs" v-model="result.account" pattern="[a-zA-Z0-9]{2,}" invalid-message="請輸入2位以上之英數字" required></input-text>
			<input-text class="input" label="名稱" pattern="^[a-zA-Z0-9\u4E00-\u9FFF\uff08-\uff09_]{2,}$" invalid-message="請輸入2個字元以上非特殊符號" size="xs" v-model="result.name" required></input-text>
			<input-text class="input" label="密碼" size="xs" type="password" v-model="result.password" pattern="[a-zA-Z0-9]{3,}" invalid-message="請輸入3位以上之英數字" required></input-text>
			<input-text class="input" label="信箱" size="xs" type="email" v-model="result.email"></input-text>
			<input-select class="input" label="單位種類" size="xs" :options="options.unit_type_list" :keys="{value: 'index', text: 'text'}" v-model="result.unit_type_id" required></input-select>
			<input-select class="input" label="單位" size="xs" :options="unitOptions" :keys="{value: 'id', text: 'name'}" v-model="result.unit_id" required></input-select>
			<input-select class="input" label="身份" size="xs" :options="options.role_list" :keys="{value: 'index', text: 'text'}" v-model="result.role" required></input-select>
		</b-row>
		<b-row style="justify-content: center">
			<b-button class="form-btn submit-btn size-xs" type="submit" variant="primary">確認</b-button>
		</b-row>
	</b-form>
</template>

<script>
import InputText from '@/components/Input/InputText';
import InputRadio from '@/components/Input/InputRadio';
import InputSelect from '@/components/Input/InputSelect';
import Loading from '@/components/Loading/Loading.vue';
import { isEmpty, checkAllTrue } from '@/utils/assist';

export default {
	name: 'UserCreator',
	components:{
		InputText,
		InputRadio,
		InputSelect,
		Loading
	},
	props: {
		role_list: {
			type: Array,
			default() {
				return [];
			}
		},
	},
	data() {
		return {
			form: [ 'account', 'name', 'password', 'email', 'role' ],
			options: {},
			result: {},
			check: {},
			call: { getUnitList: false },
			loading: true,
			unitOptions: [],
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		result: {
			deep: true,
			handler() {
				this.$emit('change', this.result);
				this.checkResult()
				this.updateUnitList()
			}
		},
	},
	created() {
		this.options.unit_type_list = this.$store.getters.enum('unit.type')
		this.$set(this.options, "unit_list", [])
		this.getUnitList()
		this.options.role_list = this.$store.getters.enum('user.role').filter(role=>this.role_list.includes(role.key))
		if(this.options.role_list.length === 1)
			this.result.role = this.options.role_list[0].index
	},
	mounted() {
	},
	computed: {
	},
	methods:{
		onLoadEnd() {
			this.updateUnitList()
		},
		getUnitList() {
			this.call.getUnitList = false
			this.$axios.getUnitList({}, (response) => {
				this.options.unit_list = response.data
				this.call.getUnitList = true
			}, (error) => {
				this.call.getUnitList = true
			})
		},
		updateUnitList() {
			this.unitOptions = this.options.unit_list.filter(unit => unit.type_index === this.result.unit_type_id)
		},
		checkResult() {
			let res = true
			for(let i in this.form) {
				if(!this.result.hasOwnProperty(this.form[i]) || (typeof this.result[this.form[i]] === 'object' && isEmpty(this.result[this.form[i]])) || this.result[this.form[i]] == null || typeof this.result[this.form[i]] === "undefined") {
					this.$set(this.check, this.form[i], false)
					res = false
				}
				else if(this.check.hasOwnProperty(this.form[i])) {
					this.$delete(this.check, this.form[i])
				}
			}
			return res
		},
		onSubmit(evt) {
			evt.preventDefault();
			if(!this.checkResult()) {
				alert("含有空白的選項！")
				return;
			}
			// 預設單位：1 鼎堅航太
			this.result.unit_id = Number.isInteger(this.result.unit_id) ? this.result.unit_id : 1
			this.$axios.createUser(this.result, (response) => {
				this.$emit('createUser')
			})
			// alert("success")
			// this.$router.go(-1)
			this.$set(this, 'result', {})
		},
	}
}
</script>

<style scoped>
.input {
	margin: .5rem .25rem
}
</style>