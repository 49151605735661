<template>
	<div class="py-2">
		<input-form class="p-0 col-12" :options="formOptions" v-model="result" @submit="onSubmit" @cancel="onCancel"></input-form>
	</div>
</template>

<script>
import InputText from '@/components/Input/InputText';
import InputSelect from '@/components/Input/InputSelect';
import InputForm from '@/components/Input/InputForm';
import Loading from '@/components/Loading/Loading.vue';
import { deepCopy, checkAllTrue } from '@/utils/assist';

export default {
	name: 'ProjectDataTypeEditor',
	components:{
		InputText,
		InputSelect,
		InputForm,
		Loading
	},
	props: {
		projectDataType: {
			type: Object
		}
	},
	data() {
		return {
			result: {},
		}
	},
	watch: {
		projectDataType: {
			deep: true,
			handler(value) {
				this.initResult()
			}
		}
	},
	created() {
		this.initResult()
	},
	mounted() {
	},
	computed: {
		formOptions() {
			return [
				{ key: "name", type: "text", label: "名稱", required: true, disabled: true, size: 'xs' },
				{ key: "type", type: "select", label: "格式", required: true, options: this.$store.getters.enum('project_data_type.type'), keys: {value: 'index', text: 'text'}, size: 'xs' },
				{ key: "data_type", type: "select", label: "種類", required: true, options: this.$store.getters.enum('project_data_type.data_type'), keys: {value: 'index', text: 'text'}, size: 'xs' },
				{ key: "note", type: "text", label: "備註", required: false, multiline: true, size: 'xs' },
			]
		},
		originalData() {
			let data = deepCopy(this.projectDataType)
			if(data) {
				Object.keys(data).forEach(key => {
					if(key.endsWith('_enum')) {
						let enum_key = key.substring(0, key.lastIndexOf('_enum'))
						data[enum_key] = data[`${enum_key}_index`]
					}
				})
			}
			return data;
		},
		updatedResult() {
			return Object.keys(this.result).reduce((obj, key) => {
				if(this.result[key] !== this.originalData[key])
					obj[key] = this.result[key];
				return obj;
			}, {})
		}
	},
	methods:{
		initResult() {
			this.result = this.originalData;
		},
		onSubmit(evt) {
			evt.preventDefault();
			let result = this.updatedResult;
			if(!result) {
				alert('修改成功')
				this.$emit('update')
			}
			else {
				this.$axios.updateProjectDataType(this.projectDataType.id, this.updatedResult, (response) => {
					alert('修改成功')
					this.$emit('update')
				}, () => {
					this.$emit('update')
				})
			}
		},
		onCancel() {
			this.$emit('cancel')
		}
	}
}
</script>

<style scoped>
.input {
	margin: .5rem .25rem
}
</style>
