<template>
	<project-info :project="project" :projectDataType="projectDataType" :group="group" :permission="permission" @reload="reload" v-if="typeof this.project !== 'undefined'"></project-info>
</template>


<script>
import ProjectInfo from '@/components/Project/Info/ProjectInfo.vue'

export default {
	components: {
		ProjectInfo,
	},
	props: {
		groupID: {
			required: true,
			default: 0
		},
		projectID: {
			required: true,
			default: 0
		},
		projectType: {
			required: true,
			default: 0
		},
		project: {
			type: Object
		},
		projectDataType: {
			type: Array
		},
		group: {
			type: Object
		},
		permission: {
			type: Object,
		}
	},
	data() {
		return {
			group_id: Number.isInteger(parseInt(this.groupID)) ? parseInt(this.groupID) : 0,
			project_type: Number.isInteger(parseInt(this.projectType)) ? parseInt(this.projectType) : 0,
			project_id: Number.isInteger(parseInt(this.projectID)) ? parseInt(this.projectID) : -1
		}
	},
	created() {
		if(typeof this.project === 'undefined') {
			this.reload()
		}
	},
	watch: {
	},
	mounted() {
	},
	methods:{
		reload() {
			this.$router.replace({name: '案件', params: {groupID: this.group_id, projectType: this.project_type, projectID: this.project_id, from: {name: '案件資訊'}}});
		}
	}
}
</script>

<style scoped>
</style>