<template>
	<div>
		<loading class="info-wrapper" v-if="loading"/>
		<project-list :title="title" :filter="query" v-show="!loading"></project-list>
	</div>
</template>


<script>
import ProjectList from '@/components/Project/List/ProjectList.vue'
import Loading from '@/components/Loading/Loading.vue'
import { checkAllTrue } from '@/utils/assist';

export default {
	components: {
		ProjectList,
		Loading,
	},
	data() {
		return {
			call: { getUnitList: false },
			loading: true,
			project_category_keys: ["party_a"],
			unit_list: [],
		}
	},
	created() {
		this.getUnitList();
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	computed: {
		title() {
			if(this.loading) return '案件列表';
			let unit = this.unit_list.find(unit => unit.id === this.query.party_a_id);
			return (unit ? unit.name : '') + '案件列表';
		},
		query() {
			return Object.keys(this.$route.query).reduce((obj, key) => {
				obj[key] = Number.isNaN(Number(this.$route.query[key])) ? this.$route.query[key] : Number(this.$route.query[key]);
				return obj;
			}, {})
		}
	},
	methods: {
		getUnitList() {
			this.call.getUnitList = false
			let types_index = this.$store.getters.enum('unit.type').filter(type => this.project_category_keys.includes(type.key)).map(type => type.index)
			this.$axios.getUnitList({types_index: types_index}, (response) => {
				this.unit_list = response.data;
				this.call.getUnitList = true
			}, (error) => {
				this.call.getUnitList = true
			})
		}
	}
}
</script>