<template>
	<div>
		<h5>{{title}}</h5>
		<div>
			<bv-filter :title="filter.title" :options="filter.options" :keys="filter.keys" :multiple="filter.multiple" :hasAll="filter.hasAll" v-model="model[filter.key]" v-for="(filter, key) in filters" :key="key" />
		</div>
	</div>
</template>

<script>
import BvFilter from '@/components/Filter/BvFilter.vue'

export default {
	name: 'BvFilterGroup',
	components: {
		BvFilter
	},
	props: {
		title: {
			type: String,
			default: ''
		},
		filters: {
			type: Array,
			default: () => []
		},
		value: {
			type: Object,
			default: () => { return {} },
		},
	},
	data() {
		return {
			model: this.value,
		}
	},
	watch: {
		value: {
			handler(value) {
				this.model = value
			}
		},
		model: {
			deep: true,
			handler(value) {
				this.change(value)
			}
		},
	},
	created() {
	},
	mounted () {
	},
	computed: {
	},
	methods: {
		change(e) {
			this.$emit('change', this.model)
		},
	}
}
</script>

<style scoped>

</style>