<template>
	<div class="info-outter-wrapper w-100">
		<loading class="info-wrapper" v-if="loading"/>
		<template class="info-wrapper" v-else>
			<h5 class="ml-2" style="white-space:normal">{{`${project.party_a_name} ${project.excavation_permit} ${project.address}`}}</h5>
			<b-row class="info-wrapper tab-wrapper">
				<div class="col-12 tab-header">
					<template v-for="(item, key) in menu">
						<router-link v-if="!(typeof item.hide === 'function' ? item.hide() : item.hide)" :key="key" :to="item.path" v-slot="{ href, isActive }">
							<div class="tab-header-item pointer" :class="{active: isActive}">
								<a :href="href" @click="clickListItem($event, item)">
									<i class="tab-header-item-icon fa-fw m-1" :class="item.icon"></i>
									<span class="tab-header-item-title">{{item.title}}</span>
								</a>
							</div>
						</router-link>
					</template>
				</div>
				<div class="col-12 tab-content">
					<b-card no-body class="basic-wrapper w-100">
						<b-card-body class="card-content py-3 px-md-3 px-1">
							<router-view :project="project" :project-data-type="project_data_type" :group="group" :permission="permission" v-if="!loading"></router-view>
						</b-card-body>
					</b-card>
				</div>
			</b-row>
		</template>
	</div>
</template>

<script>
import Loading from '@/components/Loading/Loading.vue'
import { deepCopy, isEmpty, checkAllTrue } from '@/utils/assist';

export default {
	name: "Project",
	components: {
		Loading,
	},
	props: {
		groupID: {
			required: true,
			default: 0
		},
		projectID: {
			required: true,
			default: 0
		},
		projectType: {
			required: true,
			default: 0
		},
		from: {
			default: null
		},
		group: {
			type: Object
		},
		permission: {
			type: Object
		}
	},
	data() {
		return {
			group_id: Number.isInteger(parseInt(this.groupID)) ? parseInt(this.groupID) : 0,
			project_type: Number.isInteger(parseInt(this.projectType)) ? parseInt(this.projectType) : 0,
			project_id: Number.isInteger(parseInt(this.projectID)) ? parseInt(this.projectID) : 0,
			call: { getProjectInfo: false, getProjectDataType: false },
			loading: true,
			menu: [
				{ title: "案件資訊", icon: "fas fa-info-circle", key: "info", to: this.listRouteTo, path: this.getRoute({name: '案件資訊'}) },
				{
					title: "工地管理", icon: "fas fa-cogs", key: "sites", to: this.listRouteTo, path: this.getRoute({name: '工地管理'}),
					hide: () => !this.checkPermission('edit') && !this.checkPermission('write')
				},
				{
					title: "測量成果", icon: "fas fa-poll", key: "result", to: this.listRouteTo, path: this.getRoute({name: '測量成果'}),
				},
				{
					title: "案件報告", icon: "far fa-file", key: "report", to: this.listRouteTo, path: this.getRoute({name: '案件報告'}),
					hide: () => !this.checkPermission('edit')
				},
			],
			prevRoute: null,
			project: {},
			project_data_type: [],
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
		$route(to, from) {
			if(this.from && this.prevRoute) {
				this.prevRoute = from
				this.init();
			}
			else
				this.prevRoute = from
		}
	},
	created() {
		this.init();
	},
	computed: {
		showMenu(key) {
			return (key) => {
				let item = this.menu.find(item => item.key === key)
				return item && !(typeof item.hide === 'function' ? item.hide() : item.hide)
			}
		},
		routeParams() {
			return {
				projectType: this.project_type,
				projectID: this.project_id,
				project: this.project,
				projectDataType: this.project_data_type,
				groupID: this.group_id,
				group: this.group,
				permission: this.permission,
			}
		},
		apiParams() {
			return {
				type: this.project_type,
				id: this.project_id,
			}
		},
	},
	methods: {
		init() {
			this.getProjectInfo();
			this.getProjectDataType();
		},
		onLoadEnd() {
			this.$nextTick().then(() => {
				this.redirector()
			})
		},
		getProjectInfo() {
			this.call.getProjectInfo = false
			this.$axios.getProjectInfo(this.apiParams, (response) => {
				this.project = response.data
				this.call.getProjectInfo = true
			}, (error) => {
				this.call.getProjectInfo = true
				this.$router.push({name: '專案案件列表', params: {groupID: this.group_id}});
			});
		},
		redirector() {
			let project_group_id = this.project.project_group_id ? this.project.project_group_id : 0;
			if(project_group_id !== this.group_id) {
				this.group_id = project_group_id
			}
			if(this.prevRoute) {
				if(this.menu.map(o=>o.path.name).includes(this.prevRoute.name) && this.prevRoute.name !== this.$route.name)
					this.routeTo({name: this.prevRoute.name})
				else if(this.prevRoute.name === '案件') {
					this.routeTo({name: '案件資訊'})
				}
			}
			else {
				let item = this.menu.find(item => item.path.name === this.$route.name)
				if(this.$route.name === '案件' || (item && (typeof item.hide === 'function' ? item.hide() : item.hide)))
					this.routeTo({name: '案件資訊'})
			}
			// if(this.prevRoute && this.menu.map(o=>o.path.name).includes(this.prevRoute.name))
			// 	this.routeTo({name: this.prevRoute.name})
			// else if(!this.prevRoute || this.prevRoute && this.prevRoute.name !== '案件') {
			// 	this.routeTo({name: '案件資訊'})
			// }
		},
		routeTo(to) {
			this.$router.replace(this.getRoute(to))
			.catch(err => {
				this.$router.replace(this.getRoute(to))
			})
		},
		listRouteTo(to) {
			if(this.$router.currentRoute.name !== to.name) {
				this.$router.push(this.getRoute(to))
			}
		},
		getProjectDataType() {
			this.call.getProjectDataType = false
			this.$axios.getProjectDataType((response) => {
				this.project_data_type = response.data
				this.call.getProjectDataType = true
			},(error) => {
				this.call.getProjectDataType = true
			})
		},
		getRoute(path) {
			return {
				...path,
				params: this.routeParams
			}
		},
		clickListItem(e, item) {
			e.preventDefault();
			item.to(item.path);
		},
		checkPermission(action_key) {
			if(!this.group_id) return this.$store.getters.isDeveloper;
			if(!this.permission) return false;
			return this.permission.actions.find(action => action.key === action_key)
		},
	}
}
</script>

<style>
.info-outter-wrapper {
	width: 100%;
}
.info-wrapper > div {
	padding: .5rem;
}
.info-wrapper h5 {
	white-space: normal;
}
.info-wrapper > div {
	padding: .5rem;
}
.info-wrapper h5{
	white-space: normal;
}

.tab-wrapper {
	margin-top: 1rem;
}
.tab-wrapper > .tab-header {
	padding: 1rem 1.5rem 0 1.5rem;
	display: flex;
	align-content: center;
	justify-content: space-around;
}
.tab-wrapper > .tab-header > .tab-header-item {
	flex: 1 1 auto;
	text-align: center;
	padding: .5rem 1rem;
}
.tab-wrapper > .tab-header > .tab-header-item * {
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #768b9a;
}
/* .tab-wrapper > .tab-header > .tab-header-item:hover {
	background-color: #c3dfe850;
} */
.tab-wrapper > .tab-header > .tab-header-item:hover * {
	text-decoration: none;
	color: #515763;
	filter: drop-shadow(0 0 1px #0002);
}
.tab-wrapper > .tab-header > .tab-header-item.active * {
	text-decoration: none;
	color: #050b10;
}
.tab-wrapper > .tab-content {
	padding: 0 1rem 0 1rem;
}
@media (min-width:576px) {
	.tab-wrapper > .tab-header {
		padding: 1rem 1.5rem 0 1.5rem;
		flex-direction: row;
	}
	.tab-wrapper > .tab-header > .tab-header-item * {
		justify-content: center;
	}
	.tab-wrapper > .tab-header > .tab-header-item:hover {
		border-radius: .5rem .5rem 0 0;
		border: 1px solid #768b9a50;
		border-bottom: none;
	}
	.tab-wrapper > .tab-content {
		padding: 0 1rem 0 1rem;
	}
}
@media (max-width:575px) {
	.tab-wrapper > .tab-header {
		padding: .25rem .5rem 0 .5rem;
		flex-direction: column;
		/* flex-flow: wrap; */
	}
	.tab-wrapper > .tab-header > .tab-header-item {
		padding: .5rem;
	}
	.tab-wrapper > .tab-header > .tab-header-item * {
		justify-content: flex-start;
	}
	.tab-wrapper > .tab-header > .tab-header-item:hover {
		background-color: #c3dfe810;
		border-radius: .5rem;
	}
	.tab-wrapper > .tab-content {
		padding: 0 .25rem 0 .25rem;
	}
}

</style>