<template>
	<input-form class="p-0 col-12" :options="formOptions" v-model="result" @submit="onSubmit" @cancel="onCancel"></input-form>
</template>

<script>
import InputForm from '@/components/Input/InputForm';
import Loading from '@/components/Loading/Loading.vue';
import { deepCopy, checkAllTrue } from '@/utils/assist';

export default {
	name: 'BidEditor',
	components:{
		InputForm,
		Loading
	},
	props: {
		bid: {
			type: Object,
		},
		partyAUnits: {
			type: Array,
			default: () => []
		},
		measurerUnits: {
			type: Array,
			default: () => []
		},
	},
	data() {
		return {
			result: {},
			call: {},
			loading: true,
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		bid: {
			deep: true,
			handler(value) {
				this.initResult()
			}
		},
		loading() {
			this.$emit('loading', this.loading)
		},
		result: {
			deep: true,
			handler(value) {
				if(!(this.result.measurer_unit_members_id ?? []).every(id => this.measurerUnitMembers.mapValues('id').includes(id))) {
					this.result.measurer_unit_members_id = (this.result.measurer_unit_members_id ?? []).filter(id => this.measurerUnitMembers.mapValues('id').includes(id))
				}
			}
		},
	},
	created() {
		this.initResult()
	},
	mounted() {
	},
	computed: {
		measurerUnit() {
			return this.measurerUnits.find(unit => unit.id === this.result.measurer_unit_id)
		},
		measurerUnitMembers() {
			return this.measurerUnit ? this.measurerUnit.members : []
		},
		formOptions() {
			return [
				{ key: "name", type: "text", label: "名稱", required: true, size: 'xs' },
				{ key: "abbreviation", type: "text", label: "簡稱", required: false, size: 'xs' },
				{ key: "number", type: "text", label: "編號", size: 'xs' },
				{ key: "unit_id", type: "select", label: "甲方", required: true, options: this.partyAUnits, keys: {value: 'id', text: 'name'}, size: 'xs' },
				{ key: "measurer_unit_id", type: "select", label: "測量公司", required: true, options: this.measurerUnits, keys: {value: 'id', text: 'name'}, size: 'xs' },
				{ key: "measurer_unit_members_id", type: "select", label: "測量人員", required: false, multiple: true, options: this.measurerUnitMembers, keys: {value: 'id', text: 'name'}, size: 'xs' },
			]
		},
		updatedResult() {
			return {
				...Object.filter(this.result, (value, key) => value !== this.bid[key]),
				...Object.isEqual(this.bid.measurer_unit_members.mapValues('id'), this.result.measurer_unit_members_id) ? {
					measurer_unit_members_id: [],
				} : {
					measurer_unit_members_id: this.result.measurer_unit_members_id.filter(id => !this.bid.measurer_unit_members.mapValues('id').includes(id)),
					delete_measurer_unit_members_id: this.bid.measurer_unit_members.mapValues('id').filter(id => !this.result.measurer_unit_members_id.includes(id)),
				}
			}
		}
	},
	methods:{
		initResult() {
			this.result = this.bid ? {
				name: this.bid.name,
				unit_id: this.bid.unit_id,
				number: this.bid.number,
				measurer_unit_id: this.bid.measurer_unit_id,
				abbreviation: this.bid.abbreviation,
				measurer_unit_members_id: this.bid.measurer_unit_members.mapValues('id'),
			} : {}
		},
		onSubmit(evt) {
			evt.preventDefault();
			let result = this.updatedResult;
			if(!result) {
				alert('修改成功')
				this.$emit('submit')
			}
			else {
				this.$set(this.call, 'updateBid', false)
				this.$axios.updateBid(this.bid.id, result, (response) => {
					this.$emit('submit')
					this.call.updateBid = true;
				}, (error) => {
					this.call.updateBid = true;
				})
			}
		},
		onCancel() {
			this.$emit('cancel')
		}
	}
}
</script>

<style scoped>
.input {
	margin: .5rem .25rem
}
</style>