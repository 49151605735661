<template>
	<loading v-if="loading"/>
	<div class="col-12" v-else>
		<template v-if="!editing">
			<h5 class="ml-2 pb-2" style="white-space:normal">{{group.name}}</h5>
			<h6 class="ml-2" style="white-space:normal">案件種類：{{group.project_type}}</h6>
			<b-row class="justify-content-end" v-if="editable">
				<b-button class="form-btn clear-btn" @click="editing=true">編輯</b-button>
			</b-row>
			<b-row class="form-row">
				<div class="col-lg-6 col-sm-12">
					<b-card>
						<h6>專案內案件預設施工資料</h6>
						<b-list-group-item class="border-0 py-2 px-3" v-for="(construction_datum, key) in group.project_group_construction_data_types" :key="key">
							{{construction_datum.project_data_type.name}}
						</b-list-group-item>
					</b-card>
					<b-card >
						<h6>專案內案件預設報告</h6>
						<b-list-group-item class="border-0 py-2 px-3" v-for="(unit_type, key) in unitReportTypes" :key="key">
							<hr v-show="key" />
							繳交單位：{{unit_type.text}}
							<div class="p-1">
								<b-list-group-item class="border-0 py-1 px-5" variant="light" v-if="!unit_type.report_types.length">無</b-list-group-item>
								<b-list-group-item class="border-0 py-1 px-3" v-for="(report_type, key) in unit_type.report_types" :key="key">
									{{report_type.project_data_type.name}}
									<!-- <pre style="white-space: pre-wrap">{{report_type}}</pre> -->
								</b-list-group-item>
							</div>
						</b-list-group-item>
					</b-card>
				</div>
				<div class="col-lg-6 col-12">
					<b-card>
						<h6>專案使用者</h6>
						<b-list-group-item class="border-0 py-2 px-3" v-for="(user, key) in group_users" :key="key">
							{{user.user_name}} - {{user.permission_type}}
						</b-list-group-item>
					</b-card>
				</div>
			</b-row>
		</template>
		<group-setting-editor :group="group" :group_users="group_users" @cancel="editing=false" @submit="reload" v-else></group-setting-editor>
	</div>
</template>

<script>
import Loading from '@/components/Loading/Loading.vue'
import GroupSettingEditor from '@/components/Project/Group/GroupSettingEditor'
import { checkAllTrue } from '@/utils/assist';
export default {
	name: 'GroupSetting',
	components:{
		Loading,
		GroupSettingEditor,
	},
	props: {
		groupID: {
			required: true,
			default: 0
		},
		group: {
			type: Object
		},
		permission: {
			type: Object
		}
	},
	data() {
		return {
			loading: true,
			call: {
				getGroupUser: false
			},
			group_id: Number.isInteger(parseInt(this.groupID)) ? parseInt(this.groupID) : 0,
			editing: false,
			group_users: [],
		}
	},
	created() {
		if(typeof this.group === 'undefined') {
			this.reload()
		}
		else if(!this.group_id) {
			// TODO
			this.$router.replace({name: '專案', params: {groupID: this.group_id, from: {name: '專案列表'}}});
		}
		else {
			this.getGroupUser()
		}
	},
	destroyed() {
	},
	mounted() {
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	computed: {
		editable() {
			return this.checkPermission('edit') ? true : false
		},
		unitReportTypes() {
			return this.$store.getters.enum('unit.type').map(unit_type => {
				return {
					...unit_type,
					report_types: this.group.project_unit_report_types.filter(report_type => report_type.unit_type_key === unit_type.key).map(report_type => report_type)
				}
			})
		}
	},
	methods:{
		reload() {
			this.$router.replace({name: '專案', params: {groupID: this.group_id, from: {name: '專案資訊'}}});
		},
		getGroupUser() {
			this.call.getGroupUser = false
			this.$axios.getGroupPermissionList({project_group_id: this.group_id}, (response) => {
				// this.group_users = response.data.map(data => {return data.user_name})
				this.group_users = response.data
				this.call.getGroupUser = true
			}, (error) => {
				this.call.getGroupUser = true
			})
		},
		checkPermission(action_key) {
			if(!this.groupID) return this.$store.getters.isDeveloper;
			if(!this.permission) return false;
			return this.permission.actions.find(action => action.key === action_key)
		},
	}
}
</script>

<style scoped>
</style>