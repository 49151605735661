<template>
	<div class="py-2">
		<input-form class="p-0 col-12" :options="formOptions" v-model="result" @submit="onSubmit"></input-form>
	</div>
</template>

<script>
import InputText from '@/components/Input/InputText';
import InputSelect from '@/components/Input/InputSelect';
import InputForm from '@/components/Input/InputForm';
import Loading from '@/components/Loading/Loading.vue';
import { deepCopy, checkAllTrue } from '@/utils/assist';

export default {
	name: 'ProjectDataTypeCreator',
	components:{
		InputText,
		InputSelect,
		InputForm,
		Loading
	},
	props: {
	},
	data() {
		return {
			result: {},
		}
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	computed: {
		formOptions() {
			return [
				{ key: "name", type: "text", label: "名稱", required: true, size: 'xs' },
				{ key: "type", type: "select", label: "格式", required: true, options: this.$store.getters.enum('project_data_type.type'), keys: {value: 'index', text: 'text'}, size: 'xs' },
				{ key: "data_type", type: "select", label: "種類", required: true, options: this.$store.getters.enum('project_data_type.data_type'), keys: {value: 'index', text: 'text'}, size: 'xs' },
				{ key: "note", type: "text", label: "備註", required: false, multiline: true, size: 'xs' },
			]
		},
	},
	methods:{
		onSubmit(evt) {
			evt.preventDefault();
			this.$axios.createProjectDataType(this.result, (response) => {
				alert('新增成功')
				this.$emit('create')
			})
		},
	}
}
</script>

<style scoped>
.input {
	margin: .5rem .25rem
}
</style>
