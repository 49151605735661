<template>
	<loading v-if="!this.project || loading"/>
	<div v-else>
		<project-report-generator :project="projectData" :permission="permission" :project-data-type="projectDataType" :units="unitList" :report-types="reportTypes"></project-report-generator>		
	</div>
</template>


<script>
import ProjectReportGenerator from '@/components/Project/Report/ProjectReportGenerator'
import Loading from '@/components/Loading/Loading.vue';
import { deepCopy, sortArray, checkAllTrue } from '@/utils/assist';
import { TransformPointData, TransformPositioningPointData, GetPointTargetNames, GetPointType, GetPointTypeKey } from '@/gml/utils';
import dynamicFormHelper from '@/utils/dynamicForm';

export default {
	components: {
		ProjectReportGenerator,
		Loading
	},
	props: {
		groupID: {
			required: true,
			default: 0
		},
		projectID: {
			required: true,
			default: 0
		},
		projectType: {
			required: true,
			default: 0
		},
		project: {
			type: Object
		},
		projectDataType: {
			type: Array
		},
		permission: {
			type: Object
		},
	},
	data() {
		return {
			group_id: Number.isInteger(parseInt(this.groupID)) ? parseInt(this.groupID) : 0,
			project_type: Number.isInteger(parseInt(this.projectType)) ? parseInt(this.projectType) : 0,
			project_id: Number.isInteger(parseInt(this.projectID)) ? parseInt(this.projectID) : -1,
			call: {
				getUnitList: false,
				getConstructionSiteData: false,
				getPointList: false,
				getFourPitchReportData: false,
				getUnitBranchList: false,
				getUnitMemberList: false,
				getSignatureList: false,
				getResectionPointList: false,
				getPositioningPointList: false,
				getExportedReportTypes: false,
			},
			loading: true,
			units: [],
			unitBranches: [],
			unitMembers: [],
			signatures: [],
			constructionSiteData: [],
			constructionSiteFormTitles: [],
			points: [],
			pointFormTitles: [],
			resectionPoints: [],
			positioningPoints: [],
			positioningPointFormTitles: [],
			fourPitchData: [],
			reportTypes: [],
		}
	},
	created() {
		if(this.project === undefined || this.projectDataType === undefined) {
			this.reload()
		}
		else {
			this.init()
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
	},
	computed: {
		projectData() {
			return {
				...this.project,
				construction_sites: this.constructionSites,
			}
		},
		constructionSites() {
			return this.project.construction_sites.map(site => {
				let siteDatum = this.constructionSiteData.find(datum => site.id == datum.construction_site_id)
				let dataList = this.project.data_list.filter(datum => !datum.point || datum.point.construction_site_id === site.id)
				return {
					...site,
					data_list: dataList,
					data: dataList.groupBy('type_name'),
					dynamic_form_titles: this.$store.getters.enum('dynamic_form.type').filter(type => type.data.has_unit).reduce((obj, type) => {
						obj[type.key] = this[`${type.key.toCamelCase()}FormTitles`]
						return obj
					}, {}),
					result: siteDatum ? {
						...siteDatum,
						form_data: {
							status: { name: "狀態", value: site.status },
							creator_name: { name: "上傳人員", value: siteDatum.users.map(user => user.name).join("、") },
							created_at: { name: "上傳時間", value: siteDatum.created_at },
							...dynamicFormHelper.decodeFormData(this.constructionSiteFormTitles, siteDatum.modified_form_data)
						}
					} : undefined,
					points: this.getConstructionSitePoints(site.id),
					resection_points: this.getConstructionSiteResectionPoints(site.id),
					positioning_points: this.getConstructionSitePositioningPoints(site.id),
					four_pitch_data: this.getConstructionSiteFourPitchReportData(site.id),
					// TODO: 測量資料
					// data_list: 
				}
			})
		},
		measuredStatusIndexList() {
			return [
				'measured',
				'measurement_finished',
				'remeasured',
			].map(status => this.$store.getters.enum(`construction_site.status.${status}`).index)
		},
		measuredSites() {
			return this.project.construction_sites.filter(site => this.measuredStatusIndexList.includes(site.status_index))
		},
		unitList() {
			return this.units.map(unit => {
				return {
					...unit,
					branches: this.unitBranches.filter(o => o.unit_id === unit.id).map(branch => {
						return {
							...branch,
							members: this.unitMembers.filter(o => o.unit_branch_id === branch.id).map(member => {
								return {
									...member,
									signatures: this.getSignatures('unit_member', member.id),
								}
							}),
							signatures: this.getSignatures('unit_branch', branch.id),
						}
					}),
					members: this.unitMembers.filter(o => !o.unit_branch_id && o.unit_id === unit.id).map(member => {
						return {
							...member,
							signatures: this.getSignatures('unit_member', member.id),
						}
					}),
					signatures: this.getSignatures('unit', unit.id),
				}
			})
		},
	},
	methods:{
		reload() {
			this.$router.replace({name: '案件', params: {groupID: this.group_id, projectType: this.project_type, projectID: this.project_id, from: {name: '案件報告'}}});
		},
		init() {
			this.getUnitList()
			this.getUnitBranchList()
			this.getUnitMemberList()
			this.getSignatureList()
			this.getConstructionSiteData()
			this.getPointList()
			this.getResectionPointList()
			this.getPositioningPointList()
			this.getFourPitchReportData()
			this.getExportedReportTypes()
		},
		getUnitList() {
			this.call.getUnitList = false
			this.$axios.getUnitList({}, (response) => {
				this.units = response.data
				this.call.getUnitList = true
			}, (error) => {
				this.call.getUnitList = true
				this.call.getUnitMemberList = true
			})
		},
		getUnitBranchList() {
			this.call.getUnitBranchList = false
			this.$axios.getUnitBranchList({}, (response) => {
				this.unitBranches = response.data
				this.call.getUnitBranchList = true
			}, (error) => {
				this.call.getUnitBranchList = true
			})
		},
		getUnitMemberList() {
			this.call.getUnitMemberList = false
			this.$axios.getUnitMemberList({}, (response) => {
				this.unitMembers = response.data
				this.call.getUnitMemberList = true
			}, (error) => {
				this.call.getUnitMemberList = true
			})
		},
		getSignatureList() {
			this.call.getSignatureList = false
			this.$axios.getSignatureList({}, (response) => {
				this.signatures = response.data
				this.call.getSignatureList = true
			}, (error) => {
				this.call.getSignatureList = true
			})
		},
		getConstructionSiteData() {
			this.call.getConstructionSiteData = false
			if(!this.measuredSites.length) {
				this.call.getConstructionSiteData = true
				return
			}
			this.$axios.getConstructionSiteData({projects_id: [this.project_id], construction_sites_id: this.measuredSites.map(s=>s.id)}, (response) => {
				this.constructionSiteData = response.data
				this.constructionSiteFormTitles = response.titles
				this.call.getConstructionSiteData = true
			}, (error) => {
				alert(error)
				this.call.getConstructionSiteData = true
			})
		},
		getPointList() {
			this.call.getPointList = false
			if(!this.measuredSites.length) {
				this.call.getPointList = true
				return
			}
			this.$axios.getPointList({construction_sites_id: this.measuredSites.map(s=>s.id)}, (response) => {
				this.points = response.data
				this.pointFormTitles = response.titles
				this.call.getPointList = true
			}, (error) => {
				alert(error)
				this.call.getPointList = true
			})
		},
		getResectionPointList() {
			this.call.getResectionPointList = false
			if(!this.measuredSites.length) {
				this.call.getResectionPointList = true
				return
			}
			this.$axios.getResectionPointList({construction_sites_id: this.measuredSites.map(s=>s.id)}, (response) => {
				this.resectionPoints = response.data
				this.call.getResectionPointList = true
			}, (error) => {
				alert(error)
				this.call.getResectionPointList = true
			})
		},
		getPositioningPointList() {
			this.call.getPositioningPointList = false
			if(!this.measuredSites.length) {
				this.call.getPositioningPointList = true
				return
			}
			this.$axios.getPositioningPointList({construction_sites_id: this.measuredSites.map(s=>s.id)}, (response) => {
				this.positioningPoints = response.data
				this.positioningPointFormTitles = response.titles
				this.call.getPositioningPointList = true
			}, (error) => {
				alert(error)
				this.call.getPositioningPointList = true
			})
		},
		getFourPitchReportData() {
			this.call.getFourPitchReportData = false
			let four_positioning_project_ids = this.project.construction_sites.filter(site => site.tp_system_four_positioning_data).map(site => site.tp_system_four_positioning_data.project_id);
			if(!four_positioning_project_ids.length) {
				this.call.getFourPitchReportData = true
				return;
			}
			this.$axios.getFourPitchReportData({four_positioning_project_ids: four_positioning_project_ids}, (response, status) => {
				this.fourPitchData = response.data;
				this.call.getFourPitchReportData = true
			}, (error) => {
				this.call.getFourPitchReportData = true
			})
		},
		getExportedReportTypes() {
			this.call.getExportedReportTypes = false
			this.$axios.getExportedReportTypes({
				project_type: this.project_type,
				project_id: this.project_id,
			}, (response, status) => {
				this.reportTypes = response.data;
				this.call.getExportedReportTypes = true
			}, (error) => {
				this.call.getExportedReportTypes = true
			})
		},
		getConstructionSitePoints(siteId) {
			return this.points.filter(pt => pt.construction_site_id === siteId).map(pt => {
				let name = `pt${pt.index}`
				let form_data = dynamicFormHelper.decodeFormData(this.pointFormTitles, pt.modified_form_data)
				let transformed_data = TransformPointData({
					id: pt.id,
					index: pt.index,
					name: name,
					...pt.coordinate,
					...form_data,
				})
				return {
					...pt,
					name: name,
					point_name: `pt${pt.coordinate_name}`,
					full_name: `${pt.type_index}-${siteId}_${pt.index}`,
					form_data: form_data,
					transformed_data: transformed_data,
					transformed_type: GetPointType(transformed_data),
					transformed_type_key: GetPointTypeKey(transformed_data),
					data: pt.data_list.reduce((obj, datum) => {
						obj[datum.type_name] = datum
						return obj
					}, {}),
					targets: GetPointTargetNames(transformed_data),
				}
			})
		},
		getConstructionSiteResectionPoints(siteId) {
			return this.resectionPoints.filter(p=>p.construction_site_id === siteId).map(pt => {
				return {
					...pt,
					name: `pt${pt.index}`,
					point_name: `ptA${pt.index}`,
					full_name: `${pt.type_index}-${siteId}_${pt.index}`,
					data: pt.data_list.reduce((obj, datum) => {
						obj[datum.type_name] = datum
						return obj
					}, {})
				}
			});
		},
		getConstructionSitePositioningPoints(siteId) {
			let points = this.getConstructionSitePoints(siteId)
			return this.positioningPoints.filter(p=>p.construction_site_id === siteId).map(pt => {
				let name = `支距${pt.index}`
				let point = pt.point ? points.find(p => p.id === pt.point.id) : null
				let form_data = dynamicFormHelper.decodeFormData(this.positioningPointFormTitles, pt.modified_form_data)
				let transformed_data = TransformPositioningPointData({
					id: pt.id,
					index: pt.index,
					name: name,
					...form_data,
					point: point,
				})
				return {
					...pt,
					name: name,
					point_name: pt.point_index ? `pt${pt.point_index}` : '',
					point: point,
					full_name: `${pt.type_index}-${siteId}_${pt.index}`,
					form_data: form_data,
					transformed_data: transformed_data,
					data: pt.data_list.reduce((obj, datum) => {
						obj[datum.type_name] = datum
						return obj
					}, {})
				}
			});
		},
		getConstructionSiteFourPitchReportData(siteId) {
			let points = this.getConstructionSitePoints(siteId)
			return this.fourPitchData.filter(datum=>datum.construction_site_id === siteId).map(datum => {
				let name = `pt${datum.index}`
				let point = points.find(p => p.name === name)
				return {
					...datum,
					point_name: name,
					point: point,
				}
			})
		},
		getSignatures(ownerType, ownerId) {
			return this.signatures.filter(s => s.owner_type_key === `signature_${ownerType}` && s.owner_id === ownerId);
		},
		getProjectDataType(typeId) {
			return this.projectDataType.find(type => type.id === typeId)
		},
		compareFormData(data, compare_list) {
			let match = compare_list.map((cmp, cmp_index) => {
				return { index: cmp_index, value: cmp }
			}).filter(cmp => Object.keys(cmp.value).every(key => data[key] && data[key] === cmp.value[key]))
			.map(cmp => {
				return { ...cmp, priority: Object.keys(cmp).length }
			})
			sortArray(match, "priority", -1)
			return match.length ? match[0].index : undefined
		},
	}
}
</script>

<style scoped>
.right-top {
	position: absolute;
	top: 0;
	right: 0;
}
</style>