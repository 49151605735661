<template>
	<bv-table :columns="table.columns" :data="dynamicForms" :configs="table.configs" :actions="table.actions"></bv-table>
</template>

<script>
import BvTable from '@/components/Table/BvTable'
import { sortArray } from '@/utils/assist';
export default {
	name: 'DynamicFormList',
	components:{
		BvTable,
	},
	props: {
	},
	data() {
		return {
			table: {
				columns: [
					{ key: 'id', label: 'ID', sortable: true, searchable: true, hide: !this.$store.getters.isDeveloper },
					{ key: 'name', label: '名稱', sortable: true, searchable: true },
					{ key: 'type', label: '表單用途', sortable: true, searchable: true },
				],
				actions: [
					{
						//icon, label
						button: { type: 'icon', icon: "fas fa-cog", label: "編輯表單" },
						//function, link, route
						action: { type: 'route', route: this.routeToDynamicFormEditor, target: '_blank' }
					},
				],
				configs: {
					busy: true,
					actionsColumn: true,
					perPage: 10
				}
			},
			dynamicForms: [],
		}
	},
	watch: {
	},
	created() {
		this.getDynamicFormList()
	},
	mounted() {
	},
	computed: {
	},
	methods:{
		getDynamicFormList() {
			this.table.configs.busy = true;
			this.$axios.getDynamicFormList({}, (response) => {
				this.dynamicForms = sortArray(response.data, 'id')
				this.table.configs.busy = false;
			}, (error) => {
				this.table.configs.busy = false;
			})
		},
		routeToDynamicFormEditor(index) {
			return {name: '表單編輯', params: {formID: this.dynamicForms[index].id}}
		},
	}
}
</script>

<style scoped>
</style>