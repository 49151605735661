<template>
	<loading v-if="loading"/>
	<div v-else>
		<!-- <google-map class="google-map" :center="map.center" :zoom="map.zoom" :address="map.address" :markers="map.markers" :configs="map.configs" :modes="map.modes" :overlays="map.overlays" :options="{streetViewControl: false}"></google-map> -->
		<vue-google-map class="google-map" :center="map.center" :zoom="map.zoom" :address="map.address" :markers="map.markers" :configs="map.configs" :modes="map.modes" :overlays="map.overlays" v-if="value"></vue-google-map>
		<div class="p-2 text-right" style="color: dimgrey">管線總長: {{gmlGenerator.getPipelineLength()}} M</div>
	</div>
</template>

<script>
import GoogleMap from '@/components/GoogleMap/GoogleMap'
import VueGoogleMap from '@/components/GoogleMap/VueGoogleMap'
import Loading from '@/components/Loading/Loading.vue';
import { checkAllTrue } from '@/utils/assist';
import GmlGenerator from '@/gml';
import { IsFacilityType } from '@/gml/utils'

export default {
	name: 'ProjectResultGmapViewer',
	components: {
		GoogleMap,
		VueGoogleMap,
		Loading
	},
	props: {
		value: {
			type: Boolean,
			default: true
		},
		project: {
			type: Object
		},
		points: {
			type: Array,
			default: () => []
		},
		position: {
			type: Object,
			default: () => { return {} }
		}
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			call: {},
			loading: true,
			map: {
				center: {// 預設經緯度在信義區附近
					lat: 25.0325917,
					lng: 121.5624999,
				},
				markers: [],
				overlays: [],
				zoom: 12,
				configs: {
					drawing: false,
					geocoding: true,
				},
				address: '',
				modes: { hideMarkers: true, hideTransitions: true }
			},
			gmlGenerator: null
		}
	},
	created() {
		this.initMap()
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
		points: {
			deep: true,
			handler(value) {
				this.drawMap()
			}
		}
	},
	mounted() {
	},
	computed: {
		iconUrl(color_key='blue') {
			return (color_key) => {
				const color = { blue: 'blu', white: 'wht', green: 'grn', yellow: 'ylw' }
				let key = color[color_key] ? color[color_key] : color['blue']
				return `http://maps.google.com/mapfiles/kml/paddle/${key}-blank-lv.png`
			}
		}
	},
	methods:{
		initMap() {
			this.map.center = {
				...this.map.center,
				...Object.keys(this.position).reduce((pos, key) => {
					if(key in this.map.center)
						pos[key] = this.position[key];
					return pos
				}, {})
			}
			this.map.zoom = 18
			this.drawMap()
		},
		drawMap() {
			this.markPoints()
			this.drawFeatures()
		},
		markPoints() {
			this.map.markers.splice(0, this.map.markers.length)
			this.points.forEach(point => {
				let isFacility = IsFacilityType(point.transformed_type.type)
				this.map.markers.push({
					position: { lat: point.coordinate.lat, lng: point.coordinate.lng },
					icon: {
						url: isFacility ? this.iconUrl('yellow') : this.iconUrl(),
						scaledSize: { width: 10, height: 10 },
					},
					zIndex: isFacility ? 999 : 0,
					label: point.index,
					info: {
						名稱: point.name,
						座標: `N: ${point.coordinate.twd97_y}, E: ${point.coordinate.twd97_x}`,
						埋深: point.transformed_data.depth,
						種類: point.transformed_data.type,
					},
					infoWindowOptions: {
						pixelOffset: { width: 0, height: -10 }
					}
				})
			})
		},
		getGmlFeatures() {
			this.gmlGenerator = new GmlGenerator({
				...this.project,
				file_made_at: this.project.file_made_at ?? new Date().format('yyyy-MM-dd'),
			}, this.points.map((pt, index) => {
				return {
					index: pt.index,
					name: pt.name,
					...pt.coordinate,
					...pt.form_data
				}
			}), true);
			this.gmlGenerator.checkValid();
			return this.gmlGenerator.getFeatures();
		},
		drawFeatures() {
			let features = this.getGmlFeatures()
			this.map.overlays = features.getLines().map(feature => {
				return {
					type: 'polyline',
					path: feature.nodes.map(node => {
						let pt = this.points.find(pt => pt.name === node.name)
						return pt && pt.coordinate ? { lat: pt.coordinate.lat, lng: pt.coordinate.lng } : null
					}).filter(node => node),
					click: () => {
						console.log(feature.toString())
					}
				}
			})
		}
	}
}
</script>

<style scoped>
.google-map {
	min-height: 500px;
	height: 500px;
}

.progress-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0005;
	z-index: 10;
}
.progress-bar-wrapper {
	width: 50%;
	margin: auto;
	top: 50%;
	background: #e1e6e690;
	box-shadow: 0 0 5px .5px #284a7090;
}
.progress-bar {
	background: #017ca7;
}
.progress-bar-striped {
	background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
	background-size: 1rem 1rem;
}
.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite;
}

</style>