<template>
	<div>
		<h6 class="my-1">{{title}}</h6>
		<div class="filter-wrapper">
			<bv-filter-item :text="option.text" :value="option.value" :disabled="option.disabled" :state="checkState(option.value)" v-for="(option, key) in opts" :key="key" @click="selectOption" />
		</div>
	</div>
</template>

<script>
import BvFilterItem from '@/components/Filter/BvFilterItem.vue'

export default {
	name: 'BvFilter',
	components: {
		BvFilterItem
	},
	props: {
		title: {
			type: String,
			default: ''
		},
		options: {
			type: Array,
			default: () => []
		},
		keys: {
			type: [Object, String],
			default: ''
		},
		value: {
			type: [String, Number, Array],
			// default: () => undefined,
		},
		multiple: {
			type: Boolean,
			default: false
		},
		hasAll: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			model: undefined,
			all: 'ALL',
		}
	},
	watch: {
		options: {
			deep: true,
			handler() {
				this.init()
			}
		},
		keys: {
			deep: true,
			handler() {
				this.init()
			}
		},
		value: {
			handler(value) {
				this.model = value
			}
		},
		model: {
			handler(value) {
				this.change(value)
			}
		},
	},
	created() {
		this.init()
	},
	mounted () {
	},
	computed: {
	},
	methods: {
		init() {
			this.getOptions(this.options, this.keys)
			let value = this.hasAll && (this.multiple ? (!Array.isArray(this.value) || !this.value.length) : this.value === undefined) ? this.all : undefined
			this.model = this.multiple ? (Array.isArray(value) ? value : [value]) : (Array.isArray(value) ? value[0] : value)
			this.model = this.multiple ? this.model.filter(v => this.opts.find(opt => opt.value === v)) : (this.opts.find(opt => opt.value === this.model) ? this.model : (this.hasAll ? this.all : undefined))
		},
		getOptions(options, key) {
			this.opts = Array.isArray(options) ? options.map(option => {
				let opt = typeof option === 'object' && !this.isEmpty(key.text) && !this.isEmpty(key.value) ? { text: this.getRecursiveValue(option, key.text), value: this.getRecursiveValue(option, key.value) } : typeof option === 'object' && typeof key === 'string' && !this.isEmpty(option[key]) ? { text: option[key], value: option[key] } : typeof option === 'object' && !this.isEmpty(option.text) && !this.isEmpty(option.value) ? option : { text: option, value: option }
				return {...option, ...opt}
			}) : { text: options, value: options };
			if(this.hasAll && !this.multiple) {
				this.opts.unshift({ text: this.all, value: this.all })
			}
			this.$forceUpdate()
		},
		selectOption(value) {
			if(this.multiple) {
				let index = this.model.findIndex(value)
				if(index >= 0) this.model.splice(index, 1)
				else this.model.push(value)
			}
			else {
				this.model = value
			}
		},
		checkState(value) {
			return this.multiple ? this.model.includes(value) : `${this.model}` === `${value}`
		},
		change(e) {
			this.$emit('input', this.model)
			this.$emit('change', this.model)
		},
		getRecursiveValue(obj, key_str, key_delim='.') {
			let keys = key_str.split(key_delim);
			let res = obj;
			while(keys.length) {
				let key = keys.shift();
				if(typeof res === 'undefined' || res === null)
					break;
				res = res[key];
			}
			return res
		},
		isEmpty(obj) {
			// null and undefined are "empty"
			if (obj == null || typeof obj === "undefined") return true;

			if(!Number.isNaN(Number(obj))) return false

			// Assume if it has a length property with a non-zero value
			// that that property is correct.
			if (obj.length > 0)    return false;
			if (obj.length === 0)  return true;

			// If it isn't an object at this point
			// it is empty, but it can't be anything *but* empty
			// Is it empty?  Depends on your application.
			if (typeof obj !== "object") return true;

			// Otherwise, does it have any properties of its own?
			// Note that this doesn't handle
			// toString and valueOf enumeration bugs in IE < 9
			var hasOwnProperty = Object.prototype.hasOwnProperty;
			for (var key in obj) {
				if (hasOwnProperty.call(obj, key)) return false;
			}

			return true;
		}
	}
}
</script>

<style scoped>
.filter-wrapper {
	white-space: normal;
}
</style>