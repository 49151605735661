import Xml2Json from './ref/Xml2Json'
import Constants from './Constants'
import Feature from './Feature'
import Utility from './Utility'
import Templates from './Templates'

class GmlConverter {
	/**
	 * @param {Object} meta 
	 * @param {Feature[]} features
	 */
	constructor(meta, features) {
		this.setMetadata(meta)
		this.features = features
	}

	/**
	 * 設定meta data
	 */
	setMetadata(meta) {
		this.meta = {
			...meta,
			excavationPermit: meta.excavationPermit ? meta.excavationPermit : (Date.now() % 10000000000).toString(),
			permit: !meta.excavationPermit || meta.excavationPermit === '無路證' ? (Date.now() % 10000000).toString() : meta.excavationPermit,
		}
		return this
	}

	/**
	 * 取得GML
	 * @param {String} versionKey 
	 */
	getGml(versionKey) {
		// GML版本
		let version = Constants.GML.VERSIONS[versionKey]
		// GML模板key
		let templateKey = version.template
		// GML模板configs
		let configs = Templates[templateKey]
		// GML模板(Xml2Json{})
		let templates = this.#readTemplates(configs.templates)
		let xml = templates.header
		
		// 填入header資料
		let meta = this.#getGmlHeaderValues(templateKey)
		xml.setHeader(configs.xml_header)
		this.#getGmlHeaderAttributes(templateKey).forEach(attr => {
			let tag = this.#getGmlAttributeTag(attr, version.elementTagKey)
			xml.setValue(`${configs.tag.root}.${configs.tag.meta}.${tag}`, 0, meta[attr.key])
		})
		
		// GML設施物
		let utilities = this.getGmlUtilities(versionKey)
		// GML設施物(Xml2Json[])
		let gmlUtilities = utilities.map(utility => {
			// GML設施物模板
			let template = templates[utility.getUtilityKey().toLowerCase()].copy()
			// 填入GML設施物資料
			let values = utility.getGmlValues(templateKey)
			utility.getGmlAttributes(templateKey).forEach(attr => {
				let tag = this.#getGmlAttributeTag(attr, version.elementTagKey, utility.getUtilityKey())
				let temp = template.search(`${utility.configs.tag}.${tag}`)
				if(!temp[0] || !temp[0][0]) {
					console.error(`Gml模板中無此元素：${templateKey} ${tag}(${utility.configs.tag}.${tag})`)
					return
				}
				temp = temp[0][0]
				template.setValue(`${utility.configs.tag}.${tag}`, 0, values[attr.key])
			})
			return template
		})
		// 加入header中
		gmlUtilities.forEach((feature) => {
			let index = feature.jXml[0][configs.tag.feature]
			xml.insertJNode(configs.tag.root, index, configs.tag.feature)
		})
		return {
			name: this.getGmlFileName(versionKey),
			configs: version.configs,
			content: xml.getXml()
		}
	}

	#getGmlAttributeTag = (attribute, elementTagKey='DEFAULT', utilityKey='DEFAULT') => {
		let tag = attribute.tag
		let defaultTag = ''
		if(Object.isObject(tag)) {
			defaultTag = tag.DEFAULT ?? defaultTag
			tag = tag[elementTagKey ?? 'DEFAULT'] ?? tag.DEFAULT
		}
		if(Object.isObject(tag)) {
			defaultTag = tag.DEFAULT ?? defaultTag
			tag = tag[utilityKey ?? 'DEFAULT'] ?? tag.DEFAULT
		}
		return tag ?? defaultTag
	}

	/**
	 * 取得GML設施物
	 * @param {String}} versionKey 
	 */
	getGmlUtilities(versionKey) {
		Utility.resetCounter()
		let rules = versionKey && Constants.GML.VERSIONS[versionKey] && Constants.GML.VERSIONS[versionKey].rules ? Constants.GML.VERSIONS[versionKey].rules : Constants.GML.PIPELINE_SPLIT_DEFAULT_RULES
		return this.features.getUtilities(this.meta, rules)
	}

	/**
	 * 取得GML header屬性
	 * @param {String} templateKey 
	 * @return {Array}
	 */
	#getGmlHeaderAttributes = (templateKey) => {
		return Object.values(Constants.GML.ATTRIBUTES).filter(attr => attr.src === Constants.GML.ATTRIBUTE_TYPES.SRC.HEADER && (!attr.templates || attr.templates.includes(templateKey)))
	}

	/**
	 * 取得GML header屬性資料
	 * @param {String} templateKey 
	 * @return {Object}
	 */
	#getGmlHeaderValues = (templateKey) => {
		let attributes = this.#getGmlHeaderAttributes(templateKey)
		return attributes.reduce((obj, attr) => {
			let value = attr.value ? attr.value(this.meta, templateKey) : undefined
			obj[attr.key] = value !== undefined ? value : attr.default
			return obj
		}, {})
	}

	/**
	 * 讀取模板
	 * @param {String{}} templates 
	 * @return {Xml2Json{}}
	 */
	#readTemplates = (templates) => {
		return Object.keys(templates).reduce((obj, key) => {
			let text = templates[key]
			let xmlDoc = {}
			if (window.DOMParser) {
				// code for modern browsers
				xmlDoc = new DOMParser().parseFromString(text, "text/xml");
			}
			obj[key] = new Xml2Json(xmlDoc)
			return obj
		}, {})
	}

	/**
	 * 依meta.filenameFormat取GML檔名
	 * @return {String}}
	 */
	getGmlFileName(versionKey) {
		let number = this.meta.filenameFormat.split('.').map(key => {
			switch(true) {
				case key === 'VERSION_TAG': {
					let version = Constants.GML.VERSIONS[versionKey]
					return (version && version.tag ? `(${version.tag})` : '')
				}
				case key === 'PERMIT':
					return this.meta.excavationPermit;
				case key === 'ADDRESS':
					return this.meta.address;
				case key === 'HAS_DITCH_COVER':
					return this.features.filter(feature => feature.type.getFormTypeKey() === 'DITCH_COVER').length ? `(${Constants.TABLE.TYPE.DITCH_COVER.text})` : '';
				default:
					return key;
			}
		})
		return number.join('')
	}

	/**
	 * 依meta.gmlVersions取GML版本
	 * @return {String}}
	 */
	getGmlVersions() {
		return this.meta.gmlVersions.filter(key => Constants.GML.VERSIONS[key])
	}
}

export default GmlConverter