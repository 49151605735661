<template>
	<loading v-if="loading"/>
	<div v-else>
		<h6>編輯工地資料：{{site.name}}</h6>
		<input-form class="p-0 col-12" :options="formOptions" v-model="formData" @submit="onSubmit" @cancel="cancel"></input-form>
	</div>
</template>

<script>
import InputForm from '@/components/Input/InputForm';
import InputText from '@/components/Input/InputText';
import InputSelect from '@/components/Input/InputSelect';
import Loading from '@/components/Loading/Loading.vue';
import { deepCopy, isEmpty, checkAllTrue, isEqual } from '@/utils/assist';

export default {
	name: 'ConstructionSiteEditor',
	components: {
		InputForm,
		InputText,
		InputSelect,
		Loading
	},
	props: {
		project: {
			type: Object,
			default: () => { return {} }
		},
		site: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			call: {getUnitDynamicForm: false},
			loading: true,
			dynamicForms: [],
			formData: {
				dynamic_form: [],
			},
			originalData: {},
			upload: [],
			uploading: {active: false, amount: 0, finished: 0},
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		uploading: {
			deep: true,
			handler(value) {
				if(this.uploading.active && this.uploading.amount === this.uploading.finished) {
					this.$nextTick(() => {
						this.uploading.active = false
						alert('修改成功');
						this.editEnd()
					})
				}
			}
		},
		'uploading.active': {
			handler(value) {
				this.$emit('loading', this.uploading.active)
			}
		}
	},
	mounted() {
	},
	computed: {
		dynamicFormTypes() {
			return this.$store.getters.enum('dynamic_form.type').filter(type => type.data.has_unit)
		},
		formOptions() {
			return [
				{ key: "name", type: "text", label: "工地名稱", required: true, size: 'xs', pattern: "^[a-zA-Z0-9\u4E00-\u9FFF\uff08-\uff09_]{2,}$", invalidMessage: "請輸入2個字元以上非特殊符號" },
				{ key: "paved", type: 'radio', label: "是否通知銑鋪", size: 'xs', boolean: true, options: this.$store.getters.enum('construction_site.paved'), keys: {value: 'index', text: 'text'} },
				{ key: "paved_at", type: "date", label: "預計銑舖日期", size: 'xs', hide: (v, k, data) => data.paved !== this.$store.getters.enum('construction_site.paved.paved').index },
				...this.dynamicFormTypes.map((type, key) => {
					let forms = this.dynamicForms.filter(o=>o.type_index===type.index)
					return {
						key: `${type.key}_dynamic_form_id`, type: "select", label: `${type.text}表單`, required: !!forms.length, size: 'xs', options: forms, keys: {value: 'id', text: 'name'}
					}
				}),
				{ key: "scene_pictures_url", type: "url", label: "資料連結", required: true, size: 'xs', },
				{ key: "note", type: "text", label: "備註", size: 'xs', multiline: true },
			]
		},
		siteData() {
			const formTypeKey = [
				"forms", "point_forms"
			]
			return {
				name: this.site.name,
				paved: this.site.paved_index,
				paved_at: this.site.paved_at,
				// lat: this.site.lat,
				// lng: this.site.lng,
				scene_pictures_url: this.site.scene_pictures_url,
				note: this.site.note,
				...this.dynamicFormTypes.reduce((obj, type, index) => {
					let form = (this.site[formTypeKey[index]] ?? []).first
					obj[`${type.key}_dynamic_form_id`] = form ? form.id : undefined
					return obj
				}, {})
			}
		},
	},
	created() {
		this.initFormData()
		this.getUnitDynamicForm()
	},
	methods:{
		initFormData() {
			this.formData = this.siteData
			this.originalData = {
				...Object.cloneDeep(this.formData),
				lat: this.site.lat,
				lng: this.site.lng,
			}
		},
		getUnitDynamicForm() {
			this.call.getUnitDynamicForm = false
			this.$axios.getUnitDynamicForm(this.project.party_a_id, {}, (response) => {
				this.dynamicForms = Object.values(response.data).filter(o => o);
				this.call.getUnitDynamicForm = true
			}, (error) => {
				this.call.getUnitDynamicForm = true
			});
		},
		cancel() {
			this.$emit('cancel')
		},
		editEnd() {
			this.$emit('edit')
			this.$emit('cancel')
		},
		onSubmit(evt) {
			evt.preventDefault()
			let formData = {
				...this.formData,
				lat: this.site.lat,
				lng: this.site.lng,
			}
			let updateData = Object.diff(formData, this.originalData)
			if(Object.isEmpty(updateData)) {
				this.cancel()
			}
			else {
				this.updateConstructionSiteData(updateData)
			}
		},
		updateConstructionSiteData(data) {
			this.$set(this.call, 'updateConstructionSiteData', false)
			this.$axios.updateConstructionSiteData(this.site.id, data, (response) => {
				alert('修改成功');
				this.call.updateConstructionSiteData = true
				this.editEnd()
			}, (error) => {
				this.call.updateConstructionSiteData = true
			})
		}
	}
}
</script>

<style scoped>

.right-top {
	position: absolute;
	top: 0;
	right: 0;
}


.progress-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0005;
	z-index: 10;
}
.progress-bar-wrapper {
	width: 50%;
	margin: auto;
	top: 50%;
	background: #e1e6e690;
	box-shadow: 0 0 5px .5px #284a7090;
}
.progress-bar {
	background: #017ca7;
}
.progress-bar-striped {
	background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
	background-size: 1rem 1rem;
}
.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite;
}
</style>