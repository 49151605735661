<template>
	<span class="photo-wrapper flex-column justify-content-center">
		<!-- <span class="photo loading" v-if="loading" :style="`background-image:url(${loading_img_url})`"></span> -->
		<span class="photo flex-column d-flex" :class="{pointer: selectable, loading: loading, 'photo-showname': showname}" :style="loading ? `background-image:url(${loading_img_url})` : ''" @click="select">
			<img :style="`${photoStyle} ${loading ? 'visibility:hidden' : 'visibility:unset'}`" :src="photo_src" @load="onload">
			<b-row class="photo-name justify-content-center" :class="`${textClass} ${selectable ? 'pointer' : ''}`" :style="textStyle" v-if="showname"><a @click="select">{{photo_name}}</a></b-row>
		</span>
		<div class="photo-btn-wrapper" v-show="deletable || editable">
			<button class="photo-btn photo-edit-btn" @click="edit" v-show="editable"><i class="fas fa-pen"></i></button>
			<button class="photo-btn photo-delete-btn" @click="del" v-show="deletable"><i class="fas fa-times"></i></button>
		</div>
	</span>
</template>

<script>
import loading_img from '@/assets/images/loading.gif';
import { isEmpty } from "@/utils/assist"
export default {
	name: 'Photo',
	components:{
	},
	props: {
		photo: {
			type: [Object, String]
		},
		photoStyle: {
			type: String,
			default: ""
		},
		textClass: {
			type: String,
			default: ""
		},
		textStyle: {
			type: String,
			default: ""
		},
		deletable: {
			type: Boolean,
			default: false
		},
		selectable: {
			type: Boolean,
			default: false
		},
		editable: {
			type: Boolean,
			default: false
		},
		showname: {
			type: Boolean,
			default: false
		},
		keys: {
			type: [Object, String],
			default: ''
		}
	},
	data() {
		return {
			loading: true,
			loading_img_url: loading_img,
			photo_src: '',
			photo_name: ''
		}
	},
	created() {
		this.getPhoto(this.photo, this.keys)
	},
	destroyed() {
	},
	mounted() {
	},
	watch: {
		photo: {
			deep: true,
			handler(photo) {
				this.getPhoto(photo, this.keys)
			}
		}
	},
	methods:{
		isEmpty(obj) { return isEmpty(obj) },
		getPhoto(photo, key) {
			if(typeof photo === 'object' && !this.isEmpty(key.name) && !this.isEmpty(key.src)) {
				this.photo_name = photo[key.name]
				this.photo_src = photo[key.src]
			}
			else if(typeof photo === 'object' && typeof key === 'string' && !this.isEmpty(photo[key])) {
				this.photo_name = photo[key]
				this.photo_src = photo[key]
			}
			else if(typeof photo === 'object' && !this.isEmpty(photo.name) && !this.isEmpty(photo.src)) {
				this.photo_name = photo.name
				this.photo_src = photo.src
			}
			else if(typeof photo === 'string') {
				this.photo_name = this.getName(photo)
				this.photo_src = photo
			}
			else {
				this.photo_name = photo
				this.photo_src = photo
			}
			this.$forceUpdate()
		},
		getName(name) {
			let filename = name.split('/').pop().split('.')
			filename.pop()
			return filename.join()
		},
		onload(e) {
			this.loading = false
			this.$emit("load")
		},
		select() {
			this.$emit("select")
		},
		del() {
			this.$emit("delete")
		},
		edit() {
			this.$emit("edit")
		}
	}
}
</script>

<style scoped>
.photo-wrapper {
	padding: .25rem;
}
.photo {
	/* height: 100%; */
	width: 100%;
	padding: .5rem;
	border: .5px solid #cbd8e1;
	border-radius: 1rem;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}
.photo.photo-showname {
	padding-bottom: .25rem;
}
.photo img {
	max-width: 100%;
	max-height: 100%;
	border-radius: .75rem;
}
.photo-name {
	padding: .25rem .5rem 0 .5rem;
}
.photo-name * {
	font-size: .75rem;
	white-space: normal;
}
.photo-btn-wrapper {
	display: flex;
	position: absolute;
	right: .75rem;
	top: .75rem;
	z-index: 3;
}
.photo-btn {
	margin: 0 .25em;
	height: 1rem;
	width: 1rem;
	font-size: .6rem;
	/* display: none; */
	display: flex;
	align-items: center;
	justify-content: center;
	border: .5px solid #cbd8e150;
	border-radius: 50%;
	box-shadow: 0 0 2px 1px #cbd8e1;
	background-color: #cbd8e1a0;
}
.photo:hover ~ .photo-btn-wrapper > .photo-btn, .photo-btn-wrapper:hover > .photo-btn {
	display: flex;
}
.photo-btn:hover {
	background-color: #cbd8e1;
	box-shadow: 0 0 2px 1px #b2c8d8;
}
/* .photo-btn.photo-edit-btn {
} */
/* .photo-btn.photo-delete-btn {
	border: .5px solid #d9766c50;
	box-shadow: 0 0 2px 1px #e4d3d1;
	background-color: #e4d3d1a0;
	color: #5c0702;
}
.photo-btn.photo-delete-btn:hover {
	box-shadow: 0 0 2px 1px #d9766c;
	background-color: #e4d3d1;
} */
.loading {
	width: 100%;
	height: 100%;
	/* background-color: transparent; */
	background-repeat: no-repeat;
	background-size: 40px;
	background-position: center;
	min-height: 80px;
	min-width: 80px;
}
</style>