<template>
	<loading class="main-wrapper" :show="loading" v-if="loading"/>
	<div class="main-wrapper" v-else>
		<div class="sidebar-wrapper" v-if="!$route.meta.hideSideBar">
			<side-bar @mobileCloseSidebar="mobileCloseSidebar" :title="title" :subtitle="subtitle" :menu="menu"/>
		</div>
		<div class="content-wrapper col-12">
			<Header id="Header" :hideSideBar="$route.meta.hideSideBar" @mobileOpenSidebar="mobileOpenSidebar" v-if="!$route.meta.hideHeader" />
			<div id="Content">
				<b-breadcrumb class="nav-breadcrumb" :items="breadcrumbList" v-if="breadcrumbList && breadcrumbList.length"></b-breadcrumb>
				<div class="px-md-4 px-1">
					<router-view class="main-content" v-if="!$store.getters.isLoading" :menu="menu" :group="nowGroup"></router-view>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Header from '@/components/Header.vue';
import SideBar from '@/components/SideBar/SideBar.vue'
import Loading from '@/components/Loading/Loading.vue'
import { isEmpty, checkAllTrue } from '@/utils/assist';
export default {
	components: {
		Header,
		SideBar,
		Loading
	},
	data() {
		return {
			breadcrumbList: [],
			call: { getUserPermissions:this.userPermissions ? true : false},
			loading: true,
			unit_list: [],
			project_category_keys: ["party_a"],
			group: {},
			permissions: undefined,
			groupPermissions: undefined,
			menu: {},
			title: process.env.VUE_APP_NAME,
			subtitle: ''
		}
	},
	watch: {
		'$route': {
			deep: true,
			handler(value) {
				this.updateMenu()
				this.updateList()
			}
		},
		call: {
			deep: true,
			// immediate: true,
			handler(value) {
				this.loading = this.$store.getters.isLoading || checkAllTrue(value, this.onLoadEnd) || !this.userPermissions
			}
		},
		'$store.getters.isLoading': {
			handler(value) {
				if(!value){
					this.getGroupInfo();
					this.getGroupPermission();
					this.getUserPermissions()
				}
				// if(!value)
				// 	this.getUnitList();
			}
		},
		loading() {
			if(!this.loading) {
				this.updateMenu()
			}
		},
		inGroup() {
			this.subtitle = this.inGroup ? this.nowGroup.name : '';
		}
	},
	created() {
		this.$store.dispatch('init');
		if(this.groupId < 0) {
			this.$router.push({name: '專案列表'})
		}
		else {
			this.getGroupInfo();
			this.getGroupPermission();
			this.getUserPermissions()
		}
	},
	mounted () {
	},
	computed: {
		inGroup() {
			return this.$route.matched.find(route => route.name === "專案")
		},
		groupId() {
			return this.inGroup ? (Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : this.$route.params.groupID) : 0;
		},
		inValidGroup() {
			return this.groupId > 0 ? true : false;
		},
		userPermissions() {
			return this.permissions ? (this.$store.getters.currentUser ? this.$store.getters.currentUser.permissions : undefined) : undefined;
		},
		userGroupPermission() {
			let groupPermission = this.groupPermissions.find(permission => permission.project_group_id == this.groupId)
			const actions = this.$store.getters.enum(`permission.action_type`)
			if(this.$store.getters.isDeveloper) {
				groupPermission = this.getDefaultPermissionByKey('super')
			}
			else if(!groupPermission) {
				groupPermission = this.getDefaultPermissionByKey('none')
			}
			let permission = this.$store.getters.enum(groupPermission.permission_type_enum)
			return {
				...groupPermission,
				actions: permission.data.actions.map(action_index => {
					return actions.find(action => action.index === action_index)
				})
			}
		},
		nowGroup() {
			if(this.inValidGroup && (!this.group || this.groupId !== this.group.id)) {
				this.getGroupInfo()
				this.getGroupPermission()
			}
			return this.inValidGroup ? this.group : (this.inGroup ? {
				id: 0,
				name: '未分類',
			} : undefined);
		}
	},
	methods: {
		onLoadEnd() {
			this.updateMenu()
			this.updateList()
		},
		// Script to open and close sidebar when on tablets and phones
		mobileOpenSidebar() {
			document.getElementById("Sidebar").classList.add("animate-left");
			document.getElementById("Sidebar").classList.add("open");
			document.getElementById("Sidebar").style.display = "block";
			document.getElementById("Overlay").classList.add("mobile");
			//document.getElementById("Sidebar").classList.add("fixed-sidebar");
			//document.getElementById("Overlay").classList.remove("hide", "fixed-sidebar");
		},
		mobileCloseSidebar() {
			document.getElementById("Sidebar").style = "";
			document.getElementById("Sidebar").classList.remove("open");
			document.getElementById("Sidebar").classList.remove("animate-left");
			document.getElementById("Overlay").classList.remove("mobile");
			//document.getElementById("Overlay").classList.add("hide", "fixed-sidebar");
		},
		//Route
		routeTo (pRouteTo) {
			if (this.breadcrumbList[pRouteTo].link)
				this.$router.push(this.breadcrumbList[pRouteTo].link)
		},
		updateList () {
			this.breadcrumbList = this.$route.meta.breadcrumb;
		},
		getUnitList() {
			this.call.getUnitList = false
			let types_index = this.$store.getters.enum('unit.type').filter(type => this.project_category_keys.includes(type.key)).map(type => type.index)
			this.$axios.getUnitList({types_index: types_index}, (response) => {
				this.unit_list = response.data;
				this.call.getUnitList = true
			}, (error) => {
				this.call.getUnitList = true
			})
		},
		getGroupInfo() {
			if(!this.$store.getters.isLoading && this.$store.getters.initialized && this.inValidGroup) {
				this.$set(this.call, 'getGroupInfo', false);
				if(!this.groupId) {
					// this.group = { id: 0, name: '未分類' }
					this.call.getGroupInfo = true;
				}
				else {
					this.$axios.getGroupInfo(this.groupId, (response) => {
						this.group = response.data
						this.call.getGroupInfo = true
					}, (error) => {
						this.call.getGroupInfo = true
					})
				}
			}
			else if(!this.inValidGroup) {
				this.$set(this.call, 'getGroupInfo', true);
			}
		},
		getGroupPermission() {
			if(!this.$store.getters.isLoading && this.$store.getters.initialized && this.inValidGroup) {
				this.$set(this.call, 'getGroupPermission', false)
				if(!this.groupId) {
					this.groupPermissions = []
					this.call.getGroupPermission = true;
				}
				this.$axios.getGroupPermissionList({project_group_id: this.groupId, user_id: this.$store.getters.currentUser.id}, (response) => {
					this.groupPermissions = response.data
					this.call.getGroupPermission = true
				}, (error) => {
					this.call.getGroupPermission = true
				})
			}
			else if(!this.inValidGroup) {
				this.$set(this.call, 'getGroupPermission', true)
			}
		},
		getUserPermissions() {
			if(!this.$store.getters.isLoggedIn) {
				this.$set(this.call, 'getUserPermissions', true)
			}
			else {
				this.call.getUserPermissions = false
				if(!this.$store.getters.isLoading && this.$store.getters.initialized && !this.userPermissions) {
					this.$axios.getUserPermissions((response) => {
						this.$store.dispatch('setUserPermissions', response.data.system_permission);
						this.permissions = response.data.system_permission
						this.call.getUserPermissions = true
					}, (error) => {
						this.call.getUserPermissions = true
					})
				}
				else if(this.userPermissions) {
					this.call.getUserPermissions = true
				}
			}
		},
		updateMenu() {
			this.menu = Object.assign(this.menu, {
				system: {
					name: '系統管理', icon: 'fas fa-cog', type: 'group', hide: !this.checkMenuPermission('system', 'read'),
					children: [
						{ name: '人員管理', icon: 'fas fa-users-cog', path: { name: '人員管理' } },
						{ name: '單位列表', icon: 'fas fa-building', path: { name: '單位列表' } },
						{ name: '標案管理', icon: 'fas fa-briefcase', path: { name: '標案管理' } },
						{ name: '工程管理', icon: 'fas fa-truck-pickup', path: { name: '工程管理' } },
						{ name: '表單管理', icon: 'fas fa-file-alt', path: { name: '表單管理' } },
						{ name: '案件資料', icon: 'fas fa-file', path: { name: '案件資料管理' } },
						{ name: 'APP版本', icon: 'fas fa-file', path: { name: 'APP版本' } },
					]
				},
				instrument_adjustment: {
					name: '儀器校驗', icon: 'fas fa-sliders-h', type: 'group', hide: !this.checkMenuPermission('instrument_adjustment', 'read'),
					children: [
						{ name: '控制點', icon: 'fas fa-anchor', path: { name: '控制點' } },
					]
				},
				assignment: {
					name: '派工系統', icon: 'fas fa-location-arrow', type: 'group', hide: !this.checkMenuPermission('assignment', 'read'),
					children: [
						{ name: '外務人員', icon: 'fas fa-users', path: { name: '外務人員' } },
						{ name: '派工頁面', icon: 'fas fa-map-marked-alt', path: { name: '派工頁面' } },
					]
				},
				project_group: {
					name: '專案列表', icon: 'fa fa-clipboard-list', type: 'group', hide: !this.checkMenuPermission('project_group', 'read'),
					children: [
						{ name: '新增專案', icon: 'far fa-plus-square', path: { name: '新增專案' }, hide: !this.checkMenuPermission('project_group', 'write') },
						{ name: '專案列表', icon: 'fa fa-list', path: { name: '專案列表' } },
					]
				},
				project: {
					name: '專案管理', icon: 'far fa-credit-card', type: 'group', hide: !this.inGroup || !this.checkMenuPermission('project_group', 'read'),
					children: [
						{ name: '專案管理', icon: 'fas fa-home', path: { name: '專案資訊', params: { groupID: this.groupId } }, hide: !this.inValidGroup },
						{ name: '新增案件', icon: 'far fa-plus-square', path: { name: '新增專案案件', params: { groupID: this.groupId } }, hide: !this.inValidGroup || !this.checkGroupManuPermission('write') },
						// ...this.getProjectMenu()
						{ name: '案件列表', icon: 'fas fa-list', path: { name: '專案案件列表', params: { groupID: this.groupId } } },
						{ name: '案件表單', icon: 'fas fa-list', path: { name: '專案案件表單', params: { groupID: this.groupId } } },
						{ name: '工地列表', icon: 'far fa-list-alt', path: { name: '專案工地列表', params: { groupID: this.groupId } } },
						{ name: '專案設定', icon: 'fas fa-cog', path: { name: '專案設定', params: { groupID: this.groupId } }, hide: !this.inValidGroup },
					]
				}
			})
		},
		checkMenuPermission(system_control_key, action_key) {
			let system_control_type = this.$store.getters.enum(`permission.system_control.${system_control_key}`)
			let action_type = this.$store.getters.enum(`permission.action_type.${action_key}`)
			return this.userPermissions.find(permission => {
				let permission_type = this.$store.getters.enum(permission.permission_type_enum)
				return permission.type_index === system_control_type.index && permission_type.data.actions.includes(action_type.index);
			})
		},
		checkGroupManuPermission(action_key) {
			if(!this.groupId) return this.$store.getters.isDeveloper;
			if(!this.groupPermissions) return false;
			return this.userGroupPermission.actions.find(action => action.key === action_key)
		},
		getDefaultPermissionByKey(key) {
			let permission = this.$store.getters.enum(`permission.permission_type.${key}`)
			return {
				permission_type: permission. text,
				permission_type_enum: `permission.permission_type.${key}`,
				permission_type_index: permission.index,
				permission_type_key: permission.key,
				project_group_id: this.group_id,
				user_id: this.$store.getters.currentUser.id,
				user_name: this.$store.getters.currentUser.name,
			}
		},
		getProjectMenu() {
			return this.unit_list.map(unit => {
				return {
					name: unit.name, icon: this.getUnitIcon(unit.name),
					children: [
						{ name: '案件列表', icon: 'fas fa-list', path: { name: '案件列表', query: { party_a_id: unit.id } } },
						{ name: '工地列表', icon: 'far fa-list-alt', path: { name: '工地列表', query: { "party_a_id[]": unit.id } } },
					]
				}
			})
		},
		getUnitIcon(unit_name) {
			if(unit_name.includes('自來水')) return 'fas fa-faucet';
			if(unit_name.includes('電信')) return 'fas fa-phone-alt';
			return 'fas fa-credit-card';
		}
	}
}
</script>

<style scoped>
.content {
	padding: 1em;
}
</style>