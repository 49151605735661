/**
 * 點位/管線資訊: 材質/管徑/涵管行數/涵管列數
 */
 class Info{
	/**
	 * @param {string} material 
	 * @param {float} pipelineWidth 
	 * @param {float} pipelineHeight 
	 * @param {int} pipelineColumnAmount
	 * @param {int} pipelineRowAmount
	 */
	constructor(material, pipelineWidth, pipelineHeight=0, pipelineColumnAmount=1, pipelineRowAmount=1){
		if(arguments.length === 1) {
			let info = arguments[0]
			if(typeof info === "string") {
				[material, pipelineWidth, pipelineColumnAmount, pipelineRowAmount] = info.split("/").map(o => Number.isNaN(Number(o)) ? o : Number(o))
				pipelineColumnAmount = pipelineColumnAmount === undefined ? 1 : pipelineColumnAmount;
				pipelineRowAmount = pipelineRowAmount === undefined ? 1 : pipelineRowAmount;
			}
			else if(typeof info === "object") {
				[material, pipelineWidth, pipelineHeight, pipelineColumnAmount, pipelineRowAmount] = [
					info.material,
					info.pipelineWidth,
					info.pipelineHeight,
					info.pipelineColumnAmount === undefined ? 1 : info.pipelineColumnAmount,
					info.pipelineRowAmount === undefined ? 1 : info.pipelineRowAmount,
				]
			}
		}
		[material, pipelineWidth, pipelineHeight, pipelineColumnAmount, pipelineRowAmount] = [material, pipelineWidth, pipelineHeight, pipelineColumnAmount, pipelineRowAmount].map(o => Number.isNaN(Number(o)) ? o : Number(o))
		this.pipelineWidth = pipelineWidth;
		this.pipelineHeight = pipelineHeight;
		this.material = material;
		this.pipelineColumnAmount = pipelineColumnAmount;
		this.pipelineRowAmount = pipelineRowAmount;
	}

	/**
	 * 字串格式
	 */
	static format = {
		// 材質/管徑: material, pipelineWidth
		ROUND_PIPELINE: 'material/pipelineWidth',
		// 材質/寬度/高度: material, pipelineWidth, pipelineHeight
		NON_ROUND_PIPELINE: 'material/pipelineWidth/pipelineHeight',
		// 材質/管徑/行/列數: material, pipelineWidth, pipelineColumnAmount, pipelineRowAmount
		MULTIPLE_ROUND_PIPELINE: 'material/pipelineWidth/pipelineColumnAmount/pipelineRowAmount',
	};

	static getInitialValue() {
		return {
			material: 0,
			pipelineWidth: 0,
			pipelineHeight: 0,
			pipelineColumnAmount: 1,
			pipelineRowAmount: 1,
		}
	}

	static newByString(info, format=Info.format.ROUND_PIPELINE) {
		let infos = info.split("/")
		let infoObj = format.split("/").reduce((obj, key, index) => {
			obj[key] = infos[index] ?? obj[key]
			return obj
		}, Info.getInitialValue())
		return Info.newByObject(infoObj)
	}

	static newByObject(info) {
		info = { ...Info.getInitialValue(), ...info}
		let [material, pipelineWidth, pipelineHeight, pipelineColumnAmount, pipelineRowAmount] = [
			info.material,
			info.pipelineWidth,
			info.pipelineHeight ?? 0,
			info.pipelineColumnAmount ?? 1,
			info.pipelineRowAmount ?? 1,
		]
		return new Info(material, pipelineWidth, pipelineHeight, pipelineColumnAmount, pipelineRowAmount)
	}
	
	/**
	 * 比較倆資訊是否一致
	 * @param {Info} infoA
	 * @param {Info} infoB
	 */
	static equals(infoA,infoB){
		let params = [
			'material',
			'pipelineWidth',
			'pipelineHeight',
			'pipelineColumnAmount',
			'pipelineRowAmount',
		]
		return params.every(param => infoA[param] === infoB[param]);
	}

	toString(){
		return  `${this.getPipeCalibreString()} ${this.material} ${this.getPipeBundleString()}`;
	}

	get() {
		return {
			material: this.material,
			pipelineWidth: this.pipelineWidth,
			pipelineHeight: this.pipelineHeight,
			pipelineColumnAmount: this.pipelineColumnAmount,
			pipelineRowAmount: this.pipelineRowAmount,
		}
	}

	getPipeCalibreString() {
		return this.pipelineHeight === 0 ? `ø${this.pipelineWidth}` : `w${this.pipelineWidth} h${this.pipelineHeight}`
	}

	getPipeBundleString() {
		return `${this.pipelineColumnAmount}×${this.pipelineRowAmount}`;
	}
}

export default Info
