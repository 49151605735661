import ParamConfigs from './params'
import ExportConfigs from './exports'

class ProjectConfigs {
	static formParams(type) {
		return getConfig('params', type, 'form')
	}
	static dataParams(type) {
		return getConfig('params', type, 'data')
	}
	static listParams(type) {
		return getConfig('params', type, 'data', 'list')
	}
	static listExports(type) {
		return getConfig('exports', type, 'list')
	}
}
function getBasicConfigs(key) {
	const configs = {
		params: ParamConfigs,
		exports: ExportConfigs,
	}
	return key ? configs[key] : configs
}
function getConfig(configKey, type, tag, keyTag=undefined) {
	let basicConfigs = getBasicConfigs(configKey)
	if(!basicConfigs) return []
	keyTag = keyTag ?? tag
	let common = basicConfigs[configKey].common[tag] ?? [];
	let configs = (basicConfigs[configKey].projects[type] ?? {})[tag] ?? (basicConfigs[configKey].default[tag] ?? [])
	return [...new Set([
		...common,
		...configs,
	])].map(key => {
		let config = basicConfigs.configs[key]
		return config[keyTag] ?? (config[tag] ?? key)
	}).filter(o => o)
}
export default ProjectConfigs
