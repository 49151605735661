<template>
	<input-form class="p-0 col-12" :options="formOptions" :actions="{cancel:false}" v-model="result" @submit="onSubmit"></input-form>
</template>

<script>
import InputForm from '@/components/Input/InputForm';
import Loading from '@/components/Loading/Loading.vue';
import { isEmpty, checkAllTrue } from '@/utils/assist';

export default {
	name: 'BidCreator',
	components:{
		InputForm,
		Loading
	},
	props: {
		partyAUnits: {
			type: Array,
			default() {
				return [];
			}
		},
		measurerUnits: {
			type: Array,
			default() {
				return [];
			}
		},
	},
	data() {
		return {
			call: {},
			loading: true,
			result: {},
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		loading() {
			this.$emit('loading', this.loading)
		},
		result: {
			deep: true,
			handler(value) {
				if(!(this.result.measurer_unit_members_id ?? []).every(id => this.measurerUnitMembers.mapValues('id').includes(id))) {
					this.result.measurer_unit_members_id = (this.result.measurer_unit_members_id ?? []).filter(id => this.measurerUnitMembers.mapValues('id').includes(id))
				}
			}
		},
	},
	created() {
	},
	mounted() {
	},
	computed: {
		measurerUnit() {
			return this.measurerUnits.find(unit => unit.id === this.result.measurer_unit_id)
		},
		measurerUnitMembers() {
			return this.measurerUnit ? this.measurerUnit.members : []
		},
		formOptions() {
			return [
				{ key: "name", type: "text", label: "名稱", required: true, size: 'xs' },
				{ key: "abbreviation", type: "text", label: "簡稱", required: false, size: 'xs' },
				{ key: "unit_id", type: "select", label: "甲方", required: true, options: this.partyAUnits, keys: {value: 'id', text: 'name'}, size: 'xs' },
				{ key: "number", type: "text", label: "編號", size: 'xs' },
				{ key: "measurer_unit_id", type: "select", label: "測量公司", required: true, options: this.measurerUnits, keys: {value: 'id', text: 'name'}, size: 'xs' },
				{ key: "measurer_unit_members_id", type: "select", label: "測量人員", required: false, multiple: true, options: this.measurerUnitMembers, keys: {value: 'id', text: 'name'}, size: 'xs' },
			]
		}
	},
	methods:{
		onSubmit(evt) {
			evt.preventDefault();
			this.$set(this.call, 'createBid', false)
			this.$axios.createBid(this.result, (response) => {
				this.$emit('submit')
				alert('新增成功')
				this.call.createBid = true;
			}, (error) => {
				this.call.createBid = true;
			})
		},
	}
}
</script>

<style scoped>
.input {
	margin: .5rem .25rem
}
</style>
