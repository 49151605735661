/**
 * 點位附加資訊: 私地/過溝蓋板/四支距/三支距
 */
 class AdditionalInfo {
	constructor(data) {
		AdditionalInfo.keys().forEach(infoKey => {
			this[infoKey.key] = !!(data[infoKey.key] === undefined ? data[infoKey.formKey] === '是' : data[infoKey.key])
		})
	}

	static keys() {
		return [
			{ key: 'isOnPrivateLand', formKey: 'on_private_land', combine: values => values.every(v => v) },
			{ key: 'isAcrossDitchCover', formKey: 'across_ditch_cover', combine: values => values.every(v => v) },
			{ key: 'needFourPitchPositioning', formKey: 'four_pitch_positioning', combine: values => values.every(v => v) },
			{ key: 'needThreePitchPositioning', formKey: 'three_pitch_positioning', combine: values => values.every(v => v) },
			{ key: 'isExtantFacility', formKey: 'extant_facility', combine: values => values.every(v => v) },
		]
	}

	static newByFormData(data) {
		return new AdditionalInfo(Object.map(AdditionalInfo.keys().keyBy('key'), (infoKey) => data[infoKey.formKey] === '是'))
	}

	set(key, value) {
		if(AdditionalInfo.keys().mapValues('key').includes(key)) {
			this[key] = !!value
		}
		return this
	}
	
	get() {
		return Object.map(AdditionalInfo.keys().keyBy('key'), (infoKey, key) => this[key])
	}

	/**
	 * @param {AdditionalInfo[]|AdditionalInfo{}} infoList 
	 */
	static combine(infoList) {
		if(!Array.isArray(infoList)) {
			infoList = Object.values(infoList)
		}
		return new AdditionalInfo(Object.map(AdditionalInfo.keys().keyBy('key'), infoKey => infoKey.combine(infoList.mapValues(infoKey.key))))
	}
}

export default AdditionalInfo
