<template>
	<div>
		<b-row class="justify-content-end col-12">
			<span><input-checkbox options="顯示已停用帳號" boolean v-model="showAllUsers"></input-checkbox></span>
		</b-row>
		<bv-table :columns="table.columns" :data="userList" :configs="table.configs" :actions="tableActions" :detail-columns="table.detailColumns"></bv-table>
	</div>
</template>

<script>
import InputCheckbox from '@/components/Input/InputCheckbox';
import BvTable from '@/components/Table/BvTable'
import { deepCopy } from '@/utils/assist';
export default {
	name: 'UserList',
	components:{
		InputCheckbox,
		BvTable,
	},
	props: {
		filter: {
			type: Object,
			default() {
				return {};
			}
		},
		actions: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			table: {
				detailColumns: [
					{ key: 'id', label: 'ID', hide: !this.$store.getters.isDeveloper },
					{ key: 'name', label: '名稱' },
					{ key: 'email', label: '電子信箱' },
					{ key: 'unit_name', label: '單位' },
					{ key: 'role', label: '身份' },
				],
				columns: [
					{ key: 'id', label: 'ID', sortable: true, searchable: true, hide: !this.$store.getters.isDeveloper },
					{ key: 'name', label: '名稱', sortable: true, searchable: true },
					// { key: 'email', label: '電子信箱', sortable: true, searchable: true },
					{ key: 'unit_name', label: '單位', sortable: true, searchable: true },
					{ key: 'role', label: '身份', sortable: true, searchable: true }
				],
				actions: [],
				configs: {
					busy: true,
					rowDetail: true,
					actionsColumn: true,
					perPage: 10
				}
			},
			users: [],
			showAllUsers: false,
		}
	},
	watch: {
	},
	created() {
		this.getUserList()
	},
	mounted() {
	},
	computed: {
		userList() {
			return this.showAllUsers ? this.users : this.users.filter(user => user.status_key === 'normal')
		},
		tableActions() {
			return [
				...this.table.actions,
				...this.actions.map(action => {
					let mapFunction = (obj) => Object.map(obj, (value) => {
						if(typeof value === 'function') {
							return (index) => { return value(this.userList[index]) }
						}
						return typeof value === 'object' ? mapFunction(value) : value
					})
					return mapFunction(action)
				})
			]
		},
	},
	methods:{
		getUserList() {
			this.table.configs.busy = true;
			this.$axios.getUserList({ all: 1, }, (response) => {
				this.users = response.data.filter(user => Object.keys(this.filter).every(key => user[key] === this.filter[key]))
				this.users = this.users.sortBy('name')
				this.table.configs.busy = false;
			}, (error) => {
				this.table.configs.busy = false;
			})
		},
	}
}
</script>

<style scoped>
</style>