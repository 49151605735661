<template>
	<loading v-if="loading"/>
	<b-form v-else @submit="onSubmit" class="mt-2 p-1">
		<div v-if="!existsNewSites.length" style="text-align:center; color:#aaa">請點選工地位置</div>
		<template v-else>
			<alert :warnings="warnings"></alert>
			<div class="d-flex justify-content-between" v-for="(site, key) in existsNewSites" :key="key">
				<b>[{{site.index}}]</b>
				<input-form class="p-0 mt-2 flex-fill" :options="formOptions" v-model="formData[site.index]" :use-form="false" :actions="{submit: false, cancel:false}" v-if="site.exists"></input-form>
				<b-button class="ml-2 del-btn size-xs" @click="deleteNewSite(site.index)"><i class="fa fa-trash-alt"></i></b-button>
			</div>
		</template>
		<b-row class="mt-2 justify-content-center">
			<b-button type="submit" class="form-btn submit-btn" :disabled="!existsNewSites.length">確定</b-button>
			<b-button type="button" class="form-btn cancel-btn" @click="cancel">取消</b-button>
		</b-row>
	</b-form>
</template>


<script>
import VueGoogleMap from '@/components/GoogleMap/VueGoogleMap'
import InputForm from '@/components/Input/InputForm'
import PhotoAlbum from '@/components/Album/PhotoAlbum.vue'
import Alert from '@/components/Alert/Alert.vue'
import Loading from '@/components/Loading/Loading.vue'
import { oneOf, checkAllTrue, deepCopy } from '@/utils/assist';

export default {
	name: 'ConstructionSiteCreatorForm',
	components: {
		VueGoogleMap,
		InputForm,
		PhotoAlbum,
		Alert,
		Loading
	},
	props: {
		project: {
			type: Object,
			default: () => { return {} }
		},
		images: {
			type: Array,
			default: () => []
		},
		newSites: {
			type: Array,
			default: () => []
		},
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			call: {getUnitDynamicForm: false},
			validator: {
				warnings: [
					{
						key: 'reportAmountError',
						validator: () => this.dynamicFormTypes.every(type => this.dynamicForms.filter(o=>o.type_index===type.index).length),
						title: `無可用之動態表單，請至<a href="/manager/unit/list">單位列表</a>設定`,
						messager: () => {
							return this.dynamicFormTypes.filter(type => !this.dynamicForms.filter(o=>o.type_index===type.index).length).map(type => type.text)
						}
					},
				]
			},
			loading: true,
			dynamicForms: [],
			formData: {},
			uploading: false,
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
		newSites: {
			deep: true,
			immediate: true,
			handler(value) {
				this.formData = this.existsNewSites.reduce((obj, site) => {
					obj[site.index] = { ...this.defaultNewSite, ...site }
					return obj
				}, {})
			}
		},
		uploading: {
			handler(value) {
				this.$emit('loading', this.uploading)
			}
		},
	},
	created() {
		this.getUnitDynamicForm();
	},
	mounted() {
	},
	computed: {
		dynamicFormTypes() {
			return this.$store.getters.enum('dynamic_form.type').filter(type => type.data.has_unit)
		},
		warnings() {
			return this.validator.warnings.filter(error => !error.validator(this.project, this.exportedConstructionSites, this.configs)).map(error => {
				return {
					...error,
					messages: error.messager ? (typeof error.messager === 'function' ? error.messager(this.project, this.exportedConstructionSites, this.configs) : error.messager) : ''
				}
			})
		},
		formOptions() {
			return [
				{ key: "name", type: "text", label: "工地名稱", required: true, size: 'xs', pattern: "^[a-zA-Z0-9\u4E00-\u9FFF\uff08-\uff09_]{2,}$", invalidMessage: "請輸入2個字元以上非特殊符號" },
				{ key: "paved", type: 'radio', label: "是否通知銑鋪", size: 'xs', boolean: true, options: this.$store.getters.enum('construction_site.paved'), keys: {value: 'index', text: 'text'} },
				{ key: "paved_at", type: "date", label: "預計銑舖日期", size: 'xs', hide: (v, k, data) => data.paved !== this.$store.getters.enum('construction_site.paved.paved').index },
				...this.dynamicFormTypes.map((type, key) => {
					let forms = this.dynamicForms.filter(o=>o.type_index===type.index)
					return {
						key: `${type.key}_dynamic_form_id`, type: "select", label: `${type.text}表單`, required: !!forms.length, size: 'xs', options: forms, keys: {value: 'id', text: 'name'}
					}
				}),
				{ key: "scene_pictures_url", type: "url", label: "資料連結", required: true, size: 'xs', },
				{ key: "note", type: "text", label: "備註", size: 'xs', multiline: true },
			]
		},
		sites() {
			return this.project.construction_sites
		},
		existsNewSites() {
			return this.newSites.filter(site => site.exists)
		},
		defaultNewSite() {
			return {
				// construction_site_dynamic_form_id: 9,
				// point_dynamic_form_id: 8,
				paved: this.$store.getters.enum('construction_site.paved.paved').index,
				scene_pictures_url: this.project.scene_pictures_url
			}
		},
	},
	methods:{
		getUnitDynamicForm() {
			this.call.getUnitDynamicForm = false
			this.$axios.getUnitDynamicForm(this.project.party_a_id, {}, (response) => {
				this.dynamicForms = Object.values(response.data).filter(o => o)
				this.call.getUnitDynamicForm = true
			}, (error) => {
				this.call.getUnitDynamicForm = true
			});
		},
		cancel() {
			this.$emit('cancel')
		},
		onSubmit(evt) {
			evt.preventDefault();
			let newSites = Object.values(this.formData)
			if(newSites.length <= 0) return;
			let siteNames = [...this.sites, ...newSites].map(o=>o.name)
			if(siteNames.uniq().length < siteNames.length) {
				alert("含有相同工地名稱！")
				return
			}
			this.uploading = true
			this.$axios.createConstructionSite({data: newSites.map(site => {
				return {
					project_type: this.projectType,
					project_id: this.projectID,
					...site,
					...site.position,
				}
			})}, (response) => {
				alert('上傳成功');
				this.$emit('create')
				this.uploading = false
			}, (error) => {
				this.uploading = false
			})
		},
		deleteNewSite(index) {
			this.$emit('delete', index)
		},
	}
}
</script>

<style scoped>
.del-btn {
	background: transparent !important;
	border: none;
	height: min-content;
	color: #d9766c;
}
.del-btn:hover, .del-btn:focus, .del-btn:active {
	background: transparent !important;
	color: #b24743 !important;
	box-shadow: none !important;
}
</style>