<template>
	<b-row class="w-100 outside-wrapper">
		<label class="input-label col-md-2 col-12" v-show="label" :class="labelClass">{{label}}</label>
		<span class="form-input-wrapper" :size="size === 'xs' ? 'xs' : ''">
			<b-form-checkbox-group class="input-checkbox-wrapper" :class="`input-checkbox-${direction}-wrapper`" v-model="model" :size="size === 'xs' ? 'xs' : ''" :switches="mode === 'switch'" :buttons="mode==='button'" :stacked="direction === 'column'" :inline="direction !== 'column'" :disabled="disabled" @change="change" @input="input" v-if="Array.isArray(options)">
				<b-form-checkbox :value="option.value" v-for="(option, key) in opts" :key="key">
					{{ option.text }}
				</b-form-checkbox>
			</b-form-checkbox-group>
			<b-form-checkbox class="input-checkbox-only" :class="`input-${mode}`" v-model="model" :size="size === 'xs' ? 'xs' : ''" :switch="mode === 'switch'" :button="mode==='button'" :disabled="disabled" @change="change" @input="input" v-else-if="boolean">
				{{ opts.text }}
			</b-form-checkbox>
			<b-form-checkbox class="input-checkbox-only" :class="`input-${mode}`" v-model="model" :value="opts.value" :size="size === 'xs' ? 'xs' : ''" :switch="mode === 'switch'" :button="mode==='button'" :disabled="disabled" @change="change" @input="input" v-else>
				{{ opts.text }}
			</b-form-checkbox>
		</span>
		<b-row class="w-100">
			<div class="col-md-2 col-12" :class="labelClass"></div>
			<div class="flex-fill text-hint font-size-sm text-left" :class="hintClass" v-if="hint">* {{hint}}</div>
		</b-row>
	</b-row>
</template>

<script>
import { oneOf } from "@/utils/assist"
export default {
	name: 'InputCheckbox',
	components: {
	},
	props: {
		options: {
			type: [Object, Array, String],
			default() {
				return [];
			}
		},
		keys: {
			type: [Object, String],
			default: ''
		},
		value: {
			type: [Boolean, String, Array],
		},
		size: {
			type: String,
			default: ""
		},
		label: {
			type: String,
		},
		labelClass: {
			type: String,
			default: ""
		},
		direction: {
			type: String,
			validator (value) {
				return oneOf(value, ['row', 'column']);
			},
			default: "row"
		},
		boolean: {
			type: Boolean,
			default: false
		},
		mode: {
			type: String,
			validator (value) {
				return oneOf(value, ['checkbox', 'switch', 'button']);
			},
			default: 'checkbox'
		},
		disabled: {
			type: Boolean,
			default: false
		},
		hint: {
			type: String,
			default: ""
		},
		hintClass: {
			type: String,
			default: ""
		},
	},
	data() {
		return {
			model: this.value
		}
	},
	watch: {
		// value: {
		// 	handler(value) {
		// 		this.setValue(value)
		// 	}
		// },
		options: {
			deep: true,
			handler(val) {
				this.getOptions(this.options, this.keys)
			}
		},
		keys: {
			deep: true,
			handler(val) {
				this.getOptions(this.options, this.keys)
			}
		},
	},
	created() {
		this.getOptions(this.options, this.keys)
	},
	methods: {
		// setValue(value) {
		// 	if(this.multiple && !Array.isArray(value)) {
		// 		value = []
		// 	}
		// 	if(!this.multiple && `${this.model}` !== `${value}` || this.multiple && !Array.isArray(this.model) || this.multiple && Array.isArray(this.model) && [...this.model].sort().toString() !== [...value].sort().toString()) {
		// 		this.model = value
		// 	}
		// },
		getOptions(options, key) {
			this.opts = Array.isArray(options) ? options.map(option => {
				let opt = typeof option === 'object' && !this.isEmpty(key.text) && !this.isEmpty(key.value) ? { text: this.getRecursiveValue(option, key.text), value: this.getRecursiveValue(option, key.value) } : typeof option === 'object' && typeof key === 'string' && !this.isEmpty(option[key]) ? { text: option[key], value: option[key] } : typeof option === 'object' && !this.isEmpty(option.text) && !this.isEmpty(option.value) ? option : { text: option, value: option }
				return {...option, ...opt}
			}) : { text: options, value: options };
			this.$forceUpdate()
		},
		input(e) {
			this.$emit('input', e)
		},
		change(e) {
			this.$emit('change', e)
		},
		getRecursiveValue(obj, key_str, key_delim='.') {
			let keys = key_str.split(key_delim);
			let res = obj;
			while(keys.length) {
				let key = keys.shift();
				if(typeof res === 'undefined' || res === null)
					break;
				res = res[key];
			}
			return res
		},
		isEmpty(obj) {
			// null and undefined are "empty"
			if (obj == null || typeof obj === "undefined") return true;

			if(!Number.isNaN(Number(obj))) return false

			// Assume if it has a length property with a non-zero value
			// that that property is correct.
			if (obj.length > 0)    return false;
			if (obj.length === 0)  return true;

			// If it isn't an object at this point
			// it is empty, but it can't be anything *but* empty
			// Is it empty?  Depends on your application.
			if (typeof obj !== "object") return true;

			// Otherwise, does it have any properties of its own?
			// Note that this doesn't handle
			// toString and valueOf enumeration bugs in IE < 9
			var hasOwnProperty = Object.prototype.hasOwnProperty;
			for (var key in obj) {
				if (hasOwnProperty.call(obj, key)) return false;
			}

			return true;
		}
	}
}
</script>

<style scoped>
.outside-wrapper {
	align-items: center;
}
.form-input-wrapper {
	display: flex;
	align-items: center;
	flex: 1 1;
}
.input-checkbox-wrapper.input-checkbox-row-wrapper {
	display: flex;
	flex-wrap: wrap;
	flex: 1 1;
}
.input-label {
	padding-right: .5rem;
	display: flex;
	align-items: center;
}
</style>
