<template>
	<div>
		<loading mode="fixed" image="1" size="lg" v-if="loading"/>
		<b-row>
			<div class="col-12 py-3 px-md-3 px-1" :class="{'col-md-6': creatable || showing}">
				<h5>人員列表</h5>
				<bv-table :columns="table.columns" :data="unitMembers" :configs="table.configs" :actions="table.actions"></bv-table>
			</div>
			<b-card no-body class="col-md-6 col-12 border-0 control-board" v-if="creatable || showing">
				<b-card-body>
					<b-tabs class="tabs-wrapper h-100" fill active-nav-item-class="tab-title font-weight-bold" active-tab-class="tab-title" fluid>
						<b-tab class="tab-body h-100" title="新增人員" :active="creatable" v-if="creatable">
							<unit-member-creator :unit="unit" :unit-branches="unitBranches" @submit="reload" @loading="load"></unit-member-creator>
						</b-tab>
						<b-tab class="tab-body h-100" title="人員資訊" :disabled="!showing" :active="showing">
							<unit-member-viewer :unit-member="showingUnitMember" :unit-branches="unitBranches" :unit="unit" @reload="reload" @loading="load" v-if="showing"></unit-member-viewer>
						</b-tab>
					</b-tabs>
				</b-card-body>
			</b-card>
		</b-row>
	</div>
</template>

<script>
import Loading from '@/components/Loading/Loading.vue';
import UnitMemberCreator from '@/components/Unit/Member/UnitMemberCreator';
import UnitMemberViewer from '@/components/Unit/Member/UnitMemberViewer';
import BvTable from '@/components/Table/BvTable'
import { deepCopy, checkAllTrue } from '@/utils/assist';

export default {
	name: 'UnitMemberManager',
	components:{
		UnitMemberCreator,
		UnitMemberViewer,
		BvTable,
		Loading
	},
	props: {
		unit: {
			type: Object,
		},
		unitMembers: {
			type: Array,
			default: () => []
		},
		unitBranches: {
			type: Array,
			default: () => []
		},
	},
	data() {
		return {
			call: {},
			loading: true,
			showIndex: -1,
			table: {
				columns: [
					{ key: 'id', label: 'ID', hide: !this.$store.getters.isDeveloper, sortable: true, searchable: true},
					{ key: 'name', label: '名稱', sortable: true, searchable: true},
					{ key: 'type', label: '種類', sortable: true, searchable: true},
					{ key: 'unit_branch_name', label: '所屬區處', sortable: true, searchable: true},
				],
				actions: [
					{
						//icon, label
						button: { icon: "fas fa-info-circle", label: "資訊" },
						//function, link, route
						action: { type: 'function', function: this.showUnit },
					},
				],
				configs: {
					actionsColumn: true,
					perPage: 10
				}
			},
			showing: false,
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		showIndex: {
			handler(value) {
				this.showing = false
				this.$nextTick(() => {
					this.showing = this.showIndex >= 0
				})
			}
		},
	},
	created() {
	},
	mounted() {
	},
	computed: {
		creatable() {
			return this.checkSystemPermission('write') ? true : false
		},
		showingUnitMember() {
			return this.unitMembers[this.showIndex]
		},
	},
	methods:{
		reload() {
			this.$emit('reload')
		},
		load(loading) {
			this.loading = loading
		},
		showUnit(index) {
			this.showIndex = index
		},
		checkSystemPermission(action_key) {
			let userPermissions = this.$store.getters.currentUser.permissions
			const system_control_type = this.$store.getters.enum(`permission.system_control.system`)
			let action_type = this.$store.getters.enum(`permission.action_type.${action_key}`)
			return userPermissions.find(permission => {
				let permission_type = this.$store.getters.enum(permission.permission_type_enum)
				return permission.type_index === system_control_type.index && permission_type.data.actions.includes(action_type.index);
			})
		}
	}
}
</script>

<style scoped>
.input {
	margin: .5rem .25rem
}
.tab-body {
	padding: 1rem 1.5rem;
	border: 1px solid #dee2e6;
	border-top: 0;
	height: max-content;
}
.tabs-wrapper {
	height: 100%;
}
.tabs-wrapper > .tab-content {
	height: 100%;
}
</style>