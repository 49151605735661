<template>
	<div class="col-12">
		<loading class="info-wrapper" v-if="loading"/>
		<construction-site-list :title="title" :filter="filter" v-else></construction-site-list>
	</div>
</template>

<script>
import ConstructionSiteList from '@/components/Project/List/ConstructionSiteList.vue'
import Loading from '@/components/Loading/Loading.vue'
import { checkAllTrue } from '@/utils/assist';
export default {
	name: 'GroupConstructionSiteList',
	components:{
		ConstructionSiteList,
		Loading,
	},
	props: {
		groupID: {
			required: true,
			default: 0
		},
		group: {
			type: Object
		}
	},
	data() {
		return {
			group_id: Number.isInteger(parseInt(this.groupID)) ? parseInt(this.groupID) : 0,
			loading: true
		}
	},
	created() {
		if(typeof this.group === 'undefined') {
			this.reload()
		}
		else {
			this.loading = false
		}
	},
	destroyed() {
	},
	mounted() {
	},
	watch: {
	},
	computed: {
		title() {
			return (this.group ? this.group.name : '') + '工地列表';
		},
		filter() {
			return this.group_id && this.group ? {
				project_group_id: this.group_id
			} : {
				project_group: 'null'
			}
		}
	},
	methods:{
		reload() {
			this.$router.replace({name: '專案', params: {groupID: this.group_id, from: {name: '專案工地列表'}}});
		},
	}
}
</script>

<style scoped>
</style>