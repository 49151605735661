import _ from "lodash";
(function (window) {
	Object.defineProperty(Array.prototype, 'first', {
		enumerable: false,
		configurable: false,
		get() {
			return this.length ? this[0] : undefined;
		}
	});
	Object.defineProperty(Array.prototype, 'last', {
		enumerable: false,
		configurable: false,
		get() {
			return this.length ? this[this.length - 1] : undefined;
		}
	});
	Object.defineProperty(Array.prototype, 'latest', {
		enumerable: false,
		configurable: false,
		get() {
			return this.length ? this[this.length - 1] : undefined;
		}
	});
	Array.getByIndex = function (array, n) {
		// // ToInteger() abstract op
		// n = Math.trunc(n) || 0;
		// // Allow negative indexing from the end
		// if (n < 0) n += array.length;
		// // OOB access is guaranteed to return undefined
		// if (n < 0 || n >= array.length) return undefined;
		// // Otherwise, array is just normal property access
		// return array[n];
		return array.get(n)
	}
	Object.defineProperty(Array.prototype, 'clone', {
		// 是否可被 for(i in []) 取得
		enumerable: false,
		configurable: false,
		value: function() {
			return _.cloneDeep(this)
		},
	})
	Object.defineProperty(Array.prototype, 'get', {
		// 是否可被 for(i in []) 取得
		enumerable: false,
		configurable: false,
		value: function(index) {
			// ToInteger() abstract op
			index = Math.trunc(index) || 0;
			// Allow negative indexing from the end
			if (index < 0) index += this.length;
			// OOB access is guaranteed to return undefined
			if (index < 0 || index >= this.length) return undefined;
			// Otherwise, array is just normal property access
			return this[index];
		},
	})
	Object.defineProperty(Array.prototype, 'chunk', {
		// 是否可被 for(i in []) 取得
		enumerable: false,
		configurable: false,
		value: function(size) {
			return _.chunk(this, size);
			// return Array(Math.ceil(this.length / size)).fill().map((_, index) => index * size).map(begin => this.slice(begin, begin + size));
		},
	})
	Object.defineProperty(Array.prototype, 'keyBy', {
		// 是否可被 for(i in []) 取得
		enumerable: false,
		configurable: false,
		value: function(callback) {
			if(typeof callback === 'function') {
				return this.reduce((obj, value, index) => {
					let key = callback(value, index)
					obj[key] = value
					return obj
				}, {})
			}
			return _.keyBy(this, callback);
		},
	})
	Object.defineProperty(Array.prototype, 'uniq', {
		// 是否可被 for(i in []) 取得
		enumerable: false,
		configurable: false,
		value: function(deep=false) {
			return deep ? _.uniqWith(this, _.isEqual) : _.uniq(this);
		},
	})
	Object.defineProperty(Array.prototype, 'uniqBy', {
		// 是否可被 for(i in []) 取得
		enumerable: false,
		configurable: false,
		value: function(callback) {
			return _.uniqBy(this, callback);
		},
	})
	Object.defineProperty(Array.prototype, 'duplicates', {
		// 是否可被 for(i in []) 取得
		enumerable: false,
		configurable: false,
		value: function() {
			let keys = Object.keys(Object.filter(this.countBy(), i => i > 1))
			return this.filter(v => keys.includes(`${v}`)).uniq()
		},
	})
	Object.defineProperty(Array.prototype, 'clear', {
		// 是否可被 for(i in []) 取得
		enumerable: false,
		configurable: false,
		value: function() {
			this.splice(0, this.length)
			return this;
		},
	})
	Object.defineProperty(Array.prototype, 'countBy', {
		// 是否可被 for(i in []) 取得
		enumerable: false,
		configurable: false,
		value: function(callback) {
			return _.countBy(this, callback)
		},
	})
	Object.defineProperty(Array.prototype, 'groupBy', {
		// 是否可被 for(i in []) 取得
		enumerable: false,
		configurable: false,
		value: function(callback) {
			return _.groupBy(this, callback)
		},
	})
	Object.defineProperty(Array.prototype, 'sortBy', {
		// 是否可被 for(i in []) 取得
		enumerable: false,
		configurable: false,
		value: function(callback, orders) {
			return _.orderBy(this, callback, orders)
		},
	})
	Object.defineProperty(Array.prototype, 'sumBy', {
		// 是否可被 for(i in []) 取得
		enumerable: false,
		configurable: false,
		value: function(callback, orders) {
			return _.sumBy(this, callback, orders)
		},
	})
	Object.defineProperty(Array.prototype, 'intersect', {
		// 是否可被 for(i in []) 取得
		enumerable: false,
		configurable: false,
		value: function(...array) {
			return _.intersection(this, ...array)
		},
	})
	Object.defineProperty(Array.prototype, 'mapValues', {
		// 是否可被 for(i in []) 取得
		enumerable: false,
		configurable: false,
		value: function(callback) {
			return Object.values(_.mapValues(this, callback))
		},
	})
	Object.defineProperty(Array.prototype, 'fill', {
		// 是否可被 for(i in []) 取得
		enumerable: false,
		configurable: false,
		value: function(fill, start=0, end=this.length) {
			return _.fill(this, fill, start, end);
		},
	})
	Object.defineProperty(Array.prototype, 'diff', {
		// 是否可被 for(i in []) 取得
		enumerable: false,
		configurable: false,
		value: function(...array) {
			return _.difference(this, ...array);
		},
	})
	Object.defineProperty(Array.prototype, 'find', {
		// 是否可被 for(i in []) 取得
		enumerable: false,
		configurable: false,
		value: function(callback) {
			return _.find(this, callback);
		},
	})
	Object.defineProperty(Array.prototype, 'findLast', {
		// 是否可被 for(i in []) 取得
		enumerable: false,
		configurable: false,
		value: function(callback) {
			return _.findLast(this, callback);
		},
	})
	Object.defineProperty(Array.prototype, 'shuffle', {
		// 是否可被 for(i in []) 取得
		enumerable: false,
		configurable: false,
		value: function() {
			return _.shuffle(this);
		},
	})
	Object.defineProperty(Array.prototype, 'randomTake', {
		// 是否可被 for(i in []) 取得
		enumerable: false,
		configurable: false,
		value: function(size=1) {
			return _.take(_.shuffle(this), size);
		},
	})
	Object.defineProperty(Array.prototype, 'reverse', {
		// 是否可被 for(i in []) 取得
		enumerable: false,
		configurable: false,
		value: function() {
			return _.reverse(this);
		},
	})
	Array.range = function(start=0, end, step=1) {
		step = (end > start ? 1 : -1) * Math.abs(step)
		return _.range(start, end, step)
	}
	Object.defineProperty(Array.prototype, 'range', {
		// 是否可被 for(i in []) 取得
		enumerable: false,
		configurable: false,
		value: function(delim='-', prefix='', suffix='') {
			let array = this.map(o => Number(o)).filter(o => Number.isInteger(o)).sort((a, b) => a - b)
			let list = []
			let conti = false;
			let start = undefined;
			for (let index = 0; index < array.length; index++) {
				let num = array[index];
				if(!conti) {
					start = num;
					list.push([num])
					conti = true;
				}
				else if(num === list.last.last + 1) {
					list.last.push(num)
				}
				else {
					start = num;
					list.push([num])
					conti = false
				}
			}
			return list.map(range => {
				let first = `${prefix}${range.first}${suffix}`
				let last = `${prefix}${range.last}${suffix}`
				return first + (range.length > 1 ? `${delim}${last}` : '')
			})
		},
	})
}(window));

