<template>
	<div>
		<div class="row d-flex align-items-center my-1">
			<template v-for="(action, actionKey) in buttons">
				<button-item :class="buttonClass" :key="actionKey" :visible="action.visible" :configs="action.button" :type="action.action.type" :action="action.action[action.action.type]" :action-configs="action.action"></button-item>
			</template>
		</div>
		<slot></slot>
	</div>
</template>

<script>
import ButtonItem from '@/components/Button/ButtonItem'

export default {
	name: 'ButtonGroup',
	components: {
		ButtonItem,
	},
	props: {
		buttons: {
			type: Array,
			default: () => []
		},
		buttonClass: {
			type: [String, Object],
			default: '',
		},
	},
	data() {
		return {
		}
	},
	watch: {},
	created() {},
	mounted() {},
	computed: {},
	methods: {
		getValue(item, defaultValue, ...args) {
			item = item ?? defaultValue
			return typeof item ==='function' ? item(...args) : item
		},
	},
}
</script>

<style scoped>

</style>