<template>
	<b-modal v-model="showModal" body-class="body" :title="title" :size="size" :hide-header="hideHeader" :hide-footer="hideFooter" :button-size="buttonSize" :centered="centered" @ok="ok" @cancel="cancel" @close="close">
		<template v-slot:modal-header><slot name="header"></slot></template>
		<slot></slot>
		<template v-slot:modal-footer><slot name="footer"></slot></template>
	</b-modal>
</template>

<script>
export default {
	name: 'Modal',
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		hideHeader: {
			type: Boolean,
			default: false,
		},
		hideFooter: {
			type: Boolean,
			default: false,
		},
		buttonSize: {
			type: String,
			default: "sm"
		},
		centered: {
			type: Boolean,
			default: true,
		},
		title: {
			type: String,
		},
		size: {
			type: String,
		},
	},
	data() {
		return {
			showModal: this.value,
		}
	},
	created() {
	},
	watch: {
		value: {
			handler(value) {
				this.showModal = value;
			}
		},
		showModal(value) {
			this.$emit('input', value);
		}
	},
	methods: {
		ok(e) {
			this.$emit('ok', e);
		},
		cancel(e) {
			this.showModal = false;
			this.$emit('cancel', e);
		},
		close(e) {
			this.showModal = false;
			this.$emit('close', e);
		},
	}
}
</script>

<style>
.body {
	max-height: 70vh;
	overflow: auto;
}
</style>
