<template>
	<div>
		<div class="lb-overlay d-flex flex-column align-content-center" style="justify-content:space-around;">
			<b-button class="close-btn" @click="close"><i class="fas fa-times"></i></b-button>
			<b-row class="lb-wrapper justify-content-center p-4">
				<photo :photo="photos[current]" photo-style="max-height: 70vh;" text-class="position-absolute" text-style="background:#fff5;border-radius:.25rem;bottom:.75rem;font-size:1rem;font-weight:900;" showname @load="onload"></photo>
			</b-row>
			<b-row class="photo-nav" ref="PhotoNav" @wheel="wheelNav">
				<span class="photo-wrapper d-flex align-items-center col-3 col-md-2 col-lg-2 col-xl-1"></span>
				<span class="photo-wrapper d-flex justify-content-center align-items-center col-3 col-md-2 col-lg-2 col-xl-1" v-for="(photo, key) in photos" :key="key" ref="Photo">
					<span class="photo" :class="`${current===key ? 'active' : ''} ${loading[key] ? 'loading size-xs' : ''}`" :style="loading[key] ? `background-image:url(${loading_img_url})` : ''">
						<img class="pointer" :style="loading[key] ? 'opacity:0' : 'opacity:1'" @click="click(key)" @load="load(key)" :src="typeof photo === 'object' ? photo.src : photo">
					</span>
				</span>
				<span class="photo-wrapper d-flex align-items-center col-3 col-md-2 col-lg-2 col-xl-1"></span>
			</b-row>
		</div>
	</div>
</template>

<script>
import Photo from "./Photo"
import { isEmpty, getRecursiveValue } from "@/utils/assist"
import loading_img from '@/assets/images/loading.gif';
export default {
	name: 'LightBox',
	components:{
		Photo,
	},
	props: {
		data: {
			type: Array,
			default() { return [] }
		},
		index: {
			type: Number,
			default: 0
		},
		keys: {
			type: [Object, String],
			default: ''
		}
	},
	data() {
		return {
			loading: [],
			loading_img_url: loading_img,
			photos: [],
			current: 0,
			show_img: 0
		}
	},
	created() {
		this.current = this.index >= 0 ? this.index : 0;
		this.loading = this.data.map(o => true)
		this.getPhotos(this.data, this.keys)
	},
	mounted() {
		if(this.index >= 0)
			this.scrollToCurrentImage();
	},
	computed: {
	},
	methods:{
		isEmpty(obj) { return isEmpty(obj) },
		getPhotos(photos, key) {
			this.photos = Array.isArray(photos) ? photos.map(photo => {
				let opt = typeof photo === 'object' && !this.isEmpty(key.name) && !this.isEmpty(key.src) ? { name: getRecursiveValue(photo, key.name), src: getRecursiveValue(photo, key.src) } : typeof photo === 'object' && typeof key === 'string' && !this.isEmpty(photo[key]) ? { name: photo[key], src: photo[key] } : typeof photo === 'object' && !this.isEmpty(photo.name) && !this.isEmpty(photo.src) ? photo : { name: photo.split('/').pop().substr(0, photo.split('/').pop().lastIndexOf('.')), src: photo }
				return {...photo, ...opt}
			}) : typeof photos === "string" ? { name: photos.split('/').pop().substr(0, photos.split('/').pop().lastIndexOf('.')), src: photos } : { name: photos.name, src: photos };
			this.$forceUpdate()
		},
		close() {
			this.$emit("close")
		},
		click(index) {
			this.current = index
			this.scrollToCurrentImage()
			this.$forceUpdate()
		},
		load(index) {
			this.loading[index] = false
			this.$forceUpdate()
		},
		onload() {
		},
		wheelNav(e) {
			e.preventDefault();
			this.$refs.PhotoNav.scroll(this.$refs.PhotoNav.scrollLeft + e.deltaY, 0);
		},
		scrollToCurrentImage() {
			this.$refs.PhotoNav.scroll(this.$refs.Photo[this.current].offsetLeft - document.body.offsetWidth / 2, 0);
		}
	}
}
</script>

<style scoped>
.lb-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: #000d;
	z-index: 9999;
	top: 0;
	left: 0;
}
.lb-wrapper {
	height: 75vh;
}
.photo {
	/* height: 100%; */
	width: max-content;
	padding: .5rem;
	border: .5px solid #cbd8e1;
	border-radius: 1rem;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	height: fit-content;
}
.photo img {
	max-width: 100%;
	max-height: 70vh;
	border-radius: .75rem;
}
.photo-nav {
	height: 12vh;
	padding: 0 10%;
	flex-wrap: nowrap;
	overflow: auto;
	scrollbar-width: none;
}
::-webkit-scrollbar {
	display: none;
}
.photo-nav .photo-wrapper {
	height: 100%;
	margin: 0 .5rem;
}
.photo-nav .photo {
	height: 100%;
	border: none;
}
.photo-nav .photo.active {
	height: 100%;
	border: .5px solid #cbd8e1;
	border-radius: 1rem;
}
.photo-nav .photo img {
	max-height: 100%;
	height: auto;
	max-width: 100%;
	width: auto;
	border-radius: .75rem;
}


.close-btn {
	position: absolute;
	right: 2rem;
	top: 0;
	z-index: 999;
	height: 1rem;
	width: 1rem;
	align-items: center;
	justify-content: center;
	align-items: center;
	border: none;
	background-color: transparent;
	font-size: 2rem;
	color: #fff8;
}
.close-btn:hover {
	color: #fffd;
	background-color: transparent;
}


.loading {
	width: 100%;
	height: 100%;
	/* background-color: transparent; */
	background-repeat: no-repeat;
	background-size: 50px;
	background-position: center;
}
.loading.size-xs {
	background-size: 30px;
}
</style>