<template>
	<div>
		<div class="loading" v-if="loading" :style="`background-image:url(${loading_img_url})`"></div>
		<div class="gmap-wrapper" v-show="!loading">
			<div class="gmap-control-wrapper address-box-wrapper" v-if="configs.searchingAddress && map.geocoder">
				<b-row class="col-12 align-items-center" style="height:100%; padding:.5rem">
					<b-form-input ref="inputAddress" class="address-box" v-model="map.address" @change="geocodeAddress"></b-form-input>
					<b-button class="address-box-btn" @click="geocodeAddress(map.address)"><i class="fa fa-search"></i></b-button>
					<div class="address-results-wrapper" v-if="map.geocodingOptions.selectable">
						<template v-if="map.addressResults.length">
							<div class="option-wrapper" v-for="(result, key) in map.addressResults" :key="key">
								<div class="option break-wrap font-size-sm none-select pointer" :class="{selected: result === map.addressResult}" @mousedown="selectGeocoderResult(result)">{{result.formatted_address}}</div>
							</div>
						</template>
						<div class="option-wrapper" v-else>
							<div class="option break-wrap font-size-xs none-select text-hint">查無結果</div>
						</div>
					</div>
				</b-row>
			</div>
			<gmap-map ref="map" class="gmap" :center="map.center" :zoom="map.zoom" :options="map.options" map-type-id="roadmap" map-style-id="red" @click="clickMap">
				<template v-for="(overlay, key) in map.overlays">
					<gmap-polygon :key="`polygon-${key}`" :path="overlay.path" :paths="overlay.paths" :options="overlay.options" :editable="overlay.editable" :draggable="overlay.draggable" @click="onOverlayEvent('click', $event, overlay)" @dblclick="onOverlayEvent('dblclick', $event, overlay)" @drag="onOverlayEvent('drag', $event, overlay)" @dragend="onOverlayEvent('dragend', $event, overlay)" @dragstart="onOverlayEvent('dragstart', $event, overlay)" @mousedown="onOverlayEvent('mousedown', $event, overlay)" @mousemove="onOverlayEvent('mousemove', $event, overlay)" @mouseout="onOverlayEvent('mouseout', $event, overlay)" @mouseover="onOverlayEvent('mouseover', $event, overlay)" @mouseup="onOverlayEvent('mouseup', $event, overlay)" @rightclick="onOverlayEvent('rightclick', $event, overlay)"  v-if="overlay.type==='polygon'"></gmap-polygon>
					<gmap-polyline :key="`polyline-${key}`" :path="overlay.path" :options="overlay.options" :editable="overlay.editable" :draggable="overlay.draggable" @click="onOverlayEvent('click', $event, overlay)" @dblclick="onOverlayEvent('dblclick', $event, overlay)" @drag="onOverlayEvent('drag', $event, overlay)" @dragend="onOverlayEvent('dragend', $event, overlay)" @dragstart="onOverlayEvent('dragstart', $event, overlay)" @mousedown="onOverlayEvent('mousedown', $event, overlay)" @mousemove="onOverlayEvent('mousemove', $event, overlay)" @mouseout="onOverlayEvent('mouseout', $event, overlay)" @mouseover="onOverlayEvent('mouseover', $event, overlay)" @mouseup="onOverlayEvent('mouseup', $event, overlay)" @rightclick="onOverlayEvent('rightclick', $event, overlay)"  v-else-if="overlay.type==='polyline'"></gmap-polyline>
					<gmap-circle :key="`circle-${key}`" :center="overlay.center" :radius="overlay.radius" :options="overlay.options" :editable="overlay.editable" :draggable="overlay.draggable" @click="onOverlayEvent('click', $event, overlay)" @dblclick="onOverlayEvent('dblclick', $event, overlay)" @drag="onOverlayEvent('drag', $event, overlay)" @dragend="onOverlayEvent('dragend', $event, overlay)" @dragstart="onOverlayEvent('dragstart', $event, overlay)" @mousedown="onOverlayEvent('mousedown', $event, overlay)" @mousemove="onOverlayEvent('mousemove', $event, overlay)" @mouseout="onOverlayEvent('mouseout', $event, overlay)" @mouseover="onOverlayEvent('mouseover', $event, overlay)" @mouseup="onOverlayEvent('mouseup', $event, overlay)" @rightclick="onOverlayEvent('rightclick', $event, overlay)"  v-else-if="overlay.type==='circle'"></gmap-circle>
					<gmap-rectangle :key="`rectangle-${key}`" :bounds="overlay.bounds" :options="overlay.options" :editable="overlay.editable" :draggable="overlay.draggable" @click="onOverlayEvent('click', $event, overlay)" @dblclick="onOverlayEvent('dblclick', $event, overlay)" @drag="onOverlayEvent('drag', $event, overlay)" @dragend="onOverlayEvent('dragend', $event, overlay)" @dragstart="onOverlayEvent('dragstart', $event, overlay)" @mousedown="onOverlayEvent('mousedown', $event, overlay)" @mousemove="onOverlayEvent('mousemove', $event, overlay)" @mouseout="onOverlayEvent('mouseout', $event, overlay)" @mouseover="onOverlayEvent('mouseover', $event, overlay)" @mouseup="onOverlayEvent('mouseup', $event, overlay)" @rightclick="onOverlayEvent('rightclick', $event, overlay)"  v-else-if="overlay.type==='rectangle'"></gmap-rectangle>
				</template>
				<gmap-info-window :options="infoWindow.options" :position="infoWindow.pos" :opened="infoWindow.open" @closeclick="infoWindow.open=false">
					<template v-if="Array.isArray(infoWindow.content)">
						<li v-for="(item, item_key) in infoWindow.content" :key="item_key">{{item}}</li>
					</template>
					<template v-else-if="typeof infoWindow.content === 'object'">
						<li v-for="(item, item_key) in infoWindow.content" :key="item_key">
							<b>{{item_key}}</b>：{{item}}
						</li>
					</template>
					<div v-else v-html="infoWindow.content"></div>
					
				</gmap-info-window>
				<gmap-marker v-for="(marker, key) in map.markers" :key="`marker-${key}`" :position="marker.position" :clickable="true" :icon="marker.icon" :visible="marker.visible !== undefined ? marker.visible : true" :draggable="marker.draggable" :zIndex="marker.zIndex" @click="clickMarker(marker, key)" @dblclick="onOverlayEvent('dblclick', $event, marker)" @drag="onOverlayEvent('drag', $event, marker)" @dragend="onOverlayEvent('dragend', $event, marker)" @dragstart="onOverlayEvent('dragstart', $event, marker)" @mousedown="onOverlayEvent('mousedown', $event, marker)" @mousemove="onOverlayEvent('mousemove', $event, marker)" @mouseout="onOverlayEvent('mouseout', $event, marker)" @mouseover="onOverlayEvent('mouseover', $event, marker)" @mouseup="onOverlayEvent('mouseup', $event, marker)" @rightclick="onOverlayEvent('rightclick', $event, marker)"></gmap-marker>
			</gmap-map>
		</div>
	</div>
</template>


<script>
import { gmapApi } from 'vue2-google-maps'
import { oneOf, deepSet } from "@/utils/assist"
import loading_img from '@/assets/images/loading.gif';

export default {
	name: 'VueGoogleMap',
	components:{
	},
	props: {
		center: {
			type: Object,
			validator (value) {
				return value.lat && value.lng;
			},
			default() {
				return {
					lat: 25.0325917,
					lng: 121.5624999,
				}
			}
		},
		zoom: {
			type: Number,
			default: 15
		},
		options: {
			type: Object,
		},
		markers: {
			type: Array,
			default() {
				return []
			}
		},
		configs: {
			type: Object,
			validator(value) {
				let res = true;
				if(value.drawing) {
					res &= oneOf(value.drawingMode, ['marker', 'polygon', 'polyline', 'rectangle', 'circle'])
				}
				return res
			},
		},
		overlays: {
			type: Array,
			default() { return [] }
		},
		address: {
			type: String,
		},
		modes: {
			type: Object,
			default() { return {} }
		}
	},
	data() {
		return {
			complete: false,
			loading: true,
			loading_img_url: loading_img,
			map: {
				markers: [],
				overlays: [],
				center: { lat: 25.0325917, lng: 121.5624999 },
				zoom: 15,
				options: {
					zoomControl: true,
					mapTypeControl: true,
					scaleControl: true,
					streetViewControl: true,
					rotateControl: true,
					fullscreenControl: true,
					disableDefaultUi: false,
					styles: this.getMapStyles
				},
				drawing: false,
				drawingManager: null,
				drawingOptions: {},
				geocoder: undefined,
				geocodingOptions: {
					selectable: true,
				},
				address: '',
				addressResults: [],
				addressResult: undefined,
			},
			infoWindow: {
				pos: { lat: 25.0325917, lng: 121.5624999 },
				open: false,
				options: {
					pixelOffset: { width: 0, height: -40 }
				},
				content: ""
			},
			overlayConfigs: {
				options: {},
				editable: false,
				draggable: false,
				click: () => {},
				dblclick: () => {},
				drag: () => {},
				dragend: () => {},
				dragstart: () => {},
				mousedown: () => {},
				mousemove: () => {},
				mouseout: () => {},
				mouseover: () => {},
				mouseup: () => {},
				rightclick: () => {},
			},
			controls: {},
			styles: {
				night: [
					{ elementType: 'geometry', stylers: [{color: '#242f3e'}] },
					{ elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}] },
					{ elementType: 'labels.text.fill', stylers: [{color: '#746855'}] },
					{
						featureType: 'administrative.locality',
						elementType: 'labels.text.fill',
						stylers: [{color: '#d59563'}]
					},
					{
						featureType: 'poi',
						elementType: 'labels.text.fill',
						stylers: [{color: '#d59563'}]
					},
					{
						featureType: 'poi.park',
						elementType: 'geometry',
						stylers: [{color: '#263c3f'}]
					},
					{
						featureType: 'poi.park',
						elementType: 'labels.text.fill',
						stylers: [{color: '#6b9a76'}]
					},
					{
						featureType: 'road',
						elementType: 'geometry',
						stylers: [{color: '#38414e'}]
					},
					{
						featureType: 'road',
						elementType: 'geometry.stroke',
						stylers: [{color: '#212a37'}]
					},
					{
						featureType: 'road',
						elementType: 'labels.text.fill',
						stylers: [{color: '#9ca5b3'}]
					},
					{
						featureType: 'road.highway',
						elementType: 'geometry',
						stylers: [{color: '#746855'}]
					},
					{
						featureType: 'road.highway',
						elementType: 'geometry.stroke',
						stylers: [{color: '#1f2835'}]
					},
					{
						featureType: 'road.highway',
						elementType: 'labels.text.fill',
						stylers: [{color: '#f3d19c'}]
					},
					{
						featureType: 'transit',
						elementType: 'geometry',
						stylers: [{color: '#2f3948'}]
					},
					{
						featureType: 'transit.station',
						elementType: 'labels.text.fill',
						stylers: [{color: '#d59563'}]
					},
					{
						featureType: 'water',
						elementType: 'geometry',
						stylers: [{color: '#17263c'}]
					},
					{
						featureType: 'water',
						elementType: 'labels.text.fill',
						stylers: [{color: '#515c6d'}]
					},
					{
						featureType: 'water',
						elementType: 'labels.text.stroke',
						stylers: [{color: '#17263c'}]
					}
				],
				hideBussiness: [
					{ featureType: 'poi.business', stylers: [{visibility: 'off'}] },
					{ featureType: 'poi.medical', stylers: [{visibility: 'off'}] },
					{ featureType: 'poi.place_of_worship', stylers: [{visibility: 'off'}] },
					{ featureType: 'poi.sports_complex', stylers: [{visibility: 'off'}] },
				],
				hideMarkers: [
					{ featureType: 'poi.attraction', stylers: [{visibility: 'off'}] },
					{ featureType: 'poi.business', stylers: [{visibility: 'off'}] },
					{ featureType: 'poi.government', stylers: [{visibility: 'off'}] },
					{ featureType: 'poi.medical', stylers: [{visibility: 'off'}] },
					{ featureType: 'poi.park', stylers: [{visibility: 'off'}] },
					{ featureType: 'poi.place_of_worship', stylers: [{visibility: 'off'}] },
					{ featureType: 'poi.school', stylers: [{visibility: 'off'}] },
					{ featureType: 'poi.sports_complex', stylers: [{visibility: 'off'}] },
				],
				hideTransitions: [
					{ featureType: 'transit.line', stylers: [{visibility: 'off'}] },
					{ featureType: 'transit.station', stylers: [{visibility: 'off'}] },
					{ featureType: 'transit.station.airport', stylers: [{visibility: 'off'}] },
					{ featureType: 'transit.station.bus', stylers: [{visibility: 'off'}] },
					{ featureType: 'transit.station.rail', stylers: [{visibility: 'off'}] },
				]
			}
		}
	},
	watch: {
		'configs.drawing': {
			handler(value) {
				if(value)
					this.initDrawingManager();
				else if(!value && this.map.drawingManager) {
					this.map.drawingManager.setMap(null);
				}
			}
		},
		'configs.drawingMode': {
			handler(value) {
				this.map.drawingOptions.drawingMode = value
				if(this.map.drawingManager)
					this.map.drawingManager.setOptions({
						drawingMode: value
					});
				else
					this.initDrawingManager();
			}
		},
		'configs.drawingOptions': {
			deep: true,
			handler(value) {
				this.setDrawingOptions()
				if(this.map.drawingManager)
					this.map.drawingManager.setOptions(this.map.drawingOptions);
			}
		},
		'configs.geocodingOptions': {
			deep: true,
			handler(value) {
				this.map.geocodingOptions = {
					...this.map.geocodingOptions,
					...value,
				}
			}
		},
		address: {
			handler(value) {
				this.geocodeAddress(value)
			}
		},
		'map.addressResult': {
			deep: true,
			handler(result) {
				let position = {
					lat: result.geometry.location.lat(),
					lng: result.geometry.location.lng(),
				}
				this.focusToLatLng(position, 18)
				this.$emit('geocoderSelect', {
					position: position,
					address: result.formatted_address,
				})
			}
		},
		center: {
			deep: true,
			handler(value) {
				this.map.center = value;
			}
		},
		zoom: {
			handler(value) {
				this.map.zoom = value;
			}
		},
		markers: {
			deep: true,
			handler() {
				this.map.markers.splice(0, this.map.markers.length)
				this.map.markers = Object.cloneDeep(this.markers)
				this.viewAllMarkers()
				this.$forceUpdate()
			}
		},
		modes: {
			deep: true,
			handler(modes) {
				this.setMapStyles()
			}
		},
		overlays: {
			deep: true,
			handler() {
				this.filterOverlays()
			}
		},
	},
	computed: {
		google: gmapApi,
		getMapStyles() {
			let style = []
			Object.keys(this.modes).forEach(key => {
				if(this.modes[key] && typeof this.styles[key] !== "undefined") {
					style = [...style, ...this.styles[key]]
				}
			})
			return style
		}
	},
	created() {
		this.initMap()
		this.map.drawingOptions = {
			drawingMode: this.configs.drawingMode,
			drawingControl: false,
			drawingControlOptions: {
				position: 2, //this.google.maps.ControlPosition.TOP_CENTER,
			},
			markerOptions: {
				icon: {
					url: 'http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png',
					scaledSize: {height: 32, width: 32},
				}
			},
			circleOptions: {
				fillColor: '#000',
				fillOpacity: 0.5,
				strokeWeight: 5,
				clickable: false,
				editable: true,
				zIndex: 1
			},
			polygonOptions: {
				editable: true
			},
			rectangleOptions: {
				editable: true
			}
		}
	},
	mounted() {
		this.$refs.map.$mapPromise.then((mapObject) => {
			this.complete = true
			this.loading = false
			this.google.maps.event.addListenerOnce(this.$refs.map.$mapObject, 'idle', () => {
				this.complete = true
				this.viewAllMarkers()
			})
			this.google.maps.event.addListener(this.$refs.map.$mapObject, 'bounds_changed', () => {
				this.filterMarkers()
				this.filterOverlays()
				this.loading = false
				this.$forceUpdate()
			})
			if(this.configs.drawing)
				this.initDrawingManager()
			if(this.configs.geocoding)
				this.initGeocoder()
			this.setMapStyles()
		})
	},
	methods:{
		initMap() {
			this.map.center = this.center;
			this.map.zoom = this.zoom;
			for(let opt in this.options) {
				if(opt in this.map.options){
					this.map.options[opt] = this.options[opt]
				}
			}
			this.setMapStyles()
		},
		setMapStyles() {
			this.$set(this.map.options, "styles", this.getMapStyles)
			if(!this.complete) return
			this.$refs.map.$mapObject.setOptions({
				styles: this.getMapStyles
			});
		},
		filterMarkers() {
			if(!this.complete) return
			this.map.markers = this.markers.filter(marker => {
				return this.insideMapBounds(marker.position)
				// marker.position.lat < bounds.north && marker.position.lat > bounds.south && marker.position.lng < bounds.east && marker.position.lng > bounds.west
			})
		},
		viewAllMarkers() {
			this.map.markers.splice(0, this.map.markers.length)
			this.map.markers = Object.cloneDeep(this.markers)
			if(!this.complete || !this.map.markers.length) return
			let group = new this.google.maps.LatLngBounds();
			if(this.map.markers.length <= 1){
				this.$refs.map.$mapObject.setZoom(18);
				this.$refs.map.$mapObject.setCenter(this.map.markers[0].position);
				return;
			}
			this.map.markers.forEach((marker)=>{
				group.extend(marker.position);
			});
			this.$refs.map.$mapObject.fitBounds(group);
		},
		filterOverlays() {
			if(!this.complete) return
			this.map.overlays = this.overlays.filter(overlay => {
				switch(overlay.type) {
					case 'polygon':
						return (overlay.path && overlay.path.some(pos => this.insideMapBounds(pos))) || (overlay.paths && overlay.path.some(path => path.some(pos => this.insideMapBounds(pos))))
					case 'polyline':
						return overlay.path.some(pos => this.insideMapBounds(pos))
					case 'rectangle':
						return this.insideMapBounds(overlay.bounds.sw) || this.insideMapBounds(overlay.bounds.ne) || this.insideMapBounds({lat: overlay.bounds.sw.lat, lng: overlay.bounds.ne.lng}) || this.insideMapBounds({lat: overlay.bounds.ne.lat, lng: overlay.bounds.sw.lng})
					case 'circle':
						return this.insideMapBounds(overlay.center, overlay.radius, 0) || this.insideMapBounds(overlay.center, overlay.radius, 90) || this.insideMapBounds(overlay.center, overlay.radius, 180) || this.insideMapBounds(overlay.center, overlay.radius, 360)
				}
				return false
			}).map(overlay => {
				return {
					...this.overlayConfigs,
					...overlay
				}
			})
		},
		clickMarker(marker, index) {
			// this.map.center = marker.position;
			if(marker.info) {
				this.infoWindow.pos = marker.position;
				this.infoWindow.open = true;
				this.infoWindow.content = marker.info;
				if(marker.infoWindowOptions)
					this.infoWindow.options = { 
						...this.infoWindow.options,
						...marker.infoWindowOptions
					}
			}
		},
		onOverlayEvent(eventName, event, overlay, element) {
			if(typeof overlay[eventName] ==='function') {
				overlay[eventName](event, overlay)
			}
		},
		setDrawingOptions() {
			deepSet(this.map.drawingOptions, this.configs.drawingOptions)
		},
		initDrawingManager() {
			this.setDrawingOptions()
			this.map.drawingManager = new this.google.maps.drawing.DrawingManager(this.map.drawingOptions)
			this.map.drawingManager.setMap(this.$refs.map.$mapObject);
			this.google.maps.event.addListener(this.map.drawingManager, 'overlaycomplete', this.initOverlay);
		},
		initOverlay(evt) {
			let overlay = evt.overlay
			let vueComponent = this
			let onChange = function(evt) {
				vueComponent.changeOverlay(evt, overlay)
			}
			switch(evt.type) {
				case 'circle':
					this.google.maps.event.addListener(overlay, 'radius_changed', onChange);
					this.google.maps.event.addListener(overlay, 'center_changed', onChange);
					// calSelectedPointsInCircle(overlay.getCenter(), overlay.getRadius());
					break;
				case 'polygon':
					this.google.maps.event.addListener(overlay.getPath(), 'set_at', onChange);
					this.google.maps.event.addListener(overlay.getPath(), 'insert_at', onChange);
					this.google.maps.event.addListener(overlay.getPath(), 'remove_at', onChange);
					// calSelectedPointsInPolygon(overlay.getPath());
					break;
				case 'rectangle':
					this.google.maps.event.addListener(overlay, 'bounds_changed', onChange);
					break;
				case 'marker':
					this.google.maps.event.addListener(overlay, 'dragend', onChange);
					break;
				default: 
					break;
			}
			this.$emit('addOverlay', evt)
		},
		changeOverlay(evt, overlay) {
			this.$emit('changeOverlay', evt, overlay)
		},
		initGeocoder() {
			this.map.geocoder = new this.google.maps.Geocoder();
			if(this.address) {
				this.map.address = this.address
				this.geocodeAddress(this.address)
			}
		},
		clickMap(e) {
			this.infoWindow.open = false;
		},
		geocodeAddress(address) {
			if(this.map.geocoder) {
				this.map.geocoder.geocode({ 'address': address}, (results, status) => {
					if(status === 'OK' || status === 'ZERO_RESULTS') {
						results = (results ?? []).uniqBy('formatted_address')
						this.map.addressResults = results
						this.map.addressResult = results.first
						this.$refs.inputAddress.focus()
						this.$emit("geocoderAddress", results, status)
					}
					else {
						alert(`查詢失敗：${status}`)
					}
				})
			}
		},
		selectGeocoderResult(result) {
			this.map.addressResult = result
		},
		clickPolyline(e) {
		},
		insideMapBounds(pos) {
			if(!this.complete) return false
			let bounds = this.$refs.map.$mapObject.getBounds()
			if(!bounds) return true
			bounds = {
				north: bounds.getNorthEast().lat(),
				south: bounds.getSouthWest().lat(),
				east: bounds.getNorthEast().lng(),
				west: bounds.getSouthWest().lng()
			}
			return pos.lat < bounds.north && pos.lat > bounds.south && pos.lng < bounds.east && pos.lng > bounds.west
		},
		getPositionFromDistance(pos, distance, angle) {
			let lat = Math.asin(Math.sin(pos.lat)*Math.cos(distance)+Math.cos(pos.lat)*Math.sin(distance)*Math.cos(angle))
			let dlon = Math.atan2(Math.sin(angle)*Math.sin(distance)*Math.cos(pos.lat),Math.cos(distance)-Math.sin(pos.lat)*Math.sin(lat))
			let lng = (pos.lng - dlon + Math.PI) % (2*Math.PI) - Math.PI
			return { lat: lat, lng: lng }
		},
		closeInfoWindow() {
			this.infoWindow.open = false;
		},
		focusToLatLng(position, zoom=16) {
			this.$refs.map.$mapObject.setZoom(zoom);
			this.$refs.map.$mapObject.setCenter(position);
		},
		refresh() {
			let mapCenter = this.$refs.map.$mapObject.getCenter();
			let center = { lat: mapCenter.lat(), lng: mapCenter.lng() }
			this.map.center = {...{lat: center.lat - 0.01, lng: center.lng - 0.01}}
			this.$nextTick(() => {
				this.map.center = center
			})
		}
	}
}
</script>

<style scoped>
.gmap-wrapper, .gmap {
	width: 100%;
	height: 100%;
}
li {
	list-style: none;
}
.gmap-control-wrapper {
	position: absolute;
	z-index: 3;
	margin: 10px;
	border-radius: 2px;
	background-color: white;
	box-shadow: 0px 1px 1.5px 1px #ccc5, 0px .5px 1px 1px #eee9;
	height: 40px;
	font-size: 1rem;
}
.address-box-wrapper {
	right: 50px;
	min-width: 200px;
	width: calc(100% - 175px - 85px);
	max-width: 250px;
}
.address-box {
	width: 100%;
	height: 100%;
	border: none;
	flex: 1 1;
	padding: 0 .25rem;
}
.address-box:focus {
	box-shadow: none;
}
.address-box-btn {
	border: none;
	background: transparent;
	flex: 0 0 1rem;
	color: black;
}
.address-box-btn:focus {
	box-shadow: none;
}
.address-box:focus ~ .address-results-wrapper {
	display: block;
}
.address-results-wrapper {
	display: none;
	position: absolute;
	left: 0;
	top: 100%;
	width: 100%;
	min-height: 100%;
	border-radius: 2px;
	background-color: white;
	box-shadow: 0px 1px 1.5px 1px #ccc5, 0px .5px 1px 1px #eee9;
	padding: .25rem;
}
.address-results-wrapper > .option-wrapper > .option {
	padding: .25rem;
}
.address-results-wrapper > .option-wrapper > .option:hover, .address-results-wrapper > .option-wrapper > .option.selected {
	background-color: #eee9;
}

.loading {
	width: 100%;
	height: 100%;
	/* background-color: transparent; */
	background-repeat: no-repeat;
	background-size: 50px;
	background-position: center;
	min-height: 200px;
}
</style>