<template>
	<b-row>
		<div class="col-md-8 col-sm-12 member-list">
			<user-list v-if="!reset" :filter="{role_enum: 'user.role.outsider'}"></user-list>
		</div>
		<div class="col-md-4 col-sm-12">
			<b-card no-body class="border-0 control-board">
				<b-card-body>
					<b-tabs class="tabs-wrapper" fill active-nav-item-class="tab-title font-weight-bold" active-tab-class="tab-title" fluid>
						<b-tab class="tab-body" title="新增人員" active>
							<user-creator :role_list="['outsider']" @createUser="reset=true"></user-creator>
						</b-tab>
					</b-tabs>
				</b-card-body>
			</b-card>
		</div>
	</b-row>
</template>

<script>
import UserCreator from '@/components/User/UserCreator'
import UserList from '@/components/User/UserList'
import { deepCopy } from '@/utils/assist';
export default {
	name: 'AssignMemberManager',
	components:{
		UserCreator,
		UserList,
	},
	data() {
		return {
			reset: false
		}
	},
	watch: {
		reset(value) {
			if(value) {
				this.$nextTick(function () {
					this.reset = false;
				});
			}
		}
	},
	created() {
	},
	mounted() {
	},
	computed: {
	},
	methods:{
	}
}
</script>

<style scoped>
.card-wrapper {
	padding: .5rem;
}
.card {
	height: 100%;
}
.tab-body {
	padding: .5rem 1rem;
	border: 1px solid #dee2e6;
	border-top: 0;
	height: 100%;
}
.tabs-wrapper {
	height: 100%;
}
.tabs-wrapper > .tab-content {
	height: 100%;
}
.member-list {
	padding: 0 1rem;
}
.control-board {
	padding: 0 1rem;
}
.action-btn {
	padding: .25rem .75rem;
	border-radius: .5rem;
	background: #b2c8d8aa;
	color: #050b10cc;
	border-color: #b2c8d8;
}
.action-btn:hover {
	text-decoration: none;
	background: #8ea9c2cc;
	color: #050b10;
}
.action-btn:focus {
	box-shadow: none;
}
</style>