<template>
	<div class="login-page">
		<b-card class="login-card col-md-9 col-11" title="登入">
			<form class="col-12 login-form">
				<b-row class="col-12 form-input-wrapper">
					<label class="col-2 input-label" for="account"><i class="fa-fw fas fa-user" aria-hidden="true"></i></label>
					<b-form-input class="col-10 input-text" v-model="form.account" id="account" placeholder="帳戶" :state="input.account">
					</b-form-input>
					<span class="col-12 input-background"></span>
				</b-row>
				<b-row class="col-12 form-input-wrapper">
					<label class="col-2" for="password"><i class="fa-fw fa fa-lock" aria-hidden="true"></i></label>
					<b-form-input class="col-10 input-text" v-model="form.password" type="password" id="password" placeholder="密碼" :state="input.password"></b-form-input>
					<span class="input-background"></span>
				</b-row>
				<b-row v-show="login_failed" class="error-msg">
					{{error}}
				</b-row>
				<b-row>
					<button class="col-12 input-submit btn btn-primary" @click="onSubmit">登入</button>
				</b-row>
				<b-row>
					<b-card-text>
						<router-link to="/register" class="link">忘記帳號 / 密碼</router-link>
					</b-card-text>
				</b-row>
				<b-row>
					<b-card-text>
						還沒有帳號？<router-link to="/register" class="link">註冊帳號</router-link>
					</b-card-text>
				</b-row>
			</form>
		</b-card>
	</div>
</template>

<script>
import { isEmpty } from '@/utils/assist';
export default {
	name: 'Login',
	data(){
		return {
			form: {},
			input: {},
			login_failed: false,
			error: "",
			redirect: '/'
		};
	},
	created(){
		this.redirect = this.$route.query.redirect ? this.$route.query.redirect : this.redirect
		if(this.$store.getters.currentUser){
			this.$router.push(this.redirect);
		}
	},
	methods:{
		onSubmit(evt){
			this.$set(this.input, "account", isEmpty(this.form.account) ? false : undefined)
			this.$set(this.input, "password", isEmpty(this.form.password) ? false : undefined)
			evt.preventDefault();
			//執行登入的action
			this.$store.dispatch('login',this.form)
			.then(() => {
				this.$router.push({path: this.redirect});
			})
			.catch((error) => {
				this.login_failed = true
				this.error = "帳號或密碼錯誤"
				console.error(error)
			})
		}
	}
}
</script>

<style scoped>
	.login-page {
		min-height: 100vh;
		width: 100%;	
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		background-color: #f8f9ff;
	}
	.login-page * {
		margin: auto auto;
	}
	.login-card {
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 60px 10px;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(81, 87, 99, 0.12);
	}
	.login-form > * {
		margin: 1em 0;
	}
	.card-title {
		text-align: center;
	}
	.form-input-wrapper {
		padding: 0 1em;
		border-radius: 1.5em;
		height: 2.5em;
	}
	/* 清除自動填字背景色 */
	.input-text:-webkit-autofill,
	.input-text:-webkit-autofill:hover,
	.input-text:-webkit-autofill:focus,
	.input-text:-webkit-autofill:active {
		transition: background-color 5000s ease-in-out 0s;
		-webkit-transition: background-color 5000s ease-in-out 0s;
		-moz-transition: background-color 5000s ease-in-out 0s;
		-o-transition: background-color 5000s ease-in-out 0s;
		box-shadow: 0 0 0 30px white inset !important;
		-webkit-box-shadow: 0 0 0 30px white inset !important;
		-moz-box-shadow: 0 0 0 30px white inset !important;
		-o-box-shadow: 0 0 0 30px white inset !important;
	}
	.input-text {
		border: none;
		z-index: 3;
	}
	.input-text:focus + .input-background {
		border: 1px solid #9bc8de;
	}
	.input-text.is-invalid + .input-background{
		border: 1px solid red;
	}
	.input-submit {
		height: 2.5em;
		border-radius: 1.5em;
		border: 1px solid #e0e8e7;
		background-color: #e0e8e7;
		color: #283849;
	}
	.input-submit:hover {
		background-color: #d6e4e7;
		color: #283849;
	}
	.input-text:focus, .input-submit:focus {
		box-shadow: none;
		border: none;
	}
	.card-text {
		color: #90979b;
	}
	.link {
		color: #3d4450;
	}
	.link:hover {
		cursor: pointer;
		text-decoration: none;
		color: #015d87;
	}
	.input-background {
		position: absolute;
		left: 0;
		top: 0;
		border-radius: 1.5em;
		border: 1px solid #e0e8e7;
		width: 100%;
		height: 100%;
	}
	.error-msg {
		color: #f7542c;
		font-size: 0.8rem;
	}

</style>