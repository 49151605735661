<template>
	<div class="loading" :class="`${imageMode}-loading`" v-if="show" :style="`background-image:url(${imageUrl}); background-size: ${imageSize}px;`" @wheel="onWheel"></div>
</template>


<script>
import loading_img from '@/assets/images/loading.gif';
import loading_img2 from '@/assets/images/loading2.gif';
export default {
	name: "loading",
	data() {
		return {
			IMAGE_URLS: [ loading_img, loading_img2 ],
			SIZE_TABLE: {
				xs: 50,
				sm: 100,
				md: 150,
				lg: 200,
			},
			MODE_TABLE: [
				'normal', 'fixed',
			]
		}
	},
	props: {
		show: {
			type: Boolean,
			default: true,
		},
		image: {
			type: [Number, String],
			default: 0,
		},
		size: {
			type: [Number, String],
			default: 50
		},
		disableWheel: {
			type: Boolean,
			default: false,
		},
		mode: {
			type: String,
			default: 'normal'
		}
	},
	computed: {
		imageUrl() {
			return this.IMAGE_URLS[this.image] ? this.IMAGE_URLS[this.image] : this.IMAGE_URLS[0];
		},
		imageSize() {
			return !Number.isNaN(Number(this.size)) ? this.size : this.SIZE_TABLE[this.size] ? this.SIZE_TABLE[this.size] : 50;
		},
		imageMode() {
			return this.MODE_TABLE.includes(this.mode) ? this.mode : 'normal';
		}
	},
	methods: {
		onWheel(e) {
			if(this.disableWheel)
				e.preventDefault();
		}
	}
}
</script>

<style scoped>
.loading {
	width: 100%;
	height: 100%;
	/* background-color: transparent; */
	background-repeat: no-repeat;
	background-size: 50px;
	background-position: center;
	min-height: 200px;
}
.fixed-loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999;
	background-color: #0003;
}

</style>