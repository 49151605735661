<template>
	<div>
		<b-row class="justify-content-between" v-if="!editing">
			<b-list-group class="mb-2 col-12" style="flex: 1 1;">
				<h6>{{unitMember.name}}</h6>
				<template v-for="(label, key) in basicData">
					<b-list-group-item class="border-0 px-md-2 p-1" :key="key" v-if="unitMember[key]">
						{{label}}：{{unitMember[key]}}
					</b-list-group-item>
				</template>
			</b-list-group>
			<div v-if="editable">
				<b-button class="form-btn clear-btn" @click="editing=true">編輯</b-button>
			</div>
		</b-row>
		<unit-member-editor :unitMember="unitMember" :unitBranches="unitBranches" :unit="unit" @submit="reload" @cancel="cancelEditing" @loading="load" v-if="editing"></unit-member-editor>
		<b-list-group-item class="border-0 px-md-2 p-1">電子章：</b-list-group-item>
		<signature-manager type="unit_member" :id="unitMember.id" :signatures="unitMember.signatures" photo-class="col-xl-3 col-lg-4 col-6" @reload="reload" :showTitle="false"></signature-manager>
	</div>
</template>

<script>
import InputForm from '@/components/Input/InputForm';
import SignatureManager from '@/components/Signature/SignatureManager';
import UnitMemberEditor from '@/components/Unit/Member/UnitMemberEditor';
import Loading from '@/components/Loading/Loading.vue';
import { deepCopy, checkAllTrue } from '@/utils/assist';

export default {
	name: 'UnitMemberViewer',
	components:{
		InputForm,
		SignatureManager,
		UnitMemberEditor,
		Loading
	},
	props: {
		unit: {
			type: Object,
		},
		unitMember: {
			type: Object,
		},
		unitBranches: {
			type: Array,
			default: () => []
		},
	},
	data() {
		return {
			result: {},
			call: {},
			loading: true,
			editing: false,
			basicData: {}
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		loading() {
			this.$emit('loading', this.loading)
		}
	},
	created() {
		this.basicData = {
			id: "ID",
			full_name: "全名",
			type: '種類',
			unit_branch_name: "所屬區處",
		}
		if(!this.$store.getters.isDeveloper) {
			this.$delete(this.basicData, 'id')
		}
	},
	mounted() {
	},
	computed: {
		editable() {
			return this.checkSystemPermission('edit')
		},
	},
	methods:{
		reload() {
			this.$emit('reload')
		},
		load(loading) {
			this.loading = loading
		},
		cancelEditing() {
			this.editing = false;
		},
		checkSystemPermission(action_key) {
			let userPermissions = this.$store.getters.currentUser.permissions
			const system_control_type = this.$store.getters.enum(`permission.system_control.system`)
			let action_type = this.$store.getters.enum(`permission.action_type.${action_key}`)
			return userPermissions.find(permission => {
				let permission_type = this.$store.getters.enum(permission.permission_type_enum)
				return permission.type_index === system_control_type.index && permission_type.data.actions.includes(action_type.index);
			})
		}
	}
}
</script>

<style scoped>
.input {
	margin: .5rem .25rem
}
</style>