<template>
	<div>
		<loading mode="fixed" image="1" size="lg" v-if="loading"/>
		<construction-site-viewer ref="constructionSiteViewer" :sites="project.construction_sites" :address="project.address" :images="project.data_list.filter(o=>o.type_name==='設計圖')" :mode="viewerMode" :editSiteIndex="editSiteIndex" @add="addNewConstructionSite" @edit="editConstructionSite" @clear="newSites.clear()" @loading="load"></construction-site-viewer>
		<template v-if="viewerMode === VIEWER_MODES.VIEW">
			<div class="mt-2 d-flex justify-content-end" v-if="creatable">
				<b-button type="button" class="form-btn submit-btn" @click="viewerMode = VIEWER_MODES.CREATE">新增工地</b-button>
			</div>
			<bv-table :columns="siteTable.columns" :data="siteTable.data" :configs="siteTable.configs" :actions="siteTable.actions" :detailColumns="siteTable.detailColumns"></bv-table>
			<b-row class="mt-2 justify-content-end" v-if="canAssign">
				<router-link :to="{ name: '派工頁面', query: { siteIds: project.construction_sites.map(s=>s.id).join(',') }}"><b-button type="button" class="form-btn submit-btn">指派任務</b-button></router-link>
			</b-row>
		</template>
		<template v-else-if="viewerMode === VIEWER_MODES.CREATE">
			<construction-site-creator-form :project="project" :newSites="newSites" :address="project.address" :images="project.data_list.filter(o=>o.type_name==='設計圖')" :permission="permission" @cancel="viewerMode = VIEWER_MODES.VIEW" @delete="deleteNewConstructionSite" @create="reload" @loading="load"></construction-site-creator-form>
		</template>
		<construction-site-editor class="mt-4" v-else-if="viewerMode === VIEWER_MODES.EDIT || viewerMode === VIEWER_MODES.EDIT_DATA" :project="project" :site="editSite" @cancel="viewerMode = VIEWER_MODES.VIEW; editSiteIndex = -1" @edit="reload" @loading="load"></construction-site-editor>
	</div>
</template>


<script>
import ConstructionSiteViewer from '@/components/Project/Site/ConstructionSiteViewer'
import ConstructionSiteCreatorForm from '@/components/Project/Site/ConstructionSiteCreatorForm'
import ConstructionSiteEditor from '@/components/Project/Site/ConstructionSiteEditor'
import BvTable from '@/components/Table/BvTable'
import Loading from '@/components/Loading/Loading.vue';

export default {
	name: 'ConstructionSiteManager',
	components: {
		ConstructionSiteViewer,
		ConstructionSiteCreatorForm,
		BvTable,
		ConstructionSiteEditor,
		Loading,
	},
	props: {
		groupID: {
			required: true,
			default: 0
		},
		projectType: {
			required: true,
			default: 0
		},
		projectID: {
			required: true,
			default: 0
		},
		project: {
			type: Object
		},
		permission: {
			type: Object,
		}
	},
	data() {
		return {
			group_id: Number.isInteger(parseInt(this.groupID)) ? parseInt(this.groupID) : 0,
			project_type: Number.isInteger(parseInt(this.projectType)) ? parseInt(this.projectType) : -1,
			project_id: Number.isInteger(parseInt(this.projectID)) ? parseInt(this.projectID) : -1,
			loading: false,
			siteTable: {
				detailColumns: [
					{ key: 'id', label: 'ID', hide: !this.$store.getters.isDeveloper},
					{ key: 'name', label: '名稱' },
					{ key: 'status', label: '狀態' },
					{ key: 'paved', label: '是否通知銑鋪' },
					{ key: 'paved_at', label: '預計銑鋪日期' },
					{ key: 'site_form', label: '工地表單' },
					{ key: 'point_form', label: '測量點表單' },
					{ key: 'positioning_point_form', label: '支距點表單' },
					{ key: 'note', label: '備註' },
					{
						key: 'background_image_url', label: '背景圖', type: 'image',
						hide: (index) => !this.project.construction_sites[index].background_image_url
					},
					{ key: 'scene_pictures_url', label: '資料連結', type: 'link' },
				],
				columns: [
					{ key: 'index', label: '', isRowHeader: true, },
					{ key: 'id', label: 'ID', hide: !this.$store.getters.isDeveloper },
					{ key: 'name', label: '名稱' },
					{ key: 'status', label: '狀態' },
					{ key: 'paved', label: '是否通知銑鋪' },
					{ key: 'paved_at', label: '銑鋪日期' },
				],
				data: [],
				actions: [
					{
						//icon, label
						button: { type: 'icon', icon: "fas fa-file", label: "資料連結" },
						//function, link, route
						action: { type: 'link', link: this.getConstructionSiteSceneUrl, target: "_blank" },
						hide: (index) => this.project.construction_sites[index].scene_pictures_url
					},
					{
						//icon, label
						button: { type: 'icon', icon: "fas fa-pen", label: "修改資料" },
						//function, link, route
						action: { type: 'function', function: this.editConstructionSiteData },
						hide: (index) => !this.checkPermission('edit')
					},
					{
						//icon, label
						button: { icon: "fas fa-eye", label: "檢視成果" },
						//function, link, route
						action: { type: 'route', route: this.routeToConstructionSiteResult },
						hide: (index) => !this.checkConstructionSiteResult(index)
					},
					{
						//icon, label
						button: { icon: "fas fa-map-marked-alt", label: "編輯成果" },
						//function, link, route
						action: { type: 'route', route: this.routeToConstructionSiteResultEditor, target: "_blank" },
						hide: (index) => !this.checkPermission('edit')
					}
				],
				configs: {
					actionsColumn: true,
					rowDetail: true,
					busy: true,
					perPage: 10,
					mergeColumnCells: true,
					mergeColumns: []
				}
			},
			editSiteIndex: -1,
			editSite: {},
			newSites: [],
			VIEWER_MODES: {
				VIEW: 'view',
				CREATE: 'create',
				EDIT: 'edit',
				EDIT_DATA: 'editData',
			},
			viewerMode: 'view',
		}
	},
	created() {
		if(typeof this.project === 'undefined') {
			this.reload()
		}
		else {
			this.init();
			console.log(this.project.construction_sites)
		}
	},
	watch: {
		editSiteIndex: {
			handler(index) {
				this.editSite = this.project.construction_sites[index] ?? {}
			}
		}
	},
	computed: {
		editable() {
			return this.checkPermission('edit') ? true : false
		},
		creatable() {
			return this.checkPermission('write') ? true : false;
		},
		canAssign() {
			return this.checkSystemPermission('assignment', 'read')
		}
	},
	methods:{
		reload() {
			location.reload();
		},
		load(loading) {
			this.loading = loading
		},
		init() {
			this.setSitesTableData();
		},
		setSitesTableData() {
			this.siteTable.data = this.project.construction_sites.map((site, index) => {
				return { ...site, ...{
					index: index + 1,
					site_form: site.forms.map(o=>o.name).join('、'),
					point_form: site.point_forms.map(o=>o.name).join('、'),
					positioning_point_form: site.positioning_point_forms.map(o=>o.name).join('、'),
					// background_image_url: {
					// 	type: 'image',
					// 	url: site.background_image_url
					// },
				} }
			})
			this.siteTable.configs.busy = false
		},
		getConstructionSiteSceneUrl(index) {
			return this.project.construction_sites[index].scene_pictures_url
		},
		checkConstructionSiteResult(index) {
			const measured = [
				this.$store.getters.enum("construction_site.status.measured").index,
				this.$store.getters.enum("construction_site.status.measurement_finished").index,
				this.$store.getters.enum("construction_site.status.remeasured").index,
			]
			return measured.includes(this.project.construction_sites[index].status_index)
		},
		routeToConstructionSiteResult(index) {
			return {name: '測量成果', params: {groupID: this.group_id, projectType: this.project_type, projectID: this.project_id}}
		},
		routeToConstructionSiteResultEditor(index) {
			let site = this.project.construction_sites[index]
			return {name: '編輯案件成果', params: {siteID: site.id}}
		},
		editConstructionSiteData(index) {
			this.editSiteIndex = index
			// 派工後不可再改工地位置
			const unassignedIndex = this.$store.getters.enum("construction_site.status.unassigned").index
			this.viewerMode = this.project.construction_sites[index].status_index === unassignedIndex ? this.VIEWER_MODES.EDIT : this.VIEWER_MODES.EDIT_DATA
		},
		addNewConstructionSite(newSites) {
			this.newSites = newSites
		},
		deleteNewConstructionSite(index) {
			let site = this.newSites.find(site => site.index === index)
			site.exists = false
			this.$refs.constructionSiteViewer.deleteNewSite(index)
		},
		editConstructionSite(site) {
			this.editSite = { ...this.editSite, ...site }
		},
		checkPermission(action_key) {
			if(!this.groupID) return this.$store.getters.isDeveloper;
			if(!this.permission) return false;
			return this.permission.actions.find(action => action.key === action_key)
		},
		checkSystemPermission(system_control_key, action_key) {
			let userPermissions = this.$store.getters.currentUser.permissions
			let system_control_type = this.$store.getters.enum(`permission.system_control.${system_control_key}`)
			let action_type = this.$store.getters.enum(`permission.action_type.${action_key}`)
			return userPermissions.find(permission => {
				let permission_type = this.$store.getters.enum(permission.permission_type_enum)
				return permission.type_index === system_control_type.index && permission_type.data.actions.includes(action_type.index);
			})
		}
	}
}
</script>

<style scoped>
</style>