<template>
	<loading mode="fixed" image="1" size="lg" v-if="loading"/>
	<div v-else>
		<b-row class="justify-content-end" v-if="editable">
			<b-button class="ml-2 form-btn submit-btn" @click="downloadPointsPhotos">下載測量照片</b-button>
			<b-button class="ml-2 form-btn clear-btn" @click="openImageModal">上傳照片</b-button>
		</b-row>
		<div v-for="(type, key) in pointDataTypes" :key="key">
			<hr v-if="key"/>
			<h6>{{$store.getters.enum(`project_data_type.data_type.${type.key}`).text}}</h6>
			<alert :errors="pointDataErrors[type.key]"></alert>
			<div v-for="dataType in getDataTypeList(type.key)" :key="dataType.id">
				<photo-album class="col-12" :title="dataType.name" :photos="getDataList(dataType.id)" photo-class="col-xl-2 col-md-3 col-sm-6" selectable showname @select="openLightBox"></photo-album>
			</div>
		</div>
		<modal v-model="imageModal" centered size="xl" v-if="imageModal">
			<div style="min-height: 50vh">
				<alert :errors="['檔名格式重複，請重新設定']" :text-only="true" v-if="!isValidFormat"></alert>
				<b-card no-body class="px-md-3 py-md-2 px-2 py-1 m-1">
					<div class="d-flex justify-content-between align-items-center collapsed-icon" v-b-toggle="`upload-format-form`">
						<h6>檔名格式</h6>
					</div>
					<b-collapse id="upload-format-form" class="px-md-4 p-2">
						<div v-for="(type, key) in pointDataTypes" :key="key">
							<hr v-if="key"/>
							<b>{{$store.getters.enum(`project_data_type.data_type.${type.key}`).text}}</b>
							<div class="col-12 py-2" v-for="dataType in getDataTypeList(type.key)" :key="dataType.id">
								<div>{{dataType.name}}</div>
								<b-row class="pl-3">
									<div class="col-md-6 col-12 px-md-2 px-1"><input-text size="xs" label="前綴" v-model="formatFormData[dataType.id].prefix"></input-text></div>
									<div class="col-md-6 col-12 px-md-2 px-1"><input-text size="xs" label="後綴" v-model="formatFormData[dataType.id].suffix"></input-text></div>
								</b-row>
								<b-row class="justify-content-end text-secondary">
									<div class="col-md-10 col-12 pl-2">EX: {{formatFormData[dataType.id].prefix}}pt{{formatFormData[dataType.id].suffix}}1</div>
								</b-row>
							</div>
						</div>
					</b-collapse>
				</b-card>
				<b-card no-body class="p-md-3 p-2 m-1">
					<h6>上傳檔案</h6>
					<input-form ref="imageForm" class="p-0 col-12" :options="formOptions" v-model="formData" :actions="{submit:false, cancel:false}"></input-form>
				</b-card>
			</div>
			<template v-slot:footer>
				<b-button variant="primary" @click="uploadAllPhotos">確認</b-button>
			</template>
		</modal>
		<light-box v-if="viewImage" :data="photos" :index="viewImageIndex" @close="viewImageIndex=-1"></light-box>
	</div>
</template>

<script>
import PhotoAlbum from '@/components/Album/PhotoAlbum.vue'
import LightBox from '@/components/Album/LightBox.vue'
import InputFile from '@/components/Input/InputFile.vue'
import InputForm from '@/components/Input/InputForm';
import InputText from '@/components/Input/InputText';
import Loading from '@/components/Loading/Loading.vue';
import Modal from '@/components/Modal/Modal'
import Alert from '@/components/Alert/Alert.vue';
import ProgressBar from '@/components/Loading/ProgressBar.vue';
import { deepCopy, checkAllTrue, sortArray, dataUriToBlob, loadImage, resizeImage } from '@/utils/assist';
import fileManager from '@/utils/file';

export default {
	name: 'ResultDataViewer',
	components: {
		PhotoAlbum,
		InputFile,
		InputForm,
		InputText,
		LightBox,
		Loading,
		Modal,
		Alert,
		ProgressBar
	},
	props: {
		site: {
			type: Object,
			default: () => { return {} }
		},
		permission: {
			type: Object
		},
		projectDataType: {
			type: Array
		},
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			call: {},
			loading: true,
			pointDataValidator: {
				errors: [
					{
						key: 'uploadImage',
						validator: (points, dataType) => !points.every(pt => this.getDataTypeList(dataType.key).every(type => pt.data[type.name])),
						title: '缺少點位照片',
						messager: (points, dataType) => this.getDataTypeList(dataType.key).filter(type => !points.every(pt => pt.data[type.name])).map(type => `${type.name}：${points.filter(pt => !pt.data[type.name]).map(pt => pt.name).join(', ')}`),
					},
				],
			},
			viewImageIndex: -1,
			formatFormData: {},
			formData: {},
			imageModal: false,
			imageErrors: {},
		}
	},
	created() {
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
		formatFormData: {
			deep: true,
			handler(value) {
				if(this.isValidFormat && this.formData.photos)
					this.checkImages()
			}
		}
	},
	mounted() {
	},
	computed: {
		pointTypes() {
			return this.$store.getters.enum('point.type').filter(type => type.data.has_project)
		},
		pointDataTypes() {
			const pointTypeEnum = this.pointTypes
			return this.$store.getters.enum(`project_data_type.data_type`).filter(type => type.data.point_type !== undefined).filter(type => pointTypeEnum.mapValues('index').includes(type.data.point_type)).map(dataType => {
				return {
					...dataType,
					data: {
						...dataType.data,
						point_type: pointTypeEnum.find(type => type.index === dataType.data.point_type)
					},
				}
			})
		},
		pointDataErrors() {
			return this.pointDataTypes.reduce((obj, dataType) => {
				let points = this.site[`${dataType.data.point_type.key}s`] ?? []
				let errors = this.pointDataValidator.errors.filter(error => error.validator(points, dataType))
				if(errors.length) {
					obj[dataType.key] = errors.map(error => {
						return {
							...error,
							messages: error.messager ? (typeof error.messager === 'function' ? error.messager(points, dataType) : error.messager) : ''
						}
					})
				}
				return obj
			}, {})
		},
		editable() {
			return this.checkPermission('edit') ? true : false
		},
		photos() {
			let data = this.getDataList()
			sortArray(data, 'point.id')
			sortArray(data, 'type.id')
			sortArray(data, 'type.data_type_index')
			return data
		},
		viewImage() {
			return this.viewImageIndex >= 0 ? this.photos[this.viewImageIndex] : null
		},
		formOptions() {
			return [
				{ key: "photos", type: "file", label: "上傳照片", required: true, multiple: true, preview: true, accept: 'image/*', size: 'xs', onLoad: () => this.checkImages, previewPhotoClass: (file) => {return (file)=>{ return file && this.imageErrors[file.name] ? 'error' : '' }} },
			]
		},
		isValidFormat() {
			return [...new Set(Object.values(this.formatFormData).map(o=>`${o.prefix}pt${o.suffix}`))].length === Object.values(this.formatFormData).length
		},
	},
	methods: {
		getDataTypeList(data_type_keys) {
			data_type_keys = Array.isArray(data_type_keys) ? data_type_keys : [data_type_keys];
			let types = this.projectDataType.filter(t => data_type_keys.includes(t.data_type_key) && t.type_key === 'image')
			sortArray(types, 'id')
			sortArray(types, 'data_type_index')
			return types
		},
		getDataList(dataTypeId) {
			let filterFunc = datum => dataTypeId ? datum.type_id === dataTypeId : true
			let points = this.pointTypes.map(type => this.site[`${type.key}s`]).flat().filter(pt => pt.data_list.filter(filterFunc).length)
			return points.map(pt => {
				return pt.data_list.filter(filterFunc).map(datum => {
					return {
						id: datum.id,
						src: datum.content,
						content: datum.content,
						name: pt.name ?? datum.raw_file_name.substring(0, datum.raw_file_name.lastIndexOf('.')),
						type: this.projectDataType.find(type => type.id === datum.type_id),
						point: datum.point,
					}
				})
			}).flat()
		},
		openImageModal() {
			let dataTypes = this.pointDataTypes.map(type => this.getDataTypeList(type.key)).flat()
			const dataTypeFormat = {
				儀器照片: { prefix: 'app_' },
				交會點照片: { prefix: 'app_', suffix: 'A' },
				支距管線圖: { suffix: 'B' },
				繪製後支距管線圖: { prefix: 'modified_', suffix: 'B' },
			}
			dataTypes.forEach(dataType => {
				this.$set(this.formatFormData, dataType.id, {
					prefix: '', suffix: '',
					...(dataTypeFormat[dataType.name] ?? {}),
				})
			})
			this.imageModal = true;
		},
		checkImages() {
			this.imageErrors = {}
			this.formData.images = this.formData.photos.reduce((arr, file) => {
				let filename = file.name.parseFilePath('filename')
				let info = this.getImageInfo(filename)
				if(!info || !info.type_id) {
					this.imageErrors[file.name] = `${filename}: 檔名錯誤`
				}
				else if(!this.isPointExist(info.point_type, info.index)) {
					this.imageErrors[file.name] = `${filename}: 點位不存在`
				}
				else {
					arr.push({ index: info.index, type: info.point_type, project_data_type_id: info.type_id, content: deepCopy(file) })
				}
				return arr
			}, [])
		},
		getImageInfo(name) {
			let prefixes = Object.values(this.formatFormData).map(o => o.prefix)
			let suffixes = Object.values(this.formatFormData).map(o => o.suffix)
			let match = name.match(`^(?<prefix>(${prefixes.join('|')}))pt(?<suffix>(${suffixes.join('|')}))(?<index>[0-9]+)$`)
			let data_type_index = match ? Object.keys(this.formatFormData).findIndex(key => this.formatFormData[key].prefix === match.groups.prefix && this.formatFormData[key].suffix === match.groups.suffix) : -1
			let data_type_id = data_type_index >= 0 ? parseInt(Object.keys(this.formatFormData)[data_type_index]) : undefined
			let data_type = this.projectDataType.find(t => t.id === data_type_id)
			let point_type = data_type ? this.pointTypes.find(t => t.index === data_type.data_type_data.point_type) : undefined
			return match ? {
				...match.groups,
				index: parseInt(match.groups.index),
				type_id: data_type_id,
				point_type: point_type ? point_type.index : undefined
			} : undefined
		},
		isPointExist(point_type, index) {
			let pointType = this.pointTypes.find(t => t.index === point_type)
			if(pointType) {
				return (this.site[`${pointType.key}s`] ?? []).find(pt => pt.index === index) !== undefined
			}
			return false
		},
		uploadAllPhotos() {
			if(!this.$refs.imageForm.reportValidity() || !this.isValidFormat)
				return;
			this.checkImages()
			if(Object.keys(this.imageErrors).length) {
				alert("檔案錯誤，請刪除錯誤檔案：\n" + Object.values(this.imageErrors).join('\n'))
				return
			}
			this.$set(this.call, "uploadPointImages", false)
			const MAX_POINTS = 10;
			const MAX_FILE_SIZE = 20 * 1000000;
			Promise.all(this.formData.images.map(image => {
				return resizeImage(image.content).then(blob => {
					let filename = image.content.name.parseFilePath('filename')
					image.content = new File([blob], `${filename}.jpeg`, {
						type: blob.type
					});
				})
			})).then(() => {
				let images = deepCopy(this.formData.images)
				sortArray(images, 'index')
				sortArray(images, 'type')
				Promise.all(images.reduce((array, image) => {
					let size = array[array.length - 1].size + image.content.size
					if(size >= MAX_FILE_SIZE || array[array.length - 1].points.length >= MAX_POINTS) {
						array.push({
							points: [],
							size: 0
						})
					}
					let point = array[array.length - 1].points.find(pt => pt.index === image.index && pt.type === image.type)
					if(point) {
						point.images.push(image)
					}
					else {
						array[array.length - 1].points.push({
							index: image.index,
							type: image.type,
							images: [image]
						})
					}
					return array
				}, [{
					points: [],
					size: 0
				}]).map(o => this.uploadPointImages(o.points))).then((response) => {
					alert('上傳成功')
					this.call.uploadPointImages = true
					this.$emit('reload')
				}).catch((error) => {
					console.error(error);
					this.call.uploadPointImages = true
				});
			})
		},
		uploadPointImages(points) {
			return this.$axios.uploadPointImages({
				construction_site_id: this.site.id,
				creator_id: this.$store.getters.currentUser.id,
				user_id: this.$store.getters.currentUser.id,
				points: points,
			}, (response) => {
			}, (error) => {
			})
		},
		openLightBox(image) {
			this.viewImageIndex = this.photos.findIndex(o=>o.id===image.id)
		},
		checkPermission(action_key) {
			if(!this.groupID) return this.$store.getters.isDeveloper;
			if(!this.permission) return false;
			return this.permission.actions.find(action => action.key === action_key)
		},
		downloadPointsPhotos() {
			if(!this.photos.length) {
				alert("無可下載的檔案，請先上傳照片");
				return;
			}
			this.downloading = true
			let data = this.photos.map( photo => {
				return {
					folder: photo.type.name,
					extension: photo.content.split('/').pop().split('.').pop(),
					name: photo.name,
					content: photo.content
				}
			})
			fileManager.saveFiles(this.site.name, data, () => {
				this.downloading = false
			})
		},
	}
}
</script>

<style scoped>


.progress-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0005;
	z-index: 10;
}
.progress-bar-wrapper {
	width: 50%;
	margin: auto;
	top: 50%;
	background: #e1e6e690;
	box-shadow: 0 0 5px .5px #284a7090;
}
.progress-bar {
	background: #017ca7;
}
.progress-bar-striped {
	background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
	background-size: 1rem 1rem;
}
.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite;
}
</style>