<template>
	<span class="tag-wrapper">
		<span class="title">
			<span class="prefix">{{prefix}}</span>
			{{title}}
		</span>
		<span class="description">
			<span class="arrow_t_int"></span>
			<span class="arrow_t_out"></span>
			{{description}}
		</span>
	</span>
</template>

<script>
export default {
	components: {
	},
	props: {
		title: {
			type: String,
			default: ""
		},
		description: {
			type: String,
			default: ""
		},
		prefix: {
			type: String,
			default: "#"
		}
	}
}
</script>

<style scoped>
.tag-wrapper {
}
.title {
	opacity: 0.9;
}
.title > .prefix {
	opacity: 0.75;
}
.description {
	display: none;
	position: absolute;
	background-color: #f1f6f6cc;
	border-radius: 2rem;
	border: 1px solid #dee2e4cc;
	color: #3b496dcc;
	padding: 0 1rem;
	z-index: 999;
	font-size: 0.8em;
}
.title:hover + .description {
	display: block;
}
.arrow_t_int{
	width: 0;
	height: 0;
	border-width: .5em;
	border-style: solid;
	border-color: transparent transparent #dee2e4cc transparent ;
	position: absolute;
	top: -1em;
	left: 1rem;
}
.arrow_t_out{
	width: 0;
	height: 0;
	border-width: .5em;
	border-style: solid;
	border-color: transparent transparent #f1f6f6cc transparent ;
	position: absolute;
	top: -0.95em;
	left: 1rem;
}
</style>