<template>
	<loading v-if="loading"/>
	<div class="" v-else>
		<h5 class="d-flex">
			<i class="fa-fw" :class="icon ? icon : 'fas fa-file-alt'"></i> {{ title }}
		</h5>
		<b-list-group-item class="p-0" :class="{'border-0': !collapsible}" v-for="(site, key) in sites" :key="key">
			<hr v-if="key && !collapsible"/>
			<div class="px-md-4 px-2 py-3 d-flex align-items-center " :class="collapsible ? 'collapsed-icon' : 'cursor-auto'" v-b-toggle="`${_uid}-${site.id}`" :disabled="!collapsible || !measuredStatusIndexList.includes(site.status_index)">
				<div class="flex-fill d-flex justify-content-between pr-1 flex-wrap">
					<b class="text-wrap">工地{{ site.id }}: {{ site.name }}</b>
					<router-link :to="{name: '編輯案件成果', params: {siteID: site.id}}" target="_blank" v-if="measuredStatusIndexList.includes(site.status_index)">
						<i class="fa-fw fas fa-map-marked-alt"></i> 管線編輯工具
					</router-link>
				</div>
			</div>
			<b-collapse :id="`${_uid}-${site.id}`" class="px-md-4 p-2" :visible="!collapsible">
				<slot :site="site"></slot>
			</b-collapse>
		</b-list-group-item>
	</div>
</template>

<script>
import Loading from '@/components/Loading/Loading.vue'
import { checkAllTrue } from '@/utils/assist';

export default {
	name: 'ProjectSiteResultViewer',
	components: {
		Loading,
	},
	props: {
		project: Object,
		sites: Array,
		title: String,
		icon: String,
		collapsible: {
			type: Boolean,
			default: true,
		}
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			call: { },
			loading: true,
			result: {},
			points: [],
			downloading: false,
		}
	},
	created() {
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	mounted() {
	},
	computed: {
		measuredStatusIndexList() {
			return [
				'measured',
				'measurement_finished',
				'remeasured',
			].map(status => this.$store.getters.enum(`construction_site.status.${status}`).index)
		},
	},
	methods:{
	}
}
</script>

<style scoped>
.google-map {
	min-height: 500px;
	height: 500px;
}


.progress-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0005;
	z-index: 10;
}
.progress-bar-wrapper {
	width: 50%;
	margin: auto;
	top: 50%;
	background: #e1e6e690;
	box-shadow: 0 0 5px .5px #284a7090;
}
.progress-bar {
	background: #017ca7;
}
.progress-bar-striped {
	background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
	background-size: 1rem 1rem;
}
.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite;
}
</style>