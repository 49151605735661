<template>
	<loading v-if="loading"/>
	<input-form class="p-0 col-12" :options="formOptions" :actions="{cancel:false}" v-model="result" @submit="onSubmit" v-else></input-form>
</template>

<script>
import InputForm from '@/components/Input/InputForm';
import Loading from '@/components/Loading/Loading.vue';
import { isEmpty, checkAllTrue } from '@/utils/assist';

export default {
	name: 'UnitCreator',
	components:{
		InputForm,
		Loading
	},
	props: {
		unitType: {
			type: Object,
		},
	},
	data() {
		return {
			result: {},
			call: { getDynamicFormList: false },
			loading: true,
			dynamicForms: [],
			dynamicFormTypes: this.$store.getters.enum('dynamic_form.type').filter(type => type.data.has_unit),
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		loading() {
			this.$emit('loading', this.loading)
		}
	},
	created() {
		this.result.type = this.unitType.index;
		this.getDynamicFormList()
	},
	mounted() {
	},
	computed: {
		formOptions() {
			return [
				{ key: "type", type: "select", label: "種類", required: true, options: this.$store.getters.enum('unit.type'), keys: {value: 'index', text: 'text'}, size: 'xs', disabled: true },
				{ key: "name", type: "text", label: "名稱", required: true, size: 'xs' },
				{ key: "full_name", type: "text", label: "全名", size: 'xs' },
				{ key: "phone", type: "text", label: "電話", size: 'xs' },
				{ key: "address", type: "text", label: "地址", size: 'xs' },
				...this.dynamicFormTypes.map(type => {
					return { key: `${type.key}_dynamic_form_id`, type: "select", label: `${type.text}表單`, required: false, options: this.getDynamicFormListByType(type.key), keys: {value: 'id', text: 'name'}, size: 'xs', hide: !Object.keys(this.unitType.data.relations).includes('dynamicForms') }
				}),
			]
		},
	},
	methods:{
		getDynamicFormList() {
			this.call.getDynamicFormList = false
			this.$axios.getDynamicFormList({}, (response) => {
				this.dynamicForms = response.data
				this.call.getDynamicFormList = true
			}, (error) => {
				this.call.getDynamicFormList = true
			});
		},
		getDynamicFormListByType(type) {
			return this.dynamicForms.filter(form => form.type_index === this.$store.getters.enum(`dynamic_form.type.${type}`).index)
		},
		onSubmit(evt) {
			evt.preventDefault();
			// let result = {
			// 	...this.result,
			// 	dynamic_form_ids: this.dynamicFormTypes.map(type => this.result[`${type.key}_dynamic_form_id`])
			// }
			this.$set(this.call, 'createUnit', false)
			this.$axios.createUnit(this.result, (response) => {
				this.$emit('submit')
				alert('新增成功')
				this.call.createUnit = true;
			}, (error) => {
				this.call.createUnit = true;
			})
		},
	}
}
</script>

<style scoped>
.input {
	margin: .5rem .25rem
}
</style>