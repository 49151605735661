<template>
	<div>
		<loading mode="fixed" image="1" size="lg" v-if="uploading"/>
		<project-filter v-model="filter" :group="group" :groupID="groupID" @submit="searchProjects"></project-filter>
		<loading v-if="loading"/>
		<template v-else>
			<div class="py-2" v-for="(construction, index) in constructions" :key="index">
				<hr />
				<h5>{{construction.name}}</h5>
				<alert :errors="constructionErrors[index]" :text-only="true" v-if="constructionErrors[index].length"></alert>
				<b-list-group>
					<!-- <b-list-group-item class="d-flex align-items-center justify-content-between" v-for="(project, key) in projects.filter(p=>p.construction_id===construction.id)" :key="key">
						<div>{{project.excavation_permit_name}} {{project.address}}</div>
						<button type="button" class="link-btn" @click="openProjectCreatorModal(project)"><i class="fas fa-external-link-alt fa-fw"></i>匯入</button>
					</b-list-group-item> -->
					<b-list-group-item class="p-md-2 p-1" v-for="(project, key) in projects.filter(p=>p.construction_id===construction.id)" :key="key">
						<div class="px-2 py-1 d-flex justify-content-between align-items-center collapsed-icon" v-b-toggle="`project-creator-${key}`">
							{{project.excavation_permit_name}} {{project.address}}
						</div>
						<b-collapse :id="`project-creator-${key}`" class="p-0">
							<div class="d-flex justify-content-around align-items-center">
								<b-button type="button" class="form-btn clear-btn" @click="getSimilarProjectList(project, key)" :disabled="constructionErrors[index].length>0">搜尋相似案件</b-button>
								<b-button type="button" class="form-btn submit-btn" @click="openProjectCreatorModal(project)" :disabled="constructionErrors[index].length>0">新增案件與工地</b-button>
								<!-- <b-button type="button" class="form-btn submit-btn" @click="openConstructionSiteCreatorModal(project)" v-if="project.similar_projects.length">新增工地至現有案件</b-button> -->
							</div>
							<loading v-if="searching[key]"/>
							<b-list-group class="w-100" v-else>
								<b-list-group-item class="w-100 d-flex align-items-center justify-content-between border-0 text-secondary" v-if="project.similar_projects && !project.similar_projects.length">無相似案件</b-list-group-item>
								<b-list-group-item class="w-100 d-flex align-items-center justify-content-between" v-for="(similar_project, key) in project.similar_projects" :key="key">
									<a class="list-link-text" :href="`/group/${similar_project.project_group_id}/project/${similar_project.project_type_index}/${similar_project.id}`" target="_blank">
									{{similar_project.excavation_permit_name}} {{similar_project.address}}
									</a>
									<button type="button" class="list-link-btn" @click="openConstructionSiteCreatorModal(project, similar_project)">新增工地至此案件</button>
								</b-list-group-item>
							</b-list-group>
							<!-- <pre style="white-space:pre-wrap">{{project}}</pre> -->
						</b-collapse>
					</b-list-group-item>
				</b-list-group>
			</div>
		</template>
		<modal v-model="constructionSiteCreator.showModal" centered size="xl" @ok="createConstructionSite" v-if="constructionSiteCreator.project">
			<input-form ref="constructionSiteForm" class="p-0" :options="constructionSiteFormOptions" :actions="{submit: false, cancel: false}" size="xs" v-model="constructionSiteFormData"></input-form>
			<template v-slot:footer>
				<b-button variant="primary" @click="createConstructionSite">確認</b-button>
			</template>
		</modal>
		<modal v-model="projectCreator.showModal" centered size="xl" @ok="createProjectAndConstructionSite" v-if="projectCreator.project">
			<b-form ref="projectForm">
				<b-row>
					<div class="col-xl-6 col-lg-5 col-12">
						<h6>案件基本資料</h6>
						<input-form class="p-0" :options="basicFormOptions" :actions="{submit: false, cancel: false}" size="xs" v-model="projectFormData" :use-form="false"></input-form>
						<hr class="d-lg-none"/>
					</div>
					<div class="col-xl-6 col-lg-7 col-12 pl-2">
						<h6>案件施工資料</h6>
						<div class="px-2">
							<b-row class="input" v-for="(option, key) in constructionDataFormOptions" :key="key">
								<div class="col-md-3 col-2 pr-2" v-if="option.project_data_type">
									<input-select :size="option.project_data_type.size" :options="option.project_data_type.options" :keys="option.project_data_type.keys" v-model="projectFormData.project_construction_data[key][option.project_data_type.key]" :required="option.project_data_type.required" :use-form="false"></input-select>
								</div>
								<div :class="option.project_data_type ? 'col-md-9 col-10' : 'col-12'">
									<input-radio :label="option.status.label" :label-class="option.status.labelClass" :options="option.status.options" :keys="option.status.keys" :required="option.status.required" v-model="projectFormData.project_construction_data[key][option.status.key]"></input-radio>
								</div>
							</b-row>
							<b-row class="mt-2 justify-content-center">
								<b-button class="form-btn clear-btn" type="button" @click="addConstructionDataType" v-if="unusedConstructionDataTypes.length">新增其他施工資料</b-button>
							</b-row>
						</div>
						<hr />
						<h6>案件報告</h6>
						<!-- <pre style="white-space:pre-wrap">{{unitReportTypes}}</pre> -->
						<b-card no-body class="border-0 control-board">
							<b-card-body class="p-1">
								<b-tabs class="tabs-wrapper" fill active-nav-item-class="tab-title font-weight-bold" active-tab-class="tab-title" fluid>
									<b-tab class="tab-body" :title="unit_type.text" :active="key===0" v-for="(unit_type, key) in $store.getters.enum('unit.type')" :key="key">
										<input-checkbox :options="unitReportTypes" :keys="{value: 'id', text: 'name'}" direction="column" v-model="projectFormData.unit_report_types[unit_type.index]"></input-checkbox>
									</b-tab>
								</b-tabs>
							</b-card-body>
						</b-card>
					</div>
				</b-row>
				<hr />
				<h6>工地基本資料</h6>
				<input-form class="p-0" :options="constructionSiteFormOptions" :actions="{submit: false, cancel: false}" size="xs" v-model="constructionSiteFormData" :use-form="false"></input-form>
			</b-form>
			<template v-slot:footer>
				<b-button variant="primary" @click="createProjectAndConstructionSite">確認</b-button>
			</template>
		</modal>
	</div>
</template>

<script>
import Loading from '@/components/Loading/Loading.vue';
import { isEmpty, checkAllTrue } from '@/utils/assist';
import ProjectFilter from '@/components/Project/Creator/ProjectFilter.vue';
import Modal from '@/components/Modal/Modal'
import InputForm from '@/components/Input/InputForm';
import InputSelect from '@/components/Input/InputSelect'
import InputRadio from '@/components/Input/InputRadio'
import InputCheckbox from '@/components/Input/InputCheckbox'
import Alert from '@/components/Alert/Alert.vue';

export default {
	name: 'CmsProjectCreator',
	components:{
		Loading,
		ProjectFilter,
		Modal,
		InputForm,
		InputSelect,
		InputRadio,
		InputCheckbox,
		Alert,
	},
	props: {
		groupID: {
			required: true,
			default: 0
		},
		group: {
			type: Object
		},
		projectDataTypes: {
			type: Array
		},
	},
	data() {
		return {
			group_id: Number.isInteger(parseInt(this.groupID)) ? parseInt(this.groupID) : 0,
			call: {},
			loading: true,
			searching: {},
			uploading: false,
			filter: {},
			projects: [],
			constructions: [],
			dynamicForms: {},
			projectCreator: {
				showModal: false,
				project: undefined,
			},
			projectFormData: {},
			constructionSiteCreator: {
				showModal: false,
				project: undefined,
			},
			constructionSiteFormData: {},
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	created() {
	},
	mounted() {
	},
	computed: {
		apiQuery() {
			return {
				// ...this.filter,
				// project_group_id: this.group_id ? this.group_id : null
			}
		},
		constructionErrors() {
			return this.constructions.map(construction => {
				return construction.errors.length ? [
					`工程資料不完全：未填寫${construction.errors.map(o=>o.name).join('、')}，請至<a href="/manager/construction/list">工程管理</a>頁面修改`
				] : []
			})
		},
		projectType() {
			return this.$store.getters.enum('project.type').find(type => type.index === this.group.project_type_index)
		},
		projectParams() {
			const PARAMS = {
				_common: [
					'address',
					'excavation_permit_name',
					'pipeline_center_id',
					'expected_constructed_at',
					'assigned_at',
					'scene_pictures_url',
					'unit_report_types',
					'construction_data',
					'approved_start_at',
					'approved_end_at',
					'constructed_start_at',
					'constructed_end_at',
				],
				taipei_water: [
					'party_a_branch_id',
					'bid_id',
					'construction_id',
					'supervisor_id',
					'received_at',
					'measured_at',
					'file_made_at',
				],
				taiwan_water: [
					'party_a_id',
					'party_a_branch_id',
					'construction_id',
					'supervisor_id'
				],
				taiwan_water_sixth: [
					'party_a_branch_id',
					'construction_id',
					'supervisor_id',
					'commissioner_id'
				],
				taiwan_water_seventh: [
					'party_a_branch_id',
					'construction_id',
					'supervisor_id'
				],
				telecom: [
					'contractor_id',
					'construction_type',
					'serial_number',
					'work_order_number'
				],
				sewerage: [
					'party_a_id',
					'party_a_branch_id',
					'construction_id',
					'supervisor_id'
				],
				hydraulic: [
					'party_a_id',
					'party_a_branch_id',
					'construction_id',
					'supervisor_id'
				],
				other: [
					'party_a_id',
					'contractor_id'
				]
			}
			return [...new Set([
				...PARAMS._common,
				...PARAMS[this.projectType.key] ? PARAMS[this.projectType.key] : []
			])]
		},
		basicFormOptions() {
			return [
				// { key: "party_a_id", type: "select", label: "甲方", required: true, options: this.partyAList, keys: {value: 'id', text: 'name'} },
				// { key: "party_a_branch_id", type: "select", label: "甲方區處", required: true, options: this.partyABranchList, keys: {value: 'id', text: 'name'} },
				{ key: "supervisor_id", type: "select", label: "監工", required: true, options: this.supervisorList, keys: {value: 'id', text: 'name'} },
				// { key: "commissioner_id", type: "select", label: "承辦人", required: true, options: this.unitMemberList, keys: {value: 'id', text: 'name'} },
				// { key: "construction_type", type: "select", label: "施工種類", required: true, options: this.constants.tc_project_type_list, keys: {value: 'index', text: 'text'} },
				{ key: "pipeline_center_id", type: "select", label: "道管中心", required: true, options: this.pipelineCenterList, keys: {value: 'id', text: 'name'} },
				// { key: "bid_id", type: "select", label: "標案", required: true, options: this.bidList, keys: {value: 'id', text: 'name'} },
				// { key: "construction_id", type: "select", label: "工程", required: true, options: this.constructionList, keys: {value: 'id', text: 'name'} },
				// { key: "contractor_id", type: "select", label: "承包商", required: true, options: this.constants.contractor_list, keys: {value: 'id', text: 'name'} },
				{ key: "serial_number", type: "number", label: "流水號", required: false },
				{ key: "work_order_number", type: "text", label: "工作單編號", required: false },
				// { key: "address", type: "text", label: "施工地址", required: true },
				// { key: "excavation_permit_name", type: "text", label: "路證編號", placeholder: '無路證', required: false },
				// { key: "approved_start_at", type: "date", label: "路證核准起日", required: false, hide: !this.projectFormData.excavation_permit_name},
				// { key: "approved_end_at", type: "date", label: "路證核准迄日", required: false, hide: !this.projectFormData.excavation_permit_name},
				{ key: "constructed_start_at", type: "date", label: "路證施工起日", required: false, hide: !this.projectFormData.excavation_permit_name},
				{ key: "constructed_end_at", type: "date", label: "路證施工迄日", required: false, hide: !this.projectFormData.excavation_permit_name},
				{ key: "expected_constructed_at", type: "date", label: "施工日期", required: true },
				{ key: "received_at", type: "date", label: "收件日期", required: true },
				{ key: "assigned_at", type: "date", label: "派工日期", required: true },
				{ key: "measured_at", type: "date", label: "測量日期", required: true },
				{ key: "file_made_at", type: "date", label: "文件製作日期", required: true },
				{ key: "scene_pictures_url", type: "url", label: "資料連結", required: true },
			].filter(option => this.projectParams.includes(option.key) && (typeof option.hide === 'function' ? !option.hide() : !option.hide))
		},
		constructionDataTypes() {
			return this.projectDataTypes.filter(type => type.data_type_key === this.$store.getters.enum('project_data_type.data_type.construction_datum').key)
		},
		unusedConstructionDataTypes() {
			return this.constructionDataTypes.filter(type => {
				return !this.group.project_group_construction_data_types.find(construction_data_type => type.id === construction_data_type.project_data_type.id)
			})
		},
		constructionDataFormOptions() {
			const construction_data_status_list = this.$store.getters.enum('project_construction_datum.status')
			return this.projectFormData.project_construction_data.map(datum => {
				let data_type = this.constructionDataTypes.find(data_type => data_type.id === datum.project_data_type_id)
				return {
					project_data_type: datum.new ? {
						key: 'project_data_type_id',
						type: "select",
						label: '',
						options: this.unusedConstructionDataTypes,
						keys: {value: 'id', text: 'name'},
						size: 'xs',
						required: true,
					} : undefined,
					status: {
						key: 'status',
						type: "radio",
						label: datum.new ? '' : data_type.name,
						options: construction_data_status_list,
						keys: {value: 'index', text: 'text'},
						required: true,
						labelClass: datum.new ? undefined : 'col-md-3 col-2 p-0'
					}
				}
			})
		},
		unitReportTypes() {
			return this.projectDataTypes.filter(type => type.data_type_key === this.$store.getters.enum('project_data_type.data_type.unit_report').key)
		},
		pipelineCenterList() {
			return this.projectCreator.project && this.projectCreator.project.construction.pipeline_centers && this.projectCreator.project.construction.pipeline_centers.length ? this.projectCreator.project.construction.pipeline_centers : []
		},
		supervisorList() {
			return this.projectCreator.project && this.projectCreator.project.construction.supervisors && this.projectCreator.project.construction.supervisors.length ? this.projectCreator.project.construction.supervisors : []
		},
		constructionSiteFormOptions() {
			const dynamic_form_types = this.$store.getters.enum('dynamic_form.type').filter(type => type.data.has_unit)
			return [
				{ key: "name", type: "text", label: "工地名稱", required: true },
				...dynamic_form_types.map(type => {
					return { key: `${type.key}_dynamic_form_id`, type: "select", label: `${type.text}表單`, required: true, options: this.dynamicForms[type.key], keys: {value: 'id', text: 'name'}, size: 'xs' }
				}),
				{ key: "scene_pictures_url", type: "url", label: "資料連結", required: true },
			]
			// 工地名稱: name
			// 表單
			// 資料連結: scene_pictures_url
		},
		initialProjectFormData() {
			return {
				project_group_id: this.group_id,
				project_type: this.group.project_type_index,
				party_a_id: this.filter.party_a_id,
				address: this.projectCreator.project.address,
				excavation_permit_name: this.projectCreator.project.excavation_permit_name,
				expected_constructed_at: this.projectCreator.project.expected_constructed_at,
				scene_pictures_url: this.projectCreator.project.scene_pictures_url,
				// construction
				construction_id: this.projectCreator.project.construction_id,
				// TODO: 工程&標案
				// bid_id: this.projectCreator.project.construction.bid_id,
				party_a_branch_id: this.projectCreator.project.construction.party_a_branch_id,
				pipeline_center_id: this.projectCreator.project.construction.pipeline_centers.length ? this.projectCreator.project.construction.pipeline_centers[0].id : undefined,
				// 施工資料
				project_construction_data: this.constructionDataTypes.filter(type => {
					return this.group.project_group_construction_data_types.find(construction_data_type => construction_data_type.project_data_type_id === type.id)
				}).map(type => {
					return {
						project_data_type_id: type.id,
						status: null,
						new: false,
					}
				}),
				// 案件報告
				unit_report_types: this.$store.getters.enum('unit.type').reduce((obj, unit_type) => {
					obj[unit_type.index] = this.group.project_unit_report_types.filter(type => type.unit_type_key === unit_type.key).map(type => type.project_data_type.id)
					return obj;
				}, {}),
			}
		},
		initialConstructionSiteFormData() {
			let creator = [this.projectCreator, this.constructionSiteCreator].find(creator => creator.showModal)
			let project = creator && creator.project ? creator.project : {}
			return {
				cms_id: project.cms_id,
				name: project.address,
				lat: project.lat,
				lng: project.lng,
				// construction_site_dynamic_form_id: project.construction_site_dynamic_form_id,
				// point_dynamic_form_id: project.point_dynamic_form_id,
				scene_pictures_url: project.scene_pictures_url,
			}
		}
	},
	methods:{
		searchProjects(e) {
			e.preventDefault()
			this.getCmsProjectList()
			this.getUnitDynamicForm()
		},
		getCmsProjectList() {
			this.$set(this.call, 'getCmsProjectList', false)
			this.$axios.getCmsProjectList(this.apiQuery, (response) => {
				this.projects = response.data
				this.constructions = Object.values(this.projects.reduce((obj, project) => {
					if(!obj[project.construction_id]) {
						obj[project.construction_id] = {
							...project.construction,
							errors: this.checkConstruction(project.construction),
						}
					}
					return obj
				}, {}))
				this.call.getCmsProjectList = true
			}, (error) => {
				this.call.getCmsProjectList = true
			})
		},
		checkConstruction(construction) {
			const params = [
				{ key: 'party_a_id', name: '甲方' },
				{ key: 'party_a_branch_id', name: '甲方區處' },
				{ key: 'contractor_id', name: '甲方區處' },
				{ key: 'pipeline_centers', name: '道管中心', type: 'array' },
				{ key: 'supervisors', name: '監工', type: 'array' },
			]
			return params.filter(param => {
				switch(param.type) {
					case 'array':
						return !Array.isArray(construction[param.key]) || !construction[param.key].length
				}
				return construction[param.key] === undefined || construction[param.key] === null
			})
		},
		getUnitDynamicForm() {
			this.$set(this.call, 'getUnitDynamicForm', false)
			this.$axios.getUnitDynamicForm(this.filter.party_a_id, {}, (response) => {
				this.dynamicForms = this.$store.getters.enum('dynamic_form.type').filter(type => type.data.has_unit).reduce((obj, type) => {
					obj[type.key] = response.data[type.key] ? [response.data[type.key]] : []
					return obj
				}, {})
				this.call.getUnitDynamicForm = true
			}, (error) => {
				this.call.getUnitDynamicForm = true
			});
		},
		getSimilarProjectList(project, key) {
			this.$set(this.searching, key, true)
			this.$axios.getSimilarProjectList({
				address: project.address,
				construction_id: project.construction_id,
				excavation_permit_name: project.excavation_permit_name,
			}, (response) => {
				project.similar_projects = response.data
				this.searching[key] = false
			}, (error) => {
				this.searching[key] = false
			});
		},
		openProjectCreatorModal(project) {
			this.projectCreator.showModal = true
			this.$set(this.projectCreator, 'project', project)
			this.projectFormData = this.initialProjectFormData
			this.constructionSiteFormData = this.initialConstructionSiteFormData
		},
		addConstructionDataType() {
			if(this.projectFormData.project_construction_data.length >= this.constructionDataTypes.length){
				alert('已超過施工資料數量')
				return;
			}
			this.projectFormData.project_construction_data.push({
				project_data_type_id: null,
				status: null,
				new: true,
			})
		},
		openConstructionSiteCreatorModal(project, similar_project) {
			this.constructionSiteCreator.showModal = true
			this.$set(this.constructionSiteCreator, 'project', {...project, ...similar_project})
			this.constructionSiteFormData = this.initialConstructionSiteFormData
		},
		createConstructionSite() {
			if(!this.$refs.constructionSiteForm.reportValidity())
				return;
			let data = {
				...this.constructionSiteFormData,
				project_id: this.constructionSiteCreator.project.id,
				project_type: this.constructionSiteCreator.project.project_type_index,
			}
			this.uploading = true
			this.$axios.createConstructionSite({data: [data]}, (response) => {
				alert('新增成功');
				this.$router.push({name: '工地管理', params: {groupID: this.group_id, group: this.group, projectID: data.project_id, projectType: data.project_type}});
				this.uploading = false
			}, (error) => {
				this.uploading = false
			})
		},
		createProjectAndConstructionSite() {
			if(!this.$refs.projectForm.reportValidity())
				return;
			let data = {
				...this.projectFormData,
				user_id: this.$store.getters.currentUser.id,
				project_construction_data: this.projectFormData.project_construction_data.map(datum => {
					return { project_data_type_id: datum.project_data_type_id, status: datum.status }
				}),
				project_unit_reports: Object.keys(this.projectFormData.unit_report_types).map(unit_type => {
					return this.projectFormData.unit_report_types[unit_type].map((type_id) => {
						return { unit_type: unit_type, project_data_type_id: type_id }
					})
				}).flat(),
				construction_sites: [this.constructionSiteFormData]
			}
			this.uploading = true
			this.$axios.createProjectAndConstructionSite(data, (response) => {
				alert(`成功新增案件，與${response.data.construction_sites.length}個工地`);
				this.$router.push({name: '工地管理', params: {groupID: this.group_id, group: this.group, projectID: response.data.id, projectType: data.project_type}});
				this.uploading = false
			}, (error) => {
				this.uploading = false
			})
		},
	}
}
</script>

<style scoped>
.tab-body {
	padding: .5rem 1rem;
	border: 1px solid #dee2e6;
	border-top: 0;
}
.list-link-text {
	flex: 1 1;
	white-space: normal;
}
.list-link-btn {
	flex: 0 0;
	color: #4c6b8a;
	margin: 0 .5rem;
	background-color: transparent;
	border: none;
	border-radius: .5rem;
}
.list-link-btn:hover {
	color: #284a70;
	/* background-color: #0001; */
	text-decoration: none;
	font-weight: 400;
	filter: drop-shadow(0 0 1px #0005);
}
.list-link-btn:focus {
	box-shadow: none !important;
}
</style>
