<template>
	<loading v-if="loading"/>
	<div v-else>
		<div class="py-3 px-md-3 px-1">
			<b-card no-body class="control-boar h-100">
				<b-card-body>
					<h5>最新版本</h5>
					<b-list-group v-if="appPackage">
						<template v-for="(datum, key) in basicData">
							<b-list-group-item class="border-0 py-1" v-if="!datum.hide" :key="key">
								{{ datum.label }}：{{ appPackage[datum.key] }}
							</b-list-group-item>
						</template>
					</b-list-group>
					<div class="text-hint" v-else>
						無APP版本資訊
					</div>
				</b-card-body>
			</b-card>
		</div>
		<div class="py-3 px-md-3 px-1" v-if="$store.getters.isDeveloper">
			<b-card no-body class="control-board h-100">
				<b-card-body>
					<h5>更新版本</h5>
					<app-package-uploader @submit="reload"></app-package-uploader>
				</b-card-body>
			</b-card>
		</div>
	</div>
</template>

<script>
import Loading from '@/components/Loading/Loading.vue';
import AppPackageUploader from '@/components/App/AppPackageUploader.vue'
import { checkAllTrue } from '@/utils/assist';
export default {
	name: 'AppPackageManager',
	components:{
		Loading,
		AppPackageUploader,
	},
	data() {
		return {
			call: { getLatestAppPackageInfo: false },
			loading: true,
			appPackage: undefined,
			basicData: [
				{ key: 'id', label: 'ID', hide: !this.$store.getters.isDeveloper },
				{ key: 'version', label: '版本號' },
				{ key: 'note', label: '版本內容' },
				{ key: 'created_at_time', label: '更新時間' },
			],
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
	},
	created() {
		this.init();
	},
	destroyed() {
	},
	mounted() {
	},
	computed: {
	},
	methods:{
		init() {
			this.getLatestAppPackageInfo()
		},
		getLatestAppPackageInfo() {
			this.call.getLatestAppPackageInfo = false
			this.$axios.getAppPackageInfo({}, (response) => {
				this.appPackage = response.data
				this.call.getLatestAppPackageInfo = true
			}, (error) => {
				this.call.getLatestAppPackageInfo = true
			})
		},
		reload() {
			location.reload();
		},
	}
}
</script>

<style scoped>
</style>