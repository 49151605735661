<template>
	<loading v-if="loading"/>
	<div class="p-md-3 p-1" v-else>
		<b>{{title}}點位列表</b>
		<alert :errors="errors" :warnings="warnings"></alert>
		<bv-table :columns="tableColumns" :data="pointList" :configs="table.configs" :detailColumns="tableDetailColumns" :actions="tableActions" :rowConfigs="table.rowConfigs" v-if="pointList.length"></bv-table>
		<div class="text-center text-hint" v-else>無{{title}}</div>
		<b-row class="col-12 justify-content-end align-items-center">
			<slot name="buttons"></slot>
			<template v-if="pointList.length">
				<!-- <b-button class="ml-2 form-btn submit-btn" @click="transformPointsOrthometricHeight" v-if="errorPoints.errorOrthometricHeight.points.length">轉換點位正高</b-button> -->
				<b-button class="ml-2 form-btn submit-btn" @click="downloadPointsPositionCsv">下載大地起伏csv</b-button>
				<!-- <b-button class="ml-2 form-btn submit-btn" @click="downloadPointsToOrthometricHeight">下載正高轉換檔</b-button>
				<a class="row align-items-center" href="https://egnss.nlsc.gov.tw/trans/geo.aspx" target="_blank"><i class="fa-fw fas fa-globe"></i>轉正高網頁連結<i class="fa-fw fas fa-angle-double-right ml-1"></i></a> -->
			</template>
		</b-row>
	</div>
</template>

<script>
import BvTable from '@/components/Table/BvTable'
import Modal from '@/components/Modal/Modal'
import Loading from '@/components/Loading/Loading.vue';
import Alert from '@/components/Alert/Alert.vue';
import { checkAllTrue } from '@/utils/assist';
import fileManager from '@/utils/file';
import { IsFacilityType } from '@/gml/utils'

export default {
	name: 'PointList',
	components: {
		BvTable,
		Modal,
		Loading,
		Alert,
	},
	props: {
		site: {
			type: Object,
		},
		title: {
			type: String,
			default: ''
		},
		columns: {
			type: Array,
			default: () => []
		},
		showDefaultColumns: {
			type: Boolean,
			default: true
		},
		points: {
			type: Array,
			default: () => []
		},
		detailColumns: {
			type: Array,
			default: () => []
		},
		actions: {
			type: Array,
			default: () => []
		},
		errors: {
			type: Array,
			default: () => []
		},
		warnings: {
			type: Array,
			default: () => []
		},
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			call: {},
			loading: false,
			table: {
				columns: [
					{ key: 'id', label: 'ID', sortable: true, searchable: true, isRowHeader: true, stickyColumn: true, hide: !this.$store.getters.isDeveloper },
					{ key: 'name', label: '名稱', sortable: true, searchable: true, isRowHeader: true, stickyColumn: true },
				],
				detailColumns: [
					{ key: 'id', label: 'ID', hide: !this.$store.getters.isDeveloper },
					{ key: 'name', label: '名稱' },
				],
				actions: [],
				configs: {
					actionsColumn: true,
					rowDetail: true,
					busy: false,
					perPage: 10,
					showStatistic: true,
				},
				rowConfigs: {}
			},
		}
	},
	created() {
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	mounted() {
	},
	computed: {
		tableColumns() {
			return Object.values({
				...this.showDefaultColumns ? this.table.columns.keyBy('key') : [],
				...this.columns.keyBy('key'),
			})
		},
		tableDetailColumns() {
			return Object.values({
				...this.table.detailColumns.keyBy('key'),
				...this.detailColumns.keyBy('key'),
			})
		},
		tableActions() {
			return [
				...this.table.actions,
				...this.actions,
			]
		},
		distance(pos1, pos2) {
			return (pos1, pos2) => Math.sqrt(Math.pow(pos1.twd97_x - pos2.twd97_x, 2) + Math.pow(pos1.twd97_y - pos2.twd97_y, 2))
		},
		pointList() {
			return this.points.map(point => {
				return {
					...point,
					detail_info: point
				}
			})
		}
	},
	methods: {
		downloadPointsPositionCsv() {
			let content = ["序號,點號,N,E,橢球高,大地起伏,正高,埋深,種類,地表高度,目標,目標2,目標3"]
			this.points.forEach( (pt, index, arr) => {
				let coordinate = this.getPointCoordinate(pt.coordinate)
				content.push([
					index + 1,
					pt.name,
					coordinate.twd97_y,
					coordinate.twd97_x,
					coordinate.ellipsoidal_height,
					coordinate.orthometric_height !== null ? (coordinate.ellipsoidal_height - coordinate.orthometric_height).toFixed(3) : '',
					coordinate.orthometric_height !== null ? coordinate.orthometric_height : '',
					...pt.transformed_data ? [
						pt.transformed_data.depth,
						pt.transformed_type.type,
						0,
						!IsFacilityType(pt.transformed_type.type) ? pt.transformed_data.target1 : '',
						!IsFacilityType(pt.transformed_type.type) ? pt.transformed_data.target2 : '',
						!IsFacilityType(pt.transformed_type.type) ? pt.transformed_data.target3 : ''
					] : []
				].join(","))
			})
			fileManager.saveFile(`${this.site.name}-${this.title}-大地起伏轉換`, "csv", content.join("\r\n"), {bom:true})
		},
		downloadPointsToOrthometricHeight() {
			let content = []
			this.points.forEach( pt => {
				content.push([pt.full_name, "NEh", pt.coordinate.twd97_y, pt.coordinate.twd97_x, pt.coordinate.ellipsoidal_height].join(","))
			})
			fileManager.saveFile(`${this.site.name}-轉正高`, "txt", content.join("\n"))
			// window.open("https://egnss.nlsc.gov.tw/trans/geo.aspx", '_blank');
		},
		getPointCoordinate(coordinate) {
			return {
				twd97_y : coordinate ? coordinate.twd97_y : 0,
				twd97_x : coordinate ? coordinate.twd97_x : 0,
				ellipsoidal_height : coordinate ? coordinate.ellipsoidal_height : 0,
				orthometric_height : coordinate ? coordinate.orthometric_height : 0,
			}
		},
	}
}
</script>

<style scoped>
.google-map {
	min-height: 500px;
	height: 500px;
}



.progress-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0005;
	z-index: 10;
}
.progress-bar-wrapper {
	width: 50%;
	margin: auto;
	top: 50%;
	background: #e1e6e690;
	box-shadow: 0 0 5px .5px #284a7090;
}
.progress-bar {
	background: #017ca7;
}
.progress-bar-striped {
	background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
	background-size: 1rem 1rem;
}
.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite;
}



.alert-point-list {
	max-height: 300px;
	overflow-y: auto;
	word-wrap: break-word;
}
</style>