import Node from './Node'
import Info from './Info'
import Line from './Line'
import Point from './Point'
import Feature from './Feature'
import FeatureCollection from './FeatureCollection'

/**
 * 測量點集合
 */
class NodeCollection{
	/**
	 * @param {Node[]} nodes 
	 */
	constructor(generator) {
		/**
		 * @var GmlGenerator
		 */
		this.generator = generator
		/**
		 * @var Boolean
		 */
		this.updated = false
		/**
		 * @var {Node[]}
		 */
		this.unpairedNodes = [];
		/**
		 * @var {FeatureCollection}
		 */
		this.features = new FeatureCollection(generator)
		Feature.resetCounter()
	}

	/**
	 * 新增測量點
	 * @param {Node} newNode 
	 */
	push(newNode) {
		this.updated = true
		Array.prototype.push.call(this, newNode)
		// 若非管線，新增設施物-點
		if(newNode.isFacility()) {
			this.#addPoint(newNode)
		}
		Object.keys(newNode.targetInfos).forEach(target => {
			// 取目標點Node & Info
			let info = newNode.targetInfos[target]
			let targetNode = this.getNode(target, info)
			if(targetNode) {
				if(!targetNode.isPipeline()) {
					throw new Error(`目標點位需為管線，不可連接至設施物: ${newNode.name}->${targetNode.name}(${targetNode.type.formType})`)
				}
				// 設定目標點的連接點
				targetNode.addConnection(newNode, info, true)
				targetNode.setAdditionalInfo('isAcrossDitchCover', targetNode.isAcrossDitchCover || newNode.isDitchCover())
				newNode.setTargetNode(targetNode)
				// 若為管線，新增設施物-線段
				if(newNode.isPipeline()) {
					this.#addLine(targetNode, newNode, info)
				}
			}
			else {
				// 若找不到目標點，存入unpairedNodes
				this.unpairedNodes.push({
					from: newNode.name,
					to: target,
					info: info
				})
			}
		})
		return this;
	}

	/**
	 * 依名稱搜尋測量點
	 * @param {String} name 
	 * @param {Info} info 
	 * @returns {Node}
	 */
	getNode(name, info) {
		return this.find(node => node.name === name && Info.equals(node.info, info));
	}
	getCopiedNodes(name) {
		return this.filter(node => node.name === name)
	}

	/**
	 * 檢查尚未配對的點位
	 * @param {Boolean} throwError
	 */
	checkUnpairedNodes(throwError=true) {
		let unpairedNodes = this.unpairedNodes.filter(edge => {
			let fromNode = this.getNode(edge.from, edge.info)
			let toNode = this.getNode(edge.to, edge.info)
			if(toNode) {
				if(!toNode.isPipeline()) {
					throw new Error(`目標點位並非可連接點位: ${fromNode.name}->${toNode.name}(${toNode.type.formType})`)
				}
				toNode.addConnection(fromNode, edge.info, true)
				toNode.setAdditionalInfo('isAcrossDitchCover', toNode.isAcrossDitchCover || fromNode.isDitchCover())
				if(!fromNode.isFacility()) {
					this.#addLine(toNode, fromNode, edge.info)
				}
				return false
			}
			else {
				let errMsg = `目標點位不存在，或連接點資訊錯誤: ${edge.from} -> ${edge.to}`
				if(throwError)
					throw new Error(errMsg);
				else {
					alert(errMsg)
					console.warn(errMsg)
				}
			}
			return true
		})
		return !unpairedNodes.length
	}

	/**
	 * 新增設施物-線段
	 * @param {Node} startNode 
	 * @param {Node} endNode 
	 * @param {Info} info 
	 */
	#addLine = (startNode, endNode, info) => {
		let newLine = new Line(this.features, [startNode, endNode], info)
		this.features.push(newLine);
	}
	/**
	 * 新增設施物-點
	 * @param {Node} startNode 
	 * @param {Node} endNode 
	 * @param {Info} info 
	 */
	#addPoint = (node) => {
		this.features.push(new Point(this.features, node))
	}

	/**
	 * 整理/合併管線
	 */
	#rearrangeFeatures = () => {
		this.features.rearrangeLines()
	}

	/**
	 * 設施物
	 * @returns {FeatureCollection}
	 */
	getFeatures() {
		if(this.updated) {
			this.#rearrangeFeatures()
			this.updated = false
		}
		return this.features
	}
}
NodeCollection.prototype.__proto__ = Array.prototype;
Object.defineProperties(NodeCollection.prototype, {
	updated: {
		writable: true,
		enumerable: false,
		configurable: false,
	},
	unpairedNodes: {
		writable: true,
		enumerable: false,
		configurable: false,
	},
	features: {
		writable: true,
		enumerable: false,
		configurable: false,
	},
});

export default NodeCollection
