<template>
	<div>
		<loading mode="fixed" image="1" size="lg" v-if="loading"/>
		<h5>工程列表</h5>
		<b-row>
			<div class="col-md-7 col-sm-12 col-12 py-3 px-md-3 px-1">
				<div class="py-3 px-md-3 px-1">
					<bv-filter-group :filters="filterOptions" v-model="filter" />
				</div>
				<bv-table :columns="table.columns" :data="constructionList" :configs="table.configs" :actions="table.actions" :detail-columns="table.detailColumns"></bv-table>
			</div>
			<div class="col-md-5 col-sm-12 col-12">
				<b-card no-body class="border-0 control-board">
					<b-card-body>
						<b-tabs class="tabs-wrapper" fill active-nav-item-class="tab-title font-weight-bold" active-tab-class="tab-title" fluid>
							<b-tab class="tab-body" title="新增工程" active>
								<construction-creator :units="units" :unit_member_list="unit_member_list" @submit="reload"></construction-creator>
							</b-tab>
							<b-tab class="tab-body" title="編輯工程" :disabled="!editable" :active="editable">
								<construction-editor :construction="editingConstruction" :units="units" :unit_member_list="unit_member_list" @submit="reload" @cancel="cancelEditing" @loading="load"></construction-editor>
							</b-tab>
						</b-tabs>
					</b-card-body>
				</b-card>
			</div>
		</b-row>
	</div>
</template>

<script>
import BvTable from '@/components/Table/BvTable.vue'
import BvFilterGroup from '@/components/Filter/BvFilterGroup.vue'
import ConstructionCreator from '@/components/Bid/ConstructionCreator.vue'
import ConstructionEditor from '@/components/Bid/ConstructionEditor.vue'
import { deepCopy, checkAllTrue } from '@/utils/assist';
import Loading from '@/components/Loading/Loading.vue';

export default {
	name: 'ConstructionManager',
	components:{
		BvTable,
		BvFilterGroup,
		ConstructionCreator,
		ConstructionEditor,
		Loading
	},
	props: {
		constructions: {
			type: Array,
			default: () => []
		},
		units: {
			type: Array,
			default: () => []
		},
		unit_member_list: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			call: {},
			loading: true,
			table: {
				columns: [
					{ key: 'id', label: 'ID', sortable: true, searchable: true, hide: !this.$store.getters.isDeveloper },
					{ key: 'name', label: '名稱', sortable: true, searchable: true },
					{ key: 'party_a_name', label: '甲方', sortable: true, searchable: true },
					{ key: 'party_a_branch_name', label: '甲方區處', sortable: true, searchable: true },
				],
				actions: [
					{
						//icon, label
						button: { icon: "fas fa-edit", label: "編輯" },
						//function, link, route
						action: { type: 'function', function: this.startEditing },
						hide: (index) => this.editing === index
					},
					{
						//icon, label
						button: { icon: "far fa-edit", label: "取消編輯" },
						//function, link, route
						action: { type: 'function', function: this.cancelEditing },
						hide: (index) => this.editing !== index
					},
				],
				detailColumns: [
					{ key: 'id', label: "ID" },
					{ key: 'name', label: "名稱" },
					{ key: 'abbreviation', label: "簡稱" },
					{ key: 'number', label: "工程編號" },
					{ key: 'type_text', label: "類別" },
					// { key: 'bid_name', label: "標案" },
					{ key: 'party_a_name', label: "甲方" },
					{ key: 'party_a_branch_name', label: "甲方區處" },
					{ key: 'supervisor_names', label: "監工" },
					{ key: 'contractor_name', label: "施工廠商" },
					{ key: 'contractor_manager_name', label: "施工廠商負責人" },
					{ key: 'pipeline_center_names', label: "道管中心" },
				],
				configs: {
					// busy: true,
					actionsColumn: true,
					rowDetail: true,
					perPage: 10
				}
			},
			editing: -1,
			filter: {},
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
	},
	created() {
	},
	mounted() {
	},
	computed: {
		editable() {
			return this.editing >= 0
		},
		editingConstruction() {
			return this.constructionList[this.editing]
		},
		partyAList() {
			return this.units.filter(unit => unit.type_index === this.$store.getters.enum('unit.type.party_a').index)
		},
		contractorList() {
			return this.units.filter(unit => unit.type_index === this.$store.getters.enum('unit.type.contractor').index)
		},
		partyA() {
			return this.partyAList.find(u => u.id === this.filter.party_a_id)
		},
		partyABranchList() {
			return this.partyA && this.partyA.branches ? this.partyA.branches : []
		},
		constructionList() {
			return this.constructions.filter(construction => {
				return this.filterOptions.every(option => {
					if(option.hasAll ? this.filter[option.key]==='ALL' : this.filter[option.key] === undefined)
						return true
					return option.multiple ? this.filter[option.key].includes(construction[option.key]) : (construction[option.key] === this.filter[option.key])
				})
			}).map(construction => {
				return {
					...construction,
					supervisor_names: construction.supervisors.map(o => o.name).join('、'),
					pipeline_center_names: construction.pipeline_centers.map(o => o.name).join('、'),
				}
			})
		},
		filterOptions() {
			return [
				{key: 'party_a_id', title: "甲方", options: this.partyAList, keys: {value: 'id', text: 'name'}, hasAll: true},
				{key: 'party_a_branch_id', title: "甲方區處", options: this.partyABranchList, keys: {value: 'id', text: 'name'}, hasAll: true},
				// {key: 'contractor_id', title: "施工廠商", options: this.contractorList, keys: {value: 'id', text: 'name'}, hasAll: true},
				// {key: 'bid_id', title: "標案", options: this.bids, keys: {value: 'id', text: 'name'}},
			]
		},
	},
	methods:{
		reload() {
			this.$emit('reload');
		},
		load(loading) {
			this.loading = loading
		},
		startEditing(index) {
			this.editing = index
		},
		cancelEditing(index) {
			this.editing = -1;
		},
	}
}
</script>

<style scoped>
.card-wrapper {
	padding: .5rem;
}
.card {
	height: 100%;
}
.tab-body {
	padding: .5rem 1rem;
	border: 1px solid #dee2e6;
	border-top: 0;
	height: 100%;
}
.tabs-wrapper {
	height: 100%;
}
.tabs-wrapper > .tab-content {
	height: 100%;
}
</style>