<template>
	<input-form class="p-0 col-12" :options="formOptions" :actions="{cancel:false}" v-model="result" @submit="onSubmit"></input-form>
</template>

<script>
import InputText from '@/components/Input/InputText';
import InputRadio from '@/components/Input/InputRadio';
import InputSelect from '@/components/Input/InputSelect';
import InputForm from '@/components/Input/InputForm';
import Loading from '@/components/Loading/Loading.vue';
import { isEmpty, checkAllTrue } from '@/utils/assist';

export default {
	name: 'UnitMemberCreator',
	components:{
		InputText,
		InputRadio,
		InputSelect,
		InputForm,
		Loading
	},
	props: {
		unit: {
			type: Object,
		},
		unitBranches: {
			type: Array,
			default: () => []
		},
	},
	data() {
		return {
			result: {},
			call: {},
			loading: true,
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		loading() {
			this.$emit('loading', this.loading)
		}
	},
	created() {
		this.init()
	},
	mounted() {
	},
	computed: {
		formOptions() {
			return [
				{ key: "name", type: "text", label: "名稱", required: true, size: 'xs' },
				{ key: "type", type: "select", label: "種類", required: true, options: this.$store.getters.enum('unit_member.type'), keys: {value: 'index', text: 'text'}, size: 'xs' },
				{ key: "unit_branch_id", type: "select", label: "所屬區處", options: this.unitBranches, keys: {value: 'id', text: 'name'}, size: 'xs', hide: !this.unitBranches.length },
			]
		},
		unitType() {
			return this.$store.getters.enum(`unit.type.${this.unit.type_key}`);
		},
	},
	methods:{
		init() {
			this.$set(this.result, 'type', this.unitType.data.unit_member_type)
		},
		onSubmit(evt) {
			evt.preventDefault();
			this.$set(this.call, 'createUnitMember', false)
			this.$axios.createUnitMember({
				...this.result,
				unit_id: this.unit.id
			}, (response) => {
				this.$emit('submit')
				alert('新增成功')
				this.call.createUnitMember = true;
			}, (error) => {
				this.call.createUnitMember = true;
			})
		},
	}
}
</script>

<style scoped>
.input {
	margin: .5rem .25rem
}
</style>