<template>
	<div class="px-3">
		<h5>人員管理</h5>
		<b-row>
			<div class="col-12" :class="{'col-md-7': writable}">
				<user-list ref="userList" :actions="actions" v-if="!reset"></user-list>
			</div>
			<div class="col-md-5 col-12" v-if="writable">
				<b-card no-body class="border-0 control-board">
					<b-card-body>
						<b-tabs class="tabs-wrapper" fill active-nav-item-class="tab-title font-weight-bold" active-tab-class="tab-title" fluid>
							<b-tab class="tab-body" title="新增人員" :active="writable">
								<user-creator :role_list="roleList" @createUser="reset=true"></user-creator>
							</b-tab>
							<!-- <b-tab class="tab-body" title="修改資料" :active="!!editingUser" :disabled="!editingUser" v-if="editable">
								<user-editor :user="editingUser" :role-list="roleList" @updateUser="reset=true" @cancel="cancelEdit" v-if="editingUser"></user-editor>
							</b-tab> -->
						</b-tabs>
					</b-card-body>
				</b-card>
			</div>
		</b-row>
	</div>
</template>

<script>
import UserCreator from '@/components/User/UserCreator'
import UserEditor from '@/components/User/UserEditor'
import UserList from '@/components/User/UserList'
import { deepCopy } from '@/utils/assist';
export default {
	name: 'MemberManager',
	components:{
		UserCreator,
		UserEditor,
		UserList,
	},
	data() {
		return {
			reset: false,
			editingUser: undefined,
			actions: [
				{
					//icon, label
					button: { type: 'icon', icon: "fas fa-cog", label: '編輯' },
					//function, link, route
					action: { type: 'function', function: (user) => this.editUser(user) },
					// hide: (user) => !this.editable || this.editingUser ? user.id === this.editingUser.id : false,
					hide: true,
				},
				...this.$store.getters.enum('user.status').map(status => {
					let normal = status.key === 'normal'
					return {
						//icon, label
						button: { type: 'icon', icon: `fas fa-${normal ? 'check' : 'times'}-circle`, label: status.text, class: {'text-danger': !normal, 'text-success': normal} },
						//function, link, route
						action: { type: 'function', function: (user) => this.updateUserStatus(user, status) },
						hide: (user) => !this.editable || !this.roleList.includes(user.role_key) || user.status_index === status.index || this.$store.getters.currentUser.id === user.id
					}
				}),
			],
		}
	},
	watch: {
		reset(value) {
			if(value) {
				this.$nextTick(function () {
					this.reset = false;
				});
			}
		}
	},
	created() {
	},
	mounted() {
	},
	computed: {
		writable() {
			return this.hasPermission('system', 'write')
		},
		editable() {
			return this.hasPermission('system', 'edit')
		},
		userPermissions() {
			return this.$store.getters.currentUser.permissions
		},
		roleList() {
			let role = this.$store.getters.enum(this.$store.getters.currentUser.role_key)
			return this.$store.getters.enum('user.role').filter(o => o.data.level <= role.data.level).map(o=>o.key)
		},
	},
	methods:{
		editUser(user) {
			this.editingUser = user
		},
		cancelEdit() {
			this.editingUser = undefined
		},
		updateUserStatus(user, status) {
			if(confirm(`是否確認${status.text}帳號：${user.name}（身份：${user.role}）`)) {
				this.$axios.updateUser(user.id, {status: status.index}, (response) => {
					alert("更新成功")
					this.reset = true
				})
			}
		},
		hasPermission(system_control_key, action_key) {
			let system_control_type = this.$store.getters.enum(`permission.system_control.${system_control_key}`)
			let action_type = this.$store.getters.enum(`permission.action_type.${action_key}`)
			return !!this.userPermissions.find(permission => {
				let permission_type = this.$store.getters.enum(permission.permission_type_enum)
				return permission.type_index === system_control_type.index && permission_type.data.actions.includes(action_type.index);
			})
		},
	}
}
</script>

<style scoped>
.card-wrapper {
	padding: .5rem;
}
.card {
	height: 100%;
}
.tab-body {
	padding: .5rem 1rem;
	border: 1px solid #dee2e6;
	border-top: 0;
	height: 100%;
}
.tabs-wrapper {
	height: 100%;
}
.tabs-wrapper > .tab-content {
	height: 100%;
}
.control-board {
	padding: 0 1rem;
}
.action-btn {
	padding: .25rem .75rem;
	border-radius: .5rem;
	background: #b2c8d8aa;
	color: #050b10cc;
	border-color: #b2c8d8;
}
.action-btn:hover {
	text-decoration: none;
	background: #8ea9c2cc;
	color: #050b10;
}
.action-btn:focus {
	box-shadow: none;
}
</style>