<template>
	<div>
		<loading mode="fixed" image="1" size="lg" v-if="loading"/>
		<h5>報表生成</h5>
		<template v-if="!measuredConstructionSites.length">
			無已測量的工地，請新增工地或上傳工地表單
		</template>
		<template v-else>
			<alert :errors="errors" :warnings="warnings"></alert>
			<input-form ref="configForm" :options="configOptions" :actions="{submit: false, cancel: false}" v-model="configs"></input-form>
			<input-form ref="formForm" :options="formOptions" :actions="{cancel: false}" v-model="formData" @submit="submitForm">
				<template v-for="(imageType, key) in getProjectImageTypes()" v-slot:[`after-input-${key}`]>
					<b-row class="col-12 outside-wrapper" :key="key" v-if="exportedProjectImageContentList[key].length && !formData[`upload_${key}`]">
						<label class="col-md-2 col-12 pr-2 break-wrap"> </label>
						<span style="flex: 1 1;">
							<photo-album :photos="exportedProjectImageContentList[key]" showname></photo-album>
						</span>
					</b-row>
				</template>
				<template v-if="exportedReportValues.coordinate_examination_data" v-slot:after-inputs>
					<hr />
					<h6>座標校驗資料</h6>
					<div class="p-2" v-if="controlPointExaminationData">
						<li class="no-list-style">控制點編號：{{ controlPointExaminationData.control_point.number }} {{ controlPointExaminationData.control_point.name }}</li>
						<li class="no-list-style">行政區：{{ controlPointExaminationData.control_point.city }}{{ controlPointExaminationData.control_point.district }}</li>
						<li class="no-list-style">上傳時間：{{ controlPointExaminationData.created_at_time }}</li>
						<li class="no-list-style">測量人員：{{ controlPointExaminationData.users.mapValues('name').join('、') }}</li>
						<table class="w-100">
							<tr>
								<td>原始座標</td>
								<td>N: {{ controlPointExaminationData.control_point.coordinate.twd97_y }}</td>
								<td>E: {{ controlPointExaminationData.control_point.coordinate.twd97_x }}</td>
								<td>H: {{ controlPointExaminationData.control_point.coordinate.ellipsoidal_height }}</td>
							</tr>
							<tr>
								<td>校驗資料</td>
								<td>N: {{ controlPointExaminationData.coordinate.twd97_y }}</td>
								<td>E: {{ controlPointExaminationData.coordinate.twd97_x }}</td>
								<td>H: {{ controlPointExaminationData.coordinate.ellipsoidal_height }}</td>
							</tr>
							<tr>
								<td>座標差值</td>
								<td>N: {{ (controlPointExaminationData.coordinate.twd97_y - controlPointExaminationData.control_point.coordinate.twd97_y).toFixed(3) }}</td>
								<td>E: {{ (controlPointExaminationData.coordinate.twd97_x - controlPointExaminationData.control_point.coordinate.twd97_x).toFixed(3) }}</td>
								<td>H: {{ (controlPointExaminationData.coordinate.ellipsoidal_height - controlPointExaminationData.control_point.coordinate.ellipsoidal_height).toFixed(3) }}</td>
							</tr>
						</table>
					</div>
					<div class="p-2 text-danger" v-else>
						查無可用之控制點校驗資料
					</div>
				</template>
			</input-form>
		</template>
	</div>
</template>

<script>
import InputForm from '@/components/Input/InputForm';
import PhotoAlbum from '@/components/Album/PhotoAlbum.vue'
import Loading from '@/components/Loading/Loading.vue';
import Alert from '@/components/Alert/Alert.vue';
import { deepCopy, sortArray, checkAllTrue, resetObject } from '@/utils/assist';
import GmlGenerator from '@/gml';
import fileManager from '@/utils/file';
import { GetPointDistance, GetGmlProjectKeys, IsFacilityType, IsPointOnPrivateLand, isPointExtantFacility, GetPointUtilityCategoriesCount, GetFacilityUtilityCategories } from '@/gml/utils'
export default {
	name: 'ProjectReportGenerator',
	components: {
		InputForm,
		PhotoAlbum,
		Loading,
		Alert
	},
	props: {
		project: {
			type: Object
		},
		projectDataType: {
			type: Array
		},
		units: {
			type: Array
		},
		reportTypes: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			call: {},
			loading: true,
			configs: {},
			data: {},
			formData: {},
			validator: {
				errors: [
					{
						key: 'constructionSiteAmountError',
						validator: (project, sites, configs) => !configs.seperate_construction_sites || configs.exported_construction_sites.length,
						title: "請選擇要生成報表的工地",
					},
					{
						key: 'reportAmountError',
						validator: (project, sites, configs) => configs.report_types && configs.report_types.length && (configs.report_types.includes('REPORT') ? configs.other_report_types.length : true),
						title: "請選擇要生成的報表種類",
					},
					// {
					// 	key: 'projectInfoError',
					// 	validator: project => Object.keys(GetGmlProjectKeys()).every(param=>project[param]),
					// 	title: "缺少案件資料，請至案件資訊修改",
					// },
					{
						key: 'pointCoordinateError',
						validator: (project, sites, configs) => (!configs.report_types.includes('GML') && !configs.report_types.includes('REPORT') && !configs.report_types.includes('FOUR_PITCH_REPORT')) || sites.every(site => [
							...site.points, ...site.resection_points
						].every(p=>p.coordinate&&p.coordinate.orthometric_height!==null&&p.coordinate.orthometric_height!==0)),
						title: "點位座標錯誤，請至「測量成果」重新上傳測量座標csv或轉換測量點正高",
					},
					{
						key: 'pointFormDataError',
						validator: (project, sites) => sites.every(site => site.points.every(pt => pt.status_index === this.$store.getters.enum('point.status.finished').index)),
						title: "點位資料不全，請重新上傳表單：設施物、型態、埋深",
						messager: (project, sites) => {
							return sites.map(site => site.points.filter(pt => pt.status_index !== this.$store.getters.enum('point.status.finished').index)).flat().map(p => p.name).join(', ')
						}
					},
					{
						key: 'pointImagesError',
						validator: (project, sites, configs) => !configs.report_types.includes('REPORT') || sites.every(site => this.$store.getters.enum('point.type').filter(type => type.data.has_project).every(type => (site[`${type.key}s`] ?? []).every(p => Object.values(this.getPointImageTypeConfigsByPointType(type.key)).every(t=>p.data[t.text])))),
						title: "缺少點位照片，請至「測量成果」重新上傳",
						messager: (project, sites) => {
							return this.$store.getters.enum('point.type').filter(type => type.data.has_project).map(type => {
								let points = sites.map(site => site[`${type.key}s`]).flat()
								let imageTypes = Object.values(this.getPointImageTypeConfigsByPointType(type.key))
								return imageTypes.map(imageType => {
									return {
										name: imageType.text,
										points: points.filter(p => !p.data[imageType.text]).map(p=>p.name).join(', ')
									}
								}).map(imageType => imageType.points ? `${imageType.name}：${imageType.points}` : null)
							}).flat().filter(o => o)
						}
					},
				],
				warnings: [
					{
						key: 'UnfinishedConstructionSitesWarning',
						validator: (project, sites, configs) => configs.seperate_construction_sites || !configs.seperate_construction_sites && project.construction_sites.length === sites.length,
						title: "部分工地尚未完成測量",
					},
					{
						key: 'fourPositioningWarning',
						validator: (project, sites, configs) => !(configs.report_types.includes('REPORT') || configs.report_types.includes('FOUR_PITCH_REPORT')) || ((configs.report_types.includes('REPORT') || configs.report_types.includes('FOUR_PITCH_REPORT')) && sites.every(site => !site.four_positioning_points_count || site.four_positioning_points_count && site.tp_system_four_positioning_data && site.four_positioning_points_count===site.tp_system_four_positioning_data.points_count && site.four_positioning_points_count===site.four_pitch_data.length && site.four_pitch_data.every(datum => datum.done))),
						title: "部分工地含有未匯入或未測量北水系統的四支距點位，請至「測量成果」匯入或至北水系統查詢",
					},
					{
						key: 'positioningPointsMatchingWarning',
						validator: (project, sites, configs) => !configs.report_types.includes('REPORT') || this.exportedPositioningPoints.every(p => p.point),
						title: "支距點未配對至測量點，請至「測量成果」設定",
						messager: (project, sites) => {
							return this.exportedPositioningPoints.filter(p => !p.point).mapValues('name').join(', ')
						}
					},
					{
						key: 'controlPointExaminationDataWarning',
						validator: (project, sites, configs) => !this.exportedReportValues.coordinate_examination_data || this.exportedReportValues.coordinate_examination_data && this.controlPointExaminationData,
						title: "查無可用之控制點校驗資料",
					},
				]
			},
			CONSTANT: {
				REPORT_TYPES: [
					{ text: "GML", value: "GML", export: () => this.exportGml() },
					{ text: "GML明細表", value: "GML_UTILITY_LIST", export: () => this.exportFacilityList() },
					{ text: "大地起伏表", value: "POINT_COORDINATES", export: () => this.exportPointCoordinates() },
					// { text: "其他報表(含四支距)", value: "REPORT", export: () => this.exportReport() },
					{ text: "其他報表(含四支距)", value: "REPORT", export: () => this.exportProjectReport() },
					{ text: "四支距報表", value: "FOUR_PITCH_REPORT", hide: () => this.exportedConstructionSites.every(site => !site.tp_system_four_positioning_data), export: () => this.exportFourPitchReport() },
				],
				PROJECT_IMAGES: [
					{ value: 'MATCH_POINT', text: '依配對之測量點生成', filter: images => {
						return {
							...images.filter(i => i.point).uniqBy('point.index').keyBy((img) => `pt${img.point.index}`),
							...images.filter(i => !i.point).uniqBy('content').keyBy((img, i) => i),
						}
					} },
					{ value: 'UNIQUE_IMAGE', text: '移除重複照片', filter: images => images.uniqBy('content').keyBy((img, i) => i) },
				],
				PROJECT_IMAGE_NAME_TYPES: [
					{ value: 'INDEX', text: '依照片流水號命名', format: (image, index) => index },
					{ value: 'NAME', text: '依檔案名稱命名', format: (image, index) => image.name.substring(0, image.name.lastIndexOf('.')) },
				],
			}
		}
	},
	created() {
		if(this.measuredConstructionSites.length) {
			this.init();
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
		exportedConstructionSites: {
			deep: true,
			immediate: true,
			handler(sites, oriSites) {
				if(sites != oriSites || !Object.isEqual(sites.mapValues('id'), oriSites.mapValues('id'))) {
					this.getControlPointExaminationData()
				}
			},
		},
		'formData.measuring_company': {
			handler(value) {
				let measuring_company = this.getUnitByType('measurer').find(u => u.full_name === value)
				if(measuring_company) {
					this.formData.measuring_company_tel = measuring_company.phone ? measuring_company.phone : ''
				}
			}
		}
	},
	computed: {
		measuredStatusIndexList() {
			return [
				'measured',
				'measurement_finished',
				'remeasured',
			].map(status => this.$store.getters.enum(`construction_site.status.${status}`).index)
		},
		measuredConstructionSites() {
			return this.project.construction_sites.filter(site => this.measuredStatusIndexList.includes(site.status_index))
		},
		measuredPoints() {
			return this.measuredConstructionSites.map(site => site.points).flat()
		},
		exportedConstructionSites() {
			return this.configs.seperate_construction_sites ? this.measuredConstructionSites.filter(site => this.configs.exported_construction_sites.includes(site.id)) : this.measuredConstructionSites;
		},
		exportedPoints() {
			let removePointsByNames = function(points, names) {
				return points.filter(point => !names.includes(point.name)).map(point => {
					return {
						...point,
						form_data: Object.keys(point.form_data).reduce((obj, key) => {
							if(!(key.startsWith('連接點') && key.endsWith('_點位') && names.includes(point.form_data[key].value)))
								obj[key] = deepCopy(point.form_data[key])
							return obj
						}, {}),
					}
				})
			} 
			let exportedPoints = this.exportedConstructionSites.map(site => site.points).flat()
			if(this.configs.seperate_construction_sites) {
				if(this.configs.across_construction_sites) {
					let pointNames = exportedPoints.mapValues('name')
					let targetNames = exportedPoints.map(point => point.targets).flat().filter(name => !pointNames.includes(name))
					let targetPoints = this.measuredPoints.filter(point => targetNames.includes(point.name))
	
					let connectionNames = exportedPoints.map(point => point.connections).flat().filter(name => !pointNames.includes(name))
					let connectionPoints = this.measuredPoints.filter(point => connectionNames.includes(point.name))
					exportedPoints.push(...[
						...targetPoints,
						...connectionPoints,
					])
				}
				let pointNames = exportedPoints.mapValues('name')
				let targetNames = exportedPoints.map(point => point.targets).flat().filter(name => !pointNames.includes(name))
				exportedPoints = removePointsByNames(exportedPoints, targetNames)
			}
			if(this.configs.remove_private_point) {
				let privatePointNames = this.measuredPoints.filter(point => IsPointOnPrivateLand(point.transformed_data)).map(pt => pt.name)
				exportedPoints = removePointsByNames(exportedPoints, privatePointNames)
			}
			if(this.configs.remove_extant_point) {
				let extantPointNames = this.measuredPoints.filter(point => isPointExtantFacility(point.transformed_data)).map(pt => pt.name)
				exportedPoints = removePointsByNames(exportedPoints, extantPointNames)
			}
			sortArray(exportedPoints, 'index')
			return exportedPoints
		},
		exportedResectionPoints() {
			return this.exportedConstructionSites.map(site => site.resection_points).flat()
		},
		exportedFourPitchData() {
			return this.exportedConstructionSites.map(site => site.four_pitch_data.filter(datum => datum.done)).flat()
		},
		exportedPositioningPoints() {
			return this.exportedConstructionSites.map(site => site.positioning_points).flat()
		},
		hasProjectImages() {
			return !!Object.values(this.exportedProjectImages).map(images => Object.values(images)).flat().length
		},
		isProjectImagesMatchedPoints() {
			return Object.values(this.exportedProjectImages).map(images => Object.values(images)).flat().some(i => i.point)
		},
		exportedProjectImages() {
			let imageFilter = this.CONSTANT.PROJECT_IMAGES.find(type => type.value === this.configs.project_images)
			return Object.map(this.getProjectImageTypes(), type => imageFilter.filter(this.exportedConstructionSites.mapValues(`data.${type.name}`).filter(o => o).flat()))
		},
		exportedProjectImageContents() {
			return Object.map(this.exportedProjectImages, images => Object.map(images, img => img.content))
		},
		exportedProjectImageContentList() {
			return Object.map(this.exportedProjectImageContents, images => Object.values(images))
		},
		exportedReportTypes() {
			return this.reportTypes.filter(type => this.configs.report_types.includes('REPORT') && this.configs.other_report_types.includes(type.key))
		},
		exportedReportValues() {
			return this.exportedReportTypes.mapValues('report_values').flat().uniqBy('key').keyBy('key')
		},
		errors() {
			return this.validator.errors.filter(error => !error.validator(this.project, this.exportedConstructionSites, this.configs)).map(error => {
				return {
					...error,
					messages: error.messager ? (typeof error.messager === 'function' ? error.messager(this.project, this.exportedConstructionSites, this.configs) : error.messager) : ''
				}
			})
		},
		warnings() {
			return this.validator.warnings.filter(error => !error.validator(this.project, this.exportedConstructionSites, this.configs)).map(error => {
				return {
					...error,
					messages: error.messager ? (typeof error.messager === 'function' ? error.messager(this.project, this.exportedConstructionSites, this.configs) : error.messager) : ''
				}
			})
		},
		initFormParams() {
			return {
				id: { key: 'id' },
				party_a: { key: 'party_a_name' },
				party_a_name: { key: 'party_a_full_name', value: (value, project) => value ? value : project.party_a_name },
				party_a_phone: { key: 'party_a.phone' },
				party_a_address: { key: 'party_a.address' },
				party_a_area: { key: 'party_a_branch_full_name' },
				pipeline_center: { key: 'pipeline_center_name' },
				bid: { key: 'bid_name' },
				bid_number: { key: 'bid.number' },
				construction: { key: 'construction_name' },
				address: { key: 'address' },
				excavation_permit: { key: 'excavation_permit_name' },
				constructed_date: { key: 'expected_constructed_at' }, // 施工日期
				assigned_date: { key: 'assigned_at' }, // 派工日期
				measured_date: { key: 'measured_at', show: true }, // 測量日期
				file_made_date: { key: 'file_made_at', show: true }, // 文件製作日期
				measuring_company: { key: 'bid.measurer_unit_id', value: (value) => {
					let unit = this.units.find(o=>o.id===value)
					return unit ? unit.full_name : undefined
				} }, // 測量公司
				measuring_company_tel: { key: 'bid.measurer_unit_id', value: (value) => {
					let unit = this.units.find(o=>o.id===value)
					return unit ? unit.phone : undefined
				} }, // 測量公司電話
				constructing_company: { key: 'contractor_full_name', value: (value, project) => !value && project.construction ? project.construction.contractor_full_name : value }, // 施工廠商
				constructing_manager: { key: 'construction.contractor_manager_name' }, // 施工廠商負責人
				pipeline_unit_member: { key: 'commissioner_name', show: true }, // 管線單位人員
				supervisor: { key: 'supervisor_name', show: true }, // 管線單位人員
				pipeline_unit_name: { key: 'party_a_branch_full_name' }, // 管線單位名稱
			}
		},
		formOptions() {
			return [
				{ key: "party_a", type: "select", label: "甲方", options: this.getUnitByType('party_a'), keys: {value: 'name', text: 'name'}, required: true, disabled: true },
				{ key: "party_a_area", type: "select", label: "甲方區處", options: this.partyAUnit ? this.partyAUnit.branches : [], keys: {value: 'full_name', text: 'name'}, required: true, disabled: !!this.project.party_a_branch_id },
				{ key: "pipeline_center", type: "text", label: "道管", required: true, disabled: true },
				{ key: "bid", type: "select", label: "標案", options: [{value: this.project.bid_name, text: (this.project.bid && this.project.bid.abbreviation?this.project.bid.abbreviation:this.project.bid_name) + (this.project.bid && this.project.bid.number?`（編號：${this.project.bid.number}）`:'')}], required: true, disabled: true, hide: !this.project.bid },
				{ key: "bid", type: "text", label: "標案", required: true, hide: this.project.bid },
				{ key: "bid_number", type: "text", label: "標案編號", required: true, hide: this.project.bid_number },
				{ key: "construction", type: "text", label: "工程", required: true },
				{ key: "measured_date", type: "text", label: "測量日期", required: true, disabled: true },
				{ key: "file_made_date", type: "date", label: "文件製作日期", required: true, disabled: !!this.data.file_made_date },
				{ key: "permit_granted_unit_number", type: "text", label: "發證單位代碼", placeholder: '請輸入2位大寫英文字母', invalidMessage: '請輸入2位大寫英文字母', pattern: '[A-Z]{2}', required: true, disabled: false },
				{ key: "pipeline_number", type: "number", label: "管線流水號", placeholder: '請輸入管線識別碼末6位數字（開頭0可省略）', required: true, disabled: false },
				{ key: "hole_number", type: "number", label: "人手孔流水號", placeholder: '請輸入人手孔識別碼末6位數字（開頭0可省略）', required: true, disabled: false },
				{ key: "other_facility_number", type: "number", label: "其他設施流水號", placeholder: '請輸入其他設施識別碼末6位數字（開頭0可省略）', required: true, disabled: false },
				{ key: "application_number", type: "text", label: "受理號碼", required: false, disabled: false },
				{ key: "user_name", type: "text", label: "用戶名稱", required: false, disabled: false },
				{ key: "measuring_company", type: "select", label: "測量公司", options: this.getUnitByType('measurer'), keys: {value: 'full_name', text: 'name'}, required: true },
				{ key: "measuring_company_tel", type: "text", label: "測量公司電話", required: true },
				{ key: "measuring_instrument", type: "select", label: "施測儀器", options: this.$store.getters.enum('coordinate.instrument'), keys: {value: 'data.name', text: 'text'}, required: true },
				{ key: "measurers[0]", type: "select", label: "測量人員1", options: this.measurerUnitMembers, keys: {value: 'name', text: 'name'}, required: true, hide: !this.measurerUnitMembers.length, hint: `共有 ${((this.measurerUnitMembers.find(['name', this.formData['measurers[0]']])??{}).signatures??[]).length} 張證照照片`, hintClass: { 'd-none': !this.reportParams.includes('measurers_certificate_images') } },
				{ key: "measurers[1]", type: "select", label: "測量人員2", options: this.measurerUnitMembers, keys: {value: 'name', text: 'name'}, required: false, hide: !this.measurerUnitMembers.length, hint: `共有 ${((this.measurerUnitMembers.find(['name', this.formData['measurers[1]']])??{}).signatures??[]).length} 張證照照片`, hintClass: { 'd-none': !this.reportParams.includes('measurers_certificate_images') } },
				{ key: "measurers[0]", type: "text", label: "測量人員1", required: true, hide: this.measurerUnitMembers.length },
				{ key: "measurers[1]", type: "text", label: "測量人員2", required: false, hide: this.measurerUnitMembers.length },
				{ key: "constructing_company", type: "text", label: "施工廠商", required: true },
				{ key: "layout_images", type: "file", label: "布點圖", required: false, multiple: true, accept: "image/*", preview: true, disabled: (this.exportedProjectImageContentList.layout_images ?? []).length && !this.formData.upload_layout_images },
				{ key: "upload_layout_images", type: "checkbox", label: " ", required: false, boolean: true, options: "自行上傳布點圖", hide: !(this.exportedProjectImageContentList.layout_images ?? []).length },
				{ key: "construction_plan_images", type: "file", label: "竣工平面圖", required: false, multiple: true, accept: "image/*", preview: true, disabled: (this.exportedProjectImageContentList.construction_plan_images ?? []).length && !this.formData.upload_construction_plan_images },
				{ key: "upload_construction_plan_images", type: "checkbox", label: " ", required: false, boolean: true, options: "自行上傳竣工平面圖", hide: !(this.exportedProjectImageContentList.construction_plan_images ?? []).length },
				{ key: "traffic_images", type: "file", label: "交維照片(施工前)", required: false, multiple: true, accept: "image/*", preview: true, disabled: (this.exportedProjectImageContentList.traffic_images ?? []).length && !this.formData.upload_traffic_images },
				{ key: "upload_traffic_images", type: "checkbox", label: " ", required: false, boolean: true, options: "自行上傳交維照片", hide: !(this.exportedProjectImageContentList.traffic_images ?? []).length },
				{ key: "pavement_cutting_images", type: "file", label: "路面切割照(施工中)", required: false, multiple: true, accept: "image/*", preview: true, disabled: (this.exportedProjectImageContentList.pavement_cutting_images ?? []).length && !this.formData.upload_pavement_cutting_images },
				{ key: "upload_pavement_cutting_images", type: "checkbox", label: " ", required: false, boolean: true, options: "自行上傳深度照片", hide: !(this.exportedProjectImageContentList.pavement_cutting_images ?? []).length },
				{ key: "depth_images", type: "file", label: "深度照片", required: false, multiple: true, accept: "image/*", preview: true, disabled: (this.exportedProjectImageContentList.depth_images ?? []).length && !this.formData.upload_depth_images },
				{ key: "upload_depth_images", type: "checkbox", label: " ", required: false, boolean: true, options: "自行上傳深度照片", hide: !(this.exportedProjectImageContentList.depth_images ?? []).length },
				{ key: "patching_images", type: "file", label: "臨時修復照(施工後)", required: false, multiple: true, accept: "image/*", preview: true, disabled: (this.exportedProjectImageContentList.patching_images ?? []).length && !this.formData.upload_patching_images },
				{ key: "upload_patching_images", type: "checkbox", label: " ", required: false, boolean: true, options: "自行上傳臨時修復照", hide: !(this.exportedProjectImageContentList.patching_images ?? []).length },
				{ key: "pipeline_position_images", type: "file", label: "路線成果圖", required: false, multiple: true, accept: "image/*", preview: true, disabled: (this.exportedProjectImageContentList.pipeline_position_images ?? []).length && !this.formData.upload_pipeline_position_images },
				{ key: "upload_pipeline_position_images", type: "checkbox", label: " ", required: false, boolean: true, options: "自行上傳路線成果圖", hide: !(this.exportedProjectImageContentList.pipeline_position_images ?? []).length },
				{ key: "pipeline_unit_member", type: "select", label: "管線單位人員(電子章)", required: false, emptyOption: true, options: this.partyAUnitMembers, keys: {value: 'name', text: 'name'}, hide: this.formData.upload_signature_pipeline_unit_member, hint: `共有 ${((this.pipelineUnitMember??{}).signatures??[]).length} 個電子章`, hintClass: `text-${['danger', 'warning', 'success'][((this.pipelineUnitMember??{}).signatures??[]).length > 2 ? 2 : ((this.pipelineUnitMember??{}).signatures??[]).length]}` },
				{ key: "pipeline_unit_member_signature", type: "file", label: "管線單位人員(電子章)", required: false, accept: "image/*", hide: !this.formData.upload_signature_pipeline_unit_member },
				{ key: "upload_signature_pipeline_unit_member", type: "checkbox", label: " ", required: false, boolean: true, options: "自行上傳管線單位人員電子章" },
				{ key: "supervisor", type: "select", label: "監造人員(電子章)", required: false, emptyOption: true, options: this.supervisors, keys: {value: 'name', text: 'name'}, hide: this.formData.upload_signature_supervisor, hint: `共有 ${((this.supervisor??{}).signatures??[]).length} 個電子章`, hintClass: `text-${['danger', 'warning', 'success'][((this.supervisor??{}).signatures??[]).length > 2 ? 2 : ((this.supervisor??{}).signatures??[]).length]}` }, 
				{ key: "supervisor_signature", type: "file", label: "監造人員(電子章)", required: false, accept: "image/*", hide: !this.formData.upload_signature_supervisor },
				{ key: "upload_signature_supervisor", type: "checkbox", label: " ", required: false, boolean: true, options: "自行上傳監造人員電子章" },
				{ key: "inspector", type: "select", label: "檢核人員(電子章)", required: false, emptyOption: true, options: this.inspectors, keys: {value: 'name', text: 'name'}, hide: this.formData.upload_signature_inspector, hint: `共有 ${((this.inspector??{}).signatures??[]).length} 個電子章`, hintClass: `text-${['danger', 'warning', 'success'][((this.inspector??{}).signatures??[]).length > 2 ? 2 : ((this.inspector??{}).signatures??[]).length]}` }, 
				{ key: "inspector_signature", type: "file", label: "檢核人員(電子章)", required: false, accept: "image/*", hide: !this.formData.upload_signature_inspector },
				{ key: "upload_signature_inspector", type: "checkbox", label: " ", required: false, boolean: true, options: "自行上傳檢核人員電子章" },
				{ key: "pipeline_unit_name_signature", type: "file", label: "管線單位(電子章)", required: false, accept: "image/*", hide: !this.formData.upload_signature_unit_name },
				{ key: "upload_signature_unit_name", type: "checkbox", label: " ", required: false, boolean: true, options: "自行上傳管線單位電子章" },
				{ key: "measuring_company_signature", type: "file", label: "測量公司(電子章)", required: false, accept: "image/*", hide: !this.formData.upload_signature_measuring_company },
				{ key: "upload_signature_measuring_company", type: "checkbox", label: " ", required: false, boolean: true, options: "自行上傳測量公司電子章" },
				{ key: "technician_entrusting_date", type: "date", label: "技師委託日期", required: true },
				{ key: "technician_signing_date", type: "date", label: "技師簽章日期", required: true },
			].filter(option => this.reportParams.includes(option.key) && (this.data[option.key] === undefined || !this.initFormParams[option.key] || this.initFormParams[option.key].show) && (typeof option.hide === 'function' ? !option.hide() : !option.hide))
		},
		configOptions() {
			return [
				{ key: "seperate_construction_sites", type: "checkbox", label: " ", options: "僅下載工地報表", required: true, boolean: true },
				{ key: "exported_construction_sites", type: "checkbox", label: "選擇工地", required: true, options: this.measuredConstructionSites, keys: {text: 'name', value: 'id'}, hide: !this.configs.seperate_construction_sites },
				{ key: "report_types", type: "checkbox", label: "報表種類", required: true, options: () => this.CONSTANT.REPORT_TYPES.filter(r => !r.hide || !r.hide()) },
				{ key: "other_report_types", type: "checkbox", label: "其他報表種類", required: true, options: this.reportTypes, keys: {text: 'name', value: 'key'}, hide: !this.configs.report_types.includes('REPORT') },
				{ key: "across_construction_sites", type: "checkbox", label: "報表/GML點位", required: true, options: "包含其餘工地連接點", boolean: true, hide: !this.configs.seperate_construction_sites },
				// { key: "remove_repeated_point", type: "checkbox", label: "報表點位", required: true, options: "移除重複點位", boolean: true, hide: !this.configs.report_types.includes('REPORT') },
				{ key: "remove_private_point", type: "checkbox", label: "報表點位", required: true, options: "移除私地點位", boolean: true, hide: !this.configs.report_types.includes('REPORT') && !this.configs.report_types.includes('GML') && !this.configs.report_types.includes('GML_UTILITY_LIST') },
				{ key: "remove_extant_point", type: "checkbox", label: "報表點位", required: true, options: "移除既有設施物點位", boolean: true, hide: !this.configs.report_types.includes('REPORT') && !this.configs.report_types.includes('GML') && !this.configs.report_types.includes('GML_UTILITY_LIST') },
				{ key: "project_images", type: "radio", label: "案件照片", required: false, options: this.CONSTANT.PROJECT_IMAGES, hide: !this.configs.report_types.includes('REPORT') || !this.hasProjectImages },
				{ key: "project_image_name_type", type: "radio", label: "案件照片名稱", required: false, options: this.CONSTANT.PROJECT_IMAGE_NAME_TYPES, hide: !this.configs.report_types.includes('REPORT') },
			].filter(option => this.configParams.includes(option.key))
		},
		configParams() {
			const PARAMS = {
				common: [
					'seperate_construction_sites',
					'exported_construction_sites',
					'report_types',
					'other_report_types',
					'across_construction_sites',
					'project_images',
					'project_image_name_type',
				],
				key: {
					party_a: {
						// 中華電信: [ 'remove_repeated_point' ],
						台水一區: [ 'remove_private_point' ],
						台水二區: [ 'remove_private_point' ],
						台水三區: [ 'remove_private_point' ],
						台水五區: [ 'remove_private_point' ],
						台水十二區: [ 'remove_private_point' ],
						台水屏東區: [ 'remove_private_point' ],
						'衛工處-污水': [ 'remove_extant_point' ],
					},
				},
			}
			return [
				...PARAMS.common ? PARAMS.common : [],
				...PARAMS.key ? Object.keys(PARAMS.key).map(keys => {
					let data_key = keys.split('|').map(k => this.formData[k]).join('|')
					return PARAMS.key[keys][data_key] ? PARAMS.key[keys][data_key] : (PARAMS.key[keys].default ? PARAMS.key[keys].default : [])
				}).flat() : []
			]
		},
		reportParams() {
			const GML_PROJECT_KEYS = GetGmlProjectKeys()
			const PARAMS = {
				GML: {
					common: Object.values(GML_PROJECT_KEYS).filter(param => param.required).mapValues('key').map(key => Array.isArray(key) ? key : [key]).flat(),
					key: {
						'party_a|pipeline_center': {
							'中華電信|新北市': [
								'permit_granted_unit_number',
								'pipeline_number',
								'hole_number',
								'other_facility_number',
							],
							'中華電信|基隆市': [
								'pipeline_number',
								'hole_number',
								'other_facility_number',
							],
						},
					}
				},
				REPORT: {
					common: [
						'measured_date',
						'file_made_date',
						"party_a",
						"party_a_area",
						"pipeline_center",
						"measuring_company",
						"measuring_company_tel",
						"measurers",
						"measurers[0]",
						"measurers[1]",
						"measuring_instrument",
						"supervisor",
						"supervisor_signature",
						"upload_signature_supervisor",
						"pipeline_unit_name",
						"pipeline_unit_name_signature",
						"upload_signature_unit_name",
					],
					key: {
						party_a: {
							臺北自來水: [
								"bid",
								"bid_number",
								"construction",
								"measuring_company_signature",
								"upload_signature_measuring_company",
							],
							中華電信: [
								"constructing_company",
							],
							台水屏東區: [
								"application_number",
								"user_name",
								"supervisor",
								"supervisor_signature",
								"upload_signature_supervisor",
								"pipeline_unit_name",
								"pipeline_unit_name_signature",
								"upload_signature_unit_name",
							],
							default: [
								"construction",
								"constructing_company",
							]
						},
						pipeline_center: {
							臺南市: [
								// "pipeline_unit_member",
								// "pipeline_unit_member_signature",
								// "upload_signature_pipeline_unit_member",
								// "measurers_certificate_images",
							],
							高雄市: [
								"constructing_manager",
								"constructing_manager_signature",
							],
							嘉義市: [
								'inspector',
								'inspector_signature',
								'upload_signature_inspector',
								"measuring_company_signature",
								"upload_signature_measuring_company",
							],
							雲林縣: [
								"measuring_company_signature",
								"upload_signature_measuring_company",
							],
							default: [],
						},
					}
				},
				FOUR_PITCH_REPORT: {
					common: ["bid", "construction", "bid_number"],
				},
			}
			let reports = this.configs.report_types
			return [...new Set(reports.map(report => {
				return [
					...PARAMS[report] && PARAMS[report].common ? PARAMS[report].common : [],
					...PARAMS[report] && PARAMS[report].key ? Object.keys(PARAMS[report].key).map(keys => {
						let data_key = keys.split('|').map(k => this.formData[k]).join('|')
						return PARAMS[report].key[keys][data_key] ? PARAMS[report].key[keys][data_key] : (PARAMS[report].key[keys].default ? PARAMS[report].key[keys].default : [])
					}).flat() : [],
					...Object.keys(this.getProjectImageTypes()),
					...Object.keys(this.getProjectImageTypes()).map(key => `upload_${key}`),
				]
			}).flat())]
		},
		signatureParams() {
			let random = (signatures) => signatures.mapValues('content').randomTake(1).first
			return [
				{
					key: 'measuring_company', signature_key: 'measuring_company_signature',
					value: (value) => random(this.measurerUnit.signatures)
				},
				{
					key: 'party_a_area', signature_key: 'pipeline_unit_name_signature',
					value: (value) => random(this.partyAUnitBranch.signatures)
				},
				{
					key: 'pipeline_unit_member', signature_key: 'pipeline_unit_member_signature',
					value: (value) => random(this.pipelineUnitMember ? this.pipelineUnitMember.signatures : [])
				},
				{
					key: 'supervisor', signature_key: 'supervisor_signature',
					value: (value) => random(this.supervisor ? this.supervisor.signatures : [])
				},
				{
					key: 'inspector', signature_key: 'inspector_signature',
					value: (value) => random(this.inspector ? this.inspector.signatures : [])
				},
				{
					key: 'measurers', signature_key: 'measurers_certificate_images',
					value: (value) => {
						return value.map(measurer => {
							let member = this.measurerUnitMembers.find(['name', measurer])
							return member ? (member.signatures ?? []).mapValues('content') : []
						})
					},
				},
				{
					key: 'constructing_manager', signature_key: 'constructing_manager_signature',
					value: (value) => random(this.constructionManager ? this.constructionManager.signatures : [])
				},
			].filter(param => this.reportParams.includes(param.key) && this.reportParams.includes(param.signature_key))
		},
		partyAUnit() {
			return this.units.find(u => u.name === this.formData.party_a)
		},
		partyAUnitBranch() {
			return this.partyAUnit ? this.partyAUnit.branches.find(b => b.full_name === this.formData.party_a_area) : undefined
		},
		partyAUnitMembers() {
			return [
				...this.partyAUnit ? this.partyAUnit.members : [],
				...this.partyAUnitBranch ? this.partyAUnitBranch.members : [],
			]
		},
		supervisors() {
			return this.partyAUnitMembers.filter(m => m.type_key === 'supervisor')
		},
		inspectors() {
			return this.partyAUnitMembers.filter(m => m.type_key === 'inspector')
		},
		pipelineUnitMember() {
			return this.partyAUnitMembers.find(m => m.name === this.formData.pipeline_unit_member)
		},
		supervisor() {
			return this.partyAUnitMembers.find(m => m.name === this.formData.supervisor)
		},
		inspector() {
			return this.partyAUnitMembers.find(m => m.name === this.formData.inspector)
		},
		measurerUnit() {
			return this.units.find(u => u.full_name === this.formData.measuring_company)
		},
		measurerUnitMembers() {
			return this.project.bid ? this.project.bid.measurer_unit_members : []
		},
		contractorUnit() {
			return this.units.find(u => u.full_name === this.formData.constructing_company)
		},
		constructionMembers() {
			return this.contractorUnit ? this.contractorUnit.members : []
		},
		constructionManager() {
			return this.constructionMembers.find(m => m.name === this.formData.constructing_manager)
		},
		controlPointExaminationData() {
			this.call.getControlPointExaminationData;
			let site = this.exportedConstructionSites.sortBy('result.created_at').last
			if(site.examination_data === undefined) {
				this.getControlPointExaminationData()
			}
			return site.examination_data
		},
		gmlGenerator() {
			return new GmlGenerator(this.formData, this.exportedPoints.map(point => {
				return {
					index: point.index,
					name: point.name,
					...point.coordinate,
					...point.form_data,
				}
			}));
		},
		reportFilenameTag() {
			return [
				(this.configs.seperate_construction_sites ? `(${this.exportedConstructionSites.first.name})` : ''),
				(this.configs.remove_private_point ? '(不含私地)' : ''),
				(this.configs.remove_extant_point ? '(不含既有設施物)' : ''),
			].join('')
		},
		reportFilename() {
			const FORMAT = {
				key: {
					party_a: {
						中華電信: 'serial_number.excavation_permit_name.work_order_number.address',
						default: 'excavation_permit_name.address',
					},
				},
			}
			let format = Object.keys(FORMAT.key).map(keys => {
				let data_key = keys.split('|').map(k => this.formData[k]).join('|')
				return FORMAT.key[keys][data_key] ? FORMAT.key[keys][data_key] : (FORMAT.key[keys].default ? FORMAT.key[keys].default : 'excavation_permit_name._.address')
			}).first
			return format.split('.').map(key => {
				switch(true) {
					case Object.keys(this.project).includes(key): 
						return this.project[key]
					default:
						return key;
				}
			}).filter(o => o).join('_') + this.reportFilenameTag
		},
		reportPoints() {
			let points = this.exportedPoints.reduce((obj, point) => {
				// if(!IsFacilityType(point.transformed_type.type) && this.configs.remove_repeated_point) {
				if(!IsFacilityType(point.transformed_type.type)) {
					let same_coordinate_points = this.measuredPoints.filter(p => p.point_name === point.point_name)
					let pt = same_coordinate_points[0]
					if(pt.name === point.name) {
						obj[point.id] = {
							...pt,
							targets: [...new Set(same_coordinate_points.map(pt => pt.targets.map(name => {
								let p = this.measuredPoints.find(p => p.name === name)
								return p ? p.point_name : ''
							})).flat())].filter(p => p && p !== point.point_name)
						}
					}
					else if(point.transformed_type.type !== point.transformed_type.category) {
						obj[pt.id] = {
							...obj[pt.id],
							transformed_type: point.transformed_type,
						}
					}
					if(point.name !== point.point_name) {
						let coordinate_point = this.measuredPoints.find(p => p.name === point.point_name)
						obj[point.id] = {
							...point,
							...obj[point.id],
							data: coordinate_point && coordinate_point.data ? coordinate_point.data : [],
						}
					}
				}
				else {
					obj[point.id] = point
				}
				return obj
			}, {})
			return Object.values(points)
		},
		reportPositioningPoints() {
			return [
				...this.exportedPositioningPoints,
				...this.exportedFourPitchData,
			]
		},
		reportGmlUtilities() {
			let gmlGenerator = this.gmlGenerator;
			let utilities = gmlGenerator.checkValid() ? gmlGenerator.getGmlUtilities() : []
			return utilities
		},
		reportValues() {
			let keys = Object.keys(this.exportedReportValues)
			const params = {
				number: 'control_point.number',
				X: 'coordinate.twd97_x',
				Y: 'coordinate.twd97_y',
				orthometric_height: 'coordinate.orthometric_height',
				ellipsoidal_height: 'coordinate.ellipsoidal_height',
				'control_point.X': 'control_point.coordinate.twd97_x',
				'control_point.Y': 'control_point.coordinate.twd97_y',
				'control_point.orthometric_height': 'control_point.coordinate.orthometric_height',
				'control_point.ellipsoidal_height': 'control_point.coordinate.ellipsoidal_height',
			}
			return Object.filter({
				...Object.map(keys.keyBy(), v => undefined),
				gml_utilities: this.reportGmlUtilities.map(utility => {
					let mapKeys = (obj) => Object.mapKeys(obj, (v, k) => k.toSnakeCase())
					let func = (obj) => typeof obj === 'object' ? (Array.isArray(obj) ? obj.map(v => mapKeys(func(v))) : mapKeys(Object.map(obj, v => func(v)))) : obj
					return func(utility)
				}),
				coordinate_examination_data: Object.make(this.controlPointExaminationData, params),
			}, (value, key) => keys.includes(key))
		},
		reportData() {
			let points = this.reportPoints
			let positioningPoints = this.reportPositioningPoints
			let formData = Object.deserialize(this.formData)
			return {
				id: this.project.id,
				name: this.reportFilename,
				...formData,
				...Object.map(this.exportedProjectImageContents, (images, key) => {
					images = this.formData[`upload_${key}`] || !Object.size(images) ? this.formData[key] : images
					let type = this.CONSTANT.PROJECT_IMAGE_NAME_TYPES.find(type => type.value === this.configs.project_image_name_type)
					return Object.mapKeys(images, (img, key) => Number.isInteger(Number(key)) ? type.format(img, key) : key)
				}),
				...Object.map(this.signatureParams.keyBy('signature_key'), (param, key) => formData[key] ?? param.value(formData[param.key])),
				pipeline_length: this.gmlGenerator.getPipelineLength(),
				point_count: GetPointUtilityCategoriesCount(points.map(p => p.transformed_type)),
				points: points.map(point => {
					const params = {
						name: 'point_name',
						index: 'coordinate_name',
						ori_name: 'name',
						ori_index: 'index',
						type: 'transformed_type.type',
						category: 'transformed_type.category',
						// detail_type: 'transformed_type.featureCategory',
						X: 'transformed_data.X',
						Y: 'transformed_data.Y',
						orthometric_height: 'transformed_data.orthometric_height',
						ellipsoidal_height: 'transformed_data.ellipsoidal_height',
						facility_height: 'transformed_data.facility_height',
						depth: 'transformed_data.depth',
						diameter: 'transformed_data.pipeline_width1',
						number: 'transformed_data.feature_number',
						identify: 'transformed_data.identify_code',
						facility_number: {
							key: 'connections',
							value: (connections) => {
								let name = connections.find(p => p === point.point_name)
								let connection = points.find(p => p.name === name)
								return connection ? connection.transformed_data.feature_number : undefined
							}
						},
						targets: {
							key: 'targets',
							value: (targets) => {
								return targets.filter(p => p !== point.point_name).map(name => {
									let target = points.find(p => p.name === name)
									if(target && !IsFacilityType(target.transformed_type.type)) {
										target = this.exportedPoints.find(p => p.name === target.point_name)
									}
									return target ? {
										name: target.name,
										index: target.coordinate_name,
										distance: Number(GetPointDistance(point.transformed_data, target.transformed_data)).toFixed(3),
									} : undefined
								}).filter(o => o)
							}
						},
						// images
						...Object.map(this.getPointImageTypesByPointType('point'), type => `data.${type.name}.content`),
					}
					return resetObject(point, params)
				}),
				resection_points: this.exportedResectionPoints.map(pt => {
					return {
						name: pt.point_name,
						index: pt.index,
						X: pt.coordinate.twd97_x,
						Y: pt.coordinate.twd97_y,
						orthometric_height: pt.coordinate.orthometric_height,
						ellipsoidal_height: pt.coordinate.ellipsoidal_height,
						// images
						...Object.filter(Object.map(this.getPointImageTypesByPointType('resection_point'), type => Object.get(pt, `data.${type.name}.content`, undefined)), o => o),
					}
				}),
				positioning_points: positioningPoints.map(point => {
					const params = {
						index: 'index',
						point_name: 'point.point_name',
						X: 'point.transformed_data.X',
						Y: 'point.transformed_data.Y',
						orthometric_height: 'point.transformed_data.orthometric_height',
						ellipsoidal_height: 'point.transformed_data.ellipsoidal_height',
						facility_height: 'point.transformed_data.facility_height',
						depth: 'point.transformed_data.depth',
						number: 'point.transformed_data.feature_number',
						hole_depth: 'point.transformed_data.hole_depth',
						hole_width: 'point.transformed_data.width',
						four_positioning_point_type: ['type_text', 'point.transformed_type.type'],
						point_1_type: [ 'point_1_type', 'transformed_data.point_1_type' ],
						point_1_distance: [ 'point_1_distance', 'transformed_data.point_1_distance' ],
						point_1_image: [ 'point_1_image', 'transformed_data.point_1_image' ],
						point_1_original_image: [ 'transformed_data.point_1_original_image' ],
						point_2_type: [ 'point_2_type', 'transformed_data.point_2_type' ],
						point_2_distance: [ 'point_2_distance', 'transformed_data.point_2_distance' ],
						point_2_image: [ 'point_2_image', 'transformed_data.point_2_image' ],
						// point_2_original_image: [ 'point_2_original_image' ],
						point_3_type: [ 'point_3_type', 'transformed_data.point_3_type' ],
						point_3_distance: [ 'point_3_distance', 'transformed_data.point_3_distance' ],
						point_3_image: [ 'point_3_image', 'transformed_data.point_3_image' ],
						// point_3_original_image: [ 'point_3_original_image' ],
						point_3_x_distance: [ 'point_3_x_distance', 'transformed_data.point_3_x_distance' ],
						point_3_x_image: [ 'point_3_x_image', 'transformed_data.point_3_x_image' ],
						// point_3_x_original_image: [ 'point_3_x_original_image' ],
						point_3_y_distance: [ 'point_3_y_distance', 'transformed_data.point_3_y_distance' ],
						point_3_y_image: [ 'point_3_y_image', 'transformed_data.point_3_y_image' ],
						// point_3_y_original_image: [ 'point_3_y_original_image' ],
						modified_positioning_pipeline_image: 'pipeline_graph_image',
					};
					const images = Object.map(this.getPointImageTypesByPointType('positioning_point'), (type, key) => {
						let keys = [ `data.${type.name}.content` ]
						if(Array.isArray(params[key])) {
							keys.concat(params[key])
						}
						else if(params[key]) {
							keys.push(params[key])
						}
						return keys
					});
					return resetObject(point, { ...params, ...images })
				}),
				...this.reportValues,
			}
		},
	},
	methods: {
		init() {
			this.initFormData();
			this.setData();
			this.initConfigs();
		},
		setData() {
			this.formData['measuring_instrument'] = this.$store.getters.enum('coordinate.instrument.F90').data.name
			if(this.measurerUnitMembers.length >= 2) {
				let measurers = this.measurerUnitMembers.mapValues('name').shuffle()
				this.formData['measurers[0]'] = measurers[0]
				this.formData['measurers[1]'] = measurers[1]
			}
		},
		initFormData() {
			const PARAMS = this.initFormParams
			this.data = Object.assign(this.data, resetObject(this.project, PARAMS))
			this.formData = Object.assign(this.formData, deepCopy(this.data))
		},
		initConfigs() {
			const initConfigs = {
				exported_construction_sites: this.measuredConstructionSites.mapValues('id'),
				report_types: ['GML', 'GML_UTILITY_LIST', 'POINT_COORDINATES', 'REPORT'],
				project_images: 'MATCH_POINT',
				project_image_name_type: 'INDEX',
				// remove_repeated_point: true,
				remove_extant_point: true,
				across_construction_sites: true,
				other_report_types: this.reportTypes.mapValues('key'),
			}
			Object.keys(initConfigs).intersect(this.configParams).forEach(key => {
				this.configs[key] = initConfigs[key]
			})
		},
		getUnitByType(type) {
			return this.units.filter(o => o.type_key === type)
		},
		getPointImageTypeConfigsByPointType(pointType) {
			return this.exportedReportTypes.mapValues('point_image_types').flat().uniqBy('key').filter(type => type.type === pointType).keyBy('key')
		},
		getPointImageTypesByPointType(pointType) {
			const imageConfig = this.getPointImageTypeConfigsByPointType(pointType)
			return Object.map(imageConfig, imageType => this.projectDataType.find(type => type.name === imageType.text))
		},
		getProjectImageTypeConfigs() {
			return this.exportedReportTypes.mapValues('project_image_types').flat().uniqBy('key').keyBy('key')
		},
		getProjectImageTypes() {
			const imageConfig = this.getProjectImageTypeConfigs()
			return Object.filter(Object.map(imageConfig, imageType => this.projectDataType.find(type => type.name === imageType.text)), o => o)
		},
		getControlPointExaminationData() {
			let site = this.exportedConstructionSites.sortBy('result.created_at').last
			if(!(site.examination_data ?? false)) {
				this.$set(this.call, 'getControlPointExaminationData', false)
				let datum = {
					measured_at: site.result.created_at,
					lat: site.lat,
					lng: site.lng,
					users_id: site.result.users.mapValues('id'),
					city: this.project.pipeline_center_name,
				}
				this.$axios.getControlPointExaminationData(datum, (response) => {
					site.examination_data = response.data
					this.call.getControlPointExaminationData = true
				}, (error) => {
					this.call.getControlPointExaminationData = true
				})
			}
		},
		submitForm(e) {
			e.preventDefault();
			if(!this.$refs.configForm.reportValidity()) return
			if(this.errors.length) {
				alert(this.errors.map(e=>e.title).join("\n"))
				return
			}
			this.configs.report_types.forEach(report_key => {
				let report = this.CONSTANT.REPORT_TYPES.find(type => type.value === report_key)
				report.export()
			})
		},
		exportGml() {
			let gmlGenerator = this.gmlGenerator;
			let gmls = gmlGenerator.checkValid() ? gmlGenerator.getGml(this.gmlVersions) : {}
			Object.forEach(gmls, gml => {
				let filename = gml.name + this.reportFilenameTag
				fileManager.saveFile(filename, "gml", gml.content, gml.configs)
			})
		},
		exportFacilityList() {
			let pipelineLength = this.gmlGenerator.getPipelineLengthByInfo()
			let pointCount = GetPointUtilityCategoriesCount(this.reportPoints.map(p => p.transformed_type))
			let utilityCategories = GetFacilityUtilityCategories()
			let content = [
				`GML管線總長：${this.gmlGenerator.getPipelineLength()} M`,
				...Object.values(Object.map(pipelineLength, (length, type) => {
					return [
						`  ${type}長度：`,
						...Object.values(Object.map(length, (l, key) => {
							return `\t${key}：${l} M`
						})),
					]
				})).flat(),
				'',
				`點位數量：${pointCount.point}`,
				...Object.values(Object.map(Object.filter(utilityCategories, (category, key) => pointCount[key.toLowerCase()]), (category, key) => {
					return `\t${category}：${pointCount[key.toLowerCase()]}`
				})),
			]
			fileManager.saveFile(`${this.reportFilename}_GML明細表${this.reportFilenameTag}`, "txt", content.join("\n"))
		},
		exportPointCoordinates() {
			this.exportPointCoordinatesCsv()
			this.exportPointCoordinatesForQgisCsv()
		},
		exportPointCoordinatesCsv() {
			this.$set(this.call, 'exportPointCoordinatesCsv', false)
			this.$axios.exportPointCoordinatesCsv({
				project_type: this.projectType,
				project_id: this.projectID,
			}, (response) => {
				fileManager.saveFile(`${this.reportFilename}_大地起伏表`, 'csv', response)
				this.call.exportPointCoordinatesCsv = true
			}, (error) => {
				this.call.exportPointCoordinatesCsv = true
			})
		},
		exportPointCoordinatesForQgisCsv() {
			this.$set(this.call, 'exportPointCoordinatesForQgisCsv', false)
			this.$axios.exportPointCoordinatesForQgisCsv({
				project_type: this.projectType,
				project_id: this.projectID,
			}, (response) => {
				fileManager.saveFile(`${this.reportFilename}_大地起伏表(QGIS專用)`, 'csv', response)
				this.call.exportPointCoordinatesForQgisCsv = true
			}, (error) => {
				this.call.exportPointCoordinatesForQgisCsv = true
			})
		},
		exportReport() {
			let data = this.reportData
			this.$set(this.call, 'exportReport', false)
			this.$axios.exportReport(data, (response, status) => {
				if(status === 201) {
					fileManager.saveFileFromUrl(response);
				}
				this.call.exportReport = true
			}, (error) => {
				this.call.exportReport = true
			})
		},
		exportProjectReport() {
			let data = this.reportData
			this.$set(this.call, 'exportProjectReport', false)
			this.$axios.exportProjectReport({
				project_type: this.projectType,
				project_id: this.projectID,
				report_types: this.configs.other_report_types,
				data: data,
			}, (response, status) => {
				if(status === 201) {
					fileManager.saveFileFromUrl(response);
				}
				this.call.exportProjectReport = true
			}, (error) => {
				this.call.exportProjectReport = true
			})
		},
		exportFourPitchReport() {
			let sites = this.exportedConstructionSites.filter(site => site.tp_system_four_positioning_data)
			this.$set(this.call, 'exportFourPitchReport', sites.reduce((obj, site, index) => {
				if(site.tp_system_four_positioning_data){
					obj[index] = false
				}
				return obj
			}, {}))
			sites.forEach((site, index) => {
				this.$axios.exportFourPitchReport(site.tp_system_four_positioning_data.project_id, (response, status) => {
					if(status === 201) {
						fileManager.saveFileFromUrl(response);
					}
					else if(status === 200) {
						alert(`四支距報表${response}`);
						console.warn(`四支距報表${response}`);
					}
					this.call.exportFourPitchReport[index] = true;
				}, (error) => {
					alert(error);
					this.call.exportFourPitchReport[index] = true;
				})
			})
		},
	}
}
</script>

<style>
</style>