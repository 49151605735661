import _ from "lodash";

// 判断参数是否是其中之一
export function oneOf (value, validList) {
	for (let i = 0; i < validList.length; i++) {
		if (value === validList[i]) {
			return true;
		}
	}
	return false;
}

export function camelcaseToHyphen (str) {
	return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

// For Modal scrollBar hidden
let cached;
export function getScrollBarSize (fresh) {
	if (fresh || cached === undefined) {
		const inner = document.createElement('div');
		inner.style.width = '100%';
		inner.style.height = '200px';

		const outer = document.createElement('div');
		const outerStyle = outer.style;

		outerStyle.position = 'absolute';
		outerStyle.top = 0;
		outerStyle.left = 0;
		outerStyle.pointerEvents = 'none';
		outerStyle.visibility = 'hidden';
		outerStyle.width = '200px';
		outerStyle.height = '150px';
		outerStyle.overflow = 'hidden';

		outer.appendChild(inner);

		document.body.appendChild(outer);

		const widthContained = inner.offsetWidth;
		outer.style.overflow = 'scroll';
		let widthScroll = inner.offsetWidth;

		if (widthContained === widthScroll) {
			widthScroll = outer.clientWidth;
		}

		document.body.removeChild(outer);

		cached = widthContained - widthScroll;
	}
	return cached;
}

// watch DOM change
export const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver || false;

//const SPECIAL_CHARS_REGEXP = /([\:\-\_]+(.))/g;
const MOZ_HACK_REGEXP = /^moz([A-Z])/;

function camelCase(name) {
	/*return name.replace(SPECIAL_CHARS_REGEXP, function(_, separator, letter, offset) {
		return offset ? letter.toUpperCase() : letter;
	}).replace(MOZ_HACK_REGEXP, 'Moz$1');*/
}
// getStyle
export function getStyle (element, styleName) {
	if (!element || !styleName) return null;
	styleName = camelCase(styleName);
	if (styleName === 'float') {
		styleName = 'cssFloat';
	}
	try {
		const computed = document.defaultView.getComputedStyle(element, '');
		return element.style[styleName] || computed ? computed[styleName] : null;
	} catch(e) {
		return element.style[styleName];
	}
}

// firstUpperCase
function firstUpperCase(str) {
	return str.toString()[0].toUpperCase() + str.toString().slice(1);
}
export {firstUpperCase};

// Warn
export function warnProp(component, prop, correctType, wrongType) {
	correctType = firstUpperCase(correctType);
	wrongType = firstUpperCase(wrongType);
	console.error(`[iView warn]: Invalid prop: type check failed for prop ${prop}. Expected ${correctType}, got ${wrongType}. (found in component: ${component})`);	// eslint-disable-line
}

function typeOf(obj) {
	const toString = Object.prototype.toString;
	const map = {
		'[object Boolean]'  : 'boolean',
		'[object Number]'   : 'number',
		'[object String]'   : 'string',
		'[object Function]' : 'function',
		'[object Array]'	: 'array',
		'[object Date]'	 : 'date',
		'[object RegExp]'   : 'regExp',
		'[object Undefined]': 'undefined',
		'[object Null]'	 : 'null',
		'[object Object]'   : 'object'
	};
	return map[toString.call(obj)];
}

// deepCopy
function deepCopy(data) {
	const t = typeOf(data);
	let o;

	if (t === 'array') {
		o = [];
	} else if ( t === 'object') {
		o = {};
	} else {
		return data;
	}

	if (t === 'array') {
		for (let i = 0; i < data.length; i++) {
			o.push(deepCopy(data[i]));
		}
	} else if ( t === 'object') {
		for (let i in data) {
			o[i] = deepCopy(data[i]);
		}
	}
	return o;
}

export {deepCopy};


// deepSet
function deepSet(obj1, obj2) {
	// for(let key in obj2) {
	// 	if(key in obj1 && typeof obj1[key] === 'object')
	// 		deepSet(obj1[key], obj2[key])
	// 	else if(key in obj1)
	// 		obj1[key] = obj2[key]
	// }
	return _.assign(obj1, obj2)
}
export {deepSet};



// scrollTop animation
export function scrollTop(el, from = 0, to, duration = 500) {
	if (!window.requestAnimationFrame) {
		window.requestAnimationFrame = (
			window.webkitRequestAnimationFrame ||
			window.mozRequestAnimationFrame ||
			window.msRequestAnimationFrame ||
			function (callback) {
				return window.setTimeout(callback, 1000/60);
			}
		);
	}
	const difference = Math.abs(from - to);
	const step = Math.ceil(difference / duration * 50);

	function scroll(start, end, step) {
		if (start === end) return;

		let d = (start + step > end) ? end : start + step;
		if (start > end) {
			d = (start - step < end) ? end : start - step;
		}

		if (el === window) {
			window.scrollTo(d, d);
		} else {
			el.scrollTop = d;
		}
		window.requestAnimationFrame(() => scroll(d, end, step));
	}
	scroll(from, to, step);
}


export function isEmpty(obj) {
	// null and undefined are "empty"
	if (obj == null || typeof obj === "undefined") return true;
	if (typeof obj === "number" || typeof obj === "string") return false;

	// Assume if it has a length property with a non-zero value
	// that that property is correct.
	if (obj.length > 0)	return false;
	if (obj.length === 0)  return true;

	// If it isn't an object at this point
	// it is empty, but it can't be anything *but* empty
	// Is it empty?  Depends on your application.
	if (typeof obj !== "object") return true;

	// Otherwise, does it have any properties of its own?
	// Note that this doesn't handle
	// toString and valueOf enumeration bugs in IE < 9
	var hasOwnProperty = Object.prototype.hasOwnProperty;
	for (var key in obj) {
		if (hasOwnProperty.call(obj, key)) return false;
	}

	return true;
}

export function sortArray(array, compare=null, reverse=1, deep=true, key_delim='.') {
	let getRecursiveValue = function(obj, key_str) {
		let keys = key_str.split(key_delim);
		let res = obj;
		while(keys.length) {
			let key = keys.shift();
			if(typeof res === 'undefined')
				break;
			res = res[key];
		}
		return res
	}
	let compareValues = function(a, b) {
		return a === b ? reverse : (a > b ? 1 : -1);
	}
	if(typeof compare === 'function')
		array.sort(compare)
	else if(typeof compare === 'string' && array.every(o=>typeof o === 'object')){
		// array.sort((a, b) => (deep ? (getRecursiveValue(a, compare) - getRecursiveValue(b, compare)) : a[compare] - b[compare]) * reverse)
		array.sort((a, b) => {
			let v1 = deep ? getRecursiveValue(a, compare) : a[compare];
			let v2 = deep ? getRecursiveValue(b, compare) : b[compare];
			return compareValues(v1, v2) * reverse;
		})
	}
	else
		array.sort((a, b) => {
			return compareValues(a, b) * reverse;
		});
	return array
}

export function checkAllTrue(check, pass_func=()=>{}) {
	let checkTrue = function(obj) {
		let res = true
		for(let key in obj) {
			if(typeof obj[key] === 'object')
				res = res && checkTrue(obj[key])
			else
				res = res && obj[key]
		}
		return res
	}
	let res = true
	res = res && checkTrue(check)
	if(res)
		pass_func()
	return !res
}

export function isEqual(a, b) {
	return _.isEqual(a, b)
}


export async function sleep(sec) {
	function wait(ms) {
		return new Promise(
			resolve => setTimeout(resolve, ms)
		);
	}
	await wait(sec);
}

export function getRecursiveValue(obj, key_str, key_delim='.') {
	let keys = key_str.split(key_delim);
	let res = obj;
	while(keys.length) {
		let key = keys.shift();
		if(typeof res === 'undefined' || res === null)
			break;
		res = res[key];
	}
	return res
}

export function readImageAsDataURL(file) {
	return new Promise((resolve) => {
		let reader = new FileReader();
		reader.onload = function (e) {
			resolve(e.target.result)
		}
		reader.readAsDataURL(file);
	});
}
export function dataUriToBlob(dataURI) {
	// convert base64/URLEncoded data component to raw binary data held in a string
	var byteString;
	if (dataURI.split(',')[0].indexOf('base64') >= 0)
		byteString = atob(dataURI.split(',')[1]);
	else
		byteString = unescape(dataURI.split(',')[1]);

	// separate out the mime component
	var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	// write the bytes of the string to a typed array
	var ia = new Uint8Array(byteString.length);
	for (var i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	return new Blob([ia], {type:mimeString});
}
export function loadImage(src) {
	return new Promise(resolve => {
		const image = new Image();
		image.addEventListener('load', () => {
			resolve(image);
		});
		image.src = src;
	});
}
/**
 * @param {File} image 
 */
export function resizeImage(image, config={}) {
	let resize = function (img, config) {
		config = {
			width: 1080, height: 1080, resolution: 0.8,
			...config
		}
		let height = img.width >= img.height ? (img.width > config.width ? img.height / img.width * config.width : img.height) : (img.height > config.height ? config.height : img.height);
		let width = img.width >= img.height ? (img.width > config.width ? config.width : img.width) : (img.height > config.height ? img.width / img.height * config.height : img.width);
		let canvas = document.createElement('canvas');
		let context = canvas.getContext('2d');
		canvas.style.display = 'none';
		canvas.width = width;
		canvas.height = height;
		context.drawImage(img, 0, 0, width, height);
		let blob = canvas.toDataURL('image/jpeg', config.resolution);
		return blob;
	};
	return new Promise(resolve => {
		readImageAsDataURL(image).then(src => {
			loadImage(src).then((img) => {
				resolve(dataUriToBlob(resize(img, config)));
			});
		});
	});
}

export function countItems(array) {
	return array.reduce((obj, item) => {
		obj[item] = obj[item] === undefined ? 1 : obj[item] + 1;
		return obj;
	}, {})
}

export function sortObjectKey(obj) {
	return Object.keys(obj).sort().reduce((res, key) => (res[key] = obj[key], res), {})
}

/**
 * 
 * @param {Object} data 
 * @param {Object} params 
 * 	{
 * 		`newKey`: {
 * 			key: [String, String[]], // oldKey
 * 			default: {any}
 * 			value: {function(value, data)}
 * 		}
 * 	}
 */
export function resetObject(data, params) {
	return Object.map(params, (param, key) => {
		let paramKey = Object.isObject(param) && param.key ? (param.key ?? key) : param
		let value = Array.isArray(paramKey) ? Object.get(data, paramKey.find(k => Object.has(data, k))) : Object.get(data, paramKey)
		return param.value ? param.value(value, data) : (value ?? param.default);
	})
}
