<template>
	<div v-if="!item.hide">
		<div v-if="item.children">
			<template v-for="(child, key) in item.children">
				<menu-item :item="child" :key="key"></menu-item>
			</template>
		</div>
		<div v-else>
			<b-list-group-item class="border-0 py-1">
				<router-link class="router-link" :to="item.path"><i class="fa-fw" :class="item.icon"></i> {{item.name}}</router-link>
			</b-list-group-item>
		</div>
	</div>
</template>

<script>
import MenuItem from '@/components/Menu/MenuItem.vue'
export default {
	name: 'MenuItem',
	components:{
		MenuItem
	},
	props: {
		item: {
			type: Object,
		}
	},
	data() {
		return {
		}
	},
	created() {
	},
	destroyed() {
	},
	mounted() {
	},
	computed: {
	},
	methods:{
	}
}
</script>

<style scoped>
a.router-link {
	color: #4c6b8a;
}
a.router-link:hover {
	text-decoration: none;
	color: #284a70;
	filter: drop-shadow(0 0 1px #0005);
}
</style>