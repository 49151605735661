import _ from "lodash";

(function (window) {
	Object.serialize = function (obj, splitByDot=false) {
		let serializeObject = function(obj, res={}, objKey='') {
			if(typeof obj === 'object') {
				_.forIn(obj, function(value, key) {
					serializeObject(value, res, objKey ? `${objKey}${splitByDot ? `.${key}` : `[${key}]`}` : key)
				});
			}
			else {
				res[objKey] = obj
			}
			return res
		}
		return serializeObject(obj)
	}
	Object.deserialize = function (obj) {
		let deserializeObject = function(obj) {
			let res = {};
			if(typeof obj === 'object') {
				_.forIn(obj, function(value, key) {
					_.set(res, key, value);
				});
			}
			else {
				res = obj
			}
			return res
		}
		return deserializeObject(obj)
	}
	Object.cloneDeep = function (obj) {
		return _.cloneDeep(obj)
	}
	Object.isEmpty = function (obj) {
		return _.isEmpty(obj)
	}
	Object.isObject = function (obj) {
		return typeof obj === 'object'
	}
	Object.size = function (obj) {
		return _.size(obj)
	}
	Object.has = function (obj, key) {
		return _.has(obj, key)
	}
	Object.get = function (obj, key, defaultValue) {
		return _.get(obj, key, defaultValue)
	}
	Object.set = function (obj, key, value, callback) {
		return _.setWith(obj, key, value, callback)
	}
	Object.isEqual = function (obj1, obj2) {
		return _.isEqual(obj1, obj2)
	}
	Object.diff = function(obj1, obj2) {
		let diff = {}
		Object.keys(obj2).forEach((key) => {
			if(!Object.isEqual(obj1[key], obj2[key])) {
				diff[key] = obj1[key]
			}
		})
		return diff
	}
	Object.map = function(obj, callback) {
		return _.mapValues(obj, callback);
	}
	Object.mapKeys = function(obj, callback) {
		return _.mapKeys(obj, callback);
	}
	Object.filter = function(obj, callback) {
		let filter = _.mapValues(obj, callback);
		return Object.keys(obj).reduce((o, key) => {
			if(filter[key]) o[key] = obj[key];
			return o;
		}, {});
	}
	Object.isEqual = function(obj1, obj2) {
		return _.isEqual(obj1, obj2);
	}
	Object.every = function(obj, callback) {
		return _.every(obj, callback);
	}
	Object.find = function(obj, callback) {
		return _.find(obj, callback);
	}
	Object.findLast = function(obj, callback) {
		return _.findLast(obj, callback);
	}
	Object.findKey = function(obj, callback) {
		return _.findKey(obj, callback);
	}
	Object.findLastKey = function(obj, callback) {
		return _.findLastKey(obj, callback);
	}
	Object.flatMap = function(obj, callback, depth) {
		return _.flatMapDepth(obj, callback, depth);
	}
	Object.forEach = function(obj, callback) {
		return _.forEach(obj, callback)
	}
	Object.merge = function(obj, object) {
		return _.merge(Object.cloneDeep(obj), object)
	}
	Object.make = function(data, params, strict=false) {
		data = Object.map(params, (param, key) => {
			let paramKey = Object.isObject(param) && param.key ? (param.key ?? key) : param
			let paramStrict = !!(param.strict ?? strict)
			let value = Array.isArray(paramKey) ? Object.get(data, paramKey.find(k => Object.has(data, k) && (!paramStrict || !!Object.get(data, k)))) : Object.get(data, paramKey)
			return param.value ? param.value(value, data) : (value ?? param.default);
		})
		let obj = {}
		Object.forEach(data, (value, key) => {
			Object.set(obj, key, value)
		})
		return obj
	}
}(window));
