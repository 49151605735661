<template>
	<b-form @submit="onSubmit">
		<b-card no-body class="border-0 control-board">
			<b-card-body>
				<b-tabs class="tabs-wrapper" fill active-nav-item-class="tab-title font-weight-bold" active-tab-class="tab-title" fluid>
					<b-tab class="tab-body" :title="unit_type.text" :active="key===0" v-for="(unit_type, key) in unit_types" :key="key">
						<input-checkbox :options="unit_report_types" :keys="{value: 'id', text: 'name'}" v-model="result[unit_type.index]" direction="column" ></input-checkbox>
					</b-tab>
				</b-tabs>
			</b-card-body>
		</b-card>
		<b-row class="mt-2 justify-content-center">
			<b-button class="form-btn submit-btn" type="submit" variant="primary">確認</b-button>
			<b-button class="form-btn cancel-btn" type="button" variant="primary" @click="cancel">取消</b-button>
		</b-row>
	</b-form>
</template>


<script>
import InputRadio from '@/components/Input/InputRadio';
import InputSelect from '@/components/Input/InputSelect';
import InputCheckbox from '@/components/Input/InputCheckbox';
import InputForm from '@/components/Input/InputForm';
import { isEmpty, checkAllTrue } from '@/utils/assist';

export default {
	name: 'UnitReportEditor',
	components: {
		InputRadio,
		InputSelect,
		InputForm,
		InputCheckbox,
	},
	props: {
		projectDataType: {
			type: Array,
			default: () => []
		},
		unitReports: {
			type: Array,
			default: () => []
		},
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			call: {},
			loading: true,
			result: {},
			unit_types: this.$store.getters.enum('unit.type'),
			unit_report_types: [],
			unit_report_status_list: [],
		}
	},
	created() {
		this.unit_report_types = this.projectDataType.filter(type => type.data_type_key === this.$store.getters.enum('project_data_type.data_type.unit_report').key)
		this.unit_report_status_list = this.$store.getters.enum('project_unit_report.status')
		this.result = Object.assign(this.result, this.unit_types.reduce((obj, unit_type) => {
			obj[unit_type.index] = this.unitReports.filter(report => report.unit_type_key === unit_type.key && report.status_index !== this.$store.getters.enum('project_unit_report.status.unnecessary').index).map(report => report.project_data_type_id)
			return obj
		}, {}))
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
		loading() {
			this.$emit('loading', this.loading)
			if(!this.loading) {
				alert('修改成功')
				this.$emit("submit")
			}
		}
	},
	mounted() {
	},
	computed: {
	},
	methods:{
		cancel() {
			this.$emit("cancel")
		},
		onSubmit(e) {
			e.preventDefault()
			let result = Object.keys(this.result).map(unit_type_index => {
				unit_type_index = parseInt(unit_type_index)
				return this.result[unit_type_index].map(data_type => {
					let unit_report = this.unitReports.find(report => report.unit_type_index === unit_type_index && report.project_data_type_id === data_type)
					return {
						...(unit_report ? { id: unit_report.id, status: unit_report.status_index } : {}),
						unit_type: unit_type_index,
						project_data_type_id: data_type,
					}
				})
			}).flat()

			let unnecessary_status = this.$store.getters.enum('project_unit_report.status.unnecessary')
			let updateData = [
				// 不需要的案件報告
				...this.unitReports.filter(unit_report => !result.find(report => report.id === unit_report.id) && unit_report.status_index !== unnecessary_status.index).map(unit_report => {
					return {
						id: unit_report.id,
						status: unnecessary_status.index
					}
				}),
				// 原本不需要，加回來的案件報告
				...result.filter(unit_report => unit_report.status === unnecessary_status.index).map(unit_report => {
					return {
						id: unit_report.id,
						status: this.$store.getters.enum('project_unit_report.status.uncommitted').index
					}
				})
			]
			// 新增的案件報告
			let newData = result.filter(report => !report.id)

			if(newData.length) {
				this.createMultiProjectUnitReports({
					project_id: this.projectID,
					project_type: this.projectType,
					user_id: this.$store.getters.currentUser.id,
					project_unit_reports: newData
				}, () => {
					if(updateData.length)
						this.updateMultiProjectUnitReports({project_unit_reports: updateData})
				})
			}
			else if(updateData.length)
				this.updateMultiProjectUnitReports({project_unit_reports: updateData})
		},
		createMultiProjectUnitReports(data, func=()=>{}) {
			this.$set(this.call, 'createMultiProjectUnitReports', false)
			this.$axios.createMultiProjectUnitReports(data, (response) => {
				func()
				this.call.createMultiProjectUnitReports = true;
			}, (error) => {
				func()
				this.call.createMultiProjectUnitReports = true;
			})
		},
		updateMultiProjectUnitReports(data) {
			this.$set(this.call, 'updateMultiProjectUnitReports', false)
			this.$axios.updateMultiProjectUnitReports(data, (response) => {
				this.call.updateMultiProjectUnitReports = true;
			}, (error) => {
				this.call.updateMultiProjectUnitReports = true;
			})
		},
	}
}
</script>

<style scoped>
.input {
	padding: 0 .5rem .5rem 1rem;
}
.tab-body {
	padding: .5rem 1rem;
	border: 1px solid #dee2e6;
	border-top: 0;
}
</style>
