<template>
	<loading v-if="loading"/>
	<div class="w-100" v-else>
		<bid-manager :partyAUnits="partyAUnits" :measurerUnits="measurerUnits" :bids="bids" @reload="reload"></bid-manager>
	</div>
</template>

<script>
import BidManager from '@/components/Bid/BidManager.vue'
import { deepCopy, checkAllTrue } from '@/utils/assist';
import Loading from '@/components/Loading/Loading.vue';

export default {
	components:{
		BidManager,
		Loading
	},
	props: {
		bidID: {
			default: 0
		},
		from: {
			default: null
		}
	},
	data() {
		return {
			call: { getBidList: false, getUnitList: false, getUnitMemberList: false },
			loading: true,
			units: [],
			unitMembers: [],
			bids: [],
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
	},
	created() {
		this.init();
	},
	mounted() {
	},
	computed: {
		unitList() {
			return this.units.map(unit => {
				return {
					...unit,
					members: this.unitMembers.filter(o => o.unit_id === unit.id),
				}
			})
		},
		partyAUnits() {
			return this.unitList.filter(unit => unit.type_key === "party_a")
		},
		measurerUnits() {
			return this.unitList.filter(unit => unit.type_key === "measurer")
		},
	},
	methods:{
		init() {
			// this.getPartyAUnitList();
			this.getUnitList()
		},
		reload() {
			this.init();
		},
		getUnitList() {
			this.call.getUnitList = false
			this.$axios.getUnitList({
				types_index: [
					this.$store.getters.enum("unit.type.party_a").index,
					this.$store.getters.enum("unit.type.measurer").index,
				],
			}, (response) => {
				this.units = response.data
				this.getUnitMemberList()
				this.getBidList()
				this.call.getUnitList = true
			}, (error) => {
				this.call.getUnitList = true
			})
		},
		getUnitMemberList() {
			this.call.getUnitMemberList = false
			this.$axios.getUnitMemberList({ units_id: this.measurerUnits.mapValues('id') }, (response) => {
				this.unitMembers = response.data
				this.call.getUnitMemberList = true
			}, (error) => {
				this.call.getUnitMemberList = true
			})
		},
		getBidList() {
			this.call.getBidList = false
			this.$axios.getBidList({
				less: 0,
				units_id: this.partyAUnits.mapValues('id'),
			}, (response) => {
				this.bids = response.data.sortBy('id', 'desc')
				this.call.getBidList = true
			}, (error) => {
				this.call.getBidList = true
			})
		},
	}
}
</script>

<style scoped>
.card-wrapper {
	padding: .5rem;
}
.card {
	height: 100%;
}
.tab-body {
	padding: .5rem 1rem;
	border: 1px solid #dee2e6;
	border-top: 0;
	height: 100%;
}
.tabs-wrapper {
	height: 100%;
}
.tabs-wrapper > .tab-content {
	height: 100%;
}
</style>