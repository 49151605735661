import JsZip from 'jszip' 
import { saveAs } from 'file-saver';

let fileManager = {
	readFileAsBlob: async function(url) {
		// new File(url);
		// let reader = new FileReader();
		// reader.onload = function (e) {
		// }
		// reader.readAsDataURL(url)
		let response = await fetch(url);
		let data = await response.blob();
		// let file = new File([data], url);
		return data;
	},
	saveFiles: function(filename, files, success=()=>{}, compress=true) {
		let zip = new JsZip();
		let folders = {}//zip.folder("folder");
		files.forEach((file) => {
			let folder = typeof folders[file.folder] === 'undefined' ? zip.folder(file.folder) : folders[file.folder]
			if(file.extension === 'jpeg') {
				file.extension = 'jpg'
			}
			folder.file(`${file.name}.${file.extension}`, this.readFileAsBlob(file.content));
		})
		zip.generateAsync({type:"blob"})
		.then(function(content) {
			// see FileSaver.js
			saveAs(content, `${filename ? filename : 'data'}.zip`);
			success()
		});
	},
	saveFile: function(filename, ext, content, config={}) {
		let link = document.createElement('a');
		let configs = {
			blob: true,
			bom: false,
			...config
		}
		const BOM = "\uFEFF";
		if(configs.bom)
			content = BOM + content

		let type = undefined;
		switch(ext) {
			case 'csv': type = 'text/csv'; break;
			case 'txt': type = 'text/plain'; break;
			case 'jpg':
			case 'jpeg':
				type = 'image/jpeg';
				break;
			case 'png': type = 'image/png'; break;
			case 'gif': type = 'image/gif'; break;
		}
		// 字元內容轉變成blob地址，產生對應url
		if(configs.blob)
			content = URL.createObjectURL(new Blob([content], { type: type}))

		if('download' in link) {
			link.download = `${filename ? filename : 'data'}.${ext}`;
			link.style.display = 'none';
			link.href = content;
			document.body.appendChild(link);
			link.click();
		}
		else
			alert('瀏覽器不支援')
		document.body.removeChild(link);
		if(configs.blob) {
			// 釋放url
			URL.revokeObjectURL(content);
		}
	},
	saveFileFromUrl: function(url, blank=false) {
		let link = document.createElement('a');
		if('download' in link) {
			link.download = url.split('/').pop();
			link.style.display = 'none';
			if(blank) {
				link.target = '_blank';
			}
			link.href = url;
			document.body.appendChild(link);
			link.click();
		}
		else
			alert('瀏覽器不支援')
		document.body.removeChild(link);
	},
	readFile: function(file, config={}, loadFunc=()=>{}, errFunc=()=>{}) {
		return new Promise(resolve => {
			const reader = new FileReader();
			reader.onload = function (e) {
				loadFunc(e.target.result)
			}
			reader.onerror = function (e) {
				errFunc(e)
			}
			let configs = {
				blob: false,
				encoding: "UTF-8",
				...config
			}
			let ext = file.name.split('.').pop().toLowerCase()
			switch(ext) {
				case "jpg":
				case "jpeg":
				case "png":
				case "gif":
					reader.readAsDataURL(file);
					break;
				case "csv":
				case "xml":
				case "gml":
				case "txt":
					if(configs.blob)
						reader.readAsDataURL(file);
					else {
						if(!["UTF-8", "BIG5", "UTF-16"].includes(configs.encoding.toUpperCase()))
							configs.encoding = "UTF-8"
						reader.readAsText(file, configs.encoding.toUpperCase());
					}
					break;
				case "xlsx":
					if(configs.blob)
						reader.readAsArrayBuffer(file);
					else
						reader.readAsBinaryString(file);
					break;
			}
		})
	},
	readSheet: function(text, hasHeader=true, delim=/,|","/, row_delim=/\r\n|\n/) {
		let textLines = text.split(row_delim)
		let lines = []
		while (textLines.length) {
			let line = [];
			line = textLines.shift()
			if(line) {
				line = line.split(delim)
				if(line.length)
					lines.push(line);
			}
		}
		if(hasHeader) {
			let header = lines.shift();
			lines = lines.map(line => {
				return line.reduce((obj, cell, ind) => {
					obj[header[ind]] = cell;
					return obj;
				}, {})
			})
		}
		return lines
	}
}

export default fileManager
