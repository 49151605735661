<template>
	<loading v-if="loading"/>
	<div v-else>
		<input-form class="p-0 col-12" :options="formOptions" :actions="{cancel:false}" submit-text="篩選" v-model="data" @submit="submit"></input-form>
	</div>
</template>

<script>
import InputText from '@/components/Input/InputText';
import InputForm from '@/components/Input/InputForm';
import Loading from '@/components/Loading/Loading.vue'
import { deepCopy, isEmpty, sortArray, checkAllTrue } from '@/utils/assist';

export default {
	name: 'ProjectFilter',
	components:{
		InputText,
		InputForm,
		Loading
	},
	props: {
		value: {
			type: Object,
			default: () => { return {}; }
		},
		groupID: {
			required: true,
			default: 0
		},
		group: {
			type: Object
		}
	},
	data() {
		return {
			data: {},
			call: { getUnitList: false },
			loading: true,
			unit_types: [
				this.$store.getters.enum('unit.type.party_a').index
			],
		}
	},
	created() {
		this.init()
	},
	destroyed() {
	},
	mounted() {
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		value: {
			deep: true,
			handler(value) {
				this.data = value
			}
		},
		data: {
			deep: true,
			handler(value) {
				this.$emit('change', value)
				this.$emit('input', value)
			}
		},
	},
	computed: {
		formOptions() {
			return [
				{ key: "party_a_id", type: "select", label: "甲方", required: true, options: this.partyAList, keys: {value: 'id', text: 'name'}, size: 'xs' },
			]
		},
		partyAList() {
			let projectType = this.$store.getters.enum('project.type').find(type => type.index === this.group.project_type_index)
			if(projectType && projectType.data.party_a_name) {
				return this.party_a_units.filter(unit => {
					return projectType.data.party_a_selectable ? projectType.data.party_a_name.includes(unit.name) : unit.name === projectType.data.party_a_name
				})
			}
			return this.party_a_units
		},
	},
	methods:{
		init() {
			this.data = this.value
			this.getUnitList()
		},
		getUnitList() {
			this.call.getUnitList = false
			this.$axios.getUnitList({types: this.unit_types}, (response) => {
				this.party_a_units = response.data
				this.call.getUnitList = true
			}, (error) => {
				this.call.getUnitList = true
			})
		},
		submit(e) {
			this.$emit('submit', e);
		}
	}
}
</script>

<style scoped>
.input {
	padding: 0 .5rem .5rem 1rem;
}
</style>
