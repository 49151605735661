import red_marker_img from '@/assets/images/red-dot.png';
import dkred_marker_img from '@/assets/images/dkred-dot.png';
import orange_marker_img from '@/assets/images/orange-dot.png';
import yellow_marker_img from '@/assets/images/yellow-dot.png';
import green_marker_img from '@/assets/images/green-dot.png';
import dkgreen_marker_img from '@/assets/images/dkgreen-dot.png';
import blue_marker_img from '@/assets/images/blue-dot.png';
import skyblue_marker_img from '@/assets/images/skyblue-dot.png';
import dkblue_marker_img from '@/assets/images/dkblue-dot.png';
import purple_marker_img from '@/assets/images/purple-dot.png';
import dkpurple_marker_img from '@/assets/images/dkpurple-dot.png';
import ltpink_marker_img from '@/assets/images/ltpink-dot.png';
import pink_marker_img from '@/assets/images/pink-dot.png';
import ltblue_marker_img from '@/assets/images/ltblue-dot.png';
import white_marker_img from '@/assets/images/white-dot.png';
import grey_marker_img from '@/assets/images/grey-dot.png';

import red_large_marker_img from '@/assets/images/red-dot-lg.png';
// import dkred_large_marker_img from '@/assets/images/dkred-dot-lg.png';
import orange_large_marker_img from '@/assets/images/orange-dot-lg.png';
import yellow_large_marker_img from '@/assets/images/yellow-dot-lg.png';
import green_large_marker_img from '@/assets/images/green-dot-lg.png';
// import dkgreen_large_marker_img from '@/assets/images/dkgreen-dot-lg.png';
import blue_large_marker_img from '@/assets/images/blue-dot-lg.png';
import skyblue_large_marker_img from '@/assets/images/skyblue-dot-lg.png';
// import dkblue_large_marker_img from '@/assets/images/dkblue-dot-lg.png';
import purple_large_marker_img from '@/assets/images/purple-dot-lg.png';
// import dkpurple_large_marker_img from '@/assets/images/dkpurple-dot-lg.png';
import ltpink_large_marker_img from '@/assets/images/ltpink-dot-lg.png';
import pink_large_marker_img from '@/assets/images/pink-dot-lg.png';
import ltblue_large_marker_img from '@/assets/images/ltblue-dot-lg.png';
// import white_large_marker_img from '@/assets/images/white-dot-lg.png';
// import grey_large_marker_img from '@/assets/images/grey-dot-lg.png';

class MarkerIcon {
	static marker = {
		red: red_marker_img,
		orange: orange_marker_img,
		yellow: yellow_marker_img,
		green: green_marker_img,
		blue: blue_marker_img,
		purple: purple_marker_img,
		pink: pink_marker_img,
		white: white_marker_img,
		dkred: dkred_marker_img,
		dkgreen: dkgreen_marker_img,
		skyblue: skyblue_marker_img,
		dkblue: dkblue_marker_img,
		dkpurple: dkpurple_marker_img,
		ltpink: ltpink_marker_img,
		ltblue: ltblue_marker_img,
		grey: grey_marker_img,
	}
	static largerMarker = {
		red: red_large_marker_img,
		orange: orange_large_marker_img,
		yellow: yellow_large_marker_img,
		green: green_large_marker_img,
		blue: blue_large_marker_img,
		purple: purple_large_marker_img,
		pink: pink_large_marker_img,
		// dkred: dkred_large_marker_img,
		// dkgreen: dkgreen_large_marker_img,
		skyblue: skyblue_large_marker_img,
		// dkblue: dkblue_large_marker_img,
		// dkpurple: dkpurple_large_marker_img,
		ltpink: ltpink_large_marker_img,
		ltblue: ltblue_large_marker_img,
		// white: white_large_marker_img,
		// grey: grey_large_marker_img,
	}

	static getMarkerColors() {
		return Object.keys(MarkerIcon.marker)
	}
	static getMarker(color) {
		return MarkerIcon.marker[color] ?? MarkerIcon.marker.red
	}
	static getLargeMarkerColors() {
		return Object.keys(MarkerIcon.largerMarker)
	}
	static getLargeMarker(color) {
		return MarkerIcon.largerMarker[color] ?? MarkerIcon.largerMarker.red
	}

	static getCharMarkerColors() {
		return [
			"red", "black", "blue", "green", "grey", "orange", "purple", "white", "yellow"
		]
	}
	static getCharMarker(color, char) {
		const reg = new RegExp('^([1-9]|[1-9][0-9]|[1][0-9][0-9]|100|[A-Z!@$+\\-=#%&.])$')
		color = MarkerIcon.getCharMarkerColors().includes(color) ? color : 'red'
		char = reg.test(char) ? char : '.'
		const baseUrl = 'https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/'
		return `${baseUrl}marker_${color}${char}.png`
	}

}

export default MarkerIcon
