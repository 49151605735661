<template>
	<b-card no-body class="border-0 control-board">
		<b-card-body>
			<b-tabs class="tabs-wrapper" fill active-nav-item-class="tab-title font-weight-bold" active-tab-class="tab-title" fluid>
				<b-tab class="tab-body" :title="unit_type.text" v-for="(unit_type, index) in constant.unit_type_list" :key="unit_type.key" :active="index===0">
					<loading v-if="loading"/>
					<template v-else>
						<unit-manager :unit-type="unit_type" :units="units.filter(unit=>unit.type_index===unit_type.index)" @reload="reload"></unit-manager>
					</template>
				</b-tab>
			</b-tabs>
		</b-card-body>
	</b-card>
</template>

<script>
import InputText from '@/components/Input/InputText';
import UnitManager from '@/components/Unit/UnitManager';
import Loading from '@/components/Loading/Loading.vue';
import { deepCopy, checkAllTrue } from '@/utils/assist';

export default {
	components:{
		InputText,
		UnitManager,
		Loading
	},
	data() {
		return {
			units: {},
			call: { getUnitList: false, getUnitTags: true },
			loading: true,
			constant: {
				unit_type_list: []
			},
			addible_unit: { party_a: true, pipeline_center: true },
			new_unit: {},
			new_tag: {},
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
	},
	created() {
		this.constant.unit_type_list = this.$store.getters.enum('unit.type')
		this.getUnits()
	},
	mounted() {
	},
	computed: {
		editable() {
			return this.editing >= 0
		},
	},
	methods:{
		reload() {
			this.loading = true;
			this.getUnits()
		},
		getUnits() {
			this.getUnitList(this.constant.unit_type_list.map(o=>o.index))
			this.constant.unit_type_list = this.constant.unit_type_list.map(unit_type => {
				this.$set(this.new_unit, unit_type.key, { type: unit_type.index })
				return {
					...unit_type,
					...{ addible: ((this.$store.getters.isDeveloper || this.$store.getters.isInsider) && this.addible_unit[unit_type.key]) }
				}
			})
		},
		getUnitList(types) {
			this.call.getUnitList = false
			this.$axios.getUnitList({types_index:types}, (response) => {
				this.units = response.data
				this.getAllUnitTags()
				this.call.getUnitList = true
			}, (error) => {
				this.call.getUnitList = true
			})
		},
		getAllUnitTags() {
			this.call.getUnitTags = false
			this.$axios.getUnitTags({}, (response) => {
				for(let i in this.units) {
					let unit = this.units[i]
					this.$set(this.new_tag, unit.id, { unit_id: unit.id })
					this.$set(unit, 'tags', response.data.filter(tag => tag.unit_id === unit.id))
				}
				this.call.getUnitTags = true
			}, (error) => {
				this.call.getUnitTags = true
			})
		},
		getUnitTags(unit_id) {
			let unit = this.units.find(o => o.id === unit_id)
			if(!unit) return
			this.call.getUnitTags = false
			this.$axios.getUnitTags({unit_id: unit_id}, (response) => {
				unit.tags.splice(0, unit.length)
				this.$set(unit, 'tags', response.data)
				this.call.getUnitTags = true
			}, (error) => {
				this.call.getUnitTags = true
			})
		},
		createUnit(evt, type) {
			evt.preventDefault();
			this.$axios.createUnit(this.new_unit[type.key], (response) => {
				// this.$set(this.new_unit, type.key, { type_index: type.index })
				this.new_unit[type.key].name = ''
				this.new_unit[type.key].full_name = ''
				this.getUnitList()
			})
		},
	}
}
</script>

<style scoped>
.card-wrapper {
	padding: .5rem;
}
.card {
	height: 100%;
}
.tab-body {
	padding: 1rem 1.5rem;
	border: 1px solid #dee2e6;
	border-top: 0;
	height: max-content;
}
.tabs-wrapper {
	height: 100%;
}
.tabs-wrapper > .tab-content {
	height: 100%;
}
.member-list {
	padding: 0 1rem;
}
.control-board {
	padding: 0 1rem;
}




.unit-wrapper {
	border-top: 1px solid #eee;
	padding: 1rem 0;
}
.unit-wrapper:first-child {
	border: none;
}
.unit-wrapper > .unit-content {
	padding-left: 1rem;
}
.unit-tag-note {
	white-space: normal;
	padding-left: 1rem;
	display: flex;
	color: #515763cc;
	font-size: 0.9rem;
	align-items: center;
}
.unit-tag-action {
	/* position: absolute;
	right: 0; */
	align-self: flex-end;
	padding: 0 .5rem;
}
</style>