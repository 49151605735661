<template>
	<div>
		<loading mode="fixed" image="1" size="lg" v-if="loading"/>
		<h5>標案管理</h5>
		<b-row>
			<div class="col-md-7 col-12 py-3 px-md-3 px-1">
				<bv-table :columns="table.columns" :detail-columns="table.detailColumns" :data="bidList" :configs="table.configs" :actions="table.actions"></bv-table>
			</div>
			<div class="col-md-5 col-12">
				<b-card no-body class="border-0 control-board">
					<b-card-body>
						<b-tabs class="tabs-wrapper" fill active-nav-item-class="tab-title font-weight-bold" active-tab-class="tab-title" fluid>
							<b-tab class="tab-body" title="新增標案" active>
								<bid-creator :partyAUnits="partyAUnits" :measurerUnits="measurerUnits" @submit="reload" @loading="load" />
							</b-tab>
							<b-tab class="tab-body" title="編輯標案" :disabled="!editable" :active="editable">
								<bid-editor :bid="editingBid" :partyAUnits="partyAUnits" :measurerUnits="measurerUnits" @submit="reload" @cancel="cancelEditing" @loading="load"></bid-editor>
							</b-tab>
						</b-tabs>
					</b-card-body>
				</b-card>
			</div>
		</b-row>
	</div>
</template>

<script>
import InputText from '@/components/Input/InputText';
import BvTable from '@/components/Table/BvTable.vue'
import BidCreator from '@/components/Bid/BidCreator.vue'
import BidEditor from '@/components/Bid/BidEditor.vue'
import { getRecursiveValue, checkAllTrue } from '@/utils/assist';
import Loading from '@/components/Loading/Loading.vue';

export default {
	name: 'BidManager',
	components:{
		InputText,
		BvTable,
		BidCreator,
		BidEditor,
		Loading
	},
	props: {
		partyAUnits: {
			type: Array,
			default: () => [],
		},
		measurerUnits: {
			type: Array,
			default: () => [],
		},
		bids: {
			type: Array,
			default: () => [],
		}
	},
	data() {
		return {
			call: {},
			loading: true,
			table: {
				detailColumns: [
					{ key: 'id', label: 'ID', hide: !this.$store.getters.isDeveloper},
					{ key: 'name', label: '名稱'},
					{ key: 'abbreviation', label: '簡稱'},
					{ key: 'number', label: '編號'},
					{ key: 'unit_id', label: '甲方ID', hide: !this.$store.getters.isDeveloper },
					{ key: 'unit.name', label: '甲方名稱', deep: true },
					{ key: 'measurer_unit.id', label: '測量公司ID', deep: true, hide: !this.$store.getters.isDeveloper },
					{ key: 'measurer_unit.name', label: '測量公司', deep: true },
					{ key: 'measurer_unit_member_ids', label: '測量人員ID', hide: !this.$store.getters.isDeveloper },
					{ key: 'measurer_unit_member_names', label: '測量人員' },
				],
				columns: [
					{ key: 'id', label: 'ID', sortable: true, searchable: true, hide: !this.$store.getters.isDeveloper },
					{ key: 'bid_name', label: '名稱', sortable: true, searchable: true},
					{ key: 'unit.name', label: '甲方', sortable: true, searchable: true, deep: true },
					{ key: 'measurer_unit.name', label: '測量公司', sortable: true, searchable: true, deep: true },
				],
				actions: [
					{
						//icon, label
						button: { icon: "fas fa-edit", label: "編輯" },
						//function, link, route
						action: { type: 'function', function: this.startEditing },
						hide: (index) => this.editing === index
					},
					{
						//icon, label
						button: { icon: "far fa-edit", label: "取消編輯" },
						//function, link, route
						action: { type: 'function', function: this.cancelEditing },
						hide: (index) => this.editing !== index
					},
				],
				configs: {
					actionsColumn: true,
					rowDetail: true,
					perPage: 10
				}
			},
			editing: -1,
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	created() {
	},
	mounted() {
	},
	computed: {
		bidList() {
			return this.bids.map(bid => {
				return {
					...bid,
					bid_name: bid.abbreviation ?? bid.name,
					measurer_unit_member_ids: bid.measurer_unit_members.mapValues('id').join('、'),
					measurer_unit_member_names: bid.measurer_unit_members.mapValues('name').join('、'),
				}
			})
		},
		editable() {
			return this.editing >= 0
		},
		editingBid() {
			return this.bids[this.editing]
		},
	},
	methods:{
		reload() {
			this.$emit('reload')
		},
		load(loading) {
			this.loading = loading
		},
		startEditing(index) {
			this.editing = index
		},
		cancelEditing(index) {
			this.editing = -1;
		},
	}
}
</script>

<style scoped>
.card-wrapper {
	padding: .5rem;
}
.card {
	height: 100%;
}
.tab-body {
	padding: .5rem 1rem;
	border: 1px solid #dee2e6;
	border-top: 0;
	height: 100%;
}
.tabs-wrapper {
	height: 100%;
}
.tabs-wrapper > .tab-content {
	height: 100%;
}
</style>