<template>
	<div>
		<b-row class="drawing-board">
			<label class="col-3">繪製圖形</label>
			<b-col class="col-9">
				<div>
					<b-button-group class="col-12">
						<b-button class="form-btn submit-btn" :class="mode==='circle' ? 'active' : ''" @click="click" value="circle">
							<i class="fa-fw far fa-circle"></i>
						</b-button>
						<b-button class="form-btn submit-btn" :class="mode==='rectangle' ? 'active' : ''" @click="click" value="rectangle">
							<i class="fa-fw far fa-square"></i>
						</b-button>
						<b-button class="form-btn submit-btn" :class="mode==='polygon' ? 'active' : ''" @click="click" value="polygon">
							<i class="fa-fw fas fa-draw-polygon"></i>
						</b-button>
					</b-button-group>
				</div>
				<div>
					<b-button-group class="col-12">
						<b-button class="form-btn cancel-btn size-xs" @click="click" value="cancel">
							<!-- <i class="fa-fw fas fa-ban"></i> -->
							結束繪製
						</b-button>
						<b-button class="form-btn delete-btn size-xs" @click="click" value="delete">
							<!-- <i class="fa-fw fas fa-times"></i> -->
							清除選取
						</b-button>
					</b-button-group>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { oneOf } from "@/utils/assist"
export default {
	name: 'GmapDrawingController',
	components:{
	},
	data() {
		return {
			mode: ""
		}
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	computed: {
	},
	methods:{
		click(e) {
			e.preventDefault();
			let target = e.target;
			while(target.tagName.toLowerCase() == 'svg' || target.tagName.toLowerCase() == 'path' || target.tagName.toLowerCase() == 'i')
				target = target.parentNode;
			this.changeMode(target.value)
		},
		changeMode(mode) {
			if(oneOf(mode, ['marker', 'polygon', 'polyline', 'rectangle', 'circle'])) {
				this.mode = mode
				this.$emit('changeMode', {
					drawing: true,
					drawingMode: mode
				})
			}
			else if(mode == 'cancel') {
				this.mode = ''
				this.$emit('changeMode', {
					drawing: false,
					drawingMode: ''
				})
			}
			else {
				this.$emit('clear')
			}
		}
	}
}
</script>

<style scoped>
.drawing-board {
	margin: .5rem .25rem;
	align-items: center;
}
</style>