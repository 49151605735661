<template>
	<font class="d-inline-flex align-items-center">
		<i v-if="icon" class="fa-fw" :class="icon"></i>
		<span v-if="label">{{label}}</span>
	</font>
</template>

<script>
export default {
	name: 'ButtonText',
	props: {
		configs: {
			type: Object,
			default: () => { return {} }
		},
	},
	data() {
		return {
		}
	},
	watch: {},
	computed: {
		icon() {
			return this.getValue(this.configs.icon)
		},
		label() {
			return this.getValue(this.configs.label)
		},
	},
	methods: {
		getValue(item, defaultValue, ...args) {
			item = item ?? defaultValue
			return typeof item ==='function' ? item(...args) : item
		},
	},
}
</script>

<style scoped>

</style>