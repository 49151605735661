<template>
	<loading v-if="loading"/>
	<div v-else>
		<h6>篩選工地</h6>
		<construction-site-filter @filter="filter" @load="call.filterConstructionSite=true"></construction-site-filter>
		<hr />
		<h6>圈選工地</h6>
		<gmap-drawing-controller @changeMode="changeDrawingMode" @clear="clearDrawing"></gmap-drawing-controller>
		<hr />
		<h6>目前圈選</h6>
		<b-row class="my-1 mx-2">
			<label class="col-12">圈選工地數量：{{selected.length}}</label>
		</b-row>
		<b-row class="my-1 mx-2">
			<label class="col-12">所有工地數量：{{sites.length}}</label>
		</b-row>
		<div class="justify-content-center" style="display:flex">
			<b-button class="form-btn submit-btn" type="button" @click="finish">完成圈選</b-button>
		</div>
	</div>
</template>

<script>
import Loading from '@/components/Loading/Loading.vue'
import ConstructionSiteFilter from '@/components/Assignment/ConstructionSiteFilter'
import GmapDrawingController from '@/components/GoogleMap/GmapDrawingController'
import { checkAllTrue } from '@/utils/assist';
export default {
	name: 'ConstructionSiteSelector',
	components: {
		Loading,
		ConstructionSiteFilter,
		GmapDrawingController,
	},
	props: {
		selected: {
			type: Array,
			default: () => []
		},
		sites: {
			type: Array,
			default: () => []
		},
	},
	data() {
		return {
			loading: true,
			call: {},
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	created() {
	},
	computed: {
	},
	methods: {
		onLoadEnd() {
			this.$emit('load');
		},
		filter(e) {
			this.$emit('filter', e);
		},
		changeDrawingMode(e) {
			this.$emit('changeDrawingMode', e);
		},
		clearDrawing() {
			this.$emit('clearDrawing');
		},
		finish() {
			this.$emit('finish');
		}
	}
}
</script>

<style scoped>
</style>