import api from './api'
Plugin.install = function(Vue, options) {
	Object.defineProperties(Vue.prototype, {
		axios: {
			get() {
				return api;
			}
		},
		$axios: {
			get() {
				return api;
			}
		},
	});
};
export default Plugin;