<template>
	<b-row class="w-100 outside-wrapper" v-if="label">
		<label class="input-label col-md-2 col-12" :class="`${labelClass} ${required ? 'input-label-required' : ''}`" :multiline="multiline">{{label}}</label>
		<span class="form-input-wrapper" :size="size" :multiline="multiline">
			<date-picker class="input-text" v-model="model" :config="inputConfigs[type].configs" :disabled="disabled" :state="state" v-if="type==='date' || type==='datetime'" @input="input" @change="change"></date-picker>
			<b-form-textarea :size="size === 'xs' ? 'xs' : ''" class="input-text" v-model="model" :type="type" :placeholder="placeholder" :disabled="disabled" :state="state" :required="required" v-else-if="type==='text' && multiline" @input="input" @change="change" @keypress="$emit('keypress', $event)" @keyup="$emit('keyup', $event)" @keydown="$emit('keydown', $event)"></b-form-textarea>
			<b-form-input :size="size === 'xs' ? 'xs' : ''" class="input-text" v-model="model" :type="type" :placeholder="placeholder" :disabled="disabled" :state="state" :required="required" :pattern="pattern" :title="invalidMessage" v-else @input="input" @change="change" @keypress="$emit('keypress', $event)" @keyup="$emit('keyup', $event)" @keydown="$emit('keydown', $event)"></b-form-input>
			<button class="col-1 input-label link" v-show="button" @click="click"><i class="fa-fw" :class="button" aria-hidden="true"></i></button>
			<span class="input-background"></span>
		</span>
		<b-row class="w-100">
			<div class="col-md-2 col-12" :class="labelClass"></div>
			<div class="flex-fill text-hint font-size-sm text-left" :class="hintClass" v-if="hint">* {{hint}}</div>
		</b-row>
	</b-row>
	<b-row class="col-12 outside-wrapper" v-else >
		<label class="col-1 input-label" v-if="iconlabel"><i class="fa-fw" :class="iconlabel" aria-hidden="true"></i></label>
		<span class="form-input-wrapper" :size="size" :multiline="multiline">
			<date-picker class="input-text" v-model="model" :config="inputConfigs[type].configs" :disabled="disabled" :state="state" v-if="type==='date' || type==='datetime'" @input="input" @change="change"></date-picker>
			<b-form-textarea :size="size === 'xs' ? 'xs' : ''" class="input-text" v-model="model" :type="type" :placeholder="placeholder" :disabled="disabled" :state="state" :required="required" v-else-if="type==='text' && multiline" @input="input" @change="change" @keypress="$emit('keypress', $event)" @keyup="$emit('keyup', $event)" @keydown="$emit('keydown', $event)"></b-form-textarea>
			<b-form-input :size="size === 'xs' ? 'xs' : ''" class="input-text" v-model="model" :type="type" :placeholder="placeholder" :disabled="disabled" :state="state" :required="required" :pattern="pattern" :title="invalidMessage" v-else @input="input" @change="change" @keypress="$emit('keypress', $event)" @keyup="$emit('keyup', $event)" @keydown="$emit('keydown', $event)"></b-form-input>
			<button class="col-1 input-label link" v-show="button" @click="click"><i class="fa-fw" :class="button" aria-hidden="true"></i></button>
			<span class="input-background"></span>
		</span>
		<b-row class="w-100">
			<div class="flex-fill text-hint font-size-sm text-left" :class="hintClass" v-if="hint">* {{hint}}</div>
		</b-row>
	</b-row>
</template>

<script>
// Import this component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import { oneOf } from "@/utils/assist"
export default {
	name: 'InputText',
	components: {
		datePicker
	},
	props: {
		value: {
			type: [String, Number, Date],
		},
		type: {
			type: [String, Number],
			validator (value) {
				return oneOf(value, [ 'text', 'password', 'email', 'number', 'url', 'tel', 'search', 'date', 'datetime', 'datetime-local', 'month', 'week', 'time', 'color' ]);
			},
			default: "text"
		},
		placeholder: {
			type: String,
			default: ""
		},
		size: {
			type: String,
			default: ""
		},
		label: {
			type: String,
		},
		labelClass: {
			type: String,
			default: ""
		},
		iconlabel: {
			type: String,
		},
		button: {
			type: String,
			default: ""
		},
		disabled: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false
		},
		state: {
			type: Boolean,
			default: null
		},
		hint: {
			type: String,
			default: ""
		},
		hintClass: {
			type: String,
			default: ""
		},
		pattern: {
			type: String,
		},
		invalidMessage: {
			type: String
		},
		multiline: {
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			inputConfigs: {
				date: { valueFormat: 'yyyy-MM-dd', configs: { format: 'YYYY-MM-DD', useCurrent: false } },
				datetime: { valueFormat: 'yyyy-MM-dd hh:mm:ss', configs: { format: 'YYYY-MM-DD HH:mm:ss', useCurrent: false } },
				time: { valueFormat: 'hh:mm:ss', configs: { format: 'HH:mm:ss', useCurrent: false } },
			},
			model: undefined,
		}
	},
	created() {
		this.model = this.getDefaultValue(this.type, this.value)
		if(this.model !== this.value) {
			this.$emit('input', this.model)
		}
	},
	watch: {
		value: {
			handler(value) {
				this.model = value//this.type === 'date' ? (new Date(value) instanceof Date ? (new Date()) : new Date(value)) : value
			}
		}
	},
	methods: {
		getDefaultValue(type, value) {
			let config = this.inputConfigs[type]
			switch(type) {
				case 'date':
				case 'datetime':
				case 'time':
					// break;
					return !value || isNaN(new Date(value).getTime()) ? '' : new Date(value).format(config.valueFormat);
				case 'datetime-local':
				case 'month':
				case 'week':
				case 'color':
					break;
				default:
					return value;
			}
			return value;
		},
		click(e) {
			e.preventDefault();
			let target = e.target;
			while(target.tagName.toLowerCase() == 'svg' || target.tagName.toLowerCase() == 'path' || target.tagName.toLowerCase() == 'i')
				target = target.parentNode;
			target.parentNode.querySelector('input').focus()
			target.parentNode.querySelector('input').click()
		},
		input(e) {
			this.$emit('input', this.parseValue(e))
		},
		change(e) {
			this.$emit('change', this.parseValue(e))
		},
		parseValue(value) {
			if(this.type === 'number') {
				return value ? Number(value) : ''
			}
			return value
		}
	}
}
</script>

<style scoped>
.outside-wrapper {
	align-items: center;
}
.form-input-wrapper {
	padding: 0 1rem;
	border-radius: 1.5rem;
	height: 2.5rem;
	display: flex;
	align-items: center;
	flex: 1 1;
}
.form-input-wrapper[size="xs"] {
	border-radius: 0.5rem;
	height: 1.8rem;
}
.form-input-wrapper[size="xs"] .input-label {
	display: flex;
	align-items: center;
}
.form-input-wrapper[size="xs"] .input-label [data-icon="search"] {
	font-size: 0.8rem
}
/* 清除自動填字背景色 */
.input-text:-webkit-autofill,
.input-text:-webkit-autofill:hover,
.input-text:-webkit-autofill:focus,
.input-text:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
	-webkit-transition: background-color 5000s ease-in-out 0s;
	-moz-transition: background-color 5000s ease-in-out 0s;
	-o-transition: background-color 5000s ease-in-out 0s;
	-webkit-text-fill-color: #495057;
}
.form-input-wrapper[size="xs"] .input-text {
	height: 1rem;
	font-size: 0.9rem;
	padding: 0;
}
.input-text {
	border: none;
	z-index: 3;
	padding: .25rem;
	flex: 1 1;
}
.input-text:focus {
	border: none;
	box-shadow: none;
}
.input-text:focus ~ .input-label + .input-background {
	border: 1px solid #6b8ca9;
	box-shadow: 0 0 1px 1px #8ea9c230, 0 0 1px 1px #8ea9c230;
}
.input-text[state=false] + .input-label + .input-background,
.input-text.is-invalid + .input-label + .input-background {
	border: 1px solid #e79580;
}
.input-text[state=false]:focus ~ .input-label + .input-background,
.input-text.is-invalid:focus ~ .input-label + .input-background{
	border: 1px solid #d87b6a;
	box-shadow: 0 0 1px 1px #e7958030, 0 0 1px 1px #e7958030;
}
.input-text[disabled], .input-text.is-invalid, .input-text[state=false] {
	background: transparent;
	background-image: none;
}
.input-text[disabled] + .input-label + .input-background {
	background-color: #e9ecef;
	border: 1px solid #b8bcbb;
}
.form-input-wrapper[multiline] {
	height: max-content;
	padding: 1px 1rem;
}
.form-input-wrapper[multiline] .input-text {
	height: max-content;
	padding: .5rem 0;
	min-height: 2.55em;
}
.input-background {
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 1.25rem;
	border: 1px solid #8ea9c2;
	width: 100%;
	height: 100%;
}
.input-label {
	background-color: transparent;
	border: none;
	z-index: 3;
	display: flex;
	align-items: center;
	padding-right: .5rem;
}
button.input-label {
	color: #6b8ca9;
}
label.input-label {
	overflow-wrap: break-word;
	word-wrap: break-word;
	white-space: normal;
}
label.input-label.input-label-required::after {
	content: '*';
	color: crimson;
}
</style>
