<template>
	<loading class="info-wrapper" v-if="loading"/>
	<div v-else>
		<b-form @submit="onSubmit" class="create-form">
			<b-row class="form-row">
				<div class="col-md-6 col-sm-12 py-3 px-md-3 px-1 form-wrapper">
					<h5>基本資料</h5>
					<input-text label="名稱" v-model="form_data.name" required></input-text>
					<input-select label="案件種類" :options="options.project_types" :keys="{value: 'index', text: 'text'}" v-model="form_data.project_type" required></input-select>
				</div>
			</b-row>
			<b-row class="form-row">
				<div class="col-lg-6 col-sm-12">
					<div class="form-wrapper pb-5"> 
						<h5>案件預設施工資料</h5>
						<input-checkbox :options="constants.construction_data" :keys="{value: 'id', text: 'name'}" direction="column" v-model="form_data.group_construction_data_types"></input-checkbox>
					</div>
					<div class="form-wrapper">
						<h5>案件預設報告</h5>
						<b-card no-body class="border-0 control-board">
							<b-card-body>
								<b-tabs class="tabs-wrapper" fill active-nav-item-class="tab-title font-weight-bold" active-tab-class="tab-title" fluid>
									<b-tab class="tab-body" :title="unit_type.text" :active="key===0" v-for="(unit_type, key) in constants.unit_types" :key="key">
										<input-checkbox :options="constants.unit_reports" :keys="{value: 'id', text: 'name'}" direction="column" v-model="form_data.unit_report_types[key].project_data_types"></input-checkbox>
									</b-tab>
								</b-tabs>
							</b-card-body>
						</b-card>
					</div>
				</div>
				<div class="col-lg-6 col-12">
					<div class="form-wrapper"> 
						<h5>專案使用者</h5>
						<input-form class="p-0" :options="userOptions" :actions="{submit: false, cancel: false}" v-model="form_data.user_list" :use-form="false"></input-form>
						<div class="px-2" v-if="unusedUser.length">
							<hr>
							<font>新增專案使用者</font>
							<b-row class="d-flex col-12 form-wrapper align-items-center">
								<b-form ref="user_form" class="flex-fill">
									<input-select size="xs" :options="unusedUser" :keys="{value: 'id', text: 'name'}" v-model="tempUserOptions" required></input-select>
								</b-form>
								<button class="btn form-btn submit-btn" type="button" @click="addUser">新增</button>
							</b-row>
						</div>
					</div>
				</div>
			</b-row>
			<b-row style="align-self: center;">
				<b-button class="form-btn cancel-btn" @click="$router.go(-1)" variant="primary">取消</b-button>
				<b-button class="form-btn submit-btn" type="submit" variant="primary">確定</b-button>
			</b-row>
		</b-form>
	</div>
</template>

<script>
import Loading from '@/components/Loading/Loading.vue'
import InputText from '@/components/Input/InputText';
import InputTags from '@/components/Input/InputTags';
import InputCheckbox from '@/components/Input/InputCheckbox';
import InputSelect from '@/components/Input/InputSelect';
import InputForm from '@/components/Input/InputForm';
import { deepCopy, checkAllTrue } from '@/utils/assist';
export default {
	name: 'GroupCreator',
	components: {
		Loading,
		InputText,
		InputCheckbox,
		InputSelect,
		InputForm,
		InputTags,
	},
	data() {
		return {
			loading: true,
			call: { getProjectDataType: false, getUserList: false },
			form_data: {
				name: '',
				project_type: null,
				group_construction_data_types: [],
				unit_report_types: this.$store.getters.enum('unit.type').map(unit_type => {return {unit_type: unit_type.index, project_data_types: []}}),
				user_list: {},
			},
			constants: {
				project_data_types: [],
				construction_data: [],
				unit_reports: [],
				unit_types: this.$store.getters.enum('unit.type'),
				user_list: [],
				group_permission_status_list: [],
			},
			options: {
				user_list: [],
				project_types: [],
			},
			userOptions: [],
			tempUserOptions: null,
		}
	},
	created() {
		this.getProjectDataType()
		this.getUserList()
		this.init()
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
		options: {
			deep: true,
			handler() {
				this.updateUserOptions()
			}
		},
	},
	computed: {
		unusedUser() {
			return this.constants.user_list.filter(user => {
				return !this.options.user_list.find(x => x.id === user.id)
			})
		},
		checkHideUserOptions() {
			return this.options.user_list.length === this.constants.user_list
		},
	},
	methods: {
		onLoadEnd() {
			this.updateUserOptions()
		},
		init() {
			this.constants.group_permission_status_list = this.$store.getters.enum('permission.permission_type')
			this.options.project_types = this.$store.getters.enum('project.type')
		},
		getProjectDataType() {
			this.call.getProjectDataType = false
			this.$axios.getProjectDataType((response) => {
				this.constants.project_data_types = response.data
				this.constants.construction_data = response.data.filter(type => {
					return type.data_type_key === this.$store.getters.enum('project_data_type.data_type.construction_datum').key
				})
				this.constants.unit_reports = response.data.filter(type => {
					return type.data_type_key === this.$store.getters.enum('project_data_type.data_type.unit_report').key
				})
				this.call.getProjectDataType = true
			},(error) => {
				this.call.getProjectDataType = true
			})
		},
		getUserList() {
			this.call.getUserList = false
			this.$axios.getUserList({}, (response) => {
				this.constants.user_list = response.data.filter(user => user.id !== this.$store.getters.currentUser.id)
				this.constants.user_list = this.constants.user_list.filter(user => user.role_enum !== "user.role.developer")
				this.call.getUserList = true
			}, (error) => {
				this.call.getUserList = true
			})
		},
		addUser() {
			if(!this.$refs.user_form.reportValidity())
				return;
			let new_user = this.constants.user_list.find(user => user.id === this.tempUserOptions)
			if(new_user){
				this.options.user_list.push(new_user)
			}
		},
		updateUserOptions() {
			this.userOptions = this.options.user_list.map(user => {
				let group_permission_status_list = this.$store.getters.currentUser.isDeveloper ? this.constants.group_permission_status_list : this.constants.group_permission_status_list.filter(status => status.key !== 'super')
				// if(this.$store.getters.currentUser.isDeveloper){
				// 	return {
				// 		key: user.id,
				// 		type: "radio",
				// 		label: user.name,
				// 		options: this.constants.group_permission_status_list,
				// 		keys: {value: 'index', text: 'text'},
				// 		required: true,
				// 		hide: this.checkHideUserOptions,
				// 	}
				// }
				return {
					key: user.id,
					type: "radio",
					label: user.name,
					options: group_permission_status_list,
					keys: {value: 'index', text: 'text'},
					required: true,
					hide: this.checkHideUserOptions,
				}
			})
		},
		onSubmit(evt) {
			evt.preventDefault();

			let form_data_user_list = Object.keys(this.form_data.user_list).map(id => {return parseInt(id)})
			let new_group_user_id = form_data_user_list.filter(id => {return this.form_data.user_list[id] !== 5})
			let new_group_user_result = new_group_user_id.map(id => { return { user_id: id, permission_type: this.form_data.user_list[id] } })

			let result = {
				name: this.form_data.name,
				project_type: this.form_data.project_type,
				project_group_construction_data_types: this.form_data.group_construction_data_types.map(type => {
					return { project_data_type_id: type }
				}),
				project_unit_report_types: this.form_data.unit_report_types.map(unit => {
					return unit.project_data_types.map(type => {
						return { unit_type: unit.unit_type, project_data_type_id: type }
					})
				}).flat(),
				group_permissions: new_group_user_result,
			}

			this.$axios.createGroup(result, (response) => {
				let new_group_id = response.data.id
				alert("新增成功");
				this.$router.push({name: '專案資訊', params: {groupID: new_group_id}});
			}, (error) => {
				console.error(error)
			})
		},
	}
}
</script>

<style scoped>
.create-form {
	display: flex;
	flex-direction: column;
}
.create-form.disabled {
	overflow-y: hidden;
	overflow-x: hidden;
}
.form-row {
	margin: .5rem 0;
}
.form-wrapper {
	padding: 1rem;
}
.form-wrapper > h5 ~ * {
	padding: 0 .5rem .5rem 1rem
}
.tab-body {
	padding: .5rem 1rem;
	border: 1px solid #dee2e6;
	border-top: 0;
	height: 100%;
}
.tabs-wrapper {
	height: 100%;
}
.tabs-wrapper > .tab-content {
	height: 100%;
}
</style>