<template>
	<div class="px-md-3 px-1">
		<b-card class="shadow" id="Card">
			<h5>專案管理</h5>
			<loading class="info-wrapper" v-if="loading"/>
			<bv-table v-else :columns="table.columns" :data="groups" :configs="table.configs" :actions="table.actions" search></bv-table>
		</b-card>
		<modal v-model="groupDeleter.showModal" centered @ok="deleteProjectGroup">
			是否確定刪除案件群組
		</modal>
	</div>
</template>

<script>
import Loading from '@/components/Loading/Loading.vue'
import BvTable from '@/components/Table/BvTable.vue'
import Modal from '@/components/Modal/Modal'
import { checkAllTrue } from '@/utils/assist';
export default {
	name: 'GroupList',
	components: {
		Loading,
		BvTable,
		Modal,
	},
	data() {
		return {
			loading: true,
			call: { 
				getGroupList: false,
				getGroupPermissionList: false,
			},
			currentUser: this.$store.getters.currentUser,
			table: {
				columns: [
					{ key: 'id', label: '#', isRowHeader: true, stickyColumn: true, sortable: true, searchable: true, hide: !this.$store.getters.isDeveloper },
					{ key: 'name', label: '名稱', isRowHeader: true, stickyColumn: true, sortable: true, searchable: true },
				],
				actions: [
					{
						//icon, label
						button: { icon: "fas fa-home", label: "管理" },
						//function, link, route
						action: { type: 'route', route: this.routeToGroupInfo },
					},
					{
						//icon, label
						button: { icon: "fas fa-list", label: "案件列表" },
						//function, link, route
						action: { type: 'route', route: this.routeToGroupProjectList }
					},
					{
						//icon, label
						button: { icon: "fas fa-cog", label: "設定" },
						//function, link, route
						action: { type: 'route', route: this.routeToGroupSetting },
						hide: (index) => !this.checkPermission(index, 'edit')
					},
					{
						//icon, label
						button: { icon: "fas fa-trash-alt", label: "刪除" },
						//function, link, route
						action: { type: 'function', function: this.openGroupDeleteModal },
						hide: (index) => !this.checkPermission(index, 'delete')
					}
				],
				configs: {
					actionsColumn: true,
					busy: true,
					perPage: 10,
					showStatistic: true,
				}
			},
			groups: [],
			groupDeleter: {
				showModal: false,
				groupId: -1,
			},
			groupPermissions: []
		}
	},
	created() {
		this.getGroupList()
		this.getGroupPermissionList()
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	computed: {
	},
	methods: {
		onLoadEnd() {
			this.table.configs.busy = false;
		},
		getGroupList() {
			this.call.getGroupList = false
			this.$axios.getGroupList({}, (response) => {
				this.groups = response.data
				if(this.$store.getters.isDeveloper) {
					this.groups.push({
						id: 0,
						name: '未分類',
					})
				}
				this.call.getGroupList = true
			}, (error) => {
				this.call.getGroupList = true
			})
		},
		getGroupPermissionList() {
			this.call.getGroupPermissionList = false
			// const actions = this.$store.getters.enum('permission.action_type')
			this.$axios.getGroupPermissionList({
				user_id: this.$store.getters.currentUser.id,
			}, (response) => {
				this.groupPermissions = response.data
				// .map(group => {
				// 	let permission = this.$store.getters.enum(group.permission_type_enum)
				// 	return {
				// 		...group,
				// 		actions: actions.filter(action => permission.data.actions.includes(action.index)).map(action => {
				// 			return {
				// 				type: action.text,
				// 				type_enum: `permission.action_type.${action.key}`,
				// 				type_index: action.index,
				// 				type_key: action.key,
				// 			}
				// 		})
				// 	}
				// })
				this.call.getGroupPermissionList = true
			}, (error) => {
				this.call.getGroupPermissionList = true
			})
		},
		routeToGroupProjectCreator(index) {
			return {name: '新增專案案件', params: {groupID: this.groups[index].id}}
		},
		routeToGroupSetting(index) {
			return {name: '專案設定', params: {groupID: this.groups[index].id}}
		},
		routeToGroupInfo(index) {
			return {name: '專案資訊', params: {groupID: this.groups[index].id}}
		},
		routeToGroupProjectList(index) {
			return {name: '專案案件列表', params: {groupID: this.groups[index].id}}
		},
		routeToGroupConstructionSiteList(index) {
			return {name: '專案工地列表', params: {groupID: this.groups[index].id}}
		},
		openGroupDeleteModal(index) {
			this.groupDeleter.showModal = !this.groupDeleter.showModal;
			this.groupDeleter.groupId = this.groups[index].id;
			if(!this.groupDeleter.showModal) {
				this.$nextTick(() => {
					this.groupDeleter.showModal = true;
				})
			}
		},
		deleteProjectGroup() {
			this.$axios.deleteGroup(this.groupDeleter.groupId, (response) => {
				alert("刪除成功")
				location.reload();
			})
		},
		checkPermission(index, action_key) {
			if(!this.groups[index].id) return false;
			let permission = this.groupPermissions.find(permission => permission.project_group_id == this.groups[index].id)
			if(!permission) return this.$store.getters.isDeveloper;
			let permission_type = this.$store.getters.enum(permission.permission_type_enum)
			let action = this.$store.getters.enum(`permission.action_type.${action_key}`)
			return permission_type.data.actions.includes(action.index)
		}
	}
}
</script>

<style scoped>
a.router-link {
	color: #4c6b8a;
}
a.router-link:hover {
	text-decoration: none;
	color: #284a70;
	filter: drop-shadow(0 0 1px #0005);
}
</style>