<template>
	<div>
		<!-- <b>{{site.name}}</b> -->
		<template  v-if="measuredStatusIndexList.includes(site.status_index)">
			<template v-for="(data, key) in site.data">
				<b-row class="list-unstyled" v-if="data" :key="key">
					<span class="pl-3" style="flex: 0 1 7rem;">{{data.name}}：</span>{{data.value}}
				</b-row>
			</template>
			<hr />
			<template v-for="(data, key) in site.result.form_data">
				<b-row class="list-unstyled" v-if="data" :key="key">
					<span class="pl-3" style="flex: 0 1 7rem;">{{data.name}}：</span>{{data.value}}
				</b-row>
			</template>
		</template>
		<template v-else-if="site.status_index === $store.getters.enum('construction_site.status.assigned').index">
			尚未上傳工地表單
		</template>
		<template v-else-if="site.status_index === $store.getters.enum('construction_site.status.unassigned').index">
			尚未指派
		</template>
	</div>
</template>

<script>
import { checkAllTrue } from '@/utils/assist';

export default {
	name: 'ResultInfo',
	components: {
	},
	props: {
		site: {
			type: Object,
			default: () => { return {} }
		}
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
		}
	},
	created() {
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	mounted() {
	},
	computed: {
		measuredStatusIndexList() {
			return [
				'measured',
				'measurement_finished',
				'remeasured',
			].map(status => this.$store.getters.enum(`construction_site.status.${status}`).index)
		},
	},
	methods:{
	}
}
</script>

<style scoped>
</style>