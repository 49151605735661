<template>
	<loading v-if="loading"/>
	<div class="w-100" v-else>
		<construction-manager :constructions="constructions" :units="unitList" @reload="init"></construction-manager>
	</div>
</template>

<script>
import ConstructionManager from '@/components/Bid/ConstructionManager.vue'
import { sortArray, checkAllTrue } from '@/utils/assist';
import Loading from '@/components/Loading/Loading.vue';

export default {
	components:{
		ConstructionManager,
		Loading
	},
	props: {
	},
	data() {
		return {
			call: { getConstructionList: false, getUnitList: false, getUnitBranchList: false, getUnitMemberList: false, 
				// getBidList: false
			},
			loading: true,
			// bids: [],
			constructions: [],
			units: [],
			unitBranches: [],
			unitMembers: [],
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
	},
	created() {
		this.init();
	},
	mounted() {
	},
	computed: {
		unitList() {
			return this.units.map(unit => {
				return {
					...unit,
					branches: this.unitBranches.filter(o => o.unit_id === unit.id).map(branch => {
						return {
							...branch,
							members: this.unitMembers.filter(o => o.unit_branch_id === branch.id)
						}
					}),
					members: this.unitMembers.filter(o => !o.unit_branch_id && o.unit_id === unit.id),
					// bids: this.bids.filter(bid => bid.unit_id === unit.id),
				}
			})
		},
	},
	methods:{
		init() {
			this.getConstructionList();
			this.getUnitList()
			this.getUnitBranchList()
			this.getUnitMemberList()
			// this.getBidList()
			this.getUnitMemberList()
		},
		// getBidList() {
		// 	this.call.getBidList = false
		// 	this.$axios.getBidList({}, (response) => {
		// 		this.bids = response.data
		// 		this.call.getBidList = true
		// 	}, (error) => {
		// 		this.call.getBidList = true
		// 	})
		// },
		getConstructionList() {
			this.call.getConstructionList = false
			this.$axios.getConstructionList({}, (response) => {
				this.constructions = sortArray(response.data, 'id');
				this.call.getConstructionList = true
			}, (error) => {
				this.call.getConstructionList = true
			})
		},
		getUnitList() {
			this.call.getUnitList = false
			this.$axios.getUnitList({}, (response) => {
				this.units = response.data;
				this.call.getUnitList = true
			}, (error) => {
				this.call.getUnitList = true
			})
		},
		getUnitBranchList() {
			this.call.getUnitBranchList = false
			this.$axios.getUnitBranchList({}, (response) => {
				this.unitBranches = response.data
				this.call.getUnitBranchList = true
			}, (error) => {
				this.call.getUnitBranchList = true
			})
		},
		getUnitMemberList() {
			this.call.getUnitMemberList = false
			this.$axios.getUnitMemberList({}, (response) => {
				this.unitMembers = response.data
				this.call.getUnitMemberList = true
			}, (error) => {
				this.call.getUnitMemberList = true
			})
		},
	}
}
</script>

<style scoped>
.card-wrapper {
	padding: .5rem;
}
.card {
	height: 100%;
}
.tab-body {
	padding: .5rem 1rem;
	border: 1px solid #dee2e6;
	border-top: 0;
	height: 100%;
}
.tabs-wrapper {
	height: 100%;
}
.tabs-wrapper > .tab-content {
	height: 100%;
}
</style>