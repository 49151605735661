<template>
	<input-form class="p-0 col-12" :options="formOptions" v-model="result" @submit="onSubmit" @cancel="onCancel"></input-form>
</template>

<script>
import InputForm from '@/components/Input/InputForm';
import Loading from '@/components/Loading/Loading.vue';
import { isEmpty, checkAllTrue, isEqual } from '@/utils/assist';

export default {
	name: 'ConstructionEditor',
	components:{
		InputForm,
		Loading
	},
	props: {
		construction: {
			type: Object,
		},
		units: {
			type: Array,
			default: () => []
		},
		unit_member_list: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			result: {},
			call: {},
			loading: true,
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		construction: {
			deep: true,
			handler(value) {
				this.init()
			}
		},
		loading() {
			this.$emit('loading', this.loading)
		}
	},
	created() {
		this.init()
	},
	mounted() {
	},
	computed: {
		formOptions() {
			return [
				{ key: "name", type: "text", label: "名稱", required: true, size: 'xs' },
				{ key: "abbreviation", type: "text", label: "簡稱", required: false, size: 'xs' },
				{ key: "number", type: "text", label: "工程編號", required: false, size: 'xs' },
				{ key: "type", type: "select", label: "類別", options: this.$store.getters.enum('construction.type'), keys:{value: 'index', text: 'text'}, required: true, size: 'xs' },
				{ key: "party_a_id", type: "select", label: "甲方", options: this.partyAList, keys: {value: 'id', text: 'name'}, required: true, size: 'xs' },
				{ key: "party_a_branch_id", type: "select", label: "甲方區處", options: this.partyABranchList, keys: {value: 'id', text: 'name'}, required: true, size: 'xs' },
				{ key: "supervisor_ids", type: "select", label: "監工", required: false, multiple: true, options: this.supervisorList, keys: {value: 'id', text: 'name'}, size: 'xs' },
				{ key: "contractor_id", type: "select", label: "施工廠商", options: this.contractorList, keys: {value: 'id', text: 'name'}, required: true, size: 'xs' },
				{ key: "contractor_manager_id", type: "select", label: "施工廠商負責人", options: this.contractorMemberList, keys: {value: 'id', text: 'name'}, required: false, size: 'xs' },
				{ key: "pipeline_center_ids", type: "select", label: "道管中心", required: false, multiple: true, options: this.pipelineCenterList, keys: {value: 'id', text: 'name'}, size: 'xs' },
				// { key: "bid_id", type: "select", label: "標案", options: this.bidList, keys: {value: 'id', text: 'name'}, required: false, size: 'xs' },
			]
		},
		updatedResult() {
			return Object.keys(this.result).reduce((obj, key) => {
				if(!Object.isEqual(this.result[key], this.initResult[key]))
					obj[key] = this.result[key] === undefined ? null : this.result[key];
				return obj;
			}, {})
		},
		partyA() {
			return this.units.find(u => u.id === this.result.party_a_id)
		},
		partyAList() {
			return this.units.filter(unit => unit.type_index === this.$store.getters.enum('unit.type.party_a').index)
		},
		partyABranchList() {
			return this.partyA && this.partyA.branches ? this.partyA.branches : []
		},
		partyABranch() {
			return this.partyABranchList.find(b => b.id === this.result.party_a_branch_id)
		},
		partyABranchMemberList() {
			return this.partyABranch && this.partyABranch.members ? this.partyABranch.members : []
		},
		contractorList() {
			return this.units.filter(unit => unit.type_index === this.$store.getters.enum('unit.type.contractor').index)
		},
		pipelineCenterList() {
			return this.units.filter(unit => unit.type_index === this.$store.getters.enum('unit.type.pipeline_center').index)
		},
		supervisorList() {
			return this.partyABranchMemberList.filter(m => m.type_index === this.$store.getters.enum("unit_member.type.supervisor").index)
		},
		contractor() {
			return this.units.find(u => u.id === this.result.contractor_id)
		},
		contractorMemberList() {
			return this.contractor && this.contractor.members ? this.contractor.members : []
		},
		// bidList() {
		// 	return this.partyA && this.partyA.bids ? this.partyA.bids : []
		// },
		initResult() {
			return {
				name: this.construction ? this.construction.name : undefined,
				type: this.construction ? this.construction.type_index : undefined,
				number: this.construction ? this.construction.number : undefined,
				abbreviation: this.construction ? this.construction.abbreviation : undefined,
				party_a_id: this.construction ? this.construction.party_a_id : undefined,
				party_a_branch_id: this.construction ? this.construction.party_a_branch_id : undefined,
				contractor_id: this.construction ? this.construction.contractor_id : undefined,
				contractor_manager_id: this.construction ? this.construction.contractor_manager_id : undefined,
				// bid_id: this.construction ? this.construction.bid_id : undefined,
				pipeline_center_ids: this.construction ? this.construction.pipeline_centers.map(o => o.id) : [],
				supervisor_ids: this.construction ? this.construction.supervisors.map(o => o.id) : [],
			}
		}
	},
	methods:{
		init() {
			this.result = Object.cloneDeep(this.initResult)
		},
		onSubmit(evt) {
			evt.preventDefault();
			let result = this.updatedResult;
			if(isEmpty(result)) {
				alert('修改成功')
				this.$emit('submit')
			}
			else {
				this.$set(this.call, 'updateConstruction', false)
				this.$axios.updateConstruction(this.construction.id, result, (response) => {
					this.$emit('submit')
					this.call.updateConstruction = true;
					alert('修改成功')
				}, (error) => {
					this.call.updateConstruction = true;
				})
			}
		},
		onCancel() {
			this.$emit('cancel')
		}
	}
}
</script>

<style scoped>
.input {
	margin: .5rem .25rem
}
</style>