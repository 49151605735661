<template>
	<loading class="info-wrapper" v-if="loading"/>
	<b-form  class="edit-form mb-3 pl-3" v-else @submit="onSubmit">
		<b-row class="form-row">
			<div class="col-md-6 col-sm-12 pb-5 form-wrapper">
				<h5>更改專案名稱</h5>
				<input-text class="py-2" label="名稱" v-model="form_data.name" required></input-text>
				<input-select class="py-2" label="案件種類" :options="options.project_types" :keys="{value: 'index', text: 'text'}" v-model="form_data.project_type" required></input-select>
			</div>
		</b-row>
		<b-row class="form-row">
			<div class="col-lg-6 col-sm-12">
				<div class="form-wrapper pb-5"> 
					<h5>更改預設施工資料</h5>
					<input-checkbox :options="constants.construction_data" :keys="{value: 'id', text: 'name'}" direction="column" v-model="form_data.construction_data"></input-checkbox>
				</div>
				<div class="form-wrapper">
					<h5>案件預設報告</h5>
					<b-card no-body class="border-0 control-board">
						<b-card-body>
							<b-tabs class="tabs-wrapper" fill active-nav-item-class="tab-title font-weight-bold" active-tab-class="tab-title" fluid>
								<b-tab class="tab-body" :title="unit_type.text" :active="key===0" v-for="(unit_type, key) in constants.unit_types" :key="key">
									<input-checkbox :options="constants.unit_reports" :keys="{value: 'id', text: 'name'}" direction="column" v-model="form_data.unit_report_types[key].project_data_types"></input-checkbox>
								</b-tab>
							</b-tabs>
						</b-card-body>
					</b-card>
				</div>
			</div>
			<div class="col-lg-6 col-12">
				<div class="form-wrapper"> 
					<h5>更改專案使用者</h5>
					<input-form class="p-0" :options="userOptions" :actions="{submit: false, cancel: false}" v-model="form_data.user_list" :use-form="false"></input-form>
					<div class="px-2" v-if="unusedUser.length">
						<hr>
						<font>新增專案使用者</font>
						<b-row class="d-flex col-12 form-wrapper align-items-center">
							<b-form ref="user_form" class="flex-fill">
								<input-select size="xs" :options="unusedUser" :keys="{value: 'id', text: 'name'}" v-model="tempUserOptions" required></input-select>
							</b-form>
							<button class="btn form-btn submit-btn" type="button" @click="addUser">新增</button>
						</b-row>
					</div>
				</div>
			</div>
			
		</b-row>
		<b-row class="justify-content-center">
			<b-button class="form-btn cancel-btn" @click="cancel">取消</b-button>
			<b-button class="form-btn submit-btn" type="submit">確定</b-button>
		</b-row>
	</b-form>
</template>


<script>
import InputText from '@/components/Input/InputText';
import InputSelect from '@/components/Input/InputSelect';
import InputTags from '@/components/Input/InputTags';
import InputCheckbox from '@/components/Input/InputCheckbox';
import InputRadio from '@/components/Input/InputRadio';
import InputForm from '@/components/Input/InputForm';
import Loading from '@/components/Loading/Loading'
import { deepCopy, checkAllTrue } from '@/utils/assist';

export default {
	name: 'GroupSettingEditor',
	components: {
		InputText,
		InputSelect,
		InputTags,
		InputCheckbox,
		InputRadio,
		InputForm,
		Loading,
	},
	props: {
		group: {
			type: Object,
			default: () => { return {} }
		},
		group_users: {
			type: Array,
			default: () => []
		},
	},
	data() {
		return {
			loading: true,
			call: { getProjectDataType: false, getUserList: false },
			constants: {
				project_data_types: [],
				construction_data: [],
				unit_reports: [],
				unit_types: this.$store.getters.enum('unit.type'),
				user_list: [],
				user_list_no_developer: [],
				group_permission_status_list: [],
			},
			default_data: {
				construction_data: [],
				unit_report_types: [],
				user_list: {},
			},
			form_data: {
				name: '',
				project_type: null,
				construction_data: [],
				unit_report_types: [],
				user_list: {},
			},
			options: {
				user_list: [],
				project_types: [],
			},
			userOptions: [],
			tempUserOptions: null,
		}
	},
	created() {
		this.getProjectDataType()
		this.getUserList()
		this.init()
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
		// options: {
		// 	deep: true,
		// 	handler() {
		// 		this.updateUserOptions()
		// 	}
		// },
	},
	computed: {
		unusedUser() {
			return this.constants.user_list_no_developer.filter(user => {
				return !this.options.user_list.find(x => x.id === user.id)
			})
		},
		checkHideUserOptions() {
			return this.options.user_list.length === this.constants.user_list
		},
	},
	methods: {
		onLoadEnd() {
			this.updateUserOptions()
		},
		init() {
			this.options.project_types = this.$store.getters.enum('project.type')
			this.form_data.name = this.group.name
			this.form_data.project_type = this.group.project_type_index
			this.default_data.unit_report_types = this.$store.getters.enum('unit.type').map(unit_type => {
				return {
					unit_type: unit_type.index,
					project_data_types: this.group.project_unit_report_types.filter(report_type => report_type.unit_type_index === unit_type.index).map(report_type => report_type.project_data_type_id)
				}
			})
			this.form_data.unit_report_types = deepCopy(this.default_data.unit_report_types)

			this.default_data.construction_data = this.group.project_group_construction_data_types.map(construction_data => construction_data.project_data_type_id)
			this.form_data.construction_data = deepCopy(this.default_data.construction_data)
			
			this.constants.group_permission_status_list = this.$store.getters.enum('permission.permission_type')

			this.form_data.user_list = this.group_users.reduce((obj, user) => {
				obj[user.user_id] = user.permission_type_index
				return obj;
			}, {})

			this.default_data.user_list = deepCopy(this.form_data.user_list)
		},
		reload() {
			this.$emit('reload');
		},
		getProjectDataType() {
			this.call.getProjectDataType = false
			this.$axios.getProjectDataType((response) => {
				this.constants.project_data_types = response.data
				this.constants.construction_data = response.data.filter(type => {
					return type.data_type_key === this.$store.getters.enum('project_data_type.data_type.construction_datum').key
				})
				this.constants.unit_reports = response.data.filter(type => {
					return type.data_type_key === this.$store.getters.enum('project_data_type.data_type.unit_report').key
				})
				this.call.getProjectDataType = true
			},(error) => {
				this.call.getProjectDataType = true
			})
		},
		getUserList() {
			this.call.getUserList = false
			this.$axios.getUserList({}, (response) => {
				this.constants.user_list = response.data
				this.constants.user_list_no_developer = this.constants.user_list.filter(user => user.role_enum !== "user.role.developer")
				this.call.getUserList = true
			}, (error) => {
				this.call.getUserList = true
			})
		},
		updateUserOptions() {
			if(!this.options.user_list.length) {
				this.options.user_list = this.group_users.map(user => {
					return this.constants.user_list.find(u => u.id === user.user_id)
				})
			}
			let options_user_list = this.options.user_list.filter(user => user.role_key !== "developer")
			options_user_list = options_user_list.filter(user => user.id !== this.$store.getters.currentUser.id)
			this.userOptions = options_user_list.map(user => {
				if(this.$store.getters.currentUser.role_key === "user.role.developer"){
					return {
						key: user.id,
						type: "radio",
						label: user.name,
						options: this.constants.group_permission_status_list,
						keys: {value: 'index', text: 'text'},
						required: true,
						hide: this.checkHideUserOptions,
					}
				}
				return {
					key: user.id,
					type: "radio",
					label: user.name,
					options: this.constants.group_permission_status_list.filter(status => status.index !== 0),
					keys: {value: 'index', text: 'text'},
					required: true,
					hide: this.checkHideUserOptions,
				}
			})
		},
		addUser() {
			if(!this.$refs.user_form.reportValidity())
				return;
			let new_user = this.constants.user_list.find(user => user.id === this.tempUserOptions)
			if(new_user){
				this.options.user_list.push(new_user)
				this.updateUserOptions()
			}
		},
		onSubmit(e) {
			e.preventDefault();
			//不要的預設施工資料
			let abandon_construction_data_id = this.default_data.construction_data.filter(x => !this.form_data.construction_data.includes(x));
			abandon_construction_data_id = this.group.project_group_construction_data_types.filter(x => abandon_construction_data_id.includes(x.project_data_type_id))
			abandon_construction_data_id = abandon_construction_data_id.map(construction_data => { return construction_data.id })
			//要新增的預設施工資料
			let new_construction_data_id = this.form_data.construction_data.filter(x => !this.default_data.construction_data.includes(x));
			new_construction_data_id = new_construction_data_id.map(id => { return { project_data_type_id: id } })

			//不要的預設案件報告
			let abandon_unit_report_id = this.default_data.unit_report_types.map(default_report_type => {
				// let same_unit = this.group.project_unit_report_types.filter(group_unit_report => group_unit_report.unit_type_index === default_report_type.unit_type)
				let same_unit = this.form_data.unit_report_types.filter(unit_report => unit_report.unit_type === default_report_type.unit_type)
				let abandon_unit_report = default_report_type.project_data_types.filter(x => !same_unit[0].project_data_types.includes(x))
				abandon_unit_report = this.group.project_unit_report_types.filter(x => abandon_unit_report.includes(x.project_data_type_id))
				abandon_unit_report = abandon_unit_report.filter(x => x.unit_type_index === same_unit[0].unit_type)
				return abandon_unit_report.map(unit_report => { return unit_report.id })
			}).flat()
			//要新增的預設施工資料
			let new_unit_report_id = this.default_data.unit_report_types.map(default_report_type => {
				let same_unit = this.form_data.unit_report_types.filter(unit_report => unit_report.unit_type === default_report_type.unit_type)
				let new_unit_report = same_unit[0].project_data_types.filter(x => !default_report_type.project_data_types.includes(x))
				return new_unit_report.map(id => {
					return {
						unit_type: default_report_type.unit_type,
						project_data_type_id: id,
					}
				})
			}).flat()

			let form_data_user_list = Object.keys(this.form_data.user_list).map(id => {return parseInt(id)})
			let default_data_user_list = Object.keys(this.default_data.user_list).map(id => {return parseInt(id)})

			//要移除群組的使用者
			let abandon_group_user_id = Object.keys(this.form_data.user_list).filter(id => {return this.form_data.user_list[id] === 5})
			abandon_group_user_id = abandon_group_user_id.map(id => {return parseInt(id)})
			abandon_group_user_id = abandon_group_user_id.filter(id => default_data_user_list.includes(id))			
			//要新增的群組使用者
			let new_group_user_id = form_data_user_list.filter(id => !default_data_user_list.includes(id));
			new_group_user_id = new_group_user_id.filter(id => {return this.form_data.user_list[id] !== 5})
			//要修改的群組使用者
			let update_group_user_id = default_data_user_list.map(id => {
				if(this.default_data.user_list[id] !== this.form_data.user_list[id]){return id}}).filter(all => all)
			update_group_user_id = update_group_user_id.filter(x => !abandon_group_user_id.includes(x))

			let project_group_result = { name: this.form_data.name, project_type: this.form_data.project_type }
			let new_construction_data_result = { project_group_id: this.group.id, project_group_construction_data_types: new_construction_data_id }
			let abandon_construction_data_result = { project_group_construction_data_types_id: abandon_construction_data_id }
			let new_unit_report_result = { project_group_id: this.group.id, project_unit_report_types: new_unit_report_id }
			let abandon_unit_report_result = { project_unit_report_types_id: abandon_unit_report_id }
			let new_group_user_result = new_group_user_id.map(id => { return { user_id: id, permission_type: this.form_data.user_list[id] } })
			new_group_user_result = {
				project_group_id: this.group.id, 
				group_permissions: new_group_user_result,
			}
			let abandon_group_user_result = abandon_group_user_id.map(id => {
				let permission = this.group_users.filter(x =>x.user_id === id)[0]
				return permission.id
			})
			abandon_group_user_result = {group_permissions_id: abandon_group_user_result}
			let update_group_user_result = update_group_user_id.map(id => { 
				let permission = this.group_users.filter(x => x.user_id === id)[0]
				return {id: permission.id, user_id: id, permission_type: this.form_data.user_list[id]} 
			})	
			update_group_user_result = {project_group_id: this.group.id, group_permissions: update_group_user_result}

			Promise.all([
				this.createNewConstructionDataType(new_construction_data_result),
				this.deleteConstructionDataType(abandon_construction_data_result),
				this.createNewUnitReportType(new_unit_report_result),
				this.deleteUnitReportType(abandon_unit_report_result),
				this.createNewGroupPermission(new_group_user_result),
				this.updateGroupPermission(update_group_user_result),
				this.deleteGroupPermission(abandon_group_user_result),
			]).catch(function (error) {console.error(error);});
			
			this.$axios.updateGroup(this.group.id, project_group_result, (response) => {
				let new_group_name = response.data.name
				alert("修改成功");
				location.reload();
			},(error) => {
				console.error(error)
			})
		},
		createNewConstructionDataType(new_construction_data_result) {
			if(Array.isArray(new_construction_data_result.project_group_construction_data_types) && new_construction_data_result.project_group_construction_data_types.length){
				this.$axios.createMultiProjectGroupConstructionData(new_construction_data_result, (response) => {
				},(error) => {
					console.error(error)
				})
			}
		},
		deleteConstructionDataType(abandon_construction_data_result) {
			if(Array.isArray(abandon_construction_data_result.project_group_construction_data_types_id) && abandon_construction_data_result.project_group_construction_data_types_id.length){
				this.$axios.deleteMultiProjectGroupConstructionData(abandon_construction_data_result, (response) => {
				},(error) => {
					console.error(error)
				})
			}
		},
		createNewUnitReportType(new_unit_report_result) {
			if(Array.isArray(new_unit_report_result.project_unit_report_types) && new_unit_report_result.project_unit_report_types.length){
				this.$axios.createMultiProjectGroupUnitReport(new_unit_report_result, (response) => {
				},(error) => {
					console.error(error)
				})
			}
		},
		deleteUnitReportType(abandon_unit_report_result) {
			if(Array.isArray(abandon_unit_report_result.project_unit_report_types_id) && abandon_unit_report_result.project_unit_report_types_id.length){
				this.$axios.deleteMultiProjectGroupUnitReport(abandon_unit_report_result, (response) => {
				},(error) => {
					console.error(error)
				})
			}
		},
		createNewGroupPermission(new_group_user_result) {
			if(Array.isArray(new_group_user_result.group_permissions) && new_group_user_result.group_permissions.length){
				this.$axios.createMultiGroupPermission(new_group_user_result, (response) => {
				},(error) => {
					console.error(error)
				}) 
			}
		},
		deleteGroupPermission(abandon_group_user_result) {
			if(Array.isArray(abandon_group_user_result.group_permissions_id) && abandon_group_user_result.group_permissions_id.length){
				this.$axios.deleteMultiGroupPermission(abandon_group_user_result, (response) => {
				},(error) => {
					console.error(error)
				})
			}
		},
		updateGroupPermission(update_group_user_result) {
			if(Array.isArray(update_group_user_result.group_permissions) && update_group_user_result.group_permissions.length){
				this.$axios.updateMultiGroupPermission(update_group_user_result, (response) => {
				},(error) => {
					console.error(error)
				})
			}
		},
		cancel() {
			this.$emit("cancel")
		},
	}
}
</script>

<style scoped>
.edit-form > * {
	margin-top: .5rem;
}
ul > li {
	list-style: none;
	display: flex;
}
ul > li > *:first-child {
	flex: 0 1 30%;
	align-self: flex-start;
	justify-content: flex-end;
	margin-left: 1rem;
}
.info-outter-wrapper {
	width: 100%;
}
.info-wrapper > div {
	padding: .5rem;
}
.basic-wrapper {
	padding: .5rem;
	margin-bottom: 1rem;
}
.basic-wrapper:last-child {
	margin-bottom: 0;
}
.tabs-wrapper {
	height: 100%;
}
.tabs-wrapper > .tab-content {
	height: 100%;
}
.tab-body {
	padding: .5rem 1rem;
	border: 1px solid #dee2e6;
	border-top: 0;
	height: 100%;
}
.timeline-outside-wrapper {
	height: 4.5rem;
}
.timeline-wrapper {
	position: fixed;
	left: 4rem;
	bottom: 0;
	width: calc(100% - 4rem);
	width: -webkit-calc(100% - 4rem);
	width: -moz-calc(100% - 4rem);
	width: -o-calc(100% - 4rem);
	padding: .5rem;
}
@media (max-width:991px) {
	.timeline-wrapper {
		left: 0;
		width: 100%;
		display: none;
	}
}
.timeline {
	min-height: 4rem;
}
.project-data-wrapper {
	border-bottom: 1px solid #dee2e6;
	min-height: 2rem;
}
.project-data-wrapper:last-child {
	border: none;
}



.right-top {
	position: absolute;
	top: 0;
	right: 0;
}


.google-map {
	min-height: 300px;
	height: 400px;
}


.progress-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0005;
	z-index: 10;
}
.progress-bar-wrapper {
	width: 50%;
	margin: auto;
	top: 50%;
	background: #e1e6e690;
	box-shadow: 0 0 5px .5px #284a7090;
}
.progress-bar {
	background: #017ca7;
}
.progress-bar-striped {
	background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
	background-size: 1rem 1rem;
}
.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite;
}
</style>