<template>
	<div>
		<h5>案件報告</h5>
		<b-row class="justify-content-between" v-if="!editing">
			<div class="d-flex col-12 justify-content-end" v-if="editable">
				<b-button class="form-btn clear-btn" @click="editing=true" v-if="editable">編輯報告項目</b-button>
			</div>
			<div class="px-md-3 px-2 col-12">
				<bv-table :columns="table.columns" :data="unitReportList" :configs="table.configs" :actions="table.actions"></bv-table>
			</div>
		</b-row>
		<unit-report-editor :unit-reports="projectUnitReports" :projectDataType="projectDataType" @cancel="editing=false" @submit="reload" @loading="load" v-else></unit-report-editor>
		<modal v-model="projectUnitReportModal" centered size="lg" @ok="updateProjectUnitReportStatus" title="更新案件報告狀態">
			<input-form class="p-0 col-12" style="min-height: 300px" :options="submittionFormOptions" :actions="{submit:false,cancel:false}" v-model="submittionFormData"></input-form>
		</modal>
		<modal v-model="showUnitReportModal" centered size="lg" title="退件紀錄" v-if="showUnitReport">
			<div class="col-12 py-1">報告種類：{{getProjectDataType(showUnitReport.project_data_type_id).name}}</div>
			<div class="col-12 py-1">繳交單位：{{showUnitReport.unit_type}}</div>
			<bv-table :columns="rejectionTable.columns" :data="showUnitReport.rejections" :configs="rejectionTable.configs" :actions="rejectionTable.actions"></bv-table>
		</modal>
		<modal v-model="createRejectionModal" centered size="lg" @ok="createUnitReportRejection" title="新增退件紀錄">
			<input-form class="p-0 col-12" :options="rejectionFormOptions" :actions="{submit:false,cancel:false}" v-model="rejectionFormData"></input-form>
		</modal>
	</div>
</template>


<script>
import UnitReportEditor from '@/components/Project/Info/UnitReportEditor'
import BvTable from '@/components/Table/BvTable'
import Modal from '@/components/Modal/Modal'
import InputForm from '@/components/Input/InputForm';
import { sortArray, checkAllTrue } from '@/utils/assist';

export default {
	name: 'UnitReportViewer',
	components: {
		UnitReportEditor,
		BvTable,
		Modal,
		InputForm,
	},
	props: {
		project: {
			type: Object,
		},
		projectDataType: {
			type: Array,
			default: () => []
		},
		projectUnitReports: {
			type: Array,
			default: () => []
		},
		permission: {
			type: Object,
			default: () => { return {} }
		},
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			call: {},
			loading: true,
			unit_type_list: this.$store.getters.enum('unit.type'),
			editing: false,
			updating: false,
			table: {
				columns: [
					{ key: 'id', label: 'ID', hide: !this.$store.getters.isDeveloper },
					{ key: 'unit_type', label: '繳交單位', deep: true },
					{ key: 'project_data_type.name', label: '報告種類', deep: true },
					{ key: 'status', label: '狀態' },
					{ key: 'time', label: '提交/退件/通過時間' },
					// { key: 'measurer_unit.name', label: '測量公司', deep: true },
				],
				actions: [
					{
						//icon, label
						button: { icon: "fas fa-pen-fancy", label: "確認提交報告" },
						//function, link, route
						action: { type: 'function', function: this.openProjectUnitReportModal, params: 'submitProjectUnitReport' },
						hide: (index) => !this.checkReportSubmitable(this.unitReportList[index])
					},
					{
						//icon, label
						button: { icon: "fas fa-pen-fancy", label: "確認報告已通過" },
						//function, link, route
						action: { type: 'function', function: this.openProjectUnitReportModal, params: 'passProjectUnitReport' },
						hide: (index) => !this.editable || !this.isAfterMeasuredStatus || this.unitReportList[index].status_index !== this.$store.getters.enum('project_unit_report.status.submitted').index
					},
					{
						//icon, label
						button: { icon: "fas fa-pen-fancy", label: "確認報告已通過(監工)" },
						//function, link, route
						action: { type: 'function', function: this.openProjectUnitReportModal, params: 'setProjectUnitReportPassed' },
						hide: (index) => this.unitReportList[index].unit_type_key !== 'party_a' || !this.$store.getters.isSupervisor || this.unitReportList[index].status_index === this.$store.getters.enum('project_unit_report.status.pass').index
					},
					{
						//icon, label
						button: { icon: "fas fa-plus", label: "新增退件紀錄" },
						//function, link, route
						action: { type: 'function', function: this.showRejectionCreator },
						hide: (index) => !this.editable || !this.isAfterMeasuredStatus || this.unitReportList[index].status_index !== this.$store.getters.enum('project_unit_report.status.submitted').index
					},
					{
						//icon, label
						button: { icon: "fas fa-search", label: "檢視退件紀錄" },
						//function, link, route
						action: { type: 'function', function: this.showRejections },
						hide: (index) => !this.isAfterMeasuredStatus || !this.unitReportList[index].rejections.length
					}
				],
				configs: {
					actionsColumn: true,
					perPage: this.projectUnitReports.length
				}
			},
			rejectionTable: {
				columns: [
					{ key: 'id', label: 'ID', hide: !this.$store.getters.isDeveloper },
					{ key: 'created_at_time', label: '退件時間' },
					{ key: 'note', label: '備註', tdStyle: "white-space: pre-line;" },
					// { key: 'unit_type', label: '繳交單位', deep: true },
					// { key: 'project_data_type.name', label: '報告種類', deep: true },
					// { key: 'status', label: '狀態' },
					// { key: 'unit.name', label: '甲方', deep: true },
					// { key: 'measurer_unit.name', label: '測量公司', deep: true },
				],
				configs: {
					perPage: 5
				}
			},
			createRejectionModal: false,
			rejectionFormData: {},
			showUnitReportModal: false,
			showUnitReportIndex: -1,
			projectUnitReportModal: false,
			projectUnitReportData: {
				action: undefined,
				index: -1,
			},
			submittionFormData: {},
		}
	},
	created() {
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
		loading() {
			this.$emit('loading', this.loading)
		},
	},
	mounted() {
	},
	computed: {
		editable() {
			return this.checkPermission('edit') ? true : false
		},
		unitReports() {
			// return this.projectUnitReports
			return this.unit_type_list.map(unit_type => {
				return {
					unit_type: unit_type,
					// unit_reports: this.projectUnitReports.filter(report => report.unit_type_key === unit_type.key)
					unit_reports: this.projectUnitReports.filter(report => report.unit_type_key === unit_type.key && report.status_index !== this.$store.getters.enum('project_unit_report.status.unnecessary').index)
				}
			})
		},
		unitReportList() {
			let reports = this.projectUnitReports.filter(report => report.status_index !== this.$store.getters.enum('project_unit_report.status.unnecessary').index)
			reports = sortArray(reports, 'id')
			reports = sortArray(reports, 'unit_type_index')

			return reports.map(report => {
				report.rejections = report.rejections.length ? sortArray(report.rejections, 'id') : report.rejections
				let time = null;
				if(report.status_index === this.$store.getters.enum('project_unit_report.status.rejected').index) {
					time = report.rejections[0].created_at
				}
				else {
					time = report.pass_at ? report.pass_at : report.last_submitted_at
				}
				return {
					...report,
					time: time,
					project_data_type: this.getProjectDataType(report.project_data_type_id),
				}
			})
		},
		isAfterMeasuredStatus() {
			const project_type = this.$store.getters.enum(this.project.project_type_enum)
			const project_status_key = `${(project_type.data.name ? `${project_type.data.name}_` : '')}status`
			const statuses = this.$store.getters.enum(`project.${project_status_key}`)
			const file_made_status_index = statuses.findIndex(status => status.key === 'measured')
			const project_status_index = statuses.findIndex(status => status.key === this.project.status_key)
			return project_status_index >= file_made_status_index
		},
		submittionFormOptions() {
			return [
				{ key: `last_submitted_at`, type: 'datetime', label: '繳交日期', disabled: false },
			]
		},
		rejectionFormOptions() {
			return [
				{ key: "unit_type", type: "select", label: "繳交單位", required: true, options: this.unit_type_list, keys: {value: 'index', text: 'text'}, size: 'xs', disabled: true },
				{ key: "project_data_type_id", type: "select", label: "報告種類", required: true, options: this.projectDataType, keys: {value: 'id', text: 'name'}, size: 'xs', disabled: true },
				{ key: "note", type: "text", label: "備註", required: true, size: 'xs', multiline: true},
			]
		},
		showUnitReport() {
			return this.showUnitReportIndex >= 0 ? this.unitReportList[this.showUnitReportIndex] : undefined
		}
	},
	methods:{
		reload() {
			this.$emit("reload")
		},
		load(loading) {
			this.loading = loading
		},
		checkPermission(action_key) {
			if(!this.groupID) return this.$store.getters.isDeveloper;
			if(!this.permission) return false;
			return this.permission.actions.find(action => action.key === action_key)
		},
		getProjectDataType(id) {
			return this.projectDataType.find(type => type.id === id)
		},
		openProjectUnitReportModal(index, action) {
			this.projectUnitReportModal = true
			this.projectUnitReportData.action = this[action] ? this[action] : (() => {})
			this.projectUnitReportData.index = index
		},
		updateProjectUnitReportStatus() {
			this.projectUnitReportData.action(this.projectUnitReportData.index)
		},
		passProjectUnitReport(index) {
			this.updateProjectUnitReport(this.unitReportList[index].id, {
				pass_at: new Date().format('yyyy-MM-dd'),
			})
		},
		submitProjectUnitReport(index) {
			this.updateProjectUnitReport(this.unitReportList[index].id, this.submittionFormData)
		},
		updateProjectUnitReport(id, data) {
			this.$set(this.call, 'updateProjectUnitReport', false)
			this.$axios.updateProjectUnitReport(id, data, (response) => {
				alert('修改成功')
				this.$emit('reload')
				this.call.updateProjectUnitReport = true;
			}, (error) => {
				this.call.updateProjectUnitReport = true;
			})
		},
		setProjectUnitReportPassed(index) {
			this.$set(this.call, 'setProjectUnitReportPassed', false)
			this.$axios.setProjectUnitReportPassed(this.unitReportList[index].id, (response) => {
				alert('修改成功')
				this.$emit('reload')
				this.call.setProjectUnitReportPassed = true;
			}, (error) => {
				this.call.setProjectUnitReportPassed = true;
			})
		},
		showRejectionCreator(index) {
			this.createRejectionModal = true
			this.rejectionFormData = {
				project_unit_report_id: this.unitReportList[index].id,
				project_data_type_id: this.unitReportList[index].project_data_type_id,
				unit_type: this.unitReportList[index].unit_type_index,
			}
		},
		createUnitReportRejection() {
			this.$set(this.call, 'createUnitReportRejection', false)
			this.$axios.createUnitReportRejection(this.rejectionFormData, (response) => {
				this.$emit('reload')
				alert('新增成功')
				this.call.createUnitReportRejection = true;
			}, (error) => {
				this.call.createUnitReportRejection = true;
			})
		},
		showRejections(index) {
			this.showUnitReportModal = true;
			this.showUnitReportIndex = index;
		},
		checkReportSubmitable(report) {
			return this.editable && this.isAfterMeasuredStatus && (report.status_index === this.$store.getters.enum('project_unit_report.status.uncommitted').index || report.status_index === this.$store.getters.enum('project_unit_report.status.rejected').index)
		}
	}
}
</script>

<style scoped>
ul > li {
	list-style: none;
	display: flex;
}
ul > li > *:first-child {
	flex: 0 1 30%;
	align-self: flex-start;
	justify-content: flex-end;
	margin-left: 1rem;
}
.info-outter-wrapper {
	width: 100%;
}
.info-wrapper > div {
	padding: .5rem;
}
.basic-wrapper {
	padding: .5rem;
	margin-bottom: 1rem;
}
.basic-wrapper:last-child {
	margin-bottom: 0;
}
.tabs-wrapper {
	height: 100%;
}
.tabs-wrapper > .tab-content {
	height: 100%;
}
.tab-body {
	padding: .5rem 1rem;
	border: 1px solid #dee2e6;
	border-top: 0;
	height: 100%;
}
.timeline-outside-wrapper {
	height: 4.5rem;
}
.timeline-wrapper {
	position: fixed;
	left: 4rem;
	bottom: 0;
	width: calc(100% - 4rem);
	width: -webkit-calc(100% - 4rem);
	width: -moz-calc(100% - 4rem);
	width: -o-calc(100% - 4rem);
	padding: .5rem;
}
@media (max-width:991px) {
	.timeline-wrapper {
		left: 0;
		width: 100%;
		display: none;
	}
}
.timeline {
	min-height: 4rem;
}
.project-data-wrapper {
	border-bottom: 1px solid #dee2e6;
	min-height: 2rem;
}
.project-data-wrapper:last-child {
	border: none;
}


.right-top {
	position: absolute;
	top: 0;
	right: 0;
}


.google-map {
	min-height: 300px;
	height: 400px;
}


.progress-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0005;
	z-index: 10;
}
.progress-bar-wrapper {
	width: 50%;
	margin: auto;
	top: 50%;
	background: #e1e6e690;
	box-shadow: 0 0 5px .5px #284a7090;
}
.progress-bar {
	background: #017ca7;
}
.progress-bar-striped {
	background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
	background-size: 1rem 1rem;
}
.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite;
}
</style>
