<template>
	<loading v-if="loading"/>
	<div v-else>
		<b-row class="p-2 justify-content-between">
			<h5 class="m-0">測量人員：{{user.name}}</h5>
			<b-button class="form-btn clear-btn m-0" @click="close">檢視所有測量人員</b-button>
		</b-row>
		<div class="p-2">
			<bv-table :columns="table.columns" :data="sites" :configs="table.configs" :actions="table.actions" @row-selected="rowSelected"></bv-table>
		</div>
		<b-row class="p-2 justify-content-between">
			<label class="m-0">目前工地選擇數量：{{deletedConstructionSites.length}}</label>
			<b-button class="form-btn delete-btn m-0" @click="deleteMultiMissionPoints">刪除選擇工地</b-button>
		</b-row>
		<modal v-model="missionSiteDeleter.showModal" centered @ok="deleteMissionPoint">
			是否確定刪除任務工地（{{missionSiteDeleter.message}}）
		</modal>
	</div>
</template>

<script>
import Loading from '@/components/Loading/Loading.vue'
import BvTable from '@/components/Table/BvTable'
import Modal from '@/components/Modal/Modal'
import { checkAllTrue } from '@/utils/assist';

export default {
	name: 'UserMissionViewer',
	components: {
		Loading,
		BvTable,
		Modal,
	},
	props: {
		user: {
			type: Object,
			default: () => { return {}; }
		},
		sites: {
			type: Array,
			default: () => []
		},
	},
	data() {
		return {
			loading: true,
			table: {
				columns: [
					{ key: 'id', label: 'ID', sortable: true, searchable: true, hide: !this.$store.getters.isDeveloper },
					{ key: 'mission.id', label: '任務ID', sortable: true, searchable: true, hide: !this.$store.getters.isDeveloper, deep: true },
					{ key: 'project_group_name', label: '專案', sortable: true, searchable: true},
					{ key: 'name', label: '名稱', sortable: true, searchable: true},
					{ key: 'address', label: '地址', sortable: true, searchable: true},
					{ key: 'status', label: '狀態', sortable: true, searchable: true },
					{ key: 'mission.created_at', label: '指派日期', sortable: true, searchable: true, deep: true}
				],
				actions: [
					{
						//icon, label
						button: { type: 'icon', icon: "fas fa-eye", class: "m-0", style: "color: #094c73" },
						//function, link, route
						action: { type: 'function', function: this.viewSite },
					},
					{
						//icon, label
						button: { type: 'icon', icon: "fas fa-trash-alt", class: "m-0", style: "color: #903934" },
						//function, link, route
						action: { type: 'function', function: this.clickDeleteMissionButton },
					},
				],
				configs: {
					perPage: 9,
					actionsColumn: true,
					showStatistic: true,
					selectable: true,
					selectMode: 'multi',
				}
			},
			missionSiteDeleter: {
				showModal: false,
				missionId: -1,
				siteId: -1,
				message: '',
			},
			deletedConstructionSites: [],
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	created() {
	},
	computed: {
	},
	methods: {
		reload() {
			this.$emit('reload');
		},
		viewSite(index) {
			this.$emit('view', this.sites[index])
		},
		clickDeleteMissionButton(index) {
			this.openDeleteMissionModal(this.sites[index])
		},
		openDeleteMissionModal(site) {
			this.missionSiteDeleter.showModal = !this.missionSiteDeleter.showModal;
			this.missionSiteDeleter.missionId = site.mission.id;
			this.missionSiteDeleter.siteId = site.id;
			this.missionSiteDeleter.message = `${site.name} ${this.$store.getters.isDeveloper ? `(${site.id}-${site.mission.id})` : ''}`;
			if(!this.missionSiteDeleter.showModal) {
				this.$nextTick(() => {
					this.missionSiteDeleter.showModal = true;
				})
			}
		},
		deleteMissionPoint() {
			this.$axios.deleteMissionPoint(this.missionSiteDeleter.missionId, this.missionSiteDeleter.siteId, (response) => {
				alert("刪除成功")
				this.reload();
			})
		},
		close() {
			this.$emit('close');
		},
		rowSelected(data){
			this.deletedConstructionSites = data
			this.$emit('row-selected', this.deletedConstructionSites)
		},
		deleteMultiMissionPoints() {
			if(!this.deletedConstructionSites.length){
				alert("請選擇工地")
				return
			}
			let deleteData = this.deletedConstructionSites.map(constructionSite => {
				return { mission_id: constructionSite.mission.id, construction_site_id: constructionSite.id}
			})
			this.$axios.deleteMultiMissionPoints({missions_constructionSites: deleteData}, (response) => {
				alert("刪除成功")
				this.reload();
			})
		},
	}
}
</script>

<style scoped>
</style>