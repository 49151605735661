(function (window, undefined) {
	// 對Date的擴充套件，將 Date 轉化為指定格式的String
	// 月(M)、日(d)、小時(h)、分(m)、秒(s)、季度(q) 可以用 1-2 個佔位符，
	// 年(y)可以用 1-4 個佔位符，毫秒(S)只能用 1 個佔位符(是 1-3 位的數字)
	// 例子：
	// (new Date()).format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
	// (new Date()).format("yyyy-M-d hⓂ️s.S")   ==> 2006-7-2 8:9:4.18

	const OriginalDate = Date;
	const OriginalDateDescriptors = Object.getOwnPropertyDescriptors(OriginalDate);
	window.Date = function(arg1, arg2, arg3, arg4, arg5, arg6, arg7) {
		if (arguments.length === 1) {
			let arg = arguments[0];
			if (Object.prototype.toString.call(arg) === '[object String]' && arg.indexOf('T') === -1) {
				arguments[0] = arg.replace(/-/g, "/");
			}
		}
		let bind = Function.bind;
		let unbind = bind.bind(bind);
		return new (unbind(OriginalDate.prototype.constructor, null).apply(null, arguments));
	};

	Object.keys(OriginalDateDescriptors).forEach(key => {
		Date[key] = OriginalDateDescriptors[key].value;
	})

	Date.isDate = function(date) {
		return Object.isObject(date) && date.constructor.name === 'Date'
	}

	Date.prototype.format = function (fmt) {
		var o = {
			"M+": this.getMonth() + 1, //月份
			"d+": this.getDate(), //日
			"h+": this.getHours(), //小時
			"m+": this.getMinutes(), //分
			"s+": this.getSeconds(), //秒
			"q+": Math.floor((this.getMonth() + 3) / 3), //季度
			"S": this.getMilliseconds(), //毫秒
			"W+": this.getWeekDay() //星期
		};
		if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
		else if (/(Y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() - 1911 + ""));
		for (var k in o)
			if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
		return fmt;
	}

	Date.prototype.toDateString = function() {
		return this.format('yyyy-MM-dd')
	}

	Date.prototype.toDateTimeString = function() {
		return this.format('yyyy-MM-dd hh:mm:ss')
	}

	Date.prototype.addMilliseconds = function (ms) {
		this.setMilliseconds(this.getMilliseconds() + ms);
		return this;
	}

	Date.prototype.addSeconds = function (seconds) {
		this.setSeconds(this.getSeconds() + seconds);
		return this;
	}

	Date.prototype.addMinutes = function (minutes) {
		this.setMinutes(this.getMinutes() + minutes);
		return this;
	}

	Date.prototype.addHours = function (hours) {
		this.setHours(this.getHours() + hours);
		return this;
	}

	Date.prototype.addDays = function (days) {
		this.setDate(this.getDate() + days);
		return this;
	}

	Date.prototype.addWorkDays = function (days) {
		if(this.getDay() === 6 && days > 0) this.addDays(1);
		else if(this.getDay() === 0 && days < 0) this.addDays(-1);
		let day = calDaysAfterWorkDays(this.getDay(), days);
		this.addDays(day)
		return this;
	}

	Date.prototype.addMonths = function (months) {
		this.setMonth(this.getMonth() + months);
		return this;
	}

	Date.prototype.addYears = function (years) {
		this.setFullYear(this.getFullYear() + years);
		return this;
	}

	Date.prototype.diffDays = function(date) {
		let diff = this.startOfDay() - (new Date(date)).startOfDay()
		return Math.floor(-diff/(24*3600*1000))
	}
	Date.prototype.diffWorkDays = function(date) {
		let diff = this.diffDays(date)
		return calWorkDaysAfterDays(this.getDay(), diff)
	}

	Date.prototype.getNextDay = function() {
		let next = new Date(this)
		next.addDays(1)
		return next
	}

	Date.prototype.getWeekDay = function() {
		const week = ["日", "一", "二", "三", "四", "五", "六"]
		return week[this.getDay()]
	}

	Date.prototype.isWorkDay = function() {
		return !this.isWeekend()
	}

	Date.prototype.isWeekend = function() {
		return this.getDay() === 6 || this.getDay() === 0
	}

	Date.prototype.startOfDay = function() {
		let date = new Date(this.toDateString())
		return date
	}
	Date.prototype.startOfWeek = function() {
		let date = new Date(this.toDateString())
		date.addDays(-this.getDay())
		return date
	}
	Date.prototype.startOfMonth = function() {
		return new Date(this.format('yyyy-MM-01'))
	}
	Date.prototype.startOfYear = function() {
		return new Date(this.format('yyyy-01-01'))
	}

	Date.prototype.endOfDay = function() {
		let date = this.startOfDay()
		date.addMilliseconds(-1)
		return date
	}
	Date.prototype.endOfWeek = function() {
		let date = this.startOfWeek()
		date.addMilliseconds(-1)
		return date
	}
	Date.prototype.endOfMonth = function() {
		let date = this.startOfMonth()
		date.addMilliseconds(-1)
		return date
	}
	Date.prototype.endOfYear = function() {
		let date = this.startOfYear()
		date.addMilliseconds(-1)
		return date
	}

	function diffSeconds(milliseconds) {
		return Math.floor(milliseconds / 1000);
	}

	function diffMinutes(milliseconds) {
		return Math.floor(milliseconds / 1000 / 60);
	}

	function diffHours(milliseconds) {
		return Math.floor(milliseconds / 1000 / 60 / 60);
	}

	function diffDays(milliseconds) {
		return Math.floor(milliseconds / 1000 / 60 / 60 / 24);
	}

	function calDaysAfterWorkDays(day, workDays) {
		let diff = 0
		diff += Math.floor(Math.abs(workDays) / 5) * 7
		workDays = workDays % 5
		if(day + workDays >= 6)
			diff += 2
		else if(day + workDays <= 0)
			diff += -2
		diff += workDays
		return diff
	}

	function calWorkDaysAfterDays(day, days) {
		if(days === 0) return 0
		let after = 1 * days / Math.abs(days)
		let diff = 0
		days = days + 1 * after
		diff += Math.floor(Math.abs(days) / 7) * 5
		days = days % 7
		let weekends = [6, 7, 0, -1]
		if(weekends.includes(day)){
			day = 5
			days += 7 - day
		}
		diff += Array.range(day, day + days).filter(v => !weekends.includes(v)).length - 1
		return diff * after
	}
}(window));

