<template>
	<input-form class="p-0 col-12" :options="formOptions" v-model="result" @submit="onSubmit" @cancel="onCancel"></input-form>
</template>

<script>
import InputForm from '@/components/Input/InputForm';
import Loading from '@/components/Loading/Loading.vue';
import { deepCopy, checkAllTrue } from '@/utils/assist';

export default {
	name: 'UnitMemberEditor',
	components:{
		InputForm,
		Loading
	},
	props: {
		unit: {
			type: Object,
		},
		unitMember: {
			type: Object,
		},
		unitBranches: {
			type: Array,
			default: () => []
		},
	},
	data() {
		return {
			result: {},
			call: {},
			loading: true,
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		unitMember: {
			deep: true,
			handler(value) {
				this.initResult()
			}
		},
		loading() {
			this.$emit('loading', this.loading)
		}
	},
	created() {
		this.initResult()
	},
	mounted() {
	},
	computed: {
		formOptions() {
			return [
				{ key: "name", type: "text", label: "名稱", required: true, size: 'xs' },
				{ key: "type", type: "select", label: "種類", required: true, options: this.$store.getters.enum('unit_member.type'), keys: {value: 'index', text: 'text'}, size: 'xs' },
				{ key: "unit_branch_id", type: "select", label: "所屬區處", options: this.unitBranches, keys: {value: 'id', text: 'name'}, size: 'xs', hide: !this.unitBranches.length },
			]
		},
		unitType() {
			return this.$store.getters.enum(`unit.type.${this.unit.type_key}`);
		},
		updatedResult() {
			return Object.keys(this.result).reduce((obj, key) => {
				if(this.result[key] !== this.unitMember[key])
					obj[key] = this.result[key];
				return obj;
			}, {})
		}
	},
	methods:{
		initResult() {
			this.result = deepCopy({
				name: this.unitMember.name,
				type: this.unitMember.type_index,
				unit_branch_id: this.unitMember.unit_branch_id,
			})
		},
		onSubmit(evt) {
			evt.preventDefault();
			let result = this.updatedResult;
			if(!result) {
				alert('修改成功')
				this.$emit('submit')
			}
			else {
				this.$set(this.call, 'updateUnitMember', false)
				this.$axios.updateUnitMember(this.unitMember.id, result, (response) => {
					this.$emit('submit')
					this.call.updateUnitMember = true;
				}, (error) => {
					this.call.updateUnitMember = true;
				})
			}
		},
		onCancel() {
			this.$emit('cancel')
		}
	}
}
</script>

<style scoped>
.input {
	margin: .5rem .25rem
}
</style>