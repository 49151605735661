<template>
	<loading v-if="loading"/>
	<div v-else>
		<loading mode="fixed" image="1" size="lg" v-if="uploading"/>
		<div class="mt-1 mb-3 ml-3 d-flex justify-content-between" v-for="(projectDatum, key) in projectData" :key="key">
			<photo-album v-if="projectDatum.project_data_type.type_key === 'image'" :deletable="editing" :title="projectDatum.project_data_type.name" :photos="projectDatum.data_list" :keys="{name: 'point.name', src: 'content'}" photo-class="col-lg-2 col-md-3 col-sm-4 col-xs-6 col-6" selectable showname @select="openLightBox" @delete="openDeleteProjectDatumModal">
				<template v-slot:buttons>
					<b-button class="form-btn" :class="{'clear-btn': !editing, 'cancel-btn': editing}" @click="editing=!editing" v-if="editable">{{editing ? '結束' : ''}}編輯</b-button>
					<b-button class="form-btn clear-btn" @click="openMatchProjectDatumEditorModal(projectDatum)">配對測量點</b-button>
				</template>
			</photo-album>
		</div>
		<!-- <light-box v-if="!loading && view_img" :data="project_data_group.map(group=>constant.project_data_type_list.filter(group.filter_func)).flat().map(data_type=>data_list.filter(item=>item.type_id===data_type.id)).flat()" :index="view_img_index" :keys="{name: 'name', src: 'content'}" @close="view_img=!view_img"></light-box> -->
		<modal v-model="matchProjectDatumModal.showModal" size="xl" centered @ok="matchMultiProjectDataToPoints" v-if="matchProjectDatumModal.projectDatum">
			<alert :errors="pointDataErrors" :warnings="pointDataWarnings" v-if="hasErrors || hasWarnings"></alert>
			<b-row class="align-items-center" v-for="(photo, key) in uniqueProjectData" :key="key">
				<div class="col-xl-3 col-lg-4 col-md-6 col-12 p-1"><photo :photo="photo.content"></photo></div>
				<div class="col-xl-9 col-lg-8 col-md-6 col-12 p-1">
					<input-text label-class="col-12" label="對應點位" v-model="matchProjectDatumModal.formData[photo.id]" size="xs"></input-text>
				</div>
			</b-row>
			<template v-slot:footer>
				<b-button variant="primary" @click="matchMultiProjectDataToPoints">確認</b-button>
			</template>
		</modal>
		<modal v-model="deleteProjectDatumModal.showModal" centered @ok="deleteProjectDatum">
			是否確定刪除照片
		</modal>
	</div>
</template>

<script>
import PhotoAlbum from '@/components/Album/PhotoAlbum.vue'
import Photo from '@/components/Album/Photo.vue'
import LightBox from '@/components/Album/LightBox.vue'
import InputFile from '@/components/Input/InputFile.vue'
import InputText from '@/components/Input/InputText.vue'
import Loading from '@/components/Loading/Loading.vue'
import Modal from '@/components/Modal/Modal'
import Alert from '@/components/Alert/Alert.vue';
import { deepCopy, isEmpty, sortArray, checkAllTrue } from '@/utils/assist';
import fileManager from '@/utils/file';

export default {
	name: 'ProjectDataViewer',
	components: {
		PhotoAlbum,
		Photo,
		InputFile,
		InputText,
		LightBox,
		Loading,
		Modal,
		Alert,
	},
	props: {
		projectData: {
			type: Array,
			default: () => []
		},
		editable: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			call: { getPointList: false },
			loading: true,
			upload: {},
			matchProjectDatumModal: {
				showModal: false,
				projectDatum: {},
				formData: {},
			},
			points: [],
			editing: false,
			deleteProjectDatumModal: {
				showModal: false,
				projectDatum: {},
			},
		}
	},
	created() {
		this.getPointList()
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		uploading(value) {
			if(!value)
				alert('修改成功')
		}
	},
	mounted() {
	},
	computed: {
		pointIndexList() {
			return this.points.map(pt => pt.index)
		},
		minPointIndex() {
			return Math.min(...this.pointIndexList)
		},
		maxPointIndex() {
			return Math.max(...this.pointIndexList)
		},
		pointData() {
			const regex = /(?<start>\d+)?(?<range>-)?(?<end>\d+)?/
			return Object.keys(this.matchProjectDatumModal.formData).reduce((obj, key) => {
				obj[key] = [...(new Set(this.matchProjectDatumModal.formData[key].split(',').map(text => {
					if(!text) return;
					let result = regex.exec(text.trim())
					if(!(result && (result.groups.start || result.groups.end))) return;
					let range = !!result.groups.range
					let start = result.groups.start ? Number(result.groups.start) : this.minPointIndex
					let end = result.groups.end ? Number(result.groups.end) : this.maxPointIndex
					return range ? this.pointIndexList.filter(index => start <= index && index <= end) : this.pointIndexList.includes(start) ? [start] : []
				}).filter(o => o).flat()))]
				return obj
			}, {})
		},
		pointDataWarnings() {
			let errors = {
				lost: {
					title: '缺少點位',
					messages: this.pointIndexList.filter(index => !Object.values(this.pointData).flat().includes(index)).map(index=>`pt${index}`).join(', '),
				},
			}
			return Object.filter(errors, err => err.messages)
		},
		hasWarnings() {
			return Object.values(this.pointDataWarnings).some(error => !isEmpty(error))
		},
		pointDataErrors() {
			let errors = {
				duplicate: {
					title: '點位重複',
					messages: this.pointIndexList.filter(index => Object.values(this.pointData).flat().duplicates().includes(index)).map(index=>`pt${index}`).join(', '),
				},
			}
			return Object.filter(errors, err => err.messages)
		},
		hasErrors() {
			return Object.values(this.pointDataErrors).some(error => !isEmpty(error))
		},
		uniqueProjectData() {
			return this.matchProjectDatumModal.projectDatum.data_list ? Object.values(this.matchProjectDatumModal.projectDatum.data_list.reduce((obj, datum) => {
				if(!obj[datum.content]){
					obj[datum.content] = {
						...datum,
						points_index: datum.point ? [datum.point.index] : []
					}
				}
				else {
					obj[datum.content].points_index.push(datum.point.index)
				}
				return obj
			}, {})) : []
		},
		uploading() {
			return checkAllTrue(this.upload)
		},
	},
	methods:{
		isEmpty(o) { return isEmpty(o) },
		getPointList() {
			this.call.getPointList = false
			this.$axios.getPointList({
				project_type: this.projectType,
				projects_id: [this.projectID],
				less: 1,
			}, (response) => {
				this.points = response.data.map(point => {
					return {
						...point,
						name: `pt${point.index}`,
					}
				})
				sortArray(this.points, 'id')
				this.call.getPointList = true
			}, (error) => {
				this.call.getPointList = true
			})
		},
		openLightBox(img) {
			// this.view_img = true
			// this.view_img_index = this.data_list.findIndex(o=>o.id===img.id)
		},
		openMatchProjectDatumEditorModal(datum) {
			this.matchProjectDatumModal.showModal = true
			this.matchProjectDatumModal.projectDatum = datum
			this.matchProjectDatumModal.formData = this.uniqueProjectData.reduce((obj, datum) => {
				obj[datum.id] = datum.points_index.join(',')
				return obj 
			}, {})
		},
		matchMultiProjectDataToPoints() {
			if(this.hasErrors) {
				alert('含有重複點位')
				return;
			}
			this.matchProjectDatumModal.showModal = false
			let oriData = Object.map(this.uniqueProjectData.keyBy('id'), 'points_index')
			let updatedData = Object.map(Object.diff(this.pointData, oriData), (points, id) => points.diff(oriData[id]))
			let projectData = Object.values(Object.map(Object.filter(updatedData, points => points.length), (points, id) => {
				return {
					id: id,
					points_index: points,
				}
			}))
			this.$set(this.upload, 'matchMultiProjectDataToPoints', false)
			this.$axios.matchMultiProjectDataToPoints({
				user_id: this.$store.getters.currentUser.id,
				project_data: projectData,
			}, (response) => {
				this.$emit("submit")
				this.upload.matchMultiProjectDataToPoints = true
			}, (error) => {
				this.upload.matchMultiProjectDataToPoints = true
			})
		},
		openDeleteProjectDatumModal(projectDatum) {
			this.deleteProjectDatumModal.showModal = true
			this.deleteProjectDatumModal.projectDatum = projectDatum
		},
		deleteProjectDatum() {
			this.deleteProjectDatumModal.showModal = false

			let projectDatum = this.deleteProjectDatumModal.projectDatum
			let dataType = this.projectData.find(dataType => dataType.project_data_type_id === projectDatum.type_id)
			let index = dataType.data_list.findIndex(datum => datum.id === projectDatum.id)
			dataType.data_list.splice(index, 1)

			this.$set(this.call, 'deleteProjectDatum', false)
			this.$axios.deleteProjectDatum(projectDatum.id, (response) => {
				alert("刪除成功")
				this.call.deleteProjectDatum = true;
				this.$forceUpdate()
			}, (error) => {
				this.call.deleteProjectDatum = true;
			})
		}
	}
}
</script>

<style scoped>
</style>