<template>
	<loading v-if="loading"/>
	<input-form class="p-0 col-12" :options="formOptions" :actions="{cancel:false}" v-model="result" @submit="onSubmit" v-else></input-form>
</template>

<script>
import InputForm from '@/components/Input/InputForm';
import Loading from '@/components/Loading/Loading.vue';
import { isEmpty, checkAllTrue } from '@/utils/assist';

export default {
	name: 'ConstructionCreator',
	components:{
		InputForm,
		Loading
	},
	props: {
		units: {
			type: Array,
			default: () => []
		},
	},
	data() {
		return {
			call: {},
			loading: true,
			result: {},
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		loading() {
			this.$emit('loading', this.loading)
		}
	},
	created() {
	},
	mounted() {
	},
	computed: {
		formOptions() {
			return [
				{ key: "name", type: "text", label: "名稱", required: true, size: 'xs' },
				{ key: "abbreviation", type: "text", label: "簡稱", required: false, size: 'xs' },
				{ key: "number", type: "text", label: "工程編號", required: false, size: 'xs' },
				{ key: "type", type: "select", label: "類別", options: this.$store.getters.enum('construction.type'), keys:{value: 'index', text: 'text'}, required: true, size: 'xs' },
				{ key: "party_a_id", type: "select", label: "甲方", options: this.partyAList, keys: {value: 'id', text: 'name'}, required: true, size: 'xs' },
				{ key: "party_a_branch_id", type: "select", label: "甲方區處", options: this.partyABranchList, keys: {value: 'id', text: 'name'}, required: true, size: 'xs' },
				{ key: "supervisor_ids", type: "select", label: "監工", required: false, multiple: true, options: this.supervisorList, keys: {value: 'id', text: 'name'}, size: 'xs' },
				{ key: "contractor_id", type: "select", label: "施工廠商", options: this.contractorList, keys: {value: 'id', text: 'name'}, required: true, size: 'xs' },
				{ key: "contractor_manager_id", type: "select", label: "施工廠商負責人", options: this.contractorMemberList, keys: {value: 'id', text: 'name'}, required: false, size: 'xs' },
				{ key: "pipeline_center_ids", type: "select", label: "道管中心", required: false, multiple: true, options: this.pipelineCenterList, keys: {value: 'id', text: 'name'}, size: 'xs' },
				// { key: "bid_id", type: "select", label: "標案", options: this.bidList, keys: {value: 'id', text: 'name'}, required: false, size: 'xs' },
			]
		},
		partyA() {
			return this.units.find(u => u.id === this.result.party_a_id)
		},
		partyAList() {
			return this.units.filter(unit => unit.type_index === this.$store.getters.enum('unit.type.party_a').index)
		},
		partyABranchList() {
			return this.partyA && this.partyA.branches ? this.partyA.branches : []
		},
		partyABranch() {
			return this.partyABranchList.find(b => b.id === this.result.party_a_branch_id)
		},
		partyABranchMemberList() {
			return this.partyABranch && this.partyABranch.members ? this.partyABranch.members : []
		},
		contractorList() {
			return this.units.filter(unit => unit.type_index === this.$store.getters.enum('unit.type.contractor').index)
		},
		pipelineCenterList() {
			return this.units.filter(unit => unit.type_index === this.$store.getters.enum('unit.type.pipeline_center').index)
		},
		supervisorList() {
			return this.partyABranchMemberList.filter(m => m.type_index === this.$store.getters.enum("unit_member.type.supervisor").index)
		},
		contractor() {
			return this.units.find(u => u.id === this.result.contractor_id)
		},
		contractorMemberList() {
			return this.contractor && this.contractor.members ? this.contractor.members : []
		},
		// bidList() {
		// 	return this.partyA && this.partyA.bids ? this.partyA.bids : []
		// },
	},
	methods:{
		onSubmit(evt) {
			evt.preventDefault();
			this.$set(this.call, 'createConstruction', false)
			this.$axios.createConstruction(this.result, (response) => {
				this.$emit('submit')
				alert('新增成功')
				this.call.createConstruction = true;
			}, (error) => {
				this.call.createConstruction = true;
			})
		},
	}
}
</script>

<style scoped>
.input {
	margin: .5rem .25rem
}
</style>