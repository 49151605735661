import '@babel/polyfill'
import 'mutationobserver-shim'
import _ from "lodash";
import Vue from 'vue'
import './plugins/jquery'
import './plugins/axios'
import './plugins/bootstrap-vue'
import './plugins/fontawesome'
import './plugins/google-maps'
import './plugins/vuetable'
import App from './App.vue'
import store from './store'
import router from './router'
import '@/utils/date';
import '@/utils/string';
import '@/utils/number';
import '@/utils/array';
import '@/utils/object';

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
