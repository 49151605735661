<template>
	<div>
		<loading mode="fixed" image="1" size="lg" v-if="loading"/>
		<h5 v-if="showTitle">電子章</h5>
		<b-row>
			<b-row class="col-12 justify-content-end" v-if="editable && !editing">
				<b-button class="form-btn clear-btn" @click="editing=true">上傳電子章</b-button>
			</b-row>
			<div class="col-12 px-md-3 py-2 p-1" v-if="editing">
				<input-file button="fas fa-folder-open" :accept="'image/*'" size="xs" preview multiple v-model="images" @reload="loadImages"></input-file>
				<b-row style="justify-content:flex-end">
					<b-button class="form-btn cancel-btn" @click="cancel">取消</b-button>
					<b-button class="form-btn submit-btn" @click="uploadSignatures">確定</b-button>
				</b-row>
			</div>
			<photo-album style="flex: 1 1;" :photos="signatures" :keys="{name: 'raw_file_name', src: 'content'}" :photo-class="photoClass" :selectable="selectable" :deletable="deletable" @delete="openSignatureDeleterModal" showname></photo-album>
			<modal v-model="signatureDeleter.showModal" centered @ok="deleteSignature">
				是否確定刪除電子章
			</modal>
		</b-row>
	</div>
</template>

<script>
import Loading from '@/components/Loading/Loading.vue';
import InputFile from '@/components/Input/InputFile.vue'
import PhotoAlbum from '@/components/Album/PhotoAlbum.vue'
import Modal from '@/components/Modal/Modal'
import { getRecursiveValue, checkAllTrue } from '@/utils/assist';

export default {
	name: 'SignatureManager',
	components:{
		PhotoAlbum,
		InputFile,
		Modal,
		Loading
	},
	props: {
		type: {
			type: String,
		},
		id: {
			type: Number,
		},
		signatures: {
			type: Array,
			default: () => []
		},
		showTitle: {
			type: Boolean,
			default: true
		},
		photoClass: {
			type: String,
			default: 'col-xl-1 col-lg-2 col-md-3 col-6'
		}
	},
	data() {
		return {
			call: {},
			loading: true,
			editing: false,
			images: [],
			selectable: true,
			deletable: true,
			signatureDeleter: {
				showModal: false,
				id: -1,
			},
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		loading() {
			this.$emit('loading', this.loading)
		}
	},
	created() {
	},
	mounted() {
	},
	computed: {
		creatable() {
			return this.checkSystemPermission('write') ? true : false
		},
		editable() {
			return this.checkSystemPermission('edit') ? true : false
		},
	},
	methods:{
		reload() {
			this.$emit('reload')
		},
		load(loading) {
			this.loading = loading
		},
		cancel() {
			this.editing = false
			this.images.splice(0, this.images.length);
		},
		checkSystemPermission(action_key) {
			let userPermissions = this.$store.getters.currentUser.permissions
			const system_control_type = this.$store.getters.enum(`permission.system_control.system`)
			let action_type = this.$store.getters.enum(`permission.action_type.${action_key}`)
			return userPermissions.find(permission => {
				let permission_type = this.$store.getters.enum(permission.permission_type_enum)
				return permission.type_index === system_control_type.index && permission_type.data.actions.includes(action_type.index);
			})
		},
		uploadSignatures(evt) {
			if(!this.images.length) {
				alert('請上傳照片')
				return
			}
			this.$set(this.call, 'uploadSignatures', false)
			this.$axios.uploadSignatures({
				owner_type: this.$store.getters.enum(`signature.owner_type.signature_${this.type}`).index,
				owner_id: this.id,
				images: this.images,
			}, (response) => {
				this.$emit('reload')
				alert('新增成功')
				this.call.uploadSignatures = true;
			}, (error) => {
				this.call.uploadSignatures = true;
			})
		},
		openSignatureDeleterModal(photo, index) {
			this.signatureDeleter.id = photo.id
			this.signatureDeleter.showModal = !this.signatureDeleter.showModal;
			if(!this.signatureDeleter.showModal) {
				this.$nextTick(() => {
					this.signatureDeleter.showModal = true;
				})
			}
		},
		deleteSignature() {
			this.$set(this.call, 'deleteSignature', false)
			this.$axios.deleteSignature(this.signatureDeleter.id, (response) => {
				alert("刪除成功")
				this.reload();
				this.call.deleteSignature = true;
			}, (error) => {
				this.call.deleteSignature = true;
			})
		},
		loadImages(images) {
			this.images = images;
		}
	}
}
</script>

<style scoped>
</style>