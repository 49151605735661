const TAIPEI_WATER_HEADER = `<?xml version="1.0" encoding="utf-8"?>
<UTL xmlns:gml="http://www.opengis.net/gml" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:ngis_primitive="http://standards.moi.gov.tw/schema/ngis_primitive/" xmlns:gco="http://www.isotc211.org/2005/gco" xmlns:gmd="http://www.isotc211.org/2005/gmd" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:schemaLocation="http://standards.moi.gov.tw/schema/utilityex utilityex.xsd">
	<gml:metaDataProperty>
		<ngis_primitive:NGIS_Primitive>
			<ngis_primitive:資料描述>臺北市GML</ngis_primitive:資料描述>
			<ngis_primitive:坐標參考系統識別碼>
				<gmd:RS_Identifier>
					<gmd:Code>
						<gco:CharacterString>EPSG:3826</gco:CharacterString>
					</gmd:Code>
				</gmd:RS_Identifier>
			</ngis_primitive:坐標參考系統識別碼>
			<ngis_primitive:坐標參考系統定義 xlink:href="http://standards.moi.gov.tw/schema/epsg/3826.xml" />
			<ngis_primitive:資料內容對應時間>
				<gml:TimeInstant>
					<gml:timePosition>2019-02-21</gml:timePosition>
				</gml:TimeInstant>
			</ngis_primitive:資料內容對應時間>
		</ngis_primitive:NGIS_Primitive>
	</gml:metaDataProperty>
</UTL>`
const TAIPEI_WATER_WATER_PIPELINE = `<gml:featureMember>
<UTL_管線_自來水>
	<geometry>
		<gml:LineString srsName="EPSG:3826" srsDimension="3">
			<gml:posList>300480.185 2779886.144 2.481 1.5 300479.191 2779885.989 2.429 1.5 300479.141 2779886.317 2.429 1.5 300479.141 2779886.317 3.129 0.8 300478.984 2779887.209 3.123 0.8 300478.713 2779887.931 3.084 0.8 300478.713 2779887.931 3.684 0.2 300477.982 2779887.802 3.797 0.2 300476.28 2779887.524 3.858 0.2 300475.818 2779890.014 3.838 0.2 300473.255 2779889.64 3.893 0.2 300472.027 2779889.603 3.974 0.2
			</gml:posList>
		</gml:LineString>
	</geometry>
	<類別碼>8030101</類別碼>
	<識別碼>WP3756B12JL001</識別碼>
	<起點編號/>
	<終點編號/>
	<管理單位>WP</管理單位>
	<作業區分>0</作業區分>
	<設置日期>
		<gml:TimeInstant>
			<gml:timePosition>2019-02-20</gml:timePosition>
		</gml:TimeInstant>
	</設置日期>
	<管線編號>3756B001</管線編號>
	<尺寸單位>0</尺寸單位>
	<管徑寬度>300</管徑寬度>
	<管徑高度>0</管徑高度>
	<涵管條數>1</涵管條數>
	<管線材料>DIP</管線材料>
	<起點埋設深度>0.7</起點埋設深度>
	<終點埋設深度>2</終點埋設深度>
	<管線長度>447.187</管線長度>
	<管線型態>0</管線型態>
	<使用狀態>0</使用狀態>
	<資料狀態>0</資料狀態>
	<備註/>
	<輸送物質>自來水</輸送物質>
	<過溝深度/>
	<涵管行數>1</涵管行數>
	<涵管列數>1</涵管列數>
	<壓力區分/>
	<測量日期>
		<gml:TimeInstant>
			<gml:timePosition>2019-02-21</gml:timePosition>
		</gml:TimeInstant>
	</測量日期>
</UTL_管線_自來水>
</gml:featureMember>`
const TAIPEI_WATER_HYDRANT = `<gml:featureMember>
<UTL_消防栓>
	<geometry>
		<gml:Point srsName="EPSG:3826" srsDimension="3">
			<gml:coordinates>300863.174 2777104.366 4.159</gml:coordinates>
		</gml:Point>
	</geometry>
	<類別碼>8030103</類別碼>
	<識別碼>WP3856C12JP001</識別碼>
	<管理單位>WP</管理單位>
	<作業區分>0</作業區分>
	<設置日期>
		<gml:TimeInstant>
			<gml:timePosition>2019-02-20</gml:timePosition>
		</gml:TimeInstant>
	</設置日期>
	<消防栓編號>3856C001</消防栓編號>
	<管身口徑>150</管身口徑>
	<出水口口徑>150</出水口口徑>
	<埋設深度>0.7</埋設深度>
	<消防栓型態>1</消防栓型態>
	<使用狀態>0</使用狀態>
	<資料狀態>0</資料狀態>
	<備註/>
	<測量日期>
		<gml:TimeInstant>
			<gml:timePosition>2019-02-21</gml:timePosition>
		</gml:TimeInstant>
	</測量日期>
</UTL_消防栓>
</gml:featureMember>`
const TAIPEI_WATER_SWITCH_VALVE = `<gml:featureMember>
<UTL_開關閥>
	<geometry>
		<gml:Point srsName="EPSG:3826" srsDimension="3">
			<gml:coordinates>300864.384 2777104.542 4.453</gml:coordinates>
		</gml:Point>
	</geometry>
	<類別碼>8030104</類別碼>
	<識別碼>WP3856C12JP005</識別碼>
	<管理單位>WP</管理單位>
	<作業區分>0</作業區分>
	<設置日期>
		<gml:TimeInstant>
			<gml:timePosition>2019-02-20</gml:timePosition>
		</gml:TimeInstant>
	</設置日期>
	<開關閥編號>3856C005</開關閥編號>
	<閥類編號>3856C005</閥類編號>
	<口徑>150</口徑>
	<名稱>彈性座封式制水閥</名稱>
	<地盤高>4.453</地盤高>
	<埋設深度>0.7</埋設深度>
	<開關閥型態>0</開關閥型態>
	<使用狀態>0</使用狀態>
	<資料狀態>0</資料狀態>
	<備註/>
	<測量日期>
		<gml:TimeInstant>
			<gml:timePosition>2019-02-21</gml:timePosition>
		</gml:TimeInstant>
	</測量日期>
</UTL_開關閥>
</gml:featureMember>`
const TAIPEI_WATER_HOLE = `<gml:featureMember>
<UTL_人手孔>
	<geometry>
		<gml:Point srsName="EPSG:3826" srsDimension="3">
			<gml:coordinates>301248.041 2777125.053 4.383</gml:coordinates>
		</gml:Point>
	</geometry>
	<類別碼>8030102</類別碼>
	<識別碼>WP4245D0004123</識別碼>
	<管理單位>WP</管理單位>
	<作業區分>0</作業區分>
	<設置日期>
		<gml:TimeInstant>
			<gml:timePosition>1900-01-01</gml:timePosition>
		</gml:TimeInstant>
	</設置日期>
	<人手孔編號>4245D0004123</人手孔編號>
	<孔蓋種類>0</孔蓋種類>
	<尺寸單位>2</尺寸單位>
	<蓋部寬度>80.00000000000</蓋部寬度>
	<蓋部長度>0.00000000000</蓋部長度>
	<閘門名稱/>
	<地盤高>5.93710000000</地盤高>
	<孔深>1.20000000000</孔深>
	<孔蓋型態>1</孔蓋型態>
	<使用狀態>0</使用狀態>
	<資料狀態>0</資料狀態>
	<內容物>排氣閥</內容物>
	<備註/>
	<測量日期>
		<gml:TimeInstant>
			<gml:timePosition>2011-12-19</gml:timePosition>
		</gml:TimeInstant>
	</測量日期>
</UTL_人手孔>
</gml:featureMember>`
const TAIPEI_WATER_OTHER_FACILITY = `<gml:featureMember>
<UTL_其他設施>
	<geometry>
		<gml:Point srsName="EPSG:3826" srsDimension="3">
			<gml:coordinates>301278.041 2777175.053 4.383</gml:coordinates>
		</gml:Point>
	</geometry>
	<類別碼>8030196</類別碼>
	<識別碼>WPT63100000001</識別碼>
	<管理單位>WP</管理單位>
	<作業區分>0</作業區分>
	<設置日期>
		<gml:TimeInstant>
			<gml:timePosition>1900-01-01</gml:timePosition>
		</gml:TimeInstant>
	</設置日期>
	<設施編號>N14</設施編號>
	<設施名稱>湯櫃</設施名稱>
	<設施長度>1</設施長度>
	<設施寬度>0.8</設施寬度>
	<設施高度>0.6</設施高度>
	<設施型態>0</設施型態>
	<使用狀態>0</使用狀態>
	<資料狀態>0</資料狀態>
	<備註/>
	<測量日期>
		<gml:TimeInstant>
			<gml:timePosition>2011-12-19</gml:timePosition>
		</gml:TimeInstant>
	</測量日期>
</UTL_其他設施>
</gml:featureMember>`

const TAIPEI_CITY_OLD_HEADER = `<UTL xmlns="http://standards.moi.gov.tw/schema/utilityex" xmlns:ngis_primitive="http://standards.moi.gov.tw/schema/ngis_primitive" xmlns:gml="http://www.opengis.net/gml" xmlns:gmd="http://www.isotc211.org/gmd" xmlns:gco="http://www.isotc211.org/gco" xmlns:utility="http://standards.moi.gov.tw/schema/utilityex" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" schemaLocation="http://standards.moi.gov.tw/schema/utilityex utilityex.xsd">
<gml:metaDataProperty>
 <ngis_primitive:NGIS_Primitive>
  <ngis_primitive:資料描述>臺北市管線資料</ngis_primitive:資料描述>
  <ngis_primitive:坐標參考系統識別碼>
   <gmd:RS_Identifier>
	<gmd:Code>
	 <gco:CharacterString>EPSG:3826</gco:CharacterString>
	</gmd:Code>
   </gmd:RS_Identifier>
  </ngis_primitive:坐標參考系統識別碼>
  <ngis_primitive:坐標參考系統定義 xlink:href="http://standards.moi.gov.tw/schema/epsg/3826.xml"></ngis_primitive:坐標參考系統定義>
  <ngis_primitive:資料內容對應時間>
   <gml:TimeInstant>
	<gml:timePosition>2020-04-02</gml:timePosition>
   </gml:TimeInstant>
  </ngis_primitive:資料內容對應時間>
 </ngis_primitive:NGIS_Primitive>
</gml:metaDataProperty>
</UTL>`
const TAIPEI_CITY_HEADER = `<UTL xmlns="https://standards.moi.gov.tw/schema/utilityex" xmlns:gml="http://www.opengis.net/gml" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:gco="http://www.isotc211.org/2005/gco" xmlns:gmd="http://www.isotc211.org/2005/gmd" schemaLocation="https://standards.moi.gov.tw/schema/utilityex utilityex.xsd">
</UTL>`
const TAIPEI_CITY_WATER_PIPELINE = `  <gml:featureMember>
<UTL_管線_自來水>
 <geometry>
  <gml:LineString srsName="EPSG:3826" srsDimension="3">
   <gml:posList>301787.200 2771597.667 1.835 301787.242 2771597.339 2.342 301787.288 2771596.978 2.352 301787.282 2771596.495 2.232 301787.287 2771596.296 2.238 301787.306 2771596.088 2.241 301787.308 2771595.902 2.245 301787.440 2771594.395 2.422</gml:posList>
  </gml:LineString>
 </geometry>
 <類別碼>8030101</類別碼>
 <識別碼>109000944-1-1</識別碼>
 <起點編號 />
 <終點編號 />
 <管理單位>WP</管理單位>
 <作業區分>0</作業區分>
 <設置日期>
  <gml:TimeInstant>
   <gml:timePosition>2020-02-29</gml:timePosition>
  </gml:TimeInstant>
 </設置日期>
 <管線編號>109000944-1-1</管線編號>
 <尺寸單位>0</尺寸單位>
 <管徑寬度>150</管徑寬度>
 <管徑高度>0</管徑高度>
 <涵管條數>1</涵管條數>
 <管線材料>DIP</管線材料>
 <起點埋設深度>1.1</起點埋設深度>
 <終點埋設深度>0.6</終點埋設深度>
 <管線長度>3.284</管線長度>
 <管線型態>0</管線型態>
 <使用狀態>0</使用狀態>
 <資料狀態>0</資料狀態>
 <備註/>
 <輸送物質>自來水</輸送物質>
 <過溝深度 />
 <涵管行數>1</涵管行數>
 <涵管列數>1</涵管列數>
 <測量日期>
  <gml:TimeInstant>
   <gml:timePosition>2020-03-21</gml:timePosition>
  </gml:TimeInstant>
 </測量日期>
</UTL_管線_自來水>
</gml:featureMember>`
const TAIPEI_CITY_PIPELINE = `<gml:featureMember>
<UTL_管線>
  <geometry>
	<gml:LineString srsName="EPSG:3826" srsDimension="3">
	  <gml:posList>302759.36 2777579.71 4.34 302764.12 2777583.64 4.35</gml:posList>
	</gml:LineString>
  </geometry>
  <類別碼>8010101</類別碼>
  <識別碼>TH109002066-6</識別碼>
  <起點編號 />
  <終點編號 />
  <管理單位>TH</管理單位>
  <作業區分>0</作業區分>
  <設置日期>
	<gml:TimeInstant>
	  <gml:timePosition>2020-06-04</gml:timePosition>
	</gml:TimeInstant>
  </設置日期>
  <管線編號>TH480341688</管線編號>
  <尺寸單位>1</尺寸單位>
  <管徑寬度>3</管徑寬度>
  <管徑高度>0</管徑高度>
  <涵管條數>2</涵管條數>
  <管線材料>PVC</管線材料>
  <起點埋設深度>0.4</起點埋設深度>
  <終點埋設深度>0.4</終點埋設深度>
  <管線長度>6.2</管線長度>
  <管線型態>0</管線型態>
  <使用狀態>0</使用狀態>
  <資料狀態>0</資料狀態>
  <備註 />
  <測量日期>
	<gml:TimeInstant>
	  <gml:timePosition>2020-06-10</gml:timePosition>
	</gml:TimeInstant>
  </測量日期>
  <過溝深度>0</過溝深度>
  <涵管行數>2</涵管行數>
  <涵管列數>1</涵管列數>
</UTL_管線>
</gml:featureMember>`
const TAIPEI_CITY_HYDRANT = `<gml:featureMember>
<UTL_消防栓>
 <geometry>
  <gml:Point srsName="EPSG:3826" srsDimension="3">
   <gml:coordinates>301787.440 2771594.395 3.022</gml:coordinates>
  </gml:Point>
 </geometry>
 <類別碼>8030103</類別碼>
 <識別碼>109000944-2-1</識別碼>
 <管理單位>WP</管理單位>
 <作業區分>0</作業區分>
 <設置日期>
  <gml:TimeInstant>
   <gml:timePosition>2020-02-29</gml:timePosition>
  </gml:TimeInstant>
 </設置日期>
 <消防栓編號>109000944-2-1</消防栓編號>
 <管身口徑>75</管身口徑>
 <出水口口徑>65</出水口口徑>
 <埋設深度>0.6</埋設深度>
 <消防栓型態>2</消防栓型態>
 <使用狀態>0</使用狀態>
 <資料狀態>0</資料狀態>
 <備註/>
</UTL_消防栓>
</gml:featureMember>`
const TAIPEI_CITY_SWITCH_VALVE = `<gml:featureMember>
<UTL_開關閥>
 <geometry>
  <gml:Point srsName="EPSG:3826" srsDimension="3">
   <gml:coordinates>300543.236 2765226.082 8.578</gml:coordinates>
  </gml:Point>
 </geometry>
 <類別碼>8030104</類別碼>
 <識別碼>242582-3-1</識別碼>
 <管理單位>WA05</管理單位>
 <作業區分>0</作業區分>
 <設置日期>
  <gml:TimeInstant>
   <gml:timePosition>2020-02-11</gml:timePosition>
  </gml:TimeInstant>
 </設置日期>
 <開關閥編號>242582-3-1</開關閥編號>
 <閥類編號>242582-3-1</閥類編號>
 <口徑>200</口徑>
 <名稱>彈性座封式制水閥</名稱>
 <地盤高>8.578</地盤高>
 <埋設深度>0.7</埋設深度>
 <開關閥型態>0</開關閥型態>
 <使用狀態>0</使用狀態>
 <資料狀態>0</資料狀態>
 <備註/>
</UTL_開關閥>
</gml:featureMember>`
const TAIPEI_CITY_HOLE = `<gml:featureMember>
<UTL_人手孔>
	<geometry>
		<gml:Point srsName="EPSG:3826" srsDimension="3">
			<gml:coordinates>301248.041 2777125.053 4.383</gml:coordinates>
		</gml:Point>
	</geometry>
	<類別碼>8030102</類別碼>
	<識別碼>WP4245D0004123</識別碼>
	<管理單位>WP</管理單位>
	<作業區分>0</作業區分>
	<設置日期>
		<gml:TimeInstant>
			<gml:timePosition>1900-01-01</gml:timePosition>
		</gml:TimeInstant>
	</設置日期>
	<人手孔編號>4245D0004123</人手孔編號>
	<孔蓋種類>0</孔蓋種類>
	<尺寸單位>2</尺寸單位>
	<蓋部寬度>80.00000000000</蓋部寬度>
	<蓋部長度>0.00000000000</蓋部長度>
	<閘門名稱/>
	<地盤高>5.93710000000</地盤高>
	<孔深>1.20000000000</孔深>
	<孔蓋型態>1</孔蓋型態>
	<使用狀態>0</使用狀態>
	<資料狀態>0</資料狀態>
	<內容物>排氣閥</內容物>
	<備註/>
</UTL_人手孔>
</gml:featureMember>`
const TAIPEI_CITY_OTHER_FACILITY = `<gml:featureMember>
<UTL_其他設施>
	<geometry>
		<gml:Point srsName="EPSG:3826" srsDimension="3">
			<gml:coordinates>301278.041 2777175.053 4.383</gml:coordinates>
		</gml:Point>
	</geometry>
	<類別碼>8030196</類別碼>
	<識別碼>WPT63100000001</識別碼>
	<管理單位>WP</管理單位>
	<作業區分>0</作業區分>
	<設置日期>
		<gml:TimeInstant>
			<gml:timePosition>1900-01-01</gml:timePosition>
		</gml:TimeInstant>
	</設置日期>
	<設施編號>N14</設施編號>
	<設施名稱>湯櫃</設施名稱>
	<設施長度>1</設施長度>
	<設施寬度>0.8</設施寬度>
	<設施高度>0.6</設施高度>
	<設施型態>0</設施型態>
	<使用狀態>0</使用狀態>
	<資料狀態>0</資料狀態>
	<備註/>
</UTL_其他設施>
</gml:featureMember>`

const NEW_TAIPEI_CITY_OLD_HEADER = `<UTL xmlns="http://standards.moi.gov.tw/schema/utilityex" xmlns:ngis_primitive="http://standards.moi.gov.tw/schema/ngis_primitive" xmlns:gml="http://www.opengis.net/gml" xmlns:gmd="http://www.isotc211.org/gmd" xmlns:gco="http://www.isotc211.org/gco" xmlns:utility="http://standards.moi.gov.tw/schema/utilityex" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" schemaLocation="http://standards.moi.gov.tw/schema/utilityex utilityex.xsd">
<gml:metaDataProperty>
 <ngis_primitive:NGIS_Primitive>
  <ngis_primitive:資料描述>新北市管線資料</ngis_primitive:資料描述>
  <ngis_primitive:坐標參考系統識別碼>
   <gmd:RS_Identifier>
	<gmd:Code>
	 <gco:CharacterString>EPSG:3826</gco:CharacterString>
	</gmd:Code>
   </gmd:RS_Identifier>
  </ngis_primitive:坐標參考系統識別碼>
  <ngis_primitive:坐標參考系統定義 xlink:href="http://standards.moi.gov.tw/schema/epsg/3826.xml"></ngis_primitive:坐標參考系統定義>
  <ngis_primitive:資料內容對應時間>
   <gml:TimeInstant>
	<gml:timePosition>2020-11-30</gml:timePosition>
   </gml:TimeInstant>
  </ngis_primitive:資料內容對應時間>
 </ngis_primitive:NGIS_Primitive>
</gml:metaDataProperty>
</UTL>`
const STANDARD_HEADER = `<?xml version="1.0" encoding="utf-8"?>
<UTL xmlns:gml="http://www.opengis.net/gml" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:gco="http://www.isotc211.org/2005/gco" xmlns:gmd="http://www.isotc211.org/2005/gmd" xsi:schemaLocation="https://standards.moi.gov.tw/schema/utilityex utilityex.xsd" xmlns="https://standards.moi.gov.tw/schema/utilityex">
</UTL>`
const STANDARD_WATER_PIPELINE = `  <gml:featureMember>
<UTL_管線_自來水>
 <geometry>
  <gml:LineString srsName="EPSG:3826" srsDimension="3">
   <gml:posList>300550.649 2765240.649 8.101 300550.460 2765240.094 8.092 300545.255 2765225.489 7.747 300545.176 2765225.267 7.741 300544.353 2765225.820 7.821 300543.236 2765226.082 7.878</gml:posList>
  </gml:LineString>
 </geometry>
 <類別碼>8030101</類別碼>
 <識別碼>242582-1-1</識別碼>
 <起點編號 />
 <終點編號 />
 <管理單位>WA05</管理單位>
 <作業區分>0</作業區分>
 <設置日期>
  <gml:TimeInstant>
   <gml:timePosition>2020-02-11</gml:timePosition>
  </gml:TimeInstant>
 </設置日期>
 <管線編號>242582-1-1</管線編號>
 <尺寸單位>0</尺寸單位>
 <管徑寬度>200</管徑寬度>
 <管徑高度>0</管徑高度>
 <涵管條數>1</涵管條數>
 <管線材料>DIP</管線材料>
 <起點埋設深度>0.6</起點埋設深度>
 <終點埋設深度>0.7</終點埋設深度>
 <管線長度>18.465</管線長度>
 <管線型態>0</管線型態>
 <使用狀態>0</使用狀態>
 <資料狀態>0</資料狀態>
 <備註/>
 <輸送物質>自來水</輸送物質>
</UTL_管線_自來水>
</gml:featureMember>`
const STANDARD_PIPELINE = `<gml:featureMember>
<UTL_管線>
  <geometry>
	<gml:LineString srsName="EPSG:3826" srsDimension="3">
	  <gml:posList>314784.952 2773734.775 9.767 314784.356 2773734.41 9.741 314752.002 2773740.528 10.056</gml:posList>
	</gml:LineString>
  </geometry>
  <類別碼>8010101</類別碼>
  <識別碼>TB14HC017377</識別碼>
  <起點編號 />
  <終點編號 />
  <管理單位>TB14</管理單位>
  <作業區分>0</作業區分>
  <設置日期>
	<gml:TimeInstant>
	  <gml:timePosition>2021-04-09</gml:timePosition>
	</gml:TimeInstant>
  </設置日期>
  <管線編號>TB14HC017377</管線編號>
  <尺寸單位>1</尺寸單位>
  <管徑寬度>6</管徑寬度>
  <管徑高度>6</管徑高度>
  <涵管條數>4</涵管條數>
  <管線材料>pvc</管線材料>
  <起點埋設深度>0.8</起點埋設深度>
  <終點埋設深度>0.8</終點埋設深度>
  <管線長度>30.6</管線長度>
  <管線型態>0</管線型態>
  <使用狀態>0</使用狀態>
  <資料狀態>0</資料狀態>
  <備註 />
</UTL_管線>
</gml:featureMember>`
const STANDARD_HYDRANT = `  <gml:featureMember>
<UTL_消防栓>
 <geometry>
  <gml:Point srsName="EPSG:3826" srsDimension="3">
   <gml:coordinates>300571.389 2765284.681 9.192</gml:coordinates>
  </gml:Point>
 </geometry>
 <類別碼>8030103</類別碼>
 <識別碼>242582-2-1</識別碼>
 <管理單位>WA05</管理單位>
 <作業區分>0</作業區分>
 <設置日期>
  <gml:TimeInstant>
   <gml:timePosition>2020-02-11</gml:timePosition>
  </gml:TimeInstant>
 </設置日期>
 <消防栓編號>242582-2-1</消防栓編號>
 <管身口徑>75</管身口徑>
 <出水口口徑>65</出水口口徑>
 <埋設深度>0.7</埋設深度>
 <消防栓型態>2</消防栓型態>
 <使用狀態>0</使用狀態>
 <資料狀態>0</資料狀態>
 <備註/>
</UTL_消防栓>
</gml:featureMember>`
const STANDARD_SWITCH_VALVE = `  <gml:featureMember>
<UTL_開關閥>
 <geometry>
  <gml:Point srsName="EPSG:3826" srsDimension="3">
   <gml:coordinates>300543.236 2765226.082 8.578</gml:coordinates>
  </gml:Point>
 </geometry>
 <類別碼>8030104</類別碼>
 <識別碼>242582-3-1</識別碼>
 <管理單位>WA05</管理單位>
 <作業區分>0</作業區分>
 <設置日期>
  <gml:TimeInstant>
   <gml:timePosition>2020-02-11</gml:timePosition>
  </gml:TimeInstant>
 </設置日期>
 <開關閥編號>242582-3-1</開關閥編號>
 <閥類編號>242582-3-1</閥類編號>
 <口徑>200</口徑>
 <名稱>彈性座封式制水閥</名稱>
 <地盤高>8.578</地盤高>
 <埋設深度>0.7</埋設深度>
 <開關閥型態>0</開關閥型態>
 <使用狀態>0</使用狀態>
 <資料狀態>0</資料狀態>
 <備註/>
</UTL_開關閥>
</gml:featureMember>`
const STANDARD_HOLE = `<gml:featureMember>
<UTL_人手孔>
	<geometry>
		<gml:Point srsName="EPSG:3826" srsDimension="3">
			<gml:coordinates>301248.041 2777125.053 4.383</gml:coordinates>
		</gml:Point>
	</geometry>
	<類別碼>8030102</類別碼>
	<識別碼>WP4245D0004123</識別碼>
	<管理單位>WP</管理單位>
	<作業區分>0</作業區分>
	<設置日期>
		<gml:TimeInstant>
			<gml:timePosition>1900-01-01</gml:timePosition>
		</gml:TimeInstant>
	</設置日期>
	<人手孔編號>4245D0004123</人手孔編號>
	<孔蓋種類>0</孔蓋種類>
	<尺寸單位>2</尺寸單位>
	<蓋部寬度>80.00000000000</蓋部寬度>
	<蓋部長度>0.00000000000</蓋部長度>
	<閘門名稱/>
	<地盤高>5.93710000000</地盤高>
	<孔深>1.20000000000</孔深>
	<孔蓋型態>1</孔蓋型態>
	<使用狀態>0</使用狀態>
	<資料狀態>0</資料狀態>
	<內容物>排氣閥</內容物>
	<備註/>
</UTL_人手孔>
</gml:featureMember>`
const STANDARD_OTHER_FACILITY = `<gml:featureMember>
<UTL_其他設施>
	<geometry>
		<gml:Point srsName="EPSG:3826" srsDimension="3">
			<gml:coordinates>301278.041 2777175.053 4.383</gml:coordinates>
		</gml:Point>
	</geometry>
	<類別碼>8030196</類別碼>
	<識別碼>WPT63100000001</識別碼>
	<管理單位>WP</管理單位>
	<作業區分>0</作業區分>
	<設置日期>
		<gml:TimeInstant>
			<gml:timePosition>1900-01-01</gml:timePosition>
		</gml:TimeInstant>
	</設置日期>
	<設施編號>N14</設施編號>
	<設施名稱>湯櫃</設施名稱>
	<設施長度>1</設施長度>
	<設施寬度>0.8</設施寬度>
	<設施高度>0.6</設施高度>
	<設施型態>0</設施型態>
	<使用狀態>0</使用狀態>
	<資料狀態>0</資料狀態>
	<備註/>
</UTL_其他設施>
</gml:featureMember>`

const CHIAYI_COUNTY_HEADER = `<UTL xmlns="http://standards.moi.gov.tw/schema/utilityex" xmlns:ngis_primitive="http://standards.moi.gov.tw/schema/ngis_primitive" xmlns:gml="http://www.opengis.net/gml" xmlns:gmd="http://www.isotc211.org/gmd" xmlns:gco="http://www.isotc211.org/gco" xmlns:utility="http://standards.moi.gov.tw/schema/utilityex" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" schemaLocation="http://standards.moi.gov.tw/schema/utilityex utilityex.xsd">
<gml:metaDataProperty>
 <ngis_primitive:NGIS_Primitive>
  <ngis_primitive:資料描述>嘉義縣管線資料</ngis_primitive:資料描述>
  <ngis_primitive:坐標參考系統識別碼>
   <gmd:RS_Identifier>
	<gmd:Code>
	 <gco:CharacterString>EPSG:3826</gco:CharacterString>
	</gmd:Code>
   </gmd:RS_Identifier>
  </ngis_primitive:坐標參考系統識別碼>
  <ngis_primitive:坐標參考系統定義 xlink:href="http://standards.moi.gov.tw/schema/epsg/3826.xml"></ngis_primitive:坐標參考系統定義>
  <ngis_primitive:資料內容對應時間>
   <gml:TimeInstant>
	<gml:timePosition>2020-04-02</gml:timePosition>
   </gml:TimeInstant>
  </ngis_primitive:資料內容對應時間>
 </ngis_primitive:NGIS_Primitive>
</gml:metaDataProperty>
</UTL>`
const CHIAYI_COUNTY_WATER_PIPELINE = `  <gml:featureMember>
<UTL_管線_自來水>
 <geometry>
  <gml:LineString srsName="EPSG:3826" srsDimension="3">
   <gml:posList>194261.633 2611733.150 29.259 194261.623 2611733.258 29.250 194259.618 2611733.505 29.224 194259.573 2611733.748 29.226</gml:posList>
  </gml:LineString>
 </geometry>
 <KINDNO>8030101</KINDNO>
 <PID>DC11218461</PID>
 <PNO>DC11218461</PNO>
 <STARTID>pt1</STARTID>
 <ENDID>pt4</ENDID>
 <PPRESSURE></PPRESSURE>
 <PUNIT>0</PUNIT>
 <PWIDTH>25</PWIDTH>
 <PHEIGHT>0</PHEIGHT>
 <PTOCOUNT>1</PTOCOUNT>
 <PMATERIAL>HIWP</PMATERIAL>
 <PSTARTDP>0.35</PSTARTDP>
 <PENDDP>0.35</PENDDP>
 <PLENGTH>2.376</PLENGTH>
 <PTYPE>0</PTYPE>
 <TRANSMATTER>自來水</TRANSMATTER>
 <PMANAGER></PMANAGER>
 <PCOUNTYCODE>10</PCOUNTYCODE>
 <PTOWNCODE></PTOWNCODE>
 <PTOWNUNIT></PTOWNUNIT>
 <PROADNAME></PROADNAME>
 <PWORKTYPE>0</PWORKTYPE>
 <PFINDATE>
  <gml:TimeInstant>
   <gml:timePosition>2023-09-21</gml:timePosition>
  </gml:TimeInstant>
 </PFINDATE>
 <USETYPE>0</USETYPE>
 <PSTATUS>0</PSTATUS>
 <REMARK/>
 <PFIXDATE>
  <gml:TimeInstant>
   <gml:timePosition>2023-09-21</gml:timePosition>
  </gml:TimeInstant>
 </PFIXDATE>
</UTL_管線_自來水>
</gml:featureMember>`
const CHIAYI_COUNTY_PIPELINE = `<gml:featureMember>
<UTL_管線>
  <geometry>
	<gml:LineString srsName="EPSG:3826" srsDimension="3">
	  <gml:posList>314784.952 2773734.775 9.767 314784.356 2773734.41 9.741 314752.002 2773740.528 10.056</gml:posList>
	</gml:LineString>
  </geometry>
  <KINDNO>8030101</KINDNO>
  <PID>DC11218461</PID>
  <PNO>DC11218461</PNO>
  <STARTID>pt1</STARTID>
  <ENDID>pt4</ENDID>
  <PPRESSURE></PPRESSURE>
  <PUNIT>0</PUNIT>
  <PWIDTH>25</PWIDTH>
  <PHEIGHT>0</PHEIGHT>
  <PTOCOUNT>1</PTOCOUNT>
  <PMATERIAL>HIWP</PMATERIAL>
  <PSTARTDP>0.35</PSTARTDP>
  <PENDDP>0.35</PENDDP>
  <PLENGTH>2.376</PLENGTH>
  <PTYPE>0</PTYPE>
  <TRANSMATTER>自來水</TRANSMATTER>
  <PMANAGER></PMANAGER>
  <PCOUNTYCODE>10</PCOUNTYCODE>
  <PTOWNCODE></PTOWNCODE>
  <PTOWNUNIT></PTOWNUNIT>
  <PROADNAME></PROADNAME>
  <PWORKTYPE>0</PWORKTYPE>
  <PFINDATE>
   <gml:TimeInstant>
	<gml:timePosition>2023-09-21</gml:timePosition>
   </gml:TimeInstant>
  </PFINDATE>
  <USETYPE>0</USETYPE>
  <PSTATUS>0</PSTATUS>
  <REMARK/>
  <PFIXDATE>
   <gml:TimeInstant>
	<gml:timePosition>2023-09-21</gml:timePosition>
   </gml:TimeInstant>
  </PFIXDATE>
</UTL_管線>
</gml:featureMember>`
const CHIAYI_COUNTY_HYDRANT = `  <gml:featureMember>
<UTL_消防栓>
 <geometry>
  <gml:Point srsDimension="3">
   <gml:pos>170082.600 2595738.066</gml:pos>
  </gml:Point>
 </geometry>
 <KINDNO>8030103</KINDNO>
 <HYID>HC11215002</HYID>
 <HYNUM>HC11215002</HYNUM>
 <HYTURN>1</HYTURN>
 <HYSTYLE>1</HYSTYLE>
 <HYDIAMETER>100</HYDIAMETER>
 <HYDIAMETER1>65</HYDIAMETER1>
 <HYDEPTH>0</HYDEPTH>
 <HYMANAGER></HYMANAGER>
 <HYCOUNTYCODE>10</HYCOUNTYCODE>
 <HYTOWNCODE></HYTOWNCODE>
 <HYTOWNUNIT></HYTOWNUNIT>
 <HYROADNAME></HYROADNAME>
 <HYWORKTYPE>0</HYWORKTYPE>
 <HYDATE>
  <gml:TimeInstant>
   <gml:timePosition>2023-08-01</gml:timePosition>
  </gml:TimeInstant>
 </HYDATE>
 <USETYPE>0</USETYPE>
 <HYSTATUS>0</HYSTATUS>
 <REMARK/>
 <HYFIXDATE>
  <gml:TimeInstant>
   <gml:timePosition>2023-08-01</gml:timePosition>
  </gml:TimeInstant>
 </HYFIXDATE>
</UTL_消防栓>
</gml:featureMember>`
const CHIAYI_COUNTY_SWITCH_VALVE = `  <gml:featureMember>
<UTL_開關閥>
 <geometry>
  <gml:Point srsDimension="3">
   <gml:pos>170082.463 2595738.487</gml:pos>
  </gml:Point>
 </geometry>
 <KINDNO>8030104</KINDNO>
 <VID>VC11215001</VID>
 <VNO>VC11215001</VNO>
 <VKNO>VC11215001</VKNO>
 <VNAME>彈性座封式制水閥</VNAME>
 <VDIAMETER>100</VDIAMETER>
 <VGROUPHEIGHT>1.844</VGROUPHEIGHT>
 <VDEPTH>1.2</VDEPTH>
 <GD>0</GD>
 <VMANAGER></VMANAGER>
 <VCOUNTYCODE>10</VCOUNTYCODE>
 <VTOWNCODE></VTOWNCODE>
 <VTOWNUNIT></VTOWNUNIT>
 <VROADNAME></VROADNAME>
 <VWORKTYPE>0</VWORKTYPE>
 <VDATE>
  <gml:TimeInstant>
   <gml:timePosition>2023-08-01</gml:timePosition>
  </gml:TimeInstant>
 </VDATE>
 <USETYPE>0</USETYPE>
 <VSTATUS>0</VSTATUS>
 <REMARK/>
 <VFIXDATE>
  <gml:TimeInstant>
   <gml:timePosition>2023-08-01</gml:timePosition>
  </gml:TimeInstant>
 </VFIXDATE>
</UTL_開關閥>
</gml:featureMember>`
const CHIAYI_COUNTY_HOLE = `  <gml:featureMember>
<UTL_人手孔>
  <geometry>
	<gml:Point srsDimension="3">
	  <gml:pos>301248.041 2777125.053</gml:pos>
	</gml:Point>
  </geometry>
  <KINDNO>8030102</KINDNO>
  <HID>WP4245D0004123</HID>
  <HNO>4245D0004123</HNO>
  <HTOPTYPE>0</HTOPTYPE>
  <GD>1</GD>
  <HUNIT>2</HUNIT>
  <HTOPWIDTH>80</HTOPWIDTH>
  <HTOPLENGTH>0</HTOPLENGTH>
  <GATENAME/>
  <HGROUPHEIGHT>5.9371</HGROUPHEIGHT>
  <HDEPTH>1.2</HDEPTH>
  <HDESCRIPTION></HDESCRIPTION>
  <INSIDE></INSIDE>
  <HMANAGER></HMANAGER>
  <HCOUNTYCODE>10</HCOUNTYCODE>
  <HTOWNCODE></HTOWNCODE>
  <HTOWNUNIT></HTOWNUNIT>
  <HROADNAME></HROADNAME>
  <HWORKTYPE>0</HWORKTYPE>
  <HFINDATE>
	<gml:TimeInstant>
	  <gml:timePosition>1900-01-01</gml:timePosition>
	</gml:TimeInstant>
  </HFINDATE>
  <USETYPE>0</USETYPE>
  <HSTATUS>0</HSTATUS>
  <REMARK/>
  <HFIXDATE>
   <gml:TimeInstant>
	<gml:timePosition>2023-08-01</gml:timePosition>
   </gml:TimeInstant>
  </HFIXDATE>
</UTL_人手孔>
</gml:featureMember>`
const CHIAYI_COUNTY_OTHER_FACILITY = `<gml:featureMember>
<UTL_其他設施>
	<geometry>
		<gml:Point srsDimension="3">
			<gml:pos>301278.041 2777175.053</gml:pos>
		</gml:Point>
	</geometry>
	<KINDNO>8030196</KINDNO>
	<識別碼>WPT63100000001</識別碼>
	<管理單位>WP</管理單位>
	<作業區分>0</作業區分>
	<設置日期>
		<gml:TimeInstant>
			<gml:timePosition>1900-01-01</gml:timePosition>
		</gml:TimeInstant>
	</設置日期>
	<設施編號>N14</設施編號>
	<設施名稱>湯櫃</設施名稱>
	<設施長度>1</設施長度>
	<設施寬度>0.8</設施寬度>
	<設施高度>0.6</設施高度>
	<設施型態>0</設施型態>
	<使用狀態>0</使用狀態>
	<資料狀態>0</資料狀態>
	<備註/>
</UTL_其他設施>
</gml:featureMember>`

const YUNLIN_COUNTY_WATER_PIPELINE = `  <gml:featureMember>
<UTL_管線_自來水>
 <geometry>
  <gml:LineString srsName="EPSG:3826" srsDimension="3">
   <gml:posList>300550.649 2765240.649 8.101 300550.460 2765240.094 8.092 300545.255 2765225.489 7.747 300545.176 2765225.267 7.741 300544.353 2765225.820 7.821 300543.236 2765226.082 7.878</gml:posList>
  </gml:LineString>
 </geometry>
 <類別碼>8030101</類別碼>
 <識別碼>242582-1-1</識別碼>
 <起點編號 />
 <終點編號 />
 <管理單位>WA05</管理單位>
 <作業區分>0</作業區分>
 <設置日期>
  <gml:TimeInstant>
   <gml:timePosition>2020-02-11</gml:timePosition>
  </gml:TimeInstant>
 </設置日期>
 <管線編號>242582-1-1</管線編號>
 <尺寸單位>0</尺寸單位>
 <管徑寬度>200</管徑寬度>
 <管徑高度>0</管徑高度>
 <涵管條數>1</涵管條數>
 <管線材料>DIP</管線材料>
 <起點埋設深度>0.6</起點埋設深度>
 <終點埋設深度>0.7</終點埋設深度>
 <管線長度>18.465</管線長度>
 <管線型態>0</管線型態>
 <使用狀態>0</使用狀態>
 <資料狀態>0</資料狀態>
 <品質分級>2</品質分級>
 <備註/>
 <輸送物質>自來水</輸送物質>
</UTL_管線_自來水>
</gml:featureMember>`
const YUNLIN_COUNTY_PIPELINE = `<gml:featureMember>
<UTL_管線>
  <geometry>
	<gml:LineString srsName="EPSG:3826" srsDimension="3">
	  <gml:posList>314784.952 2773734.775 9.767 314784.356 2773734.41 9.741 314752.002 2773740.528 10.056</gml:posList>
	</gml:LineString>
  </geometry>
  <類別碼>8010101</類別碼>
  <識別碼>TB14HC017377</識別碼>
  <起點編號 />
  <終點編號 />
  <管理單位>TB14</管理單位>
  <作業區分>0</作業區分>
  <設置日期>
	<gml:TimeInstant>
	  <gml:timePosition>2021-04-09</gml:timePosition>
	</gml:TimeInstant>
  </設置日期>
  <管線編號>TB14HC017377</管線編號>
  <尺寸單位>1</尺寸單位>
  <管徑寬度>6</管徑寬度>
  <管徑高度>6</管徑高度>
  <涵管條數>4</涵管條數>
  <管線材料>pvc</管線材料>
  <起點埋設深度>0.8</起點埋設深度>
  <終點埋設深度>0.8</終點埋設深度>
  <管線長度>30.6</管線長度>
  <管線型態>0</管線型態>
  <使用狀態>0</使用狀態>
  <資料狀態>0</資料狀態>
  <品質分級>2</品質分級>
  <備註 />
</UTL_管線>
</gml:featureMember>`
const YUNLIN_COUNTY_HYDRANT = `  <gml:featureMember>
<UTL_消防栓>
 <geometry>
  <gml:Point srsName="EPSG:3826" srsDimension="3">
   <gml:coordinates>300571.389 2765284.681 9.192</gml:coordinates>
  </gml:Point>
 </geometry>
 <類別碼>8030103</類別碼>
 <識別碼>242582-2-1</識別碼>
 <管理單位>WA05</管理單位>
 <作業區分>0</作業區分>
 <設置日期>
  <gml:TimeInstant>
   <gml:timePosition>2020-02-11</gml:timePosition>
  </gml:TimeInstant>
 </設置日期>
 <消防栓編號>242582-2-1</消防栓編號>
 <管身口徑>75</管身口徑>
 <出水口口徑>65</出水口口徑>
 <埋設深度>0.7</埋設深度>
 <消防栓型態>2</消防栓型態>
 <使用狀態>0</使用狀態>
 <資料狀態>0</資料狀態>
 <品質分級>2</品質分級>
 <備註/>
</UTL_消防栓>
</gml:featureMember>`
const YUNLIN_COUNTY_SWITCH_VALVE = `  <gml:featureMember>
<UTL_開關閥>
 <geometry>
  <gml:Point srsName="EPSG:3826" srsDimension="3">
   <gml:coordinates>300543.236 2765226.082 8.578</gml:coordinates>
  </gml:Point>
 </geometry>
 <類別碼>8030104</類別碼>
 <識別碼>242582-3-1</識別碼>
 <管理單位>WA05</管理單位>
 <作業區分>0</作業區分>
 <設置日期>
  <gml:TimeInstant>
   <gml:timePosition>2020-02-11</gml:timePosition>
  </gml:TimeInstant>
 </設置日期>
 <開關閥編號>242582-3-1</開關閥編號>
 <閥類編號>242582-3-1</閥類編號>
 <口徑>200</口徑>
 <名稱>彈性座封式制水閥</名稱>
 <地盤高>8.578</地盤高>
 <埋設深度>0.7</埋設深度>
 <開關閥型態>0</開關閥型態>
 <使用狀態>0</使用狀態>
 <資料狀態>0</資料狀態>
 <品質分級>2</品質分級>
 <備註/>
</UTL_開關閥>
</gml:featureMember>`
const YUNLIN_COUNTY_HOLE = `<gml:featureMember>
<UTL_人手孔>
	<geometry>
		<gml:Point srsName="EPSG:3826" srsDimension="3">
			<gml:coordinates>301248.041 2777125.053 4.383</gml:coordinates>
		</gml:Point>
	</geometry>
	<類別碼>8030102</類別碼>
	<識別碼>WP4245D0004123</識別碼>
	<管理單位>WP</管理單位>
	<作業區分>0</作業區分>
	<設置日期>
		<gml:TimeInstant>
			<gml:timePosition>1900-01-01</gml:timePosition>
		</gml:TimeInstant>
	</設置日期>
	<人手孔編號>4245D0004123</人手孔編號>
	<孔蓋種類>0</孔蓋種類>
	<尺寸單位>2</尺寸單位>
	<蓋部寬度>80.00000000000</蓋部寬度>
	<蓋部長度>0.00000000000</蓋部長度>
	<閘門名稱/>
	<地盤高>5.93710000000</地盤高>
	<孔深>1.20000000000</孔深>
	<孔蓋型態>1</孔蓋型態>
	<使用狀態>0</使用狀態>
	<資料狀態>0</資料狀態>
	<品質分級>2</品質分級>
	<內容物>排氣閥</內容物>
	<備註/>
</UTL_人手孔>
</gml:featureMember>`
const YUNLIN_COUNTY_OTHER_FACILITY = `<gml:featureMember>
<UTL_其他設施>
	<geometry>
		<gml:Point srsName="EPSG:3826" srsDimension="3">
			<gml:coordinates>301278.041 2777175.053 4.383</gml:coordinates>
		</gml:Point>
	</geometry>
	<類別碼>8030196</類別碼>
	<識別碼>WPT63100000001</識別碼>
	<管理單位>WP</管理單位>
	<作業區分>0</作業區分>
	<設置日期>
		<gml:TimeInstant>
			<gml:timePosition>1900-01-01</gml:timePosition>
		</gml:TimeInstant>
	</設置日期>
	<設施編號>N14</設施編號>
	<設施名稱>湯櫃</設施名稱>
	<設施長度>1</設施長度>
	<設施寬度>0.8</設施寬度>
	<設施高度>0.6</設施高度>
	<設施型態>0</設施型態>
	<使用狀態>0</使用狀態>
	<資料狀態>0</資料狀態>
	<品質分級>2</品質分級>
	<備註/>
</UTL_其他設施>
</gml:featureMember>`

const Templates = {
	// 北水
	TAIPEI_WATER: {
		key: 'TAIPEI_WATER',
		tag: { root: 'UTL', meta: 'gml:metaDataProperty', feature: 'gml:featureMember' },
		templates: {
			header: TAIPEI_WATER_HEADER,
			water_pipeline: TAIPEI_WATER_WATER_PIPELINE, // 管線_自來水
			hole: TAIPEI_WATER_HOLE, // 人手孔
			switch_valve: TAIPEI_WATER_SWITCH_VALVE, // 開關閥
			hydrant: TAIPEI_WATER_HYDRANT, // 消防栓
			other_facility: TAIPEI_WATER_OTHER_FACILITY, // 其他設施
			// station: TAIPEI_WATER_STATION, // 場站
		},
		xml_header: { version: "1.0", encoding: "utf-8" },
	},
	// 台北（舊版）
	TAIPEI_CITY_OLD: {
		key: 'TAIPEI_CITY_OLD',
		tag: { root: 'UTL', meta: 'gml:metaDataProperty', feature: 'gml:featureMember' },
		templates: {
			header: TAIPEI_CITY_OLD_HEADER,
			pipeline: TAIPEI_CITY_PIPELINE, // 管線
			water_pipeline: TAIPEI_CITY_WATER_PIPELINE, // 管線_自來水
			// gas_pipeline: TAIPEI_CITY_GAS_PIPELINE, // 管線_供氣
			// oil_pipeline: TAIPEI_CITY_OIL_PIPELINE, // 管線_輸油
			// industrial_pipeline: TAIPEI_CITY_INDUSTRIAL_PIPELINE, // 管線_工業
			// duct: TAIPEI_CITY_DUCT, // 管道
			hole: TAIPEI_CITY_HOLE, // 人手孔
			switch_valve: TAIPEI_CITY_SWITCH_VALVE, // 開關閥
			hydrant: TAIPEI_CITY_HYDRANT, // 消防栓
			// pole: TAIPEI_CITY_POLE, // 電桿
			// signal: TAIPEI_CITY_SIGNAL, // 號誌
			other_facility: TAIPEI_CITY_OTHER_FACILITY, // 其他設施
			// maintenance_port: TAIPEI_CITY_MAINTENANCE_PORT, // 維護口
			// station: TAIPEI_CITY_STATION, // 場站
		},
		xml_header: {},
	},
	// 台北
	TAIPEI_CITY: {
		key: 'TAIPEI_CITY',
		tag: { root: 'UTL', meta: 'gml:metaDataProperty', feature: 'gml:featureMember' },
		templates: {
			header: TAIPEI_CITY_HEADER,
			pipeline: TAIPEI_CITY_PIPELINE, // 管線
			water_pipeline: TAIPEI_CITY_WATER_PIPELINE, // 管線_自來水
			// gas_pipeline: TAIPEI_CITY_GAS_PIPELINE, // 管線_供氣
			// oil_pipeline: TAIPEI_CITY_OIL_PIPELINE, // 管線_輸油
			// industrial_pipeline: TAIPEI_CITY_INDUSTRIAL_PIPELINE, // 管線_工業
			// duct: TAIPEI_CITY_DUCT, // 管道
			hole: TAIPEI_CITY_HOLE, // 人手孔
			switch_valve: TAIPEI_CITY_SWITCH_VALVE, // 開關閥
			hydrant: TAIPEI_CITY_HYDRANT, // 消防栓
			// pole: TAIPEI_CITY_POLE, // 電桿
			// signal: TAIPEI_CITY_SIGNAL, // 號誌
			other_facility: TAIPEI_CITY_OTHER_FACILITY, // 其他設施
			// maintenance_port: TAIPEI_CITY_MAINTENANCE_PORT, // 維護口
			// station: TAIPEI_CITY_STATION, // 場站
		},
		xml_header: {},
	},
	// 新北（舊版）
	NEW_TAIPEI_CITY_OLD: {
		key: 'NEW_TAIPEI_CITY_OLD',
		tag: { root: 'UTL', meta: 'gml:metaDataProperty', feature: 'gml:featureMember' },
		templates: {
			header: NEW_TAIPEI_CITY_OLD_HEADER,
			pipeline: STANDARD_PIPELINE, // 管線
			water_pipeline: STANDARD_WATER_PIPELINE, // 管線_自來水
			// gas_pipeline: STANDARD_GAS_PIPELINE, // 管線_供氣
			// oil_pipeline: STANDARD_OIL_PIPELINE, // 管線_輸油
			// industrial_pipeline: STANDARD_INDUSTRIAL_PIPELINE, // 管線_工業
			// duct: STANDARD_DUCT, // 管道
			hole: STANDARD_HOLE, // 人手孔
			switch_valve: STANDARD_SWITCH_VALVE, // 開關閥
			hydrant: STANDARD_HYDRANT, // 消防栓
			// pole: STANDARD_POLE, // 電桿
			// signal: STANDARD_SIGNAL, // 號誌
			other_facility: STANDARD_OTHER_FACILITY, // 其他設施
			// maintenance_port: STANDARD_MAINTENANCE_PORT, // 維護口
			// station: STANDARD_STATION, // 場站
		},
		xml_header: { version: "1.0", encoding: "utf-8" },
	},
	TAOYUAN_CITY: {
		key: 'TAOYUAN_CITY',
		tag: { root: 'UTL', meta: 'gml:metaDataProperty', feature: 'gml:featureMember' },
		templates: {
			header: STANDARD_HEADER,
			pipeline: STANDARD_PIPELINE, // 管線
			water_pipeline: STANDARD_WATER_PIPELINE, // 管線_自來水
			// gas_pipeline: STANDARD_GAS_PIPELINE, // 管線_供氣
			// oil_pipeline: STANDARD_OIL_PIPELINE, // 管線_輸油
			// industrial_pipeline: STANDARD_INDUSTRIAL_PIPELINE, // 管線_工業
			// duct: STANDARD_DUCT, // 管道
			hole: STANDARD_HOLE, // 人手孔
			switch_valve: STANDARD_SWITCH_VALVE, // 開關閥
			hydrant: STANDARD_HYDRANT, // 消防栓
			// pole: STANDARD_POLE, // 電桿
			// signal: STANDARD_SIGNAL, // 號誌
			other_facility: STANDARD_OTHER_FACILITY, // 其他設施
			// maintenance_port: STANDARD_MAINTENANCE_PORT, // 維護口
			// station: STANDARD_STATION, // 場站
		},
		xml_header: { version: "1.0", encoding: "utf-8" },
	},
	// 嘉義縣
	CHIAYI_COUNTY: {
		key: 'CHIAYI_COUNTY',
		tag: { root: 'UTL', meta: 'gml:metaDataProperty', feature: 'gml:featureMember' },
		templates: {
			header: CHIAYI_COUNTY_HEADER,
			pipeline: CHIAYI_COUNTY_PIPELINE, // 管線
			water_pipeline: CHIAYI_COUNTY_WATER_PIPELINE, // 管線_自來水
			// gas_pipeline: STANDARD_GAS_PIPELINE, // 管線_供氣
			// oil_pipeline: STANDARD_OIL_PIPELINE, // 管線_輸油
			// industrial_pipeline: STANDARD_INDUSTRIAL_PIPELINE, // 管線_工業
			// duct: STANDARD_DUCT, // 管道
			hole: CHIAYI_COUNTY_HOLE, // 人手孔
			switch_valve: CHIAYI_COUNTY_SWITCH_VALVE, // 開關閥
			hydrant: CHIAYI_COUNTY_HYDRANT, // 消防栓
			// pole: STANDARD_POLE, // 電桿
			// signal: STANDARD_SIGNAL, // 號誌
			other_facility: CHIAYI_COUNTY_OTHER_FACILITY, // 其他設施
			// maintenance_port: STANDARD_MAINTENANCE_PORT, // 維護口
			// station: STANDARD_STATION, // 場站
		},
		xml_header: {},
	},
	// 雲林縣
	YUNLIN_COUNTY: {
		key: 'YUNLIN_COUNTY',
		tag: { root: 'UTL', meta: 'gml:metaDataProperty', feature: 'gml:featureMember' },
		templates: {
			header: STANDARD_HEADER,
			pipeline: YUNLIN_COUNTY_PIPELINE, // 管線
			water_pipeline: YUNLIN_COUNTY_WATER_PIPELINE, // 管線_自來水
			// gas_pipeline: YUNLIN_COUNTY_GAS_PIPELINE, // 管線_供氣
			// oil_pipeline: YUNLIN_COUNTY_OIL_PIPELINE, // 管線_輸油
			// industrial_pipeline: YUNLIN_COUNTY_INDUSTRIAL_PIPELINE, // 管線_工業
			// duct: YUNLIN_COUNTY_DUCT, // 管道
			hole: YUNLIN_COUNTY_HOLE, // 人手孔
			switch_valve: YUNLIN_COUNTY_SWITCH_VALVE, // 開關閥
			hydrant: YUNLIN_COUNTY_HYDRANT, // 消防栓
			// pole: YUNLIN_COUNTY_POLE, // 電桿
			// signal: YUNLIN_COUNTY_SIGNAL, // 號誌
			other_facility: YUNLIN_COUNTY_OTHER_FACILITY, // 其他設施
			// maintenance_port: YUNLIN_COUNTY_MAINTENANCE_PORT, // 維護口
			// station: YUNLIN_COUNTY_STATION, // 場站
		},
		xml_header: { version: "1.0", encoding: "utf-8" },
	},
	// 營建署標準格式
	STANDARD: {
		key: 'STANDARD',
		tag: { root: 'UTL', meta: 'gml:metaDataProperty', feature: 'gml:featureMember' },
		templates: {
			header: STANDARD_HEADER,
			pipeline: STANDARD_PIPELINE, // 管線
			water_pipeline: STANDARD_WATER_PIPELINE, // 管線_自來水
			// gas_pipeline: STANDARD_GAS_PIPELINE, // 管線_供氣
			// oil_pipeline: STANDARD_OIL_PIPELINE, // 管線_輸油
			// industrial_pipeline: STANDARD_INDUSTRIAL_PIPELINE, // 管線_工業
			// duct: STANDARD_DUCT, // 管道
			hole: STANDARD_HOLE, // 人手孔
			switch_valve: STANDARD_SWITCH_VALVE, // 開關閥
			hydrant: STANDARD_HYDRANT, // 消防栓
			// pole: STANDARD_POLE, // 電桿
			// signal: STANDARD_SIGNAL, // 號誌
			other_facility: STANDARD_OTHER_FACILITY, // 其他設施
			// maintenance_port: STANDARD_MAINTENANCE_PORT, // 維護口
			// station: STANDARD_STATION, // 場站
		},
		xml_header: { version: "1.0", encoding: "utf-8" },
	},
}
export default Templates



