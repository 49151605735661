<template>
	<div>
		<loading mode="fixed" image="1" size="lg" v-show="loading"/>
		<h5>基本資料</h5>
		<b-row class="justify-content-between" v-if="!editing">
			<ul class="mb-2 col-12" style="flex: 1 1;">
				<template v-for="(item, key) in basicData">
					<b-list-group-item class="d-flex border-0 py-0 px-md-3 px-1" :key="key" style="word-wrap: break-word; white-space: normal;" v-if="Object.get(project, key)">
						<a :href="Object.get(project, key)" target="_blank" v-if="item.type==='link'">{{item.name}}</a>
						<template v-else-if="item.type==='tags'">
							<tag :title="tag.name" :description="tag.note" v-for="tag in project.unit_tags" :key="tag.id" style="font-size: 0.9rem;"></tag>
						</template>
						<template v-else><b style="flex: 0 1 7rem;">{{item.name}}：</b>{{Object.get(project, key)}}</template>
					</b-list-group-item>
				</template>
			</ul>
			<div>
				<b-button class="form-btn clear-btn" @click="editing=true" v-if="editable">編輯</b-button>
			</div>
		</b-row>
		<b-row class="justify-content-end" v-if="!editing">
			<b-button class="form-btn submit-btn" @click="checkProjectStatus" v-if="editable">檢查案件狀態</b-button>
		</b-row>
		<project-info-editor :project="project" @cancel="editing=false" @submit="reload" v-else></project-info-editor>
		<hr />
		<construction-data-viewer :construction-data="constructionData" :projectDataType="projectDataType" :permission="permission" @loading="load" @reload="reload"></construction-data-viewer>
		<hr />
		<unit-report-viewer :project="project" :projectUnitReports="project.unit_reports" :projectDataType="projectDataType" :permission="permission" @loading="load" @reload="reload"></unit-report-viewer>
	</div>
</template>


<script>
import Tag from '@/components/Tag/Tag.vue'
import ProjectInfoEditor from '@/components/Project/Info/ProjectInfoEditor'
import ConstructionDataViewer from '@/components/Project/Info/ConstructionDataViewer'
import UnitReportViewer from '@/components/Project/Info/UnitReportViewer'
import Loading from '@/components/Loading/Loading.vue';
import { isEmpty, checkAllTrue } from '@/utils/assist';

export default {
	name: 'ProjectInfo',
	components: {
		Tag,
		ProjectInfoEditor,
		ConstructionDataViewer,
		UnitReportViewer,
		Loading,
	},
	props: {
		project: {
			type: Object,
			default: () => { return {} }
		},
		projectDataType: {
			type: Array,
			default: () => []
		},
		group: {
			type: Object
		},
		permission: {
			type: Object,
		}
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			// permissionType: Number.isInteger(parseInt(this.$route.params.permissionType)) ? parseInt(this.$route.params.permissionType) : -1,
			call: {},
			loading: true,
			basicData: {},
			editing: false,
		}
	},
	created() {
		this.basicData = {
			id: { name: 'ID', hide: !this.$store.getters.isDeveloper },
			project_type: { name: '案件種類', hide: !this.$store.getters.isDeveloper },
			construction_type: { name: '施工種類'},
			excavation_permit_name: { name: "路證編號" },
			emergency_repair_number: { name: "搶修路證編號" },
			work_number: { name: "施工案號/W案號" },
			serial_number: { name: "流水號" },
			work_order_number:{ name: "工作單編號" },
			// approved_start_at: { name: "路證核准起日" },
			// approved_end_at: { name: "路證核准迄日" },
			constructed_start_at: { name: "路證施工起日" },
			constructed_end_at: { name: "路證施工迄日" },
			expected_constructed_at: { name: "施工日期" },
			first_received_at: {name: "首次收件日期"},
			received_at: {name: "收件日期"},
			completely_assigned_at: {name: "完整交付日期"},
			assigned_at: { name: "派工日期" },
			measured_at: { name: "測量日期" },
			accepted_at: { name: "估驗日期" },
			file_made_at: { name: "文件製作日期" },
			finish_at: { name: "結案日期" },
			status: { name: "案件狀態" },
			party_a_name: { name: "甲方" },
			party_a_branch_name: { name: "甲方區處" },
			supervisor_name: { name: "監工" },
			commissioner_name: { name: "承辦人" },
			contractor_name: { name: "承包商" },
			pipeline_center_name: { name: "道管中心" },
			bid_name: { name: "標案" },
			construction_name: { name: "工程" },
			address: { name: "施工地點" },
			scene_pictures_url: { name: "資料連結", type: "link" },
			unit_tags: { name: "", type: "tags" }			
		}
		Object.keys(this.basicData).forEach(key => {
			if(this.basicData[key].hide)
				this.$delete(this.basicData, key)
		})
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	mounted() {
	},
	computed: {
		editable() {
			return this.checkPermission('edit') ? true : false
		},
		constructionData() {
			return this.project.construction_data.map(datum => {
				return {
					...datum,
					project_data_type: this.projectDataType.find(type => type.id === datum.project_data_type_id),
					data_list: this.project.data_list.filter(d => d.type_id === datum.project_data_type_id).map(datum => {
						if(datum.point) {
							datum.point.name = `pt${datum.point.index}`
						}
						return datum
					}),
				}
			})
		}
	},
	methods:{
		reload() {
			// this.$emit("reload")
			location.reload()
		},
		load(loading) {
			this.loading = loading
		},
		checkPermission(action_key) {
			if(!this.groupID) return this.$store.getters.isDeveloper;
			if(!this.permission) return false;
			return this.permission.actions.find(action => action.key === action_key)
		},
		checkProjectStatus() {
			this.$set(this.call, 'checkProjectStatus', false)
			this.$axios.checkProjectStatus({
				project_type: this.projectType,
				project_id: this.projectID,
				user_id: this.$store.getters.currentUser.id,
			}, (response) => {
				console.log(response)
				alert('更新成功')
				// this.reload()
				this.call.checkProjectStatus = true;
			}, (error) => {
				this.call.checkProjectStatus = true;
			})
		},
	}
}
</script>

<style scoped>
ul > li {
	list-style: none;
	display: flex;
}
ul > li > *:first-child {
	flex: 0 1 30%;
	align-self: flex-start;
	justify-content: flex-end;
	margin-left: 1rem;
}
.info-outter-wrapper {
	width: 100%;
}
.info-wrapper > div {
	padding: .5rem;
}
.basic-wrapper {
	padding: .5rem;
	margin-bottom: 1rem;
}
.basic-wrapper:last-child {
	margin-bottom: 0;
}
.tabs-wrapper {
	height: 100%;
}
.tabs-wrapper > .tab-content {
	height: 100%;
}
.tab-body {
	padding: .5rem 1rem;
	border: 1px solid #dee2e6;
	border-top: 0;
	height: 100%;
}
.timeline-outside-wrapper {
	height: 4.5rem;
}
.timeline-wrapper {
	position: fixed;
	left: 4rem;
	bottom: 0;
	width: calc(100% - 4rem);
	width: -webkit-calc(100% - 4rem);
	width: -moz-calc(100% - 4rem);
	width: -o-calc(100% - 4rem);
	padding: .5rem;
}
@media (max-width:991px) {
	.timeline-wrapper {
		left: 0;
		width: 100%;
		display: none;
	}
}
.timeline {
	min-height: 4rem;
}
.project-data-wrapper {
	border-bottom: 1px solid #dee2e6;
	min-height: 2rem;
}
.project-data-wrapper:last-child {
	border: none;
}


.right-top {
	position: absolute;
	top: 0;
	right: 0;
}


.google-map {
	min-height: 300px;
	height: 400px;
}


.progress-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0005;
	z-index: 10;
}
.progress-bar-wrapper {
	width: 50%;
	margin: auto;
	top: 50%;
	background: #e1e6e690;
	box-shadow: 0 0 5px .5px #284a7090;
}
.progress-bar {
	background: #017ca7;
}
.progress-bar-striped {
	background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
	background-size: 1rem 1rem;
}
.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite;
}
</style>
