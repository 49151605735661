<template>
	<b-form @submit="onSubmit">
		<b-row>
			<!-- <input-text class="input" label="任務名稱" label-class="col-3" v-model="result.name"></input-text> -->
			<input-select class="input" :label="`測量人員${i}`" label-class="col-3" :options="users" :keys="{value: 'id', text: 'name'}" v-model="result.outsiders_id[i - 1]" v-for="i in outsider_per_project" :key="i"></input-select>
			<input-select class="input" label="任務目的" label-class="col-3" :options="missionPorposeList" :keys="{value: 'index', text: 'text'}" v-model="result.purpose_index"></input-select>
		</b-row>
		<b-row style="justify-content: center">
			<b-button class="form-btn submit-btn" type="submit" variant="primary">指派任務</b-button>
		</b-row>
	</b-form>
</template>

<script>
import InputSelect from '@/components/Input/InputSelect'
import InputText from '@/components/Input/InputText'
import { isEmpty, deepCopy } from '@/utils/assist';
export default {
	name: 'MissionCreator',
	components:{
		InputSelect,
		InputText
	},
	data() {
		return {
			outsider_per_project: 1,
			form: [ 'name', 'outsiders_id', 'purpose_index' ],
			result: {},
			oriResult: {
				outsiders_id: [],
				purpose_index: 0
			},
			check: {},
			users: [],
			missionPorposeList: [],
		}
	},
	watch: {
		result: {
			deep: true,
			handler() {
				this.$emit('change', this.result);
			}
		},
	},
	created() {
		this.getUsers();
		this.setResult();
		this.missionPorposeList = this.$store.getters.enum('mission.purpose');
		if(!this.$store.getters.isDeveloper) {
			let index = this.missionPorposeList.findIndex(item => item.key === 'test');
			if(index >= 0)
				this.missionPorposeList.splice(index, 1);
		}
	},
	mounted() {
	},
	computed: {
	},
	methods:{
		getUsers() {
			this.$axios.getUserList({}, (response) => {
				response.data.unshift('')
				this.users = response.data
			}, (error) => {
				alert(error)
			})
		},
		checkResult() {
			let res = true
			for(let i in this.form) {
				if(!this.result.hasOwnProperty(this.form[i]) || (typeof this.result[this.form[i]] === 'object' && isEmpty(this.result[this.form[i]])) || this.result[this.form[i]] == null || typeof this.result[this.form[i]] === "undefined") {
					this.$set(this.check, this.form[i], false)
					res = false
				}
				else if(this.check.hasOwnProperty(this.form[i])) {
					this.$delete(this.check, this.form[i])
				}
			}
			return res
		},
		onSubmit(evt) {
			evt.preventDefault();
			this.checkResult()
			this.$emit('createMission', this.result);
			this.setResult();
		},
		setResult() {
			this.$set(this, 'result', deepCopy(this.oriResult));
		}
	}
}
</script>

<style scoped>
.input {
	margin: .5rem .25rem
}
</style>