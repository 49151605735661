<template>
	<span>
		<b-row id="Row" class="float-right search-bar form-input-wrapper" v-show="search">
			<b-form-input size="sm" class="col-10 input-text" v-model="searchText" @input="searchData"></b-form-input>
			<button class="col-2 input-label link" for="search"><i class="fa-fw fas fa-search" aria-hidden="true"></i></button>
			<span class="input-background"></span>
		</b-row>
		<div id="Table" class="table-responsive" @scroll="scrollTable">
			<b-table ref="Table" class="bv-table" borderless striped hover show-empty :sticky-header="tableConfig.stickyHeader" :fields="tableData.columns" :items="tableData.rows" :busy.sync="tableConfig.busy" :current-page="tableConfig.currentPage" :per-page="tablePaging.perPage" :selectable="tableConfig.selectable" :select-mode="tableConfig.selectMode" :tbody-tr-class="getRowClass" :tbody-tr-attr="getRowAttr" @row-clicked="onRowClicked">
				<template v-slot:head()="data">
					<span class="link sort-header" @click="sortDataByKey(data.column)" v-if="data.field.control.sortable" :data-order="tableOptions.sort[data.column]" :data-key="data.column" :ref="`col_sort_${data.column}`">
						{{ data.label }}<i :class="`fa-fw fas fa-sort${tableOptions.sort[data.column] === 'asc' ? '-alpha-up' : tableOptions.sort[data.column] === 'desc' ? '-alpha-down' : ''}`"></i>
					</span>
					<span v-else>{{ data.label }}</span>
					<b-row class="form-input-wrapper size-xs" v-if="data.field.control.searchable">
						<b-form-input size="xs" class="col-10 input-text" v-model="tableOptions.search[data.column]" @change="searchDataByKey(data.column)" :ref="`col_search_${data.column}`"></b-form-input>
						<button class="col-2 input-label link"><i class="fa-fw fas fa-search" aria-hidden="true"></i></button>
						<span class="input-background"></span>
					</b-row>
				</template>
				<template v-slot:cell(selected)="{ item, field: { key } }" >
					<b-checkbox v-model="item[key]" v-if="tableConfig.selectable"></b-checkbox>
				</template>
				<template v-slot:cell()="data">
					<b-row class="align-items-center justify-content-end" style="flex-wrap:nowrap;" v-if="data.field.key === 'action' && (tableConfig.rowDetail || tableConfig.actionsColumn)">
						<slot name="actions" :actions="tableData.actions">
							<!-- <div style="text-align: center;"> -->
								<template v-for="(act, key) in actions">
									<template v-if="!checkHide(act.hide, getDataIndex(data.index))">
										<button :key="key" class="table-btn d-inline-flex align-items-center p-1" :class="act.button.class" style="vertical-align: middle;" :style="act.button.style" v-if="act.action.type==='function'" @click="clickActionButton(act.action.function, data.index)">
											<i class="fa-fw" :class="act.button.icon" v-if="act.button.icon"></i>
											<template v-if="act.button.label">{{act.button.label}}</template>
										</button>
										<a :key="key" class="table-btn d-inline-flex align-items-center p-1" :class="act.button.class" style="vertical-align: middle;" :style="act.button.style" v-else-if="act.action.type==='link'" :href="typeof act.action.link ==='function' ? clickActionButton(act.action.link, data.index) : act.action.link" :target="act.action.target">
											<i class="fa-fw" :class="act.button.icon" v-if="act.button.icon"></i>
											<template v-if="act.button.label">{{act.button.label}}</template>
										</a>
										<router-link :key="key" class="table-btn d-inline-flex align-items-center p-1" :class="act.button.class" style="vertical-align: middle;" :style="act.button.style" v-else-if="act.action.type==='route'" :to="typeof act.action.route ==='function' ? clickActionButton(act.action.route, data.index) : act.action.route" :target="act.action.target">
											<i class="fa-fw" :class="act.button.icon" v-if="act.button.icon"></i>
											<template v-if="act.button.label">{{act.button.label}}</template>
										</router-link>
									</template>
								</template>
							<!-- </div> -->
						</slot>
						<button @click="styleDetailRow" class="table-btn align-items-center p-1" :row.prop="data" v-show="tableConfig.rowDetail">
							<i class="fa-fw fas fa-chevron-down"></i>
						</button>
					</b-row>
					<!-- <template v-else-if="data.field.key === 'selected' && tableConfig.selectable && tableConfig.selectMode">
						<span aria-hidden="true" :style="data.rowSelected ? 'visibility: visible' : 'visibility: hidden'">&check;</span>
					</template> -->
					<template v-else-if="typeof data.value === 'object' && data.value.type === 'image'">
						<img :src="data.value.url" style="max-width: 90%">
					</template>
					<template v-else>
						<span :style="data.field.tdStyle" class="align-items-center">{{ data.value }}</span>
					</template>
				</template>
				<template v-slot:row-details="row">
					<template v-for="(datum, ind) in [getDataDetail(row.index)]">
						<template v-for="(column, key) in tableData.detailColumns">
							<li v-if="datum[column.key]" class="pb-1" :key="`${ind}-${key}`">
								<b>{{ column.label }}：</b>
								<template v-if="column.data.type === 'html'" v-html="datum"></template>
								<img v-else-if="column.data.type === 'image'" class="loading" :src="datum[column.key]" style="max-width: 50%" :style="{backgroundImage:`url(${loading_img_url})`, ...column.style}" @load="loadImage">
								<template v-if="column.data.type === 'link'"><a :href="datum[column.key]" target="_blank">{{column.label}}</a></template>
								<template v-else>{{ datum[column.key] }}</template>
							</li>
						</template>
					</template>
					<!-- <template v-if="typeof getDatumInfo(getAbsoluteIndex(row.index)) === 'object'">
						<span v-for="(value, key) in getDatumInfo(getAbsoluteIndex(row.index))" :key="key">
							<li v-if="value" class="mb-1">
								<b>{{ key }}：</b>
								<div :id="`${key}_${getAbsoluteIndex(row.index)}_loading`" class="loading" style="max-width: 50%" :style="{backgroundImage:`url(${loading_img_url})`, ...value.style}" v-if="typeof value === 'object' && value.type === 'image'"></div>
								<img :id="`${key}_${getAbsoluteIndex(row.index)}`" class="d-none" :src="value.url" style="max-width: 50%" :style="value.style" v-if="typeof value === 'object' && value.type === 'image'" @load="loadImage">
								<font v-else>{{ value }}</font>
							</li>
						</span>
					</template>
					<div v-else v-html="getDatumInfo(getAbsoluteIndex(row.index))"></div> -->
				</template>
				<template v-slot:table-busy>
					<div class="text-center"><b-spinner class="align-middle"></b-spinner></div>
				</template>
				<template v-slot:empty>
					<div class="text-center"><label class="align-middle">無可顯示資料</label></div>
				</template>
				<template v-slot:emptyfiltered>
					<div class="text-center"><label class="align-middle">無可顯示資料</label></div>
				</template>
			</b-table>
		</div>
		<div class="table-bar row mb-3">
			<div class="col-12 pl-3" style="color: gray; font-size: .9em;" :style="{visibility: tableConfig.showStatistic ? 'visible' : 'hidden'}">
				第 {{tablePaging.from}} - {{tablePaging.to}} 筆 （ 共 {{tablePaging.total}} 筆 ）
			</div>
			<div class="col-12">
				<b-pagination v-model="tablePaging.currentPage" :total-rows="tablePaging.total" :per-page="tablePaging.perPage" align="right" :limit="tablePaging.pageLimit" class="my-0" style="align-self: flex-end;" v-show="tablePaging.lastPage > 1" @change="changePage"></b-pagination>
			</div>
		</div>
	</span>
</template>

<script>
import axios from 'axios';
import { deepCopy, isEmpty, sortArray, oneOf, isEqual } from '@/utils/assist';
import loading_img from '@/assets/images/loading.gif';

export default {
	components:{
	},
	props: {
		columns: {
			type: Array
		},
		data: {
			type: Array
		},
		detailColumns: {
			type: Array,
			default: () => []
		},
		configs: {
			type: Object,
			default: () => { return {} }
		},
		paging: {
			type: Object,
			default: () => { return {} }
		},
		actions: {
			type: Array,
			default: () => []
		},
		search: {
			type: Boolean,
			default: false
		},
		rowConfigs: {
			type: Object,
			default: () => { return {} }
		},
		sortOptions: {
			type: Object,
			default: () => { return {} }
		},
		searchOptions: {
			type: Object,
			default: () => { return {} }
		}
	},
	data() {
		return {
			loading_img_url: loading_img,
			tableConfig: {
				busy: true,
				overflow: 'scroll', 
				rowDetail: false, 
				actionsColumn: false,
				showStatistic: false,
				stickyHeader: true,
				// mergeColumnCells: false,
				// mergeColumns: []
			},
			tablePaging: {
				count: 0,
				currentPage: 1,
				from: 0,
				lastPage: 1,
				perPage: 15,
				to: 0,
				total: 0,
				pageLimit: 5,
			},
			tableData: {
				columns: [],
				rows: [],
				actions: [],
				detailColumns: [],
				details: [],
			},
			tableOptions: {
				sort: {},
				search: {}
			},
			selected: [],
			allData: [],
			searchText: "",
			tableStyle: {
				width: 0
			},
			status: {
				setStickyColumns: false,
			},
			// dataOptions: {
			// 	sort: {},
			// 	search: {}
			// }
		}
	},
	watch: {
		data: {
			deep: true,
			handler() {
				this.setTableData();
			}
		},
		paging: {
			deep: true,
			handler() {
				this.setTablePaging()
			}
		},
		'configs.busy': {
			handler(value) {
				this.tableConfig.busy = value
			}
		},
	},
	created() {
		this.init()
	},
	destroyed() {
		if(this.tableConfig.overflow === 'hide')
			window.removeEventListener('resize', this.handleResize)
	},
	mounted() {
		if(this.tableConfig.overflow === 'hide')
			this.handleResize();
	},
	updated() {
		if(this.tableConfig.overflow === 'hide')
			this.handleResize();
		this.status.setStickyColumns = false;
		this.scrollTable()
	},
	computed: {
		getColumnsKeys() {
			return this.tableData.columns.map(column => column.key);
		},
		getRowAttr(item, type) {
			return (item, type) => {
				if ((type !== 'row' && type !== 'row-details') || !item || !item._row_attr)
					return ''
				return item._row_attr
			}
		},
		getRowClass(item, type) {
			return (item, type) => {
				let class_name = '';
				if ((type !== 'row' && type !== 'row-details') || !item)
					class_name += ''
				else if(item) {
					class_name += item._row_class + (this.tableConfig.selectable && item.selected ? 'b-table-row-selected table-primary cursor-pointer' : 'cursor-pointer')
				}
				return class_name
			}
		},
		dataOptions() {
			return {
				sort: this.tableData.columns.reduce((obj, column) => {
					let ref = this.$refs[`col_sort_${column.key}`]
					if(ref && ref.dataset["order"]) {
						obj[column.key] = ref.dataset["order"]
					}
					return obj;
				}, {}),
				search: this.tableData.columns.reduce((obj, column) => {
					let ref = this.$refs[`col_search_${column.key}`]
					if(ref) {
						let searchStr = ref.$refs.input.value.trim()
						if(searchStr)
							obj[column.key] = searchStr
					}
					return obj;
				}, {})
			}
		},
	},
	methods:{
		init() {
			this.setTableConfigs()
			this.initTableColumns()
			this.setTableData();
			this.setTableColumns();
			if(typeof this.configs.busy === 'undefined')
				this.tableConfig.busy = false;
			if(this.tableConfig.overflow === 'hide')
				window.addEventListener('resize', this.handleResize)
			this.setTablePaging()
		},
		setTableConfigs() {
			this.tableConfig = Object.assign(this.tableConfig, deepCopy({
				...this.configs,
				selectMode: this.tableConfig.selectable && oneOf(this.tableConfig.selectMode.toLowerCase(), ['single', 'multi', 'range']) ? this.tableConfig.selectMode : 'single'
			}))
			this.tableOptions = Object.assign(this.tableOptions, {
				sort: deepCopy(this.sortOptions),
				search: deepCopy(this.searchOptions),
			})
		},
		scrollTable(e) {
			if(this.tableConfig.stickyHeader && !this.status.setStickyColumns) {
				this.$refs.Table.$el.scrollLeft = 0;
				this.setStickyColumns();
			}
		},
		getButtonElement(target) {
			while(target.tagName.toLowerCase() !== 'button') {
				target = target.parentNode
			}
			return target
		},
		handleResize() {
			let table = this.$refs.Table;
			if(this.$el.clientWidth < table.offsetWidth + this.getRootElementFontSize() * 1.25 * 2) {
				for (let i = this.tableData.columns.length - 2; i > 0; i--) {
					if(!this.tableData.columns[i].control.hide) {
						this.tableData.columns[i].control.hide = true;
						break;
					}
				}
				this.setTableColumns();
			}
			else {
				for(let i in this.tableData.columns) {
					if(this.tableData.columns[i].control.hide) {
						this.tableData.columns[i].control.hide = false
						break;
					}
				}
				this.setTableColumns();
			}
		},
		setStickyColumns() {
			let columns = Array.from(this.$refs.Table.$el.querySelectorAll("table > thead > tr > th"))//.map(th => th.offsetWidth);
			let left = 0;
			this.tableData.columns.forEach((column, index) => {
				if(column.stickyColumn) {
					this.$set(column, "tdAttr", Object.assign({}, column.tdAttr, { style: `left: ${left}px` }))
					this.$set(column, "thAttr", Object.assign({}, column.thAttr, { style: `left: ${left}px` }))
					left += columns[index].offsetWidth;
				}
			})
			this.status.setStickyColumns = true;
		},
		styleDetailRow(e) {
			let target = this.getButtonElement(e.target)
			target.row.toggleDetails();
			this.$nextTick(function () {
				let main_rows = this.$el.querySelectorAll(".b-table-has-details");
				for(let i = 0; i < main_rows.length; i++) {
					let row = main_rows[i];
					let detail = this.$el.querySelector("#" + main_rows[i].getAttribute('aria-owns'))
					detail.addEventListener('mouseenter', () => {
						row.classList.add('hover');
					})
					detail.addEventListener('mouseleave', () => {
						row.classList.remove('hover');
					})
				}
			})
		},
		initTableColumns() {
			const control_key = { sortable: false, searchable: false, hide: false }
			const data_key = { deep: false, default: undefined, format: v => v }
			this.tableData.columns.splice(0, this.tableData.columns.length)
			this.tableData.columns = this.columns.map(col => {
				return {
					key: '', label: '', stickyColumn: false, isRowHeader: false,
					thClass: Object.keys(control_key).filter(ctrl_key => col[ctrl_key]).map(ctrl_key => `th-${ctrl_key}`).join(' '),
					tdStyle: '', tdAttr: {}, thAttr: {},
					...Object.keys(col).reduce((obj, key) => {
						if(!Object.keys(control_key).includes(key) && !Object.keys(data_key).includes(key))
							obj[key] = col[key];
						if(key === 'thClass')
							obj[key] += Object.keys(control_key).filter(ctrl_key => col[ctrl_key]).map(ctrl_key => `th-${ctrl_key}`).join(' ');
						return obj;
					}, {}),
					control: {
						...deepCopy(control_key),
						...Object.keys(control_key).reduce((obj, ctrl_key) => {
							if(col[ctrl_key] !== undefined) {
								obj[ctrl_key] = col[ctrl_key];
							}
							return obj;
						}, {})
					},
					data: {
						...deepCopy(data_key),
						...Object.keys(data_key).reduce((obj, d_key) => {
							if(col[d_key] !== undefined) {
								obj[d_key] = col[d_key];
							}
							return obj;
						}, {})
					},
				}
			})
			if(this.tableConfig.selectable && this.tableConfig.selectMode) {
				this.tableData.columns.unshift({
					key: 'selected', label: '',
					control: { sortable: false, searchable: false, hide: false }, thStyle: "width: 1rem",
					data: deepCopy(data_key)
				});
			}
			if(this.tableConfig.rowDetail || this.tableConfig.actionsColumn) {
				this.tableData.columns.push({
					key: 'action', label: '',
					control: { sortable: false, searchable: false, hide: false }, thClass: "",
					data: deepCopy(data_key)
				});
			}
			this.initTableDetailColumns()
			this.$forceUpdate()
		},
		initTableDetailColumns() {
			const control_key = { hide: false }
			const data_key = { deep: false, default: undefined, type: 'text', format: v => v }
			this.tableData.detailColumns.splice(0, this.tableData.detailColumns.length)
			this.tableData.detailColumns = this.detailColumns.map(col => {
				return {
					key: '', label: '', style: '',
					...Object.keys(col).reduce((obj, key) => {
						if(!Object.keys(control_key).includes(key) && !Object.keys(data_key).includes(key))
							obj[key] = col[key];
						return obj;
					}, {}),
					control: {
						...deepCopy(control_key),
						...Object.keys(control_key).reduce((obj, ctrl_key) => {
							if(col[ctrl_key] !== undefined) {
								obj[ctrl_key] = col[ctrl_key];
							}
							return obj;
						}, {})
					},
					data: {
						...deepCopy(data_key),
						...Object.keys(data_key).reduce((obj, d_key) => {
							if(col[d_key] !== undefined) {
								obj[d_key] = col[d_key];
							}
							return obj;
						}, {})
					},
				}
			})
		},
		setTableColumns() {
			this.initTableColumns()
			for(let index = 0; index < this.tableData.columns.length; index++) {
				let column = this.tableData.columns[index];
				if(column.control.hide) {
					this.tableData.columns.splice(index, 1);
					index--;
					continue;
				}
			}
			this.$forceUpdate()
		},
		setTableData() {
			this.allData = [];
			this.tableData.details.splice(0, this.tableData.details.length)
			this.data.forEach((datum, index) => {
				this.allData.push({
					"_index": index,
					...this.getDataByColumns(this.tableData.columns, datum, index),
					"_row_class": this.setTableDataRowAttrs("class", datum, index),
					"_row_attr": this.setTableDataRowAttrs("attr", datum, index)
				});
				this.tableData.details.push({
					"_index": index,
					data: this.getDataByColumns(this.tableData.detailColumns, datum, index)
				})
			})
			this.tableData.rows = deepCopy(this.allData);
			if(this.tableConfig.actionsColumn)
				this.tableData.actions = deepCopy(this.actions)
			
		},
		setTableDataRowAttrs(key, row, index) {
			if(!this.rowConfigs[key])
				return ""
			if(typeof this.rowConfigs[key] === 'string')
				return this.rowConfigs[key]
			if(Array.isArray(this.rowConfigs[key]) && this.rowConfigs[key][index])
				return this.rowConfigs[key][index]
			if(typeof this.rowConfigs[key] === 'function') {
				return this.rowConfigs[key](row, index)
			}
			return ""
		},
		setTablePaging() {
			this.tablePaging = {
				...this.tablePaging,
				...this.paging
			}
		},
		searchData(search) {
			if(isEmpty(search)) {
				this.tableData.rows = deepCopy(this.allData);
				return
			}
			let searchArr = search.split(" ")
			this.tableData.rows = this.allData.filter((row, row_index) => {
				let detail_info = this.getDataDetail(row_index, true)
				let result = false;
				for(let index in searchArr) {
					if(searchArr[index].length >= 1)
						result |= [...Object.values(row), ...Object.values(detail_info)].toString().includes(searchArr[index]);
				}
				return result
			});
		},
		sortDataByKey(key) {
			let ref = this.$refs[`col_sort_${key}`]
			this.$delete(this.tableOptions.sort, key)
			this.tableOptions.sort[key] = ref.dataset["order"] !== "asc" ? "asc" : "desc"
			ref.dataset["order"] = this.tableOptions.sort[key]
			this.$emit('sort', this.tableOptions);
		},
		searchDataByKey(key) {
			if(!this.tableOptions.search[key])
				this.$delete(this.tableOptions.search, key)
			this.$emit('search', this.tableOptions);
		},
		checkOverflow(element) {
			return (element ? element.scrollWidth : null) > (element ? element.offsetWidth : null)
		},
		getRootElementFontSize() {
			return parseFloat(getComputedStyle(document.documentElement).fontSize);	
		},
		// onRowSelected(rows) {
		// 	this.$emit('row-selected', this.data.filter((datum, index) => this.$refs.Table.isRowSelected(index) ))
		// },
		onRowClicked(item){
			if(!this.tableConfig.selectable){return}
			this.$set(item, 'selected', !item.selected)
			let index = this.selected.indexOf(item._index)
			if(index >= 0){
				this.selected.splice(index, 1)
			}
			else {
				this.selected.push(item._index)
			}
			this.$emit('row-selected', this.data.filter((datum, index) => this.selected.includes(index)))
		},
		clickActionButton(onclick, showedIndex) {
			let index = this.getDataIndex(showedIndex)
			return onclick(index)
		},
		getDataDetail(showedIndex, absolute=false) {
			let index = this.getDataIndex(showedIndex, absolute)
			return this.tableData.details[index].data
		},
		getDataIndex(showedIndex, absolute=false) {
			let sortedIndex = absolute ? showedIndex : this.getAbsoluteIndex(showedIndex)
			return this.tableData.rows[sortedIndex] ? this.tableData.rows[sortedIndex]._index : -1
		},
		getAbsoluteIndex(index) {
			return index;
			// return (this.tableConfig.currentPage - 1) * this.tableConfig.perPage + index;
		},
		loadImage(e) {
			e.target.classList.remove("loading")
			e.target.style.backgroundImage = 'unset'
		},
		getDataByColumns(columns, datum, index, key='key') {
			return columns.reduce((row, column) => {
				if(this.checkHide(column.control.hide, index)) return row;
				let temp = datum;
				if(column.data.deep) {
					let keys = column.key.split(column.delim ? column.delim : ".");
					while(keys.length && temp) {
						temp = temp[keys.shift()];
					}
				}
				else
					temp = datum[column.key];
				if(typeof temp !== "undefined") {
					row[column[key]] = temp;
					if(column.data.format && typeof column.data.format === 'function')  {
						row[column[key]] = column.data.format(temp, datum);
					}
				}
				else if(column.data.default !== undefined) {
					row[column[key]] = column.data.default
				}
				return row
			}, {});
		},
		checkHide(hide, index) {
			if(!hide) return false;
			if(typeof hide === 'function') return hide(index);
			return hide
		},
		changePage() {
			this.$nextTick(() => {
				this.$emit('changePage', this.tablePaging.currentPage)
			})
		},
	}
}
</script>

<style scoped>
.table-paging {
	margin: 1rem;
}
.search-bar {
	margin: 0 1rem;
}
.form-input-wrapper {
	padding: 0 1rem;
	border-radius: 1.5rem;
	height: 2.5rem;
	display: flex;
	align-items: center;
}
.form-input-wrapper.size-xs {
	border-radius: 0.5rem;
	height: 1.8rem;
}
.form-input-wrapper.size-xs .input-label {
	display: flex;
	align-items: center;
}
.form-input-wrapper.size-xs .input-label [data-icon="search"] {
	font-size: 0.8rem
}
/* 清除自動填字背景色 */
.input-text:-webkit-autofill,
.input-text:-webkit-autofill:hover,
.input-text:-webkit-autofill:focus,
.input-text:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
	-webkit-transition: background-color 5000s ease-in-out 0s;
	-moz-transition: background-color 5000s ease-in-out 0s;
	-o-transition: background-color 5000s ease-in-out 0s;
	box-shadow: 0 0 0 30px white inset !important;
	-webkit-box-shadow: 0 0 0 30px white inset !important;
	-moz-box-shadow: 0 0 0 30px white inset !important;
	-o-box-shadow: 0 0 0 30px white inset !important;
}
.form-input-wrapper.size-xs .input-text {
	height: 1rem;
	font-size: 0.9rem
}
.input-text {
	border: none;
	z-index: 3;
}
.input-text:focus {
	border: none;
	box-shadow: none;
}
.input-text:focus + .input-label + .input-background {
	border: 1px solid #9bc8de;
}
.input-text.is-invalid + .input-label + .input-background{
	border: 1px solid red;
}
.link:hover {
	cursor: pointer;
	text-decoration: none;
}
.input-background {
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 1.5rem;
	border: 1px solid #e0e8e7;
	width: 100%;
	height: 100%;
}
.input-label {
	background-color: transparent;
	border: none;
	z-index: 3;
}
.table-bar {
	display: flex;
}
@media (min-width:576px) {
	.table-bar {
		flex-direction: row;
		justify-content: flex-end;
	}
}

@media (max-width:575px) {
	.table-bar {
		flex-direction: column;
	}
}
.table-btn {
	color: #4c6b8a;
	margin: 0 .5rem;
	background-color: transparent;
	border: none;
	border-radius: .5rem;
}
.table-btn:hover {
	color: #284a70;
	/* background-color: #0001; */
	text-decoration: none;
	font-weight: 400;
	filter: drop-shadow(0 0 1px #0005);
}
.table-btn:focus {
	box-shadow: none !important;
}
.action-btn {
	padding: .25rem .75rem;
	border-radius: .5rem;
	background: #b2c8d8aa;
	color: #050b10cc;
	border-color: #b2c8d8;
}
.action-btn:hover {
	text-decoration: none;
	background: #8ea9c2cc;
	color: #050b10;
}
.action-btn:focus {
	box-shadow: none;
}
.loading {
	width: 100%;
	height: 100%;
	/* background-color: transparent; */
	background-repeat: no-repeat;
	background-size: 50px;
	background-position: center;
	min-height: 200px;
}
</style>