<template>
	<div class="info-outter-wrapper w-100">
		<loading class="info-wrapper" v-if="loading"/>
		<template class="info-wrapper" v-else>
			<div class="info-wrapper tab-wrapper px-md-2 px-0 w-100">
				<router-view class="w-100" :group="nowGroup" :permission="groupPermission"></router-view>
			</div>
		</template>
	</div>
</template>

<script>
import Loading from '@/components/Loading/Loading.vue'
import { deepCopy, isEmpty, checkAllTrue } from '@/utils/assist';

export default {
	name: "Group",
	components: {
		Loading,
	},
	props: {
		group: {
			type: Object,
		},
		groupID: {
			required: true,
			default: 0
		},
		from: {
			default: null
		}
	},
	data() {
		return {
			group_id: Number.isInteger(parseInt(this.groupID)) ? parseInt(this.groupID) : 0,
			call: { getGroupPermission: false },
			loading: true,
			menu: [
				{ title: "專案資訊", icon: "fas fa-info-circle", key: "info", to: this.listRouteTo, path: this.getRoute({name: '專案資訊'}) },
				{ title: "案件列表", icon: "fas fa-info-circle", key: "info", to: this.listRouteTo, path: this.getRoute({name: '專案案件列表'}) },
				{ title: "工地列表", icon: "fas fa-cogs", key: "sites", to: this.listRouteTo, path: this.getRoute({name: '專案工地列表'}) },
				{ title: "新增案件", icon: "fas fa-cogs", key: "sites", to: this.listRouteTo, path: this.getRoute({name: '新增專案案件'}) },
			],
			prevRoute: null,
			groupPermissions: [],
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
		$route(to, from) {
			if(this.from && this.prevRoute && this.from.name !== this.prevRoute.name)
				this.init();
			this.prevRoute = from
		},
		groupID(value, old) {
			if(parseInt(value) !== parseInt(old)) {
				this.redirector()
			}
		}
	},
	created() {
		this.init();
	},
	computed: {
		showMenu(key) {
			return (key) => {
				let item = this.menu.find(item => item.key === key)
				return item && !(typeof item.hide === 'function' ? item.hide() : item.hide)
			}
		},
		routeParams() {
			return {
				groupID: this.group_id,
				group: this.nowGroup,
				permission: this.groupPermission
			}
		},
		nowGroup() {
			return this.group;
		},
		groupPermission() {
			let groupPermission = this.groupPermissions.find(permission => permission.project_group_id == this.group_id)
			const actions = this.$store.getters.enum(`permission.action_type`)
			if(this.$store.getters.isDeveloper) {
				groupPermission = this.getDefaultPermissionByKey('super')
			}
			else if(!groupPermission) {
				groupPermission = this.getDefaultPermissionByKey('none')
			}
			let permission = this.$store.getters.enum(groupPermission.permission_type_enum)
			return {
				...groupPermission,
				actions: permission.data.actions.map(action_index => {
					return actions.find(action => action.index === action_index)
				})
			}
		}
	},
	methods: {
		init() {
			if(this.$route.name !== '專案') {
				this.prevRoute = this.$route
			}
			// this.getGroupInfo();
			this.getGroupPermission();
		},
		onLoadEnd() {
			this.$nextTick().then(() => {
				this.redirector()
			})
		},
		getGroupInfo() {
			this.call.getGroupInfo = false
			if(!this.group_id) {
				this.group = {
					id: 0,
					name: '未分類',
				}
				this.call.getGroupInfo = true;
			}
			else {
				this.$axios.getGroupInfo(this.group_id, (response) => {
					this.group = response.data
					this.call.getGroupInfo = true
				}, (error) => {
					this.call.getGroupInfo = true
				})
			}
		},
		getGroupPermission() {
			this.call.getGroupPermission = false
			if(!this.group_id) {
				this.groupPermissions = []
				this.call.getGroupPermission = true;
			}
			this.$axios.getGroupPermissionList({project_group_id: this.group_id, user_id: this.$store.getters.currentUser.id}, (response) => {
				this.groupPermissions = response.data
				this.call.getGroupPermission = true
			}, (error) => {
				this.call.getGroupPermission = true
			})
		},
		redirector() {
			if(!this.checkPermission('read')) {
				alert('無此專案權限')
				this.routeTo({name: '專案列表'})
			}
			else if(this.prevRoute) {
				if(this.menu.map(o=>o.path.name).includes(this.prevRoute.name) && this.prevRoute.name !== this.$route.name){
					this.routeTo({name: this.prevRoute.name})
				}
				else if(this.prevRoute.name === '專案') {
					this.routeTo({name: '專案資訊'})
				}
			}
			else {
				if(this.$route.name === '專案'){
					this.routeTo({name: '專案資訊'})
				}
			}
		},
		routeTo(to) {
			this.$router.replace(this.getRoute(to))
			.catch(err => {
				this.$router.replace(this.getRoute(to))
			})
		},
		listRouteTo(to) {
			if(this.$router.currentRoute.name !== to.name) {
				this.$router.push(this.getRoute(to))
			}
		},
		getRoute(path) {
			return {
				...path,
				params: this.routeParams
			}
		},
		clickListItem(e, item) {
			e.preventDefault();
			item.to(item.path);
		},
		checkPermission(action_key) {
			if(!this.group_id) return this.$store.getters.isDeveloper;
			if(!this.groupPermission) return false;
			return this.groupPermission.actions.find(action => action.key === action_key)
			// let permission_type = this.$store.getters.enum(this.groupPermission.permission_type_enum)
			// let action = this.$store.getters.enum(`permission.action_type.${action_key}`)
			// return permission_type.data.actions.includes(action.index);
		},
		getDefaultPermissionByKey(key) {
			let permission = this.$store.getters.enum(`permission.permission_type.${key}`)
			return {
				permission_type: permission. text,
				permission_type_enum: `permission.permission_type.${key}`,
				permission_type_index: permission.index,
				permission_type_key: permission.key,
				project_group_id: this.group_id,
				user_id: this.$store.getters.currentUser.id,
				user_name: this.$store.getters.currentUser.name,
			}
		}
	}
}
</script>

<style>
.info-outter-wrapper {
	width: 100%;
}
.info-wrapper > div {
	padding: .5rem;
}
.info-wrapper h5 {
	white-space: normal;
}
.info-wrapper > div {
	padding: .5rem;
}
.info-wrapper h5{
	white-space: normal;
}

.tab-wrapper {
	margin-top: 1rem;
}
.tab-wrapper > .tab-header {
	padding: 1rem 1.5rem 0 1.5rem;
	display: flex;
	align-content: center;
	justify-content: space-around;
}
.tab-wrapper > .tab-header > .tab-header-item {
	flex: 1 1 auto;
	text-align: center;
	padding: .5rem 1rem;
}
.tab-wrapper > .tab-header > .tab-header-item * {
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #768b9a;
}
/* .tab-wrapper > .tab-header > .tab-header-item:hover {
	background-color: #c3dfe850;
} */
.tab-wrapper > .tab-header > .tab-header-item:hover * {
	text-decoration: none;
	color: #515763;
	filter: drop-shadow(0 0 1px #0002);
}
.tab-wrapper > .tab-header > .tab-header-item.active * {
	text-decoration: none;
	color: #050b10;
}
.tab-wrapper > .tab-content {
	padding: 0 1rem 0 1rem;
}
@media (min-width:576px) {
	.tab-wrapper > .tab-header {
		padding: 1rem 1.5rem 0 1.5rem;
		flex-direction: row;
	}
	.tab-wrapper > .tab-header > .tab-header-item * {
		justify-content: center;
	}
	.tab-wrapper > .tab-header > .tab-header-item:hover {
		border-radius: .5rem .5rem 0 0;
		border: 1px solid #768b9a50;
		border-bottom: none;
	}
	.tab-wrapper > .tab-content {
		padding: 0 1rem 0 1rem;
	}
}
@media (max-width:575px) {
	.tab-wrapper > .tab-header {
		padding: .25rem .5rem 0 .5rem;
		flex-direction: column;
		/* flex-flow: wrap; */
	}
	.tab-wrapper > .tab-header > .tab-header-item {
		padding: .5rem;
	}
	.tab-wrapper > .tab-header > .tab-header-item * {
		justify-content: flex-start;
	}
	.tab-wrapper > .tab-header > .tab-header-item:hover {
		background-color: #c3dfe810;
		border-radius: .5rem;
	}
	.tab-wrapper > .tab-content {
		padding: 0 .25rem 0 .25rem;
	}
}

</style>