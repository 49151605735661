const configs = {
	party_a: {
		name: '甲方',
		form: 'party_a_id',
		data: 'party_a_name',
	},
	party_a_branch: {
		name: '甲方區處',
		form: 'party_a_branch_id',
		data: 'party_a_branch_name',
	},
	supervisor: {
		name: '監工',
		form: 'supervisor_id',
		data: 'supervisor_name',
	},
	commissioner: {
		name: '承辦人',
		form: 'commissioner_id',
		data: 'commissioner_name',
	},
	construction_type: {
		name: '施工種類',
	},
	pipeline_center: {
		name: '道管中心',
		form: 'pipeline_center_id',
		data: 'pipeline_center_name',
	},
	serial_number: {
		name: '流水號',
	},
	work_order_number: {
		name: '工作單編號',
	},
	bid: {
		name: '標案',
		form: 'bid_id',
		data: 'bid_name',
	},
	construction: {
		name: '工程',
		form: 'construction_id',
		data: 'construction_name',
	},
	contractor: {
		name: '承包商',
		form: 'contractor_id',
		data: 'contractor_name',
	},
	address: {
		name: '施工地址',
	},
	work_number: {
		name: '施工案號/W案號',
	},
	excavation_permit: {
		name: '路證編號',
		form: 'excavation_permit_name',
		data: 'excavation_permit_name',
		list: 'excavation_permit',
	},
	emergency_repair_number: {
		name: '搶修路證編號',
	},
	approved_start_at: {
		name: '路證核准起日',
	},
	approved_end_at: {
		name: '路證核准迄日',
	},
	constructed_start_at: {
		name: '路證施工起日',
	},
	constructed_end_at: {
		name: '路證施工迄日',
	},
	expected_constructed_at: {
		name: '施工日期',
	},
	created_at: {
		name: '新增日期',
		list: 'created_at_date',
	},
	first_received_at: {
		name: '首次收件日期',
	},
	received_at: {
		name: '收件日期',
	},
	assigned_at: {
		name: '派工日期',
	},
	measured_at: {
		name: '測量日期',
	},
	file_made_at: {
		name: '文件製作日期',
	},
	scene_pictures_url: {
		name: '資料連結',
	},
	status: {
		name: '案件狀態',
	},
	pipeline_length: {
		name: '管線長度',
	},
}

const commonParams = {
	form: [
		'address',
		'excavation_permit',
		'pipeline_center',
		'expected_constructed_at',
		'scene_pictures_url',
		// 'unit_report_types',
		// 'construction_data',
		'emergency_repair_number',
		// 'approved_start_at',
		// 'approved_end_at',
		'constructed_start_at',
		'constructed_end_at',
	],
	data: [
		'excavation_permit',
		'expected_constructed_at',
		'status',
		'pipeline_center',
		'address',
		'party_a',
		'party_a_branch',
		'supervisor',
		'pipeline_length',
	],
}


const defaultParams = {
	form: [],
	data: [
		'created_at',
		'party_a_branch',
		'construction',
	],
}


const params = {
	// 北水
	taipei_water: {
		form: [
			'work_number',
			'assigned_at',
			'measured_at',
			'file_made_at',
			'party_a_branch',
			'bid',
			'construction',
			'supervisor',
			'received_at',
		],
		data: [
			'first_received_at',
			'received_at',
			'construction',
			'bid',
		],
	},
	// 台水
	taiwan_water: {
		form: [
			'party_a',
			'party_a_branch',
			'construction',
			'supervisor',
		],
	},
	// 台水六區
	taiwan_water_sixth: {
		form: [
			'party_a_branch',
			'bid',
			'construction',
			'supervisor',
			'commissioner',
		],
		data: [
			'bid',
		],
	},
	// 台水七區
	taiwan_water_seventh: {
		form: [
			'party_a_branch',
			'construction',
			'supervisor',
		],
	},
	// 衛工處
	sewerage: {
		form: [
			'assigned_at',
			'measured_at',
			'file_made_at',
			'party_a',
			'party_a_branch',
			'construction',
			'supervisor',
		],
	},
	// 水利處
	hydraulic: {
		form: [
			'assigned_at',
			'measured_at',
			'file_made_at',
			'party_a',
			'party_a_branch',
			'construction',
			'supervisor',
		],
	},
	// 中華電信
	telecom: {
		form: [
			'assigned_at',
			'measured_at',
			'file_made_at',
			'party_a_branch',
			'contractor',
			'construction_type',
			'serial_number',
			'work_order_number',
		],
		data: [
			'assigned_at',
			'construction_type',
			'serial_number',
			'work_order_number',
			'contractor',
		],
	},
	// 其他（Project）
	other: {
		form: [
			'assigned_at',
			'measured_at',
			'file_made_at',
			'party_a_branch',
			'party_a',
			'bid',
			'construction',
			'contractor',
		],
	},
}

const ParamConfigs = {
	configs: configs,
	params: {
		common: commonParams,
		default: defaultParams,
		projects: params,
	},
}

export default ParamConfigs;
