<template>
	<b-row class="w-100 outside-wrapper">
		<label class="input-label col-md-2 col-12" v-show="label" :class="`${labelClass} ${required ? 'input-label-required' : ''}`">{{label}}</label>
		<b-form-radio-group class="input-radio-wrapper" :size="size === 'xs' ? 'xs' : ''" v-model="model" :required="required" @change="change" @input="input">
			<b-form-radio :value="option.value" v-for="(option, key) in opts" :key="key">
				{{ option.text }}
			</b-form-radio>
			<b-form-radio v-if="hasOther" @click="selectOther">
				其他：
				<b-form-input :size="size === 'xs' ? 'xs' : ''" class="input-text" type="text" v-model="text_model" @focus="selectOther" @input="input" @change="change"></b-form-input>
			</b-form-radio>
		</b-form-radio-group>
		<b-row class="w-100">
			<div class="col-md-2 col-12" :class="labelClass"></div>
			<div class="flex-fill text-hint font-size-sm text-left" :class="hintClass" v-if="hint">* {{hint}}</div>
		</b-row>
	</b-row>
</template>

<script>
export default {
	name: 'InputRadio',
	components: {
	},
	props: {
		options: {
			type: Array,
			default() {
				return [];
			}
		},
		keys: {
			type: [Object, String],
			default: ''
		},
		value: {
			type: [String, Number],
			default: ""
		},
		size: {
			type: String,
			default: ""
		},
		label: {
			type: String,
			default: ""
		},
		labelClass: {
			type: String,
			default: ""
		},
		hasOther: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false
		},
		hint: {
			type: String,
			default: ""
		},
		hintClass: {
			type: String,
			default: ""
		},
	},
	data() {
		return {
			model: this.value,
			text_model: ""
		}
	},
	watch: {
		options: {
			deep: true,
			handler(val) {
				this.getOptions(this.options, this.keys)
			}
		},
		keys: {
			deep: true,
			handler(val) {
				this.getOptions(this.options, this.keys)
			}
		},
		value: {
			handler(value) {
				this.model = value
			}
		},
	},
	created() {
		this.getOptions(this.options, this.keys)
	},
	methods: {
		getOptions(options, key) {
			this.opts = Array.isArray(options) ? options.map(option => {
				let opt = typeof option === 'object' && !this.isEmpty(key.text) && !this.isEmpty(key.value) ? { text: this.getRecursiveValue(option, key.text), value: this.getRecursiveValue(option, key.value) } : typeof option === 'object' && typeof key === 'string' && !this.isEmpty(option[key]) ? { text: option[key], value: option[key] } : typeof option === 'object' && !this.isEmpty(option.text) && !this.isEmpty(option.value) ? option : { text: option, value: option }
				return {...option, ...opt}
			}) : { text: options, value: options };
			this.$forceUpdate()
		},
		selectOther(e) {
			let target = this.$el.querySelector('input[type="text"]')
			target.parentNode.parentNode.querySelector('input').click();
		},
		input(e) {
			this.$emit('input', e)
		},
		change(e) {
			this.$emit('change', e)
		},
		getRecursiveValue(obj, key_str, key_delim='.') {
			let keys = key_str.split(key_delim);
			let res = obj;
			while(keys.length) {
				let key = keys.shift();
				if(typeof res === 'undefined' || res === null)
					break;
				res = res[key];
			}
			return res
		},
		isEmpty(obj) {
			// null and undefined are "empty"
			if (obj == null || typeof obj === "undefined") return true;

			if(!Number.isNaN(Number(obj))) return false

			// Assume if it has a length property with a non-zero value
			// that that property is correct.
			if (obj.length > 0)    return false;
			if (obj.length === 0)  return true;

			// If it isn't an object at this point
			// it is empty, but it can't be anything *but* empty
			// Is it empty?  Depends on your application.
			if (typeof obj !== "object") return true;

			// Otherwise, does it have any properties of its own?
			// Note that this doesn't handle
			// toString and valueOf enumeration bugs in IE < 9
			var hasOwnProperty = Object.prototype.hasOwnProperty;
			for (var key in obj) {
				if (hasOwnProperty.call(obj, key)) return false;
			}

			return true;
		}
	}
}
</script>

<style scoped>
.outside-wrapper {
	align-items: center;
}
.input-radio-wrapper {
	display: flex;
	flex-wrap: wrap;
	flex: 1 1;
}
.input-text {
	display: inline-flex;
	border: none;
	border-radius: 0;
	border-bottom: 1px solid;
	padding: 0;
	height: 1em;
	max-width: 150px;
}
.input-text:focus {
	box-shadow: none;
}
.input-label {
	background-color: transparent;
	border: none;
	z-index: 3;
	display: flex;
	align-items: center;
	padding-right: .5rem;
}
label.input-label {
	overflow-wrap: break-word;
	word-wrap: break-word;
	white-space: normal;
}
label.input-label.input-label-required::after {
	content: '*';
	color: crimson;
}
</style>
