<template>
	<div class="pt-0 pl-3 pr-3">
		<iframe class="card dynamic-form-iframe w-100 m-0 p-0" :src="formUrl" frameborder="0"></iframe>
	</div>
</template>

<script>
export default {
	name: 'DynamicFormEditor',
	components:{
	},
	props: {
	},
	data() {
		return {
			formID: Number.isInteger(parseInt(this.$route.params.formID)) ? parseInt(this.$route.params.formID) : -1,
			formBaseUrl: process.env.VUE_APP_DYNAMIC_FORM_URL,
		}
	},
	watch: {
	},
	created() {
	},
	computed: {
		formUrl() {
			let domain = process.env.VUE_APP_API_URL.replace(/https:\/\/|http:\/\//, '')
			let protocol = process.env.VUE_APP_API_URL.startsWith('https') ? 'https' : 'http'
			return `${this.formBaseUrl}?projectID=0&missionTypeID=0&itemID=${this.formID}&domain=${domain}&protocol=${protocol}`
		}
	},
	methods:{
	}
}
</script>

<style scoped>
.dynamic-form-iframe {
	height: calc(100vh - 9rem);
}
</style>