<template>
	<b-row>
		<div class="card-wrapper col-lg-7 col-md-12">
			<loading mode="fixed" image="1" size="lg" v-if="searching"/>
			<b-card class="border-0" no-body>
				<b-card-body>
					<!-- <vue-google-map ref="map" class="google-map" :center="map.center" :zoom="map.zoom" :markers="map.markers" :configs="map.configs" :modes="map.modes" @addOverlay="addOverlay" @changeOverlay="changeOverlay"></vue-google-map> -->
					<vue-google-map-with-layer ref="map" class="google-map" :center="map.center" :zoom="map.zoom" :layers="map.layers" :markers="map.markers" :configs="map.configs" :modes="map.modes" @addOverlay="addOverlay" @changeOverlay="changeOverlay" @filterLayers="filterLayers"></vue-google-map-with-layer>
				</b-card-body>
			</b-card>
		</div>
		<div class="card-wrapper col-lg-5 col-md-12">
			<b-card class="assignment-card border-0" no-body>
				<b-card-body>
					<b-tabs class="tabs-wrapper" v-model="activeTabIndex" fill active-nav-item-class="tab-title font-weight-bold" active-tab-class="tab-title" fluid>
						<b-tab class="tab-body" title="選擇工地">
							<loading v-if="!call.filterConstructionSite"/>
							<construction-site-selector v-show="call.filterConstructionSite" :selected="assignment.sites" :sites="sites" @load="call.filterConstructionSite=true" @filter="getConstructionSiteList" @changeDrawingMode="initDrawingManager" @clearDrawing="clearDrawing" @finish="finishSelection"></construction-site-selector>
						</b-tab>
						<b-tab class="tab-body" title="指派任務">
							<loading v-if="loading"/>
							<template v-else>
								<template v-if="!upload">
									<bv-table :columns="pointTable.columns" :data="pointTable.data" :configs="pointTable.configs" :actions="pointTable.actions"></bv-table>
									<b-row class="justify-content-end">
										<button class="btn form-btn clear-btn" type="button" @click="upload=true;">上傳csv</button>
										<button class="btn form-btn delete-btn" type="button" @click="clearDrawing">清除</button>
									</b-row>
								</template>
								<div class="pt-2" v-else>
									<h6>上傳工地列表csv</h6>
									<input-file button="fas fa-folder-open" accept=".csv" size="xs" v-model="uploadCsv"></input-file>
									<b-row class="justify-content-center">
										<b-button class="form-btn cancel-btn size-xs" @click="upload=false;uploadCsv=null;">取消</b-button>
										<b-button class="form-btn submit-btn size-xs" @click="uploadConstructionSiteList">確定</b-button>
									</b-row>
								</div>
								<hr />
								<mission-creator @createMission="createMission"></mission-creator>
							</template>
						</b-tab>
						<b-tab class="tab-body" title="指派情形">
							<loading v-if="!call.getMissionList"/>
							<bv-table v-else-if="!missionsViewer.show" :columns="missionTable.columns" :data="missionTable.data" :configs="missionTable.configs" :actions="missionTable.actions"></bv-table>
							<user-mission-viewer ref="userMissionViewer" v-else :user="missionsViewer.user" :sites="missionsViewer.sites" @close="missionsViewer.show=false" @view="focusSite" @reload="reloadMissionViewer" @row-selected="onSelectMissionSites"></user-mission-viewer>
						</b-tab>
					</b-tabs>
				</b-card-body>
			</b-card>
		</div>
	</b-row>
</template>

<script>
import VueGoogleMap from '@/components/GoogleMap/VueGoogleMap'
import VueGoogleMapWithLayer from '@/components/GoogleMap/VueGoogleMapWithLayer'
import BvTable from '@/components/Table/BvTable'
import MissionCreator from '@/components/Assignment/MissionCreator'
import ConstructionSiteSelector from '@/components/Assignment/ConstructionSiteSelector'
import UserMissionViewer from '@/components/Assignment/UserMissionViewer'
import InputFile from '@/components/Input/InputFile'
import Loading from '@/components/Loading/Loading.vue'
import { deepCopy, isEmpty, oneOf, checkAllTrue, sortArray } from '@/utils/assist';
import fileManager from '@/utils/file';

export default {
	name: 'Assignment',
	components:{
		VueGoogleMap,
		VueGoogleMapWithLayer,
		MissionCreator,
		ConstructionSiteSelector,
		UserMissionViewer,
		BvTable,
		InputFile,
		Loading
	},
	data() {
		return {
			defaultSites: this.$route.query.siteIds ? this.$route.query.siteIds.split(',').map(s=>Number(s)).filter(o=>!Number.isNaN(o)) : [],
			loading: true,
			searching: false,
			call: { filterConstructionSite: false, },
			map: {
				center: {// 預設經緯度在信義區附近
					lat: 25.0325917,
					lng: 121.5624999,
				},
				layers: [],
				markers: [],
				zoom: 12,
				configs: {
					drawing: false,
					drawingMode:  "",
				},
				modes: {
					hideBussiness: true,
					hideMarkers: true,
					hideTransitions: true
				},
				overlays: [],
			},
			missionTable: {
				columns: [
					// { key: 'creator_name', label: '指派人員', sortable: true, searchable: true},
					{ key: 'outsider.id', label: 'ID', sortable: true, searchable: true, deep: true, hide: !this.$store.getters.isDeveloper },
					{ key: 'outsider.name', label: '測量人員', sortable: true, searchable: true, deep: true },
					{ key: 'construction_site_count', label: '點位數量', sortable: true, searchable: true },
					// { key: 'created_at', label: '指派日期', sortable: true, searchable: true }
				],
				data: [],
				actions: [
					{
						//icon, label
						button: { type: 'icon', icon: "fas fa-eye", class: "m-0", style: "color: #094c73" },
						//function, link, route
						action: { type: 'function', function: this.viewSites },
					},
				],
				configs: {
					actionsColumn: true,
					perPage: 10,
					showStatistic: true
				}
			},
			pointTable: {
				columns: [
					{ key: 'name', label: '名稱' },
					{ key: 'position', label: '座標' },
					{ key: 'project_id', label: '案件ID' },
				],
				data: [],
				actions: [],
				configs: {
					perPage: 5,
				}
			},
			assignment: {
				selected: false,
				sites: [],
			},
			sites: [],
			filter: {},
			activeTabIndex: 0,
			missionsViewer: {
				show: false,
				user: null,
				sites: [],
			},
			upload: false,
			uploadCsv: null,
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		activeTabIndex: {
			handler(value, oldValue) {
				// 檢視指派情形
				if(value === 2) {
					this.getMissionList()
				}
			}
		}
	},
	created() {
		if(this.defaultSites.length) {
			this.assignment.sites = this.defaultSites;
			this.getConstructionSiteList({ids: this.defaultSites});
		}
	},
	mounted() {
	},
	computed: {
	},
	methods:{
		getConstructionSiteList(params, isMissionSites=false) {
			this.filter = deepCopy(params)
			// this.$set(this.call, 'getConstructionSiteList', false);
			this.searching = true;
			this.$axios.getConstructionSiteList({...params, less: 1, paging: 0}, (response) => {
				this.sites = response.data;
				sortArray(this.sites, 'id')
				if(isMissionSites) {
					this.missionsViewer.sites = this.missionsViewer.sites.map(site => {
						return {
							...site,
							...this.sites.find(s => s.id === site.id)
						};
					})
				}
				this.setSitesMapLayer(this.sites, {}, (i) => i, (actions, site, index) => {
					if(isMissionSites) {
						actions.push({
							// icon, label
							button: { type: 'label', label: '刪除任務', class: 'btn btn-outline-danger btn-sm py-0 px-1 mx-1' },
							// function, link, route
							// action: { type: 'function', function: this.$refs.userMissionViewer.openDeleteMissionModal, params: site },
							action: { type: 'function', function: this.$refs.userMissionViewer.clickDeleteMissionButton, params: index },
						})
					}
					return actions
				})
				// this.call.getConstructionSiteList = true
				this.searching = false;
				if(this.sites.length <= 0) {
					alert("無可顯示工地！")
				}
				else if(this.assignment.sites.length) {
					let siteIds = this.sites.map(site => site.id)
					//  移除已圈選卻未被搜尋的點（for 已用網址query搜尋id，再用filter搜尋）
					this.assignment.sites = this.assignment.sites.filter(site => siteIds.includes(site))
					this.setPointTableData();
					if(this.assignment.sites.length)
						this.finishSelection();
				}
			}, (error) => {
				// this.call.getConstructionSiteList = true
				this.searching = false;
			})
		},
		setSitesMapLayer(sites, options={}, getInfo=(i)=>i, getActions=(a)=>a) {
			this.$refs.map.clearLayers();
			let indexedSites = sites.keyBy((s, i) => i)
			let layers = Object.map(this.$store.getters.enum("construction_site.status").keyBy('text'), status => {
				return Object.filter(indexedSites, s => s.status === status.text)
			})
			this.map.layers = Object.values(Object.map(layers, (sites, name) => {
				return {
					name: name,
					options: options,
					type: 'points',
					features: Object.values(Object.map(sites, (site, index) => {
						let info = getInfo({
							...this.$store.getters.isDeveloper ? {
								ID: site.id,
								案件ID: site.project_id,
							} : {},
							甲方: site.party_a_name,
							工地名稱: site.name,
							地址: site.address,
							// 座標: `${site.lat}°N ${site.lng}°E`,
							狀態: site.status,
							通知銑舖: site.paved + (site.paved_at ? `(${site.paved_at})` : ''),
							備註: site.note ?? '',
							// html: `<a class="btn btn-outline-info btn-sm py-0 px-1 mx-1" target="_blank" href="/group/${site.project_group_id}/project/${site.project_type_index}/${site.project_id}/sites">工地管理</a>`
						}, site, index)
						let actions = getActions([
							{
								// icon, label
								button: { type: 'label', label: '工地管理', class: 'btn btn-outline-info btn-sm py-0 px-1 mx-1' },
								// function, link, route
								action: { type: 'link', link: `/group/${site.project_group_id}/project/${site.project_type_index}/${site.project_id}/sites`, target: "_blank"},
							}
						], site, index)
						let days = site.paved_at ? (new Date()).diffWorkDays(site.paved_at, true) : 100
						let label = site.paved_at && days <= 3 && !site.is_measured ? `${days}` : ''
						return {
							id: site.id,
							type: 'Point',
							position: site.position,
							properties: {
								id: site.id,
								info: info,
								actions: actions,
								label: label,
							}
						}
					}))
				}
			}))
			this.$refs.map.refresh()
		},
		getMissionList() {
			this.$set(this.call, 'getMissionList', false)
			this.$axios.getMissionList({}, (response) => {
				this.missionTable.data.splice(0, this.missionTable.data.length)
				this.missionTable.data = Object.values(response.data.reduce((data, mission) => {
					mission.outsiders.forEach(outider => {
						if(!data[outider.id]) {
							data[outider.id] = {
								id: mission.id,
								outsider: outider,
								missions: [],
								construction_site_ids: [],
								construction_site_count: 0,
							};
						}
						data[outider.id].missions.push(mission);
						data[outider.id].construction_site_ids = [...(new Set([
							...data[outider.id].construction_site_ids,
							...mission.construction_sites.map(site => site.id)
						]))];
						data[outider.id].construction_site_count = data[outider.id].construction_site_ids.length;
					})
					return data;
				}, {}));
				sortArray(this.missionTable.data, 'outsider.id', -1);

				if(this.missionsViewer.show) {
					let index = this.missionTable.data.findIndex(datum => datum.outsider.id === this.missionsViewer.user.id);
					this.viewSites(index)
				}

				this.call.getMissionList = true
			}, (error) => {
				this.call.getMissionList = true
			})
		},
		setPointTableData() {
			this.pointTable.data.splice(0, this.pointTable.data.length)
			this.pointTable.data = this.sites.filter((project) => {
				return this.assignment.sites.includes(project.id)
			})
			this.pointTable.data = this.pointTable.data.map(site => {
				return {
					name: site.name,
					position: `${site.lat}°N ${site.lng}°E`,
					project_id: site.project_id
				}
			})
		},
		uploadConstructionSiteList() {
			fileManager.readFile(this.uploadCsv, {encoding: "BIG5"}, (text) => {
				let csv = fileManager.readSheet(text, false)
				this.$router.push({ name: '派工頁面', query: { siteIds: csv.map(row => row.first).join(',') }})
				window.location.reload()
			})
		},
		createMission(mission) {
			if(isEmpty(mission.outsiders_id)) {
				alert("未選取測量人員")
				return
			}
			else if(this.assignment.sites.length <= 0) {
				alert("請圈選指派的點位")
				return
			}
			mission.outsiders_id = mission.outsiders_id.filter(o => o !== '')
			mission.creator_id = this.$store.getters.currentUser.id
			mission.construction_sites_id = this.assignment.sites
			this.$axios.createMission(mission, (response) => {
				alert("新增成功");
				this.loading = true
				this.getMissionList();
				this.getConstructionSiteList(this.filter)
				this.clearDrawing()
			})
		},
		initDrawingManager(options) {
			for(let key in options) {
				this.map.configs[key] = options[key]
			}
		},
		clearDrawing() {
			this.assignment.sites.clear()
			this.pointTable.data.clear()
			this.$refs.map.clearDrawingOverlays()
			this.assignment.selected = false
		},
		addOverlay(overlay) {
			this.map.overlays.push(overlay)
			this.assignment.selected = true
			this.filterLayers()
		},
		changeOverlay() {
			this.filterLayers()
		},
		filterLayers() {
			this.getSelectedSites()
			this.setPointTableData()
		},
		getSelectedSites() {
			this.assignment.sites.clear()
			let features = this.$refs.map.getFeaturesInsideDrawingOverlays()
			this.assignment.sites = features.map(f => f.id).uniq()
		},
		zoomMap(center, zoom) {
			this.map.center = center;
			this.map.zoom = zoom;
		},
		finishSelection() {
			if(!this.assignment.sites.length)
				alert("無可指派工地，請先圈選點位！")
			else
				this.activeTabIndex = 1
		},
		viewSites(index) {
			this.$refs.map.closeInfoWindow();
			let sites = Object.values(this.missionTable.data[index].missions.reduce((obj, mission) => {
				mission.construction_sites.forEach(site => {
					if(!obj[site.id]) {
						obj[site.id] = { ...site, mission: mission }
					}
				})
				return obj
			}, {}))
			sortArray(sites, 'id')

			this.missionsViewer.show = true;
			this.missionsViewer.user = this.missionTable.data[index].outsider;
			this.missionsViewer.sites = sites

			this.getConstructionSiteList({outsiders_id: [this.missionTable.data[index].outsider.id]}, true);
		},
		onSelectMissionSites(sites) {
			let siteIds = sites.map(site => site.id)
			Object.values(this.map.layers).forEach(layer => {
				layer.features.forEach(feature => {
					this.$set(feature, 'selected', siteIds.includes(feature.id))
					// feature.selected = siteIds.includes(feature.id)
				})
			})
		},
		focusSite(site) {
			this.$refs.map.focusToLatLng(site.position)
		},
		reloadMissionViewer() {
			this.getMissionList();
		},
	}
}
</script>

<style scoped>
.card-wrapper {
	padding: .5rem;
}
.card {
	height: 100%;
}
.assignment-card {
	height: calc(100vh - 150px);
	min-height: 666px;
}
.google-map {
	min-height: 400px;
	height: 100%;
}
.tab-body {
	padding: .5rem 1rem;
	border: 1px solid #dee2e6;
	border-top: 0;
	height: 100%;
}
.tabs-wrapper {
	height: 100%;
}
.tabs-wrapper > .tab-content {
	height: 100%;
}
.table-responsive {
	margin: 0;
}
.action-btn {
	padding: .25rem .75rem;
	border-radius: .5rem;
	background: #b2c8d8aa;
	color: #050b10cc;
	border-color: #b2c8d8;
}
.action-btn:hover {
	text-decoration: none;
	background: #8ea9c2cc;
	color: #050b10;
}
.action-btn:focus {
	box-shadow: none !important;
}
</style>