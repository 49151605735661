<template>
	<loading v-if="loading"/>
	<b-row class="m-0 p-0" v-else>
		<vue-google-map ref="map" class="google-map col-lg-6 col-12" :center="map.center" :zoom="map.zoom" :address="map.address" :markers="map.markers" :configs="map.configs" :modes="map.modes" @changeOverlay="changeOverlay" @geocoderSelect="newDefaultSite"></vue-google-map>
		<!-- <div v-if="!existsNewSites.length" style="text-align:center; color:#aaa">請點選工地位置</div> -->
		<div class="col-lg-6 col-12">
			<alert :errors="errors"></alert>
			<input-form ref="form" class="p-0 mt-2 flex-fill" :options="formOptions" v-model="result" :actions="{submit: false, cancel:false}" v-if="newSite"></input-form>
		</div>
	</b-row>
</template>


<script>
import VueGoogleMap from '@/components/GoogleMap/VueGoogleMap'
import InputForm from '@/components/Input/InputForm'
import PhotoAlbum from '@/components/Album/PhotoAlbum.vue'
import Alert from '@/components/Alert/Alert.vue'
import Loading from '@/components/Loading/Loading.vue'
import { oneOf, checkAllTrue, deepCopy } from '@/utils/assist';
import MarkerIcon from '@/utils/markerIcon';

export default {
	name: 'ConstructionSiteCreator',
	components: {
		VueGoogleMap,
		InputForm,
		PhotoAlbum,
		Alert,
		Loading
	},
	props: {
		group: {
			type: Object
		},
		project: {
			type: Object,
			default: () => { return {} }
		},
		value: {
			type: Object,
			default: () => { return {} }
		},
		submittable: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			loading: true,
			call: { getUnitDynamicForm: false },
			validator: {
				errors: [
					{
						key: 'siteAmountError',
						validator: () => this.newSite,
						title: `請點選工地位置`,
					},
					{
						key: 'dynamicFormError',
						validator: () => this.dynamicFormTypes.every(type => this.dynamicForms.filter(o=>o.type_index===type.index).length),
						title: `無可用之動態表單，請至<a href="/manager/unit/list">單位列表</a>設定`,
						messager: () => {
							return this.dynamicFormTypes.filter(type => !this.dynamicForms.filter(o=>o.type_index===type.index).length).map(type => type.text)
						}
					},
				],
			},
			dynamicForms: [],
			map: {
				center: {// 預設經緯度在信義區附近
					lat: 25.0325917,
					lng: 121.5624999,
				},
				markers: [],
				overlays: [],
				zoom: 12,
				configs: {
					drawing: false,
					drawingMode:  "marker",
					drawingOptions: {
						markerOptions: {
							draggable: true,
							icon: {
								url: 'https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_blue1.png',
								scaledSize: { height: 40, width: 22 }
							}
						}
					},
					geocoding: true,
					searchingAddress: true
				},
				modes: { hideBussiness: true, hideMarkers: true, hideTransitions: true },
				address: '',
			},
			newSite: undefined,
			result: {},
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
		value: {
			deep: true,
			handler(value) {
				this.setResult()
			}
		},
		'project.address': {
			deep: true,
			immediate: true,
			handler(value) {
				this.map.address = this.project.address
			}
		},
		result: {
			deep: true,
			handler(value, oldValue) {
				if(!Object.isEqual(value, oldValue)) {
					this.change()
				}
			}
		},
	},
	created() {
		this.getUnitDynamicForm();
	},
	mounted() {
	},
	computed: {
		dynamicFormTypes() {
			return this.$store.getters.enum('dynamic_form.type').filter(type => type.data.has_unit)
		},
		errors() {
			return this.validator.errors.filter(error => !error.validator()).map(error => {
				return {
					...error,
					messages: error.messager ? (typeof error.messager === 'function' ? error.messager() : error.messager) : ''
				}
			})
		},
		formOptions() {
			return [
				{ key: "name", type: "text", label: "工地名稱", required: true, size: 'xs', pattern: "^[a-zA-Z0-9\u4E00-\u9FFF\uff08-\uff09_]{2,}$", invalidMessage: "請輸入2個字元以上非特殊符號" },
				{ key: "paved", type: 'radio', label: "是否通知銑鋪", size: 'xs', boolean: true, options: this.$store.getters.enum('construction_site.paved'), keys: {value: 'index', text: 'text'} },
				{ key: "paved_at", type: "date", label: "預計銑舖日期", size: 'xs', hide: (v, k, data) => data.paved !== this.$store.getters.enum('construction_site.paved.paved').index },
				...this.dynamicFormTypes.map((type, key) => {
					let forms = this.dynamicForms.filter(o=>o.type_index===type.index)
					return {
						key: `${type.key}_dynamic_form_id`, type: "select", label: `${type.text}表單`, required: !!forms.length, size: 'xs', options: forms, keys: {value: 'id', text: 'name'}
					}
				}),
				{ key: "scene_pictures_url", type: "url", label: "資料連結", required: true, size: 'xs', },
				{ key: "note", type: "text", label: "備註", size: 'xs', multiline: true },
			]
		},
		resultData() {
			return {
				...this.result,
				...this.newSite.position,
			}
		},
	},
	methods:{
		onLoadEnd() {
			this.setResult()
			this.$emit('load')
		},
		setResult() {
			this.result = Object.assign(this.result, {
				...this.result,
				...this.value,
			})
		},
		getUnitDynamicForm() {
			this.call.getUnitDynamicForm = false
			this.$axios.getUnitDynamicForm(this.project.party_a_id, {}, (response) => {
				this.dynamicForms = Object.values(response.data).filter(o => o)
				this.call.getUnitDynamicForm = true
			}, (error) => {
				this.call.getUnitDynamicForm = true
			});
		},
		changeOverlay(event, marker) {
			this.newSite.position.lat = event.latLng.lat();
			this.newSite.position.lng = event.latLng.lng();
		},
		newDefaultSite(geoResult) {
			let index = 1
			if(this.newSite) {
				let marker = this.map.markers.find(s => s.index === index);
				marker.position = geoResult.position
				this.newSite.position = geoResult.position
			}
			else {
				let overlay = {
					position: geoResult.position,
					icon: MarkerIcon.getCharMarker('purple', index),
					index: index,
					draggable: true,
					dragend: (event, marker) => {
						this.newSite.position.lat = event.latLng.lat();
						this.newSite.position.lng = event.latLng.lng();
					},
				}
				this.map.markers.push(overlay)
				this.addNewSite('overlays', index, geoResult.position)
			}
		},
		addNewSite(map, index, position) {
			this.newSite = {
				map: map,
				index: index,
				exists: true,
				position: {
					lat: typeof position.lat === 'function' ? position.lat() : position.lat,
					lng: typeof position.lng === 'function' ? position.lng() : position.lng,
				},
			}
		},
		change() {
			this.$emit('input', this.resultData)
			this.$emit('change', this.resultData)
		},
		checkForm() {
			return this.$refs.form.reportValidity()
		},
	}
}
</script>

<style scoped>
.google-map {
	min-height: 300px;
	height: 400px;
}
</style>