<template>
	<div>
		<vuetable ref="vuetable" class="py-1 table-responsive" :api-mode="false" :fields="tableFields" :data="tableData"
			:css="css.table" :data-manager="onSetData" @vuetable:loaded="onLoadedTable">
			<template v-slot:tableHeader="{fields}">
				<tr>
					<th :colspan="group.span" class="th-multi-col text-center" :class="group.class" v-for="(group, key) in tableFieldGroups" :key="`header-multi-col-${key}_${group.name}`">{{group.title}}</th>
				</tr>
				<vuetable-row-header ref="header" />
				<tr>
					<th class="pt-1" :class="field.titleClass" v-for="(field, key) in fields" :key="`header-search-${key}-${field.searchField}`">
						<input-text class="input" size="xs" button="fas fa-search" v-model="search[field.searchField]" v-if="field.searchField" @change="onSearchData"></input-text>
					</th>
				</tr>
				<slot name="header" :fields="fields"></slot>
			</template>
			<!-- <template v-for="(field) in tableFields.filter(f => $scopedSlots[`field-${f.name}`])" v-slot:[field.name]="{rowData, rowIndex, rowField}">
				<slot :name="`field-${field.name}`" :rowData="rowData" :rowIndex="rowIndex" :rowField="rowField"></slot>
			</template> -->
			<template v-for="(field, key) in tableFields" v-slot:[field.name]="data" class="text-info">
				<font :key="`field-${key}-${field.name}`" v-html="$refs.vuetable.renderNormalField(data.rowField, data.rowData)"></font>
				<template v-if="$scopedSlots[`field-${field.name}`]">
					<slot :name="`field-${field.name}`" :rowData="data.rowData" :rowIndex="data.rowIndex" :rowField="data.rowField"></slot>
				</template>
				<template v-else-if="data.rowField.actions">
					<template v-for="(action, actionKey) in data.rowField.actions">
						<template v-if="typeof action.visible === 'function' ? action.visible(data.rowData, data.rowIndex) : (action.visible === undefined ? true : action.visible)">
							<button :key="`field-${key}-${field.name}-action-${actionKey}`" class="table-btn d-inline-flex align-items-center p-1" :class="action.button.class" style="vertical-align: middle;" :style="action.button.style" :title="action.button.title" v-if="action.action.type==='function'" @click="action.action.function(data.rowData, data.rowIndex)">
								<i class="fa-fw" :class="action.button.icon" v-if="action.button.icon"></i>
								<template v-if="action.button.label">{{action.button.label}}</template>
							</button>
							<a :key="`field-${key}-${field.name}-action-${actionKey}`" class="table-btn d-inline-flex align-items-center p-1" :class="action.button.class" style="vertical-align: middle;" :style="action.button.style" :title="action.button.title" v-else-if="action.action.type==='link'" :href="typeof action.action.link ==='function' ? action.action.link(data.rowData, data.rowIndex) : action.action.link" :target="action.action.target">
								<i class="fa-fw" :class="action.button.icon" v-if="action.button.icon"></i>
								<template v-if="action.button.label">{{action.button.label}}</template>
							</a>
							<router-link :key="`field-${key}-${field.name}-action-${actionKey}`" class="table-btn d-inline-flex align-items-center p-1" :class="action.button.class" style="vertical-align: middle;" :style="action.button.style" :title="action.button.title" v-else-if="action.action.type==='route'" :to="typeof action.action.route ==='function' ? action.action.route(data.rowData, data.rowIndex) : action.action.route" :target="action.action.target">
								<i class="fa-fw" :class="action.button.icon" v-if="action.button.icon"></i>
								<template v-if="action.button.label">{{action.button.label}}</template>
							</router-link>
						</template>
					</template>
				</template>
			</template>

			<!-- <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="data">
				<slot :name="name" :data="data"></slot>
			</template> -->
			<template v-slot:tableFooter="{fields}"><slot name="footer" :fields="fields"></slot></template>
		</vuetable>
			<!-- @vuetable:pagination-data="onPaginationData" -->
		<!-- <vuetable-pagination ref="pagination" :css="css.pagination" @vuetable-pagination:change-page="onChangePage" /> -->
		<slot name="pagination" :paging="tablePaging">
			<b-row class="mb-3">
				<div class="col-12 pl-3" style="color: gray; font-size: .9em;">
					第 {{tablePaging.from}} - {{tablePaging.to}} 筆 （ 共 {{tablePaging.total}} 筆 ）
				</div>
				<b-pagination class="col-12" v-model="tablePaging.current_page" :total-rows="tablePaging.total" :per-page="tablePaging.per_page" align="right" :limit="tablePaging.page_limit" style="align-self: flex-end;" v-show="tablePaging.last_page > 1" @change="onChangePage"></b-pagination>
			</b-row>
		</slot>
	</div>
</template>

<script>
import InputText from '@/components/Input/InputText';

export default {
	name: 'VueTable',
	components:{
		InputText,
	},
	props: {
		columns: {
			type: Array
		},
		data: {
			type: Array
		},
		paging: {
			type: Object,
			default: () => { return {} }
		},
	},
	data() {
		return {
			css: {
				table: {
					tableWrapper: '',
					tableHeaderClass: 'mb-0',
					tableBodyClass: 'mb-0',
					tableClass: 'table b-table table-striped table-hover table-borderless table-vertical-bordered table-outer-vertical-borderless',
					loadingClass: 'loading',
					ascendingIcon: 'fas fa-sort-up',
					descendingIcon: 'fas fa-sort-down',
					ascendingClass: 'sorted-asc',
					descendingClass: 'sorted-desc',
					sortableIcon: 'fa fa-sort',
					sortableClass: 'th-sortable',
					detailRowClass: 'vuetable-detail-row',
					handleIcon: 'fa fa-bars text-secondary',
					renderIcon(classes, options) {
						return `<i class="${classes.join(' ')}"></span>`
					}
				},
				pagination: {
					wrapperClass: 'pagination float-right',
					activeClass: 'active',
					disabledClass: 'disabled',
					pageClass: 'page-item',
					linkClass: 'page-item page-link',
					paginationClass: 'pagination',
					paginationInfoClass: 'float-left',
					dropdownClass: 'form-control',
					icons: {
						first: 'fa fa-angle-double-left',
						prev: 'fa fa-angle-left',
						next: 'fa fa-angle-right',
						last: 'fa fa-angle-double-right',
					}
				}
			},
			search: {},
			sort: {},
			pagination: {
				per_page: this.paging.per_page ?? 10,
				page: this.paging.current_page ?? 1,
			},
			loading: false,
			stickySides: ['left', 'right'],
		}
	},
	watch: {
		data: {
			deep: true,
			handler () {
				this.loading = false
			},
		},
	},
	created() {
	},
	destroyed() {
	},
	mounted() {
	},
	updated() {
	},
	computed: {
		tablePaging() {
			return {
				total: this.data.length,
				per_page: this.data.length,
				next_page_url: '',
				prev_page_url: '',
				page_limit: 10,
				...this.paging,
			}
		},
		tableData() {
			return {
				links: {
					pagination: this.tablePaging,
				},
				data: this.data,
			}
		},
		tableFieldGroups() {
			let stickyGroups = this.stickySides.reduce((obj, key) => {
				obj[key] = Object.mapKeys(this.tableFields.filter(f => f.sticky === key).groupBy('group'), (fields, name) => `_${key}-${name}`)
				return obj
			}, {})
			let groups = {
				...stickyGroups.left,
				...this.tableFields.filter(f => !f.sticky).groupBy('group'),
				...stickyGroups.right,
			}
			groups = Object.keys(groups).map(name => {
				let fields = groups[name]
				let sticky = this.stickySides.find(side => fields.find(f => f.sticky === side)) ?? false
				// Object.map(this.stickySides.keyBy(), key => fields.filter(f => f.sticky === key).length)
				return {
					name: name,
					title: fields.first.group,
					span: fields.length,
					sticky: sticky,
					// ...Object.mapKeys(sticky, (v, key) => `${key}Sticky`),
					class: sticky ? `th-sticky b-table-sticky-column th-sticky-${sticky} th-multi-col-sticky-${sticky}` : '',
					// Object.mapKeys(sticky, (v, key) => `th-sticky b-table-sticky-column th-sticky-${key} th-multi-col-sticky-${key}`),
				}
			})
			this.stickySides.forEach(sideKey => {
				let endColumns = {
					first: groups.find(col => col.sticky === sideKey), 
					last: groups.findLast(col => col.sticky === sideKey)
				}
				Object.forEach(endColumns, (column, key) => {
					if(column) {
						column.class += ` th-sticky-${column.sticky}-${key}`;
					}
				})
			})
			return groups
		},
		tableFields() {
			let searchable = this.columns.some(column => column.searchField)
			let columns = this.columns.map(column => {
				let sticky = this.stickySides.includes(column.sticky) ? column.sticky : false
				return {
					...column,
					sticky: sticky,
					titleClass: `${column.titleClass ?? ''} ${searchable ? 'pb-1' : ''} ${sticky ? `th-sticky b-table-sticky-column th-sticky-${sticky}` : ''}`,
					dataClass: `${column.titleClass ?? ''} ${sticky ? `td-sticky b-table-sticky-column td-sticky-${sticky}` : ''}`,
					group: column.group ?? '',
				}
			})
			this.stickySides.forEach(sideKey => {
				let endColumns = {
					first: columns.find(col => col.sticky === sideKey), 
					last: columns.findLast(col => col.sticky === sideKey)
				}
				Object.forEach(endColumns, (column, key) => {
					if(column) {
						column.titleClass += ` th-sticky-${column.sticky}-${key}`;
						column.dataClass += ` td-sticky-${column.sticky}-${key}`;
					}
				})
			})
			return Object.values(columns.groupBy('group')).flat(1)
		},
		query() {
			return {
				...this.search,
				...this.pagination,
				orderBy: this.sort,
			}
		},
	},
	methods: {
		onLoadedTable() {
			let rows = this.$refs.vuetable.$el.querySelectorAll("tr:has(th.th-sticky-left),tr:has(td.td-sticky-left)")
			rows.forEach(row => {
				let columns = row.querySelectorAll('th.th-sticky-left,td.td-sticky-left')
				let offset = 0;
				columns.forEach(column => {
					if(column.offsetWidth) {
						column.style.left = `${offset}px`
						offset += column.offsetWidth
					}
				})
			})
		},
		onSetData(sortOrder, makePagination) {
			this.sort = Object.map(sortOrder.keyBy('sortField'), 'direction')
			this.fetchData()
			return this.tableData
		},
		fetchData() {
			if(!this.loading) {
				this.loading = true
				this.$emit('fetch', this.query)
			}
		},
		onPaginationData(paginationData) {
			this.$refs.pagination.setPaginationData(paginationData)
		},
		onChangePage(page) {
			this.pagination.page = page
			this.$refs.vuetable.changePage(page)
			this.fetchData()
		},
		onSearchData() {
			this.onChangePage(1)
		},
	},
}
</script>

<style scoped>
.table-btn {
	color: #4c6b8a;
	margin: 0 .1rem;
	background-color: transparent;
	border: none;
	border-radius: .5rem;
}
.table-btn:hover {
	color: #284a70;
	/* background-color: #0001; */
	text-decoration: none;
	font-weight: 400;
	filter: drop-shadow(0 0 1px #0005);
}
.table-btn:focus {
	box-shadow: none !important;
}
</style>