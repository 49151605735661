<template>
	<div v-if="typeof this.project !== 'undefined'">
		<construction-site-manager :project="project" :groupID="groupID" :projectType="projectType" :projectID="projectID" :permission="permission" @reload="reload"></construction-site-manager>
	</div>
</template>


<script>
import ConstructionSiteManager from '@/components/Project/Site/ConstructionSiteManager';

export default {
	components: {
		ConstructionSiteManager,
	},
	props: {
		groupID: {
			required: true,
			default: 0
		},
		projectType: {
			required: true,
			default: 0
		},
		projectID: {
			required: true,
			default: 0
		},
		project: {
			type: Object
		},
		permission: {
			type: Object,
		}
	},
	data() {
		return {
			group_id: Number.isInteger(parseInt(this.groupID)) ? parseInt(this.groupID) : 0,
			project_type: Number.isInteger(parseInt(this.projectType)) ? parseInt(this.projectType) : -1,
			project_id: Number.isInteger(parseInt(this.projectID)) ? parseInt(this.projectID) : -1,
		}
	},
	created() {
		if(typeof this.project === 'undefined') {
			this.reload()
		}
	},
	watch: {
	},
	mounted() {
	},
	methods:{
		reload() {
			this.$router.replace({name: '案件', params: {groupID: this.group_id, projectType: this.project_type, projectID: this.project_id, from: {name: '工地管理'}}});
		},
	}
}
</script>

<style scoped>
</style>