<template>
	<div class="login-page">
		<b-card class="login-card col-md-9 col-11" title="註冊">
			<form class="col-12 login-form">
				<b-row class="col-12 form-input-wrapper" v-for="(info, key) in infos" :key="key">
					<label class="col-2" :for="key"><i class="fa-fw" :class="info.icon" aria-hidden="true"></i></label>
					<b-form-input class="col-10 input-text" v-model="form[key]" :name="key" :type="info.type" :placeholder="info.placeholder" :state="info.state"></b-form-input>
					<span class="col-12 input-background"></span>
				</b-row>
				<b-row>
					<button class="col-12 input-submit btn btn-primary" @click="onSubmit">註冊</button>
				</b-row>
				<b-row>
					<b-card-text>
						已經是會員？<router-link to="/login" class="link">點此登入</router-link>
					</b-card-text>
				</b-row>
			</form>
		</b-card>
	</div>
</template>

<script>
	import { isEmpty } from '@/utils/assist';
	export default {
		data(){
			return {
				infos: {
					account: { value: "", placeholder: "帳戶", icon: "fas fa-user", type:"text" },
					name: { value: "", placeholder: "名稱", icon: "fas fa-user-circle", type:"text" },
					password: { value: "", placeholder: "密碼", icon: "fa fa-lock", type:"password" },
					email: { value: "", placeholder: "信箱", icon: "fas fa-envelope", type:"email" }
				},
				form: {},
			};
		},
		methods:{
			onSubmit(evt){
				for(let key in this.infos) {
					this.$set(this.infos[key], "state", isEmpty(this.form[key]) ? false : undefined)
				}

				evt.preventDefault();

				//執行註冊的action
			}
		}
	}
</script>

<style scoped>
	.login-page {
		min-height: 100vh;
		width: 100%;	
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		background-color: #f8f9ff;
	}
	.login-page * {
		margin: auto auto;
	}
	.login-card {
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 60px 10px;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(81, 87, 99, 0.12);
	}
	.login-form > * {
		margin: 1em 0;
	}
	.form-input-wrapper {
		padding: 0 1em;
		border-radius: 1.5em;
		height: 2.5em;
	}
	.card-title {
		text-align: center;
	}
	/* 清除自動填字背景色 */
	.input-text:-webkit-autofill,
	.input-text:-webkit-autofill:hover,
	.input-text:-webkit-autofill:focus,
	.input-text:-webkit-autofill:active {
		transition: background-color 5000s ease-in-out 0s;
		-webkit-transition: background-color 5000s ease-in-out 0s;
		-moz-transition: background-color 5000s ease-in-out 0s;
		-o-transition: background-color 5000s ease-in-out 0s;
		box-shadow: 0 0 0 30px white inset !important;
		-webkit-box-shadow: 0 0 0 30px white inset !important;
		-moz-box-shadow: 0 0 0 30px white inset !important;
		-o-box-shadow: 0 0 0 30px white inset !important;
	}
	.input-text {
		border: none;
		z-index: 3;
	}
	.input-text:focus + .input-background {
		border: 1px solid #9bc8de;
	}
	.input-text.is-invalid + .input-background{
		border: 1px solid red;
	}
	.input-submit {
		height: 2.5em;
		border-radius: 1.5em;
		border: 1px solid #e0e8e7;
		background-color: #e0e8e7;
		color: #283849;
	}
	.input-submit:hover {
		background-color: #d6e4e7;
		color: #283849;
	}
	.input-text:focus, .input-submit:focus {
		box-shadow: none;
		border: none;
	}
	.card-text {
		color: #90979b;
	}
	.link {
		color: #3d4450;
	}
	.link:hover {
		cursor: pointer;
		text-decoration: none;
		color: #015d87;
	}
	.input-background {
		position: absolute;
		left: 0;
		top: 0;
		border-radius: 1.5em;
		border: 1px solid #e0e8e7;
		width: 100%;
		height: 100%;
	}

</style>