<template>
	<loading v-if="loading"/>
	<div class="w-100" v-else>
		<b-form ref="form" @submit="onSubmit" class="create-form">
			<b-row class="form-row">
				<div class="col-lg-6 col-12">
					<div class="form-wrapper">
						<h5>基本資料</h5>
						<input-text class="pl-2" label-class="py-1" label="專案名稱" required disabled :value="group.name"></input-text>
						<input-select class="pl-2" label-class="py-1" label="案件種類" :options="constants.project_types" :keys="{value: 'index', text: 'text'}" v-model="result.project_type" required disabled></input-select>
						<input-form class="p-0" wrapper-class="pr-0 pl-2" :options="basicFormOptions" :actions="{submit: false, cancel: false}" v-model="result" :use-form="false"></input-form>
					</div>
				</div>
				<div class="col-lg-6 col-sm-12">
					<div class="form-wrapper">
						<h5>案件施工資料</h5>
						<b-row class="input pl-2" v-for="(option, key) in constructionDataFormOptions" :key="key">
							<div class="col-md-3 col-2 pr-2" v-if="option.project_data_type">
								<input-select :size="option.project_data_type.size" :options="option.project_data_type.options" :keys="option.project_data_type.keys" v-model="result.project_construction_data[key][option.project_data_type.key]" :required="option.project_data_type.required" :use-form="false"></input-select>
							</div>
							<div :class="option.project_data_type ? 'col-md-9 col-10' : 'col-12'">
								<input-radio :label="option.status.label" :label-class="option.status.labelClass" :options="option.status.options" :keys="option.status.keys" :required="option.status.required" v-model="result.project_construction_data[key][option.status.key]"></input-radio>
							</div>
						</b-row>
						<b-row class="mt-2 justify-content-center pl-2">
							<b-button class="form-btn clear-btn" type="button" @click="addConstructionDataType" v-if="unusedConstructionDataTypes.length">新增其他施工資料</b-button>
						</b-row>
						<hr>
					</div>
					<div class="form-wrapper">
						<h5>案件報告</h5>
						<b-card no-body class="border-0 control-board">
							<b-card-body class="p-0 pl-2">
								<b-tabs class="tabs-wrapper" fill active-nav-item-class="tab-title font-weight-bold" active-tab-class="tab-title" fluid>
									<b-tab class="tab-body" :title="unit_type.text" :active="key===0" v-for="(unit_type, key) in constants.unit_types" :key="key">
										<input-checkbox :options="unitReportTypes" :keys="{value: 'id', text: 'name'}" direction="column" v-model="result.unit_report_types[unit_type.index]"></input-checkbox>
									</b-tab>
								</b-tabs>
							</b-card-body>
						</b-card>
					</div>
				</div>
			</b-row>
			<!-- <b-row class="form-row">
				<div class="form-wrapper">
					<h5>新增工地</h5>
				</div>
			</b-row> -->
			<b-row style="align-self: center;" v-if="submittable">
				<b-button class="form-btn cancel-btn" @click="$router.go(-1)" variant="primary">取消</b-button>
				<b-button class="form-btn submit-btn" type="submit" variant="primary">確定</b-button>
			</b-row>
		</b-form>
	</div>
</template>

<script>
import InputText from '@/components/Input/InputText';
import InputCheckbox from '@/components/Input/InputCheckbox';
import InputRadio from '@/components/Input/InputRadio';
import InputFile from '@/components/Input/InputFile';
import InputSelect from '@/components/Input/InputSelect';
import InputTags from '@/components/Input/InputTags';
import InputForm from '@/components/Input/InputForm';
import Loading from '@/components/Loading/Loading.vue';
import { isEmpty, checkAllTrue } from '@/utils/assist';
import ProjectConfigs from '@/projects';

export default {
	name: 'ProjectCreator',
	components:{
		InputText,
		InputCheckbox,
		InputRadio,
		InputFile,
		InputSelect,
		InputTags,
		InputForm,
		Loading,
	},
	props: {
		group: {
			type: Object
		},
		projectDataTypes: {
			type: Array
		},
		value: {
			type: Object,
			default: () => { return {} }
		},
		submittable: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			group_id: Number.isInteger(parseInt(this.group.id)) ? parseInt(this.group.id) : 0,
			call: { getUnitList: false, getUnitBranchList: false, getBidList: false, getConstructionList: false, getUnitMemberList: false},
			loading: true,
			result: {},
			constants: {
				bid_list: [],
				construction_list: [],
				contractor_list: [],
				party_a_list: [],
				pipeline_center_list: [],
				tc_project_type_list: this.$store.getters.enum('telecom_project_type.type'),
				project_data_types: [],
				project_types: this.$store.getters.enum('project.type'),
				unit_branch_list: [],
				unit_list: [],
				unit_report_status_list: this.$store.getters.enum('project_unit_report.status'),
				unit_types: this.$store.getters.enum('unit.type'),
				unit_member_list: [],
			},
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
		value: {
			deep: true,
			handler(value) {
				this.setResult()
			}
		},
		result: {
			deep: true,
			handler(value, oldValue) {
				if(!Object.isEqual(value, oldValue)) {
					this.change()
				}
			}
		},
		'result.assigned_at': {
			immediate: true,
			handler(value) {
				if(value) {
					this.result.measured_at = (new Date(value)).addWorkDays(2)
					this.result.file_made_at = (new Date(value)).addDays(9)
				}
			}
		},
		'result.received_at': {
			immediate: true,
			handler(value) {
				if(value) {
					this.result.assigned_at = (new Date(value)).addWorkDays(1)
				}
			}
		},
	},
	created() {
		// this.getProjectDataType()
		this.getUnitList()
		this.getUnitBranchList()
		this.getBidList()
		this.getConstructionList()
		this.getUnitMemberList()
	},
	mounted() {
	},
	computed: {
		constructionDataTypes() {
			return this.projectDataTypes.filter(type => type.data_type_key === this.$store.getters.enum('project_data_type.data_type.construction_datum').key)
		},
		unusedConstructionDataTypes() {
			return this.constructionDataTypes.filter(type => {
				return !this.group.project_group_construction_data_types.find(construction_data_type => type.id === construction_data_type.project_data_type.id)
			})
		},
		constructionDataFormOptions() {
			const construction_data_status_list = this.$store.getters.enum('project_construction_datum.status')
			return this.result.project_construction_data.map(datum => {
				let data_type = this.constructionDataTypes.find(data_type => data_type.id === datum.project_data_type_id)
				return {
					project_data_type: datum.new ? {
						key: 'project_data_type_id',
						type: "select",
						label: '',
						options: this.unusedConstructionDataTypes,
						keys: {value: 'id', text: 'name'},
						size: 'xs',
						required: true,
					} : undefined,
					status: {
						key: 'status',
						type: "radio",
						label: datum.new ? '' : data_type.name,
						options: construction_data_status_list,
						keys: {value: 'index', text: 'text'},
						required: true,
						labelClass: datum.new ? undefined : 'col-md-3 col-2 p-0'
					}
				}
			})
		},
		unitReportTypes() {
			return this.projectDataTypes.filter(type => type.data_type_key === this.$store.getters.enum('project_data_type.data_type.unit_report').key)
		},
		selectedUnitIds() {
			return [
				this.result.party_a_id ? this.result.party_a_id : 0,
				this.result.pipeline_center_id ? this.result.pipeline_center_id : 0,
				this.result.contractor_id ? this.result.contractor_id : 0,
			].filter(o => o)
		},
		defaultPartyA() {
			let projectType = this.projectType
			if(projectType && !projectType.data.party_a_selectable && projectType.data.party_a_name) {
				let party_a = this.constants.party_a_list.find(unit => unit.name === projectType.data.party_a_name)
				if(party_a)
					return party_a
			}
			return undefined
		},
		partyAList() {
			let projectType = this.projectType
			if(projectType && projectType.data.party_a_name) {
				return this.constants.party_a_list.filter(unit => {
					return projectType.data.party_a_selectable ? projectType.data.party_a_name.includes(unit.name) : unit.name === projectType.data.party_a_name
				})
			}
			return this.constants.party_a_list
		},
		partyABranchList() {
			return this.result.party_a_id ? this.constants.unit_branch_list.filter(unit_branch => unit_branch.unit_id === this.result.party_a_id) : [];
		},
		unitMemberList() {
			return this.result.party_a_branch_id ? this.constants.unit_member_list.filter(member => member.unit_branch_id === this.result.party_a_branch_id) : (this.result.party_a_id ? this.constants.unit_member_list.filter(member => member.unit_id === this.result.party_a_id) : []);
		},
		bidList() {
			return this.constants.bid_list.filter(bid => this.selectedUnitIds.includes(bid.unit_id))
		},
		constructionList() {
			return [
				...this.result.party_a_branch_id ? this.constants.construction_list.filter(construction => construction.party_a_branch_id === this.result.party_a_branch_id) : [],
				// ...this.result.bid_id ? this.constants.construction_list.filter(construction => construction.bid_id === this.result.bid_id) : [],
			].map(construction => {
				return {
					...construction,
					text: construction.abbreviation && construction.abbreviation !== construction.name ? `${construction.abbreviation} (${construction.name})` : construction.name
				}
			})
		},
		supervisorList() {
			let supervisors = this.result.construction_id ? this.constants.construction_list.find(c => c.id === this.result.construction_id).supervisors : []
			return supervisors.length ? supervisors : this.unitMemberList
		},
		projectType() {
			return this.constants.project_types.find(type => type.index === this.result.project_type)
		},
		formParams() {
			return ProjectConfigs.formParams(this.projectType ? this.projectType.key : null)
		},
		basicFormOptions() {
			return [
				{ key: "party_a_id", type: "select", label: "甲方", required: true, options: this.partyAList, keys: {value: 'id', text: 'name'} },
				{ key: "party_a_branch_id", type: "select", label: "甲方區處", required: true, options: this.partyABranchList, keys: {value: 'id', text: 'name'} },
				{ key: "bid_id", type: "select", label: "標案", required: true, options: this.bidList, keys: {value: 'id', text: 'name'} },
				{ key: "pipeline_center_id", type: "select", label: "道管中心", required: true, options: this.constants.pipeline_center_list, keys: {value: 'id', text: 'name'} },
				{ key: "construction_id", type: "select", label: "工程", required: true, options: this.constructionList, keys: {value: 'id', text: 'text'} },
				{ key: "supervisor_id", type: "select", label: "監工", required: true, options: this.supervisorList, keys: {value: 'id', text: 'name'} },
				{ key: "commissioner_id", type: "select", label: "承辦人", required: true, options: this.unitMemberList, keys: {value: 'id', text: 'name'} },
				{ key: "construction_type", type: "select", label: "施工種類", required: true, options: this.constants.tc_project_type_list, keys: {value: 'index', text: 'text'} },
				{ key: "contractor_id", type: "select", label: "承包商", required: true, options: this.constants.contractor_list, keys: {value: 'id', text: 'name'} },
				{ key: "serial_number", type: "number", label: "流水號", required: false },
				{ key: "work_order_number", type: "text", label: "工作單編號", required: false },
				{ key: "address", type: "text", label: "施工地址", required: true },
				{ key: "work_number", type: "text", label: "施工案號/W案號", required: false },
				{ key: "excavation_permit_name", type: "text", label: "路證編號", placeholder: '無路證', required: false },
				{ key: "emergency_repair_number", type: "text", label: "搶修路證編號", required: false, hide: !this.result.excavation_permit_name },
				// { key: "approved_start_at", type: "date", label: "路證核准起日", required: false, !this.result.excavation_permit_name},
				// { key: "approved_end_at", type: "date", label: "路證核准迄日", required: false, !this.result.excavation_permit_name},
				{ key: "constructed_start_at", type: "date", label: "路證施工起日", required: false, hide: !this.result.excavation_permit_name},
				{ key: "constructed_end_at", type: "date", label: "路證施工迄日", required: false, hide: !this.result.excavation_permit_name},
				{ key: "expected_constructed_at", type: "date", label: "施工日期", required: true },
				{ key: "received_at", type: "date", label: "收件日期", required: true },
				{ key: "assigned_at", type: "date", label: "派工日期", required: true },
				{ key: "measured_at", type: "date", label: "測量日期", required: true },
				{ key: "file_made_at", type: "date", label: "文件製作日期", required: true },
				{ key: "scene_pictures_url", type: "url", label: "資料連結", required: true },
			].filter(option => this.formParams.includes(option.key))
		},
		resultData() {
			return {
				...this.result,
				...Object.map(Object.filter(this.result, value => value instanceof Date), value => value.toDateString()),
				excavation_permit_name: this.result.excavation_permit_name ? this.result.excavation_permit_name : '無路證',
				project_group_id: this.group_id, user_id: this.$store.getters.currentUser.id,
				project_construction_data: this.result.project_construction_data.map(datum => {
					return { project_data_type_id: datum.project_data_type_id, status: datum.status }
				}),
				project_unit_reports: Object.keys(this.result.unit_report_types).map(unit_type => {
					return this.result.unit_report_types[unit_type].map((type_id) => {
						return { unit_type: unit_type, project_data_type_id: type_id }
					})
				}).flat(),
			}
		},
	},
	methods:{
		onLoadEnd() {
			this.initResult()
			this.setResult()
			this.$emit('load')
		},
		initResult() {
			this.result = Object.assign(this.result, {
				project_type: this.group.project_type_index,
				expected_constructed_at: new Date().format('yyyy-MM-dd'),
				received_at: new Date().format('yyyy-MM-dd'),
				assigned_at: new Date().format('yyyy-MM-dd'),
				construction_data: this.group.project_group_construction_data_types.reduce((obj, type) => {
					obj[type.project_data_type.id] = null;
					return obj;
				}, {}),
				project_construction_data: this.constructionDataTypes.filter(type => {
					return this.group.project_group_construction_data_types.find(construction_data_type => construction_data_type.project_data_type_id === type.id)
				}).map(type => {
					return {
						project_data_type_id: type.id,
						status: null,
						new: false,
					}
				}),
				unit_report_types: this.$store.getters.enum('unit.type').reduce((obj, unit_type) => {
					obj[unit_type.index] = this.group.project_unit_report_types.filter(type => type.unit_type_key === unit_type.key).map(type => type.project_data_type.id)
					return obj;
				}, {}),
			})
		},
		setResult() {
			this.result = Object.assign(this.result, {
				...this.result,
				party_a_id: (this.defaultPartyA ?? {}).id,
				...this.value,
			})
		},
		getUnitList() {
			this.call.getUnitList = false
			this.$axios.getUnitList({}, (response) => {
				this.constants.unit_list = response.data;
				this.constants.party_a_list = response.data.filter(type => type.type_key === this.$store.getters.enum('unit.type.party_a').key);
				this.constants.contractor_list = response.data.filter(type => type.type_key === this.$store.getters.enum('unit.type.contractor').key);
				this.constants.pipeline_center_list = response.data.filter(type => type.type_key === this.$store.getters.enum('unit.type.pipeline_center').key);
				this.call.getUnitList = true
			}, (error) => {
				this.call.getUnitList = true
			})
		},
		getUnitBranchList() {
			this.call.getUnitBranchList = false
			this.$axios.getUnitBranchList({}, (response) => {
				this.constants.unit_branch_list = response.data;
				this.call.getUnitBranchList = true
			}, (error) => {
				this.call.getUnitBranchList = true
			})
		},
		getBidList() {
			this.call.getBidList = false
			this.$axios.getBidList({}, (response) => {
				this.constants.bid_list = response.data;
				this.call.getBidList = true
			}, (error) => {
				this.call.getBidList = true
			})
		},
		getConstructionList() {
			this.call.getConstructionList = false
			this.$axios.getConstructionList({orderBy: {abbreviation: 'asc'}}, (response) => {
				this.constants.construction_list = response.data;
				this.call.getConstructionList = true
			}, (error) => {
				this.call.getConstructionList = true
			})
		},
		getUnitMemberList() {
			this.call.getUnitMemberList = false
			this.$axios.getUnitMemberList({}, (response) => {
				this.constants.unit_member_list = response.data;
				this.call.getUnitMemberList = true
			}, (error) => {
				this.call.getUnitMemberList = true
			})
		},
		addConstructionDataType() {
			if(this.result.project_construction_data.length >= this.constructionDataTypes.length){
				alert('已超過施工資料數量')
				return;
			}
			this.result.project_construction_data.push({
				project_data_type_id: null,
				status: null,
				new: true,
			})
		},
		onSubmit(evt) {
			evt.preventDefault();
			if(this.result.project_construction_data.length > [...(new Set(this.result.project_construction_data.map(datum => datum.project_data_type_id)))].length) {
				alert('含有重複施工資料，請重新選擇施工資料種類')
				return
			}
			let result = this.resultData
			this.createProject(result)
		},
		checkForm() {
			if(!this.$refs.form.reportValidity())
				return false
			if(this.result.project_construction_data.length > [...(new Set(this.result.project_construction_data.map(datum => datum.project_data_type_id)))].length) {
				alert('含有重複施工資料，請重新選擇施工資料種類')
				return false
			}
			return true
		},
		createProject(data) {
			this.$axios.createProject(data, (response) => {
				let new_project_id = response.data.id
				let new_project_type = data.project_type
				alert("新增成功");
				this.$router.push({name: '案件資訊', params: {groupID: this.group_id, group: this.group, projectID: new_project_id, projectType: new_project_type}});
			}, (error) => {
				console.error(error)
			})
		},
		change() {
			this.$emit('input', this.resultData)
			this.$emit('change', this.resultData)
		},
	}
}
</script>

<style scoped>
.create-form {
	display: flex;
	flex-direction: column;
}
.create-form.disabled {
	overflow-y: hidden;
	overflow-x: hidden;
}
.form-row {
	margin: .5rem 0;
}
.form-wrapper {
	padding: 0 .5rem .5rem 1rem;
}

.child-option {
	margin-left: 16.67% !important;
}
.tab-body {
	padding: .5rem 1rem;
	border: 1px solid #dee2e6;
	border-top: 0;
}
.google-map {
	min-height: 300px;
	height: 100%;
}
.progress-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0005;
	z-index: 10;
}
.progress-bar-wrapper {
	width: 50%;
	margin: auto;
	top: 50%;
	background: #e1e6e690;
	box-shadow: 0 0 5px .5px #284a7090;
}
.progress-bar {
	background: #017ca7;
}
.progress-bar-striped {
	background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
	background-size: 1rem 1rem;
}
.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite;
}
</style>