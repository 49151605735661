<template>
	<!-- <b-row class="col-12 outside-wrapper" v-if="label"> -->
	<b-row class="w-100 outside-wrapper">
		<label class="input-label col-md-2 col-12" :class="`${labelClass} ${required ? 'input-label-required' : ''}`" v-if="label">{{label}}</label>
		<span class="form-input-wrapper" :class="size === 'xs' ? 'size-xs' : ''">
			<b-form-select ref="input" class="input-text input-select" v-model="model" :options="opts" :placeholder="placeholder" :disabled="disabled" :state="state" :required="required" @input="input" @change="change"></b-form-select>
			<div class="d-flex justify-content-end" style="position:absolute">
				<div class="input-text input-select" :disabled="disabled" :state="state" :required="required" @click="click"></div>
				<b-form-input class="input-text w-0 h-0 text-transparent" :placeholder="placeholder" readonly :disabled="disabled" v-if="multiple"></b-form-input>
				<b-form-input class="input-text w-0 h-0 text-transparent" :placeholder="placeholder" :value="findText(model)" readonly :disabled="disabled" v-else></b-form-input>
			</div>
			<span class="input-background-wrapper">
				<span class="input-background"></span>
				<span class="input-tag" v-if="multiple">
					<b-badge class="tag-badge" v-for="(item, key) in model" :key="key">
						{{ findText(item) }}
						<button type="button" class="tag-badge-close-btn" @click="deleteTag" :value.prop="item"><i class="fas fa-times"></i></button>
					</b-badge>
				</span>
				<span class="input-text-value text-ellipsis" v-else>
					{{findText(model)}}
				</span>
				<button type="button" class="col-1 input-label link p-0 pr-1" @click="click"><i class="fa-fw fas fa-caret-down" aria-hidden="true"></i></button>
				<span class="options-wrapper">
					<span class="option-wrapper">
						<div class="option" :class="model===option.value || multiple&&model.includes(option.value) ? 'selected' : ''" v-for="(option, key) in opts" :key="`select_option${key}`" :data-value="option.value" @mousedown="selectOption($event.target.dataset.value)">
							{{option.text}}
						</div>
					</span>
				</span>
			</span>
		</span>
		<b-row class="w-100">
			<div class="col-md-2 col-12" :class="labelClass"></div>
			<div class="flex-fill text-hint font-size-sm text-left" :class="hintClass" v-if="hint">* {{hint}}</div>
		</b-row>
	</b-row>
</template>

<script>
export default {
	name: 'InputSelect',
	components: {
	},
	props: {
		options: {
			type: Array,
			default() {
				return [];
			}
		},
		keys: {
			type: [Object, String],
			default: ''
		},
		value: {
			type: [Number, String, Array],
		},
		placeholder: {
			type: String,
			default: ""
		},
		size: {
			type: String,
			default: ""
		},
		label: {
			type: String,
			default: ""
		},
		iconlabel: {
			type: String,
			default: ""
		},
		labelClass: {
			type: String,
			default: ""
		},
		disabled: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false
		},
		state: {
			type: Boolean,
			default: null
		},
		hint: {
			type: String,
			default: ""
		},
		hintClass: {
			type: [Object, String],
			default: ""
		},
		multiple: {
			type: Boolean,
			default: false
		},
		emptyOption: {
			type: Boolean,
		}
	},
	data() {
		return {
			model: this.multiple && !Array.isArray(this.value) ? [] : ((!Number.isNaN(Number(this.value)) || this.value )? this.value : ''),
			multimodel: '',
			opts: [],
			emptyValue: 'undefined',
		}
	},
	watch: {
		value: {
			handler(value) {
				this.setValue(value)
			}
		},
		options: {
			deep: true,
			handler(val, old) {
				if(!Object.isEqual(val, old)){
					this.getOptions(this.options, this.keys)
				}
			}
		},
		keys: {
			deep: true,
			handler(val, old) {
				if(!Object.isEqual(val, old)){
					this.getOptions(this.options, this.keys)
				}
			}
		},
		model: {
			deep: true,
			handler(value) {
				// this.multimodel = this.multiple && Array.isArray(this.model) && (!Number.isNaN(Number(this.model[0])) || this.model[0]) ? this.model[0] : ''
			}
		},
	},
	created() {
		this.getOptions(this.options, this.keys)
	},
	computed: {
		hasEmptyOption() {
			return !this.multiple && ((!this.required && this.emptyOption === undefined) || this.emptyOption)
		},
	},
	methods: {
		setValue(value) {
			if(this.multiple && !Array.isArray(value)) {
				value = []
			}
			if(!this.multiple && `${this.model}` !== `${value}` || this.multiple && !Array.isArray(this.model) || this.multiple && Array.isArray(this.model) && [...this.model].sort().toString() !== [...value].sort().toString()) {
				this.model = value
			}
		},
		getOptions(options, key) {
			this.opts = Array.isArray(options) ? options.map(option => {
				let opt = typeof option === 'object' && !this.isEmpty(key.text) && !this.isEmpty(key.value) ? { text: this.getRecursiveValue(option, key.text), value: this.getRecursiveValue(option, key.value) } : typeof option === 'object' && typeof key === 'string' && !this.isEmpty(option[key]) ? { text: option[key], value: option[key] } : typeof option === 'object' && !this.isEmpty(option.text) && !this.isEmpty(option.value) ? option : { text: option, value: option }
				return {...option, ...opt}
			}) : [{ text: options, value: options }];
			if(this.hasEmptyOption) {
				if(this.opts.length)
					this.opts.unshift({ text: '', value: this.emptyValue })
			}
			else {
				this.autoSelect()
			}
			if(this.opts.length)
				this.checkValue()
			this.$forceUpdate()
		},
		selectOption(value) {
			value = Number.isNaN(Number.parseInt(value)) ? value : Number.parseInt(value)
			if(this.multiple) {
				if(!this.model.includes(value))
					this.model.push(value)
			}
			else {
				this.model = value
			}
		},
		autoSelect() {
			if(!this.multiple && Array.isArray(this.opts) && this.opts.length === 1 && this.model !== this.opts[0].value) {
				this.input(this.opts[0].value)
			}
		},
		checkValue() {
			if(this.multiple) {
				if(Array.isArray(this.model)) {
					this.model.forEach((v, index) => {
						if(!this.opts.map(o=>o.value).includes(v))
							this.model.splice(index, 1)
					})
				}
			}
			else {
				if(!this.opts.map(o=>o.value).includes(this.model)){
					this.model = undefined
				}
			}
		},
		click(e) {
			e.preventDefault();
			this.$refs.input.focus()
			this.$refs.input.$el.focus()
			this.$refs.input.$el.click()
		},
		input(e) {
			if(e === undefined) {
				this.setValue(this.value)
			}
			else {
				this.$emit('input', e === this.emptyValue ? undefined : e)
			}
		},
		change(e) {
			if(e === undefined) {
				this.setValue(this.value)
			}
			else {
				this.$emit('input', e === this.emptyValue ? undefined : e)
			}
		},
		deleteTag(e) {
			e.preventDefault();
			let target = e.target;
			while(target.tagName.toLowerCase() == 'svg' || target.tagName.toLowerCase() == 'path' || target.tagName.toLowerCase() == 'i')
				target = target.parentNode;
			let value = Number.isNaN(Number.parseInt(target.value)) ? target.value : Number.parseInt(target.value)
			let index = this.model.indexOf(value)
			if(index >= 0)
				this.model.splice(index, 1)
		},
		findText(index) {
			index = Number.isNaN(Number.parseInt(index)) ? index : Number.parseInt(index)
			let option = this.opts.find((opt) => {
				return index === opt.value
			})
			return typeof option === 'undefined' ? '' : option.text
		},
		getRecursiveValue(obj, key_str, key_delim='.') {
			let keys = key_str.split(key_delim);
			let res = obj;
			while(keys.length) {
				let key = keys.shift();
				if(typeof res === 'undefined' || res === null)
					break;
				res = res[key];
			}
			return res
		},
		isEmpty(obj) {
			// null and undefined are "empty"
			if (obj == null || typeof obj === "undefined") return true;

			if(!Number.isNaN(Number(obj))) return false

			// Assume if it has a length property with a non-zero value
			// that that property is correct.
			if (obj.length > 0)    return false;
			if (obj.length === 0)  return true;

			// If it isn't an object at this point
			// it is empty, but it can't be anything *but* empty
			// Is it empty?  Depends on your application.
			if (typeof obj !== "object") return true;

			// Otherwise, does it have any properties of its own?
			// Note that this doesn't handle
			// toString and valueOf enumeration bugs in IE < 9
			var hasOwnProperty = Object.prototype.hasOwnProperty;
			for (var key in obj) {
				if (hasOwnProperty.call(obj, key)) return false;
			}

			return true;
		}
	}
}
</script>

<style scoped>
.outside-wrapper {
	align-items: center;
}
.form-input-wrapper {
	padding: 0 1rem;
	border-radius: 1.5rem;
	height: 2.5rem;
	display: flex;
	align-items: center;
	flex: 1 1;
}
.form-input-wrapper.size-xs {
	border-radius: 0.5rem;
	height: 1.8rem;
}
.form-input-wrapper.size-xs .input-label {
	display: flex;
	align-items: center;
}
.form-input-wrapper.size-xs .input-label [data-icon="search"] {
	font-size: 0.8rem
}
/* 清除自動填字背景色 */
.input-text:-webkit-autofill,
.input-text:-webkit-autofill:hover,
.input-text:-webkit-autofill:focus,
.input-text:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
	-webkit-transition: background-color 5000s ease-in-out 0s;
	-moz-transition: background-color 5000s ease-in-out 0s;
	-o-transition: background-color 5000s ease-in-out 0s;
}
.form-input-wrapper.size-xs .input-text {
	height: 1rem;
	font-size: 0.9rem;
	padding: 0;
}
.form-input-wrapper.size-xs .input-text.input-select {
	padding: 0 .5rem 0 0;
	height: 100%;
}
.input-text.input-select {
	color: transparent;
}
.input-text {
	border: none;
	z-index: 3;
	padding: .25rem;
	background-color: transparent;
	text-overflow: ellipsis;
	overflow-x: hidden;
	white-space: nowrap;
}
.input-text:focus {
	border: none;
	box-shadow: none;
}
.input-text:focus ~ .input-background-wrapper .input-background {
	border-radius: 1.5rem 1.5rem 0 0;
	border: 1px solid #6b8ca9;
	border-bottom: none;
	box-shadow: 0 0 1px 1px #8ea9c230, 0 0 1px 1px #8ea9c230;
	/* animation: animatebackground 0.4s; */
}
.form-input-wrapper.size-xs .input-background-wrapper .input-background {
	border-radius: 0.8rem;
}
.form-input-wrapper.size-xs .input-text:focus ~ .input-background-wrapper .input-background {
	border-radius: 0.8rem 0.8rem 0 0;
}
.options-wrapper {
	max-height: 30vh;
	overflow: auto;
}
.input-text:focus ~ .input-background-wrapper .input-background ~ .options-wrapper {
	display: inline-block;
	border: 1px solid #6b8ca9;
	border-top: none;
	box-shadow: 0 2px 1px 1px #8ea9c230, 0 2px 1px 1px #8ea9c230;
}
.input-text[state=false] + .input-label,
.input-text[state=false]:focus ~ .input-label {
	color: #e79580
}
.input-text[state=false] ~ .input-background-wrapper .input-background,
.input-text.is-invalid ~ .input-background-wrapper .input-background {
	border: 1px solid #e79580;
}
.input-text[state=false]:focus ~ .input-background-wrapper .input-background,
.input-text.is-invalid:focus ~ .input-background-wrapper .input-background{
	border: 1px solid #d87b6a;
	box-shadow: 0 0 1px 1px #e7958030, 0 0 1px 1px #e7958030;
}
.input-text[disabled], .input-text.is-invalid, .input-text[state=false] {
	background: transparent;
	background-image: none;
}
.input-text[disabled] ~ .input-background-wrapper > .input-background {
	background-color: #e9ecef;
	border: 1px solid #b8bcbb;
}
.input-background {
	position: absolute;
	left: 0;
	/* top: 0; */
	border-radius: 1.5rem;
	border: 1px solid #8ea9c2;
	width: 100%;
	min-height: 100%;
}
.input-background-wrapper {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: absolute;
	left: 0;
	width: 100%;
	min-height: 100%;
}
.input-select {
	position: absolute;
	left: 0;
	top: 0;
	width: calc(100% - 1.5rem);
	width: -webkit-calc(100% - 1.5rem);
	width: -moz-calc(100% - 1.5rem);
	width: -o-calc(100% - 1.5rem);
	height: 100%;
	margin: 0 .5rem 0 1rem;
	padding: .5rem .5rem .5rem 0;
	z-index: 4;
}
.form-input-wrapper .options-wrapper {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	padding: .5rem 1rem;
	border-radius: 0 0 1.5rem 1.5rem;
	border: 1px solid #8ea9c2;
	border-top: none;
	z-index: 5;
	background-color: white;
}
.form-input-wrapper.size-xs .options-wrapper {
	border-radius: 0 0 0.8rem 0.8rem;
}
.input-select ~ .input-background-wrapper .input-background ~ .options-wrapper > .option-wrapper > .option {
	/* display: flex; */
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	/* text-overflow: ellipsis;
	overflow-x: hidden;
	white-space: nowrap; */
	white-space: normal;
	padding: 2px 0;
}
.input-select ~ .input-background-wrapper .input-background ~ .options-wrapper > .option-wrapper > .option > .selected-icon {
	flex: 0 0 1rem;
	visibility: hidden;
}
.input-select ~ .input-background-wrapper .input-background ~ .options-wrapper > .option-wrapper > .option.selected > .selected-icon {
	visibility: visible;
}
.input-select ~ .input-background-wrapper .input-background ~ .options-wrapper > .option-wrapper > .option.selected {
	background-color: #8ea9c250;
}
.input-select ~ .input-background-wrapper .input-background ~ .options-wrapper > .option-wrapper > .option:focus,
.input-select ~ .input-background-wrapper .input-background ~ .options-wrapper > .option-wrapper > .option:hover {
	background-color: #8ea9c230;
	cursor: pointer;
}
.input-label {
	background-color: transparent;
	border: none;
	z-index: 3;
	display: flex;
	align-items: center;
	padding-right: .5rem;
}
button.input-label {
	color: #6b8ca9;
}
label.input-label {
	overflow-wrap: break-word;
	word-wrap: break-word;
	white-space: normal;
}
label.input-label.input-label-required::after {
	content: '*';
	color: crimson;
}
.invisible {
	visibility: hidden;
}
select {
	background: transparent;
}
.input-text-value {
	flex: 1 1;
	font-weight: 400;
	line-height: 1.5;
	color: #49506E;
	padding: .25rem 1.25rem;
}
.form-input-wrapper.size-xs .input-text-value {
	font-size: 0.9rem;
	padding: .25rem 1rem;
}
.input-tag {
	/* position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	padding: .5rem 1rem; */
	display: flex;
	flex-wrap: wrap;
	flex: 1 1;
	height: 100%;
	padding: .25rem .5rem;
}
.size-xs .input-tag {
	padding: .2rem 1rem;
}
.input-tag .tag-badge {
	height: 100%;
	display: inline-flex;
	align-items: center;
	border-radius: 1rem;
	background-color: #b2c8d820;
	color: #284a70;
	border: 1px solid #6b8ca930;
	box-shadow: 0 0 2px 1px #6b8ca930, 0 0 2px 1px inset #6b8ca930;
	z-index: 4;
	margin: .025rem .1rem;
	padding: .25rem .5rem;
}
.input-tag .tag-badge:hover {
	pointer-events: none;
}
.input-tag .tag-badge > .tag-badge-close-btn {
	display: none;
}
.input-tag .tag-badge:hover > .tag-badge-close-btn {
	position: absolute;
	top: -.35rem;
	right: -.35rem;
	font-size: 0.8em;
	background-color: white;
	width: 1rem;
	height: 1rem;
	line-height: 1rem;
	text-align: center;
	border: none;
	border-radius: 50%;
	box-shadow: 0 0 2px .5px #cbd8e1, 0 0 2px .5px inset #cbd8e1;
	align-items: center;
	display: flex;
	justify-content: center;
	align-content: center;
	cursor: pointer;
	pointer-events: auto;
}

.animate-top {
	position: relative;
	animation: animatetop 1s;
}
@keyframes animatetop {
	from {
		top: -1rem;
		height: 0;
		opacity: 0;
	}
	to {
		opacity: 1;
		height: max-content;
	}
}
@keyframes animatebackground {
	from {
		border-radius: 1.5rem;
	}
	to {
		border-radius: 1.5rem 1.5rem 0 0;
	}
}

.invisible {
	visibility: hidden;
}

</style>
