import Type from './Type'

// 案件資料key對應表
const projectKeysTable = {
	id: { key: 'id', required: true },
	address: { key: 'address', text: '地址', required: true }, // 地址
	excavationPermit: { key: [ 'excavation_permit_name', 'excavation_permit' ], text: '路證', required: true }, // 路證
	constructedDate: { key: [ 'expected_constructed_at', 'constructed_date' ], text: '施工日期', required: true }, // 施工日期
	measuredDate: { key: [ 'measured_at', 'measured_date' ], text: '測量日期', required: true }, // 測量日期
	fileMadeDate: { key: [ 'file_made_at', 'file_made_date' ], text: '文件製作日期', required: true }, // 文件製作日期
	pipelineCenter: { key: [ 'pipeline_center_name', 'pipeline_center' ], text: '道管中心', required: true }, // 道管中心
	partyA: { key: [ 'party_a.name', 'party_a' ], text: '甲方', required: true }, // 甲方
	partyAArea: { key: [ 'party_a_branch_full_name', 'party_a_area' ], text: '甲方區處', required: true }, // 甲方區處
	// management: { key: 'management', text: '管理單位代號' }, // 管理單位代號
	permitGrantedUnitNumber: { key: 'permit_granted_unit_number', text: '發證單位代碼' }, // 發證單位代碼
	pipelineNumber: { key: 'pipeline_number', text: '管線識別碼' }, // 管線識別碼
	holeNumber: { key: 'hole_number', text: '人手孔識別碼' }, // 人手孔識別碼
	otherFacilityNumber: { key: 'other_facility_number', text: '其他設施識別碼' }, // 其他設施識別碼
	valveNumber: { key: 'valve_number', text: '閥類識別碼' }, // 閥類識別碼
}
const projectDataKeysTable = {
	partyA: {
		TAIPEI_WATER: '臺北自來水',
		TELECOM: '中華電信',
		TAIWAN_WATER_FIRST: '台水一區',
		TAIWAN_WATER_SECOND: '台水二區',
		TAIWAN_WATER_THIRD: '台水三區',
		TAIWAN_WATER_SIXTH: '台水六區',
		TAIWAN_WATER_SEVENTH: '台水七區',
		TAIWAN_WATER_TWELFTH: '台水十二區',
		TAIWAN_WATER_FIFTEENTH: '台水十五區',
		SEWERAGE_SEWAGE: '衛工處-污水',
		HYDRAULIC_ENGINEERING: '台北水利處',
	},
	pipelineCenter: {
		TAIPEI_CITY: '臺北市',
		NEW_TAIPEI_CITY: '新北市',
		KEELUNG_CITY: '基隆市',
		TAOYUAN_CITY: '桃園市',
		HSINCHU_CITY: '新竹市',
		HSINCHU_COUNTY: '新竹縣',
		MIAOLI_COUNTY: '苗栗縣',
		TAICHUNG_CITY: '臺中市',
		CHANGHUA_COUNTY: '彰化縣',
		NANTOU_COUNTY: '南投縣',
		CHIAYI_CITY: '嘉義市',
		CHIAYI_COUNTY: '嘉義縣',
		YUNLIN_COUNTY: '雲林縣',
		TAINAN_CITY: '臺南市',
		KAOHSIUNG_CITY: '高雄市',
		PINGTUNG_COUNTY: '屏東縣',
		YILAN_COUNTY: '宜蘭縣',
		HUALIEN_COUNTY: '花蓮縣',
		TAITUNG_COUNTY: '臺東縣',
		PENGHU_COUNTY: '澎湖縣',
		KINMEN_COUNTY: '金門縣',
		LIENCHIANG_COUNTY: '連江縣',
	},
}

const projectData = {
	GML_VERSIONS: {
		KEYS: ['pipelineCenter', 'partyA'],
		VALUES: {
			'臺北市|臺北自來水': [ 'TAIPEI_WATER', 'TAIPEI_WATER_WEB', 'TAIPEI_CITY', 'TAIPEI_CITY_QGIS' ],
			'新北市|臺北自來水': [ 'TAIPEI_WATER', 'TAIPEI_WATER_WEB', 'NEW_TAIPEI_CITY', 'NEW_TAIPEI_CITY_QGIS', 'STANDARD_STRICT' ],
			'臺北市|衛工處-污水': [ 'TAIPEI_SEWERAGE', 'TAIPEI_SEWERAGE_QGIS' ],
			'臺北市|台北水利處': [ 'TAIPEI_HYDRAULIC', 'TAIPEI_HYDRAULIC_QGIS' ],
			臺北市: [ 'TAIPEI_CITY', 'TAIPEI_CITY_QGIS' ],
			新北市: [ 'NEW_TAIPEI_CITY', 'NEW_TAIPEI_CITY_QGIS', 'STANDARD_STRICT' ],
			基隆市: [ 'STANDARD_STRICT' ],
			桃園市: [ 'STANDARD_STRICT', 'TAOYUAN_CITY' ],
			嘉義縣: [ 'CHIAYI_COUNTY', 'STANDARD' ],
			雲林縣: [ 'YUNLIN_COUNTY' ],
			臺南市: [ 'TAINAN_CITY' ],
			高雄市: [ 'STANDARD_STRICT' ],
			DEFAULT: [ 'STANDARD' ]
		}
	},
	// 檔案名稱格式
	FILENAME_FORMAT: {
		KEYS: ['partyA'],
		VALUES: {
			DEFAULT: 'VERSION_TAG.PERMIT._.ADDRESS',
			'臺北自來水': 'VERSION_TAG.PERMIT._.ADDRESS.HAS_DITCH_COVER',
		},
	},
	// 各區處管理單位代號
	MANAGEMENT: {
		KEYS: ['pipelineCenter', 'partyA', 'partyAArea'],
		VALUES: {
			'臺北市|臺北自來水': 'WP',
			'新北市|臺北自來水|臺北自來水北區營業分處': 'WA02',
			'新北市|臺北自來水|臺北自來水東區營業分處': 'WA03',
			'新北市|臺北自來水|臺北自來水南區營業分處': 'WA04',
			'新北市|臺北自來水|臺北自來水西區營業分處': 'WA05',
			'新北市|臺北自來水|臺北自來水工程總隊': 'WA06',
			'新北市|臺北自來水': 'WA07',
			'新北市|台水一區|台灣自來水股份有限公司第一區管理處淡水營運所': 'WR12',
			'新北市|台水一區|台灣自來水股份有限公司第一區管理處瑞芳營運所': 'WR13',
			'新北市|台水一區|台灣自來水股份有限公司第一區管理處萬里金山營運所': 'WR14',
			'新北市|台水一區|台灣自來水股份有限公司第一區管理處貢寮雙溪營運所': 'WR15',
			'基隆市|台水一區|台灣自來水股份有限公司第一區管理處工務課': 'WB',
			'基隆市|台水一區|台灣自來水股份有限公司第一區管理處基隆營運所': 'WE',
			'新北市|台水二區|台灣自來水股份有限公司第二區管理處林口服務所': 'WR17',
			'桃園市|台水二區|台灣自來水股份有限公司第二區管理處龜山服務所': 'WC',
			'桃園市|台水二區|台灣自來水股份有限公司第二區管理處工務課': 'W2',
			'新竹縣|台水三區|臺灣自來水公司第三區管理處湖口營運服務所': 'W004',
			'嘉義市|台水五區|台灣自來水股份有限公司第五區管理處嘉義服務所': 'WA01',
			'嘉義市|台水五區|台灣自來水股份有限公司第五區管理處嘉義給水廠': 'WA03',
			'嘉義縣|台水五區': 'W1',
			// '嘉義縣|台水五區|台灣自來水股份有限公司第五區管理處嘉義服務所': 'W1',
			// '嘉義縣|台水五區|台灣自來水股份有限公司第五區管理處朴子服務所': 'W1',
			// '嘉義縣|台水五區|台灣自來水股份有限公司第五區管理處義竹服務所': 'W1',
			// '嘉義縣|台水五區|台灣自來水股份有限公司第五區管理處竹崎營運所': 'W1',
			// '嘉義縣|台水五區|台灣自來水股份有限公司第五區管理處民雄營運所': 'W1',
			// '嘉義縣|台水五區|台灣自來水股份有限公司第五區管理處嘉義給水廠': 'W1',
			// '嘉義縣|台水五區|台灣自來水股份有限公司第五區管理處古坑營運所': 'W1',
			// '嘉義縣|台水五區|台灣自來水股份有限公司第五區管理處新港營運所': 'W1',
			'雲林縣|台水五區': '台灣自來水股份有限公司第五區管理處',
			// '雲林縣|台水五區|台灣自來水股份有限公司第五區管理處北港營運所': '',
			// '雲林縣|台水五區|台灣自來水股份有限公司第五區管理處斗南服務所': '',
			// '雲林縣|台水五區|台灣自來水股份有限公司第五區管理處台西營運所': '',
			// '雲林縣|台水五區|台灣自來水股份有限公司第五區管理處斗六服務所': '',
			// '雲林縣|台水五區|台灣自來水股份有限公司第五區管理處西螺服務所': '',
			// '雲林縣|台水五區|台灣自來水股份有限公司第五區管理處古坑營運所': '',
			// '雲林縣|台水五區|台灣自來水股份有限公司第五區管理處虎尾服務所': '',
			// '雲林縣|台水五區|台灣自來水股份有限公司第五區管理處雲林給水廠': '',
			'臺南市|台水六區|台南給水廠': 'WA03',
			'臺南市|台水六區|永康服務所': '50',
			'臺南市|台水六區|新市服務所': '46',
			'臺南市|台水六區|歸仁服務所': '48',
			'臺南市|台水六區|佳里服務所': '47',
			'臺南市|台水六區|麻豆服務所': '153',
			'臺南市|台水六區|新營服務所': '44',
			'臺南市|台水六區|玉井服務所': '42',
			'臺南市|台水六區|白河服務所': '45',
			'臺南市|台水六區|烏山頭給水廠': '55',
			'臺南市|台水六區|南化給水廠': '65',
			'臺南市|台水六區|台南服務所': 'WA02',
			'高雄市|台水七區': 'W1',
			'新北市|台水十二區|台灣自來水股份有限公司第十二區管理處板橋服務所': 'WR01',
			'新北市|台水十二區|台灣自來水股份有限公司第十二區管理處樹林服務所': 'WR04',
			'新北市|台水十二區|台灣自來水股份有限公司第十二區管理處土城服務所': 'WR02',
			'新北市|台水十二區|台灣自來水股份有限公司第十二區管理處蘆洲服務所': 'WR06',
			'新北市|台水十二區|台灣自來水股份有限公司第十二區管理處新莊服務所': 'WR03',
			'新北市|台水十二區|台灣自來水股份有限公司第十二區管理處泰山營運所': 'WR07',
			'新北市|台水十二區|台灣自來水股份有限公司第十二區管理處鶯歌服務所': 'WR05',
			'新北市|台水十二區|台灣自來水股份有限公司第十二區管理處板新給水廠': 'WR08',
			'新北市|台水十二區|台灣自來水股份有限公司第十二區管理處工務課': 'WR18',
			'屏東縣|台水屏東區': 'W1',
			'臺北市|衛工處-污水|臺北市政府工務局衛生下水道工程處': 'SS',
			'臺北市|中華電信|臺灣北區電信分公司': 'TB',
			'臺北市|中華電信|臺灣北區電信分公司臺北營運處(東區)': 'TE',
			'臺北市|中華電信|臺灣北區電信分公司臺北營運處(北區)': 'TN',
			'臺北市|中華電信|臺灣北區電信分公司臺北營運處(士林)': 'TH',
			'新北市|中華電信|臺灣北區電信分公司': 'TB17',
			'新北市|中華電信|臺灣北區電信分公司臺北營運處(基隆)': 'TB14',
			'基隆市|中華電信|中華電信基隆處': 'CA',
			'臺北市|台北水利處': 'AE',
		}
	},
	// 各廠商設施編號格式
	FEATURE_NUMBER_FORMAT: {
		KEYS: ['partyA', 'pipelineCenter'],
		VALUES: {
			DEFAULT: ['FORM_FEATURE_NUMBER', 'FEATURE_CODE.PERMIT.INDEX'],
			'台水一區': 'FEATURE_CODE.PERMIT_WITHOUT_SIGNS.INDEX',
			'台水一區|基隆市': 'FEATURE_CODE.PERMIT_WITHOUT_SIGNS.INDEX-4',
			'中華電信': ['FORM_FEATURE_NUMBER', 'PERMIT.-.INDEX'], // 路證編號-index
			'中華電信|臺北市': ['FORM_FEATURE_NUMBER', 'MANAGEMENT.PERMIT.-.INDEX'],
			'中華電信|新北市': ['FORM_FEATURE_NUMBER', 'MANAGEMENT.PERMIT_UNIT.FEATURE_SEQUENCE-6'], // NTP: 發證單位編號+流水號(6)
			'中華電信|基隆市': ['FORM_FEATURE_NUMBER', 'U004.PERMIT.INDEX-3'], // KL: U004+路證編號+index(3)
			'衛工處-污水': {
				// utility category
				PIPELINE: 'FORM_IDENTIFY_CODE',
				HOLE: 'MANAGEMENT.00.FORM_IDENTIFY_CODE',
				DEFAULT: 'MANAGEMENT.FORM_IDENTIFY_CODE',
			},
			'台北水利處': 'MANAGEMENT.PERMIT.-.INDEX',
		},
	},
	// 各廠商識別碼格式
	IDENTIFY_CODE_FORMAT: {
		KEYS: ['partyA', 'pipelineCenter'],
		VALUES: {
			DEFAULT: 'MANAGEMENT.FEATURE_NUMBER',
			'中華電信': 'PERMIT.-.INDEX', // 路證編號-index
			'中華電信|臺北市': 'MANAGEMENT.PERMIT.-.INDEX',
			'中華電信|新北市': 'MANAGEMENT.PERMIT_UNIT.FEATURE_SEQUENCE-6', // NTP: 發證單位編號+流水號(6)
			'中華電信|基隆市': 'U004.PERMIT.INDEX-3', // KL: U004+路證編號+index(3)
			'衛工處-污水': {
				// utility category
				PIPELINE: 'MANAGEMENT.0.FORM_IDENTIFY_CODE',
				HOLE: 'MANAGEMENT.00.FORM_IDENTIFY_CODE',
				DEFAULT: 'MANAGEMENT.FORM_IDENTIFY_CODE',
			},
			'台北水利處': 'FEATURE_NUMBER',
		},
	},
	// 各廠商類別碼大類-中類-小類
	UTILITY_CATEGORY: {
		KEYS: ['partyA'],
		VALUES: {
			DEFAULT: 'UTILITY.WATER.WATER_SYSTEM',
			'中華電信': 'UTILITY.TELECOM.TELECOM_SYSTEM',
			// '電力公司': 'UTILITY.POWER.?',
			'衛工處-污水': 'UTILITY.SEWERAGE.SEWAGE_SYSTEM',
			'台北水利處': 'UTILITY.SEWERAGE.RAINWATER_SYSTEM',
		},
	},
	PIPELINE_LENGTH_CALCULATION: {
		KEYS: ['partyA'],
		VALUES: {
			DEFAULT: 'THREE-DIMENSIONAL',
			'台水六區': 'TWO-DIMENSIONAL',
		},
	},
}

// 點位資料key對應表
const pointKeysTable = {
	id: 'id',
	name: 'name',
	index: 'index',
	X: 'twd97_x',
	Y: 'twd97_y',
	ellipsoidal_height: 'ellipsoidal_height',
	orthometric_height: [ 'orthometric_height', '正高' ],
	depth: '必填欄位_埋深',
	type: '必填欄位_設施物',
	other_facility_type: '必填欄位_設施物_其他',
	feature_type: '必填欄位_型態',
	feature_category: '必填欄位_種類',
	pipeline_type1: '必填欄位_管線種類1',
	material1: '必填欄位_材質1',
	pipeline_width1: [ '必填欄位_管徑1', '必填欄位_管徑寬度1' ],
	pipeline_height1: '必填欄位_管徑高度1',
	pipeline_column_amount1: '必填欄位_涵管行數1',
	pipeline_row_amount1: '必填欄位_涵管列數1',
	pipeline_type2: '必填欄位_管線種類2',
	material2: '必填欄位_材質2',
	pipeline_width2: [ '必填欄位_管徑2', '必填欄位_管徑寬度2' ],
	pipeline_height2: '必填欄位_管徑高度2',
	pipeline_column_amount2: '必填欄位_涵管行數2',
	pipeline_row_amount2: '必填欄位_涵管列數2',
	pipeline_type3: '必填欄位_管線種類3',
	material3: '必填欄位_材質3',
	pipeline_width3: [ '必填欄位_管徑3', '必填欄位_管徑寬度3' ],
	pipeline_height3: '必填欄位_管徑高度3',
	pipeline_type4: '必填欄位_管線種類4',
	material4: '必填欄位_材質4',
	pipeline_width4: [ '必填欄位_管徑4', '必填欄位_管徑寬度4' ],
	pipeline_height4: '必填欄位_管徑高度4',
	target1: '連接點1_點位',
	target1_info: [ '連接點1_材質/管徑', '連接點1_材質/管徑/行/列數', '連接點1_材質/寬度/高度' ],
	target2: '連接點2_點位',
	target2_info: [ '連接點2_材質/管徑', '連接點2_材質/管徑/行/列數', '連接點2_材質/寬度/高度' ],
	target3: '連接點3_點位',
	target3_info: [ '連接點3_材質/管徑', '連接點3_材質/管徑/行/列數', '連接點3_材質/寬度/高度' ],
	length: '必填欄位_長度',
	width: '必填欄位_寬度',
	height: '必填欄位_高度',
	hole_depth: '必填欄位_孔深',
	content: '必填欄位_內容物',
	identify_code: '必填欄位_識別碼',
	feature_number: ['必填欄位_編號', '選填資料_孔蓋編號'],
	on_private_land: '必填欄位_為私有土地',
	across_ditch_cover: '必填欄位_經過蓋板',
	four_pitch_positioning: '必填欄位_需四支距',
	three_pitch_positioning: '必填欄位_需三支距',
	extant_facility: '必填欄位_既有設施物',
	note: '備註',
}
const pointData = {
	SIZE_UNIT: {
		KEYS: ['partyA'],
		VALUES: {
			'臺北自來水': {
				PIPELINE: 0,
				HOLE: 2,
			},
			'中華電信': {
				PIPELINE: 1,
				HOLE: 3,
			},
			'台北水利處': {
				PIPELINE: 3,
				HOLE: 3,
			},
			DEFAULT: {
				PIPELINE: 0,
				HOLE: 0,
			},
		},
	},
	HOLE_WIDTH: {
		KEYS: ['partyA'],
		VALUES: {
			'台北水利處': {
				HOLE: 60,
			},
		},
	},
	HOLE_LENGTH: {
		KEYS: ['partyA'],
		VALUES: {
			'台北水利處': {
				HOLE: 0,
			},
		},
	},
	HOLE_DEPTH: {
		KEYS: ['partyA'],
		VALUES: {
			'中華電信': {
				HOLE: 1.2,
			}
		},
	},
	// 消防栓管徑常數
	HYDRANT_DIAMETER: {
		KEYS: [],
		VALUES: {
			DEFAULT: {
				HYDRANT: {
					KEY: 'featureTypeKey',
					VALUES: {
						ON_GROUND_SINGLE_OUTLET: 150,
						ON_GROUND_DOUBLE_OUTLET: 150,
						UNDER_GROUND_SINGLE_OUTLET: 75,
						UNDER_GROUND_DOUBLE_OUTLET: 75,
						OTHER: 150
					},
				}
			}
		},
	},
	OTHER_FACILITY_LENGTH: {
		KEYS: [],
		VALUES: {
			DEFAULT: {
				OTHER_FACILITY: {
					KEY: 'formTypeKey',
					VALUES: { DITCH_COVER: 0.8, SPRING_FLOW_REGULATOR: 1.2, MONITORING_BOX: 23 },
				},
			}
		},
	},
	OTHER_FACILITY_WIDTH: {
		KEYS: [],
		VALUES: {
			DEFAULT: {
				OTHER_FACILITY: {
					KEY: 'formTypeKey',
					VALUES: { DITCH_COVER: 0.3, SPRING_FLOW_REGULATOR: 0.9, MONITORING_BOX: 42 },
				},
			}
		},
	},
	OTHER_FACILITY_HEIGHT: {
		KEYS: [],
		VALUES: {
			DEFAULT: {
				OTHER_FACILITY: {
					KEY: 'formTypeKey',
					VALUES: { DITCH_COVER: 0.075, SPRING_FLOW_REGULATOR: 1, MONITORING_BOX: 20 },
				},
			}
		},
	},
	POINT_INFO_FORMAT: {
		KEYS: ['partyA'],
		VALUES: {
			DEFAULT: { DEFAULT: 'ROUND_PIPELINE' },
			'台北水利處': { DEFAULT: 'NON_ROUND_PIPELINE' },
			'中華電信': { DEFAULT: 'MULTIPLE_ROUND_PIPELINE' },
		},
	},
}

// 支距點位資料key對應表
const positioningPointKeysTable = {
	id: 'id',
	point_1_type: '支距1_設施物',
	point_1_distance: '支距1_距離',
	point_1_image: '支距1_modified_image',
	point_1_original_image: '支距1',
	point_2_type: '支距2_設施物',
	point_2_distance: '支距2_距離',
	point_2_image: '支距2_modified_image',
	point_2_original_image: '支距2',
	point_3_type: '支距3_設施物',
	point_3_distance: '支距3_距離',
	point_3_image: '支距3_modified_image',
	point_3_original_image: '支距3',
	point_3_x_distance: '支距3_X向(平行路面中心)',
	point_3_x_image: '支距3X_modified_image',
	point_3_x_original_image: '支距3X',
	point_3_y_distance: '支距3_Y向(垂直路面中心)',
	point_3_y_image: '支距3Y_modified_image',
	point_3_y_original_image: '支距3Y',
}

// GML版本
const GmlVersions = {
	// 北水GML
	TAIPEI_WATER: {
		key: 'TAIPEI_WATER', name: "北水GML", tag: '新', template: 'TAIPEI_WATER', configs: { bom: false },
		rules: ['MINIMUM_DIAMETER', 'UNDER_FACILITY'],
	},
	// 北水Web GML
	TAIPEI_WATER_WEB: {
		key: 'TAIPEI_WATER_WEB', name: "北水GML", tag: 'WEB', template: 'TAIPEI_WATER', configs: { bom: true },
		rules: ['MINIMUM_DIAMETER', 'UNDER_FACILITY'],
	},
	// 污水GML
	TAIPEI_SEWERAGE: {
		key: 'TAIPEI_SEWERAGE', name: "污水GML", tag: '臺北', template: 'TAIPEI_CITY', configs: { bom: false },
		rules: ['MINIMUM_DIAMETER', 'UNDER_FACILITY', 'EACH_POINT'],
	},
	// 污水GML QGIS
	TAIPEI_SEWERAGE_QGIS: {
		key: 'TAIPEI_SEWERAGE_QGIS', name: "污水GML", tag: '舊', template: 'TAIPEI_CITY_OLD', configs: { bom: false },
		rules: ['MINIMUM_DIAMETER', 'UNDER_FACILITY', 'EACH_POINT'],
	},
	// 水利GML
	TAIPEI_HYDRAULIC: {
		key: 'TAIPEI_HYDRAULIC', name: "水利GML", tag: '臺北', template: 'TAIPEI_CITY', configs: { bom: false },
		rules: ['MINIMUM_DIAMETER', 'UNDER_FACILITY', 'EACH_WATERSHED'],
	},
	// 水利GML QGIS
	TAIPEI_HYDRAULIC_QGIS: {
		key: 'TAIPEI_HYDRAULIC_QGIS', name: "水利GML", tag: '舊', template: 'TAIPEI_CITY_OLD', configs: { bom: false },
		rules: ['MINIMUM_DIAMETER', 'UNDER_FACILITY', 'EACH_WATERSHED'],
	},
	// 台北GML
	TAIPEI_CITY_QGIS: {
		key: 'TAIPEI_CITY_QGIS', name: "臺北市GML", tag: '舊', template: 'TAIPEI_CITY_OLD', configs: { bom: false },
		rules: ['MINIMUM_DIAMETER', 'UNDER_FACILITY'],
	},
	// 台北GML
	TAIPEI_CITY: {
		key: 'TAIPEI_CITY', name: "臺北市GML", tag: '臺北', template: 'TAIPEI_CITY', configs: { bom: false },
		rules: ['MINIMUM_DIAMETER', 'UNDER_FACILITY'],
	},
	// 新北Old GML
	NEW_TAIPEI_CITY_QGIS: {
		key: 'NEW_TAIPEI_CITY_QGIS', name: "新北市GML", tag: '舊', template: 'NEW_TAIPEI_CITY_OLD', configs: { bom: false },
		rules: ['MINIMUM_DIAMETER', 'UNDER_FACILITY'],
	},
	// 臺南GML
	TAINAN_CITY: {
		key: 'TAINAN_CITY', name: "臺南市GML", tag: '臺南', template: 'STANDARD', configs: { bom: false },
		rules: ['MINIMUM_DIAMETER', 'UNDER_FACILITY', 'PIPELINE_SOCKET', 'DIFFERENT_DEPTH'],
	},
	// 新北GML (同營建署標準格式)
	NEW_TAIPEI_CITY: {
		key: 'NEW_TAIPEI_CITY', name: "營建署標準格式GML", tag: '新北', template: 'STANDARD', configs: { bom: false },
		rules: ['MINIMUM_DIAMETER', 'UNDER_FACILITY'],
	},
	// 桃園市
	TAOYUAN_CITY: {
		key: 'TAOYUAN_CITY', name: "桃園市GML", tag: '桃園', template: 'TAOYUAN_CITY', configs: { bom: false },
		rules: ['MINIMUM_DIAMETER', 'UNDER_FACILITY'],
	},
	// 嘉義縣
	CHIAYI_COUNTY: {
		key: 'CHIAYI_COUNTY', name: "嘉義縣GML", tag: '嘉義縣', template: 'CHIAYI_COUNTY', configs: { bom: false },
		rules: ['MINIMUM_DIAMETER', 'UNDER_FACILITY'],
		elementTagKey: 'CHIAYI_COUNTY',
	},
	// 雲林縣
	YUNLIN_COUNTY: {
		key: 'YUNLIN_COUNTY', name: "雲林縣GML", tag: '雲林縣', template: 'YUNLIN_COUNTY', configs: { bom: false },
		rules: ['MINIMUM_DIAMETER', 'UNDER_FACILITY'],
		elementTagKey: 'YUNLIN_COUNTY',
	},
	// 營建署標準格式
	STANDARD: {
		key: 'STANDARD', name: "營建署標準格式GML", tag: '', template: 'STANDARD', configs: { bom: false },
		rules: ['MINIMUM_DIAMETER', 'UNDER_FACILITY'],
	},
	// 營建署標準格式 埋深斷管
	STANDARD_STRICT: {
		key: 'STANDARD_STRICT', name: "營建署標準格式GML", tag: '', template: 'STANDARD', configs: { bom: false },
		rules: ['MINIMUM_DIAMETER', 'UNDER_FACILITY', 'DIFFERENT_DEPTH'],
	},
}
// GML屬性種類
const GmlAttributeTypes = {
	VALUE: {
		OPTIONS: "options",
	},
	SRC: {
		HEADER: 'header',
		FEATURE: 'feature',
	}
}
const GmlAttributeOptions = {
	CONSTRUCTION_TYPE: {
		CREATE: { key: 'CREATE', value: 0, text: '新建' },
		UPDATE: { key: 'UPDATE', value: 1, text: '更動' },
		DELETE: { key: 'DELETE', value: 2, text: '刪除' },
	},
	STATUS: {
		ENABLE: { key: 'ENABLE', value: 0, text: '使用' },
		DISABLE: { key: 'DISABLE', value: 1, text: '停用' },
	},
	DATA_STATUS: {
		measured: { key: 'measured', value: 0, text: '實測' },
		transfer: { key: 'transfer', value: 1, text: '原圖轉繪' },
	},
	PRESSURE_TYPE: {
		HYPER_PRESSURE: { key: 'HYPER_PRESSURE', value: 0, text: '特高壓' },
		HIGH_PRESSURE: { key: 'HIGH_PRESSURE', value: 1, text: '高壓' },
		MIDDLE_PRESSURE: { key: 'MIDDLE_PRESSURE', value: 2, text: '中壓' },
		UNDER_PRESSURE: { key: 'UNDER_PRESSURE', value: 3, text: '低壓' } ,
		NON_PRESSURE: { key: 'NON_PRESSURE', value: 4, text: '無加壓' },
	},
	SIZE_UNIT: {
		MILLIMETER: { key: 'MILLIMETER', value: 0, text: 'mm' },
		INCH: { key: 'INCH', value: 1, text: 'inch' },
		CENTIMETER: { key: 'CENTIMETER', value: 2, text: 'cm' },
		METER: { key: 'METER', value: 3, text: 'm'}
	},
	SIGNAL_ERECTION_TYPE: {
		SPECIAL: { key: 'SPECIAL', value: 0, text: '專用桿' },
		ATTACHED: { key: 'ATTACHED', value: 1, text: '附掛' },
		OTHER: { key: 'OTHER', value: 2, text: '其他' },
	},
	PIPELINE_TYPE: {
		UNDER_GROUND: { key: 'UNDER_GROUND', value: 0, text: '地下' },
		OVERHEAD: { key: 'OVERHEAD', value: 1, text: '架空' },
		ATTACHED: { key: 'ATTACHED', value: 2, text: '附掛' },
		CHANNEL: { key: 'CHANNEL', value: 3, text: '渠道' },
	},
	HOLE_CATEGORY: {
		MAN_HOLE: { key: 'MAN_HOLE', value: 0, text: '人孔' },
		HAND_HOLE: { key: 'HAND_HOLE', value: 1, text: '手孔' },
		CLEAN_HOLE: { key: 'CLEAN_HOLE', value: 2, text: '清除孔' },
		GATE_HOLE: { key: 'GATE_HOLE', value: 3, text: '閘門' },
		VIRTUAL_HOLE: { key: 'VIRTUAL_HOLE', value: 4, text: '虛擬節點' },
	},
	HOLE_TYPE: {
		ON_SURFACE: { key: 'ON_SURFACE', value: 0, text: '地面' },
		UNDER_SURFACE: { key: 'UNDER_SURFACE', value: 1, text: '下地' },
		ASPHALT_COVERED: { key: 'ASPHALT_COVERED', value: 2, text: '柏油覆蓋' },
	},
	SWITCH_VALVE_TYPE: {
		ON_SURFACE: { key: 'ON_SURFACE', value: 0, text: '地面' },
		UNDER_SURFACE: { key: 'UNDER_SURFACE', value: 1, text: '下地' },
		ASPHALT_COVERED: { key: 'ASPHALT_COVERED', value: 2, text: '柏油覆蓋' },
	},
	HYDRANT_TYPE: {
		ON_GROUND_SINGLE_OUTLET: { key: 'ON_GROUND_SINGLE_OUTLET', value: 0, text: '地上單孔' },
		ON_GROUND_DOUBLE_OUTLET: { key: 'ON_GROUND_DOUBLE_OUTLET', value: 1, text: '地上雙孔' },
		UNDER_GROUND_SINGLE_OUTLET: { key: 'UNDER_GROUND_SINGLE_OUTLET', value: 2, text: '地下單孔' },
		UNDER_GROUND_DOUBLE_OUTLET: { key: 'UNDER_GROUND_DOUBLE_OUTLET', value: 3, text: '地下雙孔' },
		OTHER: { key: 'OTHER', value: 4, text: '其他' },
	},
	OTHER_FACILITY_TYPE: {
		ON_SURFACE: { key: 'ON_SURFACE', value: 0, text: '地面' },
		UNDER_GROUND: { key: 'UNDER_GROUND', value: 1, text: '地下' },
	},
}
// GML屬性
const GmlAttributes = {
	/** HEADER */
	// 文件製作日期
	fileMadeDate: {
		key: 'fileMadeDate', tag: 'ngis_primitive:NGIS_Primitive.ngis_primitive:資料內容對應時間.gml:TimeInstant.gml:timePosition',
		templates: [ 'TAIPEI_WATER', 'TAIPEI_CITY_OLD', 'NEW_TAIPEI_CITY_OLD', 'CHIAYI_COUNTY' ],
		src: GmlAttributeTypes.SRC.HEADER,
		value: (meta) => meta.fileMadeDate,
	},

	/** featureMember */
	// geometry
	lineCoordinates: {
		key: 'lineCoordinates', tag: 'geometry.gml:LineString.gml:posList',
		attributes: { srsName: "EPSG:3826", srsDimension: "3" },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility, templateKey) => {
			const VALUES = {
				TAIPEI_WATER: ["X", "Y", "facilityHeight", "depth"],
				TAIPEI_CITY_OLD: ["X", "Y", "facilityHeight"],
				TAIPEI_CITY: ["X", "Y", "facilityHeight"],
				NEW_TAIPEI_CITY_OLD: ["X", "Y", "orthometricHeight"],
				TAOYUAN_CITY: ["X", "Y", "orthometricHeight", "depth"],
				CHIAYI_COUNTY: ["X", "Y", "orthometricHeight"],
				YUNLIN_COUNTY: ["X", "Y", "orthometricHeight"],
				STANDARD: ["X", "Y", "orthometricHeight"],
			}
			return utility.getCoordinateString(VALUES[templateKey ?? 'STANDARD'])
		},
	},
	// geometry
	pointCoordinate: {
		key: 'pointCoordinate', tag: 'geometry.gml:Point.gml:coordinates',
		templates_except: [ 'CHIAYI_COUNTY' ],
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => {
			if(utility.feature.type.getKeys().featureTypeKey === "UNDER_SURFACE") {
				// 電信、污水
				return utility.getCoordinateString(["X", "Y", "facilityHeight"])
			}
			return utility.getCoordinateString(["X", "Y", "orthometricHeight"])
		},
	},
	// geometry
	pointPosition: {
		key: 'pointPosition', tag: 'geometry.gml:Point.gml:pos',
		attributes: { srsDimension: "3" },
		templates: [ 'CHIAYI_COUNTY' ],
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => {
			return utility.getCoordinateString(["X", "Y", "orthometricHeight"])
		},
	},
	
	// 類別碼
	categoryCode: {
		key: 'categoryCode', tag: { DEFAULT: '類別碼', CHIAYI_COUNTY: 'KINDNO' },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getMetaData('categoryCode'),
	},
	// 識別碼
	identifyCode: {
		key: 'identifyCode', tag: { DEFAULT: '識別碼', CHIAYI_COUNTY: { WATER_PIPELINE: 'PID', PIPELINE: 'PID', HYDRANT: 'HYID', SWITCH_VALVE: 'VID', HOLE: 'HID' } },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getMetaData('identifyCode'),
	},
	// 管理單位
	management: {
		key: 'management', tag: { DEFAULT: '管理單位', CHIAYI_COUNTY: { WATER_PIPELINE: 'PMANAGER', PIPELINE: 'PMANAGER', HYDRANT: 'HYMANAGER', SWITCH_VALVE: 'VMANAGER', HOLE: 'HMANAGER' } },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getMetaData('management'),
	},
	// 作業區分
	constructionType: {
		key: 'constructionType', tag: { DEFAULT: '作業區分', CHIAYI_COUNTY: { WATER_PIPELINE: 'PWORKTYPE', PIPELINE: 'PWORKTYPE', HYDRANT: 'HYWORKTYPE', SWITCH_VALVE: 'VWORKTYPE', HOLE: 'HWORKTYPE' } },
		type: GmlAttributeTypes.VALUE.OPTIONS, options: GmlAttributeOptions.CONSTRUCTION_TYPE,
		src: GmlAttributeTypes.SRC.FEATURE,
		default: 0,
	},
	// 設置日期
	constructedDate: {
		key: 'constructedDate', tag: { DEFAULT: '設置日期.gml:TimeInstant.gml:timePosition', CHIAYI_COUNTY: { WATER_PIPELINE: 'PFINDATE.gml:TimeInstant.gml:timePosition', PIPELINE: 'PFINDATE.gml:TimeInstant.gml:timePosition', HYDRANT: 'HYDATE.gml:TimeInstant.gml:timePosition', SWITCH_VALVE: 'VDATE.gml:TimeInstant.gml:timePosition', HOLE: 'HFINDATE.gml:TimeInstant.gml:timePosition' } },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getMetaData('constructedDate'),
	},
	// 使用狀態
	status: {
		key: 'status', tag: { DEFAULT: '使用狀態', CHIAYI_COUNTY: 'USETYPE' },
		type: GmlAttributeTypes.VALUE.OPTIONS, options: GmlAttributeOptions.STATUS,
		src: GmlAttributeTypes.SRC.FEATURE,
		default: 0,
	},
	// 資料狀態
	dataStatus: {
		key: 'dataStatus', tag: { DEFAULT: '資料狀態', CHIAYI_COUNTY: { WATER_PIPELINE: 'PSTATUS', PIPELINE: 'PSTATUS', HYDRANT: 'HYSTATUS', SWITCH_VALVE: 'VSTATUS', HOLE: 'HSTATUS' } },
		type: GmlAttributeTypes.VALUE.OPTIONS, options: GmlAttributeOptions.DATA_STATUS,
		src: GmlAttributeTypes.SRC.FEATURE,
		default: 0,
	},
	// 資料狀態
	qualityLevel: {
		key: 'qualityLevel', tag: { DEFAULT: '品質分級' },
		templates: [ 'YUNLIN_COUNTY' ],
		src: GmlAttributeTypes.SRC.FEATURE,
		default: 2,
	},
	// 備註
	note: {
		key: 'note', tag: { DEFAULT: '備註', CHIAYI_COUNTY: 'REMARK' },
		src: GmlAttributeTypes.SRC.FEATURE,
		default: '',
		value: (utility) => `${utility.getType().formType === '暗溝' ? '暗溝' : ''} ${utility.getData('note') ?? ''}`.trim(),
	},
	/** 管線 */
	// 起點編號
	startPointNumber: {
		src: GmlAttributeTypes.SRC.FEATURE,
		key: 'startPointNumber', tag: { DEFAULT: '起點編號', CHIAYI_COUNTY: 'STARTID' },
		value: (utility) => utility.feature.getNodeData(0, 'identifyCode') ?? utility.getData('startPointNumber'),
	},
	// 終點編號
	endPointNumber: {
		src: GmlAttributeTypes.SRC.FEATURE,
		key: 'endPointNumber', tag: { DEFAULT: '終點編號', CHIAYI_COUNTY: 'ENDID' },
		value: (utility) => utility.feature.getNodeData(-1, 'identifyCode') ?? utility.getData('endPointNumber'),
	},
	// 管線編號
	pipelineNumber: {
		key: 'pipelineNumber', tag: { DEFAULT: '管線編號', CHIAYI_COUNTY: 'PNO' },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getMetaData('featureNumber'),
	},
	// 管徑寬度
	pipelineWidth: {
		key: 'pipelineWidth', tag: { DEFAULT: '管徑寬度', CHIAYI_COUNTY: 'PWIDTH' },
		templates: {
			TAIPEI_WATER: [],
			TAIPEI_CITY_OLD: [],
			TAIPEI_CITY: [],
			NEW_TAIPEI_CITY_OLD: [ 'WATER_PIPELINE', 'GAS_PIPELINE', 'OIL_PIPELINE', 'INDUSTRIAL_PIPELINE', 'DUCT' ],
			TAOYUAN_CITY: [ 'WATER_PIPELINE', 'GAS_PIPELINE', 'OIL_PIPELINE', 'INDUSTRIAL_PIPELINE', 'DUCT' ],
			CHIAYI_COUNTY: [ 'WATER_PIPELINE', 'GAS_PIPELINE', 'OIL_PIPELINE', 'INDUSTRIAL_PIPELINE', 'DUCT' ],
			YUNLIN_COUNTY: [ 'WATER_PIPELINE', 'GAS_PIPELINE', 'OIL_PIPELINE', 'INDUSTRIAL_PIPELINE', 'DUCT' ],
			STANDARD: [ 'WATER_PIPELINE', 'GAS_PIPELINE', 'OIL_PIPELINE', 'INDUSTRIAL_PIPELINE', 'DUCT' ],
		},
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('pipelineWidth'),
	},
	// 管徑高度
	pipelineHeight: {
		key: 'pipelineHeight', tag: { DEFAULT: '管徑高度', CHIAYI_COUNTY: 'PHEIGHT' },
		templates: {
			TAIPEI_WATER: [],
			TAIPEI_CITY_OLD: [],
			TAIPEI_CITY: [],
			NEW_TAIPEI_CITY_OLD: [ 'WATER_PIPELINE', 'GAS_PIPELINE', 'OIL_PIPELINE', 'INDUSTRIAL_PIPELINE', 'DUCT' ],
			TAOYUAN_CITY: [ 'WATER_PIPELINE', 'GAS_PIPELINE', 'OIL_PIPELINE', 'INDUSTRIAL_PIPELINE', 'DUCT' ],
			CHIAYI_COUNTY: [ 'WATER_PIPELINE', 'GAS_PIPELINE', 'OIL_PIPELINE', 'INDUSTRIAL_PIPELINE', 'DUCT' ],
			YUNLIN_COUNTY: [ 'WATER_PIPELINE', 'GAS_PIPELINE', 'OIL_PIPELINE', 'INDUSTRIAL_PIPELINE', 'DUCT' ],
			STANDARD: [ 'WATER_PIPELINE', 'GAS_PIPELINE', 'OIL_PIPELINE', 'INDUSTRIAL_PIPELINE', 'DUCT' ],
		},
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('pipelineHeight'),
	},
	// 管徑寬度
	pipelineTotalWidth: {
		key: 'pipelineTotalWidth', tag: '管徑寬度',
		templates: { NEW_TAIPEI_CITY_OLD: ['PIPELINE'], TAOYUAN_CITY: ['PIPELINE'], YUNLIN_COUNTY: ['PIPELINE'], STANDARD: ['PIPELINE'] },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('pipelineWidth') * utility.getData('pipelineRowAmount'),
	},
	// 管徑高度
	pipelineTotalHeight: {
		key: 'pipelineTotalHeight', tag: '管徑高度',
		templates: { NEW_TAIPEI_CITY_OLD: ['PIPELINE'], TAOYUAN_CITY: ['PIPELINE'], YUNLIN_COUNTY: ['PIPELINE'], STANDARD: ['PIPELINE'] },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('pipelineWidth') * utility.getData('pipelineColumnAmount'),
	},
	// 涵管條數
	pipelineAmount: {
		key: 'pipelineAmount', tag: { DEFAULT: '涵管條數', CHIAYI_COUNTY: 'PTOCOUNT' },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('pipelineRowAmount') * utility.getData('pipelineColumnAmount'),
	},
	// 管線材料
	pipelineMaterial: {
		key: 'pipelineMaterial', tag: { DEFAULT: '管線材料', CHIAYI_COUNTY: 'PMATERIAL' },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('material'),
	},
	// 起點埋設深度
	startPointDepth: {
		key: 'startPointDepth', tag: { DEFAULT: '起點埋設深度', CHIAYI_COUNTY: 'PSTARTDP' },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getCoordinate().first ? utility.getCoordinate().first.depth : undefined,
	},
	// 終點埋設深度
	endPointDepth: {
		key: 'endPointDepth', tag: { DEFAULT: '終點埋設深度', CHIAYI_COUNTY: 'PENDDP' },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getCoordinate().latest ? utility.getCoordinate().latest.depth : undefined,
	},
	// 管線長度
	pipelineLength: {
		key: 'pipelineLength', tag: { DEFAULT: '管線長度', CHIAYI_COUNTY: 'PLENGTH' },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => {
			const KEYS = {
				'THREE-DIMENSIONAL': 'length',
				'TWO-DIMENSIONAL': 'surfaceLength',
			}
			let key = KEYS[utility.getMetaData('pipelineLengthCalculation')]
			return Number(utility.getData(key).toFixed(3))
		},
	},
	// 管線型態
	pipelineType: {
		key: 'pipelineType', tag: { DEFAULT: '管線型態', CHIAYI_COUNTY: 'PTYPE' },
		type: GmlAttributeTypes.VALUE.OPTIONS, options: GmlAttributeOptions.PIPELINE_TYPE,
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('featureType'),
	},
	/** 管線_供氣, 管線_輸油, 管線_工業, 管道 */
	// 壓力區分
	pressureType: {
		key: 'pressureType', tag: { DEFAULT: '壓力區分', CHIAYI_COUNTY: 'PPRESSURE' },
		type: GmlAttributeTypes.VALUE.OPTIONS, options: GmlAttributeOptions.PRESSURE_TYPE,
	},
	/** 管線_自來水, 管線_供氣, 管線_輸油, 管線_工業, 管道 */
	// 輸送物質
	transmission: {
		key: 'transmission', tag: { DEFAULT: '輸送物質', CHIAYI_COUNTY: 'TRANSMATTER' },
		src: GmlAttributeTypes.SRC.FEATURE,
		// TODO
		// value: () => '',
		default: '自來水',
	},
	/** 管線, 人手孔 */
	// 尺寸單位
	sizeUnit: {
		key: 'sizeUnit', tag: { DEFAULT: '尺寸單位', CHIAYI_COUNTY: { WATER_PIPELINE: 'PUNIT', PIPELINE: 'PUNIT', HOLE: 'HUNIT' } },
		type: GmlAttributeTypes.VALUE.OPTIONS, options: GmlAttributeOptions.SIZE_UNIT,
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('sizeUnit'),
		default: 0,
	},
	/** TAIPEI 管線 */
	// 過溝深度
	ditchDepth: {
		key: 'ditchDepth', tag: '過溝深度',
		templates: [ 'TAIPEI_WATER', 'TAIPEI_CITY_OLD', 'TAIPEI_CITY' ],
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getAdditionalInfo().isAcrossDitchCover ? 0.2 : 0,
		default: 0,
	},
	// 涵管行數
	pipelineColumnAmount: {
		key: 'pipelineColumnAmount', tag: '涵管行數',
		templates: [ 'TAIPEI_WATER', 'TAIPEI_CITY_OLD', 'TAIPEI_CITY' ],
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('pipelineColumnAmount'),
		default: 1,
	},
	// 涵管列數
	pipelineRowAmount: {
		key: 'pipelineRowAmount', tag: '涵管列數',
		templates: [ 'TAIPEI_WATER', 'TAIPEI_CITY_OLD', 'TAIPEI_CITY' ],
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('pipelineRowAmount'),
		default: 1,
	},
	/** 人手孔 */
	// 人手孔編號
	holeNumber: {
		key: 'holeNumber', tag: { DEFAULT: '人手孔編號', CHIAYI_COUNTY: 'HNO' },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getMetaData('featureNumber'),
	},
	// 孔蓋種類
	holeCategory: {
		key: 'holeCategory', tag: { DEFAULT: '孔蓋種類', CHIAYI_COUNTY: 'HTOPTYPE' },
		type: GmlAttributeTypes.VALUE.OPTIONS, options: GmlAttributeOptions.HOLE_CATEGORY,
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('featureCategory'),
	},
	// 蓋部寬度
	holeWidth: {
		key: 'holeWidth', tag: { DEFAULT: '蓋部寬度', CHIAYI_COUNTY: 'HTOPWIDTH' },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('width'),
	},
	// 蓋部長度
	holeLength: {
		key: 'holeLength', tag: { DEFAULT: '蓋部長度', CHIAYI_COUNTY: 'HTOPLENGTH' },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('length'),
	},
	// 閘門名稱
	gateName: {
		key: 'gateName', tag: { DEFAULT: '閘門名稱', CHIAYI_COUNTY: 'GATENAME' },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => '',
	},
	// 孔深
	holeDepth: {
		key: 'holeDepth', tag: { DEFAULT: '孔深', CHIAYI_COUNTY: 'HDEPTH' },
		src: GmlAttributeTypes.SRC.FEATURE,
		// TODO
		value: (utility) => utility.getData('holeDepth'),
	},
	// 孔蓋型態
	holeType: {
		key: 'holeType', tag: { DEFAULT: '孔蓋型態', CHIAYI_COUNTY: 'GD' },
		type: GmlAttributeTypes.VALUE.OPTIONS, options: GmlAttributeOptions.HOLE_TYPE,
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('featureType'),
	},
	// 內容物
	content: {
		key: 'content', tag: { DEFAULT: '內容物', CHIAYI_COUNTY: 'INSIDE' },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('content'),
	},
	/** 人手孔, 開關閥 */
	// 地盤高
	landHeight: {
		key: 'landHeight', tag: { DEFAULT: '地盤高', CHIAYI_COUNTY: { SWITCH_VALVE: 'VGROUPHEIGHT', HOLE: 'HGROUPHEIGHT' } },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('orthometricHeight'),
	},
	/** 開關閥 */
	// 開關閥編號
	switchValveNumber: {
		key: 'switchValveNumber', tag: { DEFAULT: '開關閥編號', CHIAYI_COUNTY: 'VNO' },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getMetaData('featureNumber'),
	},
	// 閥類編號
	valveNumber: {
		key: 'valveNumber', tag: { DEFAULT: '閥類編號', CHIAYI_COUNTY: 'VKNO' },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getMetaData('featureNumber'),
	},
	// 口徑
	valveDiameter: {
		key: 'valveDiameter', tag: { DEFAULT: '口徑', CHIAYI_COUNTY: 'VDIAMETER' },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('pipelineWidth'),
	},
	// 開關閥型態
	switchValveType: {
		key: 'switchValveType', tag: { DEFAULT: '開關閥型態', CHIAYI_COUNTY: 'GD' },
		type: GmlAttributeTypes.VALUE.OPTIONS, options: GmlAttributeOptions.SWITCH_VALVE_TYPE,
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('featureType'),
	},
	/** 開關閥, 維護口 */
	// 名稱
	name: {
		key: 'name', tag: { DEFAULT: '名稱', CHIAYI_COUNTY: { SWITCH_VALVE: 'VNAME' } },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('featureCategory'),
	},
	/** 開關閥, 消防栓 */
	// 埋設深度
	depth: {
		key: 'depth', tag: { DEFAULT: '埋設深度', CHIAYI_COUNTY: { HYDRANT: 'HYDEPTH', SWITCH_VALVE: 'VDEPTH' } },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('depth'),
	},
	/** 消防栓 */
	// 消防栓編號
	hydrantNumber: {
		key: 'hydrantNumber', tag: { DEFAULT: '消防栓編號', CHIAYI_COUNTY: 'HYNUM' },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getMetaData('featureNumber'),
	},
	// 管身口徑
	hydrantDiameter: {
		key: 'hydrantDiameter', tag: { DEFAULT: '管身口徑', CHIAYI_COUNTY: 'HYDIAMETER' },
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('pipelineWidth'),
	},
	// 出水口口徑
	outletDiameter: {
		key: 'outletDiameter', tag: { DEFAULT: '出水口口徑', CHIAYI_COUNTY: 'HYDIAMETER1' },
		src: GmlAttributeTypes.SRC.FEATURE,
		default: 65
	},
	// 消防栓型態
	hydrantType: {
		key: 'hydrantType', tag: { DEFAULT: '消防栓型態', CHIAYI_COUNTY: 'HYSTYLE' },
		type: GmlAttributeTypes.VALUE.OPTIONS, options: GmlAttributeOptions.HYDRANT_TYPE,
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('featureType'),
	},
	/** 電桿 */
	// 電桿編號
	poleNumber: {
		key: 'poleNumber', tag: '電桿編號',
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getMetaData('featureNumber'),
	},
	// 材質
	poleMaterial: {
		key: 'poleMaterial', tag: '材質',
		src: GmlAttributeTypes.SRC.FEATURE,
		value: () => '',
	},
	/** 電桿, 號誌 */
	// 長度
	length: {
		key: 'length', tag: '長度',
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('length'),
	},
	/** 號誌 */
	// 號誌編號
	signalNumber: {
		key: 'signalNumber', tag: '號誌編號',
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getMetaData('featureNumber'),
	},
	// 號誌種類
	signalCategory: {
		key: 'signalCategory', tag: '號誌種類',
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('featureCategory'),
	},
	// 號誌架設方式
	signalErectionType: {
		key: 'signalErectionType', tag: '號誌架設方式', type: GmlAttributeTypes.VALUE.OPTIONS, options: GmlAttributeOptions.SIGNAL_ERECTION_TYPE,
		src: GmlAttributeTypes.SRC.FEATURE,
		value: () => '',
	},
	/** 其他設施 */
	// 設施編號
	otherFacilityNumber: {
		key: 'otherFacilityNumber', tag: '設施編號',
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getMetaData('featureNumber'),
	},
	// 設施名稱
	otherFacilityName: {
		key: 'otherFacilityName', tag: '設施名稱',
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('featureCategory'),
	},
	// 設施長度
	otherFacilityLength: {
		key: 'otherFacilityLength', tag: '設施長度',
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('length') ? utility.getData('length') : utility.getData('otherFacilityLength'),
	},
	// 設施寬度
	otherFacilityWidth: {
		key: 'otherFacilityWidth', tag: '設施寬度',
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('width') ? utility.getData('width') : utility.getData('otherFacilityWidth'),
	},
	// 設施高度
	otherFacilityHeight: {
		key: 'otherFacilityHeight', tag: '設施高度',
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('height') ? utility.getData('height') : utility.getData('otherFacilityHeight'),
	},
	// 設施型態
	otherFacilityType: {
		key: 'otherFacilityType', tag: '設施型態', type: GmlAttributeTypes.VALUE.OPTIONS, options: GmlAttributeOptions.OTHER_FACILITY_TYPE,
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('featureType'),
	},
	/** 維護口 */
	// 維護口編號
	maintenancePortNumber: {
		key: 'maintenancePortNumber', tag: '維護口編號',
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getMetaData('featureNumber'),
	},
	/** 場站 */
	// 場站名稱
	stationName: {
		key: 'stationName', tag: '場站名稱',
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getData('featureCategory'),
	},
	/** ALL */
	// 測量日期
	measuredDate: {
		key: 'measuredDate', tag: '測量日期.gml:TimeInstant.gml:timePosition',
		templates: {
			TAIPEI_WATER: [],
			TAIPEI_CITY_OLD: ['PIPELINE', 'WATER_PIPELINE'],
			TAIPEI_CITY: ['PIPELINE', 'WATER_PIPELINE'],
		},
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getMetaData('measuredDate'),
	},
	// 更新日期
	updatedDate: {
		key: 'updatedDate', tag: { DEFAULT: '更新日期.gml:TimeInstant.gml:timePosition', CHIAYI_COUNTY: { WATER_PIPELINE: 'PFIXDATE.gml:TimeInstant.gml:timePosition', PIPELINE: 'PFIXDATE.gml:TimeInstant.gml:timePosition', HYDRANT: 'HYFIXDATE.gml:TimeInstant.gml:timePosition', SWITCH_VALVE: 'VFIXDATE.gml:TimeInstant.gml:timePosition', HOLE: 'HFIXDATE.gml:TimeInstant.gml:timePosition' } },
		templates: [ 'CHIAYI_COUNTY' ],
		src: GmlAttributeTypes.SRC.FEATURE,
		value: (utility) => utility.getMetaData('fileMadeDate'),
	},
}
// GML設施
const GmlUtilities = {
	// 管線
	PIPELINE: {
		key: 'PIPELINE', tag: 'gml:featureMember.UTL_管線',
		attributes: [
			'lineCoordinates', 'categoryCode', 'identifyCode', 'startPointNumber', 'endPointNumber', 'management', 'constructionType', 'constructedDate',
			'pipelineNumber', 'sizeUnit', 'pipelineWidth', 'pipelineHeight', 'pipelineTotalWidth', 'pipelineTotalHeight', 'pipelineAmount', 'pipelineMaterial', 'startPointDepth', 'endPointDepth', 'pipelineLength', 'pipelineType',
			'status', 'dataStatus', 'qualityLevel', 'note', 'measuredDate', 'updatedDate',
			'ditchDepth', 'pipelineColumnAmount', 'pipelineRowAmount',
		],
	},
	// 管線_自來水
	WATER_PIPELINE: {
		key: 'WATER_PIPELINE', tag: 'gml:featureMember.UTL_管線_自來水',
		attributes: [
			'lineCoordinates', 'categoryCode', 'identifyCode', 'startPointNumber', 'endPointNumber', 'management', 'constructionType', 'constructedDate',
			'pipelineNumber', 'sizeUnit', 'pipelineWidth', 'pipelineHeight', 'pipelineAmount', 'pipelineMaterial', 'startPointDepth', 'endPointDepth', 'pipelineLength', 'pipelineType',
			'status', 'dataStatus', 'qualityLevel', 'note', 'measuredDate', 'updatedDate',
			'transmission',
			'ditchDepth', 'pipelineColumnAmount', 'pipelineRowAmount',
		],
	},
	// 管線_供氣
	GAS_PIPELINE: {
		key: 'GAS_PIPELINE', tag: 'gml:featureMember.UTL_管線_供氣',
		attributes: [
			'lineCoordinates', 'categoryCode', 'identifyCode', 'startPointNumber', 'endPointNumber', 'management', 'constructionType', 'constructedDate',
			'pipelineNumber', 'sizeUnit', 'pipelineWidth', 'pipelineHeight', 'pipelineAmount', 'pipelineMaterial', 'startPointDepth', 'endPointDepth', 'pipelineLength', 'pipelineType',
			'status', 'dataStatus', 'note',
			'pressureType', 'transmission',
		],
	},
	// 管線_輸油
	OIL_PIPELINE: {
		key: 'OIL_PIPELINE', tag: 'gml:featureMember.UTL_管線_輸油',
		attributes: [
			'lineCoordinates', 'categoryCode', 'identifyCode', 'startPointNumber', 'endPointNumber', 'management', 'constructionType', 'constructedDate',
			'pipelineNumber', 'sizeUnit', 'pipelineWidth', 'pipelineHeight', 'pipelineAmount', 'pipelineMaterial', 'startPointDepth', 'endPointDepth', 'pipelineLength', 'pipelineType',
			'status', 'dataStatus', 'note',
			'pressureType', 'transmission',
		],
	},
	// 管線_工業
	INDUSTRIAL_PIPELINE: {
		key: 'INDUSTRIAL_PIPELINE', tag: 'gml:featureMember.UTL_管線_工業',
		attributes: [
			'lineCoordinates', 'categoryCode', 'identifyCode', 'startPointNumber', 'endPointNumber', 'management', 'constructionType', 'constructedDate',
			'pipelineNumber', 'sizeUnit', 'pipelineWidth', 'pipelineHeight', 'pipelineAmount', 'pipelineMaterial', 'startPointDepth', 'endPointDepth', 'pipelineLength', 'pipelineType',
			'status', 'dataStatus', 'note',
			'pressureType', 'transmission',
		],
	},
	// 管道
	DUCT: {
		key: 'DUCT', tag: 'gml:featureMember.UTL_管道',
		attributes: [
			'lineCoordinates', 'categoryCode', 'identifyCode', 'startPointNumber', 'endPointNumber', 'management', 'constructionType', 'constructedDate',
			'pipelineNumber', 'sizeUnit', 'pipelineWidth', 'pipelineHeight', 'pipelineAmount', 'pipelineMaterial', 'startPointDepth', 'endPointDepth', 'pipelineLength', 'pipelineType',
			'status', 'dataStatus', 'note',
		],
	},
	// 人手孔
	HOLE: {
		key: 'HOLE', tag: 'gml:featureMember.UTL_人手孔',
		attributes: [
			'pointCoordinate', 'pointPosition',
			'categoryCode', 'identifyCode', 'management', 'constructionType', 'constructedDate',
			'holeNumber', 'holeCategory', 'sizeUnit', 'holeWidth', 'holeLength', 'gateName', 'landHeight', 'holeDepth', 'holeType',
			'status', 'dataStatus', 'qualityLevel', 'content', 'note', 'measuredDate',
		],
	},
	// 開關閥
	SWITCH_VALVE: {
		key: 'SWITCH_VALVE', tag: 'gml:featureMember.UTL_開關閥',
		attributes: [
			'pointCoordinate', 'pointPosition',
			'categoryCode', 'identifyCode', 'management', 'constructionType', 'constructedDate',
			'switchValveNumber', 'valveNumber', 'valveDiameter', 'name', 'landHeight', 'depth', 'switchValveType',
			'status', 'dataStatus', 'qualityLevel', 'note', 'measuredDate', 'updatedDate',
		],
	},
	// 消防栓
	HYDRANT: {
		key: 'HYDRANT', tag: 'gml:featureMember.UTL_消防栓',
		attributes: [
			'pointCoordinate', 'pointPosition',
			'categoryCode', 'identifyCode', 'management', 'constructionType', 'constructedDate',
			'hydrantNumber', 'hydrantDiameter', 'outletDiameter', 'depth', 'hydrantType',
			'status', 'dataStatus', 'qualityLevel', 'note', 'measuredDate', 'updatedDate',
		],
	},
	// 電桿
	POLE: {
		key: 'POLE', tag: 'gml:featureMember.UTL_電桿',
		attributes: [
			'pointCoordinate',
			'categoryCode', 'identifyCode', 'management', 'constructionType', 'constructedDate',
			'poleNumber', 'length', 'poleMaterial',
			'status', 'dataStatus', 'note',
		],
	},
	// 號誌
	SIGNAL: {
		key: 'SIGNAL', tag: 'gml:featureMember.UTL_號誌',
		attributes: [
			'pointCoordinate',
			'categoryCode', 'identifyCode', 'management', 'constructionType', 'constructedDate',
			'signalNumber', 'signalCategory', 'signalErectionType', 'length',
			'status', 'dataStatus', 'note',
		],
	},
	// 其他設施
	OTHER_FACILITY: {
		key: 'OTHER_FACILITY', tag: 'gml:featureMember.UTL_其他設施',
		attributes: [
			'pointCoordinate',
			'categoryCode', 'identifyCode', 'management', 'constructionType', 'constructedDate',
			'otherFacilityNumber', 'otherFacilityName', 'otherFacilityLength', 'otherFacilityWidth', 'otherFacilityHeight', 'otherFacilityType',
			'status', 'dataStatus', 'qualityLevel', 'note', 'measuredDate', 'updatedDate',
		],
	},
	// 維護口
	MAINTENANCE_PORT: {
		key: 'MAINTENANCE_PORT', tag: 'gml:featureMember.UTL_維護口',
		attributes: [
			'pointCoordinate',
			'categoryCode', 'identifyCode', 'management', 'constructionType', 'constructedDate',
			'maintenancePortNumber', 'name',
			'status', 'dataStatus', 'note',
		],
	},
	// 場站
	STATION: {
		key: 'STATION', tag: 'gml:featureMember.UTL_場站',
		attributes: [
			'pointCoordinate',
			'categoryCode', 'identifyCode', 'management', 'constructionType', 'constructedDate',
			'stationName',
			'status', 'dataStatus', 'note',
		],
	},
}
// GML設施 類別
const GmlUtilityCategories = {
	UTILITY: {
		key: 'UTILITY', code: '8', text: '公共設施管線資料庫',
		categories: {
			TELECOM: {
				key: 'TELECOM', code: '01', text: '電信管線資料',
				categories: {
					TELECOM_SYSTEM: {
						key: 'TELECOM_SYSTEM', code: '01', text: '一般電信系統',
						categories: {
							PIPELINE: { key: 'PIPELINE', code: '01', text: '管線', utility: 'PIPELINE' }, // GmlUtilities.PIPELINE: UTL_管線
							HOLE: { key: 'HOLE', code: '02', text: '人手孔', utility: 'HOLE' }, // GmlUtilities.HOLE: UTL_人手孔
							POLE: { key: 'POLE', code: '03', text: '電桿', utility: 'POLE' }, // GmlUtilities.POLE: UTL_電桿
							OTHER_FACILITY: { key: 'OTHER_FACILITY', code: '96', text: '其他設施', utility: 'OTHER_FACILITY' }, // GmlUtilities.OTHER_FACILITY: UTL_其他設施
							STATION: { key: 'STATION', code: '97', text: '場站', utility: 'STATION' }, // GmlUtilities.STATION: UTL_場站
						}
					},
					MILITARY_SYSTEM: {
						key: 'MILITARY_SYSTEM', code: '02', text: '軍訊系統',
						categories: {
							PIPELINE: { key: 'PIPELINE', code: '01', text: '管線', utility: 'PIPELINE' }, // GmlUtilities.PIPELINE: UTL_管線
							HOLE: { key: 'HOLE', code: '02', text: '人手孔', utility: 'HOLE' }, // GmlUtilities.HOLE: UTL_人手孔
							POLE: { key: 'POLE', code: '03', text: '電桿', utility: 'POLE' }, // GmlUtilities.POLE: UTL_電桿
							OTHER_FACILITY: { key: 'OTHER_FACILITY', code: '96', text: '其他設施', utility: 'OTHER_FACILITY' }, // GmlUtilities.OTHER_FACILITY: UTL_其他設施
							STATION: { key: 'STATION', code: '97', text: '場站', utility: 'STATION' }, // GmlUtilities.STATION: UTL_場站
						}
					},
					POLICE_SYSTEM: {
						key: 'POLICE_SYSTEM', code: '03', text: '警訊系統',
						categories: {
							PIPELINE: { key: 'PIPELINE', code: '01', text: '管線', utility: 'PIPELINE' }, // GmlUtilities.PIPELINE: UTL_管線
							HOLE: { key: 'HOLE', code: '02', text: '人手孔', utility: 'HOLE' }, // GmlUtilities.HOLE: UTL_人手孔
							POLE: { key: 'POLE', code: '03', text: '電桿', utility: 'POLE' }, // GmlUtilities.POLE: UTL_電桿
							OTHER_FACILITY: { key: 'OTHER_FACILITY', code: '96', text: '其他設施', utility: 'OTHER_FACILITY' }, // GmlUtilities.OTHER_FACILITY: UTL_其他設施
							STATION: { key: 'STATION', code: '97', text: '場站', utility: 'STATION' }, // GmlUtilities.STATION: UTL_場站
						}
					},
					CABLE_TV_SYSTEM: {
						key: 'CABLE_TV_SYSTEM', code: '04', text: '有線電視系統',
						categories: {
							PIPELINE: { key: 'PIPELINE', code: '01', text: '管線', utility: 'PIPELINE' }, // GmlUtilities.PIPELINE: UTL_管線
							HOLE: { key: 'HOLE', code: '02', text: '人手孔', utility: 'HOLE' }, // GmlUtilities.HOLE: UTL_人手孔
							POLE: { key: 'POLE', code: '03', text: '電桿', utility: 'POLE' }, // GmlUtilities.POLE: UTL_電桿
							OTHER_FACILITY: { key: 'OTHER_FACILITY', code: '96', text: '其他設施', utility: 'OTHER_FACILITY' }, // GmlUtilities.OTHER_FACILITY: UTL_其他設施
							STATION: { key: 'STATION', code: '97', text: '場站', utility: 'STATION' }, // GmlUtilities.STATION: UTL_場站
						}
					},
					TRAFFIC_SIGN_SYSTEM: {
						key: 'TRAFFIC_SIGN_SYSTEM', code: '05', text: '交通號誌系統',
						categories: {
							PIPELINE: { key: 'PIPELINE', code: '01', text: '管線', utility: 'PIPELINE' }, // GmlUtilities.PIPELINE: UTL_管線
							HOLE: { key: 'HOLE', code: '02', text: '人手孔', utility: 'HOLE' }, // GmlUtilities.HOLE: UTL_人手孔
							SIGNAL: { key: 'SIGNAL', code: '04', text: '號誌', utility: 'SIGNAL' }, // GmlUtilities.SIGNAL: UTL_號誌
							OTHER_FACILITY: { key: 'OTHER_FACILITY', code: '96', text: '其他設施', utility: 'OTHER_FACILITY' }, // GmlUtilities.OTHER_FACILITY: UTL_其他設施
							STATION: { key: 'STATION', code: '97', text: '場站', utility: 'STATION' }, // GmlUtilities.STATION: UTL_場站
						}
					},
				}
			},
			POWER: {
				key: 'POWER', code: '02', text: '電力管線資料',
				categories: {
					DISTRIBUTION_SYSTEM: {
						key: 'DISTRIBUTION_SYSTEM', code: '01', text: '配電系統',
						categories: {
							PIPELINE: { key: 'PIPELINE', code: '01', text: '管線', utility: 'DUCT' }, // GmlUtilities.DUCT: UTL_管道
							HOLE: { key: 'HOLE', code: '02', text: '人手孔', utility: 'HOLE' }, // GmlUtilities.HOLE: UTL_人手孔
							POLE: { key: 'POLE', code: '03', text: '電桿', utility: 'POLE' }, // GmlUtilities.POLE: UTL_電桿
							SWITCH: { key: 'SWITCH', code: '04', text: '開關', utility: 'SWITCH_VALVE' }, // GmlUtilities.SWITCH_VALVE: UTL_開關閥
							OTHER_FACILITY: { key: 'OTHER_FACILITY', code: '96', text: '其他設施', utility: 'OTHER_FACILITY' }, // GmlUtilities.OTHER_FACILITY: UTL_其他設施
							STATION: { key: 'STATION', code: '97', text: '場站', utility: 'STATION' }, // GmlUtilities.STATION: UTL_場站
						}
					},
					STREET_LIGHTING_SYSTEM: {
						key: 'STREET_LIGHTING_SYSTEM', code: '02', text: '路燈電力系統',
						categories: {
							PIPELINE: { key: 'PIPELINE', code: '01', text: '管線', utility: 'DUCT' }, // GmlUtilities.DUCT: UTL_管道
							HOLE: { key: 'HOLE', code: '02', text: '人手孔', utility: 'HOLE' }, // GmlUtilities.HOLE: UTL_人手孔
							POLE: { key: 'POLE', code: '03', text: '電桿', utility: 'POLE' }, // GmlUtilities.POLE: UTL_電桿
							SWITCH: { key: 'SWITCH', code: '04', text: '開關', utility: 'SWITCH_VALVE' }, // GmlUtilities.SWITCH_VALVE: UTL_開關閥
							OTHER_FACILITY: { key: 'OTHER_FACILITY', code: '96', text: '其他設施', utility: 'OTHER_FACILITY' }, // GmlUtilities.OTHER_FACILITY: UTL_其他設施
							STATION: { key: 'STATION', code: '97', text: '場站', utility: 'STATION' }, // GmlUtilities.STATION: UTL_場站
						}
					},
					TRAFFIC_SIGN_SYSTEM: {
						key: 'TRAFFIC_SIGN_SYSTEM', code: '03', text: '交通號誌電力系統',
						categories: {
							PIPELINE: { key: 'PIPELINE', code: '01', text: '管線', utility: 'PIPELINE' }, // GmlUtilities.PIPELINE: UTL_管線
							HOLE: { key: 'HOLE', code: '02', text: '人手孔', utility: 'HOLE' }, // GmlUtilities.HOLE: UTL_人手孔
							SWITCH: { key: 'SWITCH', code: '04', text: '開關', utility: 'SWITCH_VALVE' }, // GmlUtilities.SWITCH_VALVE: UTL_開關閥
							OTHER_FACILITY: { key: 'OTHER_FACILITY', code: '96', text: '其他設施', utility: 'OTHER_FACILITY' }, // GmlUtilities.OTHER_FACILITY: UTL_其他設施
							STATION: { key: 'STATION', code: '97', text: '場站', utility: 'STATION' }, // GmlUtilities.STATION: UTL_場站
						}
					},
					POWER_TRANSMISSION_SYSTEM: {
						key: 'POWER_TRANSMISSION_SYSTEM', code: '04', text: '輸電系統',
						categories: {
							PIPELINE: { key: 'PIPELINE', code: '01', text: '管線', utility: 'DUCT' }, // GmlUtilities.DUCT: UTL_管道
							HOLE: { key: 'HOLE', code: '02', text: '人手孔', utility: 'HOLE' }, // GmlUtilities.HOLE: UTL_人手孔
							POLE: { key: 'POLE', code: '03', text: '電桿', utility: 'POLE' }, // GmlUtilities.POLE: UTL_電桿
							SWITCH: { key: 'SWITCH', code: '04', text: '開關', utility: 'SWITCH_VALVE' }, // GmlUtilities.SWITCH_VALVE: UTL_開關閥
							OTHER_FACILITY: { key: 'OTHER_FACILITY', code: '96', text: '其他設施', utility: 'OTHER_FACILITY' }, // GmlUtilities.OTHER_FACILITY: UTL_其他設施
							STATION: { key: 'STATION', code: '97', text: '場站', utility: 'STATION' }, // GmlUtilities.STATION: UTL_場站
						}
					},
				}
			},
			WATER: {
				key: 'WATER', code: '03', text: '自來水管線資料',
				categories: {
					WATER_SYSTEM: {
						key: 'WATER_SYSTEM', code: '01', text: '自來水系統',
						categories: {
							PIPELINE: { key: 'PIPELINE', code: '01', text: '管線', utility: 'WATER_PIPELINE' }, // GmlUtilities.WATER_PIPELINE: UTL_管線_自來水
							HOLE: { key: 'HOLE', code: '02', text: '人手孔', utility: 'HOLE' }, // GmlUtilities.HOLE: UTL_人手孔
							HYDRANT: { key: 'HYDRANT', code: '03', text: '消防栓', utility: 'HYDRANT' }, // GmlUtilities.HYDRANT: UTL_消防栓
							VALVE: { key: 'VALVE', code: '04', text: '閥類', utility: 'SWITCH_VALVE' }, // GmlUtilities.SWITCH_VALVE: UTL_開關閥
							OTHER_FACILITY: { key: 'OTHER_FACILITY', code: '96', text: '其他設施', utility: 'OTHER_FACILITY' }, // GmlUtilities.OTHER_FACILITY: UTL_其他設施
							STATION: { key: 'STATION', code: '97', text: '場站', utility: 'STATION' }, // GmlUtilities.STATION: UTL_場站
						}
					},
				}
			},
			SEWERAGE: {
				key: 'SEWERAGE', code: '04', text: '下水道管線資料',
				categories: {
					SEWAGE_SYSTEM: {
						key: 'SEWAGE_SYSTEM', code: '01', text: '污水系統',
						categories: {
							PIPELINE: { key: 'PIPELINE', code: '01', text: '管線', utility: 'PIPELINE' }, // GmlUtilities.PIPELINE: UTL_管線
							HOLE: { key: 'HOLE', code: '02', text: '人手孔及清除孔', utility: 'HOLE' }, // GmlUtilities.HOLE: UTL_人手孔
							CATCH_BASIN: { key: 'CATCH_BASIN', code: '03', text: '陰井', utility: 'HOLE' }, // GmlUtilities.HOLE: UTL_人手孔
							OTHER_FACILITY: { key: 'OTHER_FACILITY', code: '96', text: '其他設施', utility: 'OTHER_FACILITY' }, // GmlUtilities.OTHER_FACILITY: UTL_其他設施
							STATION: { key: 'STATION', code: '97', text: '場站', utility: 'STATION' }, // GmlUtilities.STATION: UTL_場站
						}
					},
					RAINWATER_SYSTEM: {
						key: 'RAINWATER_SYSTEM', code: '02', text: '雨水系統',
						categories: {
							PIPELINE: { key: 'PIPELINE', code: '01', text: '管線', utility: 'PIPELINE' }, // GmlUtilities.PIPELINE: UTL_管線
							HOLE: { key: 'HOLE', code: '02', text: '人手孔及清除孔', utility: 'HOLE' }, // GmlUtilities.HOLE: UTL_人手孔
							CATCH_BASIN: { key: 'CATCH_BASIN', code: '03', text: '陰井', utility: 'HOLE' }, // GmlUtilities.HOLE: UTL_人手孔
							OTHER_FACILITY: { key: 'OTHER_FACILITY', code: '96', text: '其他設施', utility: 'OTHER_FACILITY' }, // GmlUtilities.OTHER_FACILITY: UTL_其他設施
							STATION: { key: 'STATION', code: '97', text: '場站', utility: 'STATION' }, // GmlUtilities.STATION: UTL_場站
						}
					},
					CONFLUENCE_SYSTEM: {
						key: 'CONFLUENCE_SYSTEM', code: '03', text: '合流系統',
						categories: {
							PIPELINE: { key: 'PIPELINE', code: '01', text: '管線', utility: 'PIPELINE' }, // GmlUtilities.PIPELINE: UTL_管線
							HOLE: { key: 'HOLE', code: '02', text: '人手孔及清除孔', utility: 'HOLE' }, // GmlUtilities.HOLE: UTL_人手孔
							CATCH_BASIN: { key: 'CATCH_BASIN', code: '03', text: '陰井', utility: 'HOLE' }, // GmlUtilities.HOLE: UTL_人手孔
							OTHER_FACILITY: { key: 'OTHER_FACILITY', code: '96', text: '其他設施', utility: 'OTHER_FACILITY' }, // GmlUtilities.OTHER_FACILITY: UTL_其他設施
							STATION: { key: 'STATION', code: '97', text: '場站', utility: 'STATION' }, // GmlUtilities.STATION: UTL_場站
						}
					},
				}
			},
			GAS: {
				key: 'GAS', code: '05', text: '瓦斯管線資料',
				categories: {
					GAS_SYSTEM: {
						key: 'GAS_SYSTEM', code: '01', text: '供氣系統',
						categories: {
							PIPELINE: { key: 'PIPELINE', code: '01', text: '管線', utility: 'GAS_PIPELINE' }, // GmlUtilities.GAS_PIPELINE: UTL_管線_供氣
							HOLE: { key: 'HOLE', code: '02', text: '人手孔', utility: 'HOLE' }, // GmlUtilities.HOLE: UTL_人手孔
							SWITCH: { key: 'SWITCH', code: '03', text: '開關', utility: 'SWITCH_VALVE' }, // GmlUtilities.SWITCH_VALVE: UTL_開關閥
							OTHER_FACILITY: { key: 'OTHER_FACILITY', code: '96', text: '其他設施', utility: 'OTHER_FACILITY' }, // GmlUtilities.OTHER_FACILITY: UTL_其他設施
							STATION: { key: 'STATION', code: '97', text: '場站', utility: 'STATION' }, // GmlUtilities.STATION: UTL_場站
						}
					},
				}
			},
			HYDRAULIC: {
				key: 'HYDRAULIC', code: '06', text: '水利管線資料',
				categories: {
					HYDRAULIC_SYSTEM: {
						key: 'HYDRAULIC_SYSTEM', code: '01', text: '灌排系統',
						categories: {
							PIPELINE: { key: 'PIPELINE', code: '01', text: '管線', utility: 'PIPELINE' }, // GmlUtilities.PIPELINE: UTL_管線
							GATE: { key: 'GATE', code: '02', text: '閘門', utility: 'HOLE' }, // GmlUtilities.HOLE: UTL_人手孔
							OTHER_FACILITY: { key: 'OTHER_FACILITY', code: '96', text: '其他設施', utility: 'OTHER_FACILITY' }, // GmlUtilities.OTHER_FACILITY: UTL_其他設施
							STATION: { key: 'STATION', code: '97', text: '場站', utility: 'STATION' }, // GmlUtilities.STATION: UTL_場站
						}
					},
				}
			},
			OIL: {
				key: 'OIL', code: '07', text: '輸油管線資料',
				categories: {
					OIL_SYSTEM: {
						key: 'OIL_SYSTEM', code: '01', text: '輸油系統',
						categories: {
							PIPELINE: { key: 'PIPELINE', code: '01', text: '管線', utility: 'OIL_PIPELINE' }, // GmlUtilities.OIL_PIPELINE: UTL_管線_輸油
							HOLE: { key: 'HOLE', code: '02', text: '人手孔', utility: 'HOLE' }, // GmlUtilities.HOLE: UTL_人手孔
							VALVE: { key: 'VALVE', code: '03', text: '閥類', utility: 'SWITCH_VALVE' }, // GmlUtilities.SWITCH_VALVE: UTL_開關閥
							OTHER_FACILITY: { key: 'OTHER_FACILITY', code: '96', text: '其他設施', utility: 'OTHER_FACILITY' }, // GmlUtilities.OTHER_FACILITY: UTL_其他設施
							STATION: { key: 'STATION', code: '97', text: '場站', utility: 'STATION' }, // GmlUtilities.STATION: UTL_場站
						}
					},
				}
			},
			INTEGRATED: {
				key: 'INTEGRATED', code: '08', text: '綜合管線資料',
				categories: {
					COMMON_DUCT_SYSTEM: {
						key: 'COMMON_DUCT_SYSTEM', code: '01', text: '共同管道',
						categories: {
							PIPELINE: { key: 'PIPELINE', code: '01', text: '管線', utility: 'DUCT' }, // GmlUtilities.DUCT: UTL_管道
							HOLE: { key: 'HOLE', code: '02', text: '人手孔', utility: 'HOLE' }, // GmlUtilities.HOLE: UTL_人手孔
							MAINTENANCE_PORT: { key: 'MAINTENANCE_PORT', code: '03', text: '維護口', utility: 'MAINTENANCE_PORT' }, // GmlUtilities.MAINTENANCE_PORT: UTL_維護口
							OTHER_FACILITY: { key: 'OTHER_FACILITY', code: '96', text: '其他設施', utility: 'OTHER_FACILITY' }, // GmlUtilities.OTHER_FACILITY: UTL_其他設施
							STATION: { key: 'STATION', code: '97', text: '場站', utility: 'STATION' }, // GmlUtilities.STATION: UTL_場站
						}
					},
					BROADBAND_DUCT_SYSTEM: {
						key: 'BROADBAND_DUCT_SYSTEM', code: '02', text: '寬頻管道',
						categories: {
							PIPELINE: { key: 'PIPELINE', code: '01', text: '管線', utility: 'DUCT' }, // GmlUtilities.DUCT: UTL_管道
							HOLE: { key: 'HOLE', code: '02', text: '人手孔', utility: 'HOLE' }, // GmlUtilities.HOLE: UTL_人手孔
							MAINTENANCE_PORT: { key: 'MAINTENANCE_PORT', code: '03', text: '維護口', utility: 'MAINTENANCE_PORT' }, // GmlUtilities.MAINTENANCE_PORT: UTL_維護口
							OTHER_FACILITY: { key: 'OTHER_FACILITY', code: '96', text: '其他設施', utility: 'OTHER_FACILITY' }, // GmlUtilities.OTHER_FACILITY: UTL_其他設施
							STATION: { key: 'STATION', code: '97', text: '場站', utility: 'STATION' }, // GmlUtilities.STATION: UTL_場站
						}
					},
				}
			},
			INDUSTRIAL: {
				key: 'INDUSTRIAL', code: '91', text: '工業用管線資料',
				categories: {
					INDUSTRIAL_SYSTEM: {
						key: 'INDUSTRIAL_SYSTEM', code: '01', text: '工業系統',
						categories: {
							PIPELINE: { key: 'PIPELINE', code: '01', text: '管線', utility: 'INDUSTRIAL_PIPELINE' }, // GmlUtilities.INDUSTRIAL_PIPELINE: UTL_管線_工業
							HOLE: { key: 'HOLE', code: '02', text: '人手孔', utility: 'HOLE' }, // GmlUtilities.HOLE: UTL_人手孔
							OTHER_FACILITY: { key: 'OTHER_FACILITY', code: '96', text: '其他設施', utility: 'OTHER_FACILITY' }, // GmlUtilities.OTHER_FACILITY: UTL_其他設施
							STATION: { key: 'STATION', code: '97', text: '場站', utility: 'STATION' }, // GmlUtilities.STATION: UTL_場站
						}
					},
				}
			},
		}
	}
}

// APP & GML 種類對照表
const TypeTable = {
	// 電信管線
	CABLE: { key: 'CABLE', utilityCategory: 'PIPELINE', text: '電信管線', typeText: '管線', categoryText: '管線', countText: '管線', priority: 1 },
	// 分線點
	DIVIDER: { key: 'DIVIDER', utilityCategory: 'PIPELINE', text: '分線點', typeText: '管線', categoryText: '管線', countText: '管線', priority: 0 },
	// 人手孔
	HOLE: { key: 'HOLE', utilityCategory: 'HOLE', text: '人手孔', typeText: '人手孔', categoryText: '人手孔', countText: '人手孔', priority: 1 },
	// 電桿
	POLE: { key: 'POLE', utilityCategory: 'POLE', text: '電桿', typeText: '電桿', categoryText: '電桿', countText: '電桿', priority: 1 },
	// 號誌
	SIGNAL: { key: 'SIGNAL', utilityCategory: 'SIGNAL', text: '號誌', typeText: '號誌', categoryText: '號誌', countText: '號誌', priority: 1 },
	// 電信交接箱
	CROSS_CONNECTING_CABINET: { key: 'CROSS_CONNECTING_CABINET', utilityCategory: 'OTHER_FACILITY', text: '電信交接箱', typeText: '電信交接箱', categoryText: '其他設施', countText: '電信交接箱', priority: 1 },
	// 其他設施
	OTHER_FACILITY: { key: 'OTHER_FACILITY', utilityCategory: 'OTHER_FACILITY', text: '其他設施', typeText: '其他設施', categoryText: '其他設施', countText: '其他設施', priority: 1 },
	// 場站
	STATION: { key: 'STATION', utilityCategory: 'STATION', text: '場站', typeText: '場站', categoryText: '場站', countText: '場站', priority: 1 },
	// 電力管線
	POWER_PIPELINE: { key: 'POWER_PIPELINE', utilityCategory: 'PIPELINE' , text: '電力管線', typeText: '管線', categoryText: '管線', countText: '管線', priority: 1 },
	// 開關
	SWITCH: { key: 'SWITCH', utilityCategory: 'SWITCH', text: '開關', typeText: '開關', categoryText: '開關', countText: '開關', priority: 1 },
	// 管線
	PIPELINE: { key: 'PIPELINE', utilityCategory: 'PIPELINE', text: '管線', typeText: '管線', categoryText: '管線', countText: '管線', priority: 1 },
	// 分水點
	WATERSHED: { key: 'WATERSHED', utilityCategory: 'PIPELINE', text: '分水點', typeText: '管線', categoryText: '管線', countText: '管線', priority: 0 },
	// 套筒
	SOCKET: { key: 'SOCKET', utilityCategory: 'PIPELINE', text: '套筒', typeText: '管線', categoryText: '管線', countText: '套筒', priority: 1 },
	// 異徑接頭
	REDUCING_JOINT: { key: 'REDUCING_JOINT', utilityCategory: 'PIPELINE', text: '異徑接頭', typeText: '管線', categoryText: '管線', countText: '管線', priority: 0 },
	// 連接管
	CONNECTING_PIPE: { key: 'CONNECTING_PIPE', utilityCategory: 'PIPELINE', text: '連接管', typeText: '管線', categoryText: '管線', countText: '連接管', priority: 1 },
	// 管涵
	PIPE_CULVERT: { key: 'PIPE_CULVERT', utilityCategory: 'PIPELINE', text: '管涵', typeText: '管線', categoryText: '管線', countText: '管涵', priority: 1 },
	// 箱涵
	BOX_CULVERT: { key: 'BOX_CULVERT', utilityCategory: 'PIPELINE', text: '箱涵', typeText: '管線', categoryText: '管線', countText: '箱涵', priority: 1 },
	// 箱涵式側溝
	CULVERT_GUTTER: { key: 'CULVERT_GUTTER', utilityCategory: 'PIPELINE', text: '箱涵式側溝', typeText: '管線', categoryText: '管線', countText: '箱涵式側溝', priority: 1 },
	// 側溝
	GUTTER: { key: 'GUTTER', utilityCategory: 'PIPELINE', text: '側溝', typeText: '管線', categoryText: '管線', countText: '側溝', priority: 1 },
	// 暗溝
	BLIND_DRAIN: { key: 'BLIND_DRAIN', utilityCategory: 'PIPELINE', text: '暗溝', typeText: '管線', categoryText: '管線', countText: '暗溝', priority: 1 },
	// @deprecated 分水溝
	WATERSHED_DRAIN: { key: 'WATERSHED_DRAIN', utilityCategory: 'PIPELINE', text: '分水溝', typeText: '管線', categoryText: '管線', countText: '分水溝', priority: 0 },
	// @deprecated 異徑溝
	REDUCING_JOINT_DRAIN: { key: 'REDUCING_JOINT_DRAIN', utilityCategory: 'PIPELINE', text: '異徑溝', typeText: '管線', categoryText: '管線', countText: '異徑溝', priority: 0 },
	// 水錶
	WATER_METER: { key: 'WATER_METER', utilityCategory: 'PIPELINE', text: '水錶', typeText: '水錶', categoryText: '管線', countText: '水錶', priority: 1 },
	// 消防栓
	HYDRANT: { key: 'HYDRANT', utilityCategory: 'HYDRANT', text: '消防栓', typeText: '消防栓', categoryText: '消防栓', countText: '消防栓', priority: 1 },
	// 開關閥
	VALVE: { key: 'VALVE', utilityCategory: 'VALVE', text: '開關閥', typeText: '開關閥', categoryText: '開關閥', countText: '開關閥', priority: 1 },
	// 陰井
	CATCH_BASIN: { key: 'CATCH_BASIN', utilityCategory: 'CATCH_BASIN', text: '陰井', typeText: '陰井', categoryText: '陰井', countText: '陰井', priority: 1 },
	// 瓦斯管線
	GAS_PIPELINE: { key: 'GAS_PIPELINE', utilityCategory: 'PIPELINE', text: '瓦斯管線', typeText: '管線', categoryText: '管線', countText: '管線', priority: 1 },
	// 灌排閘門
	SLUICEWAY: { key: 'SLUICEWAY', utilityCategory: 'GATE', text: '灌排閘門', typeText: '灌排閘門', categoryText: '灌排閘門', countText: '灌排閘門', priority: 1 },
	// 輸油管線
	OIL_PIPELINE: { key: 'OIL_PIPELINE', utilityCategory: 'PIPELINE', text: '輸油管線', typeText: '管線', categoryText: '管線', countText: '管線', priority: 1 },
	// 管道
	DUCT: { key: 'DUCT', utilityCategory: 'PIPELINE', text: '管道', typeText: '管線', categoryText: '管線', countText: '管道', priority: 1 },
	// 維護口
	MAINTENANCE_PORT: { key: 'MAINTENANCE_PORT', utilityCategory: 'MAINTENANCE_PORT', text: '維護口', typeText: '維護口', categoryText: '維護口', countText: '維護口', priority: 1 },
	// 工業管線
	INDUSTRIAL_PIPELINE: { key: 'INDUSTRIAL_PIPELINE', utilityCategory: 'PIPELINE', text: '工業管線', typeText: '管線', categoryText: '管線', countText: '管線', priority: 1 },
	// 過溝蓋板
	DITCH_COVER: { key: 'DITCH_COVER', utilityCategory: 'OTHER_FACILITY', text: '過溝蓋板', typeText: '過溝蓋板', categoryText: '其他設施', countText: '過溝蓋板', priority: 1 },
	// 湯櫃
	SPRING_FLOW_REGULATOR: { key: 'SPRING_FLOW_REGULATOR', utilityCategory: 'OTHER_FACILITY', text: '湯櫃', typeText: '湯櫃', categoryText: '其他設施', countText: '湯櫃', priority: 1 },
	// 監測箱
	MONITORING_BOX: { key: 'MONITORING_BOX', utilityCategory: 'OTHER_FACILITY', text: '監測箱', typeText: '監測箱', categoryText: '其他設施', countText: '監測箱', priority: 1 },
	// 封管點
	SEALING_POINT: { key: 'SEALING_POINT', text: '封管點', typeText: '封管點', priority: -1 },
}
// APP & GML 設施型態對照表
const featureTypeTable = {
	PIPELINE: {
		UNDER_GROUND: { key: 'UNDER_GROUND', text: '地下', utilityFeatureType: GmlAttributeOptions.PIPELINE_TYPE.UNDER_GROUND },
		// ON_GROUND: "地表",
		ABOVE_GROUND: { key: 'ABOVE_GROUND', text: '地上', utilityFeatureType: GmlAttributeOptions.PIPELINE_TYPE.ATTACHED },
	},
	HOLE: {
		ON_SURFACE: { key: 'ON_SURFACE', text: '地面', utilityFeatureType: GmlAttributeOptions.HOLE_TYPE.ON_SURFACE },
		UNDER_SURFACE: { key: 'UNDER_SURFACE', text: '下地', utilityFeatureType: GmlAttributeOptions.HOLE_TYPE.UNDER_SURFACE },
		ASPHALT_COVERED: { key: 'ASPHALT_COVERED', text: '柏油覆蓋', utilityFeatureType: GmlAttributeOptions.HOLE_TYPE.ASPHALT_COVERED },
	},
	HYDRANT: {
		ON_GROUND: { key: 'ON_GROUND', text: '地上', utilityFeatureType: GmlAttributeOptions.HYDRANT_TYPE.ON_GROUND_SINGLE_OUTLET },
		UNDER_GROUND: { key: 'UNDER_GROUND', text: '地下', utilityFeatureType: GmlAttributeOptions.HYDRANT_TYPE.UNDER_GROUND_SINGLE_OUTLET },
	},
	VALVE: {
		ON_SURFACE: { key: 'ON_SURFACE', text: '地面', utilityFeatureType: GmlAttributeOptions.SWITCH_VALVE_TYPE.ON_SURFACE },
		UNDER_SURFACE: { key: 'UNDER_SURFACE', text: '下地', utilityFeatureType: GmlAttributeOptions.SWITCH_VALVE_TYPE.UNDER_SURFACE },
		ASPHALT_COVERED: { key: 'ASPHALT_COVERED', text: '柏油覆蓋', utilityFeatureType: GmlAttributeOptions.SWITCH_VALVE_TYPE.ASPHALT_COVERED },
	},
	SWITCH: {
		ON_SURFACE: { key: 'ON_SURFACE', text: '地面', utilityFeatureType: GmlAttributeOptions.SWITCH_VALVE_TYPE.ON_SURFACE },
		UNDER_SURFACE: { key: 'UNDER_SURFACE', text: '下地', utilityFeatureType: GmlAttributeOptions.SWITCH_VALVE_TYPE.UNDER_SURFACE },
		ASPHALT_COVERED: { key: 'ASPHALT_COVERED', text: '柏油覆蓋', utilityFeatureType: GmlAttributeOptions.SWITCH_VALVE_TYPE.ASPHALT_COVERED },
	},
	OTHER_FACILITY: {
		ON_SURFACE: { key: 'ON_SURFACE', text: '地面', utilityFeatureType: GmlAttributeOptions.OTHER_FACILITY_TYPE.ON_SURFACE },
		UNDER_GROUND: { key: 'UNDER_GROUND', text: '地下', utilityFeatureType: GmlAttributeOptions.OTHER_FACILITY_TYPE.UNDER_GROUND },
	},
}
// APP & GML 設施種類對照表
const featureCategoryTable = {
	HOLE: {
		MAN_HOLE: { key: 'MAN_HOLE', text: '人孔', utilityFeatureType: GmlAttributeOptions.HOLE_CATEGORY.MAN_HOLE },
		CATCH_BASIN: { key: 'CATCH_BASIN', text: '陰井', utilityFeatureType: GmlAttributeOptions.HOLE_CATEGORY.MAN_HOLE },
		PIPING_BOX: { key: 'PIPING_BOX', text: '配管箱', utilityFeatureType: GmlAttributeOptions.HOLE_CATEGORY.MAN_HOLE },
		HAND_HOLE: { key: 'HAND_HOLE', text: '手孔', utilityFeatureType: GmlAttributeOptions.HOLE_CATEGORY.HAND_HOLE },
		CLEAN_HOLE: { key: 'CLEAN_HOLE', text: '清除孔', utilityFeatureType: GmlAttributeOptions.HOLE_CATEGORY.CLEAN_HOLE },
		RETAINING_HOLE: { key: 'RETAINING_HOLE', text: '擋土座', utilityFeatureType: GmlAttributeOptions.HOLE_CATEGORY.CLEAN_HOLE },
		RETAINING_HOLE_53: { key: 'RETAINING_HOLE_53', text: '擋土座53', utilityFeatureType: GmlAttributeOptions.HOLE_CATEGORY.CLEAN_HOLE },
		GATE_HOLE: { key: 'GATE_HOLE', text: '閘門', utilityFeatureType: GmlAttributeOptions.HOLE_CATEGORY.GATE_HOLE },
		VIRTUAL_HOLE: { key: 'VIRTUAL_HOLE', text: '虛擬節點', utilityFeatureType: GmlAttributeOptions.HOLE_CATEGORY.VIRTUAL_HOLE },
	}
}

// GML斷管規則
const GmlPipelineSplitRules = {
	// 斷管管徑
	MINIMUM_DIAMETER: (node) => node.isWatershedWithBothOverDiameter(),
	// 管線套筒
	PIPELINE_SOCKET: (node) => node.isPipelineSocket(),
	// 設施物斷管(過溝蓋板以外)
	UNDER_FACILITY: (node) => node.isPipelineUnderFacility() && !node.isPipelineAcrossDitchCover(),
	// 過溝蓋板
	ACROSS_DITCH_COVER: (node) => node.isPipelineAcrossDitchCover(),
	// 埋深變化
	DIFFERENT_DEPTH: (node, line) => {
		let nodes = line.nodes
		let index = nodes.findIndex(n => n.name === node.name)
		return index > 0 && index < nodes.length - 1 && (nodes[index - 1].position.depth !== node.position.depth || nodes[index + 1].position.depth !== node.position.depth)
	},
	// 全斷
	EACH_POINT: () => true,
	// 分水點斷管
	EACH_WATERSHED: (node) => node.isPipelineWatershed(),
}

// feature編號代碼
const FeatureCode = {
	PIPELINE_UNDER_MINIMUM_SPLIT_DIAMETER: "D",
	PIPELINE_UPPER_MINIMUM_SPLIT_DIAMETER: "U",
	HOLE: "O",
	OTHER_FACILITY: "D",
	HYDRANT: "H",
	VALVE: "V",
}

const Constants = {
	DATA: {
		// 案件資料key對應表
		PROJECT_KEYS_TABLE: projectKeysTable,
		PROJECT_DATA: projectData,
		// 點位資料key對應表
		POINT_KEYS_TABLE: pointKeysTable,
		POINT_DATA: pointData,
		// 支距點位資料key對應表
		POSITIONING_POINT_KEYS_TABLE: positioningPointKeysTable,
	},
	// APP設施物 -> Utility類別（類別碼小類） -[甲方->類別碼大類&中類]-> Utility種類
	TABLE: {
		// APP & GML 種類對照表
		TYPE: TypeTable,
		// APP & GML 設施型態對照表
		FEATURE_TYPE: featureTypeTable,
		// APP & GML 設施種類對照表
		FEATURE_CATEGORY: featureCategoryTable,
	},
	FORM: {
		// 一點位連接點數量最大值
		MAX_TARGET_AMOUNT: 3,
		// 一點位可填材質管徑數量最大值
		MAX_INFO_AMOUNT: 4,
	},
	GML: {
		// GML版本
		VERSIONS: GmlVersions,
		// GML設施(UTL)
		UTILITIES: GmlUtilities,
		// GML設施 類別
		UTILITY_CATEGORIES: GmlUtilityCategories,
		// GML屬性
		ATTRIBUTES: GmlAttributes,
		// GML屬性種類
		ATTRIBUTE_TYPES: GmlAttributeTypes,
		// GML斷管規則
		PIPELINE_SPLIT_RULES: GmlPipelineSplitRules,
		// 預設GML斷管規則
		PIPELINE_SPLIT_DEFAULT_RULES: [ 'MINIMUM_DIAMETER', 'UNDER_FACILITY' ],
		// Gml分水點最小斷管管徑
		PIPELINE_MINIMUM_SPLIT_DIAMETER: 75,
		// feature編號代碼
		FEATURE_CODE: FeatureCode,
	}
}

export default Constants
