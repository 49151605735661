<template>
	<b-form @submit="onSubmit">
		<loading v-if="loading"/>
		<b-row v-else>
			<input-text class="input" label="名稱" pattern="^[a-zA-Z0-9\u4E00-\u9FFF\uff08-\uff09_]{2,}$" invalid-message="請輸入2個字元以上非特殊符號" size="xs" v-model="result.name" required></input-text>
			<input-select class="input" label="用途" size="xs" :options="$store.getters.enum('dynamic_form.type')" :keys="{value: 'index', text: 'text'}" v-model="result.type" required></input-select>
		</b-row>
		<b-row style="justify-content: center">
			<b-button class="form-btn submit-btn size-xs" type="submit" variant="primary">確認</b-button>
		</b-row>
	</b-form>
</template>

<script>
import InputText from '@/components/Input/InputText';
import InputRadio from '@/components/Input/InputRadio';
import InputSelect from '@/components/Input/InputSelect';
import Loading from '@/components/Loading/Loading.vue';
import { isEmpty, checkAllTrue } from '@/utils/assist';

export default {
	name: 'DynamicFormCreator',
	components:{
		InputText,
		InputRadio,
		InputSelect,
		Loading
	},
	props: {
		role_list: {
			type: Array,
			default() {
				return [];
			}
		},
	},
	data() {
		return {
			form: [ 'name', 'type' ],
			result: {},
			check: {},
			loading: true,
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		result: {
			deep: true,
			handler() {
				this.$emit('change', this.result);
				this.checkResult()
			}
		}
	},
	created() {
	},
	mounted() {
	},
	computed: {
	},
	methods:{
		checkResult() {
			let res = true
			for(let i in this.form) {
				if(!this.result.hasOwnProperty(this.form[i]) || (typeof this.result[this.form[i]] === 'object' && isEmpty(this.result[this.form[i]])) || this.result[this.form[i]] == null || typeof this.result[this.form[i]] === "undefined") {
					this.$set(this.check, this.form[i], false)
					res = false
				}
				else if(this.check.hasOwnProperty(this.form[i])) {
					this.$delete(this.check, this.form[i])
				}
			}
			return res
		},
		onSubmit(evt) {
			evt.preventDefault();
			// 預設表單：{}
			this.result.data = null
			this.$axios.createDynamicForm(this.result, (response) => {
				this.$emit('createDynamicForm')
			})
			// alert("success")
			// this.$router.go(-1)
			this.$set(this, 'result', {})
		},
	}
}
</script>

<style scoped>
.input {
	margin: .5rem .25rem
}
</style>