<template>
	<div>
		<loading mode="fixed" image="1" size="lg" v-show="loading"/>
		<input-form :options="formOptions" v-model="formData" @submit="submitForm" @cancel="cancel"></input-form>
	</div>
</template>


<script>
import InputForm from '@/components/Input/InputForm';
import Loading from '@/components/Loading/Loading.vue';
import { isEmpty, checkAllTrue } from '@/utils/assist';

export default {
	name: 'ConstructionDataUploader',
	components: {
		InputForm,
		Loading,
	},
	props: {
		constructionDataType: {
			type: Array,
			default: () => []
		},
		constructionData: {
			type: Array,
			default: () => []
		},
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			call: {},
			loading: true,
			formData: {},
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
		loading() {
			this.$emit('loading', this.loading)
		}
	},
	created() {
	},
	mounted() {
	},
	computed: {
		formOptions() {
			return this.constructionData.map(datum => {
				let dataType = this.constructionDataType.find(type => type.id === datum.project_data_type_id)
				switch(dataType.type_key) {
					case 'text':
						return { key: dataType.id, type: "text", label: dataType.name, required: false, multiline: true, size: 'xs' };
					case 'file':
						return { key: dataType.id, type: "file", label: dataType.name, required: false, multiple: true, accept: "*", size: 'xs' };
					case 'image':
						return { key: dataType.id, type: "file", label: dataType.name, required: false, multiple: true, accept: "image/*", preview: true, size: 'xs' };
				}
			})
		}
	},
	methods:{
		cancel() {
			this.$emit("cancel")
		},
		submitForm(e) {
			e.preventDefault();
			this.$set(this.call, 'uploadMultiProjectData', false)
			let data = {
				user_id: this.$store.getters.currentUser.id,
				project_type: this.projectType,
				project_id: this.projectID,
				data: Object.keys(this.formData).map(key => {
					return this.formData[key].map(datum => {
						return {
							project_data_type_id: key,
							content: datum
						}
					})
				}).flat(),
			}
			this.$axios.uploadMultiProjectData(data, (response) => {
				alert('上傳成功')
				this.$emit("submit")
				this.call.uploadMultiProjectData = true;
			}, (error) => {
				this.call.uploadMultiProjectData = true;
			})
		}
	}
}
</script>

<style scoped>
ul > li {
	list-style: none;
	display: flex;
}
ul > li > *:first-child {
	flex: 0 1 30%;
	align-self: flex-start;
	justify-content: flex-end;
	margin-left: 1rem;
}
.info-outter-wrapper {
	width: 100%;
}
.info-wrapper > div {
	padding: .5rem;
}
.basic-wrapper {
	padding: .5rem;
	margin-bottom: 1rem;
}
.basic-wrapper:last-child {
	margin-bottom: 0;
}
.tabs-wrapper {
	height: 100%;
}
.tabs-wrapper > .tab-content {
	height: 100%;
}
.tab-body {
	padding: .5rem 1rem;
	border: 1px solid #dee2e6;
	border-top: 0;
	height: 100%;
}
.timeline-outside-wrapper {
	height: 4.5rem;
}
.timeline-wrapper {
	position: fixed;
	left: 4rem;
	bottom: 0;
	width: calc(100% - 4rem);
	width: -webkit-calc(100% - 4rem);
	width: -moz-calc(100% - 4rem);
	width: -o-calc(100% - 4rem);
	padding: .5rem;
}
@media (max-width:991px) {
	.timeline-wrapper {
		left: 0;
		width: 100%;
		display: none;
	}
}
.timeline {
	min-height: 4rem;
}
.project-data-wrapper {
	border-bottom: 1px solid #dee2e6;
	min-height: 2rem;
}
.project-data-wrapper:last-child {
	border: none;
}


.right-top {
	position: absolute;
	top: 0;
	right: 0;
}


.google-map {
	min-height: 300px;
	height: 400px;
}


.progress-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0005;
	z-index: 10;
}
.progress-bar-wrapper {
	width: 50%;
	margin: auto;
	top: 50%;
	background: #e1e6e690;
	box-shadow: 0 0 5px .5px #284a7090;
}
.progress-bar {
	background: #017ca7;
}
.progress-bar-striped {
	background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
	background-size: 1rem 1rem;
}
.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite;
}
</style>
