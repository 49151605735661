<template>
	<b-form @submit="onSubmit">
		<input-form class="p-0" :options="updateOptions" :actions="{submit: false, cancel: false}" v-model="result.update" :use-form="false"></input-form>
		<b-row class="input" v-for="(option, key) in newOptions" :key="key">
			<div class="col-2 pr-2">
				<input-select :size="option.project_data_type.size" :options="option.project_data_type.options" :keys="option.project_data_type.keys" v-model="result.new[key][option.project_data_type.key]" :required="option.project_data_type.required" :use-form="false"></input-select>
			</div>
			<div class="col-10">
				<input-radio :options="option.status.options" :keys="option.status.keys" :required="option.status.required" v-model="result.new[key][option.status.key]"></input-radio>
			</div>
		</b-row>
		<b-row class="mt-2 justify-content-center">
			<b-button class="form-btn submit-btn" type="submit" variant="primary">確認</b-button>
			<b-button class="form-btn cancel-btn" type="button" variant="primary" @click="cancel">取消</b-button>
			<b-button class="form-btn clear-btn" type="button" variant="primary" @click="addConstructionDataType" v-if="unusedConstructionDataTypes.length">新增其他施工資料</b-button>
		</b-row>
	</b-form>
</template>


<script>
import InputRadio from '@/components/Input/InputRadio';
import InputSelect from '@/components/Input/InputSelect';
import InputForm from '@/components/Input/InputForm';
import { isEmpty, checkAllTrue } from '@/utils/assist';

export default {
	name: 'ConstructionDataEditor',
	components: {
		InputRadio,
		InputSelect,
		InputForm,
	},
	props: {
		constructionDataType: {
			type: Array,
			default: () => []
		},
		constructionData: {
			type: Array,
			default: () => []
		},
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			call: {},
			loading: true,
			editing: false,
			result: {
				update: {},
				new: [],
			},
			newDataTypes: [],
			construction_data_status_list: [],
		}
	},
	created() {
		this.construction_data_status_list = this.$store.getters.enum('project_construction_datum.status')
		this.result.update = Object.assign(this.result.update, this.constructionData.reduce((obj, datum) => {
			obj[datum.project_data_type_id] = datum.status_index
			return obj
		}, {}))
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
		loading() {
			this.$emit('loading', this.loading)
			if(!this.loading) {
				alert('修改成功')
				this.$emit("submit")
			}
		}
	},
	mounted() {
	},
	computed: {
		unusedConstructionDataTypes() {
			return this.constructionDataType.filter(type => !this.constructionData.find(datum => datum.project_data_type_id === type.id))
		},
		updateOptions() {
			return this.constructionDataType.filter(type => this.constructionData.find(datum => datum.project_data_type_id === type.id)).map(type => {
				return {
					key: type.id,
					type: "radio",
					label: type.name,
					options: this.construction_data_status_list,
					keys: {value: 'index', text: 'text'},
					required: true,
					labelClass: 'col-2 p-0'
				}
			})
		},
		newOptions() {
			return Object.keys(this.result.new).map(type => {
				return {
					project_data_type: {
						key: 'project_data_type_id',
						type: "select",
						label: '',
						options: this.unusedConstructionDataTypes,
						keys: {value: 'id', text: 'name'},
						size: 'xs',
						required: true,
					},
					status: {
						key: 'status',
						type: "radio",
						label: '',
						options: this.construction_data_status_list,
						keys: {value: 'index', text: 'text'},
						required: true,
					}
				}
			})
		},
	},
	methods:{
		cancel() {
			this.$emit("cancel")
		},
		getProjectDataType(id) {
			return this.constructionDataType.find(type => type.id === id)
		},
		addConstructionDataType() {
			if(Object.keys(this.result.new).length + this.updateOptions.length >= this.constructionDataType.length) {
				alert('已超過施工資料數量')
				return;
			}
			this.result.new.push({
				project_data_type_id: null,
				status: null,
			})
		},
		onSubmit(e) {
			e.preventDefault()
			// 檢查新增的施工資料是否重複
			if(Object.values(this.result.new).length > [...(new Set(this.result.new.map(datum => datum.project_data_type_id)))].length) {
				alert('含有重複施工資料，請重新選擇施工資料種類')
				return
			}
			let updateData = {
				project_construction_data: Object.keys(this.result.update).map(data_type_id => {
					let datum = this.constructionData.find(d => d.project_data_type_id === parseInt(data_type_id))
					return datum ? {
						id: datum.id,
						status: this.result.update[data_type_id]
					} : undefined
				}).filter(o => o)
			}
			if(this.result.new.length) {
				this.createMultiProjectConstructionData({
					project_id: this.projectID,
					project_type: this.projectType,
					user_id: this.$store.getters.currentUser.id,
					project_construction_data: this.result.new
				}, () => {
					if(updateData.project_construction_data.length)
						this.updateMultiProjectConstructionData(updateData)
				})
			}
			else if(updateData.project_construction_data.length)
				this.updateMultiProjectConstructionData(updateData)

		},
		createMultiProjectConstructionData(data, func=()=>{}) {
			this.$set(this.call, 'createMultiProjectConstructionData', false)
			this.$axios.createMultiProjectConstructionData(data, (response) => {
				func()
				this.call.createMultiProjectConstructionData = true;
			}, (error) => {
				func()
				this.call.createMultiProjectConstructionData = true;
			})
		},
		updateMultiProjectConstructionData(data) {
			this.$set(this.call, 'updateMultiProjectConstructionData', false)
			this.$axios.updateMultiProjectConstructionData(data, (response) => {
				this.call.updateMultiProjectConstructionData = true;
			}, (error) => {
				this.call.updateMultiProjectConstructionData = true;
			})
		},
	}
}
</script>

<style scoped>
.input {
	padding: 0 .5rem .5rem 1rem;
}
</style>
