<template>
	<div class="px-md-3 px-2">
		<menu-group :menu="menu"></menu-group>
	</div>
</template>

<script>
import MenuGroup from '@/components/Menu/MenuGroup.vue'
export default {
	name: 'Home',
	components:{
		MenuGroup
	},
	props: {
		menu: {
			type: [Array, Object],
			default: () => []
		}
	},
	data() {
		return {
		}
	},
	created() {
	},
	destroyed() {
	},
	mounted() {
	},
	computed: {
	},
	methods:{
	}
}
</script>

<style scoped>
</style>