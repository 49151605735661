<template>
	<component :is="formComponent" @submit="submit" ref="form">
		<div class="form-wrapper" :class="wrapperClass">
			<div :class="group.class" v-for="(group, key) in inputGroups" :key="key">
				<b :label-class="group.titleClass">{{group.title}}</b>
				<slot :name="`before-group-${group.key}`" :group="group"></slot>
				<input-form :options="group.options" :size="group.size ? group.size : size" :deep="deep" :use-form="false" :actions="{submit: false, cancel:false, clear:false}" v-model="result[group.valueKey]" v-on="getFormEvents(group.key)">
					<template v-slot:[`before-input-${input.key}`] v-for="(input) in group.options">
						<slot :name="`group-${group.key}-before-input-${input.key}`" :group="group" :input="input"></slot>
					</template>
					<template v-slot:[`after-input-${input.key}`] v-for="(input) in group.options">
						<slot :name="`group-${group.key}-after-input-${input.key}`" :group="group" :input="input"></slot>
					</template>
				</input-form>
				<slot :name="`after-group-${group.key}`" :group="group"></slot>
			</div>
		</div>
		<b-row class="mt-3 justify-content-center">
			<b-button class="form-btn submit-btn" :class="{'size-xs': size==='xs'}" type="submit" variant="primary" v-if="buttons.submit">{{submitText}}</b-button>
			<b-button class="form-btn cancel-btn" :class="{'size-xs': size==='xs'}" type="button" variant="primary" v-if="buttons.cancel" @click="cancel">{{cancelText}}</b-button>
			<b-button class="form-btn clear-btn" :class="{'size-xs': size==='xs'}" type="button" variant="primary" v-if="buttons.clear" @click="clear">{{clearText}}</b-button>
			<slot name="button"></slot>
		</b-row>
	</component>
</template>

<script>
import InputForm from '@/components/Input/InputForm'
import { deepCopy } from "@/utils/assist"
export default {
	name: 'InputFormGroup',
	components: {
		InputForm,
	},
	props: {
		groups: {
			type: Array,
			default: () => []
		},
		value: {
			type: Object,
			default: () => { return {} }
		},
		actions: {
			type: Object,
			default: () => { return {} }
		},
		wrapperClass: {
			type: [Object, String],
			default: ''
		},
		useForm: {
			type: Boolean,
			default: true
		},
		size: {
			type: String,
			default: ""
		},
		submitText: {
			type: String,
			default: '確認',
		},
		cancelText: {
			type: String,
			default: '取消',
		},
		clearText: {
			type: String,
			default: '清除',
		},
		deep: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			result: {},
			buttons: {
				submit: true,
				cancel: true,
				clear: false,
			},
			inputGroups: [],
			events: [ 'change', 'input', 'load', 'reload' ],
		}
	},
	watch: {
		value: {
			deep: true,
			handler(value) {
				this.setValue()
			}
		},
		actions: {
			deep: true,
			handler(value) {
				this.setActions()
			}
		},
		options: {
			deep: true,
			handler(value) {
				this.setGroups()
			}
		},
	},
	created() {
		this.setValue();
		this.setActions();
		this.setGroups();
	},
	computed: {
		formComponent() {
			return this.useForm ? 'b-form' : 'div';
		},
		deserializeResult() {
			return this.deep ? Object.deserialize(this.result) : this.result
			// return Object.values(this.deep ? Object.deserialize(this.result) : this.result).reduce((obj, values) => {
			// 	return { ...obj, ...values}
			// }, {})
		},
	},
	methods: {
		setValue() {
			this.result = Object.map(this.groups.keyBy('valueKey'), group => {
				let result = Object.get(this.result, group.valueKey, {})
				let value = Object.get(this.value, group.valueKey, {})
				return {
					...this.deep ? Object.serialize(result) : result,
					...this.deep ? Object.serialize(value) : value,
				}
			})
		},
		setActions() {
			this.buttons = {
				...this.buttons,
				...this.actions,
			}
		},
		setGroups() {
			this.inputGroups = this.groups.map(opt => {
				return Object.keys(opt).reduce((obj, key) => {
					obj[key] = typeof opt[key] === 'function' ? opt[key]() : opt[key];
					return obj;
				}, {})
			});
		},
		getFormEvents(groupKey) {
			return {
				...Object.map(this.events.keyBy(), (event) => {
					return (value) => this.onEvent(event, groupKey, value)
				}),
				...Object.map(this.events.keyBy(event => `option${event.toStudlyCase()}`), (event) => {
					return (key, e) => this.onOptionEvent(event, groupKey, key, e)
				}),
			}
		},
		submit(e) {
			this.$emit('submit', e)
		},
		cancel() {
			this.$emit('cancel', this.deserializeResult)
		},
		clear() {
			this.$emit('clear', this.deserializeResult)
		},
		onOptionEvent(event, groupKey, key, e) {
			let eventTitle = event.toTitleCase()
			this.$emit(`option${eventTitle}`, key, e)
			let group = this.groups.find(group => group.key === groupKey)
			let input = group.options.find(input => input.key === key)
			let handler = input && input[`on${eventTitle}`] ? input[`on${eventTitle}`] : (() => {})
			handler(e)
		},
		onEvent(event, groupKey, result) {
			let group = this.groups.find(group => group.key === groupKey)
			this.result[group.valueKey] = { ...this.result[group.valueKey], ...result }
			this.$emit(event, this.deserializeResult)
		},
		checkHide(hide, index) {
			if(!hide) return false;
			if(typeof hide === 'function') return hide(index);
			return hide
		},
		reportValidity() {
			if(this.$refs.form.tagName === 'FORM')
				return this.$refs.form.reportValidity()
		}
	}
}
</script>

<style scoped>
.form-wrapper {
	padding: 0 1rem 0 1rem;
}
.form-wrapper > * {
	padding: .25rem 0 .25rem 0;
}
</style>
