<template>
	<loading v-if="loading"/>
	<div class="col-12" v-else>
		<h5 class="ml-2 pb-2" style="white-space:normal">{{group.name}}</h5>
		<button-group class="col-12" :buttons="buttons" button-class="px-1"></button-group>
		<hr />
		<b-row class="m-0 p-0">
			<new-project-list class="col-md-6 col-12" :group="group" :permission="permission" @reload="reload"></new-project-list>
			<b-card class="border-0 col-md-6 col-12" sub-title="待測量案件" body-class="pb-1">
				<bv-table class="px-lg-2 px-1" :columns="projectTableColumns" :detail-columns="projectTable.detailColumns" :data="projects" :configs="projectTable.configs" :actions="projectTable.actions"></bv-table>
			</b-card>
		</b-row>
		<hr />
		<template v-if="group.project_type_key === 'taipei_water'">
			<b-card class="border-0 col-md-6 col-12" sub-title="估驗案件" body-class="pb-1">
				<acceptance-form class="px-1"></acceptance-form>
			</b-card>
		</template>
	</div>
</template>

<script>
import Loading from '@/components/Loading/Loading.vue'
import NewProjectList from '@/components/Project/List/NewProjectList.vue'
import AcceptanceForm from '@/components/Project/Acceptance/AcceptanceForm.vue'
import BvTable from '@/components/Table/BvTable.vue'
import ButtonGroup from '@/components/Button/ButtonGroup'
import { checkAllTrue } from '@/utils/assist';
import ProjectConfigs from '@/projects';

export default {
	name: 'GroupInfo',
	components:{
		Loading,
		NewProjectList,
		AcceptanceForm,
		BvTable,
		ButtonGroup,
	},
	props: {
		groupID: {
			required: true,
			default: 0
		},
		group: {
			type: Object
		},
		permission: {
			type: Object
		}
	},
	data() {
		return {
			loading: true,
			call: { getProjectList: false, },
			group_id: Number.isInteger(parseInt(this.groupID)) ? parseInt(this.groupID) : 0,
			projects: [],
			projectTable: {
				columns: [
					{ key: 'key', label: 'ID', sortable: true, searchable: true },
					{ key: 'excavation_permit', label: '路證編號', sortable: true, searchable: true },
					{ key: 'address', label: '地址', sortable: true, searchable: true },
					{ key: 'status', label: '狀態', sortable: true, searchable: true },
					{ key: 'received_at', label: '收件日期', sortable: true, searchable: true },
					{ key: 'created_at', label: '新增日期', sortable: true, searchable: true },
				],
				detailColumns: [
					{ key: 'excavation_permit', label: '路證編號' },
					{ key: 'address', label: '地址' },
					{ key: 'construction_name', label: '工程' },
					{ key: 'status', label: '狀態' },
					{ key: 'received_at', label: '收件日期' },
					{ key: 'created_at', label: '新增日期' },
				],
				configs: {
					actionsColumn: true,
					rowDetail: true,
					perPage: 5,
				},
				actions: [
					{
						//icon, label
						button: { icon: "fas fa-cog", label: "管理" },
						//function, link, route
						action: { type: 'route', route: this.routeToProjectInfo }
					},
				],
			},
			buttons: [
				{
					//icon, label
					button: { icon: "fas fa-plus", label: "新增案件" },
					//function, link, route
					action: { type: 'route', route: {name: '新增專案案件', params: {groupID: this.groupID}} },
					visible: () => this.checkPermission('write')
				},
				{
					//icon, label
					button: { icon: "fas fa-list", label: "案件列表" },
					//function, link, route
					action: { type: 'route', route: {name: '專案案件列表', params: {groupID: this.groupID}} }
				},
				{
					//icon, label
					button: { icon: "far fa-list-alt", label: "工地列表" },
					//function, link, route
					action: { type: 'route', route: {name: '專案工地列表', params: {groupID: this.groupID}} },
					visible: (index) => this.$store.getters.isDeveloper || this.checkPermission('edit') || this.checkPermission(index, 'write')
				},
				{
					//icon, label
					button: { icon: "fas fa-cog", label: "設定" },
					//function, link, route
					action: { type: 'route', route: {name: '專案設定', params: {groupID: this.groupID}} },
					visible: () => this.checkPermission('edit')
				},
			],
		}
	},
	created() {
		if(typeof this.group === 'undefined') {
			this.reload()
		}
		else if(!this.group_id) {
			// TODO
			this.$router.replace({name: '專案', params: {groupID: this.group_id, from: {name: '專案列表'}}});
		}
		else {
			this.getProjectList()
		}
	},
	destroyed() {
	},
	mounted() {
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	computed: {
		editable() {
			return this.checkPermission('edit') ? true : false
		},
		projectTableColumns() {
			return this.projectTable.columns.filter(column => {
				let key = column.key
				return [
					'key',
					...ProjectConfigs.listParams(this.group.project_type_key),
				].includes(key)
			})
		},
	},
	methods:{
		reload() {
			this.$router.replace({name: '專案', params: {groupID: this.group_id, from: {name: '專案資訊'}}});
		},
		checkPermission(action_key) {
			if(!this.groupID) return this.$store.getters.isDeveloper;
			if(!this.permission) return false;
			return this.permission.actions.find(action => action.key === action_key)
		},
		getProjectList() {
			let statusKey = [this.group.project_type_data.name, 'status'].filter(o => o).join('_')
			let statuses = [
				'created',
				'insufficient',
				'unmeasured',
			].map(key => this.$store.getters.enum(`project.${statusKey}.${key}`).index)
			this.call.getProjectList = false;
			this.$axios.getProjectList({
				project_group_id: this.group_id,
				status: statuses,
				less: 1,
				paging: 0,
			}, (response) => {
				this.projects = response.data
				this.call.getProjectList = true;
			}, (error) => {
				this.call.getProjectList = true;
			})
		},
		routeToProjectInfo(index) {
			if(this.group) {
				return {name: '案件', params: {groupID: this.group_id, projectType: this.projects[index].project_type_index, projectID: this.projects[index].id}}
			}
			return {name: '案件', params: {groupID: this.group_id, projectType: this.projects[index].project_type_index, projectID: this.projects[index].id}}
		},
	}
}
</script>

<style scoped>
</style>