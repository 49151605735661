let dynamicFormHelper = {
	decodeFormData(titles, data) {
		titles = titles.map(item => {
			return {
				...item,
				section: item.key.split("_")[0]
			}
		})
		return Object.keys(data).reduce((obj, key) => {
			let title = titles.find(o => o.key === key)
			if(data[key] !== null)
				obj[key] = {
					...title,
					value: title.type === 'number' && !Number.isNaN(Number(data[key])) ? Number(data[key]) : data[key]
				}
			return obj
		}, {})
	},
}

export default dynamicFormHelper
