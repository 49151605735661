<template>
	<div class="w-100 outside-wrapper">
		<b-row class="col-12 align-items-center">
			<label class="input-label col-md-2 col-12" :class="`${labelClass} ${required ? 'input-label-required' : ''}`" v-show="label">{{label}}</label>
			<span class="form-input-wrapper" :class="size === 'xs' ? 'size-xs' : ''">
				<b-form-file ref="input" :size="size === 'xs' ? 'xs' : ''" class="input-file" :class="{'input-file-disabled': disabled}" :placeholder="placeholder" :drop-placeholder="dropPlaceholder" :accept="accept" v-model="model" :multiple="multiple" :required="required" :disabled="disabled" @input="onInput" @change="onChange">
					<template slot="file-name" slot-scope="{ names }">
						<span v-if="preview_files.length <= 0">{{ placeholder }}</span>
						<b-badge class="file-badge" v-else>{{ preview_files[0].file.name }}</b-badge>
						<b-badge class="file-badge" v-show="names && preview_files.length > 1">
							+ {{ preview_files.length - 1 }}
						</b-badge>
					</template>
				</b-form-file>
				<button class="col-1 input-label link" @click="onClick"><i class="fa-fw" :class="button" aria-hidden="true"></i></button>
				<span class="input-background"></span>
			</span>
		</b-row>
		<b-row class="w-100">
			<div class="col-md-2 col-12" :class="labelClass"></div>
			<div class="flex-fill text-hint font-size-sm text-left" :class="hintClass" v-if="hint">* {{hint}}</div>
		</b-row>
		<b-row class="col-12 preview-outside-wrapper justify-content-end" v-show="preview && preview_files.length > 0">
			<div class="col-md-2 col-12" :class="labelClass"></div>
			<b-row id="PreviewFile" class="preview-wrapper flex-fill">
				<div class="preview-file-wrapper col-xl-3 col-lg-4 col-6" v-for="(file, key) in preview_files" :key="key" v-show="file.file.type.startsWith('image')">
					<template v-if="file.src">
						<span class="preview-file" :class="getPreviewPhotoClass(file.file, key)">
							<img :src="file.src">
							<div class="preview-file-name">{{file.file.name}}</div>
						</span>
						<button class="preview-file-close-btn" @click="deleteFile" :index.prop="key"><i class="fas fa-times"></i></button>
					</template>
					<template v-else>
						<span class="preview-file loading"><img :src="loading_img_url" class="loading-img"></span>
					</template>
				</div>
			</b-row>
		</b-row>
	</div>
</template>

<script>
import { deepCopy } from '@/utils/assist';
import loading_img from '@/assets/images/loading.gif';
import fileManager from '@/utils/file';
export default {
	name: 'InputFile',
	components: {
	},
	props: {
		value: {
			type: [window.File, Array],
		},
		placeholder: {
			type: String,
			default: "未選擇任何檔案"
		},
		dropPlaceholder: {
			type: String,
			default: "拖移檔案至此"
		},
		size: {
			type: String,
			default: ""
		},
		multiple: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		label: {
			type: String,
		},
		labelClass: {
			type: String,
			default: ""
		},
		button: {
			type: String,
			default: "fas fa-folder-open"
		},
		accept: {
			type: String,
			default: ""
		},
		hint: {
			type: String,
			default: ""
		},
		hintClass: {
			type: String,
			default: ""
		},
		preview: {
			type: Boolean,
			default: false
		},
		previewPhotoClass: {
			type: [String, Object, Function],
			default: ""
		}
	},
	data() {
		return {
			model: this.value,
			preview_files: [],
			loading_img_url: loading_img,
			reading: false,
			loading: false,
		}
	},
	mounted() {
	},
	watch: {
		value: {
			deep: true,
			handler(value) {
				value = Array.isArray(value) ? value : [value]
				this.model = this.multiple ? Array.from(value) : value[0]
				this.preview_files.splice(0, this.preview_files.length)
				if(this.model || (this.multiple && this.model && this.model[0])) {
					this.preivewFiles(this.multiple ? this.model : [this.model]);
				}
			}
		},
		preview_files: {
			deep: true,
			handler(files) {
				let ori = this.loading;
				this.loading = !files.every(file => file.src);
				if(ori && !this.loading) {
					this.load(this.multiple ? this.preview_files : this.preview_files[0]);
				}
			}
		}
	},
	methods: {
		onClick(e) {
			e.preventDefault();
			let target = e.target;
			while(target.tagName.toLowerCase() == 'svg' || target.tagName.toLowerCase() == 'path' || target.tagName.toLowerCase() == 'i')
				target = target.parentNode;
			target.parentNode.querySelector('input').click()
		},
		onInput(e) {
			this.$emit('input', e)
		},
		onChange(e) {
			if(e.type === 'change') {
				this.model = this.multiple ? Array.from(e.target.files) : e.target.files[0]
			}
			this.preview_files.splice(0, this.preview_files.length)
			if(this.model || (this.multiple && this.model && this.model[0])) {
				this.preivewFiles(this.multiple ? this.model : [this.model]);
				// this.load(this.multiple ? this.preview_files.map(file => file.file) : this.preview_files[0].file);
			}
		},
		load(loaded_files) {
			this.$emit('load', loaded_files);
		},
		reload(files) {
			this.$emit('reload', files);
		},
		preivewFiles(raw_files) {
			this.loading = true;
			for(let i = 0; i < raw_files.length; i++) {
				if(raw_files[i] instanceof File) {
					this.preview_files.push({
						file: raw_files[i], src: null
					});
					this.readFile(this.preview_files[i]);
				}
			}
		},
		readFile(file) {
			let reader = new FileReader();
			let self = this;
			reader.onload = function (e) {
				file.src = e.target.result
			}
			reader.readAsDataURL(file.file);
			return file
		},
		deleteFile(e) {
			e.preventDefault();
			let target = e.target;
			while(target.tagName.toLowerCase() == 'svg' || target.tagName.toLowerCase() == 'path' || target.tagName.toLowerCase() == 'i')
				target = target.parentNode;
			this.preview_files.splice(target.index, 1)
			if(this.preview_files.length <= 0) {
				this.model = null
				this.onChange({target: this.$el.querySelector('input[type="file"]')})
			}
			this.onInput(this.preview_files.map(file => file.file))
			this.reload(this.multiple ? this.preview_files.map(file => file.file) : this.preview_files[0].file);
			this.load(this.multiple ? this.preview_files : this.preview_files[0]);
		},
		getPreviewPhotoClass(file, key) {
			if(typeof this.previewPhotoClass !== 'function')
				return this.previewPhotoClass
			return this.previewPhotoClass(file, key)
		}
	}
}
</script>

<style scoped>
.outside-wrapper {
	align-items: center;
	padding: 0;
}
.form-input-wrapper {
	padding: 0 1rem;
	border-radius: 1.5rem;
	height: 2.5rem;
	display: flex;
	align-items: center;
	flex: 1 1;
}
.form-input-wrapper.size-xs {
	border-radius: 0.5rem;
	height: 1.8rem;
}
.form-input-wrapper.size-xs .input-label {
	display: flex;
	align-items: center;
}
.form-input-wrapper.size-xs .input-label [data-icon="search"] {
	font-size: 0.8rem
}
/* 清除自動填字背景色 */
.input-text:-webkit-autofill,
.input-text:-webkit-autofill:hover,
.input-text:-webkit-autofill:focus,
.input-text:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
	-webkit-transition: background-color 5000s ease-in-out 0s;
	-moz-transition: background-color 5000s ease-in-out 0s;
	-o-transition: background-color 5000s ease-in-out 0s;
}
.form-input-wrapper.size-xs .input-file {
	height: 1rem;
	font-size: 0.9rem;
	padding: 0 auto;
}
.input-file {
	border: none;
	z-index: 3;
	padding: .25rem;
}
.input-file:focus {
	border: none;
	box-shadow: none;
}
.input-file:focus ~ .input-label + .input-background {
	border: 1px solid #6b8ca9;
	box-shadow: 0 0 1px 1px #8ea9c230, 0 0 1px 1px #8ea9c230;
}
.input-file.is-invalid + .input-label + .input-background{
	border: 1px solid red;
}
.input-file.input-file-disabled, .input-file.input-file-disabled > input[disabled], .input-file.is-invalid, .input-file[state=false] {
	background-color: transparent !important;
	background-image: none;
}
.input-file.input-file-disabled + .input-label + .input-background {
	background-color: #e9ecef;
	border: 1px solid #b8bcbb;
}
.input-background {
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 1.5rem;
	border: 1px solid #8ea9c2;
	width: 100%;
	height: 100%;
}
.input-label {
	background-color: transparent;
	border: none;
	z-index: 3;
	display: flex;
	align-items: center;
	padding-right: .5rem;
}
button.input-label {
	color: #6b8ca9;
}
label.input-label {
	overflow-wrap: break-word;
	word-wrap: break-word;
	white-space: normal;
}
label.input-label.input-label-required::after {
	content: '*';
	color: crimson;
}
.size-xs input, .size-xs label {
	padding: 0;
}
.size-xs .input-label, .size-xs .input-file {
	font-size: 0.8rem;
	padding: 0;
}
.size-xs .file-badge {
	font-size: 0.8rem;
	overflow: visible;
	height: 1rem;
}
.file-badge {
	height: 100%;
	display: inline-block;
	/*align-items: center;*/
	vertical-align: middle;
	line-height: 1;
	border-radius: 1rem;
	margin-left: .25rem;
	background-color: #b2c8d820;
	color: #284a70;
	border: 1px solid #6b8ca930;
	padding: 0 .5rem;
	box-shadow: 0 0 2px 1px #6b8ca930, 0 0 2px 1px inset #6b8ca930;
	text-overflow : ellipsis;
	overflow : hidden;
	max-width: 100%;
}
.file-badge::before{
	content: '';
	display: inline-block;
	height: 100%;
	width: 0;
	vertical-align: middle;
}
.file-badge:first-child {
	margin: 0;
}
.file-badge:last-child:not(:only-child) {
	text-overflow : unset;
	overflow : unset;
}
.preview-outside-wrapper {
	margin: .25rem;
}
.preview-wrapper {
	flex-wrap: wrap;
	max-width: 100%
}
.preview-file-wrapper {
	padding: .25rem;
}
.preview-file {
	height: 100%;
	width: 100%;
	padding: .5rem;
	border: .5px solid #cbd8e1;
	border-radius: 1rem;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}
.preview-file img {
	max-width: 100%;
	max-height: 200px;
	border-radius: .75rem;
}
.preview-file-close-btn {
	position: absolute;
	right: .75rem;
	top: .75rem;
	z-index: 3;
	height: 1rem;
	width: 1rem;
	font-size: .6rem;
	display: none;
	align-items: center;
	justify-content: center;
	border: .5px solid #cbd8e150;
	border-radius: 50%;
	box-shadow: 0 0 2px 1px #cbd8e1
}
.preview-file:hover ~ .preview-file-close-btn {
	display: flex;
}
.preview-file-close-btn:hover {
	display: flex;
	background-color: #cbd8e1;
	box-shadow: 0 0 2px 1px #b2c8d8;
}
.preview-file-name {
	font-size: .9rem;
	width: 100%;
	position: absolute;
	bottom: .75em;
	background-color: #fff8;
	text-align: center;
}
.preview-file.error {
	border-color: #d9766c;
	background-color: #e1cbcb50;
}
.preview-file.error::after {
	content: "🚫";
	font-weight: 900;
	font-size: 2rem;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	position: absolute;
	margin: auto;
}
.preview-file.error > svg {
	position: absolute;
	margin: auto;
}
.preview-file.error img {
	filter: opacity(0.5);
}
.preview-file.error ~ .preview-file-close-btn {
	border: .5px solid #d9766c;
	box-shadow: 0 0 2px 1px #d9766c;
}
.preview-file.error ~ .preview-file-close-btn:hover {
	background-color: #e1cbcb;
	box-shadow: 0 0 2px 1px #d9766c;
}
.preview-file.loading {
	min-height: 100px;
}
.loading-img {
	height: 50px;
}
</style>
